import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import { sendSurgeryReferralContext } from '../../context';
import styles from '../QuestionForm/QuestionForm.module.scss';

export default function RecommendForEducationalFund(): React.ReactElement {
    const { recommendForEducationalFund, changeRecommendForEducationalFund } =
        React.useContext(sendSurgeryReferralContext);

    const translate = useTranslate();

    const handleChange = React.useCallback(
        ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
            changeRecommendForEducationalFund(checked);
        },
        [changeRecommendForEducationalFund],
    );

    return (
        <fieldset className={styles.container}>
            <ul className={styles.checkboxes}>
                <li>
                    <input
                        type="checkbox"
                        id="recommendForEducationalFund"
                        onChange={handleChange}
                        checked={recommendForEducationalFund}
                    />
                    <label htmlFor="recommendForEducationalFund">
                        {translate(
                            'vetspireExtension.surgeryReferrals.send.recommendForEducationalFund',
                        )}
                    </label>
                </li>
            </ul>
        </fieldset>
    );
}
