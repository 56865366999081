import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useProviders from 'hooks/useProviders';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import type { Provider } from 'api/providers/queries';
import { ProvidersSelect } from 'components/LookupSelect';
import useTranslate from 'hooks/useTranslate';
import Dialog from '@mui/material/Dialog';

import styles from './ChooseProvider.module.scss';

interface ChooseProviderProps {
    onClose: () => void;
    onSubmit: (providerId: string) => void;
}

export default function ChooseProvider({
    onClose,
    onSubmit,
}: ChooseProviderProps) {
    const translate = useTranslate();
    const providersQueryResult = useProviders();

    const [selectedProvider, setSelectedProvider] =
        React.useState<Provider | null>();

    return (
        <Dialog open className={styles.container}>
            <DialogTitle className={styles.title}>
                {translate(
                    'vetspireExtension.patientPlans.chooseProvider.title',
                )}
                <IconButton onClick={onClose} className={styles.closeIcon}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={styles.content}>
                    <div className={styles.note}>
                        {translate(
                            'vetspireExtension.patientPlans.chooseProvider.note',
                        )}
                    </div>
                    <div>
                        {translate(
                            'vetspireExtension.patientPlans.chooseProvider.provider',
                        )}

                        <ProvidersSelect
                            className={styles.providerSelect}
                            providersQueryResult={providersQueryResult}
                            selectedProviders={
                                selectedProvider ? [selectedProvider] : []
                            }
                            onChange={(providers) => {
                                if (providers.length > 0) {
                                    setSelectedProvider(providers[0]);
                                }
                            }}
                            addLocationGroup={false}
                            addOtherGroup={false}
                            isMulti={false}
                            vetsOnly
                            placeholder={translate(
                                'vetspireExtension.patientPlans.chooseProvider.placeholder',
                            )}
                        />
                    </div>
                </DialogContentText>
                <DialogActions>
                    <Button
                        type="button"
                        variant="text"
                        color="error"
                        onClick={onClose}
                        className={styles.button}
                    >
                        {translate(
                            'vetspireExtension.patientPlans.chooseProvider.actions.cancel',
                        )}
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="error"
                        className={styles.button}
                        disabled={!selectedProvider}
                        onClick={() => onSubmit(selectedProvider?.id || '')}
                    >
                        {translate(
                            'vetspireExtension.patientPlans.chooseProvider.actions.submit',
                        )}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
