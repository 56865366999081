import { gql } from '@apollo/client';
import {
    ConversationalSmsMessage,
    BuildProactiveMessageBodyInput,
    BuildProactiveMessageBodyResult,
    GetTemplateVetspireVariableValuesInput,
    GetTemplateVetspireVariableValuesResult,
    ProactiveMessagingTemplate,
    ProactiveMessagingVariable,
    SendProactiveMessageInput,
    LocationConversationInfo,
    LocationConversationPet,
    UpdateConversationUnreadStatusInput,
    RecentUserConversationHistoryResult,
} from '@bondvet/types/textMessaging';
import type { OperationResult } from '@bondvet/types';

import { type Client, ClientFragment, type Patient } from 'api/clients/queries';

export type ClientWithPhoneNumber = {
    id: string;
    givenName: string;
    familyName: string;
    preferredPhoneNumber: string;
    optedIn: boolean;
};

export type ClientWithPatients = ClientWithPhoneNumber & {
    pets: ReadonlyArray<LocationConversationPet>;
};

export type VetspireClientWithPatients = Client & {
    patients: readonly Patient[];
};
export type ClientsWithPatientsSearchVariables = {
    query: string;
    clientId?: string;
};

export type ClientsWithPatientsSearchResult = {
    searchAccounts: ReadonlyArray<VetspireClientWithPatients>;
};

export const clientsWithPatientsSearch = gql`
    query searchAccounts($query: String!, $clientId: ID) {
        searchAccounts(query: $query, clientId: $clientId) {
            id
            givenName
            familyName
            email
            preferredPhoneNumber {
                id
                value
            }
            externalCommPreferences {
                postcardMarketing
            }
            patients {
                id
                name
                isDeceased
                isActive
                sex
            }
        }
    }
    ${ClientFragment}
`;

export const phoneNumberHasOpenTickets = gql`
    query phoneNumberHasOpenTickets($phoneNumber: String!) {
        phoneNumberHasOpenTickets(phoneNumber: $phoneNumber)
    }
`;

export const cachedUserConversationHistory = gql`
    query cachedUserConversationHistory(
        $phoneNumber: String!
        $limit: Int
        $offset: Int
    ) {
        cachedUserConversationHistory(
            phoneNumber: $phoneNumber
            limit: $limit
            offset: $offset
        ) {
            _id
            type
            direction
            intlPhoneNumber
            body
            datetime
            fromName
            bondLocation
            botMessage
            virtualTeamMessage
            proactive
            locationId
            providerId
            zendeskTicketId
            imageSrc
        }
    }
`;

export interface PhoneHasOpenTicketsQueryResult {
    phoneNumberHasOpenTickets: boolean;
}
export interface PhoneHasOpenTicketsQueryVariables {
    phoneNumber: string;
}

export interface CachedUserConversationHistoryQueryResult {
    cachedUserConversationHistory: readonly ConversationalSmsMessage[];
}

export interface CachedUserConversationHistoryQueryVariables {
    phoneNumber: string;
    limit?: number;
    offset?: number;
}

export const syncRecentUserConversationHistory = gql`
    query syncRecentUserConversationHistory(
        $vetspireClientId: String!
        $phoneNumber: String!
        $afterId: String
        $limit: Int
        $offset: Int
    ) {
        syncRecentUserConversationHistory(
            phoneNumber: $phoneNumber
            vetspireClientId: $vetspireClientId
            afterId: $afterId
            limit: $limit
            offset: $offset
        ) {
            newMessages
        }
    }
`;

export interface SyncRecentUserConversationHistoryResult {
    syncRecentUserConversationHistory: RecentUserConversationHistoryResult;
}

export interface SyncRecentUserConversationHistoryVariables {
    vetspireClientId: string;
    afterId?: string;
    phoneNumber: string;
    limit?: number;
    offset?: number;
}

export interface BuildMessagePreviewQueryResult {
    buildProactiveMessageBody: BuildProactiveMessageBodyResult;
}

export interface BuildMessagePreviewQueryVariables {
    input: BuildProactiveMessageBodyInput;
}
export const buildMessagePreviewQuery = gql`
    query buildProactiveMessageBody($input: BuildProactiveMessageBodyInput!) {
        buildProactiveMessageBody(input: $input) {
            body1
            body2
        }
    }
`;

export interface GetTemplateVetspireVariableValuesQueryVariables {
    input: GetTemplateVetspireVariableValuesInput;
}

export interface GetTemplateVetspireVariableValuesQueryResult {
    getTemplateVetspireVariableValues: GetTemplateVetspireVariableValuesResult;
}
export const getTemplateVetspireVariableValuesQuery = gql`
    query getTemplateVetspireVariableValues(
        $input: GetTemplateVetspireVariableValuesInput!
    ) {
        getTemplateVetspireVariableValues(input: $input) {
            values {
                identifier
                value
            }
        }
    }
`;

export interface TemplateVariablesQueryVariables {
    templateId: string;
}
export interface TemplateVariablesQueryResult {
    templateVariables: readonly ProactiveMessagingVariable[];
}

export const templateVariablesQuery = gql`
    query templateVariables($templateId: ID!) {
        templateVariables(templateId: $templateId) {
            _id
            identifier
            example
            description
            maxCharacters
            type
            vetspirePath
            vetspireEntity
            vetspirePathRawType
            pronounType
        }
    }
`;

export interface ProactiveMessagingTemplatesQueryResult {
    proactiveMessagingTemplates: readonly ProactiveMessagingTemplate[];
}

export const proactiveMessagingTemplatesQuery = gql`
    query proactiveMessagingTemplates {
        proactiveMessagingTemplates(onlyEnabled: true, asHTML: true) {
            _id
            name
            messageBody1
            messageBody2
            urgent
            requiresImage
            autoCloseHours
            timingDescription
            purposeDescription
            targetAudienceDescription
        }
    }
`;

export interface SendProactiveMessageMutationResult {
    sendProactiveMessage: OperationResult;
}

export interface SendProactiveMessageMutationVariables {
    input: SendProactiveMessageInput;
}

export const sendProactiveMessageMutation = gql`
    mutation sendProactiveMessage($input: SendProactiveMessageInput!) {
        sendProactiveMessage(input: $input) {
            success
        }
    }
`;

export interface RecentLocationConversationsQueryVariables {
    locationId: string;
}
export interface RecentLocationConversationsQueryResult {
    recentLocationConversations: LocationConversationInfo[];
}

export const recentLocationConversations = gql`
    query recentLocationConversations(
        $locationId: String!
        $limit: Int
        $offset: Int
    ) {
        recentLocationConversations(
            locationId: $locationId
            limit: $limit
            offset: $offset
        ) {
            vetspireClientId
            givenName
            familyName
            preferredPhoneNumber
            optedIn
            unread
            datetime
            pets {
                id
                name
                inactive
                deceased
                sex
            }
        }
    }
`;

export interface UpdateConversationUnreadStatusMutationResult {
    updateConversationUnreadStatus: OperationResult;
}

export interface UpdateConversationUnreadStatusMutationVariables {
    input: UpdateConversationUnreadStatusInput;
}

export const updateConversationUnreadStatusMutation = gql`
    mutation updateConversationUnreadStatus(
        $input: UpdateConversationUnreadStatusInput!
    ) {
        updateConversationUnreadStatus(input: $input) {
            success
        }
    }
`;
