import { Moment } from 'moment';
import * as React from 'react';
import classnames from 'classnames';
import styles from './AppointmentLogEvents.module.scss';
import {
    APPOINTMENT_DATETIME_FORMAT,
    CancelAppointmentEvent,
    EVENT_DATETIME_FORMAT,
} from '../../types';

export const Cancel = ({
    tz,
    event,
    highlighted,
}: {
    tz: (date: string) => Moment;
    event: CancelAppointmentEvent;
    highlighted?: boolean;
}): React.ReactElement => (
    <div
        className={classnames(
            styles.textLine,
            highlighted && styles.highlighted,
        )}
    >
        <div>
            <div className={classnames(styles.bullet, styles.bgCancelled)} />
        </div>
        <div className={styles.textLeft}>
            <span className={styles.eventDatetime}>
                [{tz(event.eventDatetime).format(EVENT_DATETIME_FORMAT)}]
            </span>
            <span>
                {event.cancelName} cancelled{' '}
                {tz(event.apptDatetime).format(APPOINTMENT_DATETIME_FORMAT)}{' '}
                {event.providerName}
            </span>
        </div>
    </div>
);

export default Cancel;
