import {
    ApolloLink,
    createHttpLink,
    GraphQLRequest,
    Operation,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { getAuth } from 'firebase/auth';
import { setContext } from '@apollo/client/link/context';
import { GraphqlClientName, GraphqlClientNames } from './constants';

const defaultTemplateURL =
    process.env.REACT_APP_CLOUD_FUNCTION_API_URL_TEMPLATE;

interface GraphQLLink {
    name: GraphqlClientName;
    templateURL?: string;
    includeCredentials?: boolean;
    batch?: {
        interval: number;
        max: number;
    };
}

function getNameFormats(camelName: string): {
    camel: string;
    kebab: string;
    upperSnake: string;
} {
    const result = camelName.split(/(?=[A-Z])/);

    return {
        camel: camelName,
        kebab: result.map((s) => s.toLocaleLowerCase()).join('-'),
        upperSnake: result.map((s) => s.toLocaleUpperCase()).join('_'),
    };
}

const graphQLLinks: readonly GraphQLLink[] = GraphqlClientNames.map(
    (clientName) => {
        if (clientName === 'vetspire') {
            return {
                name: 'vetspire', //  as GraphqlClientName,
                templateURL: process.env.REACT_APP_VETSPIRE_API_URL,
                includeCredentials: true,
            };
        }

        return {
            name: clientName as GraphqlClientName,
            templateURL: defaultTemplateURL,
        };
    },
);

let firstLinkInChain: ApolloLink | undefined;

// This is for conditional batching of queries at the query level (rather than ClientName level)
let firstBatchedLinkInChain: ApolloLink | undefined;

for (const graphQLLink of graphQLLinks) {
    if (!graphQLLink.templateURL) {
        console.error(
            `No templateURL for GraphQL endpoint '${graphQLLink.name}' defined.`,
        );
    } else {
        let link: ApolloLink;

        const nameFormats = getNameFormats(graphQLLink.name);

        // check if there is an explicit URL set (e.g. REACT_APP_CREDIT_CARDS_API_URL)
        const urlTemplate =
            process.env[`REACT_APP_${nameFormats.upperSnake}_API_URL`] ||
            graphQLLink.templateURL;

        const url = urlTemplate
            .replace('{CLIENT_NAME_CAMEL}', nameFormats.camel)
            .replace('{CLIENT_NAME_KEBAB}', nameFormats.kebab);

        console.info('GraphQL endpoint: ', graphQLLink.name, url);

        // For conditional batching at query level
        const batchChainLink = new BatchHttpLink({
            uri: url,
            credentials: graphQLLink.includeCredentials ? 'include' : 'omit',
            batchInterval: 100,
            batchMax: 10,
        });

        // For ClientNames that are always batched (e.x. images)
        if (graphQLLink.batch) {
            link = new BatchHttpLink({
                uri: url,
                credentials: graphQLLink.includeCredentials
                    ? 'include'
                    : 'omit',
                batchInterval: graphQLLink.batch.interval,
                batchMax: graphQLLink.batch.max,
            });
        } else {
            link = createHttpLink({
                uri: url,
                credentials: graphQLLink.includeCredentials
                    ? 'include'
                    : 'omit',
            });
        }

        const splitter = (operation: Operation) => {
            return (
                (operation.getContext().clientName === undefined &&
                    graphQLLink.name === 'default') ||
                operation.getContext().clientName === graphQLLink.name
            );
        };

        firstLinkInChain = ApolloLink.split(splitter, link, firstLinkInChain);
        firstBatchedLinkInChain = ApolloLink.split(
            splitter,
            batchChainLink,
            firstBatchedLinkInChain,
        );
    }
}

const linkOptions = {
    uri: process.env.REACT_APP_BOND_DEFAULT_API_URL,
    credentials: 'omit',
};
const fallbackBatchLink = new BatchHttpLink({
    ...linkOptions,
    batchInterval: 100,
    batchMax: 10,
});

const authenticatedLink = setContext(
    async (
        { variables }: GraphQLRequest,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { headers: rawHeaders, clientName }: any,
    ) => {
        const headers = rawHeaders;
        const context: { [key: string]: unknown } = { headers };
        let batch = false;

        if (variables?.__noCookies === true) {
            context.fetchOptions = { credentials: 'omit' };
        }

        if (variables?.__noAuth) {
            // no need to authenticate (further)
            return context;
        }

        if (variables?.__batch) {
            batch = true;
        }

        let authToken: string | undefined;

        if (clientName === 'telehealth') {
            const auth = getAuth();
            authToken = await auth.currentUser?.getIdToken();
        } else {
            // eslint-disable-next-line import/no-cycle
            const { getValidBondAuthToken } = await import('./bondAuth');
            authToken = await getValidBondAuthToken();
        }

        if (authToken) {
            return {
                headers: {
                    ...headers,
                    authorization: authToken ? `Bearer ${authToken}` : '',
                },
                batch,
            };
        }

        return {
            batch,
        };
    },
);

const link = authenticatedLink.split(
    (operation: Operation) => operation.getContext().batch === true,
    firstBatchedLinkInChain || fallbackBatchLink,
    firstLinkInChain,
);

export default link;
