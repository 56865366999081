import * as React from 'react';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import {
    SurgeryFormsOption,
    surgeryFormsOptionsQuery,
    SurgeryFormsOptionsQueryResult,
} from 'api/bond/queries';

export type SurgeryFormsOptionsQuery = {
    loading: boolean;
    error?: ApolloError;
    surgeryFormsOptions: ReadonlyArray<SurgeryFormsOption>;
};

export default function useSurgeryFormsOptions(
    encounterId: string,
): SurgeryFormsOptionsQuery {
    const { data, loading, error } = useQuery<SurgeryFormsOptionsQueryResult>(
        surgeryFormsOptionsQuery,
        {
            variables: {
                encounterId,
            },
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );
    const [surgeryFormsOptions, setSurgeryFormsOptions] = React.useState<
        ReadonlyArray<SurgeryFormsOption>
    >([]);

    React.useEffect(() => {
        if (data?.surgeryFormsOptions) {
            setSurgeryFormsOptions(
                data.surgeryFormsOptions
                    .slice()
                    .sort((a, b) =>
                        a.renderedContent.localeCompare(b.renderedContent),
                    ),
            );
        }
    }, [data]);

    return {
        loading,
        error,
        surgeryFormsOptions,
    };
}
