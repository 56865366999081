import * as React from 'react';
import {
    Patient,
    patientDetails,
    PatientDetailsQuery,
    PatientDetailsQueryVariables,
} from 'api/clients/queries';
import { useLazyQuery } from '@apollo/client';
import { SurgeryReferral } from '@bondvet/types/surgeryReferrals';

export default function useReferralPatient(
    { patientId }: Pick<SurgeryReferral, 'patientId'>,
    patient?: Patient | null,
): Patient | null {
    const [fetch, { data }] = useLazyQuery<
        PatientDetailsQuery,
        PatientDetailsQueryVariables
    >(patientDetails, {
        fetchPolicy: 'cache-first',
    });

    React.useEffect(() => {
        if (patientId !== patient?.id) {
            fetch({ variables: { patientId } });
        }
    }, [fetch, patientId, patient?.id]);

    return React.useMemo(() => {
        if (patientId) {
            if (patientId === patient?.id) {
                return patient as Patient;
            }

            if (data?.patient?.id === patientId) {
                return data?.patient as Patient;
            }
        }

        return null;
    }, [patient, data?.patient, patientId]);
}
