import * as React from 'react';
import type { FetchResult } from '@apollo/client/link/core';
import { MutationFunctionOptions } from '@apollo/client/react/types/types';
import {
    parse,
    visit,
    Kind,
    type OperationDefinitionNode,
    type FieldNode,
    type DocumentNode,
} from 'graphql';
import { useApolloClient, gql } from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import type { OperationResult } from '@bondvet/types/common';
import type {
    CheckAppliedCouponsArguments,
    CheckAppliedEstimateCouponsArguments,
} from '@bondvet/types/vetspire';
import useVetspireSettings from './useVetspireSettings';
import useRequestInterceptor, {
    type InterceptorPayload,
    type InterceptorResponse,
} from './useRequestInterceptor';
import useBondMutation from './useBondMutation';

const CREATE_ORDER_OPERATION_NAME = 'createInvoice';
const CREATE_ESTIMATE_OPERATION_NAME = 'createEstimate';
const CREATE_ESTIMATE_FOR_ENCOUNTER_OPERATION_NAME =
    'createEstimateForEncounter';
const TIMEOUT_MS = 25000;

type CreatedOrder = {
    id: string;
    appliedCoupons?: null | readonly { id: string }[];
};
type CreateOrderMutationResult = {
    createOrder?: CreatedOrder;
    createEncounterOrder?: {
        order?: CreatedOrder;
    };
};

type CreateEstimateMutationResult = {
    createEstimate?: {
        id: string;
        appliedCoupons?: null | readonly { id: string }[];
    };
};

const checkAppliedCouponsMutation = gql`
    mutation checkAppliedCoupons($orderId: ID!) {
        checkAppliedCoupons(orderId: $orderId) {
            success
            error
        }
    }
`;

const checkAppliedEstimateCouponsMutation = gql`
    mutation checkAppliedEstimateCoupons($estimateId: ID!) {
        checkAppliedEstimateCoupons(estimateId: $estimateId) {
            success
            error
        }
    }
`;

type CheckAppliedCouponsMutationResult = {
    checkAppliedCoupons: OperationResult;
};

type CheckAppliedEstimateCouponsMutationResult = {
    checkAppliedEstimateCoupons: OperationResult;
};

/**
 * transforms the original mutation from Vetspire and
 * - makes sure that the operation name is changed
 * - we query all `appliedCoupon.id` values
 * @param query {string}
 *
 * @returns {DocumentNode} the modified mutation
 */
export function transformMutation(query: string): DocumentNode {
    return visit(parse(query), {
        OperationDefinition(operationDefinitionNode): OperationDefinitionNode {
            if (operationDefinitionNode.operation === 'mutation') {
                return {
                    ...operationDefinitionNode,
                    name: {
                        kind: Kind.NAME,
                        value: `BOND_${operationDefinitionNode.name?.value ?? CREATE_ORDER_OPERATION_NAME}`,
                    },
                };
            }

            return operationDefinitionNode;
        },
        Field(node): FieldNode {
            if (
                ['order', 'createOrder', 'createEstimate'].includes(
                    node.name?.value,
                )
            ) {
                const selections = node.selectionSet?.selections ?? [];
                const resultNode = node;

                const existingAppliedCouponsField = selections.find(
                    (selection) =>
                        selection.kind === Kind.FIELD &&
                        selection.name?.value === 'appliedCoupons',
                ) as FieldNode | undefined;

                if (existingAppliedCouponsField) {
                    // check if we already query the id
                    const appliedCouponsSelections =
                        existingAppliedCouponsField.selectionSet?.selections ??
                        [];

                    const hasIdField = appliedCouponsSelections.some(
                        (selection) =>
                            selection.kind === Kind.FIELD &&
                            selection.name?.value === 'id',
                    );

                    if (!hasIdField) {
                        const couponFieldIdx = selections.indexOf(
                            existingAppliedCouponsField,
                        );
                        // add it
                        const idField = {
                            kind: Kind.FIELD,
                            name: { kind: Kind.NAME, value: 'id' },
                        };

                        return {
                            ...resultNode,
                            selectionSet: {
                                ...resultNode.selectionSet,
                                selections: [
                                    ...selections.slice(0, couponFieldIdx),
                                    {
                                        ...existingAppliedCouponsField,
                                        selectionSet: {
                                            ...existingAppliedCouponsField.selectionSet,
                                            selections: [
                                                ...appliedCouponsSelections,
                                                idField,
                                            ],
                                        },
                                    },
                                    ...selections.slice(couponFieldIdx + 1),
                                ],
                            },
                        } as FieldNode;
                    }
                } else {
                    const appliedCouponsField = {
                        kind: Kind.FIELD,
                        name: { kind: Kind.NAME, value: 'appliedCoupons' },
                        selectionSet: {
                            kind: Kind.SELECTION_SET,
                            selections: [
                                {
                                    kind: Kind.FIELD,
                                    name: { kind: Kind.NAME, value: 'id' },
                                },
                            ],
                        },
                    };

                    return {
                        ...resultNode,
                        selectionSet: {
                            ...resultNode.selectionSet,
                            selections: [...selections, appliedCouponsField],
                        },
                    } as FieldNode;
                }
            }

            return node;
        },
    });
}

function createCheckOrderVariables(
    data: CreateOrderMutationResult | null,
): null | CheckAppliedCouponsArguments {
    const order = data?.createOrder ?? data?.createEncounterOrder?.order;

    if (!order) {
        return null;
    }

    const hasAppliedCoupons = (order.appliedCoupons?.length ?? 0) > 0;

    if (hasAppliedCoupons) {
        return { orderId: order.id };
    }

    return null;
}

function createCheckEstimateVariables(
    data: CreateEstimateMutationResult | null,
): CheckAppliedEstimateCouponsArguments | null {
    const estimate = data?.createEstimate;

    if (!estimate) {
        return null;
    }

    const hasAppliedCoupons = (estimate.appliedCoupons?.length ?? 0) > 0;

    if (hasAppliedCoupons) {
        return { estimateId: estimate.id };
    }

    return null;
}

export default function useNewOrderInterceptor() {
    const vetspireSettings = useVetspireSettings();
    const apolloClient = useApolloClient();
    const [checkAppliedCoupons] = useBondMutation<
        CheckAppliedCouponsMutationResult,
        CheckAppliedCouponsArguments
    >(checkAppliedCouponsMutation);
    const [checkAppliedEstimateCoupons] = useBondMutation<
        CheckAppliedEstimateCouponsMutationResult,
        CheckAppliedEstimateCouponsArguments
    >(checkAppliedEstimateCouponsMutation);

    const checkOrderOrEstimate = React.useCallback(
        async <
            OriginalMutationResult,
            CheckVariables extends Record<string, unknown> = Record<
                string,
                unknown
            >,
            CheckResult = unknown,
        >(
            {
                variables,
                query,
            }: Pick<InterceptorPayload, 'variables' | 'query'>,
            createCheckVariables: (
                originalMutationResult: OriginalMutationResult | null,
            ) => CheckVariables | null,
            checkCoupons: (
                options?: MutationFunctionOptions<CheckResult, CheckVariables>,
            ) => Promise<FetchResult<CheckResult>>,
        ): Promise<InterceptorResponse> => {
            const mutation = transformMutation(query);

            // currently, we just replace `window.fetch`, so we wouldn't
            // have to change the operation name. but in the future,
            // we might switch to a more robust implementation, like
            // a service worker – so let's make sure we won't still
            // intercept that operation then as well

            // 1. create that request ourselves
            const { data } = await apolloClient.mutate<OriginalMutationResult>({
                mutation,
                variables,
                context: {
                    clientName: GRAPHQL_CLIENT_NAMES.vetspire,
                },
            });

            // 2. check in the response, if there are any coupons applied
            const checkVariables = createCheckVariables(data ?? null);

            // 3. if there are, let our functions API endpoint validate those coupons
            // and remove them from the order, if necessary
            if (checkVariables) {
                await checkCoupons({ variables: checkVariables });
            }

            // with this approach, we will have Vetspire's UI show the correct status, because
            // it is waiting on the intercepted request and _then_ loads the order details
            return {
                runOriginalRequest: false,
                response: data,
            };
        },
        [apolloClient],
    );

    const onCreateOrder = React.useCallback(
        (
            interceptorPayload: InterceptorPayload,
        ): Promise<InterceptorResponse> => {
            return checkOrderOrEstimate(
                interceptorPayload,
                createCheckOrderVariables,
                checkAppliedCoupons,
            );
        },
        [checkOrderOrEstimate, checkAppliedCoupons],
    );

    const onCreateEstimate = React.useCallback(
        (
            interceptorPayload: InterceptorPayload,
        ): Promise<InterceptorResponse> => {
            return checkOrderOrEstimate(
                interceptorPayload,
                createCheckEstimateVariables,
                checkAppliedEstimateCoupons,
            );
        },
        [checkOrderOrEstimate, checkAppliedEstimateCoupons],
    );

    useRequestInterceptor(
        CREATE_ORDER_OPERATION_NAME,
        onCreateOrder,
        undefined,
        !vetspireSettings.interceptCreateOrder,
        TIMEOUT_MS,
    );

    useRequestInterceptor(
        CREATE_ESTIMATE_OPERATION_NAME,
        onCreateEstimate,
        undefined,
        !vetspireSettings.interceptCreateOrder,
        TIMEOUT_MS,
    );

    useRequestInterceptor(
        CREATE_ESTIMATE_FOR_ENCOUNTER_OPERATION_NAME,
        onCreateEstimate,
        undefined,
        !vetspireSettings.interceptCreateOrder,
        TIMEOUT_MS,
    );
}
