import * as React from 'react';
import Select, { MultiValue } from 'react-select';
import useTranslate from 'hooks/useTranslate';
import styles from 'components/LookupSelect/LookupSelect.module.scss';
import { ReactComponent as TaskCategoryIcon } from 'assets/taskCategory.svg';
import { ExternalReferralReason } from '@bondvet/types/externalReferrals';

interface ReasonsFilterProps {
    selectedReasons: ExternalReferralReason[];
    setSelectedReasons: (reasons: ExternalReferralReason[]) => void;
    options: ReadonlyArray<ExternalReferralReason>;
}

const ReasonsFilter: React.FunctionComponent<ReasonsFilterProps> = ({
    options,
    selectedReasons,
    setSelectedReasons,
}) => {
    const translate = useTranslate();
    return (
        <Select
            isMulti
            name="externalReferralsReasons"
            options={options}
            value={selectedReasons}
            className={styles.lookup}
            classNamePrefix="lookup-select"
            onChange={(value: MultiValue<ExternalReferralReason>) =>
                setSelectedReasons((value || []) as ExternalReferralReason[])
            }
            getOptionValue={(category) => category._id}
            getOptionLabel={(category) => category.name}
            placeholder={
                <div className={styles.placeholder}>
                    <TaskCategoryIcon className={styles.placeholderIcon} />
                    {translate(
                        'vetspireExtension.externalReferrals.reasonsPlaceholder',
                    )}
                </div>
            }
        />
    );
};
export default ReasonsFilter;
