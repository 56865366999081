import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import { DateFilterType, DateStats } from './types';

import styles from './DateFilter.module.scss';

interface DateFilterItemProps {
    active: boolean;
    dateFilterType: DateFilterType;
    showBullet?: boolean;
    counter?: number | string;
    translatePrefix: string;
    onClick: (dateFilterType: DateFilterType) => void;
}

const DateFilterItem: React.FunctionComponent<DateFilterItemProps> = ({
    active,
    dateFilterType,
    showBullet = true,
    counter,
    translatePrefix,
    onClick,
}) => {
    const translate = useTranslate();
    return (
        <button
            type="button"
            className={classnames(styles.item, {
                [styles.active]: active,
            })}
            onClick={() => onClick(dateFilterType)}
        >
            {showBullet && (
                <div
                    className={classnames(
                        styles.bullet,
                        styles[`bg-${dateFilterType}`],
                    )}
                />
            )}
            {translate(`${translatePrefix}.${dateFilterType}`)}
            {counter !== undefined && (
                <div
                    className={classnames(
                        styles.badge,
                        styles[`bg-${dateFilterType}`],
                    )}
                >
                    {counter}
                </div>
            )}
        </button>
    );
};

interface DateFilterProps {
    currentDateFilterType: DateFilterType;
    dateStats: Partial<DateStats>;
    translatePrefix: string;
    showFuture?: boolean;
    onChange: (dueDate: DateFilterType) => void;
}

const DateFilter: React.FunctionComponent<DateFilterProps> = ({
    currentDateFilterType,
    dateStats,
    translatePrefix,
    showFuture = true,
    onChange,
}) => {
    return (
        <div
            className={classnames(styles.container, {
                [styles.columns3]: !showFuture,
                [styles.columns4]: showFuture,
            })}
        >
            <DateFilterItem
                active={currentDateFilterType === DateFilterType.all}
                dateFilterType={DateFilterType.all}
                showBullet={false}
                counter={dateStats.all}
                translatePrefix={translatePrefix}
                onClick={onChange}
            />
            <DateFilterItem
                active={currentDateFilterType === DateFilterType.today}
                dateFilterType={DateFilterType.today}
                counter={dateStats.today}
                translatePrefix={translatePrefix}
                onClick={onChange}
            />
            <DateFilterItem
                active={currentDateFilterType === DateFilterType.past}
                dateFilterType={DateFilterType.past}
                counter={dateStats.past}
                translatePrefix={translatePrefix}
                onClick={onChange}
            />
            {showFuture && (
                <DateFilterItem
                    active={currentDateFilterType === DateFilterType.future}
                    dateFilterType={DateFilterType.future}
                    counter={dateStats.future}
                    translatePrefix={translatePrefix}
                    onClick={onChange}
                />
            )}
        </div>
    );
};

export default DateFilter;
