import * as React from 'react';
import { useQuery } from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { GraphQLSurgeryAppointment } from '@bondvet/types/surgeryReferrals';
import sortBy from 'lodash/sortBy';
import {
    surgeryAppointments,
    SurgeryAppointmentsQuery,
    SurgeryAppointmentsQueryVariables,
} from '../api/queries';

function filterSurgeryAppointments({
    linkedReferrals,
    hidden,
}: GraphQLSurgeryAppointment): boolean {
    return !hidden && (!linkedReferrals || linkedReferrals.length === 0);
}

type SurgeryAppointments = {
    appointments: ReadonlyArray<GraphQLSurgeryAppointment>;
    isLoading: boolean;
};

export default function useSurgeryAppointments(
    locationId: string | null,
    selectedDay: moment.Moment,
): SurgeryAppointments {
    const [appointments, setAppointments] = React.useState<
        ReadonlyArray<GraphQLSurgeryAppointment>
    >([]);

    const to = React.useMemo(
        () => selectedDay.clone().add(7, 'day'),
        [selectedDay],
    );

    const { data, loading } = useQuery<
        SurgeryAppointmentsQuery,
        SurgeryAppointmentsQueryVariables
    >(surgeryAppointments, {
        fetchPolicy: 'cache-and-network',
        variables: {
            locationId: locationId || undefined,
            from: selectedDay?.format('YYYY-MM-DD'),
            to: to?.format('YYYY-MM-DD'),
        },
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    React.useEffect(() => {
        if (data?.surgeryAppointments) {
            setAppointments(
                sortBy(
                    data.surgeryAppointments
                        .slice(0)
                        .filter(filterSurgeryAppointments),
                    ['start'],
                ),
            );
        } else {
            setAppointments([]);
        }
    }, [data?.surgeryAppointments]);

    return { appointments, isLoading: loading };
}
