import * as React from 'react';
import Select, { MultiValue } from 'react-select';
import useTranslate from 'hooks/useTranslate';
import styles from 'components/LookupSelect/LookupSelect.module.scss';
import { Category, InfoPdfCategory } from 'api/infoPdfs/queries';
import { ReactComponent as TaskCategoryIcon } from 'assets/taskCategory.svg';

interface AppointmentLogUserTypeSelectProps {
    selectedInfoPdfCategories: InfoPdfCategory[];
    setSelectedInfoPdfCategories: (infoPdf: InfoPdfCategory[]) => void;
    options: ReadonlyArray<Category>;
}

const PdfCategoryFilter: React.FunctionComponent<
    AppointmentLogUserTypeSelectProps
> = ({ options, selectedInfoPdfCategories, setSelectedInfoPdfCategories }) => {
    const translate = useTranslate();

    return (
        <Select
            isMulti
            name="infoPdfs"
            options={options}
            value={selectedInfoPdfCategories}
            className={styles.lookup}
            classNamePrefix="lookup-select"
            onChange={(value: MultiValue<InfoPdfCategory>) =>
                setSelectedInfoPdfCategories((value || []) as InfoPdfCategory[])
            }
            getOptionValue={(category: InfoPdfCategory) => category._id}
            getOptionLabel={(category) => category.name}
            placeholder={
                <div className={styles.placeholder}>
                    <TaskCategoryIcon className={styles.placeholderIcon} />
                    {translate(
                        'vetspireExtension.infoPDFs.categoriesPlaceholder',
                    )}
                </div>
            }
        />
    );
};
export default PdfCategoryFilter;
