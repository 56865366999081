import { useLazyQuery } from '@apollo/client';
import {
    patientDetails,
    PatientDetailsQuery,
    PatientDetailsQueryVariables,
} from 'api/clients/queries';

export default function useLazyPatientDetailsQuery() {
    return useLazyQuery<PatientDetailsQuery, PatientDetailsQueryVariables>(
        patientDetails,
        {
            fetchPolicy: 'cache-and-network',
        },
    );
}
