import * as React from 'react';
import classnames from 'classnames';
import YearSelect from 'components/YearSelect';
import useTimezone from 'hooks/useTimezone';
import moment from 'moment-timezone';
import TrophyIcon from 'assets/trophy.png';
import useTranslate from 'hooks/useTranslate';
import useImpactScoresV2 from 'components/ProviderPayment/hooks/useImpactScoresV2';
import { CircularProgress } from '@mui/material';
import styles from './YearSubTab.module.scss';

interface YearSubTabProps {
    vetspireProviderId: string;
}

const YearSubTab: React.FunctionComponent<YearSubTabProps> = ({
    vetspireProviderId,
}) => {
    const translate = useTranslate();
    const timezone = useTimezone();
    const thisYear = moment.tz(timezone).year();
    const [selectedYear, setSelectedYear] = React.useState(thisYear);
    const { selectedRange, loading } = useImpactScoresV2(
        vetspireProviderId,
        `${selectedYear}-01-01`,
        moment(`${selectedYear}-01-01`).endOf('year').format('YYYY-MM-DD'),
    );

    const content = React.useMemo(() => {
        if (loading) {
            return (
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            );
        }

        return (
            <div className={styles.flyoutBlock}>
                <div className={styles.collectedContainer}>
                    <img
                        src={TrophyIcon}
                        alt="Trophy Icon"
                        className={styles.trophyIcon}
                    />
                    <span
                        className={classnames(styles.price, {
                            [styles.grayedOut]: !selectedRange?.hasPayout,
                        })}
                    >
                        {selectedRange.payout}
                    </span>
                    <span className={styles.title}>
                        {translate(
                            'vetspireExtension.providerPayment.impactBonus.year.title',
                            {
                                year: selectedYear,
                            },
                        )}
                    </span>
                    <span className={styles.subtitle}>
                        {translate(
                            `vetspireExtension.providerPayment.impactBonus.year.subtitle.${
                                thisYear === selectedYear
                                    ? 'thisYear'
                                    : 'lastYear'
                            }.${
                                selectedRange?.hasPayout
                                    ? 'hasPayout'
                                    : 'hasNoPayout'
                            }`,
                        )}
                    </span>
                    {/* Uncommenting out for now (09.11.2022), because the feature is not fully specked out
                     {selectedRange.hasPayout && (
                        <div className={styles.projectedEarnings}>
                            <img src={CoinsIcon} alt="coins" />
                            <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        'vetspireExtension.providerPayment.impactBonus.year.projectedEarnings',
                                        {
                                            year: thisYear,
                                            price: `<strong>${selectedRange.projectedPayment}</strong>`,
                                        },
                                    ).toString(),
                                }}
                            />
                        </div>
                     )} */}
                </div>
            </div>
        );
    }, [
        loading,
        selectedRange?.hasPayout,
        selectedRange.payout,
        selectedYear,
        thisYear,
        translate,
    ]);

    return (
        <>
            <div className={classnames(styles.flyoutBlock, styles.yearSelect)}>
                <YearSelect
                    year={selectedYear}
                    minYear={0}
                    maxYear={thisYear}
                    onChange={(newYear) => setSelectedYear(newYear)}
                />
            </div>
            {content}
        </>
    );
};

export default YearSubTab;
