import {
    MESSAGE_SOURCE_NAVIGATION,
    MESSAGE_TARGET_TWEAKS,
    sendBackgroundMessage,
} from 'lib/backgroundMessages';
import * as React from 'react';
import { gql } from '@apollo/client';
import type { UpdateExpandedAccessSlotsArguments } from '@bondvet/intraday-capacity/extendedAccessSlots/types';
import { endOfMonth, format, parse } from 'date-fns';
import useSchedulingMutation from 'hooks/useSchedulingMutation';
import type { OperationResult } from '@bondvet/types';
import { DATE_FORMAT, type UpdateExpandedAccessSlots } from '../types';
import useExpandedAccessSlotsInDateRange, {
    refetchQueries,
    type ExpandedAccessSlots,
} from './useExpandedAccessSlotsInDateRange';

const updateExpandedAccessSlotsMutation = gql`
    mutation updateExpandedAccessSlotsMutation(
        $vetspireLocationId: ID!
        $date: DateString!
        $providerId: String!
        $availableSlots: [String!]!
    ) {
        updateExpandedAccessSlots(
            vetspireLocationId: $vetspireLocationId
            date: $date
            providerId: $providerId
            availableSlots: $availableSlots
        ) {
            success
        }
    }
`;

type UpdateExpandedAccessSlotsMutationResult = {
    updateExpandedAccessSlotsMutation: OperationResult;
};

export type { ExpandedAccessSlots };

export type UseExpandedAccessSlots = {
    expandedAccessSlots: ExpandedAccessSlots;
    updateExpandedAccessSlots: UpdateExpandedAccessSlots;
    loading: boolean;
};

function sendSlotsUpdatedMessage() {
    sendBackgroundMessage(
        MESSAGE_SOURCE_NAVIGATION,
        [MESSAGE_TARGET_TWEAKS],
        'expandedAccessSlotsUpdated',
    );
}

export default function useExpandedAccessSlots(
    vetspireLocationId: string,
    month: string,
): UseExpandedAccessSlots {
    const start = parse(`${month}-01`, DATE_FORMAT, new Date());
    const end = endOfMonth(start);

    const { loading, expandedAccessSlots } = useExpandedAccessSlotsInDateRange(
        vetspireLocationId,
        format(start, DATE_FORMAT),
        format(end, DATE_FORMAT),
    );

    const [update, { loading: updating }] = useSchedulingMutation<
        UpdateExpandedAccessSlotsMutationResult,
        UpdateExpandedAccessSlotsArguments
    >(updateExpandedAccessSlotsMutation, {
        refetchQueries,
    });

    const updateExpandedAccessSlots = React.useCallback(
        async (
            date: string,
            providerId: string,
            availableSlots: readonly string[],
        ): Promise<void> => {
            await update({
                variables: {
                    vetspireLocationId,
                    date,
                    providerId,
                    availableSlots,
                },
            });
            sendSlotsUpdatedMessage();
        },
        [update, vetspireLocationId],
    );

    return {
        expandedAccessSlots,
        updateExpandedAccessSlots,
        loading: loading || updating,
    };
}
