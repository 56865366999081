import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import classnames from 'classnames';
import moment from 'moment-timezone';
import useTimezone from 'hooks/useTimezone';
import { ReactComponent as TaskCategoryIcon } from 'assets/taskCategory.svg';
import { openPatientTasks } from 'lib/vetspireActions';
import { DecoratedTask } from '../../types';

import styles from './TaskItem.module.scss';
import dueDateStyles from '../../DueDate.module.scss';

interface TaskItemProps {
    decoratedTask: DecoratedTask;
}

const TaskItem: React.FunctionComponent<TaskItemProps> = ({
    decoratedTask,
}) => {
    const translate = useTranslate();
    const timezone = useTimezone();

    const dueDate = moment.tz(decoratedTask.dueDate, timezone);

    const today = moment
        .tz(timezone)
        .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const insertedAtDay = moment
        .tz(decoratedTask.insertedAt, timezone)
        .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    const daysAgo = today.diff(insertedAtDay, 'days');

    let insertedAtTag = 'vetspireExtension.tasks.taskCreated';
    if (daysAgo === 0) {
        insertedAtTag = 'vetspireExtension.tasks.taskCreatedToday';
    } else if (daysAgo === 1) {
        insertedAtTag = 'vetspireExtension.tasks.taskCreatedYesterday';
    }

    return (
        <div
            className={styles.container}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => {
                if (decoratedTask.patient?.client?.id) {
                    openPatientTasks(
                        decoratedTask.patient?.client?.id,
                        decoratedTask.patient?.id,
                    );
                }
            }}
        >
            <div
                className={classnames(
                    styles.dueDateColorColumn,
                    dueDateStyles[`bg-${decoratedTask.dateFilterType}`],
                )}
            />
            <div className={styles.content}>
                <div className={styles.header}>
                    <div
                        className={classnames(
                            styles.headerLeft,
                            styles.smallText,
                        )}
                    >
                        {decoratedTask.patient &&
                            `${decoratedTask.patient?.client?.name} > ${decoratedTask.patient?.name}`}
                    </div>
                    <div
                        className={classnames(
                            styles.headerRight,
                            styles.smallText,
                        )}
                    >
                        {dueDate.format('MMM D, YYYY')}
                    </div>
                </div>
                <div className={styles.textBlock}>
                    <div className={styles.text}>{decoratedTask.text}</div>
                    <div className={styles.smallText}>
                        {translate(insertedAtTag, {
                            daysAgo,
                            name: decoratedTask.creator?.name || '',
                        })}
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.footerItem}>
                        {decoratedTask.taskCategory && (
                            <div
                                className={classnames(
                                    styles.footerItem,
                                    styles.smallText,
                                )}
                            >
                                <TaskCategoryIcon
                                    className={styles.taskCategoryIcon}
                                />
                                <div className={styles.footerText}>
                                    {decoratedTask.taskCategory?.name || ''}
                                </div>
                            </div>
                        )}
                    </div>
                    {decoratedTask.assignees.map((assignee) => (
                        <div
                            key={assignee.id}
                            className={classnames(
                                styles.footerItem,
                                styles.smallText,
                            )}
                        >
                            <PersonOutlineIcon
                                fontSize="small"
                                classes={{
                                    root: styles.icon,
                                }}
                            />

                            <div className={styles.footerText}>
                                {assignee.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TaskItem;
