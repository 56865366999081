import { loadStripe } from '@stripe/stripe-js';
import * as React from 'react';
import type { Stripe } from '@stripe/stripe-js';

let STRIPE: Stripe | null = null;

export default function useStripe(): Stripe | null {
    const [stripe, setStripe] = React.useState<Stripe | null>(STRIPE);

    React.useEffect(() => {
        if (!STRIPE) {
            loadStripe(
                process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
            ).then((stripeApi) => {
                STRIPE = stripeApi;
                setStripe(stripeApi);
            });
        }
    }, []);

    return stripe;
}
