import * as React from 'react';
import classnames from 'classnames';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import styles from './SearchInput.module.scss';

interface SearchInputProps {
    value: string;
    className?: string;
    onChange: (value: string) => void;
    placeholder?: string;
    onFocus?: () => void;
    onBlur?: () => void;
}

const SearchInput: React.FunctionComponent<SearchInputProps> = ({
    value,
    className = '',
    onChange,
    placeholder = '',
    onFocus,
    onBlur,
}) => {
    return (
        <div className={classnames(styles.container, className)}>
            <SearchOutlinedIcon
                fontSize="inherit"
                classes={{ root: styles.icon }}
            />
            <input
                className={styles.filterText}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </div>
    );
};

export default SearchInput;
