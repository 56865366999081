import * as React from 'react';
import { gql } from '@apollo/client';
import { initializeApp } from 'firebase/app';

import {
    Auth,
    connectAuthEmulator,
    getAuth,
    onAuthStateChanged,
    signInWithCustomToken,
    signOut,
    User,
} from 'firebase/auth';

import {
    collection,
    connectFirestoreEmulator,
    doc,
    DocumentReference,
    getFirestore,
    CollectionReference,
    DocumentData,
} from '@firebase/firestore';

import { FirebaseContextData, FirebaseContextType } from './context';
import useAuthMutation from '../useAuthMutation';

export interface CreateFirebaseTokenResult {
    createFirebaseToken: string;
}

const createFirebaseTokenMutation = gql`
    mutation createFirebaseToken {
        createFirebaseToken
    }
`;

export default function useFirebaseContextHandler(): FirebaseContextType {
    const [{ firebase, user, isInitializing, isInitialized }, setValue] =
        React.useState<FirebaseContextData>({
            firebase: null,
            user: null,
            isInitializing: false,
            isInitialized: false,
            getCollection: () => {
                // eslint-disable-next-line no-promise-executor-return
                return new Promise((resolve) => resolve(null));
            },
            getDocRef: () => {
                // eslint-disable-next-line no-promise-executor-return
                return new Promise((resolve) => resolve(null));
            },
        });

    const [firebaseToken, setFirebaseToken] = React.useState<string | null>(
        null,
    );
    const [createFirebaseToken] = useAuthMutation<CreateFirebaseTokenResult>(
        createFirebaseTokenMutation,
    );

    React.useEffect(() => {
        const app = initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId:
                process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        });
        setValue((prev) => ({
            ...prev,
            firebase: app,
        }));
    }, []);

    function connectToEmulatorInDevelopment(auth: Auth) {
        if (process.env.REACT_APP_ENV === 'develop') {
            console.info('connecting to auth emulator');
            connectAuthEmulator(auth, 'http://localhost:9099', {
                disableWarnings: true,
            });
        }
    }

    const signIntoFirestore = React.useCallback(
        async (customToken?: string) => {
            let authToken = customToken || firebaseToken;

            if (!authToken) {
                const result = await createFirebaseToken();
                authToken = result.data?.createFirebaseToken || null;
                if (authToken) {
                    setFirebaseToken(authToken);
                }
            }

            if (authToken) {
                const auth = getAuth();
                await signInWithCustomToken(auth, authToken);
            }
        },
        [createFirebaseToken, firebaseToken],
    );

    const initialize = React.useCallback(() => {
        if (!user) {
            signIntoFirestore().then();
        }
    }, [signIntoFirestore, user]);

    React.useEffect(() => {
        if (!user && !isInitializing) {
            setValue((prev) => ({
                ...prev,
                isInitializing: true,
            }));

            initialize();
        }
    }, [user, initialize, isInitializing]);

    const getCollection = React.useCallback(
        async <T extends DocumentData>(collectionName: string) => {
            if (firebase) {
                await signIntoFirestore();
                if (firebaseToken) {
                    const db = getFirestore();
                    return collection(
                        db,
                        collectionName,
                    ) as CollectionReference<T>;
                }
            }
            return null;
        },
        [firebase, firebaseToken, signIntoFirestore],
    );

    const getDocRef = React.useCallback(
        async <T extends DocumentData>(
            collectionName: string,
            docId: string,
        ) => {
            if (firebase) {
                await signIntoFirestore();
                if (firebaseToken) {
                    const db = getFirestore();
                    return doc(
                        db,
                        collectionName,
                        docId,
                    ) as DocumentReference<T>;
                }
            }
            return null;
        },
        [firebase, firebaseToken, signIntoFirestore],
    );

    React.useEffect(() => {
        const auth = getAuth();

        connectToEmulatorInDevelopment(auth);
        const unsubscribe = onAuthStateChanged(auth, (newUser: User | null) => {
            if (newUser) {
                setValue((prev) => ({
                    ...prev,
                    user: newUser,
                    isInitializing: false,
                    isInitialized: true,
                }));
            } else {
                setValue((prev) => ({
                    ...prev,
                    user: null,
                }));
            }
        });

        return () => unsubscribe();
    }, []);

    async function signOutOfFirebase() {
        const auth = getAuth();
        await signOut(auth);
    }

    React.useEffect(() => {
        const firestore = getFirestore();

        if (process.env.REACT_APP_ENV === 'develop') {
            connectFirestoreEmulator(firestore, 'localhost', 8080);
        }
    }, []);

    return {
        firebase,
        signIntoFirestore,
        signOutOfFirebase,
        user,
        isInitializing,
        isInitialized,
        getCollection,
        getDocRef,
    };
}
