import { UKGSkill } from '@bondvet/types/ukg';
import { gql } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import useSchedulingQuery from 'hooks/useSchedulingQuery';

const ukgSkillsQuery = gql`
    query ukgSkills {
        ukgSkills {
            _id
            name
            schedulable
        }
    }
`;

export interface UKGSkillsQueryResult {
    ukgSkills: ReadonlyArray<UKGSkill>;
}

type UKGSkills = {
    loading: boolean;
    error?: ApolloError;
    ukgSkills: ReadonlyArray<UKGSkill>;
    refetch: () => void;
};

export default function useUKGSkills(): UKGSkills {
    const { data, loading, error, refetch } =
        useSchedulingQuery<UKGSkillsQueryResult>(ukgSkillsQuery, {
            fetchPolicy: 'cache-first',
        });

    return {
        loading,
        error,
        ukgSkills: (data?.ukgSkills || [])
            .slice(0)
            .sort((a, b) => b.name.localeCompare(a.name)),
        refetch,
    };
}
