import * as React from 'react';
import { gql } from '@apollo/client';

import {
    ConversationalSettings,
    defaultConversationalSettings,
} from '@bondvet/types/textMessaging';

import useTextMessagingQuery from 'hooks/useTextMessagingQuery';

const conversationalTextingSettingsQuery = gql`
    query conversationalSettings {
        conversationalSettings {
            goLiveDate
            showMessagesForHours
            faqLinks {
                title
                url
            }
        }
    }
`;

type RelevantConversationalSettings = Pick<
    ConversationalSettings,
    'goLiveDate' | 'faqLinks' | 'showMessagesForHours'
>;
interface TextMessagingSettingsQueryResult {
    conversationalSettings: RelevantConversationalSettings;
}

interface UseConversationalTextMessagingSettings {
    loading: boolean;
    conversationalTextingSettings: RelevantConversationalSettings;
}

export default function useConversationalTextMessagingSettings(): UseConversationalTextMessagingSettings {
    const [
        conversationalTextingSettings,
        setConversationalTextMessagingSettings,
    ] = React.useState<RelevantConversationalSettings>(
        defaultConversationalSettings,
    );

    const [loading, setLoading] = React.useState(true);

    useTextMessagingQuery<TextMessagingSettingsQueryResult>(
        conversationalTextingSettingsQuery,
        {
            fetchPolicy: 'cache-and-network',
            onCompleted: (result) => {
                const newConversationalSettings: RelevantConversationalSettings =
                    {
                        goLiveDate: result.conversationalSettings.goLiveDate
                            ? new Date(result.conversationalSettings.goLiveDate)
                            : defaultConversationalSettings.goLiveDate,
                        faqLinks: result.conversationalSettings.faqLinks ?? [],
                        showMessagesForHours:
                            result.conversationalSettings
                                .showMessagesForHours ??
                            defaultConversationalSettings.showMessagesForHours,
                    };

                setConversationalTextMessagingSettings(
                    newConversationalSettings,
                );

                setLoading(false);
            },
        },
    );

    return {
        loading,
        conversationalTextingSettings,
    };
}
