import * as React from 'react';
import AddCreditMemo from './components/AddCreditMemo';
import CreditMemos from './components/CreditMemos';
import useCreditMemos from './hooks/useCreditMemos';

export type CreditsProps = {
    clientId: string;
};

function Credits({ clientId }: CreditsProps): React.ReactElement {
    const {
        credits,
        providers,
        addCredit,
        addPatientTag,
        isLoading,
        currentTotal,
        updateCurrentTotal,
        isPolling,
    } = useCreditMemos(clientId);

    return (
        <>
            <AddCreditMemo
                clientId={clientId}
                addCredit={addCredit}
                addPatientTag={addPatientTag}
                isLoading={isLoading}
                currentTotal={currentTotal}
                updateCurrentTotal={updateCurrentTotal}
            />
            <CreditMemos
                providers={providers}
                credits={credits}
                isLoading={isLoading}
                isPolling={isPolling}
            />
        </>
    );
}

export default Credits;
