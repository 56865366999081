import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import CircularProgress from '@mui/material/CircularProgress';
import GeneralError from 'components/GeneralError/GeneralError';
import ActionButton from 'components/ActionButton';
import Alert from 'components/Alert';
import { UploadAndSendSurgeryForm } from '../../hooks/useUploadAndSendSurgeryForm';

import styles from './Finished.module.scss';
import surgeryFormsStyles from '../../SurgeryForms.module.scss';

interface FinishedFormProps {
    uploadAndSendSurgeryFormMutation: UploadAndSendSurgeryForm;
    onClose: () => void;
}

const Finished: React.FunctionComponent<FinishedFormProps> = ({
    uploadAndSendSurgeryFormMutation,
    onClose,
}) => {
    const translate = useTranslate();

    if (uploadAndSendSurgeryFormMutation.loading) {
        return (
            <div className={styles.loading}>
                <CircularProgress />
            </div>
        );
    }

    if (uploadAndSendSurgeryFormMutation.error) {
        return (
            <GeneralError message={uploadAndSendSurgeryFormMutation.error} />
        );
    }

    return (
        <div
            className={classnames(
                styles.flyoutBlock,
                styles.noHorizontal,
                styles.noVertical,
                styles.container,
            )}
        >
            <div className={styles.title}>
                {translate('vetspireExtension.surgeryForms.recheck')}
            </div>

            <Alert variant="success" className={styles.finished}>
                {translate('vetspireExtension.surgeryForms.finished')}
            </Alert>
            <div
                className={classnames(
                    styles.flyoutBlock,
                    styles.noMargin,
                    styles.padding,
                    surgeryFormsStyles.actions,
                )}
            >
                <div />
                <div />
                <ActionButton
                    onClick={onClose}
                    className={surgeryFormsStyles.close}
                >
                    {translate('vetspireExtension.surgeryForms.actions.close')}
                </ActionButton>
            </div>
        </div>
    );
};

export default Finished;
