export const PUBSUB_TOPIC_CUSTOMERIO_CLEANUP = 'customerio-cleanUp';
export const PUBSUB_TOPIC_CUSTOMERIO_SEND = 'customerio-send';

export const MERGE_CONVERTED_LEADS = 'mergeConvertedLeads';
export const REMOVE_INVALID_SUBSCRIBERS = 'removeInvalidSubscribers';

export const MESSAGE_CONTENT_COLLECTION_NAME = 'communication.messageContents';

/**
 * @ignore
 * Customer.io API calls
 */
export const ADD_CLIENT = 'addClient';
export const DELETE_CLIENT = 'deleteClient';
export const TRACK_EVENT = 'trackEvent';
export const TRACK_ANONYMOUS_EVENT = 'trackAnonymousEvent';
export const MERGE_LEAD = 'mergeLead';
export const MERGE_PEOPLE = 'mergePeople';

/**
 * augmentation
 */
export const PUBSUB_TOPIC_CUSTOMERIO_AUGMENTATION = 'customerio-augmentation';

export const EXPORT_APPOINTMENTS = 'exportAppointments';

export const EXPORT_CLIENTS = 'exportClients';

export const EXPORT_CLIENTS_ACTIVE = 'exportClientsActive';

export const EXPORT_PROMO_CODES = 'exportPromoCodes';

export const EXPORT_PROMO_CODES_LOGS = 'exportPromoCodesLogs';

export const EXPORT_PROMO_CODE_REFERRAL_LOGS = 'exportPromoCodeReferralLogs';

export const EXPORT_PROMO_CODE_REFERRAL_STATS = 'exportPromoCodeReferralStats';

export const EXPORT_PROMO_CODE_STATS = 'exportPromoCodeStats';

export const EXPORT_LEADS = 'exportLeads';
export const EXPORT_LEAD_PETS = 'exportLeadPets';

export const EXPORT_DUPLICATE_PHONE_NUMBERS = 'exportDuplicatePhoneNumbers';

export const EXPORT_LOCATIONS = 'exportLocations';

export const EXPORT_MOST_RECENT_EXAMS = 'exportMostRecentExams';

export const EXPORT_NPS_SCORES = 'exportNpsScores';

export const EXPORT_PATIENT_PLANS = 'exportPatientPlans';

export const EXPORT_PETS = 'exportPets';

export const EXPORT_SUBSCRIPTION_STATUS = 'exportSubscriptionStatus';

export const EXPORT_VETSPIRE_CLIENT_DATA = 'exportVetspireClientData';
export const EXPORT_VETSPIRE_PERSONAL_CLIENT_DATA =
    'exportVetspirePersonalClientData';
export const EXPORT_VETSPIRE_CLIENT_ADDRESSES = 'exportVetspireClientAddresses';

export const SYNC_USER_ALIASES = 'syncUserAliases';

/**
 * migration from Customer.io to Braze
 */
export const TRIGGER_SETTINGS_PATH = 'communication/triggers';

export const LEAD_IMPORTS_COLLECTION_NAME = 'leads.imports';
export const LEAD_IMPORT_ERRORS_COLLECTION_NAME = 'leads.importErrors';

export const LEAD_IMPORT_INVALID_ROWS_COLLECTION_NAME =
    'leads.invalidImportRows';

export const LEAD_IMPORTS_STORAGE_PATH = 'leads/imports';

export const TRIGGER_APPOINTMENT_SURVEY_UNEXPECTED_RESPONSE =
    'appointment_survey_sms_unexpected_response';
export const TRIGGER_APPOINTMENT_SURVEY_LOW_SCORE =
    'appointment_survey_sms_low_score_response';

export const TRIGGER_APPOINTMENT_SURVEY_HIGH_SCORE =
    'appointment_survey_sms_high_score_response';

export const TRIGGER_APPOINTMENT_SURVEY_MIDDLE_SCORE =
    'appointment_survey_sms_middle_score_response';

/**
 * Braze Payload Tracking and storing
 */

export const BRAZE_PAYLOAD_DATA_COLLECTION_NAME = 'braze.payload';
