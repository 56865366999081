import * as React from 'react';
import { ProgressStepType } from '../../types';
import ProgressStep from '../ProgressStep';

import styles from './ProgressSteps.module.scss';

interface ProgressStepsProps {
    currentProgressStepType: ProgressStepType;
    enabledSteps: ProgressStepType[];
    onChange: (progressStepType: ProgressStepType) => void;
}

const ProgressSteps: React.FunctionComponent<ProgressStepsProps> = ({
    currentProgressStepType,
    enabledSteps,
    onChange,
}) => {
    return (
        <div className={styles.container}>
            <ProgressStep
                active={currentProgressStepType === ProgressStepType.selectForm}
                enabled={enabledSteps.includes(ProgressStepType.selectForm)}
                progressStepType={ProgressStepType.selectForm}
                onClick={onChange}
            />
            <ProgressStep
                active={currentProgressStepType === ProgressStepType.medication}
                enabled={enabledSteps.includes(ProgressStepType.medication)}
                progressStepType={ProgressStepType.medication}
                onClick={onChange}
            />
            <ProgressStep
                active={currentProgressStepType === ProgressStepType.recheck}
                enabled={enabledSteps.includes(ProgressStepType.recheck)}
                progressStepType={ProgressStepType.recheck}
                onClick={onChange}
            />
        </div>
    );
};

export default ProgressSteps;
