import { gql } from '@apollo/client';

export interface Location {
    id: string;
    name: string;
    isActive: boolean;
    address: string;
    phoneNumber: string;
}

export interface LocationsQueryResult {
    locations: ReadonlyArray<Location>;
}

export const locationsQuery = gql`
    query {
        locations {
            id
            name
            isActive
            address
            phoneNumber
        }
    }
`;
