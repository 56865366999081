import { gql } from '@apollo/client';
import { PatientShortInfo, ProviderShortInfo } from '../types';

export interface Task {
    id: string;
    text: string;
    dueDate: string;
    creator: ProviderShortInfo;
    assignees: ReadonlyArray<ProviderShortInfo>;
    done: boolean;
    deleted: boolean;
    insertedAt: string;
    taskCategory: TaskCategory;
    patient?: PatientShortInfo;
}

export interface TasksQueryResult {
    tasks: ReadonlyArray<Task>;
}

export const tasksQuery = gql`
    query {
        tasks(done: false) {
            id
            text
            creator {
                id
                name
            }
            assignees {
                id
                name
            }
            done
            deleted
            insertedAt
            dueDate
            taskCategory {
                id
                name
            }
            patient {
                id
                name
                client {
                    id
                    name
                }
            }
        }
    }
`;

export interface TaskCategory {
    id: string;
    name: string;
}

export interface TasksCategoriesQueryResult {
    taskCategories: Array<TaskCategory>;
}

export const taskCategoriesQuery = gql`
    query {
        taskCategories {
            id
            name
        }
    }
`;
