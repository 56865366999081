import Alert from 'components/Alert';
import * as React from 'react';
import classnames from 'classnames';
import { isBefore } from 'date-fns';
import Typography from '@mui/material/Typography';
import useViewerSettings from 'hooks/useViewerSettings';
import useTranslate from 'hooks/useTranslate';
import SelectExpandedAccessSlots from '../SelectExpandedAccessSlots';
import Badge from '../Badge';
import {
    ProviderAndSlots,
    BookedAppointments,
    Appointment,
    ProviderSlot,
    UpdateExpandedAccessSlots,
    ProviderSlotOption,
} from '../../types';
import styles from './EditExpandedAccessLevers.module.scss';

type RemovedSlotConflict = {
    slot: ProviderSlot;
    appointments: readonly Appointment[];
};

type EditExpandedAccessLeversProps = {
    provider: ProviderAndSlots;
    bookedAppointments: BookedAppointments;
    date: string;
    readonly: boolean;
    updateExpandedAccessSlots: UpdateExpandedAccessSlots;
};

export default function EditExpandedAccessLevers({
    provider,
    bookedAppointments,
    readonly,
    date,
    updateExpandedAccessSlots,
}: EditExpandedAccessLeversProps) {
    const translate = useTranslate();
    const { viewer } = useViewerSettings();

    const [slots, setSlots] = React.useState(() => provider.slots);

    const slotOptions = React.useMemo<readonly ProviderSlotOption[]>(() => {
        const limit = new Date();

        return slots.map((slot) => ({
            ...slot,
            canBeSelected: !isBefore(new Date(slot.time), limit),
        }));
    }, [slots]);

    const isDirty = React.useMemo<boolean>(() => {
        const previouslySelectedSlots = provider.slots
            .filter((slot) => slot.availableForExtendedAccess)
            .sort((a, b) => a.key.localeCompare(b.key));
        const newlySelectedSlots = slots
            .filter((slot) => slot.availableForExtendedAccess)
            .sort((a, b) => a.key.localeCompare(b.key));

        let dirty =
            previouslySelectedSlots.length !== newlySelectedSlots.length;
        let i = 0;

        while (!dirty && i < previouslySelectedSlots.length) {
            const newSlot = newlySelectedSlots[i];
            const oldSlot = previouslySelectedSlots[i];

            dirty = dirty || newSlot.key !== oldSlot.key;

            i += 1;
        }

        return dirty;
    }, [slots, provider.slots]);

    const [slotsUpdated, setSlotsUpdated] = React.useState(false);
    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (isDirty && !readonly) {
                updateExpandedAccessSlots(
                    date,
                    provider.id,
                    slots
                        .filter((slot) => slot.availableForExtendedAccess)
                        .map((slot) => slot.time)
                        .sort(),
                ).then(() => {
                    setSlotsUpdated(true);
                });
            }
        },
        [
            isDirty,
            readonly,
            provider.id,
            date,
            updateExpandedAccessSlots,
            slots,
        ],
    );

    React.useEffect(() => {
        let timer: number | undefined;

        if (slotsUpdated) {
            timer = window.setTimeout(() => {
                timer = undefined;
                setSlotsUpdated(false);
            }, 5000);
        }

        return () => {
            if (timer) {
                window.clearTimeout(timer);
            }
        };
    }, [slotsUpdated]);

    const conflicts = React.useMemo<readonly RemovedSlotConflict[]>(() => {
        const removedSlots = provider.slots.filter(
            (providerSlot) =>
                providerSlot.availableForExtendedAccess &&
                !slots.some(
                    (slot) =>
                        providerSlot.key === slot.key &&
                        slot.availableForExtendedAccess,
                ),
        );

        if (removedSlots.length === 0) {
            return [];
        }

        const removedSlotConflicts: RemovedSlotConflict[] = [];

        for (const slot of removedSlots) {
            const appointments = bookedAppointments[slot.key];

            if (appointments?.length > 1) {
                removedSlotConflicts.push({ appointments, slot });
            }
        }

        return removedSlotConflicts;
    }, [bookedAppointments, provider.slots, slots]);

    React.useEffect(() => {
        setSlots(provider.slots);
    }, [provider.slots]);

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={classnames(styles.container, styles.flyoutBlock)}>
                <Typography variant="h5">
                    {translate(
                        'vetspireExtension.intradayCapacity.expandedAccessLevers.title',
                    )}
                </Typography>
                <Typography>
                    {translate(
                        'vetspireExtension.intradayCapacity.expandedAccessLevers.info1',
                    )}
                </Typography>
                <Typography className={styles.subCopy}>
                    {translate(
                        'vetspireExtension.intradayCapacity.expandedAccessLevers.info2',
                        {},
                        { renderInnerHtml: true },
                    )}
                </Typography>

                <SelectExpandedAccessSlots
                    slots={slotOptions}
                    onChange={setSlots}
                    readonly={readonly}
                />

                {conflicts.length > 0 && (
                    <div className={styles.conflicts}>
                        <Typography>
                            {translate(
                                'vetspireExtension.intradayCapacity.expandedAccessLevers.conflicts',
                            )}
                        </Typography>
                        <dl>
                            {conflicts.map((conflict) => (
                                <React.Fragment key={conflict.slot.key}>
                                    <dt>{conflict.slot.label}</dt>
                                    <dd>
                                        <ul>
                                            {conflict.appointments.map(
                                                (appointment) => (
                                                    <li key={appointment.id}>
                                                        {appointment.patient
                                                            ?.client?.name ??
                                                            appointment.patient
                                                                ?.name ??
                                                            translate(
                                                                'vetspireExtension.intradayCapacity.expandedAccessLevers.unknownClientAndPatient',
                                                            )}
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    </dd>
                                </React.Fragment>
                            ))}
                        </dl>
                    </div>
                )}
            </div>
            {!readonly && (
                <>
                    <div
                        className={classnames(
                            styles.flyoutBlock,
                            styles.updateInfo,
                        )}
                    >
                        <Typography>
                            {translate(
                                'vetspireExtension.intradayCapacity.expandedAccessLevers.updateInfo',
                                {
                                    person: translate(
                                        `vetspireExtension.intradayCapacity.expandedAccessLevers.${viewer?.id === provider.id ? 'your' : 'thisDoctor'}`,
                                    ),
                                    from: (
                                        <Badge>
                                            {
                                                provider.numberOfAvailableForExtendedAccess
                                            }
                                        </Badge>
                                    ),
                                    to: (
                                        <Badge>
                                            {
                                                slots.filter(
                                                    (slot) =>
                                                        slot.availableForExtendedAccess,
                                                ).length
                                            }
                                        </Badge>
                                    ),
                                },
                            )}
                        </Typography>
                    </div>
                    <div
                        className={classnames(
                            styles.flyoutBlock,
                            styles.actions,
                        )}
                    >
                        <Alert
                            variant="success"
                            classes={{
                                root: classnames(styles.success, {
                                    [styles.visible]: slotsUpdated,
                                }),
                                icon: styles.successIcon,
                            }}
                        >
                            {translate(
                                'vetspireExtension.intradayCapacity.expandedAccessLevers.slotsUpdated',
                            )}
                        </Alert>

                        <button type="submit" disabled={!isDirty}>
                            {translate(
                                'vetspireExtension.intradayCapacity.expandedAccessLevers.save',
                            )}
                        </button>
                    </div>
                </>
            )}
        </form>
    );
}
