import useVetspireMutation from 'hooks/useVetspireMutation';
import * as React from 'react';

import { gql } from '@apollo/client';
import useLazyVetspireQuery from './useLazyVetspireQuery';

const clientPrivateNotesQuery = gql`
    query clientPrivateNotes($clientId: ID!) {
        client(id: $clientId) {
            privateNotes
        }
    }
`;

const updateClientPrivateNotesMutation = gql`
    mutation updateClientPrivateNotes($clientId: ID!, $privateNotes: String) {
        updateClient(id: $clientId, input: { privateNotes: $privateNotes }) {
            privateNotes
        }
    }
`;

type GetClientPrivateNotesQueryResult = {
    client: {
        privateNotes: string;
    };
};

type GetClientPrivateNotesQueryVariables = {
    clientId: string;
};

type UpdateClientPrivateNotesMutationResult = {
    updateClient: {
        privateNotes: string;
    };
};

type UpdateClientPrivateNotesMutationVariables =
    GetClientPrivateNotesQueryVariables & {
        privateNotes: string;
    };

type Updater = (clientId: string, notesToAppend: string) => Promise<string>;

type QueueEntry = {
    notesToAdd: string;
    resolve: (notes: string) => void;
};

const QUEUES: Record<
    string,
    {
        entries: QueueEntry[];
        sending: boolean;
    }
> = {};

export default function useAddClientPrivateNotes(): Updater {
    const [getPrivateNotes] = useLazyVetspireQuery<
        GetClientPrivateNotesQueryResult,
        GetClientPrivateNotesQueryVariables
    >(clientPrivateNotesQuery, {
        fetchPolicy: 'no-cache',
    });

    const [updatePrivateNotes] = useVetspireMutation<
        UpdateClientPrivateNotesMutationResult,
        UpdateClientPrivateNotesMutationVariables
    >(updateClientPrivateNotesMutation);

    return React.useCallback(
        async (clientId: string, notesToAppend: string) => {
            if (!(clientId in QUEUES)) {
                QUEUES[clientId] = {
                    entries: [],
                    sending: false,
                };
            }

            if (QUEUES[clientId].sending) {
                return new Promise<string>((resolve) => {
                    QUEUES[clientId].entries.push({
                        notesToAdd: notesToAppend,
                        resolve,
                    });
                });
            }

            QUEUES[clientId].sending = true;

            const flushQueue = async () => {
                const { data } = await getPrivateNotes({
                    variables: { clientId },
                });

                await new Promise((resolve) => {
                    setTimeout(resolve, Math.round(Math.random() * 1000));
                });

                const currentNotes = data?.client.privateNotes ?? '';

                const prefix = currentNotes ? `${currentNotes}\n` : '';

                // get all pending items
                const pendingItems = QUEUES[clientId].entries.splice(0);

                const notesIncludingPending = [
                    notesToAppend,
                    ...pendingItems.map((item) => item.notesToAdd),
                ].join('\n');

                const newNotes = `${prefix}${notesIncludingPending}`;

                const { data: updatedData } = await updatePrivateNotes({
                    variables: { clientId, privateNotes: newNotes },
                });

                if (QUEUES[clientId].entries.length === 0) {
                    delete QUEUES[clientId];
                }

                const newPrivateNotes = updatedData?.updateClient.privateNotes;

                if (!newPrivateNotes) {
                    throw new Error('could not update private notes');
                }

                // resolve all pending items
                for (const pendingItem of pendingItems) {
                    pendingItem.resolve(newPrivateNotes);
                }

                return newPrivateNotes;
            };

            let result = await flushQueue();

            if (QUEUES[clientId]?.entries.length) {
                result = await flushQueue();
            } else {
                delete QUEUES[clientId];
            }

            return result;
        },
        [getPrivateNotes, updatePrivateNotes],
    );
}
