import * as React from 'react';
import classnames from 'classnames';
import { useAnalytics } from 'hooks/useAnalytics';
import LaunchIcon from '@mui/icons-material/Launch';
import { PopularLink } from 'api/infoPdfs/queries';
import { Page } from 'lib/vetspireActions';
import styles from '../../InfoPdf.module.scss';

type LinkOverviewProps = {
    links?: ReadonlyArray<PopularLink>;
};

const LinkOverview: React.FunctionComponent<LinkOverviewProps> = ({
    links,
}) => {
    const analytics = useAnalytics();
    const sortedLinks = React.useMemo(
        () =>
            [...(links || [])].sort(
                (link1, link2) => link1.index - link2.index,
            ),
        [links],
    );
    return (
        <>
            <div
                className={classnames(styles.flyoutBlock, styles.pdfContainer)}
            >
                {sortedLinks.map((pdf) => (
                    <a
                        key={pdf.url}
                        href={pdf.url}
                        className={styles.linkContainer}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            analytics.trackEvent(Page.infoPDF, 'link_opened', {
                                url: pdf.url,
                            });
                        }}
                    >
                        <span>{pdf.name}</span>
                        <LaunchIcon />
                    </a>
                ))}
            </div>
        </>
    );
};

export default LinkOverview;
