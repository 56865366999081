import { SchedulingShiftTime } from '@bondvet/types/scheduling';
import moment from 'moment-timezone';
import { getFallbackTimezone } from 'lib/utils';
import { UKGShift } from '@bondvet/types/ukg';

export function getBadgeTimeFromISOString(
    isoTime: string | null | undefined,
    timezone: string | undefined,
): string {
    if (!isoTime) {
        return '?';
    }

    const time = moment(isoTime).tz(timezone || getFallbackTimezone());

    if (time.minutes() === 0) {
        return time.format('ha');
    }

    return time.format('h:mma');
}

export function getShiftTimeIdFromUKGShift(
    { shift_start: shiftStartISO, shift_end: shiftEndISO }: UKGShift,
    timezone: string | undefined,
): string | null {
    if (!shiftStartISO || !shiftEndISO) {
        return null;
    }

    const start = moment(shiftStartISO).tz(timezone || getFallbackTimezone());
    const end = moment(shiftEndISO).tz(timezone || getFallbackTimezone());

    const startMinutes = start.hours() * 60 + start.minutes();
    const endMinutes = end.hours() * 60 + end.minutes();
    const duration = Math.abs(endMinutes - startMinutes);

    return `${startMinutes}_${duration}`;
}

export function getShiftTimeFromUKGShift(
    shiftTimes: readonly SchedulingShiftTime[],
    ukgShift: UKGShift,
    timezone: string | undefined,
) {
    const shiftTimeId = getShiftTimeIdFromUKGShift(ukgShift, timezone);

    if (!shiftTimeId) {
        console.warn(
            'shiftTime not found: ',
            ukgShift.shift_start,
            ukgShift.shift_end,
            shiftTimeId,
        );

        return null;
    }

    return (
        shiftTimes.find((shiftTime) => shiftTime._id === shiftTimeId) || null
    );
}
