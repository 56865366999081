import usePatientPlanAdditionsQuery from 'pages/PatientPlans/hooks/usePatientPlanAdditionsQuery';
import useUpdatePatientPlanAdditionsMutation from 'pages/PatientPlans/hooks/useUpdatePatientPlanAdditionsMutation';
import { getIntervalPrice } from 'pages/PatientPlans/utils';
import * as React from 'react';
import usePreventionPlan from 'pages/PatientPlans/hooks/usePreventionPlan';
import { CircularProgress } from '@mui/material';
import usePatientPlan from 'pages/PatientPlans/hooks/usePatientPlan';
import { Translated } from '@bondvet/web-app-i18n/util';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ChoosePaymentMethod, {
    type SubmitRef,
} from 'pages/ClientDetails/components/ChoosePaymentMethod';
import CancelPlan from 'pages/PatientPlans/components/ManagePlan/components/CancelPlan';
import ActionButton from 'components/ActionButton';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import globalStyles from 'pages/PatientPlans/PatientPlansGlobal.module.scss';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import type { IntervalPrices } from '@bondvet/types/patientPlans';
import type { PlanInterval } from '@bondvet/types/generated/vetspire';
import InactivePlanDetails from './components/InactivePlanDetails';

import styles from './ManagePlan.module.scss';

interface ManagePlanProps {
    patientPlanId: string;
    onBack: () => void;
}

export default function ManagePlan({ patientPlanId, onBack }: ManagePlanProps) {
    const translate = useTranslate();
    const [showCancel, setShowCancel] = React.useState(false);

    const {
        patientPlan,
        loading: patientPlanLoading,
        refetch: refetchPatientPlan,
    } = usePatientPlan(patientPlanId);
    const {
        data: patientPlanAdditionsData,
        loading: patientPlanAdditionsLoading,
        refetch: refetchPatientPlanAdditions,
    } = usePatientPlanAdditionsQuery(patientPlanId);

    const moneyFormatter = useMoneyFormatter({
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const { preventionPlan, loading: preventionPlanLoading } =
        usePreventionPlan({
            preventionPlanId: patientPlan?.preventionPlan?.id ?? '',
            vetspireLocationId: patientPlan?.location?.id ?? '',
            skip:
                !patientPlan?.preventionPlan?.id || !patientPlan?.location?.id,
        });

    const {
        preventionPlan: autoRenewPreventionPlan,
        loading: autoRenewPreventionPlanLoading,
    } = usePreventionPlan({
        preventionPlanId: preventionPlan?.autoRenewPreventionPlanId ?? '',
        vetspireLocationId: patientPlan?.location?.id ?? '',
        skip:
            !preventionPlan?.autoRenewPreventionPlanId ||
            !patientPlan?.location?.id,
    });

    const visibleOptions = React.useMemo(() => {
        if (!autoRenewPreventionPlan) {
            return [];
        }

        return (
            autoRenewPreventionPlan.options?.filter(
                (option) => !option.required && !option.selected,
            ) ?? []
        );
    }, [autoRenewPreventionPlan]);

    const {
        intervalTotalPrice,
        intervalTotalPriceWithOptions,
        intervalDiscountedPriceWithOptions,
        intervalDiscountPercent,
        intervalDiscount,
    } = React.useMemo(() => {
        if (!patientPlan || !autoRenewPreventionPlan) {
            return {
                intervalTotalPrice: 0,
                intervalDiscountedPrice: 0,
                intervalTotalPriceWithOptions: 0,
                intervalDiscountedPriceWithOptions: 0,
                intervalDiscountPercent: 0,
                intervalDiscount: 0,
            };
        }

        const {
            price: newIntervalTotalPrice,
            discountedPrice: newIntervalDiscountedPrice,
        } = getIntervalPrice(
            autoRenewPreventionPlan as IntervalPrices,
            patientPlan.planInterval as PlanInterval,
        );

        const {
            price: newIntervalTotalPriceWithOptions,
            discountedPrice: newIntervalDiscountedPriceWithOptions,
        } = getIntervalPrice(
            autoRenewPreventionPlan.pricesWithOptions,
            patientPlan.planInterval as PlanInterval,
        );

        let newIntervalDiscountPercent = 0;

        if (patientPlan.planInterval === 'QUARTERLY') {
            newIntervalDiscountPercent =
                autoRenewPreventionPlan.quarterlyPaymentDiscountPercent ?? 0;
        } else if (patientPlan.planInterval === 'YEARLY') {
            newIntervalDiscountPercent =
                autoRenewPreventionPlan.yearlyPaymentDiscountPercent ?? 0;
        }

        return {
            intervalTotalPrice: newIntervalTotalPrice,
            intervalDiscountedPrice: newIntervalDiscountedPrice,
            intervalTotalPriceWithOptions: newIntervalTotalPriceWithOptions,
            intervalDiscountedPriceWithOptions:
                newIntervalDiscountedPriceWithOptions,
            intervalDiscountPercent: newIntervalDiscountPercent,
            intervalDiscount:
                newIntervalDiscountedPriceWithOptions -
                newIntervalTotalPriceWithOptions,
        };
    }, [autoRenewPreventionPlan, patientPlan]);

    const [runUpdatePatientPlanAdditionsMutation, { loading: updating }] =
        useUpdatePatientPlanAdditionsMutation();

    const submitRef = React.useRef<SubmitRef>(null);

    const [error, setError] = React.useState<Translated | null>(null);

    const onPaymentSubmit = React.useCallback(async () => {
        if (submitRef.current) {
            const validCreditCard = await submitRef.current.submit();
            if (!validCreditCard) {
                setError(
                    translate(
                        'vetspireExtension.patientPlans.errors.invalidCreditCard',
                    ),
                );
            }
        }
    }, [translate]);

    const onAutoRenewChange = React.useCallback(
        async (newValue: boolean) => {
            if (
                !updating &&
                !patientPlanLoading &&
                !patientPlanAdditionsLoading
            ) {
                await runUpdatePatientPlanAdditionsMutation({
                    variables: {
                        id: patientPlanId,
                        input: {
                            autoRenew: newValue,
                        },
                    },
                });

                refetchPatientPlan();
                refetchPatientPlanAdditions().then();
            }
        },
        [
            patientPlanLoading,
            patientPlanAdditionsLoading,
            patientPlanId,
            refetchPatientPlan,
            refetchPatientPlanAdditions,
            runUpdatePatientPlanAdditionsMutation,
            updating,
        ],
    );

    if (
        !patientPlan ||
        patientPlanLoading ||
        patientPlanAdditionsLoading ||
        updating
    ) {
        return (
            <div className={styles.loading}>
                <CircularProgress />
            </div>
        );
    }

    if (patientPlan.status !== 'active') {
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <InactivePlanDetails patientPlan={patientPlan} />
                </div>
                <div className={globalStyles.footer}>
                    <ActionButton
                        type="button"
                        className={classnames(
                            globalStyles.button,
                            globalStyles.back,
                        )}
                        onClick={onBack}
                    >
                        {translate(
                            'vetspireExtension.patientPlans.actions.back',
                        )}
                    </ActionButton>
                </div>
            </div>
        );
    }

    const autoRenew =
        patientPlanAdditionsData?.patientPlanAdditions?.autoRenew ?? false;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.paymentMethod}>
                    <div
                        className={classnames(
                            globalStyles.patientName,
                            styles.names,
                        )}
                    >
                        {patientPlan?.patient?.name}

                        <div
                            className={classnames(
                                globalStyles.badge,
                                globalStyles.planName,
                            )}
                        >
                            {patientPlan?.preventionPlan?.publicName}
                        </div>
                    </div>

                    <ChoosePaymentMethod
                        clientId={patientPlan?.patient?.client?.id ?? ''}
                        error={false}
                        ref={submitRef}
                    />

                    {error && <div className={styles.error}>{error}</div>}

                    <div className={styles.paymentActions}>
                        <Button
                            type="button"
                            variant="contained"
                            color="error"
                            className={styles.button}
                            onClick={onPaymentSubmit}
                        >
                            {translate(
                                'vetspireExtension.patientPlans.managePlan.actions.paymentSubmit',
                            )}
                        </Button>
                    </div>
                </div>

                {(preventionPlanLoading || autoRenewPreventionPlanLoading) && (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                )}

                {autoRenewPreventionPlan && (
                    <div className={styles.autoRenew}>
                        <div className={styles.title}>
                            {translate(
                                'vetspireExtension.patientPlans.managePlan.autoRenewal.title',
                            )}
                        </div>

                        <FormControlLabel
                            className={styles.autoRenewSwitch}
                            control={
                                <Switch
                                    color="default"
                                    checked={autoRenew}
                                    onChange={(value) =>
                                        onAutoRenewChange(value.target.checked)
                                    }
                                />
                            }
                            label={
                                <span>
                                    {translate(
                                        'vetspireExtension.patientPlans.managePlan.autoRenewal.autoRenew',
                                    )}
                                    <div
                                        className={classnames(
                                            globalStyles.badge,
                                            {
                                                [globalStyles.enabled]:
                                                    autoRenew,
                                                [globalStyles.disabled]:
                                                    !autoRenew,
                                            },
                                        )}
                                    >
                                        {translate(
                                            autoRenew
                                                ? 'vetspireExtension.patientPlans.managePlan.autoRenewal.enabled'
                                                : 'vetspireExtension.patientPlans.managePlan.autoRenewal.disabled',
                                        )}
                                    </div>
                                </span>
                            }
                        />

                        {autoRenew ? (
                            <div className={styles.enabledNote}>
                                {translate(
                                    'vetspireExtension.patientPlans.managePlan.autoRenewal.enabledNote',
                                    {
                                        name: patientPlan?.patient?.name ?? '',
                                        date: patientPlan.formattedAutoRenewDate,
                                    },
                                )}
                            </div>
                        ) : (
                            <div className={styles.disabledNote}>
                                {translate(
                                    'vetspireExtension.patientPlans.managePlan.autoRenewal.disabledNote',
                                    {
                                        name: patientPlan?.patient?.name ?? '',
                                        date: patientPlan.formattedAutoRenewDate,
                                    },
                                )}
                            </div>
                        )}

                        <div className={styles.details}>
                            <div className={styles.title}>
                                {translate(
                                    'vetspireExtension.patientPlans.managePlan.autoRenewal.details.title',
                                )}
                            </div>
                            <div className={styles.labelWithValue}>
                                <div>
                                    {translate(
                                        'vetspireExtension.patientPlans.managePlan.autoRenewal.details.planInterval',
                                    )}
                                </div>
                                <div>
                                    {translate(
                                        `vetspireExtension.patientPlans.planInterval.${patientPlan.planInterval?.toLocaleLowerCase()}`,
                                    )}
                                </div>
                            </div>
                            <div className={styles.labelWithValue}>
                                <div>
                                    {autoRenewPreventionPlan.publicName}
                                    {translate(
                                        `vetspireExtension.patientPlans.billingNote.${patientPlan.planInterval?.toLocaleLowerCase()}`,
                                    )}
                                </div>
                                <div>
                                    {moneyFormatter.format(intervalTotalPrice)}
                                </div>
                            </div>

                            {visibleOptions.map((option) => (
                                <div
                                    key={option.name}
                                    className={styles.labelWithValue}
                                >
                                    <div>
                                        {option.name}
                                        <div
                                            className={classnames(
                                                globalStyles.badge,
                                                globalStyles.addOn,
                                            )}
                                        >
                                            {translate(
                                                'vetspireExtension.patientPlans.addOn',
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {moneyFormatter.format(
                                            getIntervalPrice(
                                                option as IntervalPrices,
                                                patientPlan.planInterval as PlanInterval,
                                            ).price,
                                        )}
                                    </div>
                                </div>
                            ))}

                            {patientPlan.planInterval !== 'MONTHLY' && (
                                <>
                                    <hr />

                                    <div className={styles.labelWithValue}>
                                        <div>
                                            {translate(
                                                'vetspireExtension.patientPlans.subTotal',
                                            )}
                                        </div>
                                        <div>
                                            {moneyFormatter.format(
                                                intervalTotalPriceWithOptions,
                                            )}
                                        </div>
                                    </div>

                                    <div className={styles.labelWithValue}>
                                        <div>
                                            {translate(
                                                `vetspireExtension.patientPlans.discount.${patientPlan.planInterval?.toLocaleLowerCase()}`,
                                                {
                                                    discount:
                                                        intervalDiscountPercent,
                                                },
                                            )}
                                        </div>
                                        <div className={styles.discount}>
                                            {moneyFormatter.format(
                                                intervalDiscount,
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}

                            <hr />

                            <div
                                className={classnames(
                                    styles.labelWithValue,
                                    styles.total,
                                )}
                            >
                                <div>
                                    {translate(
                                        `vetspireExtension.patientPlans.totalCosts.${patientPlan.planInterval?.toLocaleLowerCase()}`,
                                    )}
                                </div>
                                <div>
                                    {moneyFormatter.format(
                                        intervalDiscountedPriceWithOptions,
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className={globalStyles.footer}>
                <ActionButton
                    type="button"
                    className={classnames(
                        globalStyles.button,
                        globalStyles.back,
                    )}
                    onClick={onBack}
                >
                    {translate('vetspireExtension.patientPlans.actions.back')}
                </ActionButton>
                <ActionButton
                    type="button"
                    className={classnames(
                        globalStyles.button,
                        globalStyles.cancel,
                    )}
                    onClick={() => setShowCancel(true)}
                >
                    {translate('vetspireExtension.patientPlans.actions.cancel')}
                </ActionButton>
            </div>
            {showCancel && (
                <CancelPlan
                    patientPlan={patientPlan}
                    onClose={(cancelled) => {
                        if (cancelled) {
                            onBack();
                        } else {
                            setShowCancel(false);
                        }
                    }}
                />
            )}
        </div>
    );
}
