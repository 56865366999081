import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Provider } from 'api/providers/queries';
import {
    Encounter,
    EncountersQueryResult,
    EncountersQueryVariables,
    signedEncountersQuery,
} from 'api/encounters/queries';
import { Location } from 'api/locations/queries';
import { DateFilterType } from 'components/DateFilter/types';
import { endOfDay, startOfDay } from 'date-fns';
import { SelectOption, EncounterStats } from '../types';

interface PaginatedEncountersResult {
    loading: boolean;
    allLoaded: boolean;
    encounters: Encounter[];
    encounterStats?: Partial<EncounterStats>;
    loadMore: () => void;
    loadAll: () => void;
}

interface UsePaginatedEncounterOptions {
    selectedLocation?: Location;
    selectedProviders: Provider[];
    dateFilterType: DateFilterType;
    selectedEncounterTypes: SelectOption[];
    paginate?: boolean;
}

type LoadingDataState = {
    locationId: string;
    offset: number;
    allRowsLoaded: boolean;
    loadedEncounters: readonly Encounter[];
};

const RELOAD_MIN_ENCOUNTERS = 25;
const LIMIT = 250;

export default function usePaginatedEncounters({
    selectedLocation,
    selectedProviders,
    dateFilterType,
    selectedEncounterTypes,
}: UsePaginatedEncounterOptions): PaginatedEncountersResult {
    const [loadAll, setLoadAll] = React.useState(false);
    const [loadMoreCounter, setLoadMoreCounter] = React.useState(1);
    const selectedLocationId = selectedLocation?.id || '';
    const variables = React.useMemo(() => {
        const vars: EncountersQueryVariables = {
            limit: LIMIT,
            offset: 0,
        };

        if (dateFilterType !== DateFilterType.all) {
            if (dateFilterType === DateFilterType.past) {
                vars.startBefore = startOfDay(new Date());
            } else if (dateFilterType === DateFilterType.today) {
                vars.startAfter = startOfDay(new Date());
                vars.startBefore = endOfDay(new Date());
            } else {
                vars.startAfter = endOfDay(new Date());
            }
        }

        if (selectedLocationId) {
            vars.locationId = selectedLocationId;
        }
        return vars;
    }, [dateFilterType, selectedLocationId]);

    const [runLazy, { loading }] = useLazyQuery<EncountersQueryResult>(
        signedEncountersQuery,
        {
            variables,
            fetchPolicy: 'cache-and-network',
        },
    );

    // Local filtering for selected providers/encounter types
    // due to Vetspire API limitations.
    const filterEncounters = React.useCallback(
        (
            encounters: readonly Encounter[],
            providers: Provider[],
            encounterTypes: SelectOption[],
        ) => {
            return encounters
                .slice()
                .filter((encounter: Encounter) => {
                    return !!(
                        encounterTypes.length === 0 ||
                        encounterTypes.find(
                            (encounterType) =>
                                encounterType.value ===
                                encounter.encounterType?.id,
                        )
                    );
                }, [])
                .filter((encounter: Encounter) => {
                    return !!(
                        providers.length === 0 ||
                        providers.find(
                            (provider) =>
                                provider.id === encounter.provider?.id,
                        )
                    );
                }, [])
                .sort((a, b) => b.start.localeCompare(a.start));
        },
        [],
    );

    const [loadingData, setLoadingData] = React.useState<LoadingDataState>({
        locationId: '',
        offset: 0,
        allRowsLoaded: false,
        loadedEncounters: [],
    });

    // Using a custom loading variable to prevent multiple queries
    // due to the delayed loading state update in useLazyQuery.
    const isLoading = React.useRef<boolean>(false);

    const loadData = React.useCallback(async () => {
        if (isLoading.current) {
            return;
        }
        if (loadingData.allRowsLoaded) {
            return;
        }
        isLoading.current = true;

        const newOffset =
            // on location change reset the offset to 0
            selectedLocationId === loadingData.locationId
                ? loadingData.offset
                : 0;

        const { data } = await runLazy({
            variables: {
                offset: newOffset,
            },
        });

        setLoadingData((prevState) => {
            return {
                locationId: selectedLocationId,
                offset: prevState.offset + LIMIT,
                allRowsLoaded: (data?.encounters || []).length < LIMIT,
                loadedEncounters: [
                    // we want to remove the old loaded encounters on location change
                    ...(selectedLocationId === loadingData.locationId
                        ? prevState.loadedEncounters
                        : []),
                    ...(data?.encounters || []),
                ],
            };
        });
        isLoading.current = false;
    }, [
        loadingData.allRowsLoaded,
        loadingData.locationId,
        loadingData.offset,
        runLazy,
        selectedLocationId,
    ]);

    const filteredEncounters = React.useMemo(() => {
        return filterEncounters(
            loadingData.loadedEncounters,
            selectedProviders,
            selectedEncounterTypes,
        );
    }, [
        filterEncounters,
        loadingData.loadedEncounters,
        selectedEncounterTypes,
        selectedProviders,
    ]);

    React.useEffect(() => {
        if (!loading) {
            if (
                selectedLocationId !== loadingData.locationId ||
                // Ensure a minimum number of encounters are loaded when fetching more,
                // considering local filtering. If the previous query didn't return enough
                // encounters fitting our filters, we request another batch.
                // if load all is true we just want to load until all is loaded.
                ((filteredEncounters.length <
                    loadMoreCounter * RELOAD_MIN_ENCOUNTERS ||
                    loadAll) &&
                    !loadingData.allRowsLoaded)
            ) {
                if (selectedLocationId !== loadingData.locationId) {
                    // reset offset and loadedEncounters on location change
                    setLoadingData((prevState) => ({
                        ...prevState,
                        allRowsLoaded: false,
                        offset: 0,
                        loadedEncounters: [],
                    }));
                }
                loadData().then();
            }
        }
    }, [
        filteredEncounters.length,
        loadAll,
        loadData,
        loadMoreCounter,
        loading,
        loadingData.allRowsLoaded,
        loadingData.locationId,
        selectedLocationId,
    ]);

    const loadMore = React.useCallback(() => {
        setLoadMoreCounter(
            filteredEncounters.length / RELOAD_MIN_ENCOUNTERS + 1,
        );
    }, [filteredEncounters.length]);

    const encounterStats = React.useMemo(() => {
        let stats: Partial<EncounterStats> = {
            count: filteredEncounters.length,
            allLoaded: loadingData.allRowsLoaded,
        };
        if (loadingData.allRowsLoaded) {
            stats = {
                ...stats,
                ...filteredEncounters.reduce(
                    (acc, encounter) => {
                        if (acc.locations && encounter.location?.id) {
                            acc.locations[encounter.location.id] =
                                (acc.locations[encounter.location.id] || 0) + 1;
                        }
                        if (acc.providers && encounter.provider?.id) {
                            acc.providers[encounter.provider.id] =
                                (acc.providers[encounter.provider.id] || 0) + 1;
                        }
                        if (acc.encounterTypes && encounter.encounterType?.id) {
                            acc.encounterTypes[encounter.encounterType.id] =
                                (acc.encounterTypes[
                                    encounter.encounterType.id
                                ] || 0) + 1;
                        }
                        return acc;
                    },
                    {
                        locations: {},
                        providers: {},
                        encounterTypes: {},
                    } as Omit<EncounterStats, 'count' | 'allLoaded'>,
                ),
            };
        }
        return stats;
    }, [filteredEncounters, loadingData.allRowsLoaded]);

    return {
        loading: loading || isLoading.current,
        loadMore,
        loadAll: () => setLoadAll(true),
        allLoaded: loadingData.allRowsLoaded,
        encounters: filteredEncounters,
        encounterStats,
    };
}
