import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import CircularProgress from '@mui/material/CircularProgress';
import { WithFlyout } from 'lib/types';
import { Credit } from 'api/bond/queries';
import { Provider } from 'api/providers/queries';
import styles from './CreditMemos.module.scss';
import CreditMemo from '../CreditMemo';

interface CreditMemosProps {
    classes?: Partial<WithFlyout<typeof styles>>;
    credits: ReadonlyArray<Credit>;
    providers: ReadonlyArray<Provider>;
    isPolling: boolean;
    isLoading: boolean;
}

type CreditWithProvider = Credit & {
    provider?: Provider;
};

const mapProvidersToCredits = (
    credits: ReadonlyArray<Credit>,
    providers: ReadonlyArray<Provider>,
): ReadonlyArray<CreditWithProvider> => {
    const CreditsWithProviders = credits.map((credit) => {
        const reference = credit.references.find((ref) => {
            return ref.providerId;
        });
        const provider = providers?.find((person) => {
            if (person?.id === reference?.providerId) return true;
            return false;
        });
        return { ...credit, provider };
    });
    return CreditsWithProviders;
};

function CreditMemos({
    credits,
    classes,
    providers,
    isLoading,
    isPolling,
}: CreditMemosProps): React.ReactElement {
    const translate = useTranslate();
    const [newCredits, setNewCredits] = React.useState<
        ReadonlyArray<CreditWithProvider>
    >([]);

    React.useEffect(() => {
        setNewCredits(mapProvidersToCredits(credits, providers));
    }, [credits, providers]);

    return (
        <div
            className={classnames(
                classes?.flyoutBlock,
                styles.flyoutBlock,
                styles.root,
            )}
        >
            <header>
                <span className={styles.flyoutTitle}>
                    {translate(
                        'vetspireExtension.clientDetails.credits.creditHistory.title',
                    )}
                    {(isLoading || isPolling) && (
                        <CircularProgress
                            color="primary"
                            size="1.2rem"
                            className={styles.loading}
                        />
                    )}
                </span>
            </header>
            <div className={styles.row}>
                <h4>
                    {translate(
                        'vetspireExtension.clientDetails.credits.creditHistory.date',
                    )}
                </h4>
                <h4>
                    {translate(
                        'vetspireExtension.clientDetails.credits.creditHistory.user',
                    )}
                </h4>
                <h4>
                    {translate(
                        'vetspireExtension.clientDetails.credits.creditHistory.credit',
                    )}
                </h4>
                <h4>
                    {translate(
                        'vetspireExtension.clientDetails.credits.creditHistory.reason',
                    )}
                </h4>
            </div>
            {newCredits.map((credit) => (
                <CreditMemo
                    credit={credit}
                    key={credit._id}
                    className={styles.row}
                />
            ))}
        </div>
    );
}

export default CreditMemos;
