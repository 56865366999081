import * as React from 'react';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { MEDICAL_MISHAPS_LINK_ID } from '@bondvet/types/commonLinks';
import {
    CommonLinksQueryVariables,
    CommonLinksQueryResult,
    commonLinksQuery,
} from 'api/bond/queries';
import useTranslate from 'hooks/useTranslate';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';

import styles from './MedicalMishaps.module.scss';

const MedicalMishaps: React.FunctionComponent = () => {
    const translate = useTranslate();

    const { data, loading } = useQuery<
        CommonLinksQueryResult,
        CommonLinksQueryVariables
    >(commonLinksQuery, {
        variables: {
            id: MEDICAL_MISHAPS_LINK_ID,
        },
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    const link = React.useMemo(() => {
        if (!data?.commonLinks || data.commonLinks.length === 0) {
            return {
                name: 'MISSING LINK',
                link: '',
            };
        }

        return data.commonLinks[0];
    }, [data]);

    return (
        <div className={styles.container}>
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.medicalMishaps.title')}
                </div>
            </div>
            {loading ? (
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            ) : (
                <div className={styles.content}>
                    <div className={styles.linkLine}>
                        <Typography variant="body1">
                            {translate(
                                'vetspireExtension.medicalMishaps.linkText',
                            )}
                        </Typography>
                        <Link href={link.link} target="_blank">
                            {link.link}
                        </Link>
                    </div>
                    <CardMedia
                        component="iframe"
                        src={link.link}
                        className={styles.iframe}
                    />
                </div>
            )}
        </div>
    );
};

export default MedicalMishaps;
