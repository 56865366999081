import { gql, useMutation } from '@apollo/client';

const cancelPlanMutation = gql`
    mutation cancelPlanMutation($id: ID!) {
        cancelPlan(id: $id, discount: false) {
            id
        }
    }
`;

interface CancelPlanMutationVariables {
    id: string;
}

interface CancelPlanMutationResult {
    cancelPlan: {
        id: string;
    };
}

export default function useCancelPlanMutation() {
    return useMutation<CancelPlanMutationResult, CancelPlanMutationVariables>(
        cancelPlanMutation,
    );
}
