import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useCreateSukiAmbientSessionMutation from 'pages/Suki/hooks/useCreateSukiAmbientSessionMutation';
import * as React from 'react';
import { useSuki } from '@suki-sdk/react';
import { getValidBondAuthTokenWithoutRoles, isStillValid } from 'lib/bondAuth';
import useSukiGlobalSettings from 'pages/Suki/hooks/useSukiGlobalSettings';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import useLazyPatientDetailsQuery from 'hooks/useLazyPatientDetailsQuery';
import { openVetspirePage } from 'lib/vetspireActions';
import useUpdateEncounter from 'pages/Suki/components/AmbientSession/hooks/useUpdateEncounter';
import { useLazySukiAmbientSessionsQuery } from 'pages/Suki/hooks/useSukiAmbientSessionsQuery';
import { SukiMode } from 'pages/Suki/types';
import useTranslate from 'hooks/useTranslate';
import useEncounterUrlIds from 'hooks/useEncounterUrlIds';
import { openAmbientSessionVetspirePage } from 'pages/Suki/utils';
import classnames from 'classnames';
import useViewerSettings from 'hooks/useViewerSettings';
import { SukiAmbientSession } from '@bondvet/types/suki';
import Assistant from './components/Assistant';
import { SukiNoteData } from './types';

import styles from './AmbientSession.module.scss';

interface AmbientSessionProps {
    hidden: boolean;
}

interface AmbientSessionState {
    vetspireProviderId: string;
    vetspireClientId: string;
    vetspirePatientId: string;
    vetspireEncounterId: string | null;
    noteTypeId: string | null;
}

export default function AmbientSession({ hidden }: AmbientSessionProps) {
    const translate = useTranslate();
    const {
        init,
        isInitialized,
        error: sukiError,
        setPartnerToken,
    } = useSuki();

    const encounterUrlIds = useEncounterUrlIds();

    const { viewer, loading: viewerLoading } = useViewerSettings();

    const { sukiGlobalSettings, loading: settingsLoading } =
        useSukiGlobalSettings();

    const [error, setError] = React.useState<string | null>(null);

    const [showNotSamePatientWarning, setShowNotSamePatientWarning] =
        React.useState(false);

    const [sukiMode, setSukiMode] = React.useState<SukiMode>('idle');

    const [ambientSessionState, setAmbientSessionState] =
        React.useState<AmbientSessionState | null>(null);

    const [runPatientDetailsQuery, { data: patientDetails }] =
        useLazyPatientDetailsQuery();

    const { updateEncounter } = useUpdateEncounter();

    const [runCreateSukiAmbientSession] = useCreateSukiAmbientSessionMutation();

    const [runLazySukiAmbientSessionsQuery] = useLazySukiAmbientSessionsQuery();

    const [bondAuthToken, setBondAuthToken] = React.useState<string | null>(
        null,
    );

    const updateBondAuthTokenIfNeeded = React.useCallback(async () => {
        if (!bondAuthToken || !(await isStillValid(bondAuthToken))) {
            const newToken = await getValidBondAuthTokenWithoutRoles();

            setBondAuthToken(newToken);
            setPartnerToken(newToken);
        }

        return null;
    }, [bondAuthToken, setPartnerToken]);

    React.useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        if (!bondAuthToken) {
            updateBondAuthTokenIfNeeded().then();
        }

        timer = setInterval(() => {
            updateBondAuthTokenIfNeeded().then();
        }, 15 * 1000);

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [bondAuthToken, updateBondAuthTokenIfNeeded]);

    React.useEffect(() => {
        if (!isInitialized && sukiGlobalSettings?.partnerId && bondAuthToken) {
            init({
                partnerId: sukiGlobalSettings.partnerId,
                isTestMode: sukiGlobalSettings.isTestMode ?? true,
                partnerToken: bondAuthToken,
                theme: { primaryColor: 'rgb(16, 53, 89)' },
                logLevel: 'info',
                enableDebug: true,
            });
        }
    }, [
        bondAuthToken,
        init,
        isInitialized,
        sukiGlobalSettings.isTestMode,
        sukiGlobalSettings.partnerId,
    ]);

    const samePatientAndEncounter = React.useMemo(() => {
        if (!ambientSessionState && !encounterUrlIds?.patientId) {
            return true;
        }

        if (ambientSessionState && !encounterUrlIds?.patientId) {
            return false;
        }

        if (!ambientSessionState && encounterUrlIds?.patientId) {
            return false;
        }

        // at this point ambientSession and encounterUrlIds.patientId are not null

        if (
            (encounterUrlIds.patientId ?? '') !==
            (ambientSessionState?.vetspirePatientId ?? '')
        ) {
            return false;
        }

        if (
            (encounterUrlIds.encounterId ?? '') !==
            (ambientSessionState?.vetspireEncounterId ?? '')
        ) {
            return false;
        }

        return true;
    }, [encounterUrlIds, ambientSessionState]);

    React.useEffect(() => {
        // Reset ambientSession if there is no ongoing session and patientId changes or becomes null.
        if (sukiMode === 'idle') {
            if (!encounterUrlIds?.patientId) {
                if (ambientSessionState) {
                    setAmbientSessionState(null);
                }
            } else if (ambientSessionState) {
                if (!samePatientAndEncounter) {
                    setAmbientSessionState(null);
                }
            }
        }
    }, [
        ambientSessionState,
        encounterUrlIds?.patientId,
        samePatientAndEncounter,
        sukiMode,
    ]);

    React.useEffect(() => {
        // don't update ambientSession if there is an ongoing session
        if (sukiMode !== 'idle') {
            if (showNotSamePatientWarning !== !samePatientAndEncounter) {
                setShowNotSamePatientWarning(!samePatientAndEncounter);
            }
            return;
        }

        if (showNotSamePatientWarning) {
            setShowNotSamePatientWarning(false);
        }

        if (!encounterUrlIds?.patientId) {
            setError(
                translate(
                    'vetspireExtension.suki.errors.noPatientOrEncounter',
                ) as string,
            );
            return;
        }

        if (encounterUrlIds?.patientId) {
            runPatientDetailsQuery({
                variables: {
                    patientId: encounterUrlIds.patientId,
                },
            }).then((data) => {
                if (!data?.data?.patient?.species) {
                    setError(
                        translate(
                            'vetspireExtension.suki.errors.noSpecies',
                        ) as string,
                    );
                } else if (!data?.data?.patient?.birthDate) {
                    setError(
                        translate(
                            'vetspireExtension.suki.errors.noBirthDate',
                        ) as string,
                    );
                } else if (!data?.data?.patient?.sex) {
                    setError(
                        translate(
                            'vetspireExtension.suki.errors.noSex',
                        ) as string,
                    );
                } else if (!sukiGlobalSettings) {
                    setError(
                        translate(
                            'vetspireExtension.suki.errors.noGlobalSettings',
                        ) as string,
                    );
                } else {
                    setAmbientSessionState({
                        vetspireProviderId: viewer?.id ?? '',
                        vetspireClientId: encounterUrlIds.clientId ?? '',
                        vetspirePatientId: encounterUrlIds.patientId ?? '',
                        vetspireEncounterId: encounterUrlIds.encounterId,
                        noteTypeId:
                            (encounterUrlIds.encounterId
                                ? sukiGlobalSettings?.encounterNoteTypeId
                                : sukiGlobalSettings?.telephoneNoteTypeId) ??
                            null,
                    });

                    setError(null);
                }
            });
        }
    }, [
        encounterUrlIds.encounterId,
        encounterUrlIds.patientId,
        samePatientAndEncounter,
        patientDetails,
        showNotSamePatientWarning,
        sukiMode,
        translate,
        encounterUrlIds.clientId,
        viewer?.id,
        runPatientDetailsQuery,
        sukiGlobalSettings,
    ]);

    const goBackToSessionRecord = React.useCallback(() => {
        if (ambientSessionState) {
            openAmbientSessionVetspirePage(ambientSessionState);
        }
    }, [ambientSessionState]);

    const [externalSubmitData, setExternalSubmitData] = React.useState<{
        ambientId: string;
        sukiNoteData: SukiNoteData;
    } | null>(null);

    const onNoteSubmit = React.useCallback(
        async (ambientId: string, sukiNoteData: SukiNoteData) => {
            const result = await runLazySukiAmbientSessionsQuery({
                variables: {
                    _id: ambientId,
                },
            });

            if (
                !result.data?.sukiAmbientSessions ||
                result.data?.sukiAmbientSessions.length !== 1
            ) {
                // The ambient session has been recorded outside the Chrome extension (most likely on the provider's mobile).

                if (sukiNoteData.contents.length <= 1) {
                    // Phone notes have one content field, while SOAP notes have multiple.
                    // As phone notes are not processed automatically, we'll just create a session record to keep track of it.

                    await runCreateSukiAmbientSession({
                        variables: {
                            _id: ambientId,
                            input: {
                                type: 'phone',
                                vetspireProviderId: viewer?.id ?? '',
                                vetspireClientId: '?',
                                vetspirePatientId: '?',
                                vetspireEncounterId: null,
                                status: 'processed',
                            },
                        },
                    });
                } else {
                    setExternalSubmitData({
                        ambientId,
                        sukiNoteData,
                    });
                }
            } else {
                const noteAmbientSession = result.data
                    .sukiAmbientSessions[0] as SukiAmbientSession;

                if (noteAmbientSession.vetspireEncounterId) {
                    await updateEncounter(
                        noteAmbientSession.vetspireEncounterId,
                        sukiNoteData,
                    );

                    if (
                        encounterUrlIds.encounterId &&
                        encounterUrlIds.encounterId ===
                            noteAmbientSession.vetspireEncounterId
                    ) {
                        // move to the patient page to reload
                        openVetspirePage(
                            `/clients/${noteAmbientSession.vetspireClientId}/patients/${noteAmbientSession.vetspirePatientId}`,
                        );

                        setTimeout(() => {
                            openAmbientSessionVetspirePage(noteAmbientSession);
                        }, 500);
                    }
                } else {
                    openAmbientSessionVetspirePage(noteAmbientSession);

                    // eslint-disable-next-line no-console
                    console.warn('ignoring non-encounter note: ', sukiNoteData);
                }
            }
        },
        [
            encounterUrlIds.encounterId,
            runCreateSukiAmbientSession,
            runLazySukiAmbientSessionsQuery,
            updateEncounter,
            viewer?.id,
        ],
    );

    const onExternalNoteSubmit = React.useCallback(async () => {
        if (
            !externalSubmitData?.ambientId ||
            !externalSubmitData?.sukiNoteData ||
            !encounterUrlIds.clientId ||
            !encounterUrlIds.patientId ||
            !encounterUrlIds.encounterId
        ) {
            // This should never happen as the continue button is disabled if encounterId is not set.
            return;
        }

        await runCreateSukiAmbientSession({
            variables: {
                _id: externalSubmitData.ambientId,
                input: {
                    type: 'encounter',
                    vetspireProviderId: viewer?.id ?? '',
                    vetspireClientId: encounterUrlIds.clientId,
                    vetspirePatientId: encounterUrlIds.patientId,
                    vetspireEncounterId: encounterUrlIds.encounterId,
                    status: 'processed',
                },
            },
        });

        await updateEncounter(
            encounterUrlIds.encounterId,
            externalSubmitData?.sukiNoteData,
        );

        setExternalSubmitData(null);

        // move to the patient page to reload
        openVetspirePage(
            `/clients/${encounterUrlIds.clientId}/patients/${encounterUrlIds.patientId}`,
        );

        setTimeout(() => {
            openVetspirePage(
                `/clients/${encounterUrlIds.clientId}/patients/${encounterUrlIds.patientId}/encounters2/${encounterUrlIds.encounterId}`,
            );
        }, 500);
    }, [
        encounterUrlIds.clientId,
        encounterUrlIds.encounterId,
        encounterUrlIds.patientId,
        externalSubmitData?.ambientId,
        externalSubmitData?.sukiNoteData,
        runCreateSukiAmbientSession,
        updateEncounter,
        viewer?.id,
    ]);

    const loading = viewerLoading || settingsLoading;

    return (
        <>
            <div
                className={classnames(styles.container, {
                    [styles.hidden]: hidden,
                })}
            >
                {loading && (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                )}

                {!loading && sukiError && (
                    <div className={styles.error}>
                        {sukiError?.details?.message ??
                            translate(
                                'vetspireExtension.suki.errors.sukiError',
                            )}
                    </div>
                )}

                {!loading && !sukiError && error && (
                    <div className={styles.error}>{error}</div>
                )}

                {!loading && !error && showNotSamePatientWarning && (
                    <div className={styles.notSamePatientWarning}>
                        <div>
                            {translate(
                                'vetspireExtension.suki.errors.notSamePatient',
                            )}
                        </div>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={goBackToSessionRecord}
                        >
                            {translate(
                                'vetspireExtension.suki.actions.goBackToSessionRecord',
                            )}
                        </Button>
                    </div>
                )}

                {!loading && !error && !showNotSamePatientWarning && <div />}

                {isInitialized &&
                    viewer?.email &&
                    !error &&
                    viewer?.id &&
                    ambientSessionState &&
                    patientDetails?.patient && (
                        <Assistant
                            vetspireProviderId={viewer?.id}
                            vetspireClientId={
                                ambientSessionState.vetspireClientId
                            }
                            vetspirePatientId={
                                ambientSessionState.vetspirePatientId
                            }
                            vetspireEncounterId={
                                ambientSessionState.vetspireEncounterId
                            }
                            noteTypeId={ambientSessionState.noteTypeId}
                            patient={patientDetails?.patient}
                            setError={setError}
                            sukiMode={sukiMode}
                            setSukiMode={setSukiMode}
                            onNoteSubmit={onNoteSubmit}
                        />
                    )}
            </div>
            {externalSubmitData && (
                <Dialog open>
                    <DialogContent>
                        <DialogContentText>
                            <div className={styles.externalNote}>
                                {translate(
                                    'vetspireExtension.suki.external.confirm',
                                    undefined,
                                    { renderInnerHtml: true },
                                )}
                            </div>
                        </DialogContentText>
                        <DialogActions>
                            <Button
                                type="button"
                                variant="text"
                                color="error"
                                onClick={() => setExternalSubmitData(null)}
                                className={styles.button}
                            >
                                {translate(
                                    'vetspireExtension.suki.external.cancel',
                                )}
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                color="error"
                                className={styles.button}
                                onClick={onExternalNoteSubmit}
                                disabled={
                                    !encounterUrlIds.clientId ||
                                    !encounterUrlIds?.patientId ||
                                    !encounterUrlIds?.encounterId
                                }
                            >
                                {translate(
                                    'vetspireExtension.suki.external.submit',
                                )}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}
