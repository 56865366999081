import { Moment } from 'moment';
import { StaffingRole } from '@bondvet/types/scheduling/staffing';
import { RoleStats } from './hooks/useStaffOptimizationUKGData';

export enum NameFormat {
    noName = 'noName',
    initials = 'initials',
    fullName = 'fullName',
}

export interface StaffOptimizationOptions {
    loading: boolean;
    week: Moment;
    locationIds: string[];
    staffingRoles: StaffingRole[];
    nameFormat: NameFormat;
    numbers: boolean;
    surgeries: boolean;
    colors: boolean;
    issues: boolean;
}

export interface ShiftInfoData {
    locationId: string;
    day: string;
    staffingRole: StaffingRole;
    employeeId: string;
    employeeName: string;
    stats: RoleStats;
    badgeNumber: number;
    clickable: boolean;
    clicked: boolean;
}
