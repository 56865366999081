import { createBlobFromBase64String } from 'lib/utils';
import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@apollo/client';
import GeneralError from 'components/GeneralError/GeneralError';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import {
    categoriesQuery,
    CategoriesQueryResult,
    infoPdfBase64,
    InfoPdfBase64QueryResult,
    InfoPdfCategory,
    infoPdfsQuery,
    InfoPdfsQueryResult,
} from 'api/infoPdfs/queries';
import { closeFlyout } from 'lib/vetspireActions';
import { vetspireContext } from 'context/VetspireContext';
import styles from './InfoPdf.module.scss';
import Finished from './components/Finished/Finished';
import Tabs from '../../components/Tabs';
import { InfoPdfTab } from './types';
import PdfOverview from './components/PdfOverview';
import LinkOverview from './components/LinkOverview';

const TABS: ReadonlyArray<InfoPdfTab> = ['pdfs', 'links'];

const InformationPDFs: React.FunctionComponent = () => {
    const translate = useTranslate();
    const [currentTab, setCurrentTab] = React.useState<InfoPdfTab>('pdfs');
    const previewRef = React.useRef<HTMLIFrameElement>(null);
    const [searchValue, setSearchValue] = React.useState<string>('');
    const [sendingEmail, setSendingEmail] = React.useState<boolean>(false);
    const [finish, setFinish] = React.useState<boolean>(false);
    const [previewing, setPreviewing] = React.useState<string | null>(null);
    const [error, setError] = React.useState<string | null>(null);
    const [selectedInfoPdfCategories, setSelectedInfoPdfCategories] =
        React.useState<InfoPdfCategory[]>([]);

    const categoriesQueryResult = useQuery<CategoriesQueryResult>(
        categoriesQuery,
        {
            fetchPolicy: 'network-only',
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );

    const infoPdfsQueryResult = useQuery<InfoPdfsQueryResult>(infoPdfsQuery, {
        fetchPolicy: 'network-only',
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    React.useEffect(() => {
        setPreviewing(null);
    }, [selectedInfoPdfCategories, searchValue, currentTab]);
    const vetspire = React.useContext(vetspireContext);
    const pdfData = React.useMemo(() => {
        const categories = categoriesQueryResult.data?.infoPdfCategories;
        const infoPdfs = infoPdfsQueryResult.data?.infoPdfs;
        if (categories && infoPdfs) {
            return infoPdfs.map((infoPdf) => ({
                ...infoPdf,
                category: categories.find(
                    (category) => category._id === infoPdf.categoryId,
                ),
            }));
        }
        return [];
    }, [
        categoriesQueryResult.data?.infoPdfCategories,
        infoPdfsQueryResult.data?.infoPdfs,
    ]);

    const [clientId, petId] = React.useMemo((): string[] => {
        const found = vetspire.pathName.match(
            /^\/clients\/\d+(\/patients\/\d+)?/,
        );

        if (found) {
            const splittedUrl = vetspire.pathName.split('/');
            return [splittedUrl[2], splittedUrl[4]];
        }
        return [];
    }, [vetspire.pathName]);

    const filteredPdfs = React.useMemo(() => {
        return pdfData.filter(
            (pdf) =>
                pdf.name.match(new RegExp(`.*${searchValue}.*`, 'i')) &&
                (selectedInfoPdfCategories.length === 0 ||
                    selectedInfoPdfCategories
                        .map((category) => category._id)
                        .indexOf(pdf.categoryId) !== -1),
        );
    }, [searchValue, pdfData, selectedInfoPdfCategories]);

    const [selected, setSelected] = React.useState<ReadonlyArray<string>>(
        () => [],
    );
    const onSelect = React.useCallback((index: string) => {
        setSelected((prev) => [...prev, index]);
    }, []);

    const onUnselect = React.useCallback((index: string) => {
        setSelected((prev) => prev.filter((val) => val !== index));
    }, []);

    const {
        data: base64Pdf,
        loading: base64PdfLoading,
        error: pdfError,
    } = useQuery<InfoPdfBase64QueryResult>(infoPdfBase64, {
        fetchPolicy: 'no-cache',
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        skip: previewing === null,
        variables: {
            id: previewing === null ? '' : previewing,
            clientId,
            petId,
        },
    });

    const [blobUrl, setBlobUrl] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (!base64Pdf?.infoPdfBase64) {
            setBlobUrl(null);
        } else {
            // convert the base64 string into a blob so that the iframe can also display large pdfs more efficient
            createBlobFromBase64String(
                base64Pdf.infoPdfBase64,
                'application/pdf',
            ).then((blob) => {
                setBlobUrl(URL.createObjectURL(blob));
            });
        }
    }, [base64Pdf?.infoPdfBase64]);

    const changeCurrentTab = React.useCallback((tab: string) => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setCurrentTab(tab as InfoPdfTab);
            });
        });
    }, []);

    const tabs = React.useMemo(
        () =>
            TABS.map((name) => ({
                name,
                label: translate(`vetspireExtension.infoPDFs.tabs.${name}`),
            })),
        [translate],
    );

    if (infoPdfsQueryResult.error) {
        return <GeneralError message={infoPdfsQueryResult.error.message} />;
    }

    if (categoriesQueryResult.error) {
        return <GeneralError message={categoriesQueryResult.error.message} />;
    }

    if (finish) {
        return (
            <Finished
                loading={sendingEmail}
                error={error}
                onClose={() => {
                    closeFlyout();
                }}
            />
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.previewContainer}>
                {currentTab === 'pdfs' &&
                    previewing !== null &&
                    base64Pdf?.infoPdfBase64 && (
                        <div className={styles.previewBackground}>
                            <iframe
                                className={styles.preview}
                                ref={previewRef}
                                title="preview"
                                src={blobUrl || ''}
                            />
                        </div>
                    )}
                {currentTab === 'pdfs' &&
                    (previewing == null || !base64Pdf?.infoPdfBase64) &&
                    !pdfError?.message && (
                        // as long as no pdf is selected, we render an empty box with the
                        // same background-color as the pdf preview has to avoid massive flickering
                        <div className={styles.previewBackground}>
                            {(categoriesQueryResult.loading ||
                                infoPdfsQueryResult.loading) && (
                                <div className={styles.previewLoading}>
                                    <CircularProgress
                                        size="50px"
                                        color="inherit"
                                        className={styles.previewLoadingIcon}
                                    />
                                </div>
                            )}

                            {previewing == null && (
                                <div className={styles.noPdfSelected}>
                                    {translate(
                                        'vetspireExtension.infoPDFs.noPdfSelected',
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                {currentTab === 'pdfs' && pdfError?.message && (
                    <div className={styles.previewBackground}>
                        <div className={styles.noPdfSelected}>
                            {pdfError.message}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.flyout}>
                <div className={classnames(styles.flyoutBlock, styles.first)}>
                    <div className={styles.flyoutTitle}>
                        {translate('vetspireExtension.infoPDFs.title')}
                    </div>
                </div>

                <div
                    className={classnames(
                        styles.flyoutBlockFullWidth,
                        styles.noHorizontal,
                    )}
                >
                    <Tabs
                        currentTab={currentTab}
                        onChange={changeCurrentTab}
                        tabs={tabs}
                    />
                </div>

                {currentTab === 'pdfs' && (
                    <PdfOverview
                        previewing={previewing}
                        base64PdfLoading={base64PdfLoading}
                        categoriesQueryResult={categoriesQueryResult}
                        filteredPdfs={[...filteredPdfs].sort((a, b) =>
                            a.name.localeCompare(b.name),
                        )}
                        searchValue={searchValue}
                        selectedInfoPdfCategories={selectedInfoPdfCategories}
                        setSearchValue={setSearchValue}
                        setPreviewing={setPreviewing}
                        setSelectedInfoPdfCategories={
                            setSelectedInfoPdfCategories
                        }
                        setError={setError}
                        setSendingEmail={setSendingEmail}
                        setFinish={setFinish}
                        clientId={clientId}
                        petId={petId}
                        selected={selected}
                        onSelect={onSelect}
                        onUnselect={onUnselect}
                    />
                )}
                {currentTab === 'links' && (
                    <LinkOverview
                        links={infoPdfsQueryResult.data?.popularLinks}
                    />
                )}
            </div>
        </div>
    );
};

export default InformationPDFs;
