import * as React from 'react';
import {
    CategoriesQueryResult,
    createInfoPdfsBase64Query,
    InfoPdf,
    InfoPdfCategory,
} from 'api/infoPdfs/queries';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import { QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import SearchInput from 'components/SearchInput';
import ActionButton from 'components/ActionButton';
import { Page } from 'lib/vetspireActions';
import useTranslate from 'hooks/useTranslate';
import {
    sendInfoPdf,
    SendInfoPdfResult,
    SendInfoPdfVariables,
} from 'api/infoPdfs/mutations';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { useAnalytics } from 'hooks/useAnalytics';
import PdfOverviewRow from '../PdfOverviewRow';
import PdfCategoryFilter from '../PdfCategoryFilter';
import styles from '../../InfoPdf.module.scss';

type PdfOverviewProps = {
    previewing: string | null;
    searchValue: string;
    setPreviewing: (selected: string | null) => void;
    setSearchValue: (searchValue: string) => void;
    filteredPdfs: InfoPdf[];
    categoriesQueryResult: QueryResult<CategoriesQueryResult>;
    selectedInfoPdfCategories: InfoPdfCategory[];
    setSelectedInfoPdfCategories: (selected: InfoPdfCategory[]) => void;
    base64PdfLoading: boolean;
    setError: (err: string | null) => void;
    setSendingEmail: (sendingEmail: boolean) => void;
    setFinish: (sendingEmail: boolean) => void;
    clientId: string;
    petId: string;
    onSelect: (index: string) => void;
    onUnselect: (index: string) => void;
    selected: ReadonlyArray<string>;
};

export default function PdfOverview({
    previewing,
    searchValue,
    setPreviewing,
    setSearchValue,
    filteredPdfs,
    categoriesQueryResult,
    selectedInfoPdfCategories,
    setSelectedInfoPdfCategories,
    base64PdfLoading,
    setError,
    setSendingEmail,
    setFinish,
    clientId,
    petId,
    selected,
    onSelect,
    onUnselect,
}: PdfOverviewProps) {
    const translate = useTranslate();
    const analytics = useAnalytics();

    const [sendPdf] = useMutation<SendInfoPdfResult, SendInfoPdfVariables>(
        sendInfoPdf,
        {
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );

    const downloadQuery = React.useMemo(
        () => createInfoPdfsBase64Query(selected),
        [selected],
    );

    const [
        loadPdfData,
        { data: downloadData, loading: isLoadingDownloadData },
    ] = useLazyQuery<
        Record<string, string>,
        { clientId: string; petId: string }
    >(downloadQuery, {
        fetchPolicy: 'no-cache',
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });
    const [isDownloading, setIsDownloading] = React.useState(false);

    React.useEffect(() => {
        if (isDownloading && !isLoadingDownloadData && downloadData) {
            setIsDownloading(false);
            const downloadPdfs = async () => {
                if (!downloadData) {
                    throw new Error('no data loaded');
                }

                for (const id of selected) {
                    const pdfToDownload = filteredPdfs.find(
                        (pdf) => pdf._id === id,
                    );

                    if (!pdfToDownload) {
                        throw new Error(`could not find PDF for id ${id}`);
                    }

                    const dataKey = `pdf_${id}`;

                    const pdfData = downloadData[dataKey];

                    if (!pdfData) {
                        throw new Error(
                            `no PDF data loaded for key ${dataKey}`,
                        );
                    }

                    const [
                        { default: FileSaver },
                        { createBlobFromBase64String },
                    ] = await Promise.all([
                        import('file-saver'),
                        import('lib/utils'),
                    ]);

                    FileSaver.saveAs(
                        await createBlobFromBase64String(
                            pdfData,
                            'application/pdf',
                        ),
                        pdfToDownload.name,
                    );
                }
            };

            downloadPdfs().then(
                () => {
                    // nothing to do
                },
                (error) => {
                    console.warn('error downloading PDFs', error);
                },
            );
        }
    }, [
        clientId,
        downloadData,
        filteredPdfs,
        isDownloading,
        isLoadingDownloadData,
        loadPdfData,
        petId,
        selected,
    ]);

    const onDownload = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (selected.length > 0) {
                analytics.trackEvent(Page.infoPDF, 'download_and_print', {
                    clientId,
                    petId,
                    pdfIds: selected,
                });

                loadPdfData({ variables: { clientId, petId } }).then();
                setIsDownloading(true);
            }
        },
        [clientId, loadPdfData, petId, selected, analytics],
    );

    const onSend = React.useCallback(async () => {
        if (selected.length > 0) {
            setFinish(true);
            setSendingEmail(true);
            try {
                const sendPdfResult = await sendPdf({
                    variables: {
                        clientId,
                        pdfIds: selected,
                        petId,
                    },
                });

                analytics.trackEvent(Page.infoPDF, 'email_to_client', {
                    clientId,
                    petId,
                    pdfIds: selected,
                });

                if (!sendPdfResult.data?.sendInfoPdf?.success) {
                    setError(
                        translate(
                            'vetspireExtension.infoPDFs.noSuccess',
                        ).toString(),
                    );
                }
                setSendingEmail(false);
            } catch (sendPdfError) {
                setError((sendPdfError as Error).message);
            }
        }
    }, [
        analytics,
        clientId,
        petId,
        selected,
        sendPdf,
        setError,
        setFinish,
        setSendingEmail,
        translate,
    ]);
    return (
        <>
            <div className={classnames(styles.flyoutBlock)}>
                <SearchInput
                    value={searchValue}
                    onChange={(val) => {
                        setPreviewing(null);
                        setSearchValue(val);
                    }}
                    className={styles.searchInput}
                    placeholder="Search Forms"
                />
            </div>
            <div className={classnames(styles.flyoutBlock, styles.noVertical)}>
                <div className={styles.filterInput}>
                    <PdfCategoryFilter
                        options={
                            categoriesQueryResult?.data?.infoPdfCategories || []
                        }
                        selectedInfoPdfCategories={selectedInfoPdfCategories}
                        setSelectedInfoPdfCategories={(val) => {
                            setPreviewing(null);
                            setSelectedInfoPdfCategories(val);
                        }}
                    />
                </div>
            </div>
            <div
                className={classnames(styles.flyoutBlock, styles.pdfContainer)}
            >
                {filteredPdfs.map((pdf) => (
                    <PdfOverviewRow
                        key={pdf._id}
                        pdf={pdf}
                        selected={selected.includes(pdf._id)}
                        onPreview={setPreviewing}
                        onUnselect={onUnselect}
                        onSelect={onSelect}
                        previewing={previewing === pdf._id}
                    />
                ))}
            </div>
            {base64PdfLoading && (
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            )}

            <div
                className={classnames(
                    styles.flyoutBlock,
                    styles.noMargin,
                    styles.padding,
                    styles.actions,
                )}
            >
                <ActionButton
                    onClick={onDownload}
                    active
                    className={styles.button}
                    disabled={selected.length === 0 || isDownloading}
                >
                    {translate('vetspireExtension.infoPDFs.actions.download')}
                    {selected.length > 0 && ` (${selected.length})`}
                </ActionButton>
                <ActionButton
                    onClick={onSend}
                    active
                    className={styles.button}
                    disabled={
                        !clientId ||
                        !petId ||
                        selected.length === 0 ||
                        base64PdfLoading
                    }
                >
                    <span className={styles.buttonHint}>
                        {translate('vetspireExtension.infoPDFs.noClientError')}
                    </span>
                    {translate('vetspireExtension.infoPDFs.actions.mail')}
                    {selected.length > 0 && ` (${selected.length})`}
                </ActionButton>
            </div>
        </>
    );
}
