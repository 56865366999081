import { Theme } from '@mui/material/styles';
import { CSSObject } from 'tss-react';

type Classes = 'root' | 'tooltip' | 'arrow';

export interface Styles {
    classes: Record<Classes, string>;
}

export const getStyles = (theme: Theme): Record<Classes, CSSObject> => ({
    root: {},
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'var(--light-info-color)',
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
        fontSize: 14,
        fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
        fontWeight: 500,
    },
    arrow: {
        color: theme.palette.common.white,
    },
});
