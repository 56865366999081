import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import { WithFlyout } from 'lib/types';
import { CreditCard } from 'api/types';
import { Order } from 'api/clients/queries';
import { GraphQLGiftCardWithDetails } from '@bondvet/types/giftCards';
import styles from './DueInvoices.module.scss';
import DueInvoice from '../DueInvoice';
import ChargeInvoice from '../ChargeInvoice';

type DueInvoicesProps = {
    clientId: string;
    classes?: Partial<WithFlyout<typeof styles>>;
    defaultCard: CreditCard | null;
    orders: ReadonlyArray<Order>;
    refetchData: () => Promise<void>;
    giftCardsEnabled: boolean;
    giftCardsLoading: boolean;
    giftCardsTotal: number;
    activeGiftCards: ReadonlyArray<GraphQLGiftCardWithDetails>;
    isLoading: boolean;
};

function DueInvoices({
    clientId,
    classes,
    defaultCard,
    orders,
    refetchData,
    giftCardsEnabled,
    giftCardsLoading,
    giftCardsTotal,
    activeGiftCards,
    isLoading,
}: DueInvoicesProps): React.ReactElement {
    const translate = useTranslate();
    const [selectedInvoice, setSelectedInvoice] = React.useState<Order | null>(
        null,
    );

    const handleSelectInvoice = (invoice: Order) => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setSelectedInvoice(invoice);
            });
        });
    };

    const clearSelectedInvoice = () => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setSelectedInvoice(null);
                refetchData().then();
            });
        });
    };

    return (
        <>
            <div
                className={classnames(
                    classes?.flyoutBlock,
                    styles.flyoutBlock,
                    styles.root,
                )}
            >
                <header>
                    <span className={styles.flyoutTitle}>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.title',
                        )}
                    </span>
                </header>
                <div className={styles.row}>
                    <h4>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.date',
                        )}
                    </h4>
                    <h4>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.provider',
                        )}
                    </h4>
                    <h4>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.clinic',
                        )}
                    </h4>
                    <h4>
                        {translate(
                            'vetspireExtension.clientDetails.payments.dueInvoices.due',
                        )}
                    </h4>
                </div>
                {isLoading && (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                )}
                {orders.map((invoice) => (
                    <DueInvoice
                        invoice={invoice}
                        key={invoice.id}
                        className={styles.row}
                        chargeEnabled={
                            Boolean(defaultCard) ||
                            invoice.dueAmount / 100 <= giftCardsTotal
                        }
                        onCharge={handleSelectInvoice}
                    />
                ))}
            </div>

            {selectedInvoice && (
                <ChargeInvoice
                    clientId={clientId}
                    defaultCard={defaultCard}
                    invoice={selectedInvoice}
                    giftCardsLoading={giftCardsLoading}
                    activeGiftCards={activeGiftCards}
                    giftCardsEnabled={giftCardsEnabled}
                    onClose={clearSelectedInvoice}
                />
            )}
        </>
    );
}

export default DueInvoices;
