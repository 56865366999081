import { gql } from '@apollo/client';
import {
    UpdatePatientPlanAdditionsResult,
    UpdatePatientPlanAdditionsVariables,
} from '@bondvet/types/patientPlans';
import usePatientPlansMutation from 'hooks/usePatientPlansMutation';

const updatePatientPlanAdditionsMutation = gql`
    mutation updatePatientPlanAdditions(
        $id: ID!
        $input: PatientPlanAdditionsInput!
    ) {
        updatePatientPlanAdditions(id: $id, input: $input) {
            success
        }
    }
`;

export default function useUpdatePatientPlanAdditionsMutation() {
    return usePatientPlansMutation<
        UpdatePatientPlanAdditionsResult,
        UpdatePatientPlanAdditionsVariables
    >(updatePatientPlanAdditionsMutation);
}
