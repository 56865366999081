import * as React from 'react';
import { format } from 'date-fns';
import { ApolloQueryResult } from '@apollo/client';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import { ConversationalSmsMessage } from '@bondvet/types/textMessaging';
import uniq from 'lodash/uniq';

import usePrevious from 'hooks/usePrevious';
import Message from '../Message';
import useConversationalTextMessagingSettings from '../../hooks/useConversationalTextMessagingSettings';
import { ReactComponent as NoHistorySVG } from '../../assets/noHistory.svg';
import {
    CachedUserConversationHistoryQueryResult,
    ClientWithPatients,
} from '../../api';

import SendOutboundMessageModal from '../SendOutboundMessageModal';
import OutboundMessageBanner from '../OutboundMessageBanner';
import TopPanel from '../TopPanel/TopPanel';
import ImagePreviewModal from '../ImagePreviewModal';

import styles from './ConversationPane.module.scss';

type ConversationsPaneProps = {
    selectedClient: ClientWithPatients | undefined | null;
    setSelectedClient: (value: ClientWithPatients | null) => void;
    latestMessages: readonly ConversationalSmsMessage[] | undefined | null;
    latestMessagesLoading: boolean;
    refetchLatestMessages: () => Promise<
        ApolloQueryResult<CachedUserConversationHistoryQueryResult>
    >;
    recentConversationHistoryLoading: boolean;
    updatingConversationUnreadStatus: boolean;
    markAsReadOrUnread: (unread: boolean) => void;
    loadMore: () => Promise<void>;
    recent: boolean;
    unread: boolean;
    hasMore?: boolean;
    scrollToMessageId?: string | null;
    hasOpenTicket?: boolean;
    hasOpenTicketLoading?: boolean;
    eventTriggeredReload?: boolean;
    initialRecentHistorySync?: boolean;
    className?: string;
};

export default function ConversationPane({
    selectedClient,
    setSelectedClient,
    latestMessages,
    latestMessagesLoading,
    refetchLatestMessages,
    recentConversationHistoryLoading,
    updatingConversationUnreadStatus,
    markAsReadOrUnread,
    loadMore,
    hasMore,
    unread,
    recent,
    scrollToMessageId,
    hasOpenTicket = true,
    hasOpenTicketLoading = true,
    eventTriggeredReload = false,
    initialRecentHistorySync = false,
    className,
}: ConversationsPaneProps): React.ReactElement {
    const translate = useTranslate();
    const { conversationalTextingSettings } =
        useConversationalTextMessagingSettings();

    const [showSendTextModal, setShowSendTextModal] =
        React.useState<boolean>(false);

    const [showImagePreviewModal, setShowImagePreviewModal] =
        React.useState<boolean>(false);

    const [selectedImageSrc, setSelectedImageSrc] = React.useState<
        string | undefined
    >(undefined);

    const sortedMessages = React.useMemo(() => {
        return uniq(
            [...(latestMessages || [])].sort((m1, m2) =>
                m1.datetime > m2.datetime ? -1 : 1,
            ),
        );
    }, [latestMessages]);

    const scrollToMessageRef = React.useRef<HTMLDivElement>();
    const oldScrollToMessageId = usePrevious(scrollToMessageId);

    React.useEffect(() => {
        if (scrollToMessageId !== oldScrollToMessageId) {
            scrollToMessageRef?.current?.scrollIntoView({
                behavior: 'instant',
            });
        }
    }, [scrollToMessageRef, scrollToMessageId, oldScrollToMessageId]);

    if (!selectedClient) {
        return (
            <div
                className={classnames(
                    styles.container,
                    styles.noHistory,
                    className,
                )}
            >
                <NoHistorySVG />
                <p>
                    {translate(
                        'vetspireExtension.texting.conversationPane.empty',
                    )}
                </p>
            </div>
        );
    }

    return (
        <div className={classnames(styles.container, className)}>
            <TopPanel
                selectedClient={selectedClient}
                recent={recent}
                markAsReadOrUnread={markAsReadOrUnread}
                updatingConversationUnreadStatus={
                    updatingConversationUnreadStatus
                }
                unread={unread}
            ></TopPanel>
            <div className={styles.conversationContainer}>
                <div className={styles.sinceDateBlurb}>
                    {translate(
                        'vetspireExtension.texting.conversationPane.sinceDateBlurb',
                        {
                            date: (
                                <span className={styles.date}>
                                    {conversationalTextingSettings?.goLiveDate
                                        ? format(
                                              conversationalTextingSettings.goLiveDate,
                                              'MMMM do, yyy',
                                          )
                                        : translate(
                                              'vetspireExtension.texting.conversationPane.sinceDateFallback',
                                          ).toString()}
                                </span>
                            ),
                        },
                    )}
                </div>
                {!latestMessages ||
                    (latestMessages.length === 0 &&
                        (latestMessagesLoading ? (
                            <div className={styles.loadingWrapper}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <div
                                className={classnames(
                                    styles.messages,
                                    styles.noHistory,
                                )}
                            >
                                <NoHistorySVG />
                                <p>
                                    {translate(
                                        'vetspireExtension.texting.conversationPane.clientNoHistory',
                                    )}
                                </p>
                            </div>
                        )))}
                {(latestMessages?.length || 0) > 0 && (
                    <div
                        id="scrollableDiv"
                        className={styles.infiniteScrollWrapper}
                    >
                        <InfiniteScroll
                            dataLength={latestMessages?.length ?? 0}
                            next={loadMore}
                            inverse
                            hasMore={
                                (hasMore &&
                                    !recentConversationHistoryLoading &&
                                    !latestMessagesLoading) ??
                                false
                            }
                            scrollableTarget="scrollableDiv"
                            className={styles.messages}
                            loader={
                                <div className={styles.loadingWrapper}>
                                    <CircularProgress />
                                </div>
                            }
                        >
                            {sortedMessages?.map((message) => {
                                return (
                                    <Message
                                        key={`${message._id}`}
                                        message={message}
                                        onImageClick={(src: string) => {
                                            setSelectedImageSrc(src);
                                            setShowImagePreviewModal(true);
                                        }}
                                        scrollToMessageRef={
                                            scrollToMessageId &&
                                            message._id === scrollToMessageId
                                                ? scrollToMessageRef
                                                : null
                                        }
                                    />
                                );
                            })}
                            <div className={styles.topOfScroll}>
                                {(recentConversationHistoryLoading ||
                                    latestMessagesLoading) &&
                                    translate(
                                        'vetspireExtension.texting.conversationPane.loadMoreDisabled',
                                    )}
                                {!recentConversationHistoryLoading &&
                                    !latestMessagesLoading &&
                                    (hasMore
                                        ? translate(
                                              'vetspireExtension.texting.conversationPane.loadMore',
                                          )
                                        : translate(
                                              'vetspireExtension.texting.conversationPane.endOfLoadMore',
                                          ))}
                            </div>
                            <div id="an_empty_div_to_help_with_scrolling"></div>
                        </InfiniteScroll>
                    </div>
                )}
                {selectedClient && (
                    <OutboundMessageBanner
                        selectedClient={selectedClient}
                        setSelectedClient={setSelectedClient}
                        setShowSendTextModal={setShowSendTextModal}
                        hasOpenTicket={hasOpenTicket}
                        loadingNewMessages={
                            latestMessagesLoading || initialRecentHistorySync
                        }
                        loadingOpenTickets={hasOpenTicketLoading}
                        eventTriggeredReload={eventTriggeredReload}
                    />
                )}

                {showSendTextModal && selectedClient && (
                    <SendOutboundMessageModal
                        onClose={() => setShowSendTextModal(false)}
                        client={selectedClient}
                        refetchCachedConversations={refetchLatestMessages}
                    />
                )}

                {showImagePreviewModal && selectedImageSrc && (
                    <ImagePreviewModal
                        onClose={() => setShowImagePreviewModal(false)}
                        src={selectedImageSrc}
                    />
                )}
            </div>
        </div>
    );
}
