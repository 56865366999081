import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import type { OperationResult } from '@bondvet/types';
import { useMutation } from '@apollo/client';
import { uploadAndSendSurgeryForm as uploadAndSendSurgeryFormMutation } from 'api/bond/mutations';

type Mutation = {
    uploadAndSendSurgeryForm: OperationResult;
};

export interface Variables {
    surgeryFormId: string;
    encounterId: string;
    medicationIds: string[];
    optionsId?: string | null;
    note?: string | null;
}

export type UploadAndSendSurgeryForm = {
    loading: boolean;
    error?: string | null;
    uploadAndSendSurgeryForm: (
        surgeryFormId: string,
        encounterId: string,
        medicationIds: string[],
        optionsId: string | null,
        note: string,
    ) => void;
};

export default function useUploadAndSendSurgeryForm(): UploadAndSendSurgeryForm {
    const [mutate, { loading, error, data }] = useMutation<Mutation, Variables>(
        uploadAndSendSurgeryFormMutation,
        {
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );

    const uploadAndSendSurgeryForm = (
        surgeryFormId: string,
        encounterId: string,
        medicationIds: string[],
        optionsId: string | null,
        note: string,
    ) => {
        const variables: Variables = {
            surgeryFormId,
            encounterId,
            medicationIds,
        };

        if (optionsId) {
            variables.optionsId = optionsId;
        }

        if (note) {
            variables.note = note;
        }

        mutate({ variables }).then();
    };

    return {
        loading,
        error: error?.message || data?.uploadAndSendSurgeryForm.error || null,
        uploadAndSendSurgeryForm,
    };
}
