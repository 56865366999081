import * as React from 'react';
import moment from 'moment-timezone';
import { QueryResult } from '@apollo/client';
import { LookupStats } from 'components/LookupSelect/types';
import { Task, TaskCategory, TasksQueryResult } from 'api/tasks/queries';
import { Provider } from 'api/providers/queries';
import useTimezone from 'hooks/useTimezone';
import {
    DateFilterType,
    DateStatsNumbers as DateStats,
} from 'components/DateFilter/types';
import { DecoratedTask } from '../types';

interface DecoratedTasksWithStats {
    decoratedTasks: DecoratedTask[];
    dueDateStats: DateStats;
    taskCategoryStats: LookupStats;
    assigneeStats: LookupStats;
}

interface UseDecoratedTasksOptions {
    tasksQueryResult: QueryResult<TasksQueryResult>;
    selectedTaskCategories: TaskCategory[];
    selectedProviders: Provider[];
    currentDateFilterType: DateFilterType;
}

export default function useDecoratedTasks({
    tasksQueryResult,
    selectedTaskCategories,
    selectedProviders,
    currentDateFilterType,
}: UseDecoratedTasksOptions): DecoratedTasksWithStats {
    const [decoratedTasksWithStats, setDecoratedTasksWithStats] =
        React.useState<DecoratedTasksWithStats>({
            decoratedTasks: [],
            dueDateStats: {
                all: 0,
                past: 0,
                today: 0,
                future: 0,
            },
            taskCategoryStats: {},
            assigneeStats: {},
        });

    const timezone = useTimezone();

    // decorate tasks with stats and mark them as visible/invisible
    React.useEffect(() => {
        if (!tasksQueryResult.loading && !tasksQueryResult.error) {
            const today = moment.tz(timezone).format('YYYY-MM-DD');

            const newStats: DateStats = {
                all: 0,
                past: 0,
                today: 0,
                future: 0,
            };

            const newTaskCategoryStats: LookupStats = {};
            const newAssigneeStats: LookupStats = {};

            const newDecoratedTasks: DecoratedTask[] = (
                tasksQueryResult.data?.tasks || []
            )
                .slice()
                .sort((a, b) => a.dueDate.localeCompare(b.dueDate))
                .reduce((filtered: DecoratedTask[], task: Task) => {
                    if (task.done || task.deleted) {
                        return filtered;
                    }

                    const taskCategoryFilter =
                        selectedTaskCategories.length === 0
                            ? true
                            : !!selectedTaskCategories.find(
                                  (taskCategory) =>
                                      taskCategory.id === task.taskCategory?.id,
                              );

                    const assigneeFilter =
                        selectedProviders.length === 0
                            ? true
                            : task.assignees.reduce(
                                  (visible, assignee) =>
                                      visible ||
                                      !!selectedProviders.find(
                                          (provider) =>
                                              provider.id === assignee.id,
                                      ),
                                  false,
                              );

                    let dateFilterType: DateFilterType;

                    if (taskCategoryFilter && assigneeFilter) {
                        newStats.all += 1;
                    }

                    let dateFilter = true;

                    if (task.dueDate < today) {
                        if (taskCategoryFilter && assigneeFilter) {
                            newStats.past += 1;
                        }
                        dateFilterType = DateFilterType.past;

                        if (
                            currentDateFilterType !== DateFilterType.all &&
                            currentDateFilterType !== DateFilterType.past
                        ) {
                            dateFilter = false;
                        }
                    } else if (task.dueDate === today) {
                        if (taskCategoryFilter && assigneeFilter) {
                            newStats.today += 1;
                        }
                        dateFilterType = DateFilterType.today;

                        if (
                            currentDateFilterType !== DateFilterType.all &&
                            currentDateFilterType !== DateFilterType.today
                        ) {
                            dateFilter = false;
                        }
                    } else {
                        if (taskCategoryFilter && assigneeFilter) {
                            newStats.future += 1;
                        }
                        dateFilterType = DateFilterType.future;

                        if (
                            currentDateFilterType !== DateFilterType.all &&
                            currentDateFilterType !== DateFilterType.future
                        ) {
                            dateFilter = false;
                        }
                    }

                    const visible =
                        taskCategoryFilter && assigneeFilter && dateFilter;

                    if (task.taskCategory) {
                        if (!newTaskCategoryStats[task.taskCategory.id]) {
                            newTaskCategoryStats[task.taskCategory.id] = 1;
                        } else {
                            newTaskCategoryStats[task.taskCategory.id] += 1;
                        }
                    }
                    task.assignees.forEach((assignee) => {
                        if (!newAssigneeStats[assignee.id]) {
                            newAssigneeStats[assignee.id] = 1;
                        } else {
                            newAssigneeStats[assignee.id] += 1;
                        }
                    });

                    return [
                        ...filtered,
                        {
                            ...task,
                            dateFilterType,
                            visible,
                        },
                    ];
                }, []);

            setDecoratedTasksWithStats({
                decoratedTasks: newDecoratedTasks,
                dueDateStats: newStats,
                taskCategoryStats: newTaskCategoryStats,
                assigneeStats: newAssigneeStats,
            });
        }
    }, [
        tasksQueryResult,
        currentDateFilterType,
        selectedProviders,
        selectedTaskCategories,
        timezone,
    ]);

    return decoratedTasksWithStats;
}
