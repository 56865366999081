import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import {
    Location,
    locationsQuery,
    LocationsQueryResult,
} from 'api/locations/queries';
import { LocationsSelect } from 'components/LookupSelect';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import WeekSelect from 'components/WeekSelect';
import { SurgeryReferralTab } from '../../types';
import ChecklistAppointment from '../ChecklistAppointment';
import styles from './Checklist.module.scss';
import useSurgeryAppointments from '../../hooks/useSurgeryAppointments';

type ChecklistProps = {
    onChangeTab: (tab: SurgeryReferralTab) => void;
};

function Checklist({ onChangeTab }: ChecklistProps): React.ReactElement {
    const locationsQueryResult = useQuery<LocationsQueryResult>(
        locationsQuery,
        {
            fetchPolicy: 'cache-and-network',
        },
    );
    const [selectedDay, setSelectedDay] = React.useState<Moment>(
        moment.tz().startOf('week'),
    );
    const [selectedLocations, setSelectedLocations] = React.useState<
        Location[]
    >([]);

    const onChangeLocation = (locations: Location[]) => {
        setSelectedLocations(locations);
    };

    const { appointments, isLoading } = useSurgeryAppointments(
        selectedLocations?.[0]?.id || null,
        selectedDay,
    );

    const onChangeDay = (newDay: Moment): void => {
        setSelectedDay(newDay);
    };

    return (
        <div className={styles.container}>
            <LocationsSelect
                isMulti={false}
                locationsQueryResult={locationsQueryResult}
                selectedLocations={selectedLocations}
                onChange={onChangeLocation}
            />
            <div className={styles.daySelect}>
                <WeekSelect week={selectedDay} onChange={onChangeDay} />
            </div>
            {isLoading && (
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            )}
            <div className={styles.appointments}>
                {appointments.map((appointment) => (
                    <ChecklistAppointment
                        key={appointment.id}
                        appointment={appointment}
                        onChangeTab={onChangeTab}
                    />
                ))}
            </div>
        </div>
    );
}

export default Checklist;
