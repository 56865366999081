import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelCircleIcon from '@mui/icons-material/Cancel';

import classnames from 'classnames';
import styles from './PaymentAdminMessage.module.scss';

interface PaymentAdminMessageProps {
    enabled: boolean;
    month: string;
}

const PaymentAdminMessage: React.FunctionComponent<
    PaymentAdminMessageProps
> = ({ enabled, month }) => {
    const translate = useTranslate();

    return (
        <div className={styles.adminMessageContainer}>
            {enabled ? (
                <div
                    className={classnames(styles.adminMessage, styles.enabled)}
                >
                    <CheckCircleIcon className={styles.checkCircleIcon} />
                    <span>
                        {translate(
                            'vetspireExtension.providerPayment.totalBonus.enabledForProvider',
                            { date: month },
                        )}
                    </span>
                </div>
            ) : (
                <div
                    className={classnames(styles.adminMessage, styles.disabled)}
                >
                    <CancelCircleIcon className={styles.cancelCircleIcon} />

                    <span>
                        {translate(
                            'vetspireExtension.providerPayment.totalBonus.disabledForProvider',
                            { date: month },
                        )}
                    </span>
                </div>
            )}
        </div>
    );
};

export default PaymentAdminMessage;
