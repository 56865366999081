import useLocationSpecificVetspireSettings from 'hooks/useLocationSpecificVetspireSettings';
import * as React from 'react';
import { openVetspirePage } from 'lib/vetspireActions';
import { useApolloClient, gql } from '@apollo/client';
import {
    parse,
    visit,
    Kind,
    type OperationDefinitionNode,
    type FieldNode,
    type DocumentNode,
    type FragmentDefinitionNode,
} from 'graphql';
import type { Conversation } from '@bondvet/types/generated/vetspire';
import {
    MESSAGE_SOURCE_NAVIGATION,
    MESSAGE_TARGET_TWEAKS,
    sendBackgroundMessage,
} from 'lib/backgroundMessages';
import { vetspireContext } from 'context/VetspireContext';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import useTranslate from './useTranslate';
import useClientAndPatientId from './useClientAndPatientId';
import useVetspireQuery from './useVetspireQuery';
import useRequestInterceptor, {
    type InterceptorPayload,
    type InterceptorResponse,
} from './useRequestInterceptor';
import useVetspireSettings from './useVetspireSettings';
import useViewerSettings from './useViewerSettings';

const GET_CONVERSATION_OPERATION_NAME = 'getConversation';

const SEND_MESSAGE_OPERATION_NAME = 'sendMessage';

type HideButtonMessage = {
    labels: string | string[];
    parentSelector: string;
    logDescription: string;
};

function addMediumField<NodeType extends FieldNode | FragmentDefinitionNode>(
    node: NodeType,
): NodeType {
    const selections = node.selectionSet?.selections ?? [];

    const hasMediumField = selections.some(
        (selection) =>
            selection.kind === Kind.FIELD && selection.name?.value === 'medium',
    );

    if (hasMediumField) {
        return node;
    }

    return {
        ...node,
        selectionSet: {
            ...node.selectionSet,
            selections: [
                ...selections,
                {
                    kind: Kind.FIELD,
                    name: { kind: Kind.NAME, value: 'medium' },
                },
            ],
        },
    } as NodeType;
}

export function transformGetConversationQuery(query: string): DocumentNode {
    const parsedQuery = parse(query);
    return visit(parsedQuery, {
        OperationDefinition(operationDefinitionNode): OperationDefinitionNode {
            if (operationDefinitionNode.operation === 'query') {
                return {
                    ...operationDefinitionNode,
                    name: {
                        kind: Kind.NAME,
                        value: `BOND_${operationDefinitionNode.name?.value ?? GET_CONVERSATION_OPERATION_NAME}`,
                    },
                };
            }

            return operationDefinitionNode;
        },
        Field(node): FieldNode {
            if (node.name?.value === 'conversation') {
                return addMediumField(node);
            }

            return node;
        },
        FragmentDefinition(node): FragmentDefinitionNode {
            if (node.typeCondition.name.value === 'Conversation') {
                return addMediumField(node);
            }

            return node;
        },
    });
}

export function transformSendMessageMutation(mutation: string): DocumentNode {
    const parsedMutation = parse(mutation);
    return visit(parsedMutation, {
        OperationDefinition(operationDefinitionNode): OperationDefinitionNode {
            if (operationDefinitionNode.operation === 'mutation') {
                return {
                    ...operationDefinitionNode,
                    name: {
                        kind: Kind.NAME,
                        value: `BOND_${operationDefinitionNode.name?.value ?? SEND_MESSAGE_OPERATION_NAME}`,
                    },
                };
            }

            return operationDefinitionNode;
        },
    });
}

const PATIENT_WRAPPER_SELECTOR = '.patient-wrapper';

const conversationIdRegex = /^\/clients\/\d+\/patients\/\d+\/messages\/(\d+)$/;
const messagesRegex = /^\/clients\/\d+\/patients\/\d+\/messages/;

const conversationMediumQuery = gql`
    query conversationMediumQuery($id: ID!) {
        conversation(id: $id) {
            medium
        }
    }
`;

const smsConversationalQuery = gql`
    query smsConversationalQuery($clientId: ID!) {
        client(id: $clientId) {
            externalCommPreferences {
                smsConversational: postcardMarketing
            }
        }
    }
`;

type ConversationMediumQueryResult = {
    conversation: null | Pick<Conversation, 'medium'>;
};

type ConversationMediumQueryVariables = {
    id: string;
};

type SmsConversationalQueryResult = {
    client: null | {
        externalCommPreferences:
            | null
            | readonly {
                  smsConversational: null | boolean;
              }[];
    };
};

type SmsConversationalQueryVariables = {
    clientId: string;
};

function sendHideButtonMessage(
    labels: string | string[],
    logDescription?: string,
) {
    window.requestAnimationFrame(() => {
        sendBackgroundMessage(
            MESSAGE_SOURCE_NAVIGATION,
            [MESSAGE_TARGET_TWEAKS],
            'hideButton',
            {
                labels,
                parentSelector: PATIENT_WRAPPER_SELECTOR,
                logDescription:
                    logDescription ?? Array.isArray(labels)
                        ? labels[0]
                        : labels,
            } as HideButtonMessage,
        );
    });
}

function sendShowButtonMessage(
    labels: string | string[],
    logDescription?: string,
) {
    window.requestAnimationFrame(() => {
        sendBackgroundMessage(
            MESSAGE_SOURCE_NAVIGATION,
            [MESSAGE_TARGET_TWEAKS],
            'showButton',
            {
                labels,
                parentSelector: PATIENT_WRAPPER_SELECTOR,
                logDescription:
                    logDescription ?? Array.isArray(labels)
                        ? labels[0]
                        : labels,
            } as HideButtonMessage,
        );
    });
}

function hideNewSmsButton() {
    sendHideButtonMessage('New SMS');
}

function showNewSmsButton() {
    sendShowButtonMessage('New SMS');
}

function hideSmsUi() {
    sendHideButtonMessage(
        ['Close Conversation', 'Re-open Conversation'],
        'close or re-open conversation',
    );

    // and trigger the overlay over the new message text areas
    window.requestAnimationFrame(() => {
        sendBackgroundMessage(
            MESSAGE_SOURCE_NAVIGATION,
            [MESSAGE_TARGET_TWEAKS],
            'coverNewMessageInputs',
        );
    });
}

function showSmsUi() {
    sendShowButtonMessage(
        ['Close Conversation', 'Re-open Conversation'],
        'close or re-open conversation',
    );

    // and trigger the overlay over the new message text areas
    window.requestAnimationFrame(() => {
        sendBackgroundMessage(
            MESSAGE_SOURCE_NAVIGATION,
            [MESSAGE_TARGET_TWEAKS],
            'uncoverNewMessageInputs',
        );
    });
}

function showOptedOutWarning() {
    window.requestAnimationFrame(() => {
        sendBackgroundMessage(
            MESSAGE_SOURCE_NAVIGATION,
            [MESSAGE_TARGET_TWEAKS],
            'showOptedOutWarning',
        );
    });
}

function hideOptedOutWarning() {
    window.requestAnimationFrame(() => {
        sendBackgroundMessage(
            MESSAGE_SOURCE_NAVIGATION,
            [MESSAGE_TARGET_TWEAKS],
            'hideOptedOutWarning',
        );
    });
}

export default function useTextingRestrictions() {
    const translate = useTranslate();
    const { pathName } = React.useContext(vetspireContext);
    const isMessagesView = React.useMemo(
        () => messagesRegex.test(pathName),
        [pathName],
    );

    const apolloClient = useApolloClient();

    const {
        disableNonDoctorTextMessaging: globalDisableNonDoctorTextMessaging,
        warnOnOptedOutTexting: globalWarnOnOptedOutTexting,
    } = useVetspireSettings();
    const { viewer } = useViewerSettings();
    const {
        disableNonDoctorTextMessaging: locationDisableNonDoctorTextMessaging,
        warnOnOptedOutTexting: locationWarnOnOptedOutTexting,
    } = useLocationSpecificVetspireSettings();

    const disableNonDoctorTextMessaging =
        globalDisableNonDoctorTextMessaging ||
        locationDisableNonDoctorTextMessaging;
    const warnOnOptedOutTexting =
        globalWarnOnOptedOutTexting || locationWarnOnOptedOutTexting;

    // don't hide any buttons until we have loaded the viewer!
    const hideButtons =
        !!viewer && !viewer.isVeterinarian && disableNonDoctorTextMessaging;

    // when a user either directly goes to a conversation URL
    // or they simply reload the page on a conversations page
    // then we will not be able to register the request interceptor
    // in time. Thus, we have to check the pathname as well
    // and load the conversation medium ourselves
    const conversationId = React.useMemo(() => {
        return pathName.match(conversationIdRegex)?.[1] ?? null;
    }, [pathName]);
    const { data: conversationData } = useVetspireQuery<
        ConversationMediumQueryResult,
        ConversationMediumQueryVariables
    >(conversationMediumQuery, {
        skip: conversationId === null,
        variables: { id: conversationId ?? '' },
    });
    const isSmsConversation = conversationData?.conversation?.medium === 'SMS';

    const [hideOpenCloseButtons, setHideOpenCloseButtons] =
        React.useState(false);

    // reset hideOpenCloseButtons when the conversationId changes
    React.useEffect(() => {
        setHideOpenCloseButtons(false);
    }, [conversationId]);

    React.useEffect(() => {
        if (hideOpenCloseButtons && hideButtons) {
            hideSmsUi();
        } else {
            showSmsUi();
        }
    }, [hideOpenCloseButtons, hideButtons]);

    const showWarning =
        isSmsConversation &&
        !!viewer &&
        viewer.isVeterinarian &&
        warnOnOptedOutTexting;

    const { clientId, patientId } = useClientAndPatientId();

    const { data: clientData } = useVetspireQuery<
        SmsConversationalQueryResult,
        SmsConversationalQueryVariables
    >(smsConversationalQuery, {
        skip: clientId === null || !showWarning,
        variables: { clientId: clientId ?? '' },
        fetchPolicy: 'cache-and-network',
    });

    const isOptedOut = React.useMemo(() => {
        const externalCommPreferences =
            clientData?.client?.externalCommPreferences;

        if (!externalCommPreferences || externalCommPreferences.length === 0) {
            return false;
        }

        const [{ smsConversational }] = externalCommPreferences;

        return !smsConversational;
    }, [clientData?.client]);

    React.useEffect(() => {
        if (isOptedOut && showWarning) {
            showOptedOutWarning();
        } else {
            hideOptedOutWarning();
        }
    }, [isOptedOut, showWarning]);

    React.useEffect(() => {
        if (isMessagesView && hideButtons) {
            hideNewSmsButton();
        } else {
            showNewSmsButton();
        }
    }, [isMessagesView, hideButtons]);

    React.useEffect(() => {
        if (isSmsConversation && hideButtons) {
            setHideOpenCloseButtons(true);
        }
    }, [isSmsConversation, hideButtons]);

    const onGetConversation = React.useCallback(
        async (
            interceptorPayload: InterceptorPayload,
        ): Promise<InterceptorResponse> => {
            if (!hideButtons) {
                return {
                    runOriginalRequest: true,
                };
            }

            const { variables, query } = interceptorPayload;
            const updatedQuery = transformGetConversationQuery(query);

            // currently, we just replace `window.fetch`, so we wouldn't
            // have to change the operation name. but in the future,
            // we might switch to a more robust implementation, like
            // a service worker – so let's make sure we won't still
            // intercept that operation then as well

            // create the request ourselves
            const { data } =
                await apolloClient.query<ConversationMediumQueryResult>({
                    query: updatedQuery,
                    variables,
                    context: {
                        clientName: GRAPHQL_CLIENT_NAMES.vetspire,
                    },
                });

            // check the response
            const loadedConversationIsSmsConversation =
                data?.conversation?.medium === 'SMS';

            if (loadedConversationIsSmsConversation) {
                setHideOpenCloseButtons(true);

                if (showWarning && isOptedOut) {
                    showOptedOutWarning();
                } else {
                    hideOptedOutWarning();
                }
            }

            return {
                runOriginalRequest: false,
                response: data,
            };
        },
        [hideButtons, apolloClient, showWarning, isOptedOut],
    );

    const onSendMessage = React.useCallback(
        async (
            interceptorPayload: InterceptorPayload,
        ): Promise<InterceptorResponse> => {
            if (!showWarning || !isOptedOut) {
                return {
                    runOriginalRequest: true,
                };
            }

            const { query, variables } = interceptorPayload;
            const theApolloClient = apolloClient;

            window.setTimeout(() => {
                if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                        translate(
                            'vetspireExtension.texting.confirmTextingOptedOutClient',
                        ) as string,
                    )
                ) {
                    theApolloClient
                        .mutate({
                            mutation: transformSendMessageMutation(query),
                            variables,
                        })
                        .then(
                            () => {
                                openVetspirePage(
                                    `/clients/${clientId}/patients/${patientId}/messages`,
                                );
                                setTimeout(() => {
                                    openVetspirePage(
                                        `/clients/${clientId}/patients/${patientId}/messages/${conversationId}`,
                                    );
                                }, 100);
                            },
                            (error) => {
                                console.warn(
                                    'error trying to send text message:',
                                    error.message,
                                    error,
                                );
                            },
                        );
                }
            }, 0);
            return {
                runOriginalRequest: false,
                // fake a response so that Vetspire clears
                // the UI
                response: {
                    sendMessage: { upsertMessage: { body: '', id: '123' } },
                },
            };
        },
        [
            showWarning,
            apolloClient,
            isOptedOut,
            clientId,
            patientId,
            conversationId,
            translate,
        ],
    );

    useRequestInterceptor(GET_CONVERSATION_OPERATION_NAME, onGetConversation);

    useRequestInterceptor(
        SEND_MESSAGE_OPERATION_NAME,
        onSendMessage,
        undefined,
        !showWarning || !isOptedOut,
    );
}
