import * as React from 'react';
import useLocationSpecificVetspireSettings from 'hooks/useLocationSpecificVetspireSettings';
import useVetspireSettings from 'hooks/useVetspireSettings';
import useVetspireContext from 'hooks/useVetspireContext';
import useLocationId from 'hooks/useLocationId';
import {
    addBackgroundMessageListener,
    MESSAGE_SOURCE_NAVIGATION,
    MESSAGE_TARGET_TWEAKS,
    sendBackgroundMessage,
} from 'lib/backgroundMessages';
import {
    requestCurrentScheduleDate,
    type VetspireExtensionMessage,
} from 'lib/vetspireActions';
import { formatInTimeZone } from 'date-fns-tz';
import useExpandedAccessSlotsInDateRange from './useExpandedAccessSlotsInDateRange';
import { ExpandedAccessSlotWithProvider } from '../types';

const SCHEDULE_SCREEN_TIME_FORMAT = 'h:mmaaa';

export type SetExpandedAccessSlotsMessage = {
    slots: readonly string[];
    provider: string;
    id: string;
};

function sendSlotsMessage(
    expandedAccessSlots: readonly ExpandedAccessSlotWithProvider[],
    enabled: boolean,
    timezone: string,
): void {
    const slots: SetExpandedAccessSlotsMessage[] = [];

    for (const { _id: id, availableSlots, provider } of expandedAccessSlots) {
        slots.push({
            id,
            provider: provider?.name ?? '',
            slots: availableSlots.map((slot) =>
                formatInTimeZone(slot, timezone, SCHEDULE_SCREEN_TIME_FORMAT),
            ),
        });
    }

    sendBackgroundMessage(
        MESSAGE_SOURCE_NAVIGATION,
        [MESSAGE_TARGET_TWEAKS],
        'setExpandedAccessSlots',
        { slots, enabled },
    );
}

export default function useExpandedAccessSlotsOnSchedule(): void {
    const { selectedScheduleDate } = useVetspireContext();
    const { showExpandedAccessSlots: globalShowExpandedAccessSlots } =
        useVetspireSettings();
    const { showExpandedAccessSlots: locationShowExpandedAccessSlots } =
        useLocationSpecificVetspireSettings();

    const showExpandedAccessSlots =
        globalShowExpandedAccessSlots || locationShowExpandedAccessSlots;

    const locationId = useLocationId();
    const { loading, expandedAccessSlots, locationTimezone, refetch } =
        useExpandedAccessSlotsInDateRange(
            locationId,
            selectedScheduleDate ?? '',
            selectedScheduleDate ?? '',
            !showExpandedAccessSlots || selectedScheduleDate === null,
        );

    // clear access slots when locationId or showExpandedAccessSlots setting changes
    React.useEffect(() => {
        sendSlotsMessage(
            [],
            showExpandedAccessSlots,
            // we send an empty array. we don't need an actual timezone
            '',
        );
    }, [locationId, showExpandedAccessSlots]);

    React.useEffect(() => {
        if (!loading && expandedAccessSlots) {
            sendSlotsMessage(
                Object.values(expandedAccessSlots),
                showExpandedAccessSlots,
                locationTimezone,
            );
        }
    }, [
        loading,
        expandedAccessSlots,
        locationTimezone,
        showExpandedAccessSlots,
    ]);

    const listener = React.useCallback(
        (message: VetspireExtensionMessage) => {
            if (
                showExpandedAccessSlots &&
                message.action === 'requestExpandedAccessSlots'
            ) {
                refetch();
            }
        },
        [refetch, showExpandedAccessSlots],
    );

    React.useEffect(() => {
        const unsubscribe = addBackgroundMessageListener(
            MESSAGE_SOURCE_NAVIGATION,
            listener,
        );

        requestCurrentScheduleDate();

        return unsubscribe;
    }, [listener]);
}
