import * as React from 'react';
import classnames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Banner.module.scss';

export interface BannerContent {
    text: string;
    buttonText: string;
    onClick: () => void;
    onClose: () => void;
}

interface BannerProps {
    bannerContent: BannerContent | null;
}

const Banner: React.FunctionComponent<BannerProps> = ({ bannerContent }) => {
    if (!bannerContent) return null;

    return (
        <div className={classnames(styles.flyOutBlock, styles.bannerContainer)}>
            <span>{bannerContent.text}</span>
            <span className={styles.button} onClick={bannerContent.onClick}>
                {bannerContent.buttonText}
            </span>

            <CloseIcon
                className={styles.close}
                onClick={bannerContent.onClose}
            />
        </div>
    );
};

export default Banner;
