import useLazyClientDetails from 'hooks/useLazyClientDetails';
import * as React from 'react';
import { useAnalytics } from 'hooks/useAnalytics';
import useClientAndPatientId from 'hooks/useClientAndPatientId';
import { openFlyout, Page } from 'lib/vetspireActions';

interface UsePatientPlanSignUp {
    openSignUpPage: (providerId: string) => Promise<void>;
}

export default function usePatientPlanSignUp(): UsePatientPlanSignUp {
    const analytics = useAnalytics();
    const { clientId, patientId } = useClientAndPatientId();
    const [loadClientDetails] = useLazyClientDetails();

    const openSignUpPage = React.useCallback(
        async (providerId: string) => {
            const params = new URLSearchParams();

            if (clientId) {
                const result = await loadClientDetails({
                    variables: {
                        clientId,
                    },
                });

                if (result.data?.client?.email) {
                    params.set('email', result.data?.client?.email);
                }

                if (result.data?.client?.primaryLocationId) {
                    params.set(
                        'locationId',
                        result.data?.client?.primaryLocationId,
                    );
                }
            }

            if (patientId) {
                params.set('patientId', patientId);
            }

            params.set('trackingOrigin', 'vetspireExtension');
            params.set('trackingProviderId', providerId || '');

            analytics.trackEvent(Page.clientDetails, 'open_plan_signup');

            openFlyout(Page.patientPlanSignUp, `?${params.toString()}`);
        },
        [analytics, clientId, loadClientDetails, patientId],
    );

    return {
        openSignUpPage,
    };
}
