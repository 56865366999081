export enum AppointmentLogFilterType {
    all = 'all',
    cancelled = 'cancelled',
    rescheduled = 'rescheduled',
    noShow = 'noShow',
    blockoff = 'blockoff',
    create = 'create',
}

export interface AppointmentLogStats {
    all: number;
    cancelled: number;
    rescheduled: number;
    noShow: number;
    blockoff: number;
}
