import * as React from 'react';
import classnames from 'classnames';
import styles from './Duplicates.module.scss';
import useDuplicates from './useDuplicates';

export type DuplicatesProps = {
    classes?: Partial<typeof styles>;
    clientId: string;
};

function Duplicates({
    classes,
    clientId,
}: DuplicatesProps): React.ReactElement | null {
    const duplicates = useDuplicates(clientId);

    if (duplicates.length > 0) {
        return <div className={classnames(classes?.root, styles.root)} />;
    }

    return null;
}

export default Duplicates;
