import { Projection } from '../common';

export const EXTERNAL_REFERRALS_CUSTOMERIO_TRIGGER =
    'externalReferralsCustomerIoTrigger';
export const EXTERNAL_REFERRALS_CUSTOMERIO_CAMPAIGN =
    'externalReferralsCustomerIoCampaign';

export const LOCATION_INDEPENDENT_SETTINGS_COLLECTION_NAME =
    'settings.locationIndependent';

export const SMS_CONVERSATIONAL_SETTING_ENABLED =
    'smsConversationalSettingEnabled';

export const EXTENSION_PROMO_CODES_VALIDATION_ENABLED =
    'extensionPromoCodesValidationEnabled';

export const GIFT_CARDS_FEATURE_ENABLED = 'giftCardsFeatureEnabled';

export const TEAM_APP_DATA_TABS_ENABLED = 'teamAppDataTabsEnabled';

export const TEAM_APP_DASHBOARD_TILES_ENABLED = 'teamAppDashboardTilesEnabled';

export type LocationIndependentSettingType =
    | typeof EXTERNAL_REFERRALS_CUSTOMERIO_TRIGGER
    | typeof EXTERNAL_REFERRALS_CUSTOMERIO_CAMPAIGN
    | typeof SMS_CONVERSATIONAL_SETTING_ENABLED
    | typeof EXTENSION_PROMO_CODES_VALIDATION_ENABLED
    | typeof GIFT_CARDS_FEATURE_ENABLED
    | typeof TEAM_APP_DATA_TABS_ENABLED
    | typeof TEAM_APP_DASHBOARD_TILES_ENABLED;

export interface LocationIndependentSetting {
    _id: LocationIndependentSettingType;
    enabled?: boolean;
}

export const SETTINGS_PATH = 'settings';
export const INTAKE_FORM_SETTINGS_PATH = `settings/intakeForm`; // FlowJS doesn't accept template-literals for the types
export const LOCATION_TAGS_SETTINGS_PATH = `${SETTINGS_PATH}/locationTags`;
export const COMING_SOON_TAG_NAME = 'comingSoon';
export const PROMOTIONS_TAG_NAME = 'promotions';

export const FILES_PATH_EXTERNAL_REFERRALS =
    'vetspireExtension/uploads/externalReferrals/';
export const FILES_PATH_INFO_PDFS = 'vetspireExtension/dev/uploads/';

export const FRONT_OF_HOUSE_SCREENS_COLLECTION = 'settings.frontOfHouseScreens';

export interface FrontOfHouseScreen {
    _id: string;
    imageId: string;
    description?: string | null;
    createdAt: Date;
    updatedAt?: Date;
    createdBy?: string;
    updatedBy?: string;
    sortOrder: number;
    displayInClinics: boolean;
}

export interface LocationUpdate extends Omit<FrontOfHouseScreen, 'sortOrder'> {
    locationId: string;
    image?: {
        _id: string;
        url: string;
    };
    isOnMultipleLocations?: boolean;
}

export interface FrontOfHouseScreenInput {
    description?: string | null;
    sortOrder: number;
    imageId: string;
    displayInClinics: boolean;
}

export interface LocationUpdateInput extends FrontOfHouseScreenInput {
    locationId: string;
}

export interface Image {
    _id: string;
    url: string;
}

export const GRAPHQL_FRONT_OF_HOUSE_SCREEN_FIELDS = [
    '_id',
    'description',
    'sortOrder',
    'displayInClinics',
] as const;

export const GraphQLFrontOfHouseScreenProjection: Projection =
    GRAPHQL_FRONT_OF_HOUSE_SCREEN_FIELDS.reduce(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        { imageId: 1 },
    );

export type GraphQLFrontOfHouseScreenFields =
    (typeof GRAPHQL_FRONT_OF_HOUSE_SCREEN_FIELDS)[number];

export type GraphQLFrontOfHouseScreen = Pick<
    FrontOfHouseScreen,
    GraphQLFrontOfHouseScreenFields
> & {
    image: Image;
};

// those should be filtered out if TEAM_APP_DASHBOARD_TILES_ENABLED is false
export const TEAM_APP_METRICS_FILTER = [
    'Assistant Shifts Worked',
    'Clinic Manager Shifts Worked',
    'Total Shifts Worked',
    'Nurse Shifts Worked',
    'Support Team Ratio',
    'Nurse cost',
    'Clinic manager cost',
    'Assistant cost',
    'Care coordinator cost',
    'Comms center staffing cost',
    'Doctor cost',
    'Total labor costs',
    'Tax/Benefit cost',
    'Staffing Costs % to Revenue',
    'Total Profitability',
    'Profitability / Day',
    'Profitability / Active Doctor Shift',
    'Profitability % to Revenue',
    'Comms Center Shifts Worked',
    'Care Coordinator Shifts Worked',
];
