import * as React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import isEqual from 'lodash/isEqual';
import { StaffingRole } from '@bondvet/types/scheduling/staffing';
import range from 'lodash/range';
import CircularProgress from '@mui/material/CircularProgress';
import useDebouncedState from 'hooks/useDebouncedState';
import GeneralError from 'components/GeneralError/GeneralError';
import { Translated } from '@bondvet/web-app-i18n/util';
import Options from './components/Options';
import { NameFormat, StaffOptimizationOptions, ShiftInfoData } from './types';
import useStaffOptimizationUKGData, {
    LocationWithStaffData,
    RoleStats,
    StaffingEmployee,
    TotalStatsByDay,
} from './hooks/useStaffOptimizationUKGData';
import ShiftInfo from './components/ShiftInfo';
import useSetStaffingShiftStatus from './hooks/useSetStaffingShiftStatus';
import styles from './StaffOptimizationUKG.module.scss';
import useTranslate from '../../hooks/useTranslate';
import { ReactComponent as MoonIcon } from './assets/moon.svg';
import { ReactComponent as SunIcon } from './assets/sun.svg';
import { getBadgeTimeFromISOString } from './utils';

function getShiftDataFromBadgeMouseEvent(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
): ShiftInfoData {
    return {
        locationId: event.currentTarget.getAttribute('data-location-id') || '',
        day: event.currentTarget.getAttribute('data-day') || '',
        staffingRole: (event.currentTarget.getAttribute('data-staffing-role') ||
            '') as StaffingRole,
        employeeId: event.currentTarget.getAttribute('data-employee-id') || '',
        employeeName:
            event.currentTarget.getAttribute('data-employee-name') || '',
        stats: {
            count: parseInt(
                event.currentTarget.getAttribute('data-stats-count') || '0',
                10,
            ),
            expected: parseInt(
                event.currentTarget.getAttribute('data-stats-expected') || '0',
                10,
            ),
            underStaffed: parseInt(
                event.currentTarget.getAttribute('data-stats-understaffed') ||
                    '0',
                10,
            ),
            overStaffed: parseInt(
                event.currentTarget.getAttribute('data-stats-overstaffed') ||
                    '0',
                10,
            ),
            ignore: parseInt(
                event.currentTarget.getAttribute('data-stats-ignore') || '0',
                10,
            ),
        },
        badgeNumber: parseInt(
            event.currentTarget.getAttribute('data-badge-number') || '0',
            10,
        ),
        clickable:
            event.currentTarget.getAttribute('data-clickable') === 'true',
        clicked: false,
    };
}

const StaffOptimizationUKG: React.FunctionComponent = () => {
    const translate = useTranslate();
    const [showStartPage, setShowStartPage] = React.useState(false);

    const [options, setOptions] = React.useState<StaffOptimizationOptions>({
        loading: true,
        week: moment().startOf('isoWeek'),
        locationIds: [],
        staffingRoles: [],
        nameFormat: NameFormat.initials,
        numbers: true,
        surgeries: true,
        colors: true,
        issues: true,
    });
    const [lastOptions, setLastOptions] =
        React.useState<StaffOptimizationOptions>(options);

    const staffOptimizationData = useStaffOptimizationUKGData({
        locationIds:
            options.locationIds.length > 0 ? options.locationIds : null,
        from: options.week,
        to: React.useMemo(
            () => options.week.clone().add({ days: 6 }),
            [options.week],
        ),
        enabled: !options.loading,
    });

    const initialized = React.useRef(false);
    React.useEffect(() => {
        if (
            !initialized.current &&
            !staffOptimizationData?.runQueriesDisabled &&
            staffOptimizationData?.runQueries
        ) {
            initialized.current = true;
            if (options.locationIds.length === 0) {
                setShowStartPage(true);
            } else {
                staffOptimizationData.runQueries();
                setLastOptions(options);
            }
        }
    }, [options, staffOptimizationData]);

    const [shiftInfoData, debouncedShiftInfoData, setShiftInfoData] =
        useDebouncedState<ShiftInfoData | null>(null);

    const setStaffingShiftStatusMutation = useSetStaffingShiftStatus();

    const onMouseOverBadge = React.useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!shiftInfoData?.clicked) {
                const newShiftInfoData = getShiftDataFromBadgeMouseEvent(event);

                // avoid some unnecessary rerenders
                if (!isEqual(shiftInfoData, newShiftInfoData)) {
                    setShiftInfoData(newShiftInfoData);
                }
            }
        },
        [shiftInfoData, setShiftInfoData],
    );

    const onMouseOutBadge = React.useCallback(() => {
        if (!shiftInfoData?.clicked) {
            setShiftInfoData(null);
        }
    }, [setShiftInfoData, shiftInfoData?.clicked]);

    const onClickBadge = React.useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();
            const newShiftData = getShiftDataFromBadgeMouseEvent(event);
            if (newShiftData.clickable) {
                newShiftData.clicked = true;
            }

            setShiftInfoData(newShiftData, true);
        },
        [setShiftInfoData],
    );

    const setIgnoreShift = async (ignore: number) => {
        setShiftInfoData(null, true);

        if (
            shiftInfoData &&
            shiftInfoData.locationId &&
            shiftInfoData.day &&
            shiftInfoData.staffingRole
        ) {
            setStaffingShiftStatusMutation.setStaffingShiftStatus(
                shiftInfoData?.locationId,
                shiftInfoData?.day,
                shiftInfoData?.staffingRole,
                ignore,
            );

            if (staffOptimizationData?.updateShiftStatuses) {
                staffOptimizationData.updateShiftStatuses();
            }
        }
    };

    const filtersUnchanged =
        options.week.isSame(lastOptions.week) &&
        isEqual(options.locationIds, lastOptions.locationIds) &&
        isEqual(options.staffingRoles, lastOptions.staffingRoles) &&
        options.nameFormat === lastOptions.nameFormat &&
        options.numbers === lastOptions.numbers &&
        options.surgeries === lastOptions.surgeries &&
        options.colors === lastOptions.colors &&
        options.issues === lastOptions.issues;

    const showWarningMessage = !filtersUnchanged;

    const runReportDisabled =
        (!!staffOptimizationData?.runQueriesDisabled ||
            !!staffOptimizationData?.loading) &&
        filtersUnchanged;

    const handleRunReport = () => {
        staffOptimizationData?.runQueries?.();
        setShowStartPage(false);
        setLastOptions(options);
    };

    const getEmployeeBadge = (
        locationId: string,
        day: string,
        staffingRole: StaffingRole,
        employeeId: string | null,
        employee: StaffingEmployee | null,
        staffingOk: boolean,
        stats: RoleStats,
        badgeNumber: number,
    ) => {
        let label = '';

        if (employee !== null) {
            if (lastOptions.nameFormat === NameFormat.fullName) {
                label = employee.name;
            }

            if (lastOptions.nameFormat === NameFormat.initials) {
                label = employee.name
                    .split(' ')
                    .map((s) => s[0])
                    .join('');
            }
        }

        let roleClassName = lastOptions.colors
            ? styles[staffingRole]
            : styles.noRoleColor;

        let statusClassName = '';
        let clickable = false;

        if (stats.underStaffed > 0) {
            if (badgeNumber > stats.count + stats.ignore) {
                statusClassName = styles.underStaffed;
                roleClassName = '';
                clickable = true;
            } else if (badgeNumber > stats.count) {
                roleClassName = '';
                statusClassName = styles.underStaffedIgnored;
                clickable = true;
            }
        } else if (stats.overStaffed > 0) {
            if (badgeNumber > stats.expected + stats.ignore) {
                statusClassName = styles.overStaffed;
                roleClassName = '';
                clickable = true;
            } else if (badgeNumber > stats.expected) {
                statusClassName = styles.overStaffedIgnored;
                clickable = true;
            }
        }

        const addShiftInfo =
            shiftInfoData?.locationId === locationId &&
            shiftInfoData?.day === day &&
            shiftInfoData?.staffingRole === staffingRole &&
            shiftInfoData?.employeeId === employeeId;

        const timeLabel = `${getBadgeTimeFromISOString(
            employee?.shiftStartISO,
            employee?.timezone,
        )} - ${getBadgeTimeFromISOString(
            employee?.shiftEndISO,
            employee?.timezone,
        )}`;

        let Icon: React.FC<React.SVGProps<SVGSVGElement>> | null = null;
        switch (employee?.shiftTime?.icon) {
            case 'sun':
                Icon = SunIcon;
                break;
            case 'moon':
                Icon = MoonIcon;
                break;
            default:
                break;
        }

        return (
            <div
                key={employeeId}
                className={classnames(styles.employeeBadgeContainer, {
                    [styles.fullName]:
                        lastOptions.nameFormat === NameFormat.fullName,
                })}
            >
                <div
                    className={classnames(
                        statusClassName,
                        styles.employeeBadge,
                        {
                            [styles.clickable]: clickable,
                            [styles.staffingOk]: staffingOk,
                        },
                        roleClassName,
                    )}
                    data-location-id={locationId}
                    data-day={day}
                    data-staffing-role={staffingRole}
                    data-employee-id={employeeId}
                    data-employee-name={employee?.name || null}
                    data-stats-count={stats.count}
                    data-stats-expected={stats.expected}
                    data-stats-overstaffed={stats.overStaffed}
                    data-stats-understaffed={stats.underStaffed}
                    data-stats-ignore={stats.ignore}
                    data-clickable={clickable ? 'true' : 'false'}
                    data-badge-number={badgeNumber}
                    onMouseOver={onMouseOverBadge}
                    onMouseOut={onMouseOutBadge}
                    onClick={onClickBadge}
                >
                    {lastOptions.nameFormat === NameFormat.fullName ? (
                        <div className={styles.fullNameContent}>
                            <div className={styles.fullNameText}>
                                <div>{label}</div>
                                <span className={styles.time}>{timeLabel}</span>
                            </div>
                            {Icon && <Icon className={styles.shiftTimeIcon} />}
                        </div>
                    ) : (
                        <div className={styles.initialsContent}>
                            <div className={styles.initialsText}>
                                {label && <span>{label}</span>}
                                {Icon && (
                                    <Icon
                                        className={classnames(
                                            styles.shiftTimeIcon,
                                            lastOptions.nameFormat ===
                                                NameFormat.initials
                                                ? styles.initialsShiftTimeIcon
                                                : styles.noNameShiftTimeIcon,
                                        )}
                                    />
                                )}
                            </div>
                            <div>
                                <span className={styles.time}>{timeLabel}</span>
                            </div>
                        </div>
                    )}
                </div>
                {addShiftInfo && shiftInfoData && debouncedShiftInfoData && (
                    <ShiftInfo
                        position={
                            locationId ===
                            staffOptimizationData?.locationsWithStaffData?.[0]
                                ?.bondLocation?._id
                                ? 'bottom'
                                : 'top'
                        }
                        data={shiftInfoData}
                        setIgnoreShift={setIgnoreShift}
                        hide={() => setShiftInfoData(null, true)}
                    />
                )}
            </div>
        );
    };

    const renderRoleLine = (
        locationId: string,
        rowHeader: Translated | null,
        role: StaffingRole,
        { locationStats, employeesByDayAndRole }: LocationWithStaffData,
    ) => {
        if (
            lastOptions.staffingRoles.length > 0 &&
            !lastOptions.staffingRoles.includes(role)
        ) {
            return null;
        }

        return (
            <div className={styles.gridLine}>
                <div className={styles.statsBlock}>
                    <div className={styles.rowHeader}>{rowHeader}</div>
                    <div className={styles.role}>
                        {translate(
                            `vetspireExtension.staffOptimization.rowHeaders.${role}`,
                        )}
                    </div>
                    <div className={styles.count}>
                        {lastOptions.numbers && locationStats[role].count}
                    </div>
                    <div className={styles.underStaffedCount}>
                        {lastOptions.numbers &&
                            locationStats[role].underStaffed > 0 &&
                            `-${locationStats[role].underStaffed}`}
                    </div>
                    <div className={styles.overStaffedCount}>
                        {lastOptions.numbers &&
                            locationStats[role].overStaffed > 0 &&
                            `+${locationStats[role].overStaffed}`}
                    </div>
                </div>

                {Object.keys(employeesByDayAndRole).map((day) => {
                    const employeesByRole = employeesByDayAndRole[day];
                    let staffingOk = false;

                    if (
                        lastOptions.issues &&
                        [
                            'doctor',
                            'trainingDoctor',
                            'nurse',
                            'assistant',
                            'careCoordinator',
                            'manager',
                            ...(lastOptions.surgeries
                                ? [
                                      'surgeryDoctor',
                                      'surgeryNurse',
                                      'surgeryAssistant',
                                  ]
                                : []),
                        ].find(
                            (checkRole) =>
                                employeesByRole[checkRole].stats.underStaffed >
                                    employeesByRole[checkRole].stats.ignore ||
                                employeesByRole[checkRole].stats.overStaffed >
                                    employeesByRole[checkRole].stats.ignore,
                        ) === undefined
                    ) {
                        staffingOk = true;
                    }

                    let badgeNumber = 0;

                    return (
                        <div className={styles.employeesBlock} key={day}>
                            <div
                                className={
                                    lastOptions.numbers ? '' : styles.noNumbers
                                }
                            >
                                {employeesByRole[role].employees.map(
                                    (employee) => {
                                        badgeNumber += 1;
                                        return getEmployeeBadge(
                                            locationId,
                                            day,
                                            role,
                                            employee.id,
                                            employee,
                                            staffingOk,
                                            employeesByRole[role].stats,
                                            badgeNumber,
                                        );
                                    },
                                )}
                                {
                                    // add empty badges for understaffed
                                    employeesByRole[role].stats.underStaffed >
                                        0 &&
                                        range(
                                            employeesByRole[role].stats
                                                .underStaffed,
                                        ).map((n) => {
                                            badgeNumber += 1;
                                            return getEmployeeBadge(
                                                locationId,
                                                day,
                                                role,
                                                // use negative numbers as key to avoid collisions with employee.id
                                                (n * -1).toString(),
                                                null,
                                                staffingOk,
                                                employeesByRole[role].stats,
                                                badgeNumber,
                                            );
                                        })
                                }
                            </div>
                            {lastOptions.numbers && (
                                <>
                                    <div
                                        className={classnames(
                                            styles.statNumber,
                                            styles.count,
                                        )}
                                    >
                                        {employeesByRole[role].stats.count}
                                    </div>
                                    {employeesByRole[role].stats.underStaffed >
                                        0 &&
                                        employeesByRole[role].stats
                                            .underStaffed -
                                            employeesByRole[role].stats.ignore >
                                            0 && (
                                            <div
                                                className={classnames(
                                                    styles.statNumber,
                                                    styles.underStaffedCount,
                                                )}
                                            >
                                                -
                                                {employeesByRole[role].stats
                                                    .underStaffed -
                                                    employeesByRole[role].stats
                                                        .ignore}
                                            </div>
                                        )}
                                    {employeesByRole[role].stats.overStaffed >
                                        0 &&
                                        employeesByRole[role].stats
                                            .overStaffed -
                                            employeesByRole[role].stats.ignore >
                                            0 && (
                                            <div
                                                className={classnames(
                                                    styles.statNumber,
                                                    styles.overStaffedCount,
                                                )}
                                            >
                                                +
                                                {employeesByRole[role].stats
                                                    .overStaffed -
                                                    employeesByRole[role].stats
                                                        .ignore}
                                            </div>
                                        )}
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderLocationRow = (
        locationWithStaffData: LocationWithStaffData,
    ) => {
        const { bondLocation } = locationWithStaffData;
        return (
            <div key={bondLocation._id} className={styles.locationBlock}>
                <div className={styles.locationName}>{bondLocation.name}</div>
                {renderRoleLine(
                    bondLocation._id,
                    translate(
                        'vetspireExtension.staffOptimization.rowHeaders.tx',
                    ),
                    'doctor',
                    locationWithStaffData,
                )}
                {renderRoleLine(
                    bondLocation._id,
                    null,
                    'trainingDoctor',
                    locationWithStaffData,
                )}
                {renderRoleLine(
                    bondLocation._id,
                    null,
                    'nurse',
                    locationWithStaffData,
                )}
                {renderRoleLine(
                    bondLocation._id,
                    null,
                    'assistant',
                    locationWithStaffData,
                )}
                {renderRoleLine(
                    bondLocation._id,
                    null,
                    'careCoordinator',
                    locationWithStaffData,
                )}
                {renderRoleLine(
                    bondLocation._id,
                    null,
                    'manager',
                    locationWithStaffData,
                )}

                {lastOptions.surgeries && (
                    <>
                        <div className={styles.txSxSeparator} />
                        {renderRoleLine(
                            bondLocation._id,
                            translate(
                                'vetspireExtension.staffOptimization.rowHeaders.sx',
                            ),
                            'surgeryDoctor',
                            locationWithStaffData,
                        )}
                        {renderRoleLine(
                            bondLocation._id,
                            null,
                            'surgeryNurse',
                            locationWithStaffData,
                        )}
                        {renderRoleLine(
                            bondLocation._id,
                            null,
                            'surgeryAssistant',
                            locationWithStaffData,
                        )}
                    </>
                )}
            </div>
        );
    };

    const renderTotalsLine = (
        rowHeader: Translated | null,
        roleTitle: Translated | null,
        totalStatsByDay: TotalStatsByDay,
        roles: StaffingRole[],
        className?: string,
    ) => {
        return (
            <div className={classnames(styles.gridLine, className)}>
                <div className={styles.totalsRowHeader}>
                    <div className={styles.rowHeader}>{rowHeader}</div>
                    <div>{roleTitle}</div>
                </div>
                {Object.keys(totalStatsByDay).map((day) => {
                    const stats = totalStatsByDay[day];
                    let count = 0;
                    let overStaffed = 0;
                    let underStaffed = 0;

                    roles.forEach((role) => {
                        count += stats[role].count;
                        overStaffed += stats[role].overStaffed;
                        underStaffed += stats[role].underStaffed;
                    });

                    return (
                        <div key={day} className={styles.totalsBlock}>
                            <div className={styles.count}>{count}</div>
                            <div className={styles.underStaffedCount}>
                                {underStaffed > 0 && `-${underStaffed}`}
                            </div>
                            <div className={styles.overStaffedCount}>
                                {overStaffed > 0 && `+${overStaffed}`}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderTotalsRow = (totalStatsByDay: TotalStatsByDay) => {
        return (
            <>
                {renderTotalsLine(
                    translate(
                        'vetspireExtension.staffOptimization.rowHeaders.total',
                    ),
                    translate(
                        'vetspireExtension.staffOptimization.rowHeaders.doctor',
                    ),
                    totalStatsByDay,
                    lastOptions.surgeries
                        ? ['doctor', 'surgeryDoctor']
                        : ['doctor'],
                )}
                {renderTotalsLine(
                    null,
                    translate(
                        'vetspireExtension.staffOptimization.rowHeaders.trainingDoctor',
                    ),
                    totalStatsByDay,
                    ['trainingDoctor'],
                )}
                {renderTotalsLine(
                    null,
                    translate(
                        'vetspireExtension.staffOptimization.rowHeaders.nurse',
                    ),
                    totalStatsByDay,
                    lastOptions.surgeries
                        ? ['nurse', 'surgeryNurse']
                        : ['nurse'],
                )}
                {renderTotalsLine(
                    null,
                    translate(
                        'vetspireExtension.staffOptimization.rowHeaders.assistant',
                    ),
                    totalStatsByDay,
                    lastOptions.surgeries
                        ? ['assistant', 'surgeryAssistant']
                        : ['assistant'],
                )}
                {renderTotalsLine(
                    null,
                    translate(
                        'vetspireExtension.staffOptimization.rowHeaders.careCoordinator',
                    ),
                    totalStatsByDay,
                    ['careCoordinator'],
                )}
                {renderTotalsLine(
                    null,
                    translate(
                        'vetspireExtension.staffOptimization.rowHeaders.manager',
                    ),
                    totalStatsByDay,
                    ['manager'],
                )}
                {lastOptions.surgeries ? (
                    <>
                        {renderTotalsLine(
                            null,
                            translate(
                                'vetspireExtension.staffOptimization.rowHeaders.total',
                            ),
                            totalStatsByDay,
                            [
                                'doctor',
                                'trainingDoctor',
                                'surgeryDoctor',
                                'nurse',
                                'surgeryNurse',
                                'assistant',
                                'surgeryAssistant',
                                'careCoordinator',
                                'manager',
                            ],
                            styles.totalSum,
                        )}
                    </>
                ) : (
                    renderTotalsLine(
                        null,
                        translate(
                            'vetspireExtension.staffOptimization.rowHeaders.total',
                        ),
                        totalStatsByDay,
                        [
                            'doctor',
                            'nurse',
                            'assistant',
                            'careCoordinator',
                            'manager',
                        ],
                        styles.totalSum,
                    )
                )}
            </>
        );
    };

    return (
        <div className={styles.container}>
            {options.loading || staffOptimizationData?.loading || (
                <div
                    className={classnames(styles.gridLine, styles.columnsGroup)}
                >
                    <div
                        className={classnames(
                            styles.statsBlock,
                            styles.columns,
                        )}
                    />
                    {range(7).map((n) => (
                        <div key={n} className={styles.columns} />
                    ))}
                </div>
            )}
            {staffOptimizationData?.error && (
                <GeneralError
                    className={styles.error}
                    message={staffOptimizationData.error}
                />
            )}
            <div>
                <Options
                    options={options}
                    onChange={(newOptions: StaffOptimizationOptions) =>
                        setOptions(newOptions)
                    }
                    runReportDisabled={runReportDisabled}
                    onRunReport={handleRunReport}
                />
                {showWarningMessage && (
                    <div className={styles.filtersNotReflected}>
                        {translate(
                            `vetspireExtension.staffOptimization.filtersNotReflected`,
                        )}
                    </div>
                )}
            </div>
            {showStartPage && (
                <>
                    <div />
                    <div className={styles.startPage}>
                        {translate(
                            `vetspireExtension.staffOptimization.startPage`,
                        )}
                    </div>
                </>
            )}
            {!showStartPage && staffOptimizationData?.loading && (
                <div className={styles.loading}>
                    <CircularProgress
                        size="50px"
                        color="inherit"
                        className={styles.loadingIcon}
                    />
                </div>
            )}
            {!showStartPage && !staffOptimizationData?.loading && (
                <>
                    <div className={styles.grid}>
                        <div className={styles.gridLine}>
                            <div className={styles.statsBlock} />
                            {range(7).map((n) => {
                                const day = lastOptions.week
                                    .clone()
                                    .add({ days: n });
                                return (
                                    <div key={n} className={styles.dayHeader}>
                                        <span className={styles.date}>
                                            {day.format('MM/DD')}
                                        </span>{' '}
                                        {day.format('dddd')}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.grid}>
                        <div>
                            {(
                                staffOptimizationData?.locationsWithStaffData ||
                                []
                            ).map((bondLocation) =>
                                renderLocationRow(bondLocation),
                            )}
                        </div>
                    </div>
                    {lastOptions.numbers && (
                        <div className={classnames(styles.grid, styles.totals)}>
                            {staffOptimizationData?.totalStatsByDay &&
                                renderTotalsRow(
                                    staffOptimizationData.totalStatsByDay,
                                )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default StaffOptimizationUKG;
