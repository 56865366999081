import * as React from 'react';
import { QueryResult } from '@apollo/client';
import Select, { MultiValue, OptionProps } from 'react-select';
import useTranslate from 'hooks/useTranslate';
import { ReactComponent as LocationIcon } from 'assets/location.svg';
import { Location } from '@bondvet/types/locations';
import classnames from 'classnames';
import difference from 'lodash/difference';
import differenceBy from 'lodash/differenceBy';
import uniq from 'lodash/uniq';
import { LocationsQueryResult, RegionsQueryResult } from 'api/bond/queries';
import { Region } from '@bondvet/types/analytics/index';
import styles from './LookupSelect.module.scss';
import Checkbox from '../CheckBox/CheckBox';

interface LocationsSelectProps {
    locationsQueryResult: QueryResult<LocationsQueryResult>;
    regionsQueryResult: QueryResult<RegionsQueryResult>;
    selectedLocations: Location[];
    onChange: (locations: Location[]) => void;
    filter?: (location: Location) => boolean;
    isDisabled?: boolean;
    className?: string;
}

type RegionOption = {
    id: string;
    label: string;
    locations: Location[];
};

const BondLocationsSelectWithRegions: React.FunctionComponent<
    LocationsSelectProps
> = ({
    locationsQueryResult,
    regionsQueryResult,
    selectedLocations,
    onChange,
    filter,
    isDisabled = false,
    className,
}) => {
    const translate = useTranslate();

    const options: readonly RegionOption[] = React.useMemo(() => {
        if (
            locationsQueryResult.data?.locations &&
            regionsQueryResult.data?.getRegions
        ) {
            const regions = regionsQueryResult.data.getRegions;
            return (
                Object.values(
                    locationsQueryResult.data.locations
                        .slice()
                        .filter((location) => !filter || filter(location))
                        .reduce((acc, location) => {
                            const newAcc: Record<string, RegionOption> = {
                                ...acc,
                            };

                            const region = regions.find(
                                ({ locationIds }: Region) =>
                                    locationIds.find(
                                        (id) => id === location._id,
                                    ),
                            );

                            let regionOption: RegionOption = {
                                id: 'no-region',
                                label: 'No region',
                                locations: [],
                            };

                            if (region) {
                                regionOption = {
                                    id: region._id,
                                    label: region.name,
                                    locations: [],
                                };
                            }

                            if (!newAcc[regionOption.id]) {
                                newAcc[regionOption.id] = regionOption;
                            }

                            newAcc[regionOption.id].locations.push(location);

                            return newAcc;
                        }, {}) || {},
                ) as readonly RegionOption[]
            )
                .map((region) => {
                    return {
                        ...region,
                        locations: region.locations.sort((a, b) =>
                            (a.name || '').localeCompare(b.name || ''),
                        ),
                    };
                })
                .sort((a, b) => (a.label || '').localeCompare(b.label || ''));
        }
        return [];
    }, [
        filter,
        locationsQueryResult.data?.locations,
        regionsQueryResult.data?.getRegions,
    ]);

    const toggleLocation = (
        location: Location,
        value: Location[],
        setValue: (locations: Location[]) => void,
    ) => {
        if (value.find(({ _id }) => location._id === _id)) {
            setValue(value.filter(({ _id }) => location._id !== _id));
        } else {
            setValue([...value, location]);
        }
    };

    const toggleRegion = (
        locations: Location[],
        regionSelected: boolean,
        value: Location[],
        setValue: (locations: Location[]) => void,
    ) => {
        if (regionSelected) {
            setValue(differenceBy(value, locations, '_id'));
        } else {
            setValue(uniq([...value, ...locations]));
        }
    };

    const optionComponent = ({
        data,
        setValue,
        getValue,
    }: OptionProps<RegionOption | Location, true>) => {
        const value = getValue();

        const region = data as RegionOption;

        const regionSelected = difference(region.locations, value).length === 0;
        return (
            <div className={styles.region}>
                <div className={styles.regionCheckbox}>
                    <Checkbox
                        classes={{ label: styles.regionCheckboxLabel }}
                        value={regionSelected}
                        labelOrientation="after"
                        label={region.label}
                        solid
                        onChange={() => {
                            toggleRegion(
                                region.locations,
                                regionSelected,
                                value as Location[],
                                setValue as unknown as (
                                    locations: Location[],
                                ) => void,
                            );
                        }}
                    />
                </div>
                <ul className={styles.locations}>
                    {region.locations.map((location: Location) => (
                        <li
                            key={location._id}
                            className={styles.locationCheckbox}
                        >
                            <Checkbox
                                value={
                                    regionSelected ||
                                    !!(value as Location[]).find(
                                        ({ _id }: Location) =>
                                            location._id === _id,
                                    )
                                }
                                solid
                                onChange={() => {
                                    toggleLocation(
                                        location,
                                        value as Location[],
                                        setValue as unknown as (
                                            locations: Location[],
                                        ) => void,
                                    );
                                }}
                                labelOrientation="after"
                                label={location.name ?? ''}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <Select
            isMulti
            isLoading={locationsQueryResult.loading}
            name="providers"
            options={options}
            value={selectedLocations}
            className={classnames(styles.lookup, className)}
            classNamePrefix="lookup-select"
            onChange={(value: MultiValue<RegionOption | Location>) => {
                onChange((value || []) as Location[]);
            }}
            components={{ Option: optionComponent }}
            isDisabled={isDisabled}
            getOptionLabel={(option) => (option as Location).name || ''}
            getOptionValue={(option) => (option as Location)._id}
            closeMenuOnSelect={false}
            placeholder={
                <div className={styles.placeholder}>
                    <LocationIcon className={styles.placeholderIcon} />
                    {translate(
                        'vetspireExtension.lookups.locations.placeholder',
                    )}
                </div>
            }
        />
    );
};

export default BondLocationsSelectWithRegions;
