import * as React from 'react';
import classnames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import styles from './Alert.module.scss';

export type AlertVariant = 'error' | 'info' | 'success';

export type AlertProps = {
    classes?: Partial<typeof styles>;
    variant?: AlertVariant;
    className?: string | null;
};

function Alert({
    className,
    classes,
    children,
    variant = 'error',
}: React.PropsWithChildren<AlertProps>): React.ReactElement {
    return (
        <div
            className={classnames(classes?.root, styles.root, className, {
                [styles.error]: variant === 'error',
                [styles.info]: variant === 'info',
                [styles.success]: variant === 'success',
            })}
        >
            {variant === 'success' && (
                <CheckIcon
                    fontSize="large"
                    classes={{ root: classnames(styles.icon, classes?.icon) }}
                />
            )}
            <div>{children}</div>
        </div>
    );
}

export default Alert;
