import usePatientPlansQuery from 'hooks/usePatientPlansQuery';
import { PreventionPlanFragment } from 'pages/PatientPlans/fragments';
import { gql } from '@apollo/client';
import {
    DecoratedPreventionPlan,
    PreventionPlanQueryArguments,
    PreventionPlanQueryResult,
} from '@bondvet/types/patientPlans';

const preventionPlanQuery = gql`
    ${PreventionPlanFragment}
    query preventionPlan($preventionPlanId: ID!, $vetspireLocationId: ID!) {
        preventionPlan(
            preventionPlanId: $preventionPlanId
            vetspireLocationId: $vetspireLocationId
        ) {
            ...PreventionPlanFragment
        }
    }
`;

interface UsePreventionPlan {
    loading: boolean;
    preventionPlan: DecoratedPreventionPlan | null;
}

interface UsePreventionPlanOptions {
    preventionPlanId: string;
    vetspireLocationId: string;
    skip: boolean;
}

export default function usePreventionPlan({
    preventionPlanId,
    vetspireLocationId,
    skip,
}: UsePreventionPlanOptions): UsePreventionPlan {
    const { loading, data } = usePatientPlansQuery<
        PreventionPlanQueryResult,
        PreventionPlanQueryArguments
    >(preventionPlanQuery, {
        variables: {
            preventionPlanId,
            vetspireLocationId,
        },
        skip,
    });

    return {
        loading,
        preventionPlan: data?.preventionPlan ?? null,
    };
}
