import * as React from 'react';
import 'index.scss';
import { ApolloProvider } from '@apollo/client';
import moment from 'moment-timezone';
import { createRoot } from 'react-dom/client';
import {
    addBackgroundMessageListener,
    MESSAGE_SOURCE_NAVIGATION,
} from 'lib/backgroundMessages';
import apiClient from 'lib/apiClient';
import {
    setVetspireLocationData,
    setVetspireLocationId,
} from 'context/VetspireContext';
import { setVetspireAuthToken } from 'lib/vetspireAuth';
import { setBondAuthToken } from 'lib/bondAuth';
import {
    requestManifestData,
    VetspireExtensionMessage,
} from 'lib/vetspireActions';
import App from 'components/App';

moment.locale('en', { week: { dow: 0 } });

addBackgroundMessageListener(
    MESSAGE_SOURCE_NAVIGATION,
    (message: VetspireExtensionMessage) => {
        switch (message.action) {
            case 'vetspireAuthTokenChanged':
                setVetspireAuthToken(message.vetspireAuthToken || '');
                break;
            case 'pathNameChanged':
                setVetspireLocationData({
                    pathName: message.pathName,
                    hostName: message.hostName,
                    host: message.host,
                    protocol: message.protocol,
                });
                break;
            case 'locationIdChanged':
                setVetspireLocationId(message.locationId || '');
                break;
            case 'setManifestData':
                if (message.manifestData) {
                    sessionStorage.setItem(
                        'manifestData',
                        JSON.stringify(message.manifestData),
                    );
                }
                break;
            default:
                break;
        }
    },
);

requestManifestData();

const urlParams = new URLSearchParams(window.location.search);

const token = urlParams.get('initVetspireAuthToken');
if (token) {
    setVetspireAuthToken(token);
}

// clear bondAutToken to avoid using a token from an old login session
setBondAuthToken('');

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container);

    root.render(
        <React.StrictMode>
            <ApolloProvider client={apiClient}>
                <App />
            </ApolloProvider>
        </React.StrictMode>,
    );
}
