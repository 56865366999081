import * as React from 'react';
import type { LocationSchedule } from '@bondvet/types/locations';
import {
    multipleLocationSchedulesQuery,
    MultipleLocationSchedulesQueryResult,
} from 'api/bond/queries';
import useBondQuery from './useBondQuery';

export type UseLocationSchedulesArguments = {
    locationIds?: ReadonlyArray<string>;
    vetspireLocationIds?: ReadonlyArray<string>;
};

type LocationSchedules = {
    loading: boolean;
    locationSchedules: ReadonlyArray<LocationSchedule>;
};

export default function useLocationSchedules({
    locationIds,
    vetspireLocationIds,
}: UseLocationSchedulesArguments = {}): LocationSchedules {
    const { data, loading } =
        useBondQuery<MultipleLocationSchedulesQueryResult>(
            multipleLocationSchedulesQuery,
            {
                fetchPolicy: 'cache-first',
                variables: { locationIds, vetspireLocationIds },
            },
        );

    const locationSchedules = React.useMemo(
        () => data?.multipleLocationSchedules || [],
        [data?.multipleLocationSchedules],
    );

    return { locationSchedules, loading };
}
