import * as React from 'react';
import { SurgeryReferralTextAnswer } from '@bondvet/types/surgeryReferrals';
import { InputProps } from './types';

interface TextQuestionInputProps extends InputProps {
    value: SurgeryReferralTextAnswer['answer'];
}

export default function TextInput({
    value,
    onChange,
    id,
    required,
}: TextQuestionInputProps): React.ReactElement {
    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChange(event.target.value);
        },
        [onChange],
    );

    return (
        <textarea
            id={id}
            required={required}
            rows={4}
            onChange={handleChange}
            value={value}
        />
    );
}
