import { gql } from '@apollo/client';
import useLazySukiQuery from 'hooks/useLazySukiQuery';
import {
    SukiAmbientSessionStatsQueryArguments,
    SukiAmbientSessionStatsQueryResult,
} from '@bondvet/types/suki';

const query = gql`
    query sukiAmbientSessionStats(
        $vetspireProviderId: ID!
        $from: DateTime!
        $to: DateTime!
        $timezone: String!
    ) {
        sukiAmbientSessionStats(
            vetspireProviderId: $vetspireProviderId
            from: $from
            to: $to
            timezone: $timezone
        ) {
            date
            finishedAmbientSessions
            processedAmbientSessions
        }
    }
`;

export default function useLazySukiAmbientSessionStatsQuery() {
    return useLazySukiQuery<
        SukiAmbientSessionStatsQueryResult,
        SukiAmbientSessionStatsQueryArguments
    >(query, { fetchPolicy: 'cache-and-network' });
}
