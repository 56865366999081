import { OperationResult } from '../common';

export const SUKI_AMBIENT_SESSION_TYPE = ['encounter', 'phone'] as const;

export type SukiAmbientSessionType = (typeof SUKI_AMBIENT_SESSION_TYPE)[number];

export const SUKI_AMBIENT_SESSION_STATUS = [
    'started',
    'finished',
    'processed',
] as const;

export type SukiAmbientSessionStatus =
    (typeof SUKI_AMBIENT_SESSION_STATUS)[number];

export const SUKI_AMBIENT_SESSION_COLLECTION_NAME = 'suki.ambientSessions';

export interface SukiAmbientSession {
    _id: string;
    type: SukiAmbientSessionType;
    status: SukiAmbientSessionStatus;
    vetspireProviderId: string;
    vetspireClientId: string;
    vetspirePatientId: string;
    vetspireEncounterId: string | null;
    startedAt: Date;
    finishedAt?: Date;
    processedAt?: Date;
}

export type SukiAmbientSessionInput = Omit<
    SukiAmbientSession,
    '_id' | 'createdAt' | 'finishedAt' | 'processedAt'
>;

export interface SukiAmbientSessionsQueryArguments {
    _id?: string;
    vetspireProviderId?: string;
    from?: Date;
    to?: Date;
    types?: readonly SukiAmbientSessionType[];
    statuses?: readonly SukiAmbientSessionStatus[];
}

export interface SukiAmbientSessionsQueryResult {
    sukiAmbientSessions: SukiAmbientSession[];
}

export interface CreateSukiAmbientSessionInput {
    type: SukiAmbientSessionType;
    vetspireProviderId: string;
    vetspireClientId: string;
    vetspirePatientId: string;
    vetspireEncounterId: string | null;
    status: SukiAmbientSessionStatus;
}

export interface CreateSukiAmbientSessionArguments {
    _id: string;
    input: CreateSukiAmbientSessionInput;
}

export interface CreateSukiAmbientSessionResult {
    createSukiAmbientSession: OperationResult;
}

export interface UpdateSukiAmbientSessionInput {
    status: SukiAmbientSessionStatus;
}

export interface UpdateSukiAmbientSessionArguments {
    _id: string;
    input: UpdateSukiAmbientSessionInput;
}

export interface UpdateSukiAmbientSessionResult {
    createSukiAmbientSession: OperationResult;
}

export interface SukiAmbientSessionStats {
    vetspireProviderId: string;
    date: string; // YYYY-MM-DD
    finishedAmbientSessions: number;
    processedAmbientSessions: number;
}

export interface SukiAmbientSessionStatsQueryArguments {
    vetspireProviderId: string;
    from: Date;
    to: Date;
    timezone: string;
}

export interface SukiAmbientSessionStatsQueryResult {
    sukiAmbientSessionStats: readonly SukiAmbientSessionStats[];
}

export const SUKI_SETTINGS_COLLECTION_NAME = 'suki.settings';
export const SUKI_SETTINGS_GLOBAL_ID = 'global';

interface SukiToEncounterMapping {
    sukiTitle: string;
    sectionTitle: string;
    blockTitle: string;
}

export interface SukiGlobalSetting {
    _id: typeof SUKI_SETTINGS_GLOBAL_ID;
    partnerId: string | null;
    isTestMode: boolean | null;
    encounterMappings: SukiToEncounterMapping[];
    encounterNoteTypeId: string | null;
    telephoneNoteTypeId: string | null;
}

export const defaultSukiGlobalSettings: SukiGlobalSetting = {
    _id: SUKI_SETTINGS_GLOBAL_ID,
    partnerId: null,
    isTestMode: true,
    encounterMappings: [],
    encounterNoteTypeId: null,
    telephoneNoteTypeId: null,
};

export type SukiSetting = SukiGlobalSetting;

export interface SukiGlobalSettingsQueryResult {
    sukiGlobalSettings: SukiGlobalSetting;
}
