import * as React from 'react';
import classnames from 'classnames';

import useTranslate from 'hooks/useTranslate';

import styles from './AddClientToQueue.module.scss';
import NewVisitForm from './NewVisitForm';

export type AddClientToQueueProps = {
    clientId?: string;
    patientId?: string;
};

function AddClientToQueue({
    clientId,
    patientId,
}: AddClientToQueueProps): React.ReactElement {
    const translate = useTranslate();

    return (
        <>
            <header className={classnames(styles.flyoutBlock, styles.first)}>
                <span className={styles.flyoutTitle}>
                    {translate(
                        'vetspireExtension.telehealth.addClientToQueue.title',
                    )}
                </span>
            </header>
            <span className={styles.addClientContainer}>
                {clientId ? (
                    <NewVisitForm clientId={clientId} patientId={patientId} />
                ) : (
                    <p>No Client Selected</p>
                )}
            </span>
        </>
    );
}

export default AddClientToQueue;
