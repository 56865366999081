import * as React from 'react';
import classNames from 'classnames';
import { Order } from 'api/clients/queries';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useTranslate from 'hooks/useTranslate';
import { openInvoice } from 'lib/vetspireActions';
import useClientAndPatientId from 'hooks/useClientAndPatientId';
import styles from './DueInvoice.module.scss';
import { formatAmountAsUSD } from '../../util';
import useCurrentInvoiceId from './useCurrentInvoiceId';

export type DueInvoiceProps = {
    classes?: {
        root?: string;
        selected?: string;
    };
    className?: string;
    invoice: Order;
    onCharge: (invoice: Order) => void;
    chargeEnabled: boolean;
};

function DueInvoice({
    classes,
    className,
    invoice,
    onCharge,
    chargeEnabled,
}: DueInvoiceProps): React.ReactElement {
    const { invoicedAt, location, provider, dueAmount, client, patients, id } =
        invoice;
    const translate = useTranslate();
    const { patientId: currentPatientId } = useClientAndPatientId();
    const currentInvoiceId = useCurrentInvoiceId();

    const patientId = React.useMemo(() => {
        if (patients && patients.length > 0) {
            return patients[0].id;
        }

        if (client.patients && client.patients.length > 0) {
            return client.patients[0].id;
        }

        // we don't assign our late cancel/reschedule or no-show invoices
        // to a patient, thus we could have invoices without patients
        // (though they shouldn't be due ...)
        return currentPatientId;
    }, [client, patients, currentPatientId]);

    const handleClick = React.useCallback(() => {
        if (patientId) {
            openInvoice(client.id, patientId, id);
        }
    }, [patientId, client, id]);

    const handleKeyDown = React.useCallback(
        ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
            if (patientId && key === 'Enter') {
                openInvoice(client.id, patientId, id);
            }
        },
        [patientId, client, id],
    );
    return (
        <div
            role="button"
            tabIndex={0}
            className={classNames(className, classes?.root, styles.root, {
                [classNames(classes?.selected, styles.selected)]:
                    currentInvoiceId === id,
            })}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
        >
            <strong>{invoicedAt?.format('MMM DD, YYYY')}</strong>
            <span>{provider?.name}</span>
            <span>{location?.name}</span>
            <span className={styles.right}>{formatAmountAsUSD(dueAmount)}</span>
            <span className={styles.right}>
                <Tooltip
                    title={translate(
                        `vetspireExtension.clientDetails.payments.dueInvoices.${
                            chargeEnabled ? 'charge' : 'chargingDisabled'
                        }`,
                    )}
                    placement="top"
                >
                    <span>
                        <IconButton
                            disabled={!chargeEnabled}
                            className={styles.icon}
                            onClick={() => onCharge(invoice)}
                            size="large"
                        >
                            <ExitToAppIcon color="inherit" />
                        </IconButton>
                    </span>
                </Tooltip>
            </span>
        </div>
    );
}

export default DueInvoice;
