import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import * as React from 'react';
import Select from 'react-select';
import {
    SUKI_AMBIENT_SESSION_TYPE,
    SukiAmbientSessionType,
} from '@bondvet/types/suki';
import styles from 'components/LookupSelect/LookupSelect.module.scss';

interface SelectAmbientSessionTypesProps {
    className: string;
    values: readonly SukiAmbientSessionType[];
    onChange: (ambientSessionTypes: readonly SukiAmbientSessionType[]) => void;
}

interface Option {
    value: SukiAmbientSessionType;
    label: string;
}

export default function SelectAmbientSessionTypes({
    className,
    values,
    onChange,
}: SelectAmbientSessionTypesProps) {
    const translate = useTranslate();

    const options = React.useMemo<readonly Option[]>(
        () =>
            SUKI_AMBIENT_SESSION_TYPE.map((ambientSessionType) => ({
                value: ambientSessionType,
                label: translate(
                    `vetspireExtension.suki.ambientSessionType.${ambientSessionType}`,
                ) as string,
            })),
        [translate],
    );

    const selectedOptions = React.useMemo<readonly Option[]>(
        () => options.filter((option) => values.includes(option.value)),
        [options, values],
    );

    return (
        <Select<Option, true>
            isMulti={true}
            name="ambientSessionTypes"
            value={selectedOptions}
            required
            className={classnames(styles.lookup, className)}
            classNamePrefix="lookup-select"
            options={options}
            onChange={(value) => onChange(value.map((option) => option.value))}
            menuPosition="fixed"
            placeholder={
                <div className={styles.placeholder}>
                    {translate(
                        'vetspireExtension.suki.sessionOverview.ambientSessionTypePlaceholder',
                    )}
                </div>
            }
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
        />
    );
}
