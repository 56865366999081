import classnames from 'classnames';
import * as React from 'react';
import { Translated } from '@bondvet/web-app-i18n/util';
import styles from '../Form.module.scss';

type LabelInputProps = {
    id: string;
    label: Translated;
    value: string;
    onChange: (event: string) => void;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    rows?: number;
};

export default function TextArea({
    id,
    label,
    value,
    onChange,
    required,
    disabled = false,
    placeholder,
    className,
    rows = 4,
}: LabelInputProps): React.ReactElement {
    return (
        <fieldset className={styles.container}>
            <label htmlFor={id} className={styles.label}>
                {label}
                {required && ' *'}
            </label>
            <textarea
                disabled={disabled}
                className={classnames(styles.textArea, className)}
                rows={rows}
                id={id}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                placeholder={placeholder}
            />
        </fieldset>
    );
}
