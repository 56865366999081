import * as React from 'react';
import { PatientPlanFragment } from 'pages/PatientPlans/fragments';
import { decoratePatientPlan } from 'pages/PatientPlans/utils';
import { DecoratedPatientPlan } from 'pages/PatientPlans/types';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import type { Patient, PatientPlan } from '@bondvet/types/generated/vetspire';
import { eachLimit } from 'async';
import usePreventionPlans from './usePreventionPlans';
import useGlobalSettings from './useGlobalSettings';

const patientsQuery = gql`
    query patients($clientId: ID) {
        patients(filters: { clientId: $clientId }) {
            id
            name
        }
    }
`;

interface PatientsQueryVariables {
    clientId: string;
}

interface PatientsQueryResult {
    patients: Pick<Patient, 'id' | 'name' | 'isActive'>[];
}

const patientPlansQuery = gql`
    ${PatientPlanFragment}
    query patientPlans($patientId: ID!) {
        patientPlans(filters: { patientId: $patientId }) {
            ...PatientPlanFragment
        }
    }
`;

interface PatientPlansQueryVariables {
    patientId: string;
}

interface PatientPlansQueryResult {
    patientPlans: PatientPlan[];
}

interface UsePatientPlans {
    loading: boolean;
    activePatientPlans: readonly DecoratedPatientPlan[];
    inactivePatientPlans: readonly DecoratedPatientPlan[];
    visiblePatientPlans: readonly DecoratedPatientPlan[];
}

export default function usePatientPlans(
    clientId: string | null,
    showInactivePlans: boolean,
): UsePatientPlans {
    const globalSettings = useGlobalSettings();

    const { preventionPlans } = usePreventionPlans();

    const [runPatientPlansQuery] = useLazyQuery<
        PatientPlansQueryResult,
        PatientPlansQueryVariables
    >(patientPlansQuery, { fetchPolicy: 'cache-and-network' });

    const [patientsPlansLoading, setPatientsPlansLoading] =
        React.useState(false);

    const [patientPlans, setPatientPlans] = React.useState<
        readonly DecoratedPatientPlan[]
    >([]);

    const loadPatientPlans = React.useCallback(
        async (patients: PatientsQueryResult['patients']) => {
            if (!clientId || patients.length === 0) {
                setPatientPlans([]);
                return;
            }

            setPatientsPlansLoading(true);

            const newPatientPlans: DecoratedPatientPlan[] = [];

            await eachLimit(patients, 5, async (patient) => {
                const { data: patientPlansData } = await runPatientPlansQuery({
                    variables: {
                        patientId: patient.id,
                    },
                });

                newPatientPlans.push(
                    ...(patientPlansData?.patientPlans ?? []).map((plan) =>
                        decoratePatientPlan(
                            globalSettings,
                            preventionPlans,
                            plan,
                        ),
                    ),
                );
            });

            setPatientPlans(newPatientPlans);
            setPatientsPlansLoading(false);
        },
        [clientId, globalSettings, preventionPlans, runPatientPlansQuery],
    );

    const { loading: patientsLoading } = useQuery<
        PatientsQueryResult,
        PatientsQueryVariables
    >(patientsQuery, {
        variables: {
            clientId: clientId ?? '',
        },
        skip: !clientId || preventionPlans?.length === 0,
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => loadPatientPlans(data?.patients ?? []),
    });

    const activePatientPlans = React.useMemo(() => {
        return patientPlans.filter(
            (patientPlan) => patientPlan.status === 'active',
        );
    }, [patientPlans]);

    const inactivePatientPlans = React.useMemo(() => {
        return patientPlans.filter(
            (patientPlan) => patientPlan.status !== 'active',
        );
    }, [patientPlans]);

    const visiblePatientPlans = React.useMemo(() => {
        const newVisiblePlans = showInactivePlans
            ? [...activePatientPlans, ...inactivePatientPlans]
            : activePatientPlans;

        return newVisiblePlans
            .slice()
            .sort((a, b) => a.sortString.localeCompare(b.sortString));
    }, [activePatientPlans, inactivePatientPlans, showInactivePlans]);

    return {
        loading: patientsLoading || patientsPlansLoading,
        activePatientPlans,
        inactivePatientPlans,
        visiblePatientPlans,
    };
}
