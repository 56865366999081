import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';

import styles from './GeneralError.module.scss';

interface GeneralErrorProps {
    message: string;
    className?: string;
}

const GeneralError: React.FunctionComponent<GeneralErrorProps> = ({
    message,
    className,
}) => {
    const translate = useTranslate();
    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.header}>
                {translate('vetspireExtension.error.title')}
            </div>
            <div className={styles.message}>{message}</div>
        </div>
    );
};

export default GeneralError;
