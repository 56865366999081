import { gql } from '@apollo/client';
import {
    Patient,
    ClientFirestoreSessionIds,
    Availability,
} from '@bondvet/types/telehealth';

export type TelehealthInput = {
    email: string | undefined;
    phone: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    reasonId: string;
    notes: string;
    patientId: string | undefined;
    pet: string | undefined;
    species: string | undefined;
    sourceId?: string;
};

export type AppointmentType = {
    _id: string;
    name: string;
};

export type TelehealthAppointmentTypesQueryResult = {
    appointmentTypes: ReadonlyArray<AppointmentType>;
};

export const telhealthAppointmentTypesQuery = gql`
    query appointmentTypes {
        appointmentTypes {
            _id
            name
        }
    }
`;

export type PatientsQueryResult = {
    getPatients: ReadonlyArray<Patient>;
};

export type PatientsQueryVariables = {
    email: string;
};

export const patientsQuery = gql`
    query patients($email: Email!) {
        getPatients(email: $email) {
            _id
            name
            species
        }
    }
`;

export type QueueStatusResult = {
    availability: Availability;
};

export const queueStatusQuery = gql`
    query availability {
        availability {
            open
            blockedOffUntil
        }
    }
`;

export type CreateTelehealthSessionResult = {
    createTelehealthSession: {
        error?: string;
        sessionIds: ClientFirestoreSessionIds;
    };
};

export const createTelehealthSessionMutation = gql`
    mutation CreateTelehealthSession(
        $input: TelehealthSessionInput!
        $paymentIntentId: String!
    ) {
        createTelehealthSession(
            input: $input
            paymentIntentId: $paymentIntentId
        ) {
            error
            sessionIds {
                sessionId
                authToken
                twilioAuthToken
            }
        }
    }
`;

export const createPaymentIntentForClientMutation = gql`
    mutation createPaymentIntentForClient(
        $clientId: String!
        $sourceId: String!
    ) {
        createPaymentIntentForClient(
            vetspireClientId: $clientId
            sourceId: $sourceId
            reason: telehealth
        ) {
            paymentIntent {
                id
                clientSecret
                nextAction {
                    type
                }
            }
            error
        }
    }
`;
