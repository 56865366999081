import useVetspireQuery from 'hooks/useVetspireQuery';
import { navigateToVetspireDay } from 'lib/vetspireActions';
import * as React from 'react';
import useLocationId from 'hooks/useLocationId';
import { gql } from '@apollo/client';
import useVetspireContext from 'hooks/useVetspireContext';
import SelectedDay from 'pages/IntradayCapacity/components/SelectedDay/SelectedDay';
import { addMonths, isBefore, parse, format, toDate } from 'date-fns';
import MonthSelect from 'components/MonthSelect';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import styles from './IntradayCapacity.module.scss';
import MonthOverview from './components/MonthOverview';
import useIntradayCapacity from './hooks/useIntradayCapacity';
import { MONTH_FORMAT, DATE_FORMAT } from './types';

const MIN_MONTH = '2024-08';

const locationNameQuery = gql`
    query locationNameQuery($locationId: ID) {
        location(id: $locationId) {
            name
        }
    }
`;

type LocationNameQueryResult = {
    location: null | { name?: string };
};

type LocationNameQueryVariables = {
    locationId: string;
};

export default function IntradayCapacity() {
    const locationId = useLocationId();
    const translate = useTranslate();
    const { data } = useVetspireQuery<
        LocationNameQueryResult,
        LocationNameQueryVariables
    >(locationNameQuery, {
        variables: { locationId },
        skip: !locationId,
        fetchPolicy: 'cache-first',
    });
    const locationName = data?.location?.name;

    const [selectedMonth, setSelectedMonth] = React.useState<string>(() =>
        format(new Date(), MONTH_FORMAT),
    );
    const { selectedScheduleDate } = useVetspireContext();

    const {
        capacities,
        appointments,
        isAdmin,
        viewerName,
        isLoading,
        updateExpandedAccessSlots,
    } = useIntradayCapacity(selectedMonth, locationId);
    const [selectedDate, setSelectedDate] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (selectedScheduleDate) {
            const scheduleMonth = format(
                parse(selectedScheduleDate, DATE_FORMAT, new Date()),
                MONTH_FORMAT,
            );

            setSelectedMonth(scheduleMonth);
            setSelectedDate(selectedScheduleDate);
        }
    }, [selectedScheduleDate]);

    const onSelectDate = React.useCallback(
        (date: string) => {
            const newDate = date === selectedDate ? null : date;

            setSelectedDate(newDate);

            if (newDate !== null && locationName) {
                navigateToVetspireDay(locationName, newDate, false);
            }
        },
        [selectedDate, locationName],
    );

    // clear selected date when a new month is selected
    const onSelectMonth = React.useCallback(
        (newSelectedMonth: string) => {
            let monthOfSelectedDate: string | null = null;
            setSelectedMonth(newSelectedMonth);

            if (selectedDate) {
                monthOfSelectedDate = format(
                    parse(selectedDate, DATE_FORMAT, new Date()),
                    MONTH_FORMAT,
                );
            }

            if (newSelectedMonth !== monthOfSelectedDate) {
                // selected month doesn't match the selected date anymore
                const currentMonth = format(new Date(), MONTH_FORMAT);

                if (currentMonth === newSelectedMonth) {
                    setSelectedDate(format(new Date(), DATE_FORMAT));
                } else {
                    setSelectedDate(null);
                }
            }
        },
        [selectedDate],
    );

    const { minMonth, maxMonth } = React.useMemo(() => {
        const today = toDate(new Date());

        let min = addMonths(today, -24);

        if (isBefore(min, parse(MIN_MONTH, MONTH_FORMAT, new Date()))) {
            min = parse(MIN_MONTH, MONTH_FORMAT, new Date());
        }

        return {
            minMonth: format(min, MONTH_FORMAT),
            maxMonth: format(addMonths(today, 3), MONTH_FORMAT),
        };
    }, []);

    return (
        <div>
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.intradayCapacity.title')}
                </div>
            </div>
            <div className={styles.flyoutBlock}>
                <MonthSelect
                    month={selectedMonth}
                    onChange={onSelectMonth}
                    minMonth={minMonth}
                    maxMonth={maxMonth}
                />
            </div>
            <div className={classnames(styles.floutBlock, styles.hint)}>
                <p>{translate('vetspireExtension.intradayCapacity.hint')}</p>
            </div>
            <div className={styles.flyoutBlock}>
                <MonthOverview
                    month={selectedMonth}
                    onSelectDate={onSelectDate}
                    capacities={capacities}
                    selectedDate={selectedDate}
                    isAdmin={isAdmin}
                    viewerName={viewerName}
                    isLoading={isLoading}
                />
            </div>

            {selectedDate && (
                <SelectedDay
                    date={selectedDate}
                    capacities={capacities[selectedDate]}
                    updateExpandedAccessSlots={updateExpandedAccessSlots}
                    bookedAppointments={appointments}
                />
            )}
        </div>
    );
}
