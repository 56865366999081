import * as React from 'react';
import { CircularProgress } from '@mui/material';
import useFirebaseContext from 'hooks/firebase/useFirebaseContext';

function withFirebaseInitialized(
    WrappedComponent: React.ComponentType,
): React.ComponentType<unknown> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/display-name
    return (props: any) => {
        const { isInitialized } = useFirebaseContext();

        if (isInitialized) {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <WrappedComponent {...props} />;
        }

        return <CircularProgress />;
    };
}

export default withFirebaseInitialized;
