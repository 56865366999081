import { gql } from '@apollo/client';

export const SurgeryReferralFragment = gql`
    fragment SurgeryReferralFragment on SurgeryReferral {
        _id
        patientId
        notes
        linkedInvoices {
            invoiceId
        }
        provider {
            id
            name
        }
        surgeryType {
            _id
            name
            notesRequired
        }
    }
`;

export const GeneralSurgeryReferralQuestionFragment = gql`
    fragment GeneralSurgeryReferralQuestionFragment on GeneralSurgeryReferralQuestion {
        _id
        createdAt
        updatedAt
        question {
            __typename
            type
            label
            required
            ... on SurgeryReferralRadiosQuestion {
                options
            }
            ... on SurgeryReferralCheckboxesQuestion {
                options
            }
            ... on SurgeryReferralDropdownQuestion {
                options
            }
        }
    }
`;
