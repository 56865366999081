import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import useViewerSettings from 'hooks/useViewerSettings';
import classnames from 'classnames';
import Tabs from 'components/Tabs';
import ProviderPaymentTab from 'components/ProviderPaymentTab';
import ImpactBonusTab from 'components/ProviderPayment/components/ImpactBonusTab';
import { ProviderRightValue } from '@bondvet/types/providers';
import { useAnalytics } from 'hooks/useAnalytics';
import { Page } from 'lib/vetspireActions';
import styles from 'components/ProviderPayment/ProviderPayment.module.scss';
import Banner, { BannerContent } from './components/Banner';

type PulseTabs = 'proSal' | 'gainsharing';

const TABS: ReadonlyArray<PulseTabs> = ['proSal', 'gainsharing'];

const Pulse: React.FunctionComponent = () => {
    const [bannerContent, setBannerContent] =
        React.useState<BannerContent | null>(null);
    const analytics = useAnalytics();
    const translate = useTranslate();
    const [currentTab, setCurrentTab] =
        React.useState<PulseTabs>('gainsharing');
    const viewerSettings = useViewerSettings();
    const proSalSetting = [
        ProviderRightValue.enabled_ownRecords,
        ProviderRightValue.enabled_allRecords,
    ].includes(
        viewerSettings.rights?.vetspireExtension_providerPayment ||
            ProviderRightValue.disabled,
    );
    const gainsharingSetting =
        viewerSettings.rights?.vetspireExtension_gainsharing ===
        ProviderRightValue.enabled;

    const changeCurrentTab = React.useCallback((tab: string) => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setCurrentTab(tab as PulseTabs);
            });
        });
    }, []);

    const [tackedTab, setTrackedTab] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (viewerSettings.viewer?.id && currentTab !== tackedTab) {
            setTrackedTab(currentTab);
            analytics.trackEvent(Page.providerPayment, `open_${currentTab}`);
        }
    }, [analytics, currentTab, tackedTab, viewerSettings.viewer?.id]);

    const tabs = React.useMemo(
        () =>
            TABS.map((name) => ({
                name,
                label: translate(
                    `vetspireExtension.providerPayment.tabs.${name}`,
                ),
            })),
        [translate],
    );

    const content = React.useMemo(() => {
        if (gainsharingSetting && proSalSetting) {
            return (
                <>
                    <Tabs
                        currentTab={currentTab}
                        onChange={changeCurrentTab}
                        tabs={tabs}
                    />
                    {currentTab === 'proSal' && (
                        <ProviderPaymentTab
                            setBannerContent={setBannerContent}
                        />
                    )}
                    {currentTab === 'gainsharing' && <ImpactBonusTab />}
                </>
            );
        }
        if (gainsharingSetting) {
            return <ImpactBonusTab />;
        }
        if (proSalSetting) {
            return <ProviderPaymentTab setBannerContent={setBannerContent} />;
        }

        return <></>;
    }, [changeCurrentTab, currentTab, gainsharingSetting, proSalSetting, tabs]);

    return (
        <div className={classnames(styles.container)}>
            <Banner bannerContent={bannerContent} />
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.providerPayment.title', {
                        name: viewerSettings?.viewer?.name || '',
                    })}
                </div>
                <div className={styles.flyoutSubTitle}>
                    {translate('vetspireExtension.providerPayment.subTitle', {
                        name: viewerSettings?.viewer?.name || '',
                    })}
                </div>
            </div>
            {content}
        </div>
    );
};

export default Pulse;
