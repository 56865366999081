import * as React from 'react';
import classnames from 'classnames';
import styles from './Tabs.module.scss';

export interface TabData {
    label: React.ReactNode;
    name: string;
}

export interface TabsProps {
    currentTab: string;
    showCheckList?: boolean;
    onChange: (tab: string) => void;
    tabs: ReadonlyArray<TabData>;
    className?: string;
}

export default function Tabs({
    currentTab,
    onChange,
    tabs,
    className,
}: TabsProps): React.ReactElement {
    return (
        <div
            key={currentTab}
            className={classnames(styles.container, className, {
                [styles.columns2]: tabs.length === 2,
                [styles.columns3]: tabs.length === 3,
                [styles.columns4]: tabs.length === 4,
            })}
        >
            {tabs.map(({ name, label }) => (
                <button
                    key={name}
                    type="button"
                    className={classnames(styles.tab, {
                        [styles.activeTab]: name === currentTab,
                    })}
                    onClick={() => onChange(name)}
                >
                    {label}
                </button>
            ))}
        </div>
    );
}
