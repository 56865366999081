import InfoIcon from '@mui/icons-material/Info';
import * as React from 'react';
import { GraphQLSurgeryAppointment } from '@bondvet/types/surgeryReferrals';
import { openInvoice, openVetspirePage } from 'lib/vetspireActions';
import ActionButton from 'components/ActionButton';
import classNames from 'classnames';
import LightTooltip from 'components/LightTooltip';
import useTranslate from 'hooks/useTranslate';
import useTimezone from 'hooks/useTimezone';
import moment from 'moment-timezone';
import { SurgeryReferralTab } from '../../types';
import styles from './ChecklistAppointment.module.scss';
import useHideAppointment from './useHideAppointment';

export type ChecklistAppointmentProps = {
    appointment: GraphQLSurgeryAppointment;
    onChangeTab: (tab: SurgeryReferralTab) => void;
};

function ChecklistAppointment({
    appointment,
    onChangeTab,
}: ChecklistAppointmentProps): React.ReactElement {
    const { patient, type, location, invoices, start } = appointment;
    const { onHide, processing } = useHideAppointment(appointment.id);
    const userTimezone = useTimezone();
    const timezone = React.useMemo(
        () => location.timezone || userTimezone,
        [location.timezone, userTimezone],
    );

    const handleLink = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (invoices && invoices.length > 0) {
                openInvoice(patient.client.id, patient.id, invoices[0].id);
                onChangeTab('referrals');
            } else {
                openVetspirePage(
                    `/clients/${patient.client.id}/patients/${patient.id}/billing`,
                );
            }
        },
        [patient, invoices, onChangeTab],
    );
    const translate = useTranslate();

    const handleHide = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();

            onHide();
        },
        [onHide],
    );

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <strong>{type.name}</strong>
                <span>{location.name}</span>
                <span>
                    {moment(start, moment.ISO_8601)
                        .tz(timezone)
                        .format('MMM DD, YYYY')}
                </span>
                <span>
                    {patient.name}, {patient.client.name}
                </span>
            </div>
            <div className={styles.actions}>
                <ActionButton
                    type="button"
                    onClick={handleLink}
                    disabled={processing}
                    className={classNames(styles.linkButton, styles.button)}
                >
                    {translate(
                        'vetspireExtension.surgeryReferrals.checklist.link',
                    )}
                </ActionButton>
                <ActionButton
                    type="button"
                    onClick={handleHide}
                    disabled={processing}
                    className={classNames(styles.hideButton, styles.button)}
                >
                    {translate(
                        'vetspireExtension.surgeryReferrals.checklist.remove',
                    )}
                </ActionButton>
                <LightTooltip
                    arrow
                    placement="top"
                    title={translate(
                        'vetspireExtension.surgeryReferrals.checklist.removeInfo',
                    )}
                >
                    <InfoIcon className={styles.infoIcon} />
                </LightTooltip>
            </div>
        </div>
    );
}

export default ChecklistAppointment;
