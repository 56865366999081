import * as React from 'react';
import useProviders from 'hooks/useProviders';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import type { Provider } from 'api/providers/queries';
import {
    TasksCategoriesQueryResult,
    taskCategoriesQuery,
    TaskCategory,
    tasksQuery,
    TasksQueryResult,
} from 'api/tasks/queries';
import { locationsQuery, LocationsQueryResult } from 'api/locations/queries';
import { useQuery } from '@apollo/client';
import { ProviderRightValue } from '@bondvet/types/providers';
import { ProvidersSelect, TaskCategoriesSelect } from 'components/LookupSelect';
import GeneralError from 'components/GeneralError/GeneralError';
import useViewerSettings, {
    ViewerSettingsKey,
    ViewerSettingsNS,
} from 'hooks/useViewerSettings';
import DateFilter from 'components/DateFilter';
import { DateFilterType } from 'components/DateFilter/types';
import TaskItem from './components/TaskItem/TaskItem';
import useDecoratedTasks from './hooks/useDecoratedTasks';

import styles from './Tasks.module.scss';

const Tasks: React.FunctionComponent = () => {
    const translate = useTranslate();
    const [selectedTaskCategories, setSelectedTaskCategories] = React.useState<
        TaskCategory[]
    >([]);
    const [selectedTaskCategoriesRestored, setSelectedTaskCategoriesRestored] =
        React.useState(false);

    const [selectedProviders, setSelectedProviders] = React.useState<
        Provider[]
    >([]);
    const [selectedProvidersRestored, setSelectedProvidersRestored] =
        React.useState(false);

    const locationsQueryResult = useQuery<LocationsQueryResult>(
        locationsQuery,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    const providersQueryResult = useProviders();

    const taskCategoriesQueryResult = useQuery<TasksCategoriesQueryResult>(
        taskCategoriesQuery,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    const tasksQueryResult = useQuery<TasksQueryResult>(tasksQuery, {
        fetchPolicy: 'no-cache',
    });

    const [currentDateFilterType, setCurrentDateFilterType] =
        React.useState<DateFilterType>(DateFilterType.all);

    const viewerSettings = useViewerSettings();

    // restore selectedProviders and selectedTaskCategories from localStorage
    // as soon as all required queries are executed
    React.useEffect(() => {
        if (!viewerSettings.loading && !viewerSettings.error) {
            if (
                !providersQueryResult.loading &&
                !providersQueryResult.error &&
                providersQueryResult.providers
            ) {
                // restore values only until rights are loaded
                if (!selectedProvidersRestored) {
                    if (viewerSettings.rights !== null) {
                        // stop updating as soon as provider rights are loaded
                        setSelectedProvidersRestored(true);
                    }

                    // restrict to own records as long as provider rights are not loaded
                    if (
                        viewerSettings.rights === null ||
                        viewerSettings.rights?.vetspireExtension_tasks !==
                            ProviderRightValue.enabled_allRecords
                    ) {
                        setSelectedProviders(
                            providersQueryResult.providers.filter(
                                (provider) =>
                                    provider.id === viewerSettings.viewer?.id,
                            ),
                        );
                    } else {
                        const newSelectedProviderIds = viewerSettings.getItem<
                            string[]
                        >(
                            ViewerSettingsNS.tasks,
                            ViewerSettingsKey.selectedProviderIds,
                            [],
                        );

                        setSelectedProviders(
                            providersQueryResult.providers.filter((provider) =>
                                newSelectedProviderIds.includes(provider.id),
                            ),
                        );
                    }
                }
            }

            if (
                !taskCategoriesQueryResult.loading &&
                !taskCategoriesQueryResult.error &&
                taskCategoriesQueryResult.data?.taskCategories
            ) {
                // restore values only once
                if (!selectedTaskCategoriesRestored) {
                    setSelectedTaskCategoriesRestored(true);

                    const newSelectedTaskCategoryIds = viewerSettings.getItem<
                        string[]
                    >(
                        ViewerSettingsNS.tasks,
                        ViewerSettingsKey.selectedTaskCategoryIds,
                        [],
                    );

                    setSelectedTaskCategories(
                        taskCategoriesQueryResult.data?.taskCategories.filter(
                            (taskCategory) =>
                                newSelectedTaskCategoryIds.includes(
                                    taskCategory.id,
                                ),
                        ),
                    );
                }
            }
        }
    }, [
        providersQueryResult,
        taskCategoriesQueryResult,
        selectedProvidersRestored,
        selectedTaskCategoriesRestored,
        viewerSettings,
    ]);

    const decoratedTasksWithStats = useDecoratedTasks({
        tasksQueryResult,
        selectedTaskCategories,
        selectedProviders,
        currentDateFilterType,
    });

    const onChangeProviders = (providers: Provider[]) => {
        viewerSettings.setItem<string[]>(
            ViewerSettingsNS.tasks,
            ViewerSettingsKey.selectedProviderIds,
            providers.map((provider) => provider.id),
        );
        setSelectedProviders(providers);
    };

    const onChangeTaskCategories = (taskCategories: TaskCategory[]) => {
        viewerSettings.setItem<string[]>(
            ViewerSettingsNS.tasks,
            ViewerSettingsKey.selectedTaskCategoryIds,
            taskCategories.map((taskCategory) => taskCategory.id),
        );
        setSelectedTaskCategories(taskCategories);
    };

    if (tasksQueryResult.error) {
        return <GeneralError message={tasksQueryResult.error.message} />;
    }

    if (viewerSettings.error) {
        return <GeneralError message={viewerSettings.error.message} />;
    }

    const taskListItems: JSX.Element[] = [];

    const visibleDateFilterTypes =
        currentDateFilterType === DateFilterType.all
            ? [DateFilterType.past, DateFilterType.today, DateFilterType.future]
            : [currentDateFilterType];

    visibleDateFilterTypes.forEach((dateFilterType) => {
        taskListItems.push(
            <div className={styles.dateFilterTypeTitle} key={dateFilterType}>
                {translate(`vetspireExtension.tasks.dueDate.${dateFilterType}`)}
            </div>,
        );
        const decoratedTasks = decoratedTasksWithStats.decoratedTasks.filter(
            (decoratedTask) =>
                decoratedTask.dateFilterType === dateFilterType &&
                decoratedTask.visible,
        );

        if (decoratedTasks.length === 0) {
            taskListItems.push(
                <div
                    className={styles.noTasks}
                    key={`${dateFilterType}-noTasks`}
                >
                    {translate('vetspireExtension.tasks.noTasks')}
                </div>,
            );
        } else {
            decoratedTasks.forEach((decoratedTask) => {
                taskListItems.push(
                    <TaskItem
                        key={decoratedTask.id}
                        decoratedTask={decoratedTask}
                    />,
                );
            });
        }
    });

    return (
        <div className={styles.container}>
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.tasks.title')}
                </div>
            </div>
            <div className={styles.flyoutBlock}>
                <TaskCategoriesSelect
                    taskCategoriesQueryResult={taskCategoriesQueryResult}
                    selectedTaskCategories={selectedTaskCategories}
                    onChange={onChangeTaskCategories}
                    stats={decoratedTasksWithStats.taskCategoryStats}
                />
            </div>
            <div className={styles.flyoutBlock}>
                <ProvidersSelect
                    locationsQueryResult={locationsQueryResult}
                    providersQueryResult={providersQueryResult}
                    selectedProviders={selectedProviders}
                    onChange={onChangeProviders}
                    isDisabled={
                        viewerSettings.rights === null ||
                        viewerSettings.rights.vetspireExtension_tasks !==
                            ProviderRightValue.enabled_allRecords
                    }
                    stats={decoratedTasksWithStats.assigneeStats}
                />
            </div>
            <div
                className={classnames(
                    styles.flyoutBlockFullWidth,
                    styles.noHorizontal,
                )}
            >
                <DateFilter
                    currentDateFilterType={currentDateFilterType}
                    dateStats={decoratedTasksWithStats.dueDateStats}
                    translatePrefix="vetspireExtension.tasks.dueDate"
                    onChange={(dateFilterType) =>
                        setCurrentDateFilterType(dateFilterType)
                    }
                />
            </div>
            <div
                className={classnames(
                    styles.flyoutBlock,
                    styles.noVertical,
                    styles.noHorizontal,
                    styles.taskList,
                )}
            >
                {tasksQueryResult.loading || viewerSettings.loading ? (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                ) : (
                    taskListItems
                )}
            </div>
        </div>
    );
};

export default Tasks;
