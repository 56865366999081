import { CircularProgress } from '@mui/material';
import useCancellationSettings from 'pages/PatientPlans/hooks/useCancellationSettings';
import usePatientPlanAdditionsQuery from 'pages/PatientPlans/hooks/usePatientPlanAdditionsQuery';
import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import { DecoratedPatientPlan } from 'pages/PatientPlans/types';
import globalStyles from 'pages/PatientPlans/PatientPlansGlobal.module.scss';

import styles from './InactivePlanDetails.module.scss';

interface InactivePlanDetailsProps {
    patientPlan: DecoratedPatientPlan;
}

export default function InactivePlanDetails({
    patientPlan,
}: InactivePlanDetailsProps) {
    const translate = useTranslate();

    const { cancellationSettings } = useCancellationSettings();

    const { data, loading } = usePatientPlanAdditionsQuery(
        patientPlan.id ?? '',
    );

    const cancellationReason = React.useMemo(() => {
        return cancellationSettings?.cancellationReasons.find(
            (reason) =>
                reason.value === data?.patientPlanAdditions?.cancellationReason,
        );
    }, [
        cancellationSettings?.cancellationReasons,
        data?.patientPlanAdditions?.cancellationReason,
    ]);

    return (
        <div className={styles.container}>
            <div className={globalStyles.patientName}>
                {patientPlan?.patient?.name}

                <div
                    className={classnames(
                        globalStyles.badge,
                        globalStyles.planName,
                    )}
                >
                    {patientPlan?.preventionPlan?.publicName}
                </div>
                <div
                    className={classnames(globalStyles.badge, {
                        [globalStyles.patientInactive]:
                            patientPlan.status === 'patientInactive',
                        [globalStyles.patientDeceased]:
                            patientPlan.status === 'patientDeceased',
                        [globalStyles.cancelled]:
                            patientPlan.status === 'cancelled',
                        [globalStyles.expired]:
                            patientPlan.status === 'expired',
                    })}
                >
                    {translate(
                        `vetspireExtension.patientPlans.managePlan.status.${patientPlan?.status}`,
                    )}
                </div>
            </div>

            <div className={styles.title}>
                {translate(
                    'vetspireExtension.patientPlans.managePlan.planDates',
                )}
            </div>
            <div>
                {patientPlan?.formattedStartDate}
                {' - '}
                {patientPlan?.formattedEndDate}
            </div>
            {patientPlan?.status === 'cancelled' && (
                <>
                    <div className={styles.title}>
                        {translate(
                            'vetspireExtension.patientPlans.managePlan.cancelReason',
                        )}
                    </div>
                    {loading ? (
                        <CircularProgress size={14} />
                    ) : (
                        <>
                            <div>{cancellationReason?.label ?? ''}</div>
                            <div>
                                {data?.patientPlanAdditions
                                    ?.cancellationReasonNote ?? ''}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
