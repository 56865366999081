import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Select, { MultiValue } from 'react-select';
import styles from 'components/LookupSelect/LookupSelect.module.scss';

import { sendSurgeryReferralContext } from '../../context';
import { SurgeonOption } from '../../types';

import formStyles from '../QuestionForm/QuestionForm.module.scss';

interface RecommendedSurgeonsProps {
    surgeonOptions: ReadonlyArray<SurgeonOption>;
}

export default function RecommendedSurgeons({
    surgeonOptions,
}: RecommendedSurgeonsProps): React.ReactElement {
    const translate = useTranslate();
    const { recommendedSurgeonIds, changeRecommendedSurgeonIds } =
        React.useContext(sendSurgeryReferralContext);

    const onChange = React.useCallback(
        (newOptions: MultiValue<SurgeonOption>) => {
            changeRecommendedSurgeonIds(
                (newOptions as SurgeonOption[])?.map(
                    (option) => option.value,
                ) || [],
            );
        },
        [changeRecommendedSurgeonIds],
    );

    const values = React.useMemo<SurgeonOption[]>(() => {
        return recommendedSurgeonIds.map((id) => ({
            value: id,
            label:
                surgeonOptions.find((option) => option.value === id)?.label ||
                '?',
        }));
    }, [recommendedSurgeonIds, surgeonOptions]);

    return (
        <fieldset className={formStyles.container}>
            <div className={formStyles.label}>
                {translate(
                    'vetspireExtension.surgeryReferrals.send.recommendedSurgeons',
                )}
            </div>
            <div>
                <Select
                    isMulti
                    name="surgeons"
                    options={surgeonOptions}
                    value={values}
                    className={styles.lookup}
                    classNamePrefix="lookup-select"
                    onChange={onChange}
                    placeholder={
                        <div className={styles.placeholder}>
                            <PersonOutlineIcon
                                fontSize="small"
                                classes={{
                                    root: styles.placeholderMuiIcon,
                                }}
                            />
                            {translate(
                                'vetspireExtension.surgeryReferrals.send.recommendedSurgeonsPlaceholder',
                            )}
                        </div>
                    }
                />
            </div>
        </fieldset>
    );
}
