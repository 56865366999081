import * as React from 'react';
import {
    PreferredClinic,
    RECOMMENDED_DATE_EMERGENT,
    RecommendedDate,
    SurgeryReferralAnswer,
    SurgeryType,
} from '@bondvet/types/surgeryReferrals';

export type ChangeAnswer = (
    id: string,
    type: SurgeryReferralAnswer['type'],
    value: string | string[],
) => void;

export interface SendSurgeryReferralContext {
    surgeryType: SurgeryType | null;
    notes: string;
    recommendForEducationalFund: boolean;
    changeNotes: (notes: string | null) => void;
    changeRecommendForEducationalFund: (
        recommendForEducationalFund: boolean,
    ) => void;
    onChange: ChangeAnswer;
    recommendedDate: RecommendedDate;
    changeRecommendedDate: (recommendedDate: RecommendedDate) => void;
    preferredClinic: PreferredClinic;
    changePreferredClinic: (preferredClinic: PreferredClinic) => void;
    recommendedSurgeonIds: string[];
    changeRecommendedSurgeonIds: (newValues: string[]) => void;
}

export const sendSurgeryReferralContext =
    React.createContext<SendSurgeryReferralContext>({
        surgeryType: null,
        notes: '',
        recommendForEducationalFund: false,
        changeNotes: () => {},
        changeRecommendForEducationalFund: () => {},
        onChange: () => {},
        recommendedDate: RECOMMENDED_DATE_EMERGENT,
        changeRecommendedDate: () => {},
        recommendedSurgeonIds: [],
        changeRecommendedSurgeonIds: () => {},
        preferredClinic: '',
        changePreferredClinic: () => {},
    });
