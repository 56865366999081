import * as React from 'react';

export default function useDebouncedState<T>(
    initialValue: T,
    delay = 250,
): [
    T,
    T,
    (newValue: T | ((prevState: T) => T), updateImmediately?: boolean) => void,
] {
    const [currentValue, setCurrentValue] = React.useState(initialValue);
    const [debouncedValue, setDebouncedValue] = React.useState(initialValue);

    const setCurrentValueWithOption = React.useCallback(
        (newValue: T | ((prevState: T) => T), updateImmediately = false) => {
            setCurrentValue(newValue);
            if (updateImmediately) {
                setDebouncedValue(newValue);
            }
        },
        [],
    );

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(currentValue);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [currentValue, delay]);

    return [currentValue, debouncedValue, setCurrentValueWithOption];
}
