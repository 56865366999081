import { openPatientPlans } from 'lib/vetspireActions';
import { DecoratedPatientPlan } from 'pages/PatientPlans/types';
import * as React from 'react';
import ChooseProvider from 'pages/PatientPlans/components/ChooseProvider';
import usePatientPlanSignUp from 'pages/PatientPlans/hooks/usePatientPlanSignUp';
import ManagePlan from 'pages/PatientPlans/components/ManagePlan';
import SelectPlan from 'pages/PatientPlans/components/SelectPlan';
import ActionButton from 'components/ActionButton';
import Tabs from 'components/Tabs';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import useGlobalSettings from './hooks/useGlobalSettings';

import styles from './PatientPlans.module.scss';

const PLAN_TABS = ['selectPlan', 'managePlan'] as const;
type PlanTab = (typeof PLAN_TABS)[number];

export default function PatientPlans() {
    const translate = useTranslate();

    const globalSettings = useGlobalSettings();

    const [currentTab, setCurrentTab] = React.useState<PlanTab>('selectPlan');

    const [selectedPatientPlan, setSelectedPatientPlan] =
        React.useState<DecoratedPatientPlan | null>(null);

    const [showChooseProvider, setShowChooseProvider] = React.useState(false);

    const { openSignUpPage } = usePatientPlanSignUp();

    const planTabs = React.useMemo(
        () =>
            PLAN_TABS.map((name) => ({
                name,
                label: translate(`vetspireExtension.patientPlans.tabs.${name}`),
            })),
        [translate],
    );

    const openPlanSignUp = React.useCallback(
        (providerId: string) => {
            setShowChooseProvider(false);
            openSignUpPage(providerId).then();
        },
        [openSignUpPage],
    );

    const onNext = React.useCallback(() => {
        if (selectedPatientPlan) {
            setCurrentTab('managePlan');
            openPatientPlans(
                selectedPatientPlan?.patient?.client?.id ?? '',
                selectedPatientPlan?.patient?.id ?? '',
            );
        }
    }, [selectedPatientPlan]);

    return (
        <div className={classnames(styles.flyout, styles.container)}>
            <header className={classnames(styles.flyoutBlock, styles.first)}>
                <span className={styles.flyoutTitle}>
                    {translate('vetspireExtension.patientPlans.title')}
                </span>
                <ul className={styles.urls}>
                    <li>
                        <a
                            href={globalSettings.planPoliciesUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translate(
                                'vetspireExtension.patientPlans.urls.planPolicies',
                            )}
                        </a>
                    </li>
                    <li>
                        <a
                            href={globalSettings.faqsUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translate(
                                'vetspireExtension.patientPlans.urls.faqs',
                            )}
                        </a>
                    </li>
                    <li>
                        <a
                            href={globalSettings.overviewUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translate(
                                'vetspireExtension.patientPlans.urls.overview',
                            )}
                        </a>
                    </li>
                </ul>
                <ActionButton
                    type="button"
                    className={classnames(styles.button, styles.signUp)}
                    onClick={() => setShowChooseProvider(true)}
                >
                    {translate('vetspireExtension.patientPlans.actions.signUp')}
                </ActionButton>
            </header>

            <Tabs
                currentTab={currentTab}
                onChange={(newTab) => {
                    if (newTab === 'selectPlan' || selectedPatientPlan) {
                        setCurrentTab(newTab as PlanTab);
                    }
                }}
                tabs={planTabs}
            />

            <div className={styles.tabContent}>
                {currentTab === 'selectPlan' && (
                    <SelectPlan
                        selectedPatientPlan={selectedPatientPlan}
                        setSelectedPatientPlan={setSelectedPatientPlan}
                        onNext={onNext}
                    />
                )}
                {currentTab === 'managePlan' && selectedPatientPlan && (
                    <ManagePlan
                        patientPlanId={selectedPatientPlan.id ?? ''}
                        onBack={() => setCurrentTab('selectPlan')}
                    />
                )}
            </div>

            {showChooseProvider && (
                <ChooseProvider
                    onSubmit={(providerId: string) =>
                        openPlanSignUp(providerId)
                    }
                    onClose={() => setShowChooseProvider(false)}
                />
            )}
        </div>
    );
}
