import * as React from 'react';
import { vetspireContext } from 'context/VetspireContext';

export type EncounterUrlIds = {
    clientId: string | null;
    patientId: string | null;
    encounterId: string | null;
};

export default function useEncounterUrlIds(
    enforcedClientId?: string | null,
    enforcedPatientId?: string | null,
    enforcedEncounterId?: string | null,
): EncounterUrlIds {
    const [{ clientId, patientId, encounterId }, setEncounterUrlIds] =
        React.useState<EncounterUrlIds>({
            clientId: null,
            patientId: null,
            encounterId: null,
        });
    const { pathName } = React.useContext(vetspireContext);

    React.useEffect(() => {
        if (enforcedClientId) {
            setEncounterUrlIds({
                clientId: enforcedClientId,
                patientId: enforcedPatientId || null,
                encounterId: enforcedEncounterId || null,
            });
        } else {
            const match = pathName.match(
                /^\/clients\/(\d+)(?:\/patients\/(\d+))?(?:\/encounters2?\/(\d+))?/,
            );

            if (match) {
                setEncounterUrlIds({
                    clientId: match[1],
                    patientId: match[2] ?? null,
                    encounterId: match[3] ?? null,
                });
            } else {
                setEncounterUrlIds({
                    clientId: null,
                    patientId: null,
                    encounterId: null,
                });
            }
        }
    }, [enforcedClientId, enforcedEncounterId, enforcedPatientId, pathName]);

    return { clientId, patientId, encounterId };
}
