import * as React from 'react';
import { Patient } from 'api/clients/queries';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import LightTooltip from 'components/LightTooltip';
import InfoIcon from '@mui/icons-material/Info';
import { openInvoice } from 'lib/vetspireActions';
import useReferralPatient from '../../hooks/useReferralPatient';
import { GraphQLSurgeryReferral } from '../../api/queries';
import LinkSurgeryReferral from '../LinkSurgeryReferral';

import styles from './SurgeryReferral.module.scss';

interface SurgeryReferralProps {
    patient?: Patient | null;
    referral: GraphQLSurgeryReferral;
    invoiceId: string | null;
    canLink: boolean;
}

export default function SurgeryReferral({
    patient,
    referral,
    invoiceId,
    canLink,
}: SurgeryReferralProps): React.ReactElement {
    const referralPatient = useReferralPatient(referral, patient);
    const translate = useTranslate();
    const { linkedInvoices, surgeryType, provider, notes } = referral;
    const linkedInvoiceId = linkedInvoices?.[0]?.invoiceId;
    const notesRequired = surgeryType?.notesRequired;
    const name = surgeryType?.name;

    const isLinked = !!linkedInvoiceId;

    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

    React.useEffect(() => {
        let handle: NodeJS.Timeout | undefined;

        if (showSuccessMessage) {
            handle = setTimeout(() => {
                handle = undefined;
                setShowSuccessMessage(false);
            }, 5000);
        }

        return () => {
            if (handle !== undefined) {
                clearTimeout(handle);
            }
        };
    }, [showSuccessMessage]);

    const onLinked = React.useCallback(() => {
        setShowSuccessMessage(true);
    }, []);

    const Component = isLinked ? 'button' : 'div';

    const handleClick = React.useMemo<
        React.MouseEventHandler | undefined
    >(() => {
        if (linkedInvoiceId && referralPatient) {
            return (event: React.MouseEvent) => {
                event.preventDefault();
                const {
                    id: patientId,
                    client: { id: clientId },
                } = referralPatient;

                openInvoice(clientId, patientId, linkedInvoiceId);
            };
        }

        return undefined;
    }, [linkedInvoiceId, referralPatient]);

    return (
        <Component
            className={classnames(styles.container, {
                [styles.linked]: isLinked,
            })}
            onClick={handleClick}
        >
            <div className={styles.content}>
                <h3>
                    {name ||
                        translate(
                            'vetspireExtension.surgeryReferrals.referrals.deletedSurgeryType',
                        )}
                    {notesRequired && notes && (
                        <LightTooltip
                            arrow
                            placement="top"
                            title={
                                <>
                                    {notes.substr(0, 500)}
                                    {notes.length > 500 && '...'}
                                </>
                            }
                        >
                            <InfoIcon className={styles.infoIcon} />
                        </LightTooltip>
                    )}
                </h3>
                {isLinked && (
                    <>
                        <em
                            className={classnames({
                                [styles.linkedToCurrentInvoice]:
                                    linkedInvoiceId === invoiceId,
                            })}
                        >
                            {translate(
                                'vetspireExtension.surgeryReferrals.referrals.linkedInvoice',
                                {
                                    invoice: linkedInvoiceId || '',
                                },
                            )}
                            {invoiceId === linkedInvoiceId && (
                                <LightTooltip
                                    arrow
                                    placement="top"
                                    title={translate(
                                        'vetspireExtension.surgeryReferrals.referrals.linkedToCurrentInvoice',
                                    )}
                                >
                                    <InfoIcon className={styles.infoIcon} />
                                </LightTooltip>
                            )}
                        </em>
                    </>
                )}
                <p>
                    {translate(
                        'vetspireExtension.surgeryReferrals.referrals.provider',
                        { name: provider?.name },
                    )}
                    <br />
                    {[
                        referralPatient?.name,
                        referralPatient?.client?.name,
                    ].join(', ')}
                </p>
            </div>

            <div className={styles.actions}>
                <LinkSurgeryReferral
                    invoiceId={invoiceId}
                    referral={referral}
                    isLinked={isLinked}
                    onLinked={onLinked}
                    canLink={canLink}
                />
                {showSuccessMessage && (
                    <small>
                        {translate(
                            'vetspireExtension.surgeryReferrals.referrals.linked',
                        )}
                    </small>
                )}
            </div>
        </Component>
    );
}
