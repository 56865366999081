import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import Select, {
    type ActionMeta,
    type MultiValue,
    type MultiValueRemoveProps,
    components,
} from 'react-select';
import lookupStyles from 'components/LookupSelect/LookupSelect.module.scss';
import type { ProviderSlotOption } from '../../types';
import styles from './SelectExpandedAccessSlots.module.scss';

type SelectExpandedAccessSlotsPros = {
    slots: readonly ProviderSlotOption[];
    onChange: (value: readonly ProviderSlotOption[]) => void;
    readonly: boolean;
};

function isOptionSelected(option: ProviderSlotOption): boolean {
    return option.availableForExtendedAccess;
}

function isOptionDisabled(option: ProviderSlotOption): boolean {
    return !option.canBeSelected;
}

function filterOption(option: { data: ProviderSlotOption }): boolean {
    return option.data.canBeSelected || option.data.availableForExtendedAccess;
}

function MultiValueRemove(
    props: MultiValueRemoveProps<ProviderSlotOption, true>,
) {
    if (props.data.canBeSelected) {
        return <components.MultiValueRemove {...props} />;
    }

    return null;
}

export default function SelectExpandedAccessSlots({
    slots,
    onChange,
    readonly,
}: SelectExpandedAccessSlotsPros) {
    const translate = useTranslate();
    const selected = React.useMemo(
        () => slots.filter((slot) => slot.availableForExtendedAccess),
        [slots],
    );

    const isClearable = React.useMemo(
        () => selected.some((slot) => slot.canBeSelected),
        [selected],
    );
    const isDisabled = React.useMemo(
        () => readonly || !slots.some((slot) => slot.canBeSelected),
        [readonly, slots],
    );

    const handleChange = React.useCallback(
        (
            newValues: MultiValue<ProviderSlotOption>,
            actionMeta: ActionMeta<ProviderSlotOption>,
        ) => {
            switch (actionMeta.action) {
                case 'remove-value':
                case 'deselect-option': {
                    const removedValue =
                        actionMeta.removedValue || actionMeta.option;

                    // only remove values that can be selected (= that are
                    // not before the min hours limit)
                    if (removedValue?.canBeSelected !== false) {
                        onChange(
                            slots.map((slot) => ({
                                ...slot,
                                availableForExtendedAccess:
                                    slot.availableForExtendedAccess &&
                                    slot.time !== removedValue?.time,
                            })),
                        );
                    }
                    break;
                }
                case 'clear':
                    {
                        const removableValues = (actionMeta.removedValues ?? [])
                            .filter((option) => option.canBeSelected)
                            .map((option) => option.time);

                        if (removableValues.length > 0) {
                            onChange(
                                slots.map((slot) => ({
                                    ...slot,
                                    availableForExtendedAccess:
                                        slot.availableForExtendedAccess &&
                                        !removableValues.includes(slot.time),
                                })),
                            );
                        }
                    }
                    break;
                default: {
                    const selectedTimes = newValues.map((slot) => slot.time);

                    onChange(
                        slots.map((slot) => ({
                            ...slot,
                            availableForExtendedAccess: selectedTimes.includes(
                                slot.time,
                            ),
                        })),
                    );
                    break;
                }
            }
        },
        [onChange, slots],
    );

    return (
        <>
            <label
                htmlFor="select-expanded-access-slots"
                className={styles.label}
            >
                {translate(
                    'vetspireExtension.intradayCapacity.selectExpandedAccessSlots.label',
                )}
            </label>
            <Select<ProviderSlotOption, true>
                isMulti
                id="select-expanded-access-slots"
                name="expandedAccessSlots"
                value={selected}
                className={classnames(lookupStyles.lookup, styles.select)}
                classNamePrefix="lookup-select"
                menuPosition="fixed"
                menuPlacement="auto"
                hideSelectedOptions
                onChange={handleChange}
                options={slots}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isOptionSelected={isOptionSelected}
                isOptionDisabled={isOptionDisabled}
                filterOption={filterOption}
                components={{ MultiValueRemove }}
                placeholder={translate(
                    `vetspireExtension.intradayCapacity.selectSlotsPlaceholder${isDisabled ? 'Disabled' : ''}`,
                )}
            />
        </>
    );
}
