import * as React from 'react';

import { ExternalReferralHospitalWithReasons } from '@bondvet/types/externalReferrals';
import styles from './HospitalOverview.module.scss';

type HospitalOverviewProps = {
    hospital: ExternalReferralHospitalWithReasons;
    selected: boolean;
    onClick?: () => void;
};

const HospitalOverview: React.FunctionComponent<HospitalOverviewProps> = ({
    hospital,
    onClick,
    selected,
}) => {
    let containerStyle = styles.container;
    if (selected) {
        containerStyle += ` ${styles.selected}`;
    }
    return (
        <button
            className={containerStyle}
            key={hospital.name}
            type="button"
            onClick={onClick}
        >
            <span>{hospital.name}</span>
            {/* <div className={styles.address}>{hospital.address}</div> */}
        </button>
    );
};

export default HospitalOverview;
