import useVetspireSettings from 'hooks/useVetspireSettings';
import { openPatientChart } from 'lib/vetspireActions';
import * as React from 'react';
import classnames from 'classnames';
import PatientsSelect from 'components/LookupSelect/PatientsSelect';
import useClientAndPatientId from 'hooks/useClientAndPatientId';
import useTranslate from 'hooks/useTranslate';
import { Patient } from '@bondvet/types/generated/vetspire';
import useClientDetails from 'hooks/useClientDetails';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import styles from './TravelCertificates.module.scss';

export default function TravelCertificates() {
    const translate = useTranslate();
    const vetspireSettings = useVetspireSettings();

    const { clientId, patientId } = useClientAndPatientId();

    const clientDetails = useClientDetails(clientId);

    const patientsFilter = React.useMemo(
        () => ({
            clientId: clientId ?? '',
            isActive: true,
        }),
        [clientId],
    );

    const [selectedPatient, setSelectedPatient] =
        React.useState<Patient | null>(null);

    const onPatientChange = React.useCallback(
        (newPatients: Patient[]) => {
            setSelectedPatient(newPatients[0] ?? null);
            if (newPatients[0]) {
                if (newPatients[0].id !== patientId) {
                    openPatientChart(
                        newPatients[0].client?.id ?? '',
                        newPatients[0].id,
                    );
                }
            }
        },
        [patientId],
    );

    return (
        <div className={classnames(styles.flyout, styles.container)}>
            <header className={classnames(styles.flyoutBlock, styles.first)}>
                <span className={styles.flyoutTitle}>
                    {translate('vetspireExtension.travelCertificates.title')}
                </span>
            </header>
            {clientId === null ? (
                <div
                    className={classnames(
                        styles.flyoutBlock,
                        styles.first,
                        styles.noClient,
                    )}
                >
                    {translate(
                        'vetspireExtension.travelCertificates.errors.noClient',
                    )}
                </div>
            ) : (
                <>
                    <div className={classnames(styles.flyoutBlock)}>
                        <label>
                            {translate(
                                'vetspireExtension.travelCertificates.client',
                            )}
                        </label>
                        <div>{clientDetails?.name ?? ''}</div>
                    </div>
                    <div className={styles.flyoutBlock}>
                        <label>
                            {translate(
                                'vetspireExtension.travelCertificates.patient',
                            )}
                        </label>
                        <PatientsSelect
                            filters={patientsFilter}
                            selectedPatients={
                                selectedPatient ? [selectedPatient] : []
                            }
                            onChange={onPatientChange}
                            isMulti={false}
                            preselectedPatientIds={patientId ? [patientId] : []}
                        />
                    </div>
                    {selectedPatient && (
                        <ul
                            className={classnames(
                                styles.flyoutBlock,
                                styles.urls,
                            )}
                        >
                            {(vetspireSettings.travelCertificates ?? []).map(
                                ({ name, cert }) => {
                                    const url = (
                                        vetspireSettings.travelCertificateURLTemplate ??
                                        ''
                                    )
                                        .replaceAll('{cert}', cert)
                                        .replaceAll(
                                            '{patientId}',
                                            selectedPatient.id,
                                        );

                                    return (
                                        <li key={cert}>
                                            <a
                                                href={url}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {name}
                                                <OpenInNewIcon fontSize="small" />
                                            </a>
                                        </li>
                                    );
                                },
                            )}
                        </ul>
                    )}
                </>
            )}
        </div>
    );
}
