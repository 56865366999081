import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import NumberCard from 'components/MonthlyPulseReport/components/NumberCard';
import BigNumberCard from 'components/MonthlyPulseReport/components/BigNumberCard';
import BarCard from 'components/MonthlyPulseReport/components/BarCard';
import AverageBarCard from 'components/MonthlyPulseReport/components/AverageBarCard';
import DetailsTable from 'components/MonthlyPulseReport/components/DetailsTable';
import Section from 'components/MonthlyPulseReport/components/Section';
import Glossary from 'components/MonthlyPulseReport/components/Glossary';
import GeneralError from 'components/GeneralError';
import useMonthlyPulseReportData from 'components/MonthlyPulseReport/hooks/useMonthlyPulseReportData';

import styles from './MonthlyPulseReport.module.scss';
import useProviderPaymentSettings from '../../hooks/useProviderPaymentSettings';

const MonthlyPulseReport: React.FunctionComponent = () => {
    const { search } = useLocation();

    const { data: providerPaymentSettingsData } = useProviderPaymentSettings();
    const settings = providerPaymentSettingsData?.providerPaymentSettings;

    const { providerId, month } = React.useMemo(() => {
        const urlParams = new URLSearchParams(search);
        return {
            providerId: urlParams.get('providerId'),
            month: urlParams.get('month'),
        };
    }, [search]);

    const { data, textVersion, reportTranslate, error, loading } =
        useMonthlyPulseReportData(
            month || '',
            providerId || '',
            settings,
            providerId === null || month === null,
        );

    if (loading) {
        return (
            <div className={styles.reportContainer}>
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            </div>
        );
    }

    if (error || !data) {
        return (
            <GeneralError
                message={
                    error?.message || reportTranslate('noDataFound').toString()
                }
            />
        );
    }

    return (
        <>
            <div className={styles.reportContainer}>
                <div className={styles.header}>
                    <span className={styles.title} title={textVersion}>
                        {reportTranslate('title')}
                    </span>
                    <span className={styles.subtitle}>
                        <span>{data.monthWithYear}</span>
                        <span className={styles.divider}>|</span>
                        <span>{data.providerName}</span>
                    </span>
                </div>
                <div className={styles.workSummaryContainer}>
                    <div>
                        <span>
                            {reportTranslate('titles.shiftsWorkedLabel', {
                                month: data.month,
                            })}
                        </span>
                        <span>{data.shiftsWorked}</span>
                    </div>
                    {textVersion === 'v1' && (
                        <div>
                            <span>
                                {reportTranslate('titles.ptoDaysTakenLabel', {
                                    month: data.month,
                                })}
                            </span>
                            <span>{data.ptoShifts}</span>
                        </div>
                    )}
                </div>
                <Section
                    title={reportTranslate('titles.monthProductionBreakdown', {
                        month: data.month,
                    })}
                >
                    <div className={styles.smallColumn}>
                        <NumberCard
                            label={reportTranslate('titles.totalProduction')}
                            subLabel={reportTranslate(
                                'subTitles.includesAdminAndPTO',
                            )}
                            value={data.totalProduction}
                        />
                        <NumberCard
                            label={reportTranslate(
                                'titles.gapToAchieveProductionTarget',
                            )}
                            confetti={data.gapToAchieveProductionTarget === 0}
                            value={data.gapToAchieveProductionTarget}
                        />
                    </div>
                    <div className={styles.smallColumn}>
                        <NumberCard
                            label={reportTranslate('titles.ytdEarnings')}
                            subLabel={reportTranslate(
                                'subTitles.includesBaseSalary',
                            )}
                            value={data.yearToDateEarnings}
                        />
                        <NumberCard
                            label={reportTranslate('titles.proSalEarned')}
                            subLabel={' '}
                            value={data.productionBonus}
                        />
                    </div>
                    <div className={styles.largeColumn}>
                        <BarCard
                            label={reportTranslate('titles.totalProductionMOM')}
                            subLabel={reportTranslate('subTitles.last6Months')}
                            barColor={'#10365A'}
                            stackBarColor={'#C6D1B2'}
                            precision={0}
                            data={data.monthOverMonthProduction}
                            legend={{
                                stackValue: reportTranslate(
                                    'legend.adminCreditPTO',
                                ).toString(),
                                value: reportTranslate(
                                    'legend.totalNetProduction',
                                ).toString(),
                                target: reportTranslate(
                                    'legend.productionTarget',
                                ).toString(),
                            }}
                        />
                    </div>
                </Section>
                <Section title={reportTranslate('titles.yourMonthlySnapshot')}>
                    <div className={styles.largeColumn}>
                        <BarCard
                            label={reportTranslate('titles.top5ApptTypes')}
                            subLabel={reportTranslate('subTitles.byRevenue')}
                            barColor={'#F4D6C6'}
                            precision={0}
                            data={data.topFiveAppointmentTypesByRevenue}
                        />
                        <BarCard
                            label={reportTranslate('titles.top5ApptTypes')}
                            subLabel={reportTranslate('subTitles.byNumber')}
                            unit={'number'}
                            barColor={'#F4D6C6'}
                            precision={0}
                            data={data.topFiveAppointmentTypesByNumber}
                        />
                    </div>
                    <div className={styles.largeColumn}>
                        <BigNumberCard
                            label={reportTranslate(
                                'titles.numOfIncApptsToHitProd',
                            )}
                            subLabel={reportTranslate('subTitles.atCurrentAGT')}
                            value={data.appointmentsToHitProduction ?? 'N/A'}
                        />
                        <div className={styles.grid}>
                            <div className={styles.smallColumn}>
                                <AverageBarCard
                                    label={reportTranslate(
                                        'titles.apptsPerShift',
                                    )}
                                    barColor={'#F4D6C6'}
                                    precision={1}
                                    data={data.appointmentsPerShiftEntries}
                                />
                                <AverageBarCard
                                    label={reportTranslate(
                                        'titles.revenuePerShift',
                                    )}
                                    barColor={'#F4D6C6'}
                                    precision={0}
                                    isMoney
                                    data={data.revenuePerShiftEntries}
                                />
                            </div>
                            <div className={styles.smallColumn}>
                                <AverageBarCard
                                    label={reportTranslate('titles.agt')}
                                    barColor={'#F4D6C6'}
                                    precision={0}
                                    isMoney
                                    data={data.agtEntries}
                                />
                                <AverageBarCard
                                    label={reportTranslate('titles.nps')}
                                    barColor={'#F4D6C6'}
                                    precision={1}
                                    data={data.npsEntries}
                                />
                            </div>
                        </div>
                    </div>
                </Section>
            </div>
            <div className={styles.reportContainer}>
                <Section
                    title={reportTranslate('titles.detailedView', {
                        month: `${data.month} `,
                    })}
                >
                    <DetailsTable
                        inClinic={data.detailsTable.rows.inClinic}
                        atHome={data.detailsTable.rows.atHome}
                        misc={data.detailsTable.rows.misc}
                        homeLocation={data.homeLocation}
                        summary={data.detailsTable.summary}
                        productionRate={data.providerPayRate}
                        footer={reportTranslate(
                            'table.footer.netProductionFooter',
                        )}
                        reportTranslate={reportTranslate}
                        textVersion={textVersion}
                    />
                </Section>
            </div>
            {data.extraShiftDays.length !== 0 && (
                <>
                    <div className={styles.extraShiftsTitleContainer}>
                        <Section
                            title={reportTranslate('titles.extraShifts')}
                        />
                    </div>
                    {data.extraShiftDays.map((day, idx) => (
                        <div
                            className={classnames(styles.reportContainer, {
                                [styles['new-page']]: idx > 0,
                            })}
                            key={day.day}
                        >
                            {textVersion < 'v3' ? (
                                <div
                                    className={styles.extraShiftBonusContainer}
                                >
                                    <div className={styles.extraShiftBonus}>
                                        {day.dailyBonus}
                                    </div>
                                    <div className={styles.label}>
                                        {reportTranslate(
                                            'titles.extraShiftDayBonusEarned',
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={classnames(
                                        styles.extraShiftBonusContainer_v3,
                                        styles.regular,
                                    )}
                                >
                                    <div className={styles.valueWithLabel}>
                                        <div className={styles.value}>
                                            {data.extraShiftBonus}
                                        </div>
                                        <div className={styles.label}>
                                            {reportTranslate(
                                                'titles.extraShiftDayBase',
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.valueWithLabel}>
                                        <div className={styles.value}>
                                            {day.dailyBonus}
                                        </div>
                                        <div className={styles.label}>
                                            {reportTranslate(
                                                'titles.extraShiftDayBonusEarned',
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <DetailsTable
                                day={day.day}
                                inClinic={day.rows.inClinic}
                                atHome={day.rows.atHome}
                                misc={day.rows.misc}
                                summary={day.summary}
                                productionRate={data.providerPayRate}
                                textVersion={textVersion}
                                reportTranslate={reportTranslate}
                            />

                            {textVersion >= 'v3' && (
                                <div className={styles.extraShiftDayFooter}>
                                    <span>
                                        {reportTranslate(
                                            'table.footer.extraShiftDayFooter',
                                        )}
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </>
            )}
            {data.criticalExtraShiftDays.length !== 0 && (
                <>
                    <div className={styles.extraShiftsTitleContainer}>
                        <Section
                            title={reportTranslate(
                                'titles.criticalExtraShifts',
                            )}
                        />
                    </div>
                    {data.criticalExtraShiftDays.map((day, idx) => (
                        <div
                            className={classnames(styles.reportContainer, {
                                [styles['new-page']]: idx > 0,
                            })}
                            key={day.day}
                        >
                            <div
                                className={classnames(
                                    styles.extraShiftBonusContainer_v3,
                                    styles.critical,
                                )}
                            >
                                <div className={styles.valueWithLabel}>
                                    <div className={styles.value}>
                                        {data.criticalExtraShiftBonus}
                                    </div>
                                    <div className={styles.label}>
                                        {reportTranslate(
                                            'titles.criticalExtraShiftDayBase',
                                        )}
                                    </div>
                                </div>
                                <div className={styles.valueWithLabel}>
                                    <div className={styles.value}>
                                        {day.dailyBonus}
                                    </div>
                                    <div className={styles.label}>
                                        {reportTranslate(
                                            'titles.criticalExtraShiftDayBonusEarned',
                                        )}
                                    </div>
                                </div>
                            </div>

                            <DetailsTable
                                day={day.day}
                                inClinic={day.rows.inClinic}
                                atHome={day.rows.atHome}
                                misc={day.rows.misc}
                                summary={day.summary}
                                productionRate={data.providerPayRate}
                                textVersion={textVersion}
                                reportTranslate={reportTranslate}
                            />

                            <div className={styles.extraShiftDayFooter}>
                                <span>
                                    {reportTranslate(
                                        'table.footer.criticalExtraShiftDayFooter',
                                    )}
                                </span>
                            </div>
                        </div>
                    ))}
                </>
            )}
            <div className={styles.reportContainer}>
                <Glossary
                    textVersion={textVersion}
                    reportTranslate={reportTranslate}
                    showOnlinePharmacy
                />
            </div>
        </>
    );
};

export default MonthlyPulseReport;
