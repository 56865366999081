import * as React from 'react';
import { useApolloClient } from '@apollo/client';
import {
    Source,
    Stripe,
    StripeCardNumberElement,
    StripeElements,
} from '@stripe/stripe-js';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import {
    TelehealthInput,
    createPaymentIntentForClientMutation,
} from 'api/telehealth/queries';
import useTranslate from 'hooks/useTranslate';
import { initialCardState, useStripe } from './lib';
import {
    CardElements,
    CreatePaymentIntentForClientResult,
    CreditCardControls,
    StripeChangeEvent,
    StripeElement,
} from './types';

import inputStyles from './inputStyles.json';
import useCreateTelehealthSession from '../useCreateTelehealthSession';

async function createStripeSource(
    stripe: Stripe,
    cardNumber: StripeCardNumberElement,
): Promise<Source> {
    const { error, source } = await stripe.createSource(cardNumber, {
        usage: 'reusable',
    });

    if (error) throw new Error(error.message || error.code || error.toString());
    if (!source) throw new Error('No SourceId received.');

    return source;
}

export default function useClientCreditCardForm(
    clientId: string,
): CreditCardControls {
    const stripe = useStripe();
    const apolloClient = useApolloClient();
    const translate = useTranslate();
    const { setupSession } = useCreateTelehealthSession();

    const [initialized, setInitialized] = React.useState(false);
    const [cardNumberRef, setCardNumberRef] =
        React.useState<HTMLDivElement | null>(null);
    const [cardExpiryRef, setCardExpiryRef] =
        React.useState<HTMLDivElement | null>(null);
    const [cardCvcRef, setCardCvcRef] = React.useState<HTMLDivElement | null>(
        null,
    );
    const [cardElements, setCardElements] = React.useState<CardElements | null>(
        null,
    );

    const [{ valid }, setValid] = React.useState<Record<string, boolean>>({
        cardNumberValid: false,
        cardCvcValid: false,
        cardExpiryValid: false,
        valid: false,
    });

    const [{ processing, processingError, success }, setErrorState] =
        React.useState({
            ...initialCardState,
        });

    // set up stripe-dom-elements
    React.useEffect(() => {
        if (
            !initialized &&
            stripe &&
            cardNumberRef &&
            cardCvcRef &&
            cardExpiryRef
        ) {
            const elements: StripeElements = stripe.elements();

            const createChangeHandler = (nextElement?: StripeElement) => {
                return ({ complete, elementType }: StripeChangeEvent) => {
                    requestAnimationFrame(() => {
                        const elementKey = `${elementType}Valid`;

                        setValid((prev) => {
                            const allValid = Object.keys(prev)
                                .filter(
                                    (key) =>
                                        key !== 'valid' && key !== elementKey,
                                )
                                .reduce((result, key) => {
                                    if (key === elementKey) {
                                        return result && complete;
                                    }

                                    return result && prev[key];
                                }, complete);

                            return {
                                ...prev,
                                [elementKey]: complete,
                                valid: allValid,
                            };
                        });
                    });

                    if (complete && nextElement) {
                        nextElement.focus();
                    }
                };
            };

            const cardNumberElement = elements.create('cardNumber', {
                showIcon: true,
                style: inputStyles,
            });
            const cardExpiryElement = elements.create('cardExpiry', {
                style: inputStyles,
            });
            const cardCvcElement = elements.create('cardCvc', {
                style: inputStyles,
            });

            if (cardNumberRef && cardCvcRef && cardExpiryRef) {
                cardNumberElement.mount(cardNumberRef);
                cardNumberElement.on('ready', () => {
                    cardNumberElement.focus();
                });

                cardExpiryElement.mount(cardExpiryRef);
                cardCvcElement.mount(cardCvcRef);

                cardNumberElement.on(
                    'change',
                    createChangeHandler(cardExpiryElement),
                );
                cardExpiryElement.on(
                    'change',
                    createChangeHandler(cardCvcElement),
                );
                cardCvcElement.on('change', createChangeHandler());

                setCardElements({
                    cardNumber: cardNumberElement,
                    cardExpiry: cardExpiryElement,
                    cardCvc: cardCvcElement,
                });
            }
            setInitialized(true);
        }
    }, [stripe, initialized, cardNumberRef, cardCvcRef, cardExpiryRef]);

    const setStateToError = (message: string) => {
        setErrorState({
            processing: false,
            processingError: message,
            success: false,
        });
    };

    const handleSubmitNewCard = async (telehealthInput: TelehealthInput) => {
        if (valid && stripe && !processing && cardElements && telehealthInput) {
            setErrorState({
                ...initialCardState,
                processing: true,
            });

            // create a stripe-source for the new card
            const sourceIdResp = await createStripeSource(
                stripe,
                cardElements.cardNumber,
            );

            // create a payment-intent with the card data
            const { data: paymentIntentData, errors: paymentIntentErrors } =
                await apolloClient.mutate<CreatePaymentIntentForClientResult>({
                    mutation: createPaymentIntentForClientMutation,
                    variables: {
                        clientId,
                        sourceId: sourceIdResp.id,
                    },
                    context: {
                        clientName: GRAPHQL_CLIENT_NAMES.creditCards,
                    },
                });

            if (paymentIntentErrors || !paymentIntentData) {
                setStateToError(
                    translate(
                        'vetspireExtension.errors.technicalError',
                    ).toString(),
                );
                return;
            }

            const paymentIntentResult =
                paymentIntentData.createPaymentIntentForClient;

            if (paymentIntentResult.error) {
                setStateToError(paymentIntentResult.error);
                return;
            }

            // confirm the payment because cvc was correct, sets status to "uncaptured"
            const stripeResult = await stripe.confirmCardPayment(
                paymentIntentResult.paymentIntent.clientSecret,
                {
                    payment_method: {
                        card: cardElements.cardNumber,
                    },
                    setup_future_usage: 'off_session',
                },
            );

            if (
                stripeResult.error ||
                stripeResult.paymentIntent.status !== 'requires_capture'
            ) {
                setStateToError(
                    stripeResult.error?.message ||
                        (translate(
                            'vetspireExtension.errors.unknownError',
                        ) as string),
                );
            }

            try {
                if (telehealthInput) {
                    await setupSession(
                        telehealthInput,
                        paymentIntentData.createPaymentIntentForClient
                            .paymentIntent.id,
                        setStateToError,
                    );
                }
            } catch (e) {
                setStateToError((e as Error).message);
            }
        }
    };

    const handleSubmitKnownCard = async (telehealthInput: TelehealthInput) => {
        if (stripe && !processing && telehealthInput) {
            const { email, sourceId } = telehealthInput;
            setErrorState({
                ...initialCardState,
                processing: true,
            });

            // create a paymentIntent - no CVC check because we don't make them enter here
            if (email && sourceId) {
                const { data: paymentIntentData, errors: paymentIntentErrors } =
                    await apolloClient.mutate<CreatePaymentIntentForClientResult>(
                        {
                            mutation: createPaymentIntentForClientMutation,
                            variables: {
                                clientId,
                                sourceId: telehealthInput.sourceId,
                            },
                            context: {
                                clientName: GRAPHQL_CLIENT_NAMES.creditCards,
                            },
                        },
                    );

                if (paymentIntentData?.createPaymentIntentForClient.error) {
                    setStateToError(
                        paymentIntentData.createPaymentIntentForClient.error,
                    );
                    setErrorState({
                        processing: false,
                        success: false,
                        processingError:
                            paymentIntentData.createPaymentIntentForClient
                                .error,
                    });
                    return;
                }

                if (!paymentIntentData || paymentIntentErrors) {
                    setStateToError(
                        translate(
                            'vetspireExtension.errors.unknownError',
                        ).toString(),
                    );
                    setErrorState({
                        processing: false,
                        success: false,
                        processingError: translate(
                            'vetspireExtension.errors.unknownError',
                        ).toString(),
                    });
                    return;
                }

                try {
                    if (telehealthInput) {
                        const setupSessionSuccess = await setupSession(
                            telehealthInput,
                            paymentIntentData.createPaymentIntentForClient
                                .paymentIntent.id,
                            setStateToError,
                        );

                        if (setupSessionSuccess) {
                            setErrorState({
                                processing: false,
                                success: true,
                                processingError: '',
                            });
                        }
                    }
                } catch (e) {
                    setStateToError((e as Error).message);
                }
            }
        }
    };

    return {
        cardNumberRef: setCardNumberRef,
        cardCvcRef: setCardCvcRef,
        cardExpiryRef: setCardExpiryRef,
        cardDataValid: valid,
        processing,
        processingError,
        success,
        handleSubmitNewCard,
        handleSubmitKnownCard,
    };
}
