import * as React from 'react';
import { gql } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import {
    BookingSetting,
    ChargeLateCancelFeeSetting,
    ChargeVeryLateCancelFeeSetting,
    ChargeLateRescheduleFeeSetting,
    ChargeVeryLateRescheduleFeeSetting,
    ApplyBookingFeeGracePeriodSetting,
} from '@bondvet/types/booking';
import useBookingQuery from 'hooks/useBookingQuery';

const globalBookingSettingsQuery = gql`
    query globalBookingSettings {
        globalBookingSettings {
            ... on HourGlobalBookingSetting {
                _id
                enabled
                hours
                waiveReasons {
                    id
                    label
                }
            }
        }
    }
`;

interface GlobalBookingSettingsQueryResult {
    globalBookingSettings: readonly BookingSetting[];
}

interface UseGlobalBookingSettings {
    globalBookingSettings: readonly BookingSetting[];
    settingsById: {
        chargeLateCancelFee: Omit<ChargeLateCancelFeeSetting, '_id'>;
        chargeVeryLateCancelFee: Omit<
            ChargeVeryLateRescheduleFeeSetting,
            '_id'
        >;
        chargeLateRescheduleFee: Omit<ChargeLateRescheduleFeeSetting, '_id'>;
        chargeVeryLateRescheduleFee: Omit<
            ChargeVeryLateRescheduleFeeSetting,
            '_id'
        >;
        applyBookingFeeGracePeriod: Omit<
            ApplyBookingFeeGracePeriodSetting,
            '_id'
        >;
    };
    loading: boolean;
    error?: ApolloError;
}

export default function useGlobalBookingSettings(): UseGlobalBookingSettings {
    const { data, loading, error } =
        useBookingQuery<GlobalBookingSettingsQueryResult>(
            globalBookingSettingsQuery,
        );

    const settingsById = React.useMemo(() => {
        const chargeLateCancelFee = (data?.globalBookingSettings.find(
            (setting) => setting._id === 'chargeLateCancelFee',
        ) || null) as ChargeLateCancelFeeSetting | null;

        const chargeVeryLateCancelFee = (data?.globalBookingSettings.find(
            (setting) => setting._id === 'chargeVeryLateCancelFee',
        ) || null) as ChargeVeryLateCancelFeeSetting | null;

        const chargeLateRescheduleFee = (data?.globalBookingSettings.find(
            (setting) => setting._id === 'chargeLateRescheduleFee',
        ) || null) as ChargeLateRescheduleFeeSetting | null;

        const chargeVeryLateRescheduleFee = (data?.globalBookingSettings.find(
            (setting) => setting._id === 'chargeVeryLateRescheduleFee',
        ) || null) as ChargeVeryLateRescheduleFeeSetting | null;

        const applyBookingFeeGracePeriod = (data?.globalBookingSettings.find(
            (setting) => setting._id === 'applyBookingFeeGracePeriod',
        ) || null) as ApplyBookingFeeGracePeriodSetting | null;

        return {
            chargeLateCancelFee: {
                enabled: chargeLateCancelFee?.enabled || false,
                hours: chargeLateCancelFee?.hours || 0,
                waiveReasons: chargeLateCancelFee?.waiveReasons || [],
            },
            chargeVeryLateCancelFee: {
                enabled: chargeVeryLateCancelFee?.enabled || false,
                hours: chargeVeryLateCancelFee?.hours || 0,
                waiveReasons: chargeVeryLateCancelFee?.waiveReasons || [],
            },
            chargeLateRescheduleFee: {
                enabled: chargeLateRescheduleFee?.enabled || false,
                hours: chargeLateRescheduleFee?.hours || 0,
                waiveReasons: chargeLateRescheduleFee?.waiveReasons || [],
            },
            chargeVeryLateRescheduleFee: {
                enabled: chargeVeryLateRescheduleFee?.enabled || false,
                hours: chargeVeryLateRescheduleFee?.hours || 0,
                waiveReasons: chargeVeryLateRescheduleFee?.waiveReasons || [],
            },
            applyBookingFeeGracePeriod: {
                enabled: applyBookingFeeGracePeriod?.enabled || false,
                hours: applyBookingFeeGracePeriod?.hours || 0,
            },
        };
    }, [data?.globalBookingSettings]);

    return {
        globalBookingSettings: data?.globalBookingSettings || [],
        settingsById,
        loading,
        error,
    };
}
