import * as React from 'react';
import { openPatientChart, openPatientPlans } from 'lib/vetspireActions';
import ChoosePaymentMethod, {
    type SubmitRef,
} from 'pages/ClientDetails/components/ChoosePaymentMethod';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import { Translated } from '@bondvet/web-app-i18n/util';
import DialogActions from '@mui/material/DialogActions';
import classnames from 'classnames';
import TextArea from 'components/Form/TextArea';
import useTranslate from 'hooks/useTranslate';
import type { DecoratedPatientPlan } from 'pages/PatientPlans/types';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import type { CancellationReason } from '@bondvet/types/patientPlans';
import useCancelPlanMutation from 'pages/PatientPlans/hooks/useCancelPlanMutation';
import useUpdatePatientPlanAdditionsMutation from 'pages/PatientPlans/hooks/useUpdatePatientPlanAdditionsMutation';
import useGlobalSettings from 'pages/PatientPlans/hooks/useGlobalSettings';
import SelectCancellationReason from '../SelectCancellationReason';

import styles from './CancelPlan.module.scss';

interface CancelPlanProps {
    patientPlan: DecoratedPatientPlan;
    onClose: (cancelled: boolean) => void;
}

export default function CancelPlan({ patientPlan, onClose }: CancelPlanProps) {
    const translate = useTranslate();
    const globalSettings = useGlobalSettings();

    const [selectedCancellationReason, setSelectedCancellationReason] =
        React.useState<CancellationReason | null>(null);

    const [cancellationReasonNote, setCancellationReasonNote] =
        React.useState('');

    const submitRef = React.useRef<SubmitRef>(null);

    const [error, setError] = React.useState<Translated | null>(null);

    const [showLimitWarning, setShowLimitWarning] = React.useState(
        !patientPlan.withinCancellationPeriod,
    );

    const [runCancelPlanMutation] = useCancelPlanMutation();
    const [runUpdatePatientPlanAdditionsMutation] =
        useUpdatePatientPlanAdditionsMutation();

    const cancelReasonValid = !!selectedCancellationReason;
    const cancellationReasonNoteValid =
        !!cancellationReasonNote ||
        (!!selectedCancellationReason &&
            !selectedCancellationReason.requiresExplanation);

    const validateCancelReason = React.useCallback((): boolean => {
        if (!cancelReasonValid) {
            setError(
                translate(
                    'vetspireExtension.patientPlans.cancelPlan.errors.cancelReasonNotSet',
                ),
            );
            return false;
        }

        if (!cancellationReasonNoteValid) {
            setError(
                translate(
                    'vetspireExtension.patientPlans.cancelPlan.errors.cancelDescriptionNotSet',
                ),
            );
            return false;
        }

        return true;
    }, [cancelReasonValid, cancellationReasonNoteValid, translate]);

    const onSubmit = React.useCallback(async () => {
        if (showLimitWarning) {
            setShowLimitWarning(false);
            return;
        }

        if (validateCancelReason()) {
            if (submitRef.current) {
                const validCreditCard = await submitRef.current.submit();
                if (!validCreditCard) {
                    setError(
                        translate(
                            'vetspireExtension.patientPlans.errors.invalidCreditCard',
                        ),
                    );
                    return;
                }
            }

            const { errors: cancelPlanErrors } = await runCancelPlanMutation({
                variables: {
                    id: patientPlan.id ?? '',
                },
            });

            if (cancelPlanErrors) {
                setError(cancelPlanErrors[0].message ?? 'Internal Error');
            } else {
                const { errors: addCancellationReasonErrors } =
                    await runUpdatePatientPlanAdditionsMutation({
                        variables: {
                            id: patientPlan.id ?? '',
                            input: {
                                cancellationReason:
                                    selectedCancellationReason?.value ?? '',
                                cancellationReasonNote,
                            },
                        },
                    });

                if (addCancellationReasonErrors) {
                    setError(
                        addCancellationReasonErrors[0].message ??
                            'Internal Error',
                    );
                } else {
                    openPatientChart(
                        patientPlan?.patient?.client?.id ?? '',
                        patientPlan?.patient?.id ?? '',
                    );
                    setTimeout(() => {
                        openPatientPlans(
                            patientPlan?.patient?.client?.id ?? '',
                            patientPlan?.patient?.id ?? '',
                        );
                    }, 500);

                    onClose(true);
                }
            }
        }
    }, [
        showLimitWarning,
        validateCancelReason,
        runCancelPlanMutation,
        patientPlan.id,
        patientPlan?.patient?.client?.id,
        patientPlan?.patient?.id,
        translate,
        runUpdatePatientPlanAdditionsMutation,
        selectedCancellationReason?.value,
        cancellationReasonNote,
        onClose,
    ]);

    return (
        <Dialog open className={styles.container}>
            <DialogTitle className={styles.title}>
                <ErrorIcon className={styles.titleIcon} />
                {translate('vetspireExtension.patientPlans.cancelPlan.title')}
                <IconButton
                    onClick={() => onClose(false)}
                    className={styles.closeIcon}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.content}>
                <div className={styles.generalNote}>
                    {translate(
                        'vetspireExtension.patientPlans.cancelPlan.generalNote',
                        { days: globalSettings.cancellationPeriodDays },
                        { renderInnerHtml: true },
                    )}
                </div>
                {patientPlan.withinCancellationPeriod && (
                    <div className={styles.periodNote}>
                        {translate(
                            'vetspireExtension.patientPlans.cancelPlan.withinPeriodText',
                            { days: globalSettings.cancellationPeriodDays },
                            { renderInnerHtml: true },
                        )}
                    </div>
                )}

                {!patientPlan.withinCancellationPeriod && (
                    <div>
                        <div className={styles.outsidePeriodTitle}>
                            <span className={styles.raisedHand} />
                            {translate(
                                'vetspireExtension.patientPlans.cancelPlan.outsidePeriodTitle',
                            )}
                        </div>

                        <div className={styles.periodNote}>
                            {translate(
                                'vetspireExtension.patientPlans.cancelPlan.outsidePeriodText',
                                { days: globalSettings.cancellationPeriodDays },
                            )}
                        </div>

                        {showLimitWarning && (
                            <div className={styles.outsidePeriodConfirmation}>
                                {translate(
                                    'vetspireExtension.patientPlans.cancelPlan.outsidePeriodConfirmation',
                                )}
                            </div>
                        )}
                    </div>
                )}

                {!showLimitWarning && (
                    <>
                        <div>
                            <SelectCancellationReason
                                selectedCancellationReason={
                                    selectedCancellationReason
                                }
                                setSelectedCancellationReason={
                                    setSelectedCancellationReason
                                }
                                error={
                                    !!error &&
                                    !selectedCancellationReason?.value
                                }
                            />
                            {selectedCancellationReason?.value === 'other' && (
                                <>
                                    <TextArea
                                        id="otherNote"
                                        className={classnames(
                                            styles.cancellationReasonNote,
                                            {
                                                [styles.textAreaError]: !!error,
                                            },
                                        )}
                                        required
                                        rows={3}
                                        label={translate(
                                            'vetspireExtension.patientPlans.cancelPlan.cancellationReason.noteLabel',
                                        )}
                                        value={cancellationReasonNote}
                                        onChange={(value) =>
                                            setCancellationReasonNote(value)
                                        }
                                        placeholder={
                                            translate(
                                                'vetspireExtension.patientPlans.cancelPlan.cancellationReason.notePlaceholder',
                                            ) as string
                                        }
                                    />
                                </>
                            )}
                        </div>

                        {patientPlan.someItemsRedeemed && (
                            <>
                                <hr />

                                <ChoosePaymentMethod
                                    className={styles.paymentMethod}
                                    clientId={
                                        patientPlan?.patient?.client?.id ?? ''
                                    }
                                    error={false}
                                    ref={submitRef}
                                />
                            </>
                        )}

                        <div className={styles.confirmationNote}>
                            {translate(
                                'vetspireExtension.patientPlans.cancelPlan.confirmationNote',
                                undefined,
                                { renderInnerHtml: true },
                            )}
                        </div>

                        <div className={styles.chargeNote}>
                            {translate(
                                'vetspireExtension.patientPlans.cancelPlan.chargeNote',
                                undefined,
                                { renderInnerHtml: true },
                            )}
                        </div>
                        {error && <div className={styles.error}>{error}</div>}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    variant="text"
                    color="error"
                    onClick={() => onClose(false)}
                    className={styles.button}
                >
                    {translate(
                        'vetspireExtension.patientPlans.cancelPlan.actions.cancel',
                    )}
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="error"
                    className={classnames(styles.button, styles.nextButton)}
                    onClick={onSubmit}
                >
                    {translate(
                        // eslint-disable-next-line no-nested-ternary
                        showLimitWarning
                            ? 'vetspireExtension.patientPlans.cancelPlan.actions.next'
                            : patientPlan.someItemsRedeemed
                              ? 'vetspireExtension.patientPlans.cancelPlan.actions.chargeAndSubmit'
                              : 'vetspireExtension.patientPlans.cancelPlan.actions.submit',
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
