import { gql } from '@apollo/client';

export const PatientPlanFragment = gql`
    fragment PatientPlanFragment on PatientPlan {
        id
        startDate
        endDate
        cancelled
        updatedAt
        planInterval
        location {
            id
            name
        }
        patient {
            id
            name
            isActive
            isDeceased
            deceasedDate
            updatedAt
            client {
                id
                name
            }
        }
        preventionPlan {
            id
            name
        }
        items {
            id
            name
            quantity
            quantityUsed
        }
    }
`;

export const PreventionPlanFragment = gql`
    fragment PreventionPlanFragment on PreventionPlan {
        id
        options {
            name
            quantity
            product {
                id
                name
                firstPrice
                secondPrice
            }
            package {
                id
                name
                fixedPrice
            }
            required
            selected
            totalPrice
            monthlyPrice
            monthlyDiscountedPrice
            quarterlyPrice
            quarterlyDiscountedPrice
            yearlyPrice
            yearlyDiscountedPrice
        }
        dynamicPrice
        species
        sex
        neutered
        publicName
        note
        totalValue
        totalPrice
        monthlyPrice
        monthlyDiscountedPrice
        quarterlyPrice
        quarterlyDiscountedPrice
        yearlyPrice
        yearlyDiscountedPrice
        pricesWithOptions {
            totalPrice
            monthlyPrice
            monthlyDiscountedPrice
            quarterlyPrice
            quarterlyDiscountedPrice
            yearlyPrice
            yearlyDiscountedPrice
        }
        quarterlyPaymentDiscountPercent
        yearlyPaymentDiscountPercent
        autoRenewPreventionPlanId
    }
`;
