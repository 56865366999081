import * as React from 'react';
import classnames from 'classnames';
import { ProactiveMessagingVariable } from '@bondvet/types/textMessaging';
import TextInput from 'components/Form/TextInput';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import TextingTooltip from '../TextingTooltip';
import styles from './VariableInput.module.scss';

interface FreeFormVariableInputProps {
    inputRef?: (ref: HTMLInputElement | null) => void;
    variable: ProactiveMessagingVariable;
    value: string;
    onChange: (newValue: string) => void;
    error?: string;
    disabled?: boolean;
}

const beautifyIdentifierPreview = (identifier: string) => {
    return identifier
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export default function VariableInput({
    inputRef,
    variable,
    value,
    onChange,
    error,
    disabled = false,
}: FreeFormVariableInputProps): React.ReactElement {
    const valid = value && value.length <= variable.maxCharacters;

    return (
        <div className={styles.root}>
            <TextInput
                id={variable._id}
                inputRef={inputRef}
                value={value}
                label={
                    <span>
                        <span>
                            {beautifyIdentifierPreview(variable.identifier)}
                        </span>
                        <TextingTooltip
                            title={variable.description}
                            placement="top"
                        >
                            <InfoIcon className={styles.infoIcon} />
                        </TextingTooltip>
                    </span>
                }
                onChange={onChange}
                disabled={disabled}
                error={error}
                className={styles.input}
            />
            <div
                className={classnames(styles.iconWrapper, {
                    [styles.valid]: valid,
                })}
            >
                <CheckCircleIcon />
            </div>
            <div className={styles.characterCount}>
                <span className={classnames({ [styles.invalid]: !valid })}>
                    {value.length}
                </span>
                /{variable.maxCharacters}
            </div>
        </div>
    );
}
