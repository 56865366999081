import * as React from 'react';
import classnames from 'classnames';
import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';

import { useTranslate } from '@bondvet/web-app-i18n/util';

import SearchInput from 'components/SearchInput';
import useDebouncedState from 'hooks/useDebouncedState';
import { formatPhoneNumber } from 'lib/utils';
import {
    clientsWithPatientsSearch,
    ClientsWithPatientsSearchResult,
    ClientsWithPatientsSearchVariables,
    VetspireClientWithPatients,
    ClientWithPatients,
} from '../../api';
import TextingTooltip from '../TextingTooltip';

import { checkClientConversationalOptInStatus } from '../../util';

import styles from './ClientSearch.module.scss';

type ClientSearchProps = {
    setSelectedClient: (newClient: ClientWithPatients) => void;
};

export default function ClientSearch({
    setSelectedClient,
}: ClientSearchProps): React.ReactElement {
    const translate = useTranslate();

    const [searchString, debouncedSearchString, setSearchString] =
        useDebouncedState<string>('');

    const [searchResult, setSearchResult] = React.useState<
        readonly VetspireClientWithPatients[]
    >([]);

    const [isFocused, setIsFocused] = React.useState(false);

    const { loading: searchClientsLoading } = useQuery<
        ClientsWithPatientsSearchResult,
        ClientsWithPatientsSearchVariables
    >(clientsWithPatientsSearch, {
        skip: !debouncedSearchString,
        variables: { query: debouncedSearchString },
        onCompleted: (newSearchResults) => {
            setSearchResult(newSearchResults.searchAccounts);
        },
    });

    const handleSearchSelect = React.useCallback(
        (selectedClient: VetspireClientWithPatients) => {
            setSelectedClient({
                id: selectedClient.id,
                familyName: selectedClient.familyName || '',
                givenName: selectedClient.givenName || '',
                pets: selectedClient.patients.map((patient) => ({
                    id: patient.id,
                    name: patient.name,
                    inactive: !patient.isActive,
                    deceased: patient.isDeceased,
                    sex: patient.sex,
                })),
                preferredPhoneNumber:
                    selectedClient.preferredPhoneNumber?.value || '',
                optedIn: !!selectedClient.externalCommPreferences.find(
                    (pref) => !!pref.postcardMarketing,
                ),
            });
        },
        [setSelectedClient],
    );
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        // setting a bit of a timeout to still capture
        // the click event inside of the results list
        setTimeout(() => {
            setIsFocused(false);
        }, 200);
    };

    return (
        <div className={styles.root}>
            <SearchInput
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={styles.searchInput}
                value={searchString || ''}
                placeholder={translate(
                    'vetspireExtension.texting.clientSearch.placeholder',
                ).toString()}
                onChange={(newValue) => setSearchString(newValue)}
            />
            <TableContainer
                className={classnames(styles.searchResults, {
                    [styles.hidden]:
                        searchString === '' ||
                        debouncedSearchString === '' ||
                        !isFocused,
                })}
                sx={{ maxHeight: 400 }}
            >
                {searchClientsLoading ? (
                    <CircularProgress />
                ) : (
                    <Table stickyHeader>
                        <TableHead className={styles.header}>
                            <TableRow>
                                <TableCell>
                                    {translate(
                                        'vetspireExtension.texting.clientSearch.client',
                                    )}
                                </TableCell>
                                <TableCell>
                                    {translate(
                                        'vetspireExtension.texting.clientSearch.phone',
                                    )}
                                </TableCell>
                                <TableCell>
                                    {translate(
                                        'vetspireExtension.texting.clientSearch.pets',
                                    )}
                                    <TextingTooltip
                                        title={translate(
                                            'vetspireExtension.texting.clientSearch.petsTooltip',
                                            undefined,
                                            { renderInnerHtml: true },
                                        )}
                                        placement="top"
                                    >
                                        <InfoIcon />
                                    </TextingTooltip>
                                </TableCell>
                                <TableCell>
                                    {translate(
                                        'vetspireExtension.texting.clientSearch.optedIn',
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!searchResult?.length && (
                                <TableRow>
                                    <TableCell
                                        colSpan={4}
                                        className={styles.noResults}
                                    >
                                        {translate(
                                            'vetspireExtension.texting.clientSearch.noResults',
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                            {searchResult &&
                                searchResult.map((client) => {
                                    const optedIn =
                                        checkClientConversationalOptInStatus(
                                            client,
                                        );

                                    const hasPhone =
                                        !!client.preferredPhoneNumber?.value;

                                    return (
                                        <TableRow
                                            onClick={
                                                hasPhone
                                                    ? () =>
                                                          handleSearchSelect(
                                                              client,
                                                          )
                                                    : undefined
                                            }
                                            className={classnames({
                                                [styles.disabled]: !hasPhone,
                                            })}
                                            key={client.id}
                                        >
                                            <TableCell
                                                className={classnames({
                                                    [styles.disabled]:
                                                        !hasPhone,
                                                })}
                                            >
                                                {`${client.givenName} ${client.familyName}`}
                                            </TableCell>
                                            <TableCell
                                                className={classnames(
                                                    styles.phone,
                                                    {
                                                        [styles.disabled]:
                                                            !hasPhone,
                                                        [styles.italic]:
                                                            !hasPhone,
                                                    },
                                                )}
                                            >
                                                {formatPhoneNumber(
                                                    client.preferredPhoneNumber
                                                        ?.value,
                                                ) ??
                                                    translate(
                                                        'vetspireExtension.texting.clientSearch.noPhone',
                                                    )}
                                            </TableCell>
                                            <TableCell
                                                className={classnames({
                                                    [styles.disabled]:
                                                        !hasPhone,
                                                })}
                                            >
                                                {(client.patients || []).map(
                                                    (p, index) => (
                                                        <span
                                                            className={classnames(
                                                                styles.patient,
                                                                {
                                                                    [styles.inactive]:
                                                                        !p.isActive,
                                                                    [styles.deceased]:
                                                                        p.isDeceased,
                                                                },
                                                            )}
                                                            key={p.id}
                                                        >
                                                            {p.name}
                                                            {index ===
                                                            (
                                                                client.patients ||
                                                                []
                                                            ).length -
                                                                1
                                                                ? ''
                                                                : ', '}
                                                        </span>
                                                    ),
                                                )}
                                            </TableCell>
                                            <TableCell
                                                className={classnames({
                                                    [styles.notOptedIn]:
                                                        !optedIn,
                                                    [styles.disabled]:
                                                        optedIn && !hasPhone,
                                                })}
                                            >
                                                {optedIn ? (
                                                    <CheckIcon />
                                                ) : (
                                                    <CloseIcon />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </div>
    );
}
