import * as React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import { ProactiveMessagingTemplate } from '@bondvet/types/textMessaging';

import useTextMessagingMutation from 'hooks/useTextMessagingMutation';
import { getVetspireLocationId } from 'context/VetspireContext';
import useViewerSettings from 'hooks/useViewerSettings';

import {
    sendProactiveMessageMutation,
    SendProactiveMessageMutationResult,
    SendProactiveMessageMutationVariables,
    ClientWithPatients,
    CachedUserConversationHistoryQueryResult,
} from '../../api';
import MessageVariableEditor from '../MessageVariableEditor';
import MessageTemplateSelection from '../MessageTemplateSelection';

import styles from './SendOutboundMessageModal.module.scss';
import { OnSubmitParams } from '../MessageVariableEditor/useMessageVariableForm';

interface SendOutboundMessageModalProps {
    client: ClientWithPatients;
    onClose: () => void;
    refetchCachedConversations: () => Promise<
        ApolloQueryResult<CachedUserConversationHistoryQueryResult>
    >;
}

export default function SendOutboundMessageModal({
    client,
    onClose,
    refetchCachedConversations,
}: SendOutboundMessageModalProps): React.ReactElement {
    const viewerSettings = useViewerSettings();
    const providerId = viewerSettings.viewer?.id || 'missing';
    const locationId = getVetspireLocationId();

    const [step, setStep] = React.useState<'chooseTemplate' | 'setVariables'>(
        'chooseTemplate',
    );
    const [selectedTemplate, setSelectedTemplate] =
        React.useState<ProactiveMessagingTemplate | null>(null);

    const [sendingMessage, setSendingMessage] = React.useState(false);
    const [sendProactiveMessage, { loading: submitLoading }] =
        useTextMessagingMutation<
            SendProactiveMessageMutationResult,
            SendProactiveMessageMutationVariables
        >(sendProactiveMessageMutation, {
            onCompleted: (data) => {
                if (data?.sendProactiveMessage.success) {
                    refetchCachedConversations().then();
                    onClose();
                    setSendingMessage(false);
                }
            },
        });

    const onSubmit = React.useCallback(
        ({
            freeFormVariableValues,
            vetspireVariableOverrideValues,
            patientId,
            appointmentId,
            imageBase64,
        }: OnSubmitParams) => {
            if (client.preferredPhoneNumber && selectedTemplate?._id) {
                // We have to strim the first bit of the base64 that states the file type.
                // It generally looks like "data:image/jpeg;base64,"
                const trimmedBase64 = imageBase64
                    ? imageBase64.substring(imageBase64.indexOf('base64,') + 7)
                    : undefined;

                setSendingMessage(true);

                sendProactiveMessage({
                    variables: {
                        input: {
                            phoneNumber: client.preferredPhoneNumber,
                            templateId: selectedTemplate?._id ?? '',
                            providerId,
                            locationId,
                            freeFormVariableValues,
                            vetspireVariableOverrideValues,
                            patientId,
                            appointmentId,
                            clientId: client.id,
                            imageBase64: trimmedBase64,
                        },
                    },
                }).then();
            }
        },
        [
            providerId,
            locationId,
            sendProactiveMessage,
            selectedTemplate,
            client,
        ],
    );

    return (
        <Dialog
            open
            classes={{
                paper: classnames(styles.modalContent, {
                    [styles.splitModal]: step === 'chooseTemplate',
                }),
            }}
        >
            <CloseIcon
                className={classnames(styles.close, {
                    [styles.splitModal]: step === 'chooseTemplate',
                })}
                onClick={() => {
                    onClose();
                }}
            />
            {step === 'chooseTemplate' && (
                <MessageTemplateSelection
                    client={client}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    next={() => setStep('setVariables')}
                />
            )}
            {step === 'setVariables' && (
                <MessageVariableEditor
                    client={client}
                    selectedTemplate={selectedTemplate}
                    onBack={() => setStep('chooseTemplate')}
                    onSubmit={onSubmit}
                    submitLoadingOrInProgress={submitLoading || sendingMessage}
                />
            )}
        </Dialog>
    );
}
