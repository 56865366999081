import classnames from 'classnames';
import * as React from 'react';

import { useTranslate } from '@bondvet/web-app-i18n/util';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { CircularProgress, Tab, Tabs } from '@mui/material';

import {
    LocationConversationPet,
    ProactiveMessagingTemplate,
} from '@bondvet/types/textMessaging';

import { ClientAppointment, Patient } from 'api/clients/queries';
import VariableInput from '../VariableFields/VariableInput';
import LocationFields from '../VariableFields/LocationFields';
import PatientSelect from '../VariableFields/PatientSelect';
import AppointmentFields from '../VariableFields/AppointmentFields';
import ClientFields from '../VariableFields/ClientFields';
import { ClientWithPatients } from '../../api';
import useMessageVariableForm, {
    FormState,
    OnSubmitParams,
} from './useMessageVariableForm';

import styles from './MessageVariableEditor.module.scss';
import AttachmentInput from '../VariableFields/AttachmentInput';

interface MessageVariableEditorProps {
    client: ClientWithPatients;
    selectedTemplate: ProactiveMessagingTemplate | null;
    onBack: () => void;
    onSubmit: (params: OnSubmitParams) => void;
    submitLoadingOrInProgress: boolean;
}

export default function MessageVariableEditor({
    client,
    selectedTemplate,
    onBack,
    onSubmit,
    submitLoadingOrInProgress,
}: MessageVariableEditorProps): React.ReactElement {
    const translate = useTranslate();

    const {
        formRefs,
        messageBody,
        errors,
        freeFormVariableValues,
        vetspireVariableOverrideValues,
        selectedLocation,
        selectedPatient,
        selectedAppointment,
        updateFormState,
        previewLoading,
        templateVariablesLoading,
        freeFormTemplateVariables,
        vetspireTemplateVariablesByEntity,
        updateFreeformVariableValues,
        updateOverrideVariableValues,
        removeOverrideVariableValues,
        handleSubmit,
        attachment,
        setAttachment,
    } = useMessageVariableForm({
        client,
        selectedTemplate,
        onSubmit,
    });

    const handlePatientChange = React.useCallback(
        (newPatient: LocationConversationPet | null) => {
            const newFormState: Partial<FormState> = {
                selectedPatient: newPatient,
            };
            if (
                !newPatient ||
                selectedAppointment?.patient?.id !== newPatient.id
            ) {
                newFormState.selectedAppointment = null;
            }
            updateFormState(newFormState);
            removeOverrideVariableValues('patient').then();
        },
        [
            removeOverrideVariableValues,
            selectedAppointment?.patient?.id,
            updateFormState,
        ],
    );

    const handlePatientNameChange = React.useCallback(
        (newName: string) => {
            updateOverrideVariableValues('pet_name', newName);
            if (newName === '') {
                handlePatientChange(null);
            }
        },
        [handlePatientChange, updateOverrideVariableValues],
    );

    const submitDisabled =
        !selectedTemplate ||
        !client.preferredPhoneNumber ||
        previewLoading ||
        submitLoadingOrInProgress;

    const handleAppointmentChange = React.useCallback(
        (newAppointment: Partial<ClientAppointment> | null) => {
            updateFormState({
                selectedAppointment: newAppointment,
                selectedPatient:
                    newAppointment?.patient as Partial<Patient> | null,
            });
            if (newAppointment) {
                updateOverrideVariableValues(
                    'pet_name',
                    newAppointment?.patient?.name ?? '',
                    true,
                );
            }
        },
        [updateFormState, updateOverrideVariableValues],
    );

    const petName = React.useMemo(() => {
        return (
            vetspireVariableOverrideValues.find(
                (override) => override.identifier === 'pet_name',
            )?.value ?? ''
        );
    }, [vetspireVariableOverrideValues]);

    return (
        <>
            <DialogContent className={styles.root}>
                {selectedTemplate && (
                    <>
                        <DialogTitle className={styles.title}>
                            {translate(
                                'vetspireExtension.texting.sendOutboundMessageModal.title',
                            )}
                        </DialogTitle>
                        <Tabs
                            value={2}
                            variant="fullWidth"
                            classes={{
                                root: styles.indicatorBackground,
                                indicator: styles.indicator,
                            }}
                        >
                            <Tab
                                classes={{
                                    root: styles.tab,
                                    disabled: styles.selected,
                                }}
                                value={1}
                                disabled
                                label="1. Template"
                            />
                            <Tab
                                classes={{
                                    root: styles.tab,
                                    selected: styles.selected,
                                }}
                                value={2}
                                label="2. Variables"
                            />
                        </Tabs>
                        <div className={styles.variableContent}>
                            <div>
                                {`To: `}
                                <strong>
                                    {`${client.givenName} ${client.familyName}`}
                                </strong>
                                {` ${client.preferredPhoneNumber}`}
                            </div>
                            <div>
                                {'Template: '}
                                <strong>{selectedTemplate.name}</strong>
                            </div>
                            <div className={styles.dashedDivider} />
                            <div className={styles.reminderBlock}>
                                <h3>
                                    {translate(
                                        'vetspireExtension.texting.sendOutboundMessageModal.variables.reminder.title',
                                    )}
                                </h3>
                                <p>
                                    {translate(
                                        'vetspireExtension.texting.sendOutboundMessageModal.variables.reminder.body',
                                    )}
                                </p>
                            </div>
                            <div className={styles.dashedDivider} />
                            {templateVariablesLoading ? (
                                <CircularProgress />
                            ) : (
                                <div>
                                    <ul className={styles.variablesList}>
                                        <LocationFields
                                            inputRefs={(identifier, ref) => {
                                                formRefs.current = {
                                                    ...formRefs.current,
                                                    [identifier]: ref,
                                                };
                                            }}
                                            selectedLocationId={
                                                selectedLocation?.id
                                            }
                                            activeVariables={
                                                vetspireTemplateVariablesByEntity.location
                                            }
                                            onLocationChange={(newLocation) => {
                                                updateFormState({
                                                    selectedLocation:
                                                        newLocation,
                                                });
                                                removeOverrideVariableValues(
                                                    'location',
                                                ).then();
                                            }}
                                            overrideValues={
                                                vetspireVariableOverrideValues
                                            }
                                            updateOverrideValues={
                                                updateOverrideVariableValues
                                            }
                                            errors={errors.vetspire}
                                        />
                                        <ClientFields
                                            inputRefs={(identifier, ref) => {
                                                formRefs.current = {
                                                    ...formRefs.current,
                                                    [identifier]: ref,
                                                };
                                            }}
                                            client={client}
                                            activeVariables={
                                                vetspireTemplateVariablesByEntity.client
                                            }
                                            overrideValues={
                                                vetspireVariableOverrideValues
                                            }
                                            updateOverrideValues={
                                                updateOverrideVariableValues
                                            }
                                            errors={errors.vetspire}
                                        />
                                        <AppointmentFields
                                            inputRefs={(identifier, ref) => {
                                                formRefs.current = {
                                                    ...formRefs.current,
                                                    [identifier]: ref,
                                                };
                                            }}
                                            clientId={client.id}
                                            selectedAppointmentId={
                                                selectedAppointment?.id
                                            }
                                            selectedLocationId={
                                                selectedLocation?.id
                                            }
                                            onChange={handleAppointmentChange}
                                            activeVariables={
                                                vetspireTemplateVariablesByEntity.appointment
                                            }
                                            error={errors.appointmentId}
                                        />
                                        <PatientSelect
                                            inputRefs={(identifier, ref) => {
                                                formRefs.current = {
                                                    ...formRefs.current,
                                                    [identifier]: ref,
                                                };
                                            }}
                                            client={client}
                                            selectedPatientId={
                                                selectedPatient?.id
                                            }
                                            activeVariables={
                                                vetspireTemplateVariablesByEntity.patient
                                            }
                                            onChange={handlePatientChange}
                                            petName={petName}
                                            onNameChange={
                                                handlePatientNameChange
                                            }
                                            overrideValues={
                                                vetspireVariableOverrideValues
                                            }
                                            updateOverrideValues={
                                                updateOverrideVariableValues
                                            }
                                            errors={errors?.vetspire}
                                        />
                                        {freeFormTemplateVariables.map(
                                            (freeformVariable) => (
                                                <li
                                                    key={
                                                        freeformVariable.identifier
                                                    }
                                                >
                                                    <VariableInput
                                                        inputRef={(ref) => {
                                                            formRefs.current[
                                                                freeformVariable.identifier
                                                            ] = ref;
                                                        }}
                                                        variable={
                                                            freeformVariable
                                                        }
                                                        value={
                                                            freeFormVariableValues.find(
                                                                (
                                                                    variableValue,
                                                                ) =>
                                                                    variableValue.identifier ===
                                                                    freeformVariable.identifier,
                                                            )?.value || ''
                                                        }
                                                        onChange={(newValue) =>
                                                            updateFreeformVariableValues(
                                                                freeformVariable.identifier,
                                                                newValue,
                                                            )
                                                        }
                                                        error={
                                                            errors?.freeform?.[
                                                                freeformVariable
                                                                    .identifier
                                                            ]
                                                        }
                                                    />
                                                </li>
                                            ),
                                        )}
                                        {selectedTemplate?.requiresImage && (
                                            <AttachmentInput
                                                attachment={attachment}
                                                setAttachment={setAttachment}
                                                error={errors.attachment}
                                            />
                                        )}
                                    </ul>
                                </div>
                            )}
                            <div className={styles.messagePreview}>
                                <h4>Text Message Preview</h4>
                                <div className={styles.messageWrapper}>
                                    <strong>
                                        {`${client.givenName} ${client.familyName}`}
                                    </strong>
                                    <div>{`From: ${selectedLocation?.name ?? 'Bond Vet'}`}</div>
                                    <hr />
                                    <p
                                        className={styles.messageBody}
                                        dangerouslySetInnerHTML={{
                                            __html: messageBody?.body1 ?? '',
                                        }}
                                    />
                                    {!!messageBody?.body2 && (
                                        <p
                                            className={styles.messageBody}
                                            dangerouslySetInnerHTML={{
                                                __html: messageBody.body2,
                                            }}
                                        />
                                    )}
                                    {previewLoading && (
                                        <div className={styles.loading}>
                                            <CircularProgress />
                                        </div>
                                    )}
                                </div>
                                {selectedTemplate?.requiresImage && (
                                    <div className={styles.messageWrapper}>
                                        <strong>
                                            {`${client.givenName} ${client.familyName}`}
                                        </strong>
                                        <div>{`From: ${selectedLocation?.name ?? 'Bond Vet'}`}</div>
                                        <hr />
                                        {attachment && (
                                            <img
                                                src={attachment}
                                                alt="Attachment"
                                                className={styles.attachment}
                                            />
                                        )}
                                        {!attachment && (
                                            <p
                                                className={classnames(
                                                    styles.messageBody,
                                                    styles.error,
                                                )}
                                            >
                                                Image required. Attach above.
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </DialogContent>
            <DialogActions className={styles.actions}>
                <Button
                    className={classnames(styles.actionButton, {
                        [styles.disabled]: submitDisabled,
                    })}
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                >
                    {submitLoadingOrInProgress ? (
                        translate(
                            'vetspireExtension.texting.sendOutboundMessageModal.variables.submitting',
                        )
                    ) : (
                        <>
                            {translate(
                                'vetspireExtension.texting.sendOutboundMessageModal.variables.submit',
                            )}
                        </>
                    )}
                </Button>
                <Button
                    type="button"
                    className={styles.backButton}
                    onClick={() => onBack()}
                >
                    {translate(
                        'vetspireExtension.texting.sendOutboundMessageModal.variables.cancel',
                    )}
                </Button>
            </DialogActions>
        </>
    );
}
