import * as React from 'react';
import classnames from 'classnames';
import { Translated } from '@bondvet/web-app-i18n/util';
import styles from '../Form.module.scss';

type LabelInputProps = {
    id: string;
    inputRef?: (ref: HTMLInputElement | null) => void;
    label: Translated;
    value: string;
    onChange: (event: string) => void;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    error?: string;
};

export default function TextInput({
    id,
    inputRef,
    label,
    value,
    onChange,
    required,
    disabled,
    error,
    className,
}: LabelInputProps): React.ReactElement {
    return (
        <fieldset className={classnames(styles.container, className)}>
            <label htmlFor={id} className={styles.label}>
                {label}
                {required && ' *'}
            </label>
            <input
                ref={(r) => inputRef?.(r)}
                className={classnames(styles.input, {
                    [styles.error]: !!error,
                })}
                id={id}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                disabled={disabled}
            />
            {error && <div className={styles.errorText}>{error}</div>}
        </fieldset>
    );
}
