import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import useCurrentInvoiceId from 'components/CreditCards/components/DueInvoice/useCurrentInvoiceId';
import Alert from 'components/Alert';
import useTranslate from 'hooks/useTranslate';
import usePatientDetails from 'hooks/usePatientDetails';
import InfoIcon from '@mui/icons-material/Info';
import styles from './ReferralsList.module.scss';
import useSurgeryReferrals from '../../hooks/useSurgeryReferrals';
import SurgeryReferral from '../SurgeryReferral';

interface ReferralsListProps {
    patientId: string | null;
}

export default function ReferralsList({
    patientId,
}: ReferralsListProps): React.ReactElement {
    const invoiceId = useCurrentInvoiceId();
    const { referrals, loading } = useSurgeryReferrals(patientId);
    const translate = useTranslate();
    const { details } = usePatientDetails(patientId);

    // only allow linking if we're displaying an invoice which
    // hasn't been linked yet
    const canLink =
        !!invoiceId &&
        !referrals.find(
            ({ linkedInvoices }) =>
                !!linkedInvoices?.find(
                    (invoice) => invoice.invoiceId === invoiceId,
                ),
        );

    return (
        <div className={styles.container}>
            {!invoiceId && (
                <Alert>
                    {translate(
                        'vetspireExtension.surgeryReferrals.referrals.noInvoiceRoute',
                    )}
                </Alert>
            )}
            {details && (
                <>
                    <p className={styles.intro}>
                        {translate(
                            'vetspireExtension.surgeryReferrals.referrals.intro',
                            { name: details?.name },
                        )}
                    </p>

                    {loading && (
                        <div className={styles.loading}>
                            <CircularProgress />
                        </div>
                    )}

                    {referrals.map((referral) => (
                        <SurgeryReferral
                            referral={referral}
                            key={referral._id}
                            patient={details}
                            invoiceId={invoiceId}
                            canLink={canLink}
                        />
                    ))}

                    {invoiceId && (
                        <p className={styles.info}>
                            <InfoIcon className={styles.infoIcon} />
                            {translate(
                                'vetspireExtension.surgeryReferrals.referrals.info',
                            )}
                        </p>
                    )}
                </>
            )}
        </div>
    );
}
