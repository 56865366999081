import { UKGEmployee } from '@bondvet/types/ukg';
import { gql } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import useSchedulingQuery from 'hooks/useSchedulingQuery';

const ukgEmployeesQuery = gql`
    query ukgEmployees {
        ukgEmployees {
            _id
            first_name
            last_name
            status
        }
    }
`;

export interface UKGEmployeesQueryResult {
    ukgEmployees: ReadonlyArray<UKGEmployee>;
}

type UKGEmployees = {
    loading: boolean;
    error?: ApolloError;
    ukgEmployees: ReadonlyArray<UKGEmployee>;
    refetch: () => void;
};

export default function useUKGEmployees(): UKGEmployees {
    const { data, loading, error, refetch } =
        useSchedulingQuery<UKGEmployeesQueryResult>(ukgEmployeesQuery, {
            fetchPolicy: 'cache-first',
        });

    return {
        loading,
        error,
        ukgEmployees: (data?.ukgEmployees || [])
            .slice(0)
            .sort((a, b) =>
                `${b.first_name || ''} ${b.last_name || ''}`.localeCompare(
                    `${a.first_name || ''} ${a.last_name || ''}`,
                ),
            ),
        refetch,
    };
}
