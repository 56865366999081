import * as React from 'react';
import { Translated } from '@bondvet/web-app-i18n/util';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import classnames from 'classnames';
import Confetti from 'assets/confetti3.png';
import styles from './NumberCard.module.scss';

interface NumberCardProps {
    label: Translated;
    subLabel?: Translated;
    value: number;
    confetti?: boolean;
}

const NumberCard: React.FunctionComponent<NumberCardProps> = ({
    label,
    value,
    subLabel,
    confetti,
}) => {
    const moneyFormatter = useMoneyFormatter({
        maximumFractionDigits: 0,
    });
    return (
        <div className={styles.container}>
            <div
                className={classnames({
                    [styles.confetti]: confetti,
                })}
            />

            {confetti && (
                <img
                    className={styles.confettiIcon}
                    alt="Confetti"
                    src={Confetti}
                />
            )}
            <span className={styles.value}>{moneyFormatter.format(value)}</span>
            <span className={styles.label}>{label}</span>
            {subLabel && <span className={styles.subLabel}>{subLabel}</span>}
        </div>
    );
};

export default NumberCard;
