import * as React from 'react';
import { DateFilterType } from 'components/DateFilter/types';
import { Location } from 'api/locations/queries';
import { Provider } from 'api/providers/queries';
import CircularProgress from '@mui/material/CircularProgress';
import useTranslate from 'hooks/useTranslate';
import ActionButton from 'components/ActionButton';
import styles from './EncountersList.module.scss';
import EncounterItem from '../EncounterItem';
import usePaginatedEncounters from '../../hooks/usePaginatedEncounters';
import { EncounterStats, SelectOption } from '../../types';

interface EncountersListProps {
    dateFilterType: DateFilterType;
    selectedLocation?: Location;
    selectedProviders: Provider[];
    selectedEncounterTypes: SelectOption[];
    paginate?: boolean;
    setEncounterStats?: (stats: Partial<EncounterStats>) => void;
    loadAllData?: boolean;
}

const EncountersList: React.FunctionComponent<EncountersListProps> = ({
    dateFilterType,
    selectedLocation,
    selectedProviders,
    selectedEncounterTypes,
    paginate = false,
    setEncounterStats,
    loadAllData = false,
}) => {
    const translate = useTranslate();
    const {
        loading,
        loadMore,
        allLoaded,
        encounters,
        loadAll,
        encounterStats,
    } = usePaginatedEncounters({
        selectedLocation,
        selectedProviders,
        dateFilterType,
        selectedEncounterTypes,
        paginate,
    });

    React.useEffect(() => {
        if (loadAllData && !allLoaded) {
            loadAll();
        }
    }, [allLoaded, loadAll, loadAllData]);

    React.useEffect(() => {
        if (setEncounterStats && encounterStats) {
            setEncounterStats(encounterStats);
        }
    }, [encounterStats, setEncounterStats]);

    const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        loadMore();
    };

    return (
        <div>
            <div className={styles.dateFilterTypeTitle} key={dateFilterType}>
                {translate(
                    `vetspireExtension.encounters.startDate.${dateFilterType}`,
                )}
            </div>
            {!loading && encounters.length === 0 && (
                <div
                    className={styles.noEncounters}
                    key={`${dateFilterType}-noEncounters`}
                >
                    {translate('vetspireExtension.encounters.noEncounters')}
                </div>
            )}
            {encounters.length !== 0 && !(loadAllData && !allLoaded) && (
                <div>
                    {encounters.map((encounter) => (
                        <EncounterItem
                            key={encounter.id}
                            encounter={encounter}
                            dateFilterType={dateFilterType}
                        />
                    ))}
                </div>
            )}
            {loading && (
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            )}
            {!loading && !allLoaded && (
                <div className={styles.loadMoreButton}>
                    <ActionButton onClick={handleLoadMore}>
                        Load More
                    </ActionButton>
                </div>
            )}
        </div>
    );
};

export default EncountersList;
