import useTranslate from 'hooks/useTranslate';
import * as React from 'react';
import usePatientsQuery from 'hooks/usePatientsQuery';
import Select, { SingleValue, MultiValue } from 'react-select';
import { Patient, PatientFilters } from '@bondvet/types/generated/vetspire';
import isEqual from 'lodash/isEqual';

import styles from './LookupSelect.module.scss';

interface PatientsSelectProps {
    filters: PatientFilters;
    skip?: boolean;
    preselectedPatientIds?: string[];
    selectedPatients: Patient[];
    onChange: (patients: Patient[]) => void;
    isDisabled?: boolean;
    isMulti?: boolean;
    placeholder?: string;
}

export default function PatientsSelect({
    filters,
    skip,
    preselectedPatientIds,
    selectedPatients,
    onChange,
    isDisabled = false,
    isMulti = false,
    placeholder,
}: PatientsSelectProps) {
    const translate = useTranslate();
    const patientsQuery = usePatientsQuery(filters, skip);

    const updatedFromPreselectedPatientIds = React.useRef<string[]>([]);

    React.useEffect(() => {
        let newUpdatedFromPreselectedPatientIds: string[] = [];

        if (patientsQuery.data?.patients) {
            if (preselectedPatientIds && preselectedPatientIds.length > 0) {
                // preselect patients
                newUpdatedFromPreselectedPatientIds =
                    preselectedPatientIds.slice(0);
            } else if (patientsQuery.data?.patients?.length === 1) {
                // preselect patient if client has only one active patient
                onChange(patientsQuery.data?.patients);
            }

            if (
                !isEqual(
                    updatedFromPreselectedPatientIds.current,
                    newUpdatedFromPreselectedPatientIds,
                )
            ) {
                updatedFromPreselectedPatientIds.current =
                    newUpdatedFromPreselectedPatientIds;

                onChange(
                    patientsQuery.data?.patients?.filter((patient) =>
                        newUpdatedFromPreselectedPatientIds.includes(
                            patient.id,
                        ),
                    ),
                );
            }
        }
    }, [onChange, patientsQuery.data?.patients, preselectedPatientIds]);

    const options = React.useMemo(() => {
        return (
            patientsQuery.data?.patients
                .slice()
                .sort((a, b) => (a.name || '').localeCompare(b.name || '')) ||
            []
        );
    }, [patientsQuery.data]);

    const handleChange = React.useCallback(
        (newValues: SingleValue<Patient> | MultiValue<Patient>): void => {
            if (isMulti) {
                onChange((newValues || []) as Patient[]);
            } else {
                onChange([newValues as Patient]);
            }
        },
        [isMulti, onChange],
    );

    return (
        <Select
            isMulti={isMulti}
            isLoading={patientsQuery.loading}
            name="patients"
            options={options}
            value={selectedPatients}
            className={styles.lookup}
            classNamePrefix="lookup-select"
            onChange={handleChange}
            getOptionValue={(patient: Patient) => patient.id}
            getOptionLabel={(patient: Patient) => patient?.name ?? ''}
            isDisabled={isDisabled}
            placeholder={
                <div className={styles.placeholder}>
                    {placeholder ??
                        translate(
                            'vetspireExtension.lookups.patients.placeholder',
                        )}
                </div>
            }
        />
    );
}
