import { gql } from '@apollo/client';
import { ProviderPaymentMonthDataResult } from '@bondvet/types/providerPayment';
import { ProviderPaymentSetting } from '@bondvet/types/providers';

export interface ProviderPaymentMonthData {
    providerPaymentMonthData: ProviderPaymentMonthDataResult;
}

export const providerPaymentMonthData = gql`
    query providerPaymentMonthData(
        $vetspireProviderId: String!
        $month: String!
    ) {
        providerPaymentMonthData(
            vetspireProviderId: $vetspireProviderId
            month: $month
        ) {
            providerName
            month
            homeLocation
            shiftsWorked
            ptoShifts
            totalGrossProduction
            totalNetProduction
            yearToDateEarnings
            gapToAchieveProductionTarget
            productionBonus
            ptoAndAdminCredit
            totalProduction
            netOnlinePharmacyAmount
            grossOnlinePharmacyAmount
            netProductionAdjustmentAmount
            netProductionAdjustmentNote
            productionTarget
            providerPayRate
            gapToAchieveProductionTarget
            monthOverMonthTotalProduction {
                month
                totalNetProduction
                ptoAndAdminCredit
                productionTarget
            }
            topFiveAppointmentTypesByRevenue {
                appointmentTypeName
                value
            }
            topFiveAppointmentTypesByVisits {
                appointmentTypeName
                value
            }
            appointmentsToHitProduction
            revenuePerShift
            appointmentsPerShift
            agt
            nps
            providerProductTypeDetail {
                _id
                name
                productTypeCategory
                netProduction
                grossProduction
                credit
            }
            compareTo {
                revenuePerShift
                appointmentsPerShift
                agt
                nps
                totalGrossProduction
                totalNetProduction
                providerProductTypeDetail {
                    _id
                    name
                    productTypeCategory
                    netProduction
                    grossProduction
                    credit
                }
            }
            extraShiftBonus
            extraShiftDays {
                date
                dailyBonus
                totalNetProduction
                totalGrossProduction
                providerProductTypeDetail {
                    _id
                    name
                    productTypeCategory
                    grossProduction
                    netProduction
                }
            }
            criticalExtraShiftBonus
            criticalExtraShiftDays {
                date
                dailyBonus
                totalNetProduction
                totalGrossProduction
                providerProductTypeDetail {
                    _id
                    name
                    productTypeCategory
                    grossProduction
                    netProduction
                }
            }
        }
    }
`;

export interface ProviderPaymentSettings {
    providerPaymentSettings: ProviderPaymentSetting;
}

export const providerPaymentSettings = gql`
    query providerPaymentSettings {
        providerPaymentSettings {
            firstOnlinePharmacyCreditMonth
            firstCriticalExtraShiftDaysMonth
            firstNoAdminPtoSickMonth
        }
    }
`;
