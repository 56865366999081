import * as React from 'react';
import type { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import {
    type Provider,
    type ProvidersQueryResult,
    type ProvidersQueryVariables,
    providersQuery,
} from 'api/providers/queries';
import useVetspireQuery from './useVetspireQuery';

export type UseProviders = {
    loading: boolean;
    providers: readonly Provider[];
    error?: ApolloError;
};

export type { Provider };

export default function useProviders(
    locationId?: string,
    onlyActive = true,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
): UseProviders {
    const { data, loading, error } = useVetspireQuery<
        ProvidersQueryResult,
        ProvidersQueryVariables
    >(providersQuery, {
        variables: {
            locationId,
            onlyActive,
        },
        fetchPolicy,
    });

    return React.useMemo(
        () => ({
            loading,
            providers: data?.providers ?? [],
            error,
        }),
        [loading, data?.providers, error],
    );
}
