import * as React from 'react';
import classnames from 'classnames';

import useProviders from 'hooks/useProviders';
import useTelehealthQuery from 'hooks/useTelehealthQuery';
import useTranslate from 'hooks/useTranslate';
import withFirebaseInitialized from 'hooks/firebase/withFirebaseInitialized';
import { QueueStatusResult, queueStatusQuery } from 'api/telehealth/queries';

import styles from './CurrentQueue.module.scss';

import useCaseQueue from '../../hooks/useCaseQueue';
import useActiveProviders from '../../hooks/useActiveProviders';

function CurrentQueue(): React.ReactElement {
    const translate = useTranslate();
    const { doctorQueue } = useCaseQueue();
    const { activeProviderIds } = useActiveProviders();

    const { data: queueStatusData } = useTelehealthQuery<QueueStatusResult>(
        queueStatusQuery,
        {
            fetchPolicy: 'cache-first',
        },
    );
    const { providers } = useProviders();
    const queueOpen = React.useMemo(
        () =>
            queueStatusData?.availability.open &&
            queueStatusData.availability.blockedOffUntil === null,
        [queueStatusData],
    );

    const providerList = React.useMemo(
        () =>
            providers.filter((vetspireProvider) =>
                activeProviderIds.includes(vetspireProvider.id),
            ),
        [activeProviderIds, providers],
    );

    return (
        <>
            <header className={classnames(styles.flyoutBlock, styles.first)}>
                <span className={styles.flyoutTitle}>
                    {translate(
                        'vetspireExtension.telehealth.currentQueue.title',
                    )}
                </span>
                <span
                    className={
                        queueOpen
                            ? styles.openQueueStatus
                            : styles.closedQueueStatus
                    }
                >
                    {translate(
                        queueOpen
                            ? 'vetspireExtension.telehealth.currentQueue.active'
                            : 'vetspireExtension.telehealth.currentQueue.inactive',
                    )}
                </span>
            </header>
            <div className={styles.queueContainer}>
                <div>
                    <span className={styles.name}>
                        {translate(
                            'vetspireExtension.telehealth.currentQueue.clientsInQueue',
                        )}
                        :
                    </span>
                    <span className={styles.number}>
                        {doctorQueue?.length ||
                            translate(
                                'vetspireExtension.telehealth.currentQueue.noClientsInQueue',
                            )}
                    </span>
                </div>
            </div>
            <div className={styles.queueContainer}>
                <div>
                    <span className={styles.name}>
                        {translate(
                            'vetspireExtension.telehealth.currentQueue.activeProviders',
                        )}
                        :
                    </span>
                    <span className={styles.number}>
                        {providerList.length > 0
                            ? providerList
                                  .map((provider) => provider?.name)
                                  .join(', ')
                            : translate(
                                  'vetspireExtension.telehealth.currentQueue.noActiveProviders',
                              )}
                    </span>
                </div>
            </div>
        </>
    );
}

export default withFirebaseInitialized(CurrentQueue);
