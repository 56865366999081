import * as React from 'react';
import classnames from 'classnames';
import Select, { SingleValue } from 'react-select';
import useTranslate from 'hooks/useTranslate';
import { CancellationReason } from '@bondvet/types/patientPlans';
import useCancellationSettings from 'pages/PatientPlans/hooks/useCancellationSettings';

import styles from 'components/LookupSelect/LookupSelect.module.scss';

interface SelectCancellationReasonProps {
    selectedCancellationReason: CancellationReason | null;
    setSelectedCancellationReason: (
        cancellationReason: CancellationReason | null,
    ) => void;
    error?: boolean;
}

export default function SelectCancellationReason({
    setSelectedCancellationReason,
    selectedCancellationReason,
    error,
}: SelectCancellationReasonProps) {
    const translate = useTranslate();
    const { cancellationSettings } = useCancellationSettings();

    return (
        <Select
            isMulti={false}
            name="cancellationReason"
            value={selectedCancellationReason}
            required
            className={classnames(styles.lookup, { [styles.error]: error })}
            classNamePrefix="lookup-select"
            options={cancellationSettings.cancellationReasons}
            onChange={(value: SingleValue<CancellationReason>) => {
                setSelectedCancellationReason(
                    value as CancellationReason | null,
                );
            }}
            menuPosition="fixed"
            placeholder={
                <div className={styles.placeholder}>
                    {translate(
                        'vetspireExtension.patientPlans.cancelPlan.cancellationReason.placeholder',
                    )}
                </div>
            }
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
        />
    );
}
