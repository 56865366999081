import usePatientPlansQuery from 'hooks/usePatientPlansQuery';
import { PreventionPlanFragment } from 'pages/PatientPlans/fragments';
import { gql } from '@apollo/client';
import {
    DecoratedPreventionPlan,
    PreventionPlansQueryArguments,
    PreventionPlansQueryResult,
} from '@bondvet/types/patientPlans';

const preventionPlanQuery = gql`
    ${PreventionPlanFragment}
    query preventionPlans($vetspireLocationId: ID) {
        preventionPlans(vetspireLocationId: $vetspireLocationId) {
            ...PreventionPlanFragment
        }
    }
`;

interface UsePreventionPlans {
    loading: boolean;
    preventionPlans: readonly DecoratedPreventionPlan[];
}

interface UsePreventionPlansOptions {
    vetspireLocationId?: string | null;
    skip?: boolean;
}

export default function usePreventionPlans({
    vetspireLocationId = null,
    skip = false,
}: UsePreventionPlansOptions = {}): UsePreventionPlans {
    const { loading, data } = usePatientPlansQuery<
        PreventionPlansQueryResult,
        PreventionPlansQueryArguments
    >(preventionPlanQuery, {
        variables: {
            vetspireLocationId: vetspireLocationId ?? null,
        },
        skip,
    });

    return {
        loading,
        preventionPlans: data?.preventionPlans ?? [],
    };
}
