import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    Patient,
    patientDetails,
    PatientDetailsQuery,
    PatientDetailsQueryVariables,
} from '../api/clients/queries';

type PatientDetails = {
    loading: boolean;
    details: Patient | null;
};

export default function usePatientDetails(
    patientId: string | null,
): PatientDetails {
    const [fetch, { data, loading }] = useLazyQuery<
        PatientDetailsQuery,
        PatientDetailsQueryVariables
    >(patientDetails, {
        fetchPolicy: 'cache-first',
    });
    const [details, setDetails] = React.useState<Patient | null>(null);

    React.useEffect(() => {
        if (patientId) {
            fetch({ variables: { patientId } });
        }
    }, [patientId, fetch]);

    React.useEffect(() => {
        if (patientId && data?.patient?.id === patientId) {
            setDetails(data.patient);
        } else {
            setDetails(null);
        }
    }, [data?.patient, patientId]);

    return {
        loading,
        details,
    };
}
