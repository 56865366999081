import useProviderPaymentQuery from 'hooks/useProviderPaymentQuery';
import { ProviderPaymentSettings } from '../api/queries';
import * as queries from '../api/queries';

const useProviderPaymentSettings = () => {
    return useProviderPaymentQuery<ProviderPaymentSettings>(
        queries.providerPaymentSettings,
        {
            fetchPolicy: 'no-cache',
        },
    );
};

export default useProviderPaymentSettings;
