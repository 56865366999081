import { gql } from '@apollo/client';
import type {
    IdAndName,
    VetspirePhoneNumber,
    VetspireSex,
} from '@bondvet/types/vetspire';
import type { Location } from '@bondvet/types/locations';
import type { ClientExternalCommPreferencesValue } from '@bondvet/types/generated/vetspire';
import type {
    Appointment,
    AppointmentRescheduledAt,
} from '@bondvet/types/appointments';
import type { GlobalSettings } from '@bondvet/types/surgeryCalendar';

import type { CreditCard } from '../types';

export type Client = {
    id: string;
    email: string | null;
    identification: string | null;
    name: string | null;
    familyName: string | null;
    givenName: string | null;
    isActive: boolean;
    primaryLocationId: string | null;
    preferredPhoneNumber: VetspirePhoneNumber;
    externalCommPreferences: ReadonlyArray<ClientExternalCommPreferencesValue>;
};

export const ClientFragment = gql`
    fragment ClientFragment on Client {
        id
        email
        identification
        name
        familyName
        givenName
        isActive
        primaryLocationId
        preferredPhoneNumber {
            value
        }
        externalCommPreferences {
            postcardMarketing
        }
    }
`;

export const clientQuery = gql`
    ${ClientFragment}
    query client($clientId: ID!) {
        client(id: $clientId) {
            ...ClientFragment
        }
    }
`;

export type ClientQueryResult = {
    client: Client | null;
};

export const clientsWithEmail = gql`
    ${ClientFragment}
    query clientsWithEmail($email: String!) {
        clients(filters: { email: $email }) {
            ...ClientFragment
        }
    }
`;

export type ClientsQueryResult = {
    clients: ReadonlyArray<Client>;
};

export const clientAppointmentsQuery = gql`
    query appointments(
        $clientId: ID!
        $patientId: ID
        $locationId: ID
        $start: DateTime
    ) {
        appointments(
            clientId: $clientId
            patientId: $patientId
            locationId: $locationId
            start: $start
            includeDeleted: false
        ) {
            id
            start
            type {
                id
                name
            }
            location {
                id
                name
            }
            patient {
                id
                name
            }
        }
    }
`;

export type ClientAppointment = {
    id: string;
    start: string;
    status: string;
    type: IdAndName;
    patient: IdAndName;
    location: IdAndName;
};

export type ClientAppointmentsQueryVariables = {
    clientId: string;
    patientId?: string | undefined;
    locationId: string | undefined;
    start: Date | undefined;
};

export type ClientAppointmentsQueryResult = {
    appointments: ReadonlyArray<ClientAppointment>;
};

export const creditCards = gql`
    query creditCards($clientId: ID!) {
        creditCards(clientId: $clientId) {
            id
            expirationMonth
            expirationYear
            brand
            last4
            isDefault
        }
    }
`;

export type CreditCardsQueryResult = {
    creditCards: ReadonlyArray<CreditCard>;
};

export const dueInvoices = gql`
    query dueInvoices($clientId: ID!) {
        orders(
            clientId: $clientId
            invoiced: true
            notUncollectible: true
            includeNegatives: false
            includeAccountCredits: false
        ) {
            id
            provider {
                id
                name
            }
            total
            totalPaid
            totalNotRendered
            paymentDueCents
            invoicedAt
            location {
                name
                timezone
            }
            patients {
                id
            }
            client {
                id
                patients {
                    id
                }
            }
        }
    }
`;

type BaseOrder<DecimalType, DateType> = {
    id: string;
    provider?: {
        id: string;
        name: string;
    };
    total: DecimalType;
    totalPaid: DecimalType;
    totalNotRendered: DecimalType;
    paymentDueCents?: number;
    invoicedAt: DateType | null;
    location?: {
        name: string;
        timezone: string;
    };
    patients: ReadonlyArray<{ id: string }> | null;
    client: {
        id: string;
        patients: ReadonlyArray<{ id: string }>;
    };
};

export type RawOrder = BaseOrder<string, string>;

export type Order = BaseOrder<number, import('moment').Moment> & {
    dueAmount: number;
};

export type DueInvoicesQueryResult = {
    orders: ReadonlyArray<RawOrder>;
};

export const patientDetails = gql`
    query patientDetails($patientId: ID!) {
        patient(id: $patientId) {
            id
            name
            sex
            species
            birthDate
            client {
                id
                name
                givenName
                familyName
            }
        }
    }
`;

export type SurgeryCalendarQueryResult = {
    surgeryCalendar: {
        appointments: ReadonlyArray<{
            _id: string;
            locationId: string;
            date: Date;
            appointmentTypeId: string;
            appointmentTypeName: string;
        }>;
        blockOffs: ReadonlyArray<{
            _id: string;
            locationId: string;
            date: Date;
            appointmentTypeId: string;
            appointmentTypeName: string;
        }>;
        shifts: ReadonlyArray<{
            date: string;
            vetspireLocationId: string;
            vetspireProviderId: string;
            name: string;
        }>;
        vetspireLocations: ReadonlyArray<{
            _id: string;
            timezone: string;
        }>;
        globalSettings: GlobalSettings;
    };
};

export const surgeryCalendar = gql`
    query surgeryCalendar(
        $vetspireLocationIds: [ID]!
        $fromDate: DateString!
        $toDate: DateString!
    ) {
        surgeryCalendar(
            vetspireLocationIds: $vetspireLocationIds
            fromDate: $fromDate
            toDate: $toDate
        ) {
            appointments {
                _id
                locationId
                date
                appointmentTypeId
                appointmentTypeName
            }
            blockOffs {
                _id
                locationId
                date
                appointmentTypeId
                appointmentTypeName
            }
            shifts {
                date
                vetspireLocationId
                vetspireProviderId
                name
            }
            globalSettings {
                types {
                    dental
                    surgery
                    hidden
                }
                surgeryDoctorSkillIds
            }
        }
    }
`;

export type Details = {
    id: string;
    name: string;
    givenName: string;
    familyName: string;
    species: string;
    sex: VetspireSex;
    birthDate: string;
    isActive: boolean;
    isDeceased: boolean;
};

export type Patient = Details & {
    client: Details;
};

export type PatientDetailsQuery = {
    patient: Patient;
};

export type PatientDetailsQueryVariables = {
    patientId: string;
};

export const bondLocationQuery = gql`
    query locations {
        locations {
            _id
            name
            timezone
            _vetspire {
                id
            }
        }
    }
`;

export type LifetimeDataWaivedFee = {
    _id: string;
    createdAt: string;
    waiveReason: string;
    feeProductType:
        | 'cancel'
        | 'reschedule'
        | 'veryLateCancel'
        | 'veryLateReschedule';
};

export type RescheduledAtItem = AppointmentRescheduledAt & {
    timestamp: string;
};

export type LifetimeData = Partial<Omit<Appointment, 'rescheduledAt'>> & {
    _id: Appointment['_id'];
} & {
    location?: Location;
    cancelledAt: string | null;
    cancelOrigin?: string;
    removedAt: string | null;
    rescheduledAt: readonly RescheduledAtItem[];
    waivedFees: readonly LifetimeDataWaivedFee[];
};

export interface LifetimeStatsQueryResult {
    lifetimeStats: ReadonlyArray<LifetimeData>;
}

export const lifetimeStatsQuery = gql`
    query lifetimeStatsQuery($clientId: ID!) {
        lifetimeStats(clientId: $clientId) {
            _id
            date
            locationId
            clientId
            isNoShow
            noShowCharged
            noShowInvoiceId
            noShowChargeAttempts
            noShowChargeLastError
            cancelledAt
            cancelledBy
            cancelOrigin
            removedAt
            removedBy
            rescheduledAt {
                timestamp
                providerId
                oldTime
                newTime
                isLateReschedule
                isVeryLateReschedule
            }
            isLateCancellation
            isVeryLateCancellation
            waivedFees {
                _id
                createdAt
                feeProductType
                waiveReason
            }
        }
    }
`;

type BaseCredit<DecimalType, DateType> = {
    amount: DecimalType;
    datetime: DateType | null;
    id: string;
    note: string;
    provider: {
        id: string;
        name: string;
    };
};

export type RawCredit = BaseCredit<string, string>;

export type Credit = BaseCredit<number, import('moment').Moment>;

export interface CreditMemosQueryResults {
    client: {
        creditMemos: ReadonlyArray<RawCredit>;
    };
}

export const creditMemosQuery = gql`
    query creditMemosQuery($clientId: ID!) {
        client(id: $clientId) {
            creditMemos {
                id
                amount
                datetime
                note
                provider {
                    id
                    name
                }
            }
        }
    }
`;

export const giftCards = gql`
    query giftCards($vetspireClientId: String!) {
        giftCards(vetspireClientId: $vetspireClientId) {
            _id
            last3
            sequenceNr
            createdAt
            createdBy
        }
    }
`;

export const giftCardWithDetails = gql`
    query giftCardWithDetails(
        $vetspireLocationId: String!
        $vetspireClientId: String!
        $giftCardId: String!
    ) {
        giftCardWithDetails(
            vetspireLocationId: $vetspireLocationId
            vetspireClientId: $vetspireClientId
            giftCardId: $giftCardId
        ) {
            _id
            last3
            sequenceNr
            createdAt
            createdBy
            status
            balance
        }
    }
`;

export const checkGiftCardBalance = gql`
    query checkGiftCardBalance(
        $vetspireLocationId: String!
        $vetspireClientId: String!
        $giftCardNumber: String!
        $pin: String!
    ) {
        checkGiftCardBalance(
            vetspireLocationId: $vetspireLocationId
            vetspireClientId: $vetspireClientId
            giftCardNumber: $giftCardNumber
            pin: $pin
        ) {
            last3
            balance
        }
    }
`;

export const giftCardsFeatureEnabledQuery = gql`
    query giftCardsFeatureEnabled {
        giftCardsFeatureEnabled
    }
`;

export const checkGiftCardBalanceQuery = gql`
    query checkGiftCardBalance(
        $vetspireLocationId: String!
        $vetspireClientId: String!
        $giftCardNumber: String!
        $pin: String!
    ) {
        checkGiftCardBalance(
            vetspireLocationId: $vetspireLocationId
            vetspireClientId: $vetspireClientId
            giftCardNumber: $giftCardNumber
            pin: $pin
        ) {
            last3
            balance
        }
    }
`;
