import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import {
    DraggableProvidedDraggableProps,
    DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';
import { hideButtonTooltip, showButtonTooltip } from 'lib/vetspireActions';
import styles from './NavigationButton.module.scss';

interface NavigationButtonProps {
    titleTag?: string;
    icon: JSX.Element;
    active: boolean;
    expanded: boolean;
    noOpacity?: boolean;
    onClick?: () => void;
    innerRef?: (instance: HTMLDivElement | null) => void;
    draggableProps?: DraggableProvidedDraggableProps;
    dragHandleProps?: DraggableProvidedDragHandleProps;
    style?: React.CSSProperties;
    badge?: React.ReactElement;
}

const NavigationButton: React.FunctionComponent<NavigationButtonProps> = ({
    titleTag = '',
    active,
    noOpacity = false,
    icon,
    onClick,
    expanded,
    innerRef,
    draggableProps,
    dragHandleProps,
    style,
    badge,
}: NavigationButtonProps) => {
    const translate = useTranslate();

    const ref = React.createRef<HTMLDivElement>();
    return (
        <>
            {/* eslint-disable react/jsx-props-no-spreading */}
            <div
                ref={innerRef || ref}
                {...draggableProps}
                {...dragHandleProps}
                style={style}
                onMouseEnter={() => {
                    if (
                        !expanded &&
                        titleTag &&
                        ref?.current &&
                        ref.current.getBoundingClientRect().top
                    ) {
                        showButtonTooltip(
                            ref.current.getBoundingClientRect().top,
                            translate(titleTag).toString(),
                        );
                    }
                }}
                onMouseLeave={() => {
                    if (titleTag && !expanded) {
                        hideButtonTooltip(translate(titleTag).toString());
                    }
                }}
            >
                <button
                    type="button"
                    className={classnames(
                        styles.container,
                        expanded ? styles.expanded : null,
                        {
                            [styles.active]: active,
                            [styles.noOpacity]: noOpacity,
                        },
                        !onClick && styles.noPointerEvents,
                    )}
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        }
                    }}
                >
                    {icon}
                    {titleTag && (
                        <div
                            className={classnames(styles.title, {
                                [styles.active]: active,
                            })}
                        >
                            <span>{titleTag ? translate(titleTag) : ''}</span>
                        </div>
                    )}
                    {badge}
                </button>
            </div>
        </>
    );
};

export default NavigationButton;
