import * as React from 'react';
import {
    FirestoreTelehealthSession,
    SessionStatus,
    TELEHEALTH_SESSIONS_COLLECTION_NAME,
} from '@bondvet/types/telehealth';
import { onSnapshot, orderBy, query, where } from 'firebase/firestore';

import useFirebaseContext from 'hooks/firebase/useFirebaseContext';

export type IdentifiedFirestoreTelehealthSession =
    FirestoreTelehealthSession & {
        id: string;
    };

type UseClientSessionsType = {
    nurseQueue: IdentifiedFirestoreTelehealthSession[] | undefined;
    doctorQueue: IdentifiedFirestoreTelehealthSession[] | undefined;
};

export default function useCaseQueue(): UseClientSessionsType {
    const [nurseQueue, setNurseQueue] =
        React.useState<IdentifiedFirestoreTelehealthSession[]>();
    const [doctorQueue, setDoctorQueue] =
        React.useState<IdentifiedFirestoreTelehealthSession[]>();

    const { getCollection } = useFirebaseContext();

    React.useEffect(() => {
        let unsubscribe = () => {};

        const loadDbSessions = async () => {
            const sessionsCollection = await getCollection(
                TELEHEALTH_SESSIONS_COLLECTION_NAME,
            );

            if (sessionsCollection) {
                const sessionQuery = query(
                    sessionsCollection,
                    where('status', '!=', SessionStatus.finished),
                    orderBy('status'),
                );

                unsubscribe = await onSnapshot(sessionQuery, (snapshot) => {
                    const nurses: IdentifiedFirestoreTelehealthSession[] = [];
                    const doctors: IdentifiedFirestoreTelehealthSession[] = [];

                    const nurseCalls: IdentifiedFirestoreTelehealthSession[] =
                        [];
                    const doctorCalls: IdentifiedFirestoreTelehealthSession[] =
                        [];

                    snapshot.forEach((doc) => {
                        const data =
                            doc.data() as IdentifiedFirestoreTelehealthSession;
                        switch (data.status) {
                            case SessionStatus.nurseQueue:
                            case SessionStatus.nurseJoining:
                                nurses.push({ ...data, id: doc.id });
                                break;

                            case SessionStatus.nurseCall:
                                nurseCalls.push({ ...data, id: doc.id });
                                break;

                            case SessionStatus.veterinarianQueue:
                            case SessionStatus.veterinarianJoining:
                                doctors.push({ ...data, id: doc.id });
                                break;

                            case SessionStatus.veterinarianCall:
                                doctorCalls.push({ ...data, id: doc.id });
                                break;

                            default:
                        }
                    });

                    setNurseQueue(nurses.concat(nurseCalls));
                    setDoctorQueue(doctors.concat(doctorCalls));
                });
            }
        };

        loadDbSessions().then();

        return unsubscribe;
    }, [getCollection]);

    return { nurseQueue, doctorQueue };
}
