import * as React from 'react';
import { Translated } from '@bondvet/web-app-i18n/util';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Legend,
    ResponsiveContainer,
    LabelList,
    Cell,
} from 'recharts';
import styles from './AverageBarCard.module.scss';

interface BarCardProps {
    label: Translated;
    isMoney?: boolean;
    barColor: string;
    precision: number;
    data: {
        value: number;
        label: string;
    }[];
}

const COLORS = ['#10365A', '#295889'];

const AverageBarCard: React.FunctionComponent<BarCardProps> = ({
    label,
    barColor,
    precision,
    data,
    isMoney,
}) => {
    const moneyFormatter = useMoneyFormatter({
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
        style: isMoney ? 'currency' : 'decimal',
    });

    const formattedData = React.useMemo(
        () =>
            data.map((entry, index) => ({
                formattedValue: moneyFormatter.format(entry.value),
                ...entry,
                index,
            })),
        [moneyFormatter, data],
    );
    return (
        <div className={styles.container}>
            <span className={styles.label}>{label}</span>
            <div className={styles.chartContainer}>
                <ResponsiveContainer className={styles.responsiveChart}>
                    <BarChart data={formattedData} margin={{ top: 20 }}>
                        <YAxis hide />
                        <Legend />
                        <Bar
                            dataKey="value"
                            fill={barColor}
                            legendType="none"
                            isAnimationActive={false}
                        >
                            <LabelList
                                dataKey="formattedValue"
                                position="top"
                                className={styles.topValue}
                            />
                            {formattedData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Bar>
                        <XAxis
                            dataKey="index"
                            axisLine={false}
                            tickLine={false}
                            height={40}
                            tick={(props) => (
                                <foreignObject
                                    key={props.payload.value}
                                    className={styles.xAxisLabelWrapper}
                                    x={`calc(${props.x}px - 25%)`}
                                    y={props.y}
                                    width="50%"
                                    height="2rem"
                                >
                                    <div>
                                        <span className={styles.xAxisLabel}>
                                            {formattedData[props.index].label}
                                        </span>
                                    </div>
                                </foreignObject>
                            )}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default AverageBarCard;
