import * as React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import sumBy from 'lodash/sumBy';
import classnames from 'classnames';
import {
    RowGroup,
    TableRowData,
} from 'components/MonthlyPulseReport/hooks/useMonthlyPulseReportData';
import { Translated, TranslateFunction } from '@bondvet/web-app-i18n/util';
import styles from './DetailsTable.module.scss';

interface GroupSetting {
    color: string;
}

const settings: Record<string, GroupSetting> = {
    [RowGroup.inClinic]: {
        color: '#d0cfe2',
    },
    [RowGroup.atHome]: {
        color: '#f4d5c8',
    },
    [RowGroup.misc]: {
        color: '#c6d1b2',
    },
};

interface DataContent {
    rows: TableRowData[];
}

interface DetailsTableProps {
    [RowGroup.inClinic]: TableRowData[] | undefined;
    [RowGroup.atHome]: TableRowData[] | undefined;
    [RowGroup.misc]: TableRowData[] | undefined;
    homeLocation?: string;
    summary: TableRowData[];
    productionRate: number;
    day?: string;
    footer?: Translated;
    textVersion: string;
    reportTranslate: TranslateFunction;
}

const DetailsTable: React.FunctionComponent<DetailsTableProps> = ({
    inClinic,
    atHome,
    misc,
    homeLocation,
    summary,
    productionRate,
    day,
    footer,
    reportTranslate,
}) => {
    const moneyFormatter = useMoneyFormatter({
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const data: Record<string, undefined | DataContent> = {
        [RowGroup.inClinic]: { rows: inClinic || [] },
        [RowGroup.atHome]: { rows: atHome || [] },
        [RowGroup.misc]: { rows: misc || [] },
    };

    const showLocationColumn = !!(
        inClinic?.find(({ locationValue }) => locationValue != null) ||
        atHome?.find(({ locationValue }) => locationValue != null) ||
        misc?.find(({ locationValue }) => locationValue != null)
    );

    const renderRowContent = (
        row: TableRowData,
        groupRowIndex: number,
        overallRowIndex: number,
    ) => {
        const even =
            (sumBy(
                Object.keys(data).filter((entry, i) => i < overallRowIndex),
                (dataLenKey) => data[dataLenKey]?.rows?.length || 0,
            ) +
                groupRowIndex) %
            2;

        return (
            <TableRow
                className={classnames({
                    [styles.oddRow]: !even,
                })}
                key={groupRowIndex}
            >
                <TableCell>{row.name}</TableCell>
                <TableCell align="right" className={styles.grossProductionCell}>
                    {row.grossProduction !== undefined &&
                        moneyFormatter.format(row.grossProduction)}
                </TableCell>
                <TableCell
                    align="right"
                    className={classnames({ [styles.highlightedCell]: !day })}
                >
                    {moneyFormatter.format(row.netProduction)}
                </TableCell>
                {showLocationColumn && (
                    <TableCell align="right" className={styles.highlightedCell}>
                        {row.locationValue !== undefined &&
                            moneyFormatter.format(row.locationValue)}
                    </TableCell>
                )}
            </TableRow>
        );
    };

    const renderRow = (key: string, overallRowIndex: number) => {
        const dataElem = data[key];
        if (!dataElem || dataElem.rows.length === 0) return null;

        return (
            <React.Fragment key={key}>
                <TableRow>
                    <TableCell
                        scope="row"
                        rowSpan={dataElem.rows.length + 1}
                        className={styles.rowGroup}
                    >
                        <div
                            className={styles.cellContainer}
                            style={{
                                borderRightColor: settings[key]?.color,
                            }}
                        >
                            <div className={styles.labelContainer}>
                                <span className={styles.label}>
                                    {reportTranslate(`table.groups.${key}`)}
                                </span>
                                <div
                                    className={styles.underline}
                                    style={{
                                        background: settings[key]?.color,
                                    }}
                                />
                            </div>
                        </div>
                    </TableCell>
                </TableRow>

                {dataElem.rows.map((row, groupRowIndex) =>
                    renderRowContent(row, groupRowIndex, overallRowIndex),
                )}

                <TableRow className={styles.spacingRow}>
                    <TableCell />
                    <TableCell />
                    <TableCell className={styles.grossProductionCell} />
                    <TableCell
                        className={classnames({
                            [styles.highlightedCell]: !day,
                        })}
                    />
                    {showLocationColumn && (
                        <TableCell className={styles.highlightedCell} />
                    )}
                </TableRow>
            </React.Fragment>
        );
    };

    const renderTableSummary = () => {
        const overallIndex =
            (inClinic?.length || 0) +
            (misc?.length || 0) +
            (atHome?.length || 0);
        return (
            <>
                {summary
                    .filter((summaryEntry) => !summaryEntry.hidden)
                    .map((summaryEntry, index) => (
                        <React.Fragment key={summaryEntry.name}>
                            {/* first column placeholder */}
                            <TableRow>
                                <TableCell scope="row" rowSpan={2} />
                            </TableRow>
                            <TableRow
                                className={classnames({
                                    [styles.targetRow]: summaryEntry.targetRow,
                                    [styles.firstSummaryRow]: index === 0,
                                    [styles.oddRow]: !(
                                        (index + overallIndex) %
                                        2
                                    ),
                                })}
                            >
                                <TableCell>{summaryEntry.name}</TableCell>
                                <TableCell
                                    className={styles.grossProductionCell}
                                    align="right"
                                >
                                    {summaryEntry.grossProduction !==
                                        undefined &&
                                        moneyFormatter.format(
                                            summaryEntry.grossProduction,
                                        )}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={classnames({
                                        [styles.highlightedCell]:
                                            !day &&
                                            summaryEntry.name ===
                                                reportTranslate(
                                                    'table.summary.total',
                                                ).toString(),
                                        [styles.positiveNumber]:
                                            summaryEntry.showSign &&
                                            summaryEntry.netProduction > 0,
                                        [styles.negativeNumber]:
                                            summaryEntry.showSign &&
                                            summaryEntry.netProduction < 0,
                                    })}
                                >
                                    {moneyFormatter.format(
                                        summaryEntry.netProduction,
                                    )}
                                </TableCell>
                                {showLocationColumn && (
                                    <TableCell
                                        align="right"
                                        className={classnames({
                                            [styles.highlightedCell]:
                                                summaryEntry.name ===
                                                reportTranslate(
                                                    'table.summary.total',
                                                ).toString(),
                                            [styles.positiveNumber]:
                                                summaryEntry.showSign &&
                                                summaryEntry.locationValue !==
                                                    undefined &&
                                                summaryEntry.locationValue > 0,
                                            [styles.negativeNumber]:
                                                summaryEntry.showSign &&
                                                summaryEntry.locationValue !==
                                                    undefined &&
                                                summaryEntry.locationValue < 0,
                                        })}
                                    >
                                        {summaryEntry.locationValue !==
                                            undefined &&
                                            moneyFormatter.format(
                                                summaryEntry.locationValue,
                                            )}
                                    </TableCell>
                                )}
                            </TableRow>
                            {summaryEntry.note && (
                                <>
                                    {/* first column placeholder */}
                                    <TableRow>
                                        <TableCell scope="row" rowSpan={2} />
                                    </TableRow>
                                    <TableRow
                                        className={classnames(styles.noteRow, {
                                            [styles.oddRow]: !(
                                                (index + overallIndex) %
                                                2
                                            ),
                                        })}
                                    >
                                        <TableCell className={styles.noteCell}>
                                            {summaryEntry.note}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                        </React.Fragment>
                    ))}
            </>
        );
    };

    return (
        <div className={styles.container}>
            <Table className={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '1.5rem' }}></TableCell>
                        <TableCell align="left">
                            {day || (
                                <div className={styles.productionRate}>
                                    {reportTranslate(`table.productionRate`, {
                                        productionRate:
                                            moneyFormatter.format(
                                                productionRate,
                                            ),
                                    })}
                                </div>
                            )}
                        </TableCell>
                        <TableCell
                            className={styles.grossProductionCell}
                            align="right"
                        >
                            {reportTranslate('table.heads.grossProduction')}
                        </TableCell>
                        <TableCell
                            align="right"
                            className={classnames(styles.headerCell, {
                                [styles.highlightedCell]: !day,
                            })}
                        >
                            {reportTranslate(
                                'table.heads.netProduction',
                            ).toString()}
                        </TableCell>
                        {showLocationColumn && (
                            <TableCell
                                align="right"
                                className={classnames(
                                    styles.highlightedCell,
                                    styles.headerCell,
                                )}
                            >
                                {reportTranslate(
                                    'table.heads.locationNetProduction',
                                    { homeLocation },
                                )}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(data).map(renderRow)}
                    {renderTableSummary()}
                </TableBody>
            </Table>
            {footer && (
                <div className={styles.footer}>
                    <span>{footer}</span>
                </div>
            )}
        </div>
    );
};

export default DetailsTable;
