import * as React from 'react';
import moment from 'moment-timezone';
import {
    ImpactScoreV2QueryVariables,
    ImpactScoreV2QueryResult,
} from '@bondvet/types/gainSharing';
import { impactScoreV2 } from 'api/providers/queries';
import useTimezone from 'hooks/useTimezone';
import * as queries from 'api/scheduling/queries';
import {
    ProviderScheduleLocationResult,
    ImpactScoreV2OfProviderQueryResult,
    ImpactScoreV2OfProviderQueryVariables,
} from 'api/scheduling/queries';
import useGainSharingQuery from 'hooks/useGainSharingQuery';

export interface Milestone {
    value?: number;
    label?: string;
    icon?: string | React.ReactNode;
    percent?: number;
    percentRevenueImpact?: string;
    hide?: boolean;
    hit?: boolean;
}

export interface ImpactScore {
    value?: number;
    label?: string;
    percent?: number;
    amountToGo?: string;
    milestones?: Milestone[];
}

export interface ImpactScoresV2 {
    progressBars: {
        visits: ImpactScore;
        revenue: ImpactScore;
    };
    payout?: string;
    monthName?: string;
    locationId?: string | null;
    locationName?: string;
    isFirstMilestoneReached: boolean;
    isLastMilestoneReached: boolean;
    loading: boolean;
    selectedRange: {
        payout: string;
        hasPayout?: boolean;
        payoutDays: number;
        projectedPayment: string;
        payoutPerDay?: {
            _id: string;
            date: string;
            payout: number;
            progress: number;
            formattedPayout: string;
        }[];
    };
}

const VISITS_LABEL = 'visits';
const REVENUE_LABEL = 'revenue';

export default function useImpactScoresV2(
    vetspireProviderId: string,
    from: string,
    to: string,
): ImpactScoresV2 {
    const timezone = useTimezone();

    // get the location of the provider
    const locationQuery = useGainSharingQuery<ProviderScheduleLocationResult>(
        queries.providerScheduleLocation,
        {
            fetchPolicy: 'no-cache',
            variables: {
                vetspireProviderId,
                date: moment.tz(timezone).format('YYYY-MM-DD'),
            },
            skip: !vetspireProviderId,
        },
    );

    // extract the location id
    const locationId = React.useMemo(() => {
        return locationQuery?.data?.providerScheduleLocation?.locationId;
    }, [locationQuery?.data?.providerScheduleLocation?.locationId]);

    // get the impact scores of the current day
    const day = moment.tz(timezone || '');
    const { data: apiData, loading } = useGainSharingQuery<
        ImpactScoreV2QueryResult,
        ImpactScoreV2QueryVariables
    >(impactScoreV2, {
        skip: !locationId || !timezone,
        variables: {
            locationId: locationId || undefined,
            date: day.format('YYYY-MM-DD'),
        },
        fetchPolicy: 'no-cache',
    });

    // get the payout of the provider of the month
    const impactScoreV2OfProvider = useGainSharingQuery<
        ImpactScoreV2OfProviderQueryResult,
        ImpactScoreV2OfProviderQueryVariables
    >(queries.impactScoreV2OfProvider, {
        skip: !vetspireProviderId,
        variables: {
            vetspireProviderId,
            from,
            to,
        },
        fetchPolicy: 'no-cache',
    });

    const formatRevenue = React.useCallback(
        (revenue?: number | null) =>
            `$${Math.round(revenue || 0).toLocaleString('en-US')}`,
        [],
    );

    return React.useMemo(() => {
        const isFirstMilestoneReached =
            !!apiData?.impactScoreV2?.milestone1Reached;
        const isLastMilestoneReached =
            !!apiData?.impactScoreV2?.milestone2Reached;
        const rangePayout =
            impactScoreV2OfProvider.data?.impactScoreV2OfProvider?.payout || 0;
        const startOfYear = moment().startOf('year');
        const endOfYear = moment().endOf('year');

        return {
            loading:
                loading ||
                locationQuery.loading ||
                impactScoreV2OfProvider.loading,
            locationName: apiData?.impactScoreV2?.locationName,
            locationId,
            isFirstMilestoneReached,
            isLastMilestoneReached,
            monthName: day.format('MMMM'),
            payout: formatRevenue(
                (apiData?.impactScoreV2?.payout || 0) /
                    (apiData?.impactScoreV2?.payoutEmployees?.length || 1),
            ),
            progressBars: {
                visits: {
                    value: apiData?.impactScoreV2?.visits,
                    percent: apiData?.impactScoreV2?.visitsPercent,
                    label: apiData?.impactScoreV2?.visits.toString(),
                    amountToGo: (
                        (apiData?.impactScoreV2?.visitsMilestone2 || 0) -
                        (apiData?.impactScoreV2?.visits || 0)
                    ).toString(),
                    milestones: [
                        {
                            icon: '\uD83D\uDD11',
                            value: apiData?.impactScoreV2?.visitsMilestone1,
                            label: apiData?.impactScoreV2?.visitsMilestone1.toString(),
                            percent:
                                apiData?.impactScoreV2?.visitsMilestone1Percent,
                            percentRevenueImpact: `${apiData?.impactScoreV2?.bonusPercentMilestone1}%`,
                            hit:
                                apiData?.impactScoreV2?.milestone1Reached ===
                                VISITS_LABEL,
                            hide:
                                apiData?.impactScoreV2?.milestone1Reached ===
                                REVENUE_LABEL,
                        },
                        {
                            icon: '\uD83D\uDD11',
                            value: apiData?.impactScoreV2?.visitsMilestone2,
                            label: apiData?.impactScoreV2?.visitsMilestone2.toString(),
                            percent:
                                apiData?.impactScoreV2?.visitsMilestone2Percent,
                            percentRevenueImpact: `${apiData?.impactScoreV2?.bonusPercentMilestone2}%`,
                            hit:
                                apiData?.impactScoreV2?.milestone2Reached ===
                                VISITS_LABEL,
                            hide:
                                apiData?.impactScoreV2?.milestone2Reached ===
                                REVENUE_LABEL,
                        },
                    ],
                },
                revenue: {
                    value: apiData?.impactScoreV2?.revenuePerDoctor,
                    label: formatRevenue(
                        apiData?.impactScoreV2?.revenuePerDoctor,
                    ),
                    percent: apiData?.impactScoreV2?.revenuePerDoctorPercent,
                    amountToGo: formatRevenue(
                        (apiData?.impactScoreV2?.revenuePerDoctorMilestone2 ||
                            0) -
                            (apiData?.impactScoreV2?.revenuePerDoctor || 0),
                    ),
                    milestones: [
                        {
                            icon: '\uD83D\uDD11',
                            value: apiData?.impactScoreV2
                                ?.revenuePerDoctorMilestone1,
                            label: formatRevenue(
                                apiData?.impactScoreV2
                                    ?.revenuePerDoctorMilestone1,
                            ),
                            percent:
                                apiData?.impactScoreV2
                                    ?.revenuePerDoctorMilestone1Percent,
                            percentRevenueImpact: `${apiData?.impactScoreV2?.bonusPercentMilestone1}%`,
                            hit:
                                apiData?.impactScoreV2?.milestone1Reached ===
                                REVENUE_LABEL,
                            hide:
                                apiData?.impactScoreV2?.milestone1Reached ===
                                VISITS_LABEL,
                        },
                        {
                            icon: '\uD83D\uDD11',
                            value: apiData?.impactScoreV2
                                ?.revenuePerDoctorMilestone2,
                            label: formatRevenue(
                                apiData?.impactScoreV2
                                    ?.revenuePerDoctorMilestone2,
                            ),
                            percent:
                                apiData?.impactScoreV2
                                    ?.revenuePerDoctorMilestone2Percent,
                            percentRevenueImpact: `${apiData?.impactScoreV2?.bonusPercentMilestone2}%`,
                            hit:
                                apiData?.impactScoreV2?.milestone2Reached ===
                                REVENUE_LABEL,
                            hide:
                                apiData?.impactScoreV2?.milestone2Reached ===
                                VISITS_LABEL,
                        },
                    ],
                },
            },
            selectedRange: {
                payout: formatRevenue(rangePayout),
                hasPayout: !!rangePayout,
                payoutDays:
                    impactScoreV2OfProvider.data?.impactScoreV2OfProvider?.payoutPerDay?.filter(
                        (payoutPerDay) => payoutPerDay?.payout > 0,
                    )?.length || 0,
                payoutPerDay:
                    impactScoreV2OfProvider.data?.impactScoreV2OfProvider?.payoutPerDay?.map(
                        (payoutPerDay) => ({
                            ...payoutPerDay,
                            formattedPayout: formatRevenue(payoutPerDay.payout),
                        }),
                    ),
                projectedPayment: formatRevenue(
                    (rangePayout * endOfYear.diff(startOfYear, 'day')) /
                        moment().diff(startOfYear, 'day'),
                ),
            },
        };
    }, [
        apiData?.impactScoreV2?.milestone1Reached,
        apiData?.impactScoreV2?.milestone2Reached,
        apiData?.impactScoreV2?.locationName,
        apiData?.impactScoreV2?.payout,
        apiData?.impactScoreV2?.payoutEmployees?.length,
        apiData?.impactScoreV2?.visits,
        apiData?.impactScoreV2?.visitsPercent,
        apiData?.impactScoreV2?.visitsMilestone2,
        apiData?.impactScoreV2?.visitsMilestone1,
        apiData?.impactScoreV2?.visitsMilestone1Percent,
        apiData?.impactScoreV2?.bonusPercentMilestone1,
        apiData?.impactScoreV2?.visitsMilestone2Percent,
        apiData?.impactScoreV2?.bonusPercentMilestone2,
        apiData?.impactScoreV2?.revenuePerDoctor,
        apiData?.impactScoreV2?.revenuePerDoctorPercent,
        apiData?.impactScoreV2?.revenuePerDoctorMilestone2,
        apiData?.impactScoreV2?.revenuePerDoctorMilestone1,
        apiData?.impactScoreV2?.revenuePerDoctorMilestone1Percent,
        apiData?.impactScoreV2?.revenuePerDoctorMilestone2Percent,
        impactScoreV2OfProvider.data?.impactScoreV2OfProvider?.payout,
        impactScoreV2OfProvider.data?.impactScoreV2OfProvider?.payoutPerDay,
        impactScoreV2OfProvider.loading,
        loading,
        locationQuery.loading,
        locationId,
        day,
        formatRevenue,
    ]);
}
