import * as React from 'react';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { surgeryFormsQuery, SurgeryFormsQueryResult } from 'api/bond/queries';
import { SurgeryForm } from '@bondvet/types/surgeryForms';

export type SurgeryFormsQuery = {
    loading: boolean;
    error?: ApolloError;
    surgeryForms: ReadonlyArray<SurgeryForm>;
};

export default function useSurgeryForms(): SurgeryFormsQuery {
    const { data, loading, error } = useQuery<SurgeryFormsQueryResult>(
        surgeryFormsQuery,
        {
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );
    const [surgeryForms, setSurgeryForms] = React.useState<
        ReadonlyArray<SurgeryForm>
    >([]);

    React.useEffect(() => {
        if (data?.surgeryForms) {
            setSurgeryForms(
                data.surgeryForms
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name)),
            );
        }
    }, [data]);

    return {
        loading,
        error,
        surgeryForms,
    };
}
