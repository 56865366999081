import useLazyBondQuery from 'hooks/useLazyBondQuery';
import useVetspireSettings from 'hooks/useVetspireSettings';
import * as React from 'react';
import { gql } from '@apollo/client';
import useRequestInterceptor, {
    type InterceptorPayload,
    type InterceptorResponse,
} from './useRequestInterceptor';

const query = gql`
    query isSukiProvider($providerId: ID!) {
        isSukiProvider(providerId: $providerId)
    }
`;

interface IsSukiProviderQuery {
    isSukiProvider: boolean;
}

interface IsSukiProviderVariables {
    providerId: string;
}

const PATHS = ['/schedule', /^\/clients\/\d+\/patients\/\d+\/chart/];
const OPERATION_NAME = 'newEncounter';

type EncounterInput = {
    appointmentId: string;
    locationId: string;
    providerId: string;
    start: string;
};

type NewEncounterVariables = {
    input: EncounterInput;
    typeId: string | null;
};

export default function useSuki(): void {
    const { sukiEncounterTypeId } = useVetspireSettings();
    const [checkProvider] = useLazyBondQuery<
        IsSukiProviderQuery,
        IsSukiProviderVariables
    >(query, {
        fetchPolicy: 'cache-first',
    });

    const onRequest = React.useCallback(
        async ({
            variables,
        }: InterceptorPayload<NewEncounterVariables>): Promise<
            InterceptorResponse<NewEncounterVariables>
        > => {
            let newVariables: NewEncounterVariables | undefined;
            const { providerId } = variables.input;

            if (providerId && sukiEncounterTypeId) {
                try {
                    const { data } = await checkProvider({
                        variables: { providerId },
                    });

                    if (data?.isSukiProvider) {
                        newVariables = {
                            ...variables,
                            typeId: sukiEncounterTypeId,
                        };
                    }
                } catch (error) {
                    console.warn(
                        'error checking for Suki Pilot for provider #%s: %s\n%s',
                        providerId,
                        (error as Error).message,
                        (error as Error).stack,
                    );
                }
            }

            return {
                runOriginalRequest: true,
                newVariables,
            };
        },
        [checkProvider, sukiEncounterTypeId],
    );

    useRequestInterceptor<NewEncounterVariables>(
        OPERATION_NAME,
        onRequest,
        PATHS,
        !sukiEncounterTypeId,
    );
}
