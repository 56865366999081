import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { Patient } from '@bondvet/types/telehealth';

import {
    AppointmentType,
    TelehealthInput,
    patientsQuery,
    PatientsQueryResult,
    PatientsQueryVariables,
    TelehealthAppointmentTypesQueryResult,
    telhealthAppointmentTypesQuery,
} from 'api/telehealth/queries';
import useCreditCards from 'components/CreditCards/hooks/useCreditCards';
import Dropdown from 'components/Form/Dropdown';
import TextArea from 'components/Form/TextArea';
import useTranslate from 'hooks/useTranslate';
import useClientDetails from 'hooks/useClientDetails';
import useTelehealthQuery from 'hooks/useTelehealthQuery';

import styles from './NewVisitForm.module.scss';
import PaymentMethod from './PaymentMethod';

interface NewVisitFormProps {
    clientId: string;
    patientId?: string;
}

function NewVisitForm({
    clientId,
    patientId,
}: NewVisitFormProps): React.ReactElement {
    const translate = useTranslate();

    const [patient, setPatient] = React.useState<Patient>();
    const [creditCardId, setCreditCardId] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [isNewCard, setIsNewCard] = React.useState(true);
    const [telehealthInput, setTelehealthInput] =
        React.useState<TelehealthInput>();
    const [appointmentReason, setAppointmentReason] =
        React.useState<AppointmentType>();

    const client = useClientDetails(clientId);
    const { creditCards, isLoading: isLoadingCreditCards } =
        useCreditCards(clientId);
    const { data: patientsData } = useTelehealthQuery<
        PatientsQueryResult,
        PatientsQueryVariables
    >(patientsQuery, {
        variables: {
            email: client?.email || '',
        },
    });

    const { data: reasonData } =
        useTelehealthQuery<TelehealthAppointmentTypesQueryResult>(
            telhealthAppointmentTypesQuery,
        );

    const patientsOptions = React.useMemo(() => {
        return patientsData?.getPatients.map((patientData) => ({
            label: patientData.name,
            id: patientData._id,
        }));
    }, [patientsData]);

    React.useEffect(() => {
        // pre-select the current patient, if we're on
        // a patient page
        if (patientId && patientsData?.getPatients?.length) {
            const initialPatient = patientsData.getPatients.find(
                (dbPatient) => dbPatient._vetspire?.id === patientId,
            );

            if (initialPatient) {
                setPatient(initialPatient);
            }
        }
    }, [patientId, patientsData]);

    const reasonOptions = React.useMemo(() => {
        return reasonData?.appointmentTypes.map((reason) => ({
            label: reason.name,
            id: reason._id,
        }));
    }, [reasonData]);

    const missingInfo = React.useMemo(
        () => !client?.email || !client.preferredPhoneNumber?.value,
        [client],
    );

    const valid = React.useMemo(
        () => !!appointmentReason && !!notes && !!patient,
        [appointmentReason, notes, patient],
    );

    React.useEffect(() => {
        if (creditCards.length === 0) {
            setIsNewCard(true);
        } else {
            setIsNewCard(false);
            setCreditCardId(
                creditCards.find((card) => card.isDefault)?.id || '',
            );
        }
    }, [creditCards]);

    React.useEffect(() => {
        if (
            client &&
            appointmentReason &&
            notes &&
            patient &&
            (isNewCard || creditCardId)
        ) {
            setTelehealthInput({
                email: client.email || '',
                phone: client.preferredPhoneNumber?.value,
                firstName: client.givenName || '',
                lastName: client.familyName || '',
                reasonId: appointmentReason._id,
                patientId: patient._id,
                pet: patient.name,
                species: patient.species || '',
                sourceId: creditCardId,
                notes,
            });
        }
    }, [client, patient, notes, appointmentReason, creditCardId, isNewCard]);

    const handleChangeReason = (value: string) => {
        const selectedReason = reasonData?.appointmentTypes.find(
            (data) => data._id === value,
        );
        setAppointmentReason(selectedReason);
    };

    const handleChangePatient = (value: string) => {
        const selectedPatient = patientsData?.getPatients.find(
            (patientData) => patientData._id === value,
        );
        setPatient(selectedPatient);
    };

    const handleChangeCreditCard = (value: string) => {
        setCreditCardId(value);
    };

    const handleChangeNotes = (value: string) => {
        setNotes(value);
    };

    if (!client) return <></>;

    return (
        <span className={styles.flyoutContent}>
            <form className={styles.root} id="new-visit-form">
                {missingInfo && (
                    <fieldset className={styles.container}>
                        <p className={styles.error}>
                            {translate(
                                'vetspireExtension.telehealth.addClientToQueue.missingInfo.error',
                            ).toString()}
                            <br />
                            {translate(
                                'vetspireExtension.telehealth.addClientToQueue.missingInfo.action',
                            ).toString()}
                        </p>
                    </fieldset>
                )}
                {reasonData && reasonOptions && (
                    <Dropdown
                        disabled={missingInfo}
                        onChange={handleChangeReason}
                        id="patient-dropdown"
                        label={translate(
                            'vetspireExtension.telehealth.addClientToQueue.formFields.reason.label',
                        ).toString()}
                        placeholder={translate(
                            'vetspireExtension.telehealth.addClientToQueue.formFields.reason.placeholder',
                        ).toString()}
                        value={appointmentReason?._id}
                        required
                        options={reasonOptions}
                    />
                )}
                {patientsData && patientsOptions && (
                    <Dropdown
                        disabled={missingInfo}
                        onChange={handleChangePatient}
                        id="patient-dropdown"
                        label={translate(
                            'vetspireExtension.telehealth.addClientToQueue.formFields.patient.label',
                        )}
                        placeholder={translate(
                            'vetspireExtension.telehealth.addClientToQueue.formFields.patient.placeholder',
                        ).toString()}
                        value={patient?._id}
                        required
                        options={patientsOptions}
                    />
                )}
                <TextArea
                    disabled={missingInfo}
                    id="notes"
                    label={translate(
                        'vetspireExtension.telehealth.addClientToQueue.formFields.notes.label',
                    )}
                    value={notes}
                    onChange={handleChangeNotes}
                    placeholder={translate(
                        'vetspireExtension.telehealth.addClientToQueue.formFields.notes.placeholder',
                    ).toString()}
                />
                <fieldset className={styles.container}>
                    {isLoadingCreditCards ? (
                        <CircularProgress />
                    ) : (
                        <PaymentMethod
                            handleChangeCreditCard={handleChangeCreditCard}
                            isNewCard={isNewCard}
                            currentCardId={creditCardId}
                            creditCards={creditCards}
                            telehealthInput={telehealthInput}
                            formValid={valid}
                            clientId={clientId}
                        />
                    )}
                </fieldset>
            </form>
        </span>
    );
}

export default NewVisitForm;
