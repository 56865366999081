import * as React from 'react';
import { Role } from '@bondvet/roles';
import { getBondRoles } from 'lib/bondAuth';

export default function useBondRoles(): Role[] {
    const [bondRoles, setBondRoles] = React.useState<Role[]>([]);

    React.useEffect(() => {
        getBondRoles().then((roles) => setBondRoles(roles));
    }, []);

    return bondRoles;
}
