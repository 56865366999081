import { DocumentNode, gql } from '@apollo/client';

export interface Category {
    _id: string;
    name: string;
    color: string;
}

export interface CategoriesQueryResult {
    infoPdfCategories: ReadonlyArray<Category>;
}

export const categoriesQuery = gql`
    query {
        infoPdfCategories {
            _id
            name
            color
        }
    }
`;

export interface InfoPdf {
    _id: string;
    name: string;
    categoryId: string;
    category?: {
        _id: string;
        name: string;
        color: string;
    };
}

export interface PopularLink {
    _id: string;
    name: string;
    url: string;
    index: number;
}

export interface InfoPdfsQueryResult {
    infoPdfs: ReadonlyArray<InfoPdf>;
    popularLinks: ReadonlyArray<PopularLink>;
}

export const infoPdfsQuery = gql`
    query {
        infoPdfs {
            _id
            name
            categoryId
        }
        popularLinks {
            _id
            name
            url
            index
        }
    }
`;

export interface InfoPdfCategory {
    _id: string;
    name: string;
}

export interface InfoPdfBase64QueryResult {
    infoPdfBase64: string;
}

export const infoPdfBase64 = gql`
    query infoPdfBase64($id: ID!, $clientId: ID, $petId: ID) {
        infoPdfBase64(id: $id, clientId: $clientId, petId: $petId)
    }
`;

export function createInfoPdfsBase64Query(
    pdfIds: ReadonlyArray<string>,
): DocumentNode {
    if (pdfIds.length > 0) {
        const aggregatedQueries = pdfIds.reduce(
            (query, pdfId) =>
                `${query}
                pdf_${pdfId}: infoPdfBase64(id: "${pdfId}", clientId: $clientId, petId: $petId)`,
            '',
        );

        return gql`query multipleInfoPdfBase64($clientId: ID, $petId: ID) {${aggregatedQueries}}`;
    }

    // just return any query
    return infoPdfsQuery;
}
