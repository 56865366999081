import * as React from 'react';
import {
    ProactiveMessagingVariable,
    ProactiveMessagingTemplate,
    TemplateVariableValue,
} from '@bondvet/types/textMessaging';

import useTextMessagingQuery from 'hooks/useTextMessagingQuery';
import { ClientAppointment, Patient } from 'api/clients/queries';
import { Location } from 'api/locations/queries';

import useDebouncedState from 'hooks/useDebouncedState';
import useLocationId from 'hooks/useLocationId';
import useViewerSettings from 'hooks/useViewerSettings';
import {
    buildMessagePreviewQuery,
    BuildMessagePreviewQueryResult,
    BuildMessagePreviewQueryVariables,
    templateVariablesQuery,
    TemplateVariablesQueryVariables,
    TemplateVariablesQueryResult,
    ClientWithPatients,
} from '../../api';

export type FormState = {
    selectedPatient?: Partial<Patient> | null;
    selectedLocation?: Partial<Location> | null;
    selectedAppointment?: Partial<ClientAppointment> | null;
};

type ValidationResult = { [key: string]: string | undefined };

type EntityType = 'patient' | 'client' | 'appointment' | 'location';
type VariablesByEntities = {
    [key in EntityType]: ReadonlyArray<ProactiveMessagingVariable>;
};

export type OnSubmitParams = {
    freeFormVariableValues: ReadonlyArray<TemplateVariableValue>;
    vetspireVariableOverrideValues: ReadonlyArray<TemplateVariableValue>;
    patientId?: string;
    appointmentId?: string;
    imageBase64?: string;
};

type UseMessageVariableFormProps = {
    client: ClientWithPatients;
    selectedTemplate: ProactiveMessagingTemplate | null;
    onSubmit: (params: OnSubmitParams) => void;
};

export default function useMessageVariableForm({
    client,
    selectedTemplate,
    onSubmit,
}: UseMessageVariableFormProps) {
    const viewerSettings = useViewerSettings();
    const defaultLocationId = useLocationId();
    const [attachment, setAttachment] = React.useState<string | null>(null);
    const formRefs = React.useRef<{
        [key: string]: HTMLInputElement | null;
    }>({});

    const [messageBody, setMessageBody] = React.useState<{
        body1?: string;
        body2?: string;
    }>({});
    const [errors, setErrors] = React.useState<{
        vetspire?: ValidationResult;
        freeform?: ValidationResult;
        appointmentId?: string | null;
        attachment?: string | null;
    }>({});

    const [
        freeFormVariableValues,
        debouncedFreeFormVariableValues,
        setTemplateVariableValues,
    ] = useDebouncedState<ReadonlyArray<TemplateVariableValue>>([], 750);

    const [
        vetspireVariableOverrideValues,
        debouncedVetspireVariableOverrideValues,
        setVetspireValueOverrideValues,
    ] = useDebouncedState<ReadonlyArray<TemplateVariableValue>>([], 1000);

    const [
        { selectedPatient, selectedLocation, selectedAppointment },
        setFormState,
    ] = React.useState<FormState>({
        selectedLocation: {
            id: defaultLocationId,
        },
    });

    const updateFormState = React.useCallback(
        (newValue: Partial<FormState>) => {
            setFormState({
                selectedPatient,
                selectedLocation,
                selectedAppointment,
                ...newValue,
            });
        },
        [selectedAppointment, selectedLocation, selectedPatient],
    );

    React.useEffect(() => {
        const activePatients = (client.pets || []).filter(
            (p) => !p.inactive && !p.deceased,
        );
        if (activePatients.length === 1) {
            updateFormState({ selectedPatient: activePatients[0] });
        }
    }, [client.pets, updateFormState]);

    const { loading: previewLoading } = useTextMessagingQuery<
        BuildMessagePreviewQueryResult,
        BuildMessagePreviewQueryVariables
    >(buildMessagePreviewQuery, {
        fetchPolicy: 'no-cache',
        skip: !selectedTemplate,
        variables: {
            input: {
                templateId: selectedTemplate?._id || '',
                useExampleValues: false,
                freeFormVariableValues: debouncedFreeFormVariableValues,
                vetspireVariableOverrideValues:
                    debouncedVetspireVariableOverrideValues,
                clientId: client.id,
                patientId: selectedPatient?.id,
                locationId: selectedLocation?.id,
                appointmentId: selectedAppointment?.id,
            },
        },
        onCompleted: (messagePreviewData) => {
            if (messagePreviewData?.buildProactiveMessageBody?.body1) {
                setMessageBody({
                    body1: messagePreviewData.buildProactiveMessageBody.body1,
                    body2: messagePreviewData.buildProactiveMessageBody.body2,
                });
            }
        },
    });

    const { data: templateVariables, loading: templateVariablesLoading } =
        useTextMessagingQuery<
            TemplateVariablesQueryResult,
            TemplateVariablesQueryVariables
        >(templateVariablesQuery, {
            fetchPolicy: 'no-cache',
            skip: !selectedTemplate,
            variables: {
                templateId: selectedTemplate?._id || '',
            },
        });

    const freeFormTemplateVariables = React.useMemo(
        () =>
            (templateVariables?.templateVariables || []).filter(
                (v) => v.type === 'freeform',
            ),
        [templateVariables],
    );

    const vetspireTemplateVariables = React.useMemo(
        () =>
            (templateVariables?.templateVariables || []).filter(
                (v) => v.type === 'vetspire',
            ),
        [templateVariables],
    );

    const vetspireTemplateVariablesByEntity: VariablesByEntities =
        React.useMemo(
            () =>
                vetspireTemplateVariables.reduce<VariablesByEntities>(
                    (acc, variable) => {
                        if (!variable.vetspireEntity) {
                            return acc;
                        }

                        return {
                            ...acc,
                            [variable.vetspireEntity]: [
                                ...(acc[variable.vetspireEntity] ?? []),
                                variable,
                            ],
                        };
                    },
                    {
                        patient: [],
                        appointment: [],
                        client: [],
                        location: [],
                    },
                ),
            [vetspireTemplateVariables],
        );

    const updateFreeformVariableValues = React.useCallback(
        async (identifier: string, value: string) => {
            setErrors(({ vetspire, freeform }) => ({
                vetspire,
                freeform: {
                    ...(freeform ?? {}),
                    [identifier]: undefined,
                },
            }));
            setTemplateVariableValues((oldValues) => {
                const newVariableValues: TemplateVariableValue[] = [
                    ...oldValues,
                ];
                const updatedValue = {
                    identifier,
                    value,
                };
                const existingValue = oldValues.find(
                    (v) => v.identifier === identifier,
                );

                if (existingValue) {
                    const index = oldValues.indexOf(existingValue);
                    newVariableValues[index] = updatedValue;
                } else {
                    newVariableValues.push(updatedValue);
                }

                return newVariableValues;
            });
        },
        [setTemplateVariableValues],
    );

    React.useEffect(() => {
        const teamMemberVariable = freeFormTemplateVariables.find(
            ({ identifier }) => identifier === 'team_member_name',
        );
        if (teamMemberVariable) {
            updateFreeformVariableValues(
                teamMemberVariable.identifier,
                viewerSettings.viewer?.givenName ?? '',
            ).then();
        }
    }, [
        freeFormTemplateVariables,
        updateFreeformVariableValues,
        viewerSettings?.viewer?.givenName,
    ]);

    const updateOverrideVariableValues = React.useCallback(
        (identifier: string, value: string, updateImmediately?: boolean) => {
            setErrors(({ vetspire, freeform }) => {
                const newErrors = {
                    freeform,
                    vetspire: {
                        ...(vetspire ?? {}),
                    },
                };

                if (newErrors.vetspire && newErrors.vetspire[identifier]) {
                    delete newErrors.vetspire[identifier];
                }

                return newErrors;
            });

            setVetspireValueOverrideValues((prevState) => {
                const newVariableValues: TemplateVariableValue[] = [
                    ...prevState,
                ];
                const updatedValue = {
                    identifier,
                    value,
                };
                const existingValue = prevState.find(
                    (v) => v.identifier === identifier,
                );

                if (existingValue) {
                    const index = prevState.indexOf(existingValue);
                    newVariableValues[index] = updatedValue;
                } else {
                    newVariableValues.push(updatedValue);
                }
                return newVariableValues;
            }, updateImmediately);
        },
        [setVetspireValueOverrideValues],
    );

    const removeOverrideVariableValues = React.useCallback(
        async (entity: EntityType) => {
            const variablesUsingEntity = (
                templateVariables?.templateVariables || []
            ).filter((v) => v.vetspireEntity === entity);

            const newVariableValues = [...vetspireVariableOverrideValues];
            for (const variable of variablesUsingEntity) {
                const existingValue = vetspireVariableOverrideValues.find(
                    (override) => override.identifier === variable.identifier,
                );

                if (existingValue) {
                    const index =
                        vetspireVariableOverrideValues.indexOf(existingValue);

                    newVariableValues.splice(index, 1);
                }
            }

            await setVetspireValueOverrideValues(newVariableValues);
        },
        [
            templateVariables,
            setVetspireValueOverrideValues,
            vetspireVariableOverrideValues,
        ],
    );

    const handleSubmit = React.useCallback(() => {
        if (!previewLoading && messageBody?.body1) {
            let appointmentValidation = null;
            if (
                vetspireTemplateVariablesByEntity.appointment.length !== 0 &&
                !selectedAppointment?.id
            ) {
                appointmentValidation = 'Required';
            }

            const validateEntity = (
                entity: ReadonlyArray<ProactiveMessagingVariable>,
            ) => {
                return entity.reduce<ValidationResult>((acc, variable) => {
                    const value = vetspireVariableOverrideValues.find(
                        (v) => v.identifier === variable.identifier,
                    )?.value;

                    if (!value) {
                        return {
                            ...acc,
                            [variable.identifier]: 'Required',
                        } as ValidationResult;
                    }

                    if (value.length > variable.maxCharacters) {
                        return {
                            ...acc,
                            [variable.identifier]: 'Above character limit',
                        } as ValidationResult;
                    }
                    return acc;
                }, {});
            };

            const clientValidation = validateEntity(
                vetspireTemplateVariablesByEntity.client,
            );
            const patientValidation = validateEntity(
                vetspireTemplateVariablesByEntity.patient,
            );
            const locationValidation = validateEntity(
                vetspireTemplateVariablesByEntity.location,
            );

            const freeformValidation =
                freeFormTemplateVariables.reduce<ValidationResult>(
                    (acc, variable) => {
                        const value = freeFormVariableValues.find(
                            (variableValue) =>
                                variableValue.identifier ===
                                variable.identifier,
                        )?.value;

                        if (!value) {
                            return {
                                ...acc,
                                [variable.identifier]: 'Required',
                            } as ValidationResult;
                        }

                        if (value.length > variable.maxCharacters) {
                            return {
                                ...acc,
                                [variable.identifier]: 'Above character limit',
                            } as ValidationResult;
                        }
                        return acc;
                    },
                    {},
                );

            let attachmentValidation = null;
            if (selectedTemplate?.requiresImage && !attachment) {
                attachmentValidation = 'Required';
            }

            if (
                Object.keys(clientValidation).length > 0 ||
                Object.keys(patientValidation).length > 0 ||
                Object.keys(locationValidation).length > 0 ||
                Object.keys(freeformValidation).length > 0 ||
                appointmentValidation !== null ||
                attachmentValidation !== null
            ) {
                setErrors({
                    vetspire: {
                        ...clientValidation,
                        ...patientValidation,
                        ...locationValidation,
                    },
                    freeform: freeformValidation,
                    appointmentId: appointmentValidation,
                    attachment: attachmentValidation,
                });

                if (Object.keys(locationValidation).length > 0) {
                    const [firstError] = Object.keys(locationValidation);
                    formRefs.current?.[firstError]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                } else if (Object.keys(clientValidation).length > 0) {
                    const [firstError] = Object.keys(clientValidation);
                    formRefs.current?.[firstError]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                } else if (appointmentValidation) {
                    formRefs.current?.appointment?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                } else if (Object.keys(patientValidation).length > 0) {
                    const [firstError] = Object.keys(patientValidation);
                    formRefs.current?.[firstError]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                } else if (Object.keys(freeformValidation).length > 0) {
                    const [firstError] = Object.keys(freeformValidation);
                    formRefs.current?.[firstError]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }

                return;
            }

            onSubmit({
                freeFormVariableValues,
                vetspireVariableOverrideValues,
                patientId: selectedPatient?.id,
                appointmentId: selectedAppointment?.id,
                imageBase64: attachment ?? undefined,
            });
        }
    }, [
        attachment,
        freeFormTemplateVariables,
        freeFormVariableValues,
        messageBody?.body1,
        onSubmit,
        previewLoading,
        selectedAppointment?.id,
        selectedPatient?.id,
        selectedTemplate?.requiresImage,
        vetspireTemplateVariablesByEntity.appointment.length,
        vetspireTemplateVariablesByEntity.client,
        vetspireTemplateVariablesByEntity.location,
        vetspireTemplateVariablesByEntity.patient,
        vetspireVariableOverrideValues,
    ]);

    return {
        formRefs,
        messageBody,
        errors,
        freeFormVariableValues,
        vetspireVariableOverrideValues,
        selectedPatient,
        selectedLocation,
        selectedAppointment,
        updateFormState,
        previewLoading,
        templateVariablesLoading,
        freeFormTemplateVariables,
        vetspireTemplateVariablesByEntity,
        updateFreeformVariableValues,
        updateOverrideVariableValues,
        removeOverrideVariableValues,
        handleSubmit,
        attachment,
        setAttachment,
    };
}
