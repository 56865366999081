import * as React from 'react';
import { gql, QueryLazyOptions } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import {
    StaffingShiftStatus,
    StaffingShiftStatusesQueryResult,
    StaffingShiftStatusesQueryArguments,
} from '@bondvet/types/scheduling/staffing';
import useLazySchedulingQuery from 'hooks/useLazySchedulingQuery';

export const staffingShiftStatusesQuery = gql`
    query staffingShiftStatuses(
        $vetspireLocationIds: [ID]!
        $fromDate: DateString!
        $toDate: DateString!
    ) {
        staffingShiftStatuses(
            vetspireLocationIds: $vetspireLocationIds
            fromDate: $fromDate
            toDate: $toDate
        ) {
            locationId
            day
            staffingRole
            ignore
        }
    }
`;

interface ExtractedStaffingShiftStatusesData {
    // locationId
    [key: string]: {
        // day
        [key: string]: {
            // staffingRole
            [key: string]: StaffingShiftStatus;
        };
    };
}

interface ExtractedStaffingShiftStatusesQuery {
    run: (
        options?:
            | QueryLazyOptions<StaffingShiftStatusesQueryArguments>
            | undefined,
    ) => void;
    loading: boolean;
    error: ApolloError | undefined;
    data: ExtractedStaffingShiftStatusesData | null;
}

export default function useExtractedUKGShiftStatusesQuery(): ExtractedStaffingShiftStatusesQuery {
    const [runStaffingShiftStatusesQuery, staffingShiftStatusesQueryResult] =
        useLazySchedulingQuery<
            StaffingShiftStatusesQueryResult,
            StaffingShiftStatusesQueryArguments
        >(staffingShiftStatusesQuery, {
            fetchPolicy: 'no-cache',
        });

    const data =
        React.useMemo<ExtractedStaffingShiftStatusesData | null>(() => {
            if (!staffingShiftStatusesQueryResult.data?.staffingShiftStatuses) {
                return null;
            }

            const newData: ExtractedStaffingShiftStatusesData = {};

            staffingShiftStatusesQueryResult.data.staffingShiftStatuses.forEach(
                (row) => {
                    if (!newData[row.vetspireLocationId]) {
                        newData[row.vetspireLocationId] = {};
                    }

                    if (!newData[row.vetspireLocationId][row.day]) {
                        newData[row.vetspireLocationId][row.day] = {};
                    }

                    newData[row.vetspireLocationId][row.day][row.staffingRole] =
                        row;
                },
            );

            return newData;
        }, [staffingShiftStatusesQueryResult]);

    return React.useMemo(() => {
        return {
            loading: staffingShiftStatusesQueryResult.loading,
            error: staffingShiftStatusesQueryResult.error,
            run: runStaffingShiftStatusesQuery,
            data,
        };
    }, [staffingShiftStatusesQueryResult, runStaffingShiftStatusesQuery, data]);
}
