import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import { vetspireContext } from 'context/VetspireContext';
import { ExternalReferralReason } from '@bondvet/types/externalReferrals';
import SearchInput from 'components/SearchInput';
import GeneralError from 'components/GeneralError/GeneralError';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import {
    actionsQuery,
    hospitalsQuery,
    HospitalsQueryResult,
    reasonsQuery,
    ReasonsQueryResult,
} from 'api/externalReferrals/queries';
import styles from './ExternalReferrals.module.scss';
import HospitalOverview from './components/HospitalOverview';
import ReasonsFilter from './components/ReasonsFilter/ReasonsFilter';
import useClientDetails from '../../hooks/useClientDetails';

const emailPlaceholder = (text: string) => {
    return `
        <div style="min-width: 320px;
            border-spacing: 0;
            border-collapse: collapse;
            background-color: #f8f1e9;
            width: 100%;
            height: 97vh;
            display: flex;
            align-items: center;
            justify-content: center;">
                <h3>${text}</h3>
        </div>`;
};

const ExternalReferrals: React.FunctionComponent = () => {
    const translate = useTranslate();

    const vetspire = React.useContext(vetspireContext);
    const [searchValue, setSearchValue] = React.useState<string>('');
    const [selected, setSelected] = React.useState<number | null>(null);
    const [selectedReasons, setSelectedReasons] = React.useState<
        ExternalReferralReason[]
    >([]);

    React.useEffect(() => {
        setSelected(null);
    }, [selectedReasons, searchValue]);

    const [clientId] = React.useMemo((): string[] => {
        const found = vetspire.pathName.match(
            /^\/clients\/\d+(\/patients\/\d+)?/,
        );

        if (found) {
            const splittedUrl = vetspire.pathName.split('/');
            return [splittedUrl[2], splittedUrl[4]];
        }
        return [];
    }, [vetspire.pathName]);

    const client = useClientDetails(clientId);

    const reasonsQueryResult = useQuery<ReasonsQueryResult>(reasonsQuery, {
        fetchPolicy: 'network-only',
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    const hospitalsQueryResult = useQuery<HospitalsQueryResult>(
        hospitalsQuery,
        {
            fetchPolicy: 'network-only',
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );

    const emailsQueryResult = useQuery(actionsQuery, {
        fetchPolicy: 'network-only',
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    const emailBody: string =
        emailsQueryResult?.data?.customerIoEmailActions?.[0]?.body;
    let srcDoc = emailPlaceholder('Select a hospital for the email preview');

    if (reasonsQueryResult.error) {
        return <GeneralError message={reasonsQueryResult.error.message} />;
    }

    if (hospitalsQueryResult.error) {
        return <GeneralError message={hospitalsQueryResult.error.message} />;
    }

    let hospitals = hospitalsQueryResult.data?.externalReferralHospitals;
    if (hospitals && searchValue) {
        hospitals = hospitals.filter(
            (hospital) =>
                hospital.name
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) >= 0,
        );
    }
    if (hospitals && selectedReasons.length !== 0) {
        // filter out every hospital that has at least one of the selected reasons specified
        hospitals = hospitals.filter((hospital) =>
            hospital.reasons.some((reason) =>
                selectedReasons.find((r) => r._id === reason._id),
            ),
        );
    }

    if (emailBody && selected !== null && hospitals && client?.givenName) {
        const selectedHospital = hospitals[selected];
        srcDoc = emailBody
            .replace(/{{\s*event\.firstName\s*}}/g, client.givenName)
            .replace(/{{\s*event\.hospitalName\s*}}/g, selectedHospital.name)
            .replace(/{{\s*event\.address\s*}}/g, selectedHospital.address)
            .replace(
                /{{\s*event\.description\s*}}/g,
                selectedHospital.description,
            )
            .replace(
                /{{\s*event\.imageUrl\s*}}/g,
                selectedHospital.imageUrl || '',
            );
    } else if (!emailBody && !emailsQueryResult.loading) {
        srcDoc = emailPlaceholder(
            'There was an error fetching the email preview from customer.io',
        );
    } else if (!client?.givenName) {
        srcDoc = emailPlaceholder(
            'Please switch to the corresponding client page',
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.previewContainer}>
                <iframe
                    className={styles.emailFrame}
                    id="email-preview-frame"
                    name="email-preview"
                    title="email-preview"
                    srcDoc={srcDoc}
                />
            </div>
            <div className={styles.flyout}>
                <div className={classnames(styles.flyoutBlock, styles.first)}>
                    <div className={styles.flyoutTitle}>
                        {translate('vetspireExtension.externalReferrals.title')}
                    </div>
                </div>
                <div className={classnames(styles.flyoutBlock)}>
                    <SearchInput
                        value={searchValue}
                        onChange={(val) => {
                            setSelected(null);
                            setSearchValue(val);
                        }}
                        className={styles.searchInput}
                        placeholder="Search Hospitals"
                    />
                </div>
                <div
                    className={classnames(
                        styles.flyoutBlock,
                        styles.noVertical,
                    )}
                >
                    <div className={styles.filterInput}>
                        <ReasonsFilter
                            selectedReasons={selectedReasons}
                            setSelectedReasons={setSelectedReasons}
                            options={
                                reasonsQueryResult.data
                                    ?.externalReferralReasons || []
                            }
                        />
                    </div>
                </div>
                {hospitals && (
                    <div
                        className={classnames(
                            styles.flyoutBlock,
                            styles.hospitalContainer,
                        )}
                    >
                        {hospitals.map((hospital, index) => (
                            <HospitalOverview
                                key={hospital._id}
                                hospital={hospital}
                                selected={selected === index}
                                onClick={() => setSelected(index)}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExternalReferrals;
