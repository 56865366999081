import * as React from 'react';
import {
    addBackgroundMessageListener,
    type BackgroundMessage,
    MESSAGE_SOURCE_NAVIGATION,
    MESSAGE_TARGET_TWEAKS,
    sendBackgroundMessage,
} from 'lib/backgroundMessages';

export interface InterceptorPayload<
    Variables extends Record<string, unknown> = Record<string, unknown>,
> {
    operationName: string;
    query: string;
    variables: Variables;
}

export interface InterceptorResponse<
    Variables extends Record<string, unknown> = Record<string, unknown>,
> {
    runOriginalRequest: boolean;
    newVariables?: Variables;
    response?: unknown;
}

export default function useRequestInterceptor<
    Variables extends Record<string, unknown> = Record<string, unknown>,
>(
    operationName: string,
    onRequest: (
        payload: InterceptorPayload<Variables>,
    ) =>
        | Promise<InterceptorResponse<Variables>>
        | InterceptorResponse<Variables>,
    onlyAtPaths?: readonly (string | RegExp)[],
    skip = false,
    timeoutMs?: number,
): void {
    // at least in local dev, the whole app, and thus this hook as well,
    // seems to be mounted, then unmounted after a few ms, then mounted again.
    // this can have unwanted effects on f.e. the request interceptors, which
    // depend on postmessage communication with the content script
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => {
        const timeout = window.setTimeout(() => {
            setMounted(true);
        }, 600);

        return () => {
            window.clearTimeout(timeout);
        };
    }, []);

    const [interceptorId, setInterceptorId] = React.useState<null | string>(
        null,
    );

    React.useEffect(() => {
        let registeredIntereceptorId: string | null = null;

        if (mounted && !skip) {
            sendBackgroundMessage(
                MESSAGE_SOURCE_NAVIGATION,
                [MESSAGE_TARGET_TWEAKS],
                'addRequestInterceptor',
                {
                    interceptor: {
                        operationName,
                        onlyAtPaths,
                        timeoutMs,
                    },
                },
                true,
            ).then((response) => {
                registeredIntereceptorId =
                    (response as { interceptorId?: string }).interceptorId ??
                    null;

                setInterceptorId(registeredIntereceptorId);
            });
        }

        return () => {
            if (registeredIntereceptorId) {
                sendBackgroundMessage(
                    MESSAGE_SOURCE_NAVIGATION,
                    [MESSAGE_TARGET_TWEAKS],
                    'removeRequestInterceptor',
                    { interceptorId: registeredIntereceptorId },
                );
            }
        };
    }, [operationName, onlyAtPaths, mounted, skip, timeoutMs]);

    const listener = React.useCallback(
        async (message: BackgroundMessage) => {
            if (
                message.action === 'interceptRequest' &&
                message.interceptorId === interceptorId
            ) {
                const response = await onRequest(
                    message as unknown as InterceptorPayload<Variables>,
                );

                sendBackgroundMessage(
                    MESSAGE_SOURCE_NAVIGATION,
                    [MESSAGE_TARGET_TWEAKS],
                    'requestInterceptorResponse',
                    {
                        response: {
                            ...response,
                            eventId: message.eventId,
                        },
                    },
                );
            }
        },
        [interceptorId, onRequest],
    );

    React.useEffect(() => {
        let unsubscribe: () => void = () => {};
        if (interceptorId) {
            unsubscribe = addBackgroundMessageListener(
                MESSAGE_SOURCE_NAVIGATION,
                listener,
            );
        }

        return unsubscribe;
    }, [listener, interceptorId]);
}
