import useTranslate from 'hooks/useTranslate';
import * as React from 'react';
import classnames from 'classnames';
import styles from './ProgressStep.module.scss';
import { ProgressStepType } from '../../types';

interface StepProps {
    active: boolean;
    enabled: boolean;
    progressStepType: ProgressStepType;
    onClick: (step: ProgressStepType) => void;
}

const StepComponent: React.FunctionComponent<StepProps> = ({
    active,
    enabled,
    progressStepType,
    onClick,
}) => {
    const translate = useTranslate();

    return (
        <button
            type="button"
            className={classnames(styles.progressStep, {
                [styles.active]: active,
                [styles.enabled]: enabled,
            })}
            onClick={() => {
                if (enabled) {
                    onClick(progressStepType);
                }
            }}
        >
            {translate(
                `vetspireExtension.surgeryForms.progressStep.${progressStepType}`,
            )}
        </button>
    );
};

export default StepComponent;
