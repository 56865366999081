import * as React from 'react';
import { SukiTabs } from 'pages/Suki/types';
import { openAmbientSessionVetspirePage } from 'pages/Suki/utils';
import FormControlLabel from '@mui/material/FormControlLabel';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import MonthSelect from 'components/MonthSelect';
import { format } from 'date-fns';
import Switch from '@mui/material/Switch';
import {
    SukiAmbientSession,
    SukiAmbientSessionType,
} from '@bondvet/types/suki';
import Calendar from './components/Calendar';
import AmbientSessionList from './components/AmbientSessionList';
import SelectAmbientSessionTypes from './components/SelectAmbientSessionTypes';

import styles from './SessionOverview.module.scss';

interface SessionOverviewProps {
    changeCurrentTab: (tab: SukiTabs) => void;
}

export default function SessionOverview({
    changeCurrentTab,
}: SessionOverviewProps) {
    const translate = useTranslate();

    const currentMonth = React.useMemo(() => format(new Date(), 'yyyy-MM'), []);
    const currentDate = React.useMemo(
        () => format(new Date(), 'yyyy-MM-dd'),
        [],
    );

    const [selectedMonth, setSelectedMonth] = React.useState(currentMonth);
    const [selectedDate, setSelectedDate] = React.useState(currentDate);

    const [showAll, setShowAll] = React.useState(false);

    const [selectedAmbientSessionTypes, setSelectedAmbientSessionTypes] =
        React.useState<readonly SukiAmbientSessionType[]>([
            'encounter',
            'phone',
        ]);

    const openAmbientSession = React.useCallback(
        (ambientSession: SukiAmbientSession) => {
            openAmbientSessionVetspirePage(ambientSession);
            changeCurrentTab('ambientSession');
        },
        [changeCurrentTab],
    );

    return (
        <div className={styles.container}>
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <MonthSelect
                    month={selectedMonth}
                    maxMonth={currentMonth}
                    onChange={(newMonth) => setSelectedMonth(newMonth)}
                />
                <Calendar
                    month={selectedMonth}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                />
            </div>
            <div className={classnames(styles.flyoutBlock, styles.options)}>
                <FormControlLabel
                    control={
                        <Switch
                            color="default"
                            checked={showAll}
                            onChange={(value) =>
                                setShowAll(value.target.checked)
                            }
                        />
                    }
                    label={translate(
                        'vetspireExtension.suki.sessionOverview.showAll',
                    )}
                />
                <SelectAmbientSessionTypes
                    className={styles.ambientTypeFilter}
                    values={selectedAmbientSessionTypes}
                    onChange={setSelectedAmbientSessionTypes}
                />
            </div>
            <div className={styles.flyoutBlock}>
                <AmbientSessionList
                    date={selectedDate}
                    types={selectedAmbientSessionTypes}
                    statuses={
                        showAll ? ['finished', 'processed'] : ['finished']
                    }
                    openAmbientSession={openAmbientSession}
                />
            </div>
        </div>
    );
}
