import * as React from 'react';
import { FirebaseApp } from 'firebase/app';
import { User } from 'firebase/auth';
import {
    CollectionReference,
    DocumentData,
    DocumentReference,
} from '@firebase/firestore';

export type FirebaseContextData = {
    firebase: null | FirebaseApp;
    user: null | User;
    isInitializing: boolean;
    isInitialized: boolean;
    getCollection: <T extends DocumentData>(
        collectionName: string,
    ) => Promise<CollectionReference<T> | null>;
    getDocRef: <T extends DocumentData>(
        collectionName: string,
        docId: string,
    ) => Promise<DocumentReference<T> | null>;
};

export type FirebaseContextUpdaters = {
    signIntoFirestore: (customToken?: string) => Promise<void>;
    signOutOfFirebase: () => Promise<void>;
};

export type FirebaseContextType = FirebaseContextData & FirebaseContextUpdaters;
const FirebaseContext = React.createContext<FirebaseContextType>({
    firebase: null,
    user: null,
    isInitializing: false,
    isInitialized: false,
    getCollection: () => {
        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve) => resolve(null));
    },
    getDocRef: () => {
        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve) => resolve(null));
    },
    signIntoFirestore: () => {
        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve) => resolve());
    },
    signOutOfFirebase: () => {
        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve) => resolve());
    },
});
export default FirebaseContext;
