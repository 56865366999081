import { openVetspirePage } from 'lib/vetspireActions';
import { SukiAmbientSession } from '@bondvet/types/suki';

export function vetspireIdToGuid(vetspireId: string): string {
    const s = vetspireId.padStart(32, '0');
    return `${s.substring(0, 8)}-${s.substring(8, 12)}-${s.substring(12, 16)}-${s.substring(16, 20)}-${s.substring(20, 32)}`;
}

export function openAmbientSessionVetspirePage(
    ambientSession: Partial<SukiAmbientSession>,
) {
    if (ambientSession.vetspireEncounterId) {
        openVetspirePage(
            `/clients/${ambientSession.vetspireClientId}/patients/${ambientSession.vetspirePatientId}/encounters2/${ambientSession.vetspireEncounterId}`,
        );
    } else {
        openVetspirePage(
            `/clients/${ambientSession.vetspireClientId}/patients/${ambientSession.vetspirePatientId}/chart`,
        );
    }
}

export function nl2br(message: string): string {
    return message.replace(/\r?\n/g, '<br />');
}
