import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import { AppointmentLogFilterType } from '../AppointmentLogFilter/types';
import styles from '../../AppointmentLog.module.scss';
import { AppointmentLogs } from '../../hooks/useAppointmentLogs';
import { BlockOffLogs } from '../../hooks/useBlockOffLogs';

interface WarningMessageProps {
    appointmentsQuery: AppointmentLogs;
    blockOffsQuery: BlockOffLogs;
    currentAppointmentLogFilterType: AppointmentLogFilterType;
}

const WarningMessage = ({
    appointmentsQuery,
    blockOffsQuery,
    currentAppointmentLogFilterType,
}: WarningMessageProps) => {
    const translate = useTranslate();

    let noEventsMessage = null;
    let missingDataMessage = null;

    if (blockOffsQuery.missingData || appointmentsQuery.missingData) {
        missingDataMessage = translate('vetspireExtension.apptLog.missingData');
    }

    if (appointmentsQuery.data.all === 0) {
        noEventsMessage = translate(
            'vetspireExtension.apptLog.noAppointmentLogs',
        );
    }
    if (
        currentAppointmentLogFilterType ===
            AppointmentLogFilterType.cancelled &&
        appointmentsQuery.data.cancelled === 0
    ) {
        noEventsMessage = translate('vetspireExtension.apptLog.noCancelLogs');
    }

    if (
        currentAppointmentLogFilterType === AppointmentLogFilterType.noShow &&
        appointmentsQuery.data.noShow === 0
    ) {
        noEventsMessage = translate('vetspireExtension.apptLog.noNoShowLogs');
    }

    if (
        currentAppointmentLogFilterType ===
            AppointmentLogFilterType.rescheduled &&
        appointmentsQuery.data.rescheduled === 0
    ) {
        noEventsMessage = translate(
            'vetspireExtension.apptLog.noRescheduleLogs',
        );
    }

    if (
        currentAppointmentLogFilterType === AppointmentLogFilterType.blockoff &&
        blockOffsQuery.data.blockOffCount === 0
    ) {
        noEventsMessage = translate('vetspireExtension.apptLog.noBlockOffLogs');
    }

    if (!missingDataMessage && !noEventsMessage) {
        return null;
    }

    return (
        <>
            <div className={classnames(styles.noEvents, styles.warningMessage)}>
                {missingDataMessage}
            </div>
            <div className={styles.noEvents}>{noEventsMessage}</div>
        </>
    );
};

export default WarningMessage;
