import * as React from 'react';
import moment from 'moment-timezone';
import range from 'lodash/range';
import classnames from 'classnames';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import { getFallbackTimezone } from 'lib/utils';
import { navigateToVetspireDay } from 'lib/vetspireActions';
import useTranslate from 'hooks/useTranslate';
import styles from './SurgeryCalendar.module.scss';
import { SurgeryCalendarOptions } from './types';
import Options from './components/Options/Options';
import useSurgeryCalendarsData, {
    BondLocationWithShiftData,
} from './hooks/useSurgeryCalendarsData';
import GeneralError from '../GeneralError/GeneralError';

const SurgeryCalendar: React.FunctionComponent = () => {
    const [options, setOptions] = React.useState<SurgeryCalendarOptions>({
        loading: true,
        vetspireLocationIds: [],
        week: moment().startOf('isoWeek'),
        doctors: [],
        surgeries: true,
        dentals: true,
        openSlots: false,
        analytics: false,
    });
    const translate = useTranslate();

    const surgeryCalendarData = useSurgeryCalendarsData(options);

    const renderLocationRow = React.useCallback(
        (bondLocation: BondLocationWithShiftData) => {
            return (
                <div
                    key={bondLocation.location?._id || ''}
                    className={classnames(styles.locationBlock, {
                        [styles.noAnalytics]: !options.analytics,
                    })}
                >
                    <div className={styles.statsColumn}>
                        <div className={styles.locationName}>
                            {bondLocation.location?.name || ''}
                        </div>
                        {options.analytics && <span>Utilization</span>}
                        {options.analytics && (
                            <span>{`${bondLocation.utilization * 100}%`}</span>
                        )}
                        {options.analytics && <span>Total Capacity</span>}
                        {options.analytics && (
                            <span>{bondLocation.totalCapacity}</span>
                        )}
                        {options.analytics && <span>Utilization</span>}
                        {options.analytics && (
                            <span>{bondLocation.capacityUsed}</span>
                        )}
                    </div>
                    {Object.keys(bondLocation.shifts).map((day) => {
                        const shiftsOfDay = bondLocation.shifts[day];

                        return (
                            <div className={styles.shifts} key={day}>
                                {shiftsOfDay.surgeries.map((shift) => {
                                    return (
                                        <button
                                            key={shift._id}
                                            type="button"
                                            className={styles.shiftWrapper}
                                            onClick={() =>
                                                navigateToVetspireDay(
                                                    bondLocation.location?.name,
                                                    moment
                                                        .tz(
                                                            shift.date,
                                                            bondLocation
                                                                .location
                                                                ?.timezone ||
                                                                getFallbackTimezone(),
                                                        )
                                                        .format('YYYY-MM-DD'),
                                                )
                                            }
                                        >
                                            <div className={styles.shift}>
                                                <div
                                                    className={
                                                        styles.shiftColor
                                                    }
                                                    style={{
                                                        background: shift.color,
                                                    }}
                                                />
                                                <div
                                                    className={styles.shiftText}
                                                >
                                                    <div>
                                                        {
                                                            shift.appointmentTypeName
                                                        }
                                                    </div>
                                                    <div>
                                                        {!shiftsOfDay.providers ||
                                                        shiftsOfDay?.providers
                                                            .length !== 1 ? (
                                                            <div>
                                                                <span>
                                                                    {translate(
                                                                        'vetspireExtension.surgeryCalendar.noDoctorAssigned.title',
                                                                    )}
                                                                </span>
                                                                <div
                                                                    className={
                                                                        styles.infoIconContainer
                                                                    }
                                                                >
                                                                    <InfoIcon
                                                                        className={
                                                                            styles.infoIcon
                                                                        }
                                                                    />
                                                                    <div
                                                                        className={
                                                                            styles.noDoctorAssigned
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.arrow
                                                                            }
                                                                        />
                                                                        <div
                                                                            className={
                                                                                styles.noDoctorAssignedContainer
                                                                            }
                                                                        >
                                                                            <div>
                                                                                <div
                                                                                    className={
                                                                                        styles.title
                                                                                    }
                                                                                >
                                                                                    {translate(
                                                                                        'vetspireExtension.surgeryCalendar.noDoctorAssigned.title',
                                                                                    )}
                                                                                </div>
                                                                                <span>
                                                                                    {translate(
                                                                                        'vetspireExtension.surgeryCalendar.noDoctorAssigned.content',
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            shiftsOfDay
                                                                .providers[0]
                                                                .name
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            );
        },
        [translate, options?.analytics],
    );

    return (
        <div className={styles.container}>
            {!options.loading && !surgeryCalendarData?.loading && (
                <div
                    className={classnames(
                        styles.gridLine,
                        styles.columnsGroup,
                        {
                            [styles.noAnalytics]: !options.analytics,
                        },
                    )}
                >
                    <div
                        className={classnames(
                            styles.statsBlock,
                            styles.columns,
                        )}
                    />
                    {range(7).map((n) => (
                        <div key={n} className={styles.columns} />
                    ))}
                </div>
            )}
            {surgeryCalendarData?.error && (
                <GeneralError
                    className={styles.error}
                    message={surgeryCalendarData.error}
                />
            )}
            <Options
                possibleDoctors={surgeryCalendarData.doctors || []}
                options={options}
                onChange={(newOptions: SurgeryCalendarOptions) => {
                    setOptions(newOptions);
                }}
            />
            <div className={styles.grid}>
                <div
                    className={classnames(styles.gridLine, {
                        [styles.noAnalytics]: !options.analytics,
                    })}
                >
                    <div className={styles.statsBlock} />
                    {range(7).map((n) => {
                        const day = options.week.clone().add({ days: n });
                        return (
                            <div key={n} className={styles.dayHeader}>
                                <span className={styles.date}>
                                    {day.format('MM/DD')}
                                </span>{' '}
                                {day.format('dddd')}
                            </div>
                        );
                    })}
                </div>
            </div>
            {!surgeryCalendarData.loading && (
                <div className={styles.grid}>
                    <div>
                        {(
                            surgeryCalendarData?.locationsWithShiftData || []
                        ).map((bondLocation) =>
                            renderLocationRow(bondLocation),
                        )}
                        {(surgeryCalendarData?.locationsWithShiftData || [])
                            .length === 0 && (
                            <span className={styles.nothingFoundMsg}>
                                {translate(
                                    'vetspireExtension.surgeryCalendar.nothingFound',
                                )}
                            </span>
                        )}
                    </div>
                </div>
            )}
            {(options.loading || surgeryCalendarData.loading) && (
                <span className={styles.nothingFoundMsg}>
                    <CircularProgress />
                </span>
            )}
        </div>
    );
};

export default SurgeryCalendar;
