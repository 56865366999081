import * as React from 'react';
import classnames from 'classnames';
import { ArrowForwardIos } from '@mui/icons-material';
import usePatientsQuery from 'hooks/usePatientsQuery';
import useViewerSettings from 'hooks/useViewerSettings';
import uniq from 'lodash/uniq';
import { CircularProgress } from '@mui/material';
import {
    SukiAmbientSession,
    SukiAmbientSessionStatus,
    SukiAmbientSessionType,
} from '@bondvet/types/suki';
import useSukiAmbientSessionsQuery from 'pages/Suki/hooks/useSukiAmbientSessionsQuery';
import { endOfDay, parse, format } from 'date-fns';
import { ReactComponent as ProcessedIcon } from './assets/processed.svg';

import styles from './AmbientSessionList.module.scss';

interface AmbientSessionListProps {
    date: string; // yyyy-MM-dd
    types: readonly SukiAmbientSessionType[];
    statuses: readonly SukiAmbientSessionStatus[];
    openAmbientSession: (ambientSession: SukiAmbientSession) => void;
}

export default function AmbientSessionList({
    date,
    types,
    statuses,
    openAmbientSession,
}: AmbientSessionListProps) {
    const viewerSettings = useViewerSettings();

    const from = parse(date, 'yyyy-MM-dd', new Date());
    const to = endOfDay(from);

    const { data: sukiAmbientSessions, loading } = useSukiAmbientSessionsQuery({
        vetspireProviderId: viewerSettings.viewer?.id ?? '',
        from,
        to,
        types,
        statuses,
    });

    const vetspirePatientIds = React.useMemo(
        () =>
            uniq(
                (sukiAmbientSessions?.sukiAmbientSessions ?? [])
                    .map((session) => session.vetspirePatientId)
                    .filter((id) => !!id),
            ),
        [sukiAmbientSessions?.sukiAmbientSessions],
    );

    const { data: patients } = usePatientsQuery({
        ids: vetspirePatientIds,
    });

    if (loading) {
        return (
            <div className={styles.container}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <ul className={styles.container}>
            {sukiAmbientSessions?.sukiAmbientSessions.map((ambientSession) => {
                const patient = patients?.patients.find(
                    (searchPatient) =>
                        searchPatient.id === ambientSession.vetspirePatientId,
                );

                return (
                    <li
                        key={ambientSession._id}
                        className={classnames({
                            [styles.processed]:
                                ambientSession.status === 'processed',
                        })}
                        onClick={() => openAmbientSession(ambientSession)}
                    >
                        <div>
                            {ambientSession.status === 'processed' && (
                                <ProcessedIcon className={styles.icon} />
                            )}
                            {format(
                                new Date(ambientSession.startedAt),
                                'd/M/yyyy, h:maaa',
                            )}
                        </div>
                        <div>{patient?.client?.name ?? '...'}</div>
                        <div>{patient?.name ?? '...'}</div>
                        <div>
                            <ArrowForwardIos />
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}
