import { Moment } from 'moment';
import { AppointmentLogFilterType } from './components/AppointmentLogFilter/types';

export interface RescheduledAppointmentEvent {
    id: string;
    type: AppointmentLogFilterType.rescheduled;
    eventDatetime: string;
    cancelName: string;
    oldApptDatetime: string;
    newApptDatetime: string;
    oldProviderName: string;
    newProviderName: string;
}

export interface NoShowAppointmentEvent {
    id: string;
    type: AppointmentLogFilterType.noShow;
    eventDatetime: string;
}

export interface CancelAppointmentEvent {
    id: string;
    type: AppointmentLogFilterType.cancelled;
    eventDatetime: string;
    cancelName: string;
    apptDatetime: string;
    providerName: string;
}

export interface CreateAppointmentEvent {
    id: string;
    type: AppointmentLogFilterType.create;
    eventDatetime: string;
    apptDatetime: string;
    providerName: string;
    cancelName: string;
}

export interface CreateBlockOffEvent {
    id: string;
    type: BlockOffLogType.create;
    eventDatetime: Moment;
    apptDatetime: Moment;
    providerName: string;
    cancelName: string;
    appointmentTypeName: string;
    apptEndDatetime: Moment;
}

export interface ChangeStartBlockOffEvent {
    id: string;
    type: BlockOffLogType.changeStart;
    eventDatetime: Moment;
    apptDatetime: Moment;
    cancelName: string;
    appointmentTypeName: string;
}

export interface ChangeDurationBlockOffEvent {
    id: string;
    type: BlockOffLogType.changeDuration;
    eventDatetime: Moment;
    apptDatetime: Moment;
    oldApptDatetime?: Moment;
    oldApptEndDatetime?: Moment;
    cancelName: string;
    apptEndDatetime: Moment;
    appointmentTypeName: string;
}

export interface ChangeProviderNameBlockOffEvent {
    id: string;
    type: BlockOffLogType.changeProviderName;
    providerName: string;
    eventDatetime: Moment;
    cancelName: string;
    appointmentTypeName: string;
}

export interface ChangeAppointmentTypeNameBlockOffEvent {
    id: string;
    type: BlockOffLogType.changeAppointmentTypeName;
    eventDatetime: Moment;
    cancelName: string;
    appointmentTypeName: string;
    oldAppointmentTypeName: string;
}

export interface DeleteBlockOffEvent {
    id: string;
    type: BlockOffLogType.deleteBlockOffEvent;
    eventDatetime: Moment;
    cancelName: string;
    appointmentTypeName: string;
    apptDatetime: Moment;
    apptEndDatetime: Moment;
}

export interface UserTypeSelectOption {
    value: string;
    label: string;
}

export enum BlockOffLogType {
    create = 'create',
    changeStart = 'change-start',
    changeDuration = 'change-duration',
    changeProviderName = 'change-provider-name',
    changeAppointmentTypeName = 'change-appointment-type-name',
    deleteBlockOffEvent = 'delete-block-off-event',
}

export const EVENT_DATETIME_FORMAT = 'M/D/YY [at] h:mm a';
export const NO_SHOW_DATETIME_FORMAT = 'h:mm a';
export const APPOINTMENT_DATETIME_FORMAT = 'M/D/YY h:mm a';
export const CREATE_DATETIME_FORMAT_LONG = 'M/D/YY h:mm a';
export const CREATE_DATETIME_FORMAT_SHORT = 'h:mma';
export const USER_TYPE_SELECTED_TEAM = 'team';
export const USER_TYPE_SELECTED_CLIENT = 'client';
export const TEAM_VETSPIRE_USERNAME = 'Client';
