import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import {
    BonuslyFeedItemType,
    bonuslyQuery,
    BonuslyQueryResult,
    BonuslyQueryVariables,
} from 'api/bond/queries';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { useAnalytics } from 'hooks/useAnalytics';
import GeneralError from 'components/GeneralError/GeneralError';
import { Page } from 'lib/vetspireActions';
import styles from './Bonusly.module.scss';
import BonuslyFeedItem from './components/BonuslyFeedItem';

let lockObj = false;
const Bonusly: React.FunctionComponent = () => {
    const translate = useTranslate();
    const analytics = useAnalytics();
    const [page, setPage] = React.useState(0);
    const [feed, setFeed] = React.useState<BonuslyFeedItemType[]>([]);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const { data, loading, error } = useQuery<
        BonuslyQueryResult,
        BonuslyQueryVariables
    >(bonuslyQuery, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        fetchPolicy: 'network-only',
        variables: {
            page,
        },
    });

    React.useEffect(() => {
        if (data?.bonuslyFeed && data.bonuslyFeed.length > 0) {
            setFeed([...feed, ...data.bonuslyFeed]);
            lockObj = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFeed, data?.bonuslyFeed]);

    React.useEffect(() => {
        const onScroll = () => {
            if (
                containerRef.current &&
                !loading &&
                data?.bonuslyFeed &&
                data?.bonuslyFeed.length > 0 &&
                containerRef.current.getBoundingClientRect().bottom * 0.7 <=
                    window.outerHeight &&
                !lockObj
            ) {
                lockObj = true;
                setPage(page + 1);
            }
        };
        document.addEventListener('scroll', onScroll);
        return () => document.removeEventListener('scroll', onScroll);
    }, [loading, page, setPage, data?.bonuslyFeed]);

    if (error) {
        return <GeneralError message={error.message} />;
    }

    return (
        <div className={styles.container}>
            <div ref={containerRef}>
                <div className={classnames(styles.flyoutBlock, styles.first)}>
                    <div className={styles.flyoutTitle}>
                        {translate('vetspireExtension.bonusly.title')}
                    </div>
                </div>
                <div
                    className={classnames(
                        styles.flyoutBlock,
                        styles.noVertical,
                        styles.noHorizontal,
                        styles.feedList,
                    )}
                >
                    {feed.map((bonuslyFeedItem) => (
                        <BonuslyFeedItem
                            bonuslyFeedItem={bonuslyFeedItem}
                            key={bonuslyFeedItem.id}
                        />
                    ))}
                    {loading && (
                        <div className={styles.loading}>
                            <CircularProgress />
                        </div>
                    )}
                </div>
                <div className={styles.banner}>
                    <a
                        href="https://bonus.ly/bonuses"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            analytics.trackEvent(
                                Page.bonusly,
                                'bonusly_shout_out',
                            );
                        }}
                    >
                        {translate('vetspireExtension.bonusly.giveThanks')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Bonusly;
