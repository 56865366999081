import { gql } from '@apollo/client';
import {
    LocationShortInfo,
    PatientShortInfo,
    ProviderShortInfo,
} from '../types';

export type Medication = {
    id: string;
    name: string;
    sig: string | null;
    note: string | null;
    frequency: string | null;
};

export type Encounter = {
    id: string;
    start: string;
    location?: LocationShortInfo;
    patient?: PatientShortInfo;
    provider?: ProviderShortInfo;
    medications?: ReadonlyArray<Medication>;
    encounterType: {
        id: string;
        name: string;
    };
};

export type EncounterQueryVariables = {
    id: string;
};

export interface EncounterQueryResult {
    encounter: Encounter;
}

export const encounterQuery = gql`
    query encounter($id: ID!) {
        encounter(id: $id) {
            medications {
                id
                name
                sig
                note
                frequency
            }
        }
    }
`;

export interface EncountersQueryResult {
    encounters: Encounter[];
}

export interface EncountersQueryVariables {
    limit?: number;
    offset?: number;
    locationId?: string;
    startBefore?: Date;
    startAfter?: Date;
}

export const signedEncountersQuery = gql`
    query encounters(
        $locationId: ID
        $limit: Int
        $offset: Int
        $startBefore: NativeDateTime
        $startAfter: NativeDateTime
    ) {
        encounters(
            signed: false
            locationId: $locationId
            limit: $limit
            offset: $offset
            startBefore: $startBefore
            startAfter: $startAfter
        ) {
            id
            start
            encounterType {
                id
                name
            }
            location {
                id
                name
            }
            patient {
                id
                name
                client {
                    id
                    name
                }
            }
            provider {
                id
                name
            }
        }
    }
`;

export interface EncounterTypesQueryResult {
    encounterTypes: {
        id: string;
        name: string;
    }[];
}

export const encounterTypesQuery = gql`
    query encounterTypes {
        encounterTypes {
            name
            id
        }
    }
`;
