import * as React from 'react';
import { Translated } from '@bondvet/web-app-i18n/util';
import useProviders from 'hooks/useProviders';
import useViewerSettings from 'hooks/useViewerSettings';
import type { Provider } from 'api/providers/queries';
import classnames from 'classnames';
import { ProvidersSelect } from 'components/LookupSelect';
import MonthSelect from 'components/MonthSelect';
import moment from 'moment-timezone';
import useTimezone from 'hooks/useTimezone';
import { CircularProgress } from '@mui/material';
import GeneralError from 'components/GeneralError/GeneralError';
import useTranslate from 'hooks/useTranslate';
import { ProviderRightValue } from '@bondvet/types/providers';
import { BannerContent } from 'components/ProviderPayment/components/Banner';
import useShowBanner from 'components/ProviderPaymentTab/hooks/useShowBanner';
import useProviderPaymentMonth from './hooks/useProviderPaymentMonth';
import MonthOverview from './components/MonthOverview';
import TotalBonus from './components/TotalBonus';
import styles from './ProviderPaymentTab.module.scss';
import PinValidation from './components/PinValidation/PinValidation';

const PIN_TIMEOUT_MS = 300000;
const MIN_MONTH = '2021-06';

interface ProviderPaymentTabProps {
    setBannerContent: (bannerContent: BannerContent | null) => void;
}

const ProviderPaymentTab: React.FunctionComponent<ProviderPaymentTabProps> = ({
    setBannerContent,
}) => {
    const viewerSettings = useViewerSettings();
    const translate = useTranslate();

    const [pinValidated, setPinValidated] = React.useState(false);
    const [pinValidatedTimer, setPinValidatedTimer] =
        React.useState<NodeJS.Timeout | null>(null);

    useShowBanner(pinValidated, setBannerContent);

    React.useEffect(() => {
        return () => {
            if (pinValidatedTimer) {
                clearTimeout(pinValidatedTimer);
            }
        };
    }, [pinValidatedTimer]);

    const validatePin = () => {
        setPinValidated(true);
        setPinValidatedTimer(
            setTimeout(() => {
                setPinValidated(false);
            }, PIN_TIMEOUT_MS),
        );
    };

    const [selectedProviders, setSelectedProviders] = React.useState<
        Provider[]
    >([]);
    const providersQueryResult = useProviders();

    const timezone = useTimezone();

    const [selectedMonth, setSelectedMonth] = React.useState(() =>
        moment.tz(timezone).format('YYYY-MM'),
    );

    React.useEffect(() => {
        if (
            viewerSettings.rights?.vetspireExtension_providerPayment ===
                ProviderRightValue.enabled_ownRecords &&
            providersQueryResult.providers
        ) {
            const provider = (providersQueryResult.providers || []).find(
                (prov) => prov.id === viewerSettings.viewer?.id,
            );

            if (provider && provider.id !== selectedProviders?.[0]?.id) {
                setSelectedProviders([provider]);
            }
        }
    }, [providersQueryResult, viewerSettings, selectedProviders]);

    const {
        loadProviderPaymentMonth,
        providerPaymentMonth,
        lastRunVariables,
        loading,
    } = useProviderPaymentMonth();

    React.useEffect(() => {
        let vetspireProviderId = null;

        if (
            viewerSettings.rights?.vetspireExtension_providerPayment ===
            ProviderRightValue.enabled_allRecords
        ) {
            if (selectedProviders.length === 1) {
                vetspireProviderId = selectedProviders[0].id;
            }
        } else {
            vetspireProviderId = viewerSettings.viewer?.id;
        }

        if (
            vetspireProviderId &&
            selectedMonth &&
            (vetspireProviderId !== lastRunVariables?.vetspireProviderId ||
                selectedMonth !== lastRunVariables?.month)
        ) {
            loadProviderPaymentMonth({
                vetspireProviderId,
                month: selectedMonth,
            }).then();
        }
    }, [
        providerPaymentMonth,
        loadProviderPaymentMonth,
        lastRunVariables,
        selectedProviders,
        selectedMonth,
        viewerSettings,
    ]);

    const isFutureMonth = selectedMonth > moment.tz(timezone).format('YYYY-MM');

    const renderHint = (hint: Translated): JSX.Element => {
        return <div className={styles.hint}>{hint}</div>;
    };

    const allowProviderFilter =
        viewerSettings.rights?.vetspireExtension_providerPayment ===
        ProviderRightValue.enabled_allRecords;

    let content: JSX.Element;

    if (providersQueryResult.loading || loading) {
        content = renderHint(<CircularProgress />);
    } else if (providersQueryResult.error) {
        content = <GeneralError message={providersQueryResult.error.message} />;
    } else if (!pinValidated) {
        content = <PinValidation onValidated={validatePin} />;
    } else if (allowProviderFilter && selectedProviders.length === 0) {
        content = renderHint(
            translate(
                'vetspireExtension.providerPayment.errors.noProviderSelected',
            ),
        );
    } else if (!providerPaymentMonth?.paymentCalculation) {
        content = renderHint(
            translate('vetspireExtension.providerPayment.errors.noData'),
        );
    } else {
        content = (
            <div>
                <MonthOverview
                    provider={selectedProviders[0]}
                    timezone={timezone}
                    providerPaymentMonth={providerPaymentMonth}
                />
                {isFutureMonth || (
                    <TotalBonus
                        provider={selectedProviders[0]}
                        timezone={timezone}
                        providerPaymentMonth={providerPaymentMonth}
                    />
                )}
            </div>
        );
    }

    let minMonth = moment
        .tz(timezone)
        .subtract({
            months:
                viewerSettings.rights?.vetspireExtension_providerPayment ===
                ProviderRightValue.enabled_allRecords
                    ? 24
                    : 3,
        })
        .format('YYYY-MM');

    if (
        viewerSettings.rights?.vetspireExtension_providerPayment ===
            ProviderRightValue.enabled_ownRecords &&
        minMonth < MIN_MONTH
    ) {
        minMonth = MIN_MONTH;
    }

    const maxMonth = moment.tz(timezone).add({ months: 3 }).format('YYYY-MM');

    return (
        <div
            className={classnames(styles.container, {
                [styles.noFilter]: !pinValidated,
                [styles.oneFilter]: pinValidated && !allowProviderFilter,
                [styles.twoFilters]: pinValidated && allowProviderFilter,
            })}
        >
            {pinValidated && allowProviderFilter && (
                <div className={styles.flyoutBlock}>
                    <ProvidersSelect
                        providersQueryResult={providersQueryResult}
                        selectedProviders={selectedProviders}
                        onChange={(providers) =>
                            setSelectedProviders(providers)
                        }
                        addLocationGroup={false}
                        addOtherGroup={false}
                        isMulti={false}
                        vetsOnly
                    />
                </div>
            )}
            {pinValidated && (
                <div className={styles.flyoutBlock}>
                    <MonthSelect
                        month={selectedMonth}
                        minMonth={minMonth}
                        maxMonth={maxMonth}
                        onChange={setSelectedMonth}
                    />
                </div>
            )}
            <div className={styles.flyoutBlock}>{content}</div>
        </div>
    );
};

export default ProviderPaymentTab;
