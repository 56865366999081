import * as React from 'react';
import {
    locationsQuery as bondLocationsQuery,
    LocationsQueryResult as BondLocationsQueryResult,
} from 'api/bond/queries';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { Location } from '@bondvet/types/locations';
import {
    StaffingRole,
    STAFFING_ROLES,
} from '@bondvet/types/scheduling/staffing';
import { useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import {
    SchedulingShiftTime,
    UKGRawLiveShiftsArguments,
} from '@bondvet/types/scheduling';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import useExtractedStaffingShiftStatusesQuery from './useExtractedStaffinghiftStatusesQuery';
import useUKGSkills from './useUKGSkills';
import useLazyUKGRawLiveShifts from './useLazyUKGRawLiveShifts';
import useUKGSchedules from './useUKGSchedules';
import useUKGEmployees from './useUKGEmployees';
import useStaffingSettings from './useStaffingSettings';
import useSchedulingShiftTimes from './useSchedulingShiftTimes';
import { getShiftTimeFromUKGShift } from '../utils';

const TERMINATED_STATUS = 'Terminated';

export interface RoleStats {
    count: number;
    expected: number;
    underStaffed: number;
    overStaffed: number;
    ignore: number;
}

export interface StatsByRole {
    [key: string]: RoleStats;
}

export interface StaffingEmployee {
    id: string;
    name: string;
    shiftStartISO: string | null;
    shiftEndISO: string | null;
    timezone: string | undefined;
    shiftTime: SchedulingShiftTime | null;
}

export interface EmployeesByRole {
    [key: string]: {
        employees: StaffingEmployee[];
        stats: RoleStats;
    };
}

export interface EmployeesByDayAndRole {
    [key: string]: EmployeesByRole;
}

export interface LocationWithStaffData {
    bondLocation: Location;
    employeesByDayAndRole: EmployeesByDayAndRole;
    locationStats: StatsByRole;
}

export interface TotalStatsByDay {
    [key: string]: StatsByRole;
}

interface StaffOptimizationData {
    loading?: boolean;
    error?: string;
    locationsWithStaffData?: LocationWithStaffData[];
    totalStatsByDay?: TotalStatsByDay;
    updateShiftStatuses?: () => void;
    runQueries?: () => void;
    runQueriesDisabled?: boolean;
}

interface UseStaffOptimizationOptions {
    locationIds: string[] | null;
    from: Moment;
    to: Moment;
    enabled: boolean;
}

function getInitStats(): RoleStats {
    return {
        count: 0,
        expected: 0,
        underStaffed: 0,
        overStaffed: 0,
        ignore: 0,
    };
}

function getInitStatsByRole(): StatsByRole {
    return STAFFING_ROLES.reduce(
        (statsByRole, role) => ({
            ...statsByRole,
            [role]: getInitStats(),
        }),
        {},
    );
}

function getInitEmployeesByRole(): EmployeesByRole {
    return STAFFING_ROLES.reduce(
        (employeesByRole, role) => ({
            ...employeesByRole,
            [role]: {
                employees: [],
                stats: getInitStats(),
            },
        }),
        {},
    );
}

function calculateStaffing(employeesByRole: EmployeesByRole) {
    // TODO implement actual rules as soon as they are defined

    // eslint-disable-next-line no-param-reassign
    employeesByRole.nurse.stats.expected = employeesByRole.doctor.stats.count;

    // eslint-disable-next-line no-param-reassign
    employeesByRole.assistant.stats.expected =
        employeesByRole.doctor.stats.count;

    // eslint-disable-next-line no-param-reassign
    employeesByRole.careCoordinator.stats.expected = 1;

    // eslint-disable-next-line no-param-reassign
    employeesByRole.surgeryNurse.stats.expected =
        employeesByRole.surgeryDoctor.stats.count;

    // eslint-disable-next-line no-param-reassign
    employeesByRole.surgeryAssistant.stats.expected =
        employeesByRole.surgeryAssistant.stats.count;

    /*
    The OPs team would like to use the staffing display feature to easily see who is/isn't working for the day.
    -> For our Staffing display extension feature, let's remove the hardcoded staffing display ratios

    Code is commented and not deleted, so we just have to uncomment it when we have the actual staffing ratio rules.

    [
        'nurse',
        'assistant',
        'careCoordinator',
        'surgeryNurse',
        'surgeryAssistant',
    ].forEach((staffingRole) => {
        if (
            employeesByRole[staffingRole].stats.count >
            employeesByRole[staffingRole].stats.expected
        ) {
            // eslint-disable-next-line no-param-reassign
            employeesByRole[staffingRole].stats.overStaffed =
                employeesByRole[staffingRole].stats.count -
                employeesByRole[staffingRole].stats.expected;
        } else if (
            employeesByRole[staffingRole].stats.count <
            employeesByRole[staffingRole].stats.expected
        ) {
            // eslint-disable-next-line no-param-reassign
            employeesByRole[staffingRole].stats.underStaffed =
                employeesByRole[staffingRole].stats.expected -
                employeesByRole[staffingRole].stats.count;
        }
    });
     */
}

export default function useStaffOptimizationUKGData({
    locationIds,
    from,
    to,
    enabled,
}: UseStaffOptimizationOptions): StaffOptimizationData | null {
    const [runQueriesDisabled, setRunQueriesDisabled] = React.useState(true);
    const bondLocationsQueryResult = useQuery<BondLocationsQueryResult>(
        bondLocationsQuery,
        {
            fetchPolicy: 'cache-first',
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );

    const selectedBondLocations = React.useMemo<Location[]>(() => {
        return (
            bondLocationsQueryResult.data?.locations.filter(
                (location) =>
                    location._vetspire?.id &&
                    location.showStaffOptimization &&
                    (locationIds === null ||
                        locationIds.includes(location._id || '')),
            ) || []
        );
    }, [locationIds, bondLocationsQueryResult]);

    const selectedVetspireLocationIds = React.useMemo<ReadonlyArray<string>>(
        () =>
            selectedBondLocations
                .map((location) => location._vetspire?.id)
                .filter((id) => !!id) as ReadonlyArray<string>,
        [selectedBondLocations],
    );

    const staffingSettings = useStaffingSettings();
    const schedulingShiftTimes = useSchedulingShiftTimes();
    const ukgSkills = useUKGSkills();
    const ukgEmployees = useUKGEmployees();
    const ukgSchedules = useUKGSchedules(
        selectedVetspireLocationIds,
        from.format('YYYY-MM-DD'),
        to.format('YYYY-MM-DD'),
    );

    const [runUKGRawLiveShiftsQuery, ukgRawLiveShiftsQueryResult] =
        useLazyUKGRawLiveShifts();

    const extractedStaffingShiftStatusesQuery =
        useExtractedStaffingShiftStatusesQuery();

    const [lastState, setLastState] = React.useState<{
        selectedLocations: Location[] | null;
        queryVariables: UKGRawLiveShiftsArguments | null;
        from: Moment | null;
        to: Moment | null;
    }>({
        selectedLocations: null,
        queryVariables: null,
        from: null,
        to: null,
    });

    const getQueryVariables =
        React.useCallback((): UKGRawLiveShiftsArguments => {
            return {
                vetspireLocationIds: selectedVetspireLocationIds,
                fromDate: from.format('YYYY-MM-DD'),
                toDate: to.format('YYYY-MM-DD'),
            };
        }, [selectedVetspireLocationIds, from, to]);

    const updateShiftStatuses = React.useCallback(() => {
        const queryVariables = getQueryVariables();

        extractedStaffingShiftStatusesQuery?.run({
            variables: queryVariables,
        });
    }, [extractedStaffingShiftStatusesQuery, getQueryVariables]);

    React.useEffect(() => {
        // do not run query unless options area initialized
        if (
            enabled &&
            !bondLocationsQueryResult.loading &&
            !ukgRawLiveShiftsQueryResult.loading
        ) {
            const queryVariables = getQueryVariables();

            if (!isEqual(queryVariables, lastState.queryVariables)) {
                setRunQueriesDisabled(false);
            } else {
                setRunQueriesDisabled(true);
            }
        }
    }, [
        bondLocationsQueryResult.loading,
        enabled,
        getQueryVariables,
        lastState.queryVariables,
        ukgRawLiveShiftsQueryResult.loading,
    ]);

    const runQueries = React.useCallback(() => {
        const queryVariables = getQueryVariables();
        runUKGRawLiveShiftsQuery({ variables: queryVariables }).then();

        extractedStaffingShiftStatusesQuery?.run({
            variables: queryVariables,
        });

        updateShiftStatuses();
        setLastState({
            queryVariables,
            selectedLocations: selectedBondLocations,
            from,
            to,
        });
    }, [
        extractedStaffingShiftStatusesQuery,
        from,
        getQueryVariables,
        runUKGRawLiveShiftsQuery,
        selectedBondLocations,
        to,
        updateShiftStatuses,
    ]);

    return React.useMemo(() => {
        const totalStatsByDay: TotalStatsByDay = {};

        if (bondLocationsQueryResult.error) {
            return {
                error: bondLocationsQueryResult.error.message,
            };
        }

        if (staffingSettings.error) {
            return {
                error: staffingSettings.error.message,
            };
        }

        if (ukgSkills.error) {
            return {
                error: ukgSkills.error.message,
            };
        }

        if (ukgEmployees.error) {
            return {
                error: ukgEmployees.error.message,
            };
        }

        if (ukgSchedules.error) {
            return {
                error: ukgSchedules.error.message,
            };
        }

        if (extractedStaffingShiftStatusesQuery.error) {
            return {
                error: extractedStaffingShiftStatusesQuery.error.message,
            };
        }

        if (ukgRawLiveShiftsQueryResult.error) {
            return {
                error: ukgRawLiveShiftsQueryResult.error.message,
            };
        }

        if (
            bondLocationsQueryResult.loading ||
            ukgRawLiveShiftsQueryResult.loading ||
            extractedStaffingShiftStatusesQuery.loading ||
            staffingSettings.loading ||
            ukgSkills.loading ||
            ukgEmployees.loading ||
            ukgSchedules.loading
        ) {
            return { loading: true };
        }

        const locationsWithStaffData = (lastState.selectedLocations || [])
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
            .map((bondLocation) => {
                const locationWithStaffData: LocationWithStaffData = {
                    bondLocation,
                    employeesByDayAndRole: {},
                    locationStats: getInitStatsByRole(),
                };

                const scheduleIdsOfLocation = (ukgSchedules.ukgSchedules || [])
                    .filter((ukgSchedule) =>
                        ukgSchedule.scheduled_cost_centers?.find(
                            (scheduledCostCenter) =>
                                scheduledCostCenter.id ===
                                bondLocation._ukg?.costCenterId,
                        ),
                    )
                    .map((ukgSchedule) => ukgSchedule._id);

                const shiftsOfLocation =
                    ukgRawLiveShiftsQueryResult.data?.ukgRawLiveShifts.filter(
                        (ukgShift) =>
                            scheduleIdsOfLocation.includes(
                                ukgShift.schedule_id,
                            ),
                    ) || [];

                const day = lastState?.from?.clone();

                while (day && !day?.isAfter(lastState?.to)) {
                    const dayS = day.format('YYYY-MM-DD');

                    const shiftsOfDay = shiftsOfLocation.filter(
                        (shift) =>
                            moment(shift.date).format('YYYY-MM-DD') === dayS,
                    );

                    const employeesByRole = getInitEmployeesByRole();

                    STAFFING_ROLES.forEach((staffingRole: StaffingRole) => {
                        const skillIds =
                            staffingSettings.staffingSettings.skillRoleMapping[
                                staffingRole
                            ];

                        employeesByRole[staffingRole] = {
                            employees: [],
                            stats: getInitStats(),
                        };

                        shiftsOfDay
                            .filter(
                                (shift) =>
                                    shift.account?.account_id &&
                                    shift.skill.id &&
                                    skillIds.includes(shift.skill.id),
                            )
                            .forEach((shift) => {
                                const ukgEmployee =
                                    ukgEmployees.ukgEmployees.find(
                                        (employee) =>
                                            employee._id ===
                                            shift.account?.account_id,
                                    );

                                if (
                                    ukgEmployee &&
                                    ukgEmployee.status !== TERMINATED_STATUS
                                ) {
                                    const shiftTime = getShiftTimeFromUKGShift(
                                        schedulingShiftTimes.schedulingShiftTimes,
                                        shift,
                                        bondLocation.timezone,
                                    );

                                    employeesByRole[
                                        staffingRole
                                    ].employees.push({
                                        id: ukgEmployee._id,
                                        name: `${
                                            ukgEmployee.first_name || ''
                                        } ${ukgEmployee.last_name}`,
                                        shiftStartISO:
                                            shift.shift_start || null,
                                        shiftEndISO: shift.shift_end || null,
                                        timezone: bondLocation.timezone,
                                        shiftTime,
                                    });
                                    employeesByRole[staffingRole].stats.count +=
                                        1;
                                }
                            });
                    });

                    calculateStaffing(employeesByRole);

                    if (!totalStatsByDay[dayS]) {
                        totalStatsByDay[dayS] = getInitStatsByRole();
                    }

                    STAFFING_ROLES.forEach((role: StaffingRole) => {
                        totalStatsByDay[dayS][role].count +=
                            employeesByRole[role].stats.count;

                        totalStatsByDay[dayS][role].overStaffed +=
                            employeesByRole[role].stats.overStaffed;

                        totalStatsByDay[dayS][role].underStaffed +=
                            employeesByRole[role].stats.underStaffed;

                        locationWithStaffData.locationStats[role].count +=
                            employeesByRole[role].stats.count;

                        locationWithStaffData.locationStats[role].overStaffed +=
                            employeesByRole[role].stats.overStaffed;

                        locationWithStaffData.locationStats[
                            role
                        ].underStaffed +=
                            employeesByRole[role].stats.underStaffed;

                        const ignore =
                            extractedStaffingShiftStatusesQuery.data?.[
                                bondLocation._id
                            ]?.[dayS]?.[role]?.ignore || 0;

                        if (employeesByRole[role].stats.overStaffed > 0) {
                            totalStatsByDay[dayS][role].overStaffed -= ignore;

                            locationWithStaffData.locationStats[
                                role
                            ].overStaffed -= ignore;
                        } else if (
                            employeesByRole[role].stats.underStaffed > 0
                        ) {
                            totalStatsByDay[dayS][role].underStaffed -= ignore;

                            locationWithStaffData.locationStats[
                                role
                            ].underStaffed -= ignore;
                        }

                        employeesByRole[role].stats.ignore = ignore;
                    });

                    locationWithStaffData.employeesByDayAndRole[dayS] =
                        employeesByRole;

                    day.add({ day: 1 });
                }

                return locationWithStaffData;
            });

        return {
            loading: false,
            locationsWithStaffData,
            totalStatsByDay,
            updateShiftStatuses,
            runQueries,
            runQueriesDisabled,
        };
    }, [
        bondLocationsQueryResult.error,
        bondLocationsQueryResult.loading,
        staffingSettings.error,
        staffingSettings.loading,
        staffingSettings.staffingSettings.skillRoleMapping,
        ukgSkills.error,
        ukgSkills.loading,
        ukgEmployees.error,
        ukgEmployees.loading,
        ukgEmployees.ukgEmployees,
        ukgSchedules.error,
        ukgSchedules.loading,
        ukgSchedules.ukgSchedules,
        extractedStaffingShiftStatusesQuery.error,
        extractedStaffingShiftStatusesQuery.loading,
        extractedStaffingShiftStatusesQuery.data,
        ukgRawLiveShiftsQueryResult.error,
        ukgRawLiveShiftsQueryResult.loading,
        ukgRawLiveShiftsQueryResult.data?.ukgRawLiveShifts,
        lastState,
        updateShiftStatuses,
        runQueries,
        runQueriesDisabled,
        schedulingShiftTimes.schedulingShiftTimes,
    ]);
}
