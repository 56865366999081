import { UKGSchedule } from '@bondvet/types/ukg';
import { gql } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import useSchedulingQuery from 'hooks/useSchedulingQuery';
import { UKGSchedulesArguments } from '@bondvet/types/scheduling';

const ukgSchedulesQuery = gql`
    query ukgSchedules {
        ukgSchedules {
            _id
            name
            display_name
            schedule_start
            schedule_end
            status
            scheduled_cost_centers {
                id
                name
                display_name
            }
        }
    }
`;

export interface UKGSchedulesQueryResult {
    ukgSchedules: ReadonlyArray<UKGSchedule>;
}

type UKGSchedules = {
    loading: boolean;
    error?: ApolloError;
    ukgSchedules: ReadonlyArray<UKGSchedule>;
    refetch: () => void;
};

export default function useUKGSchedules(
    vetspireLocationIds?: ReadonlyArray<string>,
    fromDate?: string,
    toDate?: string,
): UKGSchedules {
    const { data, loading, error, refetch } = useSchedulingQuery<
        UKGSchedulesQueryResult,
        UKGSchedulesArguments
    >(ukgSchedulesQuery, {
        variables: {
            vetspireLocationIds,
            fromDate,
            toDate,
        },
        fetchPolicy: 'cache-first',
    });

    return {
        loading,
        error,
        ukgSchedules: (data?.ukgSchedules || [])
            .slice(0)
            .sort((a, b) => b.name.localeCompare(a.name)),
        refetch,
    };
}
