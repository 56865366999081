import * as React from 'react';
import { PatientPlanFragment } from 'pages/PatientPlans/fragments';
import { decoratePatientPlan } from 'pages/PatientPlans/utils';
import { DecoratedPatientPlan } from 'pages/PatientPlans/types';
import { gql, useQuery } from '@apollo/client';
import type { PatientPlan } from '@bondvet/types/generated/vetspire';
import usePreventionPlans from './usePreventionPlans';
import useGlobalSettings from './useGlobalSettings';

const patientPlanQuery = gql`
    ${PatientPlanFragment}
    query patientPlan($id: ID!) {
        patientPlan(id: $id) {
            ...PatientPlanFragment
        }
    }
`;

interface PatientPlanQueryVariables {
    id: string;
}

interface PatientPlanQueryResult {
    patientPlan: PatientPlan | null;
}

interface UsePatientPlan {
    loading: boolean;
    patientPlan: DecoratedPatientPlan | null;
    refetch: () => void;
}

export default function usePatientPlan(patientPlanId: string): UsePatientPlan {
    const globalSettings = useGlobalSettings();

    const { preventionPlans } = usePreventionPlans();

    const { data, loading, refetch } = useQuery<
        PatientPlanQueryResult,
        PatientPlanQueryVariables
    >(patientPlanQuery, {
        variables: {
            id: patientPlanId,
        },
        fetchPolicy: 'cache-and-network',
    });

    const decoratedPatientPlan = React.useMemo(() => {
        return data?.patientPlan
            ? decoratePatientPlan(
                  globalSettings,
                  preventionPlans,
                  data.patientPlan,
              )
            : null;
    }, [data?.patientPlan, globalSettings, preventionPlans]);

    return {
        loading,
        refetch,
        patientPlan: decoratedPatientPlan,
    };
}
