import { gql } from '@apollo/client';
import useSukiMutation from 'hooks/useSukiMutation';
import {
    UpdateSukiAmbientSessionArguments,
    UpdateSukiAmbientSessionResult,
} from '@bondvet/types/suki';

const query = gql`
    mutation updateSukiAmbientSession(
        $_id: ID!
        $input: UpdateSukiAmbientSessionInput!
    ) {
        updateSukiAmbientSession(_id: $_id, input: $input) {
            success
            error
        }
    }
`;

export default function useUpdateSukiAmbientSessionMutation() {
    return useSukiMutation<
        UpdateSukiAmbientSessionResult,
        UpdateSukiAmbientSessionArguments
    >(query);
}
