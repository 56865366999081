import * as React from 'react';
import { gql } from '@apollo/client';
import type {
    RootMutationTypeUpdateAddressArgs,
    RootMutationTypeUpdateClientArgs,
} from '@bondvet/types/generated/vetspire';
import type {
    AddVetspireClientUpdatesArguments,
    VetspireClientUpdate,
} from '@bondvet/types/users';
import useBondMutation from './useBondMutation';
import useClientAndPatientId from './useClientAndPatientId';
import useRequestInterceptor, {
    InterceptorPayload,
    InterceptorResponse,
} from './useRequestInterceptor';
import useVetspireSettings from './useVetspireSettings';
import useTrackSubscriptionStatus, {
    ClientExternalCommPreferencesArgs,
} from './useTrackSubscriptionStatus';

const UPDATE_CLIENT_EXTERNAL_COMM_PREFERENCES_OPERATION_NAME =
    'updateClientExternalCommPreferences';
const UPDATE_ADDRESS_OPERATION_NAME = 'updateAddress';
const UPDATE_CLIENT_PHONE_NUMBERS_OPERATION_NAME = 'updateClientPhoneNumbers';
const UPDATE_CLIENT_INFO_OPERATION_NAME = 'updateClientInfo';

const UPDATE_DELAY_MS = 1000;

const addVetspireClientUpdatesMutation = gql`
    mutation addVetspireClientUpdates(
        $clientUpdates: [VetspireClientUpdateInput]
    ) {
        addVetspireClientUpdates(clientUpdates: $clientUpdates) {
            success
        }
    }
`;

export default function useClientUpdates(): void {
    const vetspireSettings = useVetspireSettings();
    const { clientId: urlClientId } = useClientAndPatientId();
    const trackSubscriptionStatus = useTrackSubscriptionStatus();

    const [pendingClientUpdates, setPendingClientUpdates] = React.useState<
        Record<
            string,
            Pick<
                VetspireClientUpdate,
                'clientId' | 'operationName' | 'updateCount'
            >
        >
    >({});

    const [addVetspireClientUpdates] =
        useBondMutation<AddVetspireClientUpdatesArguments>(
            addVetspireClientUpdatesMutation,
        );

    const triggerClientSync = React.useCallback(
        (operationName: string, clientId: string) => {
            setPendingClientUpdates((prev) => {
                const key = `${clientId}.${operationName}`;
                return {
                    ...prev,
                    [key]: {
                        clientId,
                        operationName,
                        updateCount: (prev[key]?.updateCount ?? 0) + 1,
                    },
                };
            });
        },
        [],
    );

    const timer = React.useRef<NodeJS.Timeout | null>(null);

    React.useEffect(() => {
        if (!timer.current && Object.values(pendingClientUpdates).length > 0) {
            timer.current = setTimeout(() => {
                timer.current = null;

                try {
                    // If the mutation fails, we do nothing to avoid interruptions in Vetspire.
                    addVetspireClientUpdates({
                        variables: {
                            clientUpdates: Object.values(pendingClientUpdates),
                        },
                    }).then(
                        () => {},
                        (error) => {
                            console.error(
                                'Error at addVetspireClientUpdates: ',
                                (error as Error)?.message,
                            );
                        },
                    );
                } catch (error) {
                    console.error(
                        'Error at addVetspireClientUpdates: ',
                        (error as Error)?.message,
                    );
                }

                setPendingClientUpdates({});
            }, UPDATE_DELAY_MS);
        }

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }
        };
    }, [addVetspireClientUpdates, pendingClientUpdates]);

    const onUpdateClientExternalCommPreferencesRequest = React.useCallback(
        ({
            operationName,
            variables,
        }: InterceptorPayload<ClientExternalCommPreferencesArgs>):
            | InterceptorResponse<ClientExternalCommPreferencesArgs>
            | Promise<
                  InterceptorResponse<ClientExternalCommPreferencesArgs>
              > => {
            triggerClientSync(operationName, variables.id);
            return trackSubscriptionStatus(variables);
        },
        [triggerClientSync, trackSubscriptionStatus],
    );

    const onUpdateAddressRequest = React.useCallback(
        async ({
            operationName,
            variables,
        }: InterceptorPayload<RootMutationTypeUpdateAddressArgs>): Promise<
            InterceptorResponse<RootMutationTypeUpdateAddressArgs>
        > => {
            if (urlClientId) {
                // The mutation variables do not contain the client id,
                // therefore we use the clientId in the URL (if available).

                triggerClientSync(operationName, urlClientId);
            }

            return {
                runOriginalRequest: true,
                newVariables: variables,
            };
        },
        [urlClientId, triggerClientSync],
    );

    const onUpdateClientRequest = React.useCallback(
        async ({
            operationName,
            variables,
        }: InterceptorPayload<RootMutationTypeUpdateClientArgs>): Promise<
            InterceptorResponse<RootMutationTypeUpdateClientArgs>
        > => {
            triggerClientSync(operationName, variables.id);

            return {
                runOriginalRequest: true,
                newVariables: variables,
            };
        },
        [triggerClientSync],
    );

    useRequestInterceptor<ClientExternalCommPreferencesArgs>(
        UPDATE_CLIENT_EXTERNAL_COMM_PREFERENCES_OPERATION_NAME,
        onUpdateClientExternalCommPreferencesRequest,
        undefined,
        !vetspireSettings.interceptClientUpdates,
    );

    useRequestInterceptor<RootMutationTypeUpdateAddressArgs>(
        UPDATE_ADDRESS_OPERATION_NAME,
        onUpdateAddressRequest,
        undefined,
        !vetspireSettings.interceptClientUpdates,
    );

    useRequestInterceptor<RootMutationTypeUpdateClientArgs>(
        UPDATE_CLIENT_PHONE_NUMBERS_OPERATION_NAME,
        onUpdateClientRequest,
        undefined,
        !vetspireSettings.interceptClientUpdates,
    );

    useRequestInterceptor<RootMutationTypeUpdateClientArgs>(
        UPDATE_CLIENT_INFO_OPERATION_NAME,
        onUpdateClientRequest,
        undefined,
        !vetspireSettings.interceptClientUpdates,
    );
}
