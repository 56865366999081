import {
    defaultCancellationSettings,
    CancellationSettingsQueryResult,
} from '@bondvet/types/patientPlans';
import type { CancellationSettings } from '@bondvet/types/patientPlans';
import { gql } from '@apollo/client';
import usePatientPlansQuery from 'hooks/usePatientPlansQuery';

const cancellationSettingsQuery = gql`
    query cancellationSettings {
        cancellationSettings {
            cancellationReasons {
                label
                value
                requiresExplanation
                obsolete
            }
        }
    }
`;

interface UseCancellationSettings {
    loading: boolean;
    cancellationSettings: CancellationSettings;
}

export default function useCancellationSettings(): UseCancellationSettings {
    const { loading, data } =
        usePatientPlansQuery<CancellationSettingsQueryResult>(
            cancellationSettingsQuery,
        );

    return {
        loading,
        cancellationSettings:
            data?.cancellationSettings || defaultCancellationSettings,
    };
}
