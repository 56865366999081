import { gql } from '@apollo/client';
import useLazySchedulingQuery from 'hooks/useLazySchedulingQuery';
import { QueryTuple } from '@apollo/client/react/types/types';
import {
    UKGRawLiveShiftsArguments,
    UKGRawLiveShiftsQueryResult,
} from '@bondvet/types/scheduling';

export const ukgRawLiveShiftsQuery = gql`
    query ukgRawLiveShifts(
        $vetspireLocationIds: [ID]!
        $fromDate: DateString!
        $toDate: DateString!
    ) {
        ukgRawLiveShifts(
            vetspireLocationIds: $vetspireLocationIds
            fromDate: $fromDate
            toDate: $toDate
        ) {
            _id
            account {
                account_id
            }
            date
            skill {
                id
                name
            }
            schedule_id
            shift_start
            shift_end
        }
    }
`;

export default function useLazyUKGRawLiveShifts(): QueryTuple<
    UKGRawLiveShiftsQueryResult,
    UKGRawLiveShiftsArguments
> {
    return useLazySchedulingQuery<
        UKGRawLiveShiftsQueryResult,
        UKGRawLiveShiftsArguments
    >(ukgRawLiveShiftsQuery, {
        fetchPolicy: 'no-cache',
    });
}
