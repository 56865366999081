import {
    useMutation,
    type DocumentNode,
    type OperationVariables,
    type MutationHookOptions,
    type MutationTuple,
    type TypedDocumentNode,
} from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from '../lib/constants';

export default function useBookingMutation<
    TData = unknown,
    TVariables extends OperationVariables = OperationVariables,
>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
    return useMutation<TData, TVariables>(mutation, {
        ...options,
        context: {
            ...options?.context,
            clientName: GRAPHQL_CLIENT_NAMES.booking,
        },
    });
}
