import * as React from 'react';
import { Props, Payload } from 'recharts/types/component/DefaultLegendContent';
import styles from './BarCard.module.scss';

export const renderCustomLegend = ({ payload }: Props): React.ReactNode => {
    return (
        <div className={styles.legend}>
            {payload?.map((data) => {
                const { dataKey, color, value } = data as Payload & {
                    dataKey: string;
                };
                return (
                    <div className={styles.legendItem} key={dataKey}>
                        {dataKey !== 'target' && (
                            <div
                                className={styles.legendItemIcon}
                                style={{ background: color }}
                            />
                        )}
                        {dataKey === 'target' && (
                            <div className={styles.legendItemIcon}>
                                <div
                                    className={styles.dashedLine}
                                    style={{
                                        borderTopColor: color,
                                    }}
                                />
                            </div>
                        )}
                        {value}
                    </div>
                );
            })}
        </div>
    );
};
