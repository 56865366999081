import * as React from 'react';
import { BonuslyFeedItemType } from 'api/bond/queries';
import LaunchIcon from '@mui/icons-material/Launch';
import styles from './BonuslyFeedItem.module.scss';

interface BonuslyFeedItemProps {
    bonuslyFeedItem: BonuslyFeedItemType;
}

export const BonuslyFeedItem: React.FunctionComponent<BonuslyFeedItemProps> = ({
    bonuslyFeedItem,
}) => {
    const content = React.useMemo(() => {
        // infiltrate the reason_html with our classes
        if (bonuslyFeedItem.reason_html) {
            const element = document.createElement('span');
            element.innerHTML = bonuslyFeedItem.reason_html;
            element.querySelectorAll('.inline-bonus-amount').forEach((e) => {
                e.className = styles.inlineBonusAmount;
            });
            element.querySelectorAll('.user-link').forEach((e) => {
                e.className = styles.userLink;
                e.setAttribute('target', '_blank');
            });
            element.querySelectorAll('.hashtag').forEach((e) => {
                e.className = styles.hashtag;
                e.setAttribute('target', '_blank');
            });
            element.querySelectorAll('.bonus-image-wrapper').forEach((e) => {
                e.className = styles.bonusImageWrapper;
            });
            element.querySelectorAll('.bonus-image').forEach((e) => {
                e.className = styles.bonusImage;
            });
            return element.outerHTML;
        }
        return '';
    }, [bonuslyFeedItem.reason_html]);

    // create the receiver items
    const receivers = React.useMemo(() => {
        return [...bonuslyFeedItem.receivers]
            .sort(
                (a, b) =>
                    bonuslyFeedItem.reason_html.indexOf(a.username) -
                    bonuslyFeedItem.reason_html.indexOf(b.username),
            )
            .map((receiver) => {
                return (
                    <div className={styles.iconCircle} key={receiver.username}>
                        <img
                            src={receiver.profile_pic_url}
                            alt={receiver.username}
                        />
                    </div>
                );
            });
    }, [bonuslyFeedItem.receivers, bonuslyFeedItem.reason_html]);

    return (
        <div className={styles.container}>
            <div className={styles.iconCircleList}>
                <div className={styles.iconCircle}>
                    <div>+{bonuslyFeedItem.amount}</div>
                </div>
                <div className={styles.iconCircleSubList}>{receivers}</div>
            </div>
            <a
                href={`https://bonus.ly/bonuses/${bonuslyFeedItem.id}`}
                className={styles.postLink}
                target="_blank"
                rel="noreferrer"
            >
                <LaunchIcon color="inherit" />
            </a>
            <div className={styles.contentContainer}>
                <span className={styles.giverName}>
                    {bonuslyFeedItem.giver.full_name}:
                </span>
                <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            </div>
        </div>
    );
};

export default BonuslyFeedItem;
