import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import Select, { SingleValue } from 'react-select';
import classnames from 'classnames';
import usePromoCodes, { PromoCodeOption } from './usePromoCodes';
import styles from './PromoCodeSelect.module.scss';
import { PROMO_CODE_NOTE_PREFIX } from '../../constants';
import addCreditMemoStyles from '../AddCreditMemo/AddCreditMemo.module.scss';
import { UpcomingVisitOption } from '../UpcomingVisitSelect/useUpcomingVisits';

type PromoCodeSelectProps = {
    skipQuery: boolean;
    selectedVisit: SingleValue<UpcomingVisitOption> | null;
    setNote: (value: string) => void;
    note: string;
    setAmount: (amount: string) => void;
    setPromoCodePatientTag: (value?: string | null) => void;
};

const INVALID_PROMO_CODES_SELECTOR = 'invalid-promo-codes-separator';

function PromoCodeSelect({
    skipQuery,
    selectedVisit,
    setNote,
    note,
    setAmount,
    setPromoCodePatientTag,
}: PromoCodeSelectProps): React.ReactElement {
    const translate = useTranslate();
    const {
        valid: validPromoCodeOptions,
        invalid: invalidPromoCodeOptions,
        isLoading,
    } = usePromoCodes(skipQuery, selectedVisit?.id);
    const [showDisabledPromoCodes, setShowDisabledPromoCodes] =
        React.useState(false);

    const promoCodeOptions: ReadonlyArray<PromoCodeOption> =
        React.useMemo(() => {
            if (invalidPromoCodeOptions.length === 0) {
                return validPromoCodeOptions;
            }
            if (showDisabledPromoCodes) {
                return [
                    ...validPromoCodeOptions,
                    {
                        key: INVALID_PROMO_CODES_SELECTOR,
                        label: '',
                        disabled: true,
                    },
                    ...invalidPromoCodeOptions,
                ];
            }
            return [
                ...validPromoCodeOptions,
                {
                    key: INVALID_PROMO_CODES_SELECTOR,
                    label: '',
                    disabled: true,
                },
            ];
        }, [
            invalidPromoCodeOptions,
            showDisabledPromoCodes,
            validPromoCodeOptions,
        ]);

    const selectedPromoCodeOption = React.useMemo(() => {
        if (
            promoCodeOptions.length > 0 &&
            note &&
            note.startsWith(PROMO_CODE_NOTE_PREFIX)
        ) {
            const codeFromNote = note.replace(PROMO_CODE_NOTE_PREFIX, '');

            const code =
                promoCodeOptions.find(
                    (option) => option.label === codeFromNote,
                ) ?? null;

            if (!code) {
                return invalidPromoCodeOptions.find(
                    (option) => option.label === codeFromNote,
                );
            }

            return code;
        }

        return null;
    }, [promoCodeOptions, note, invalidPromoCodeOptions]);

    React.useEffect(() => {
        // in case a client used a promo code while booking that is
        // not useable in the chrome extension we need to also show
        // the disabledPromoCodes for it to show up correctly in the select
        if (selectedPromoCodeOption && !selectedPromoCodeOption.valid) {
            setShowDisabledPromoCodes(true);
        }
    }, [selectedPromoCodeOption]);

    const handlePromoCodeChange = React.useCallback(
        (option: SingleValue<PromoCodeOption>) => {
            if (option) {
                setNote(`${PROMO_CODE_NOTE_PREFIX}${option.label}`);
                setAmount(option.amount ?? '');
                if (option.amountType === 'patientTag') {
                    setPromoCodePatientTag(option.patientTagId);
                } else {
                    setPromoCodePatientTag(null);
                }
            } else {
                setNote('');
            }
        },
        [setNote, setAmount, setPromoCodePatientTag],
    );

    const isPromoCodeOptionSelected = React.useCallback(
        (option: PromoCodeOption) => {
            return selectedPromoCodeOption?.label === option.label;
        },
        [selectedPromoCodeOption],
    );

    const onToggleDisabled = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowDisabledPromoCodes((last) => !last);
    };

    const formatPromoCodeOptionLabel = ({
        key,
        label,
        valid,
        errorReason,
    }: PromoCodeOption) => {
        if (key === INVALID_PROMO_CODES_SELECTOR) {
            return (
                <div className={styles.promoCodeSeparator}>
                    <button onClick={onToggleDisabled}>
                        {showDisabledPromoCodes ? 'Hide' : 'See'} codes that are
                        not valid for this visit
                    </button>
                </div>
            );
        }

        return (
            <div
                className={classnames(styles.promoCodeOption, {
                    [styles.invalid]: valid === false,
                })}
            >
                <span>{label}</span>
                {!valid && <span className={styles.error}>{errorReason}</span>}
            </div>
        );
    };

    return (
        <>
            <div className={addCreditMemoStyles.section}>
                {translate(
                    'vetspireExtension.clientDetails.credits.addCredits.promoCode',
                )}
            </div>

            <Select<PromoCodeOption>
                name="promoCode"
                className={styles.lookup}
                classNamePrefix="promo-code-select"
                value={selectedPromoCodeOption}
                onChange={handlePromoCodeChange}
                options={promoCodeOptions}
                isOptionSelected={isPromoCodeOptionSelected}
                noOptionsMessage={() =>
                    translate(
                        !skipQuery
                            ? 'vetspireExtension.clientDetails.credits.addCredits.noPromoCodes'
                            : 'vetspireExtension.clientDetails.credits.addCredits.visitNotSelected',
                    ) as string
                }
                formatOptionLabel={formatPromoCodeOptionLabel}
                required
                isLoading={isLoading}
                isOptionDisabled={({ disabled }) => !!disabled}
                placeholder={
                    <div className={styles.placeholder}>
                        Select Promo Code to apply ...
                    </div>
                }
            />
        </>
    );
}

export default PromoCodeSelect;
