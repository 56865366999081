import * as React from 'react';
import { CircularProgress } from '@mui/material';

import { CreditCard } from 'api/types';
import { TelehealthInput } from 'api/telehealth/queries';
import useTranslate from 'hooks/useTranslate';
import ActionButton from 'components/ActionButton';
import useClientCreditCardForm from 'pages/Telehealth/hooks/useCreditCardForm/useClientCreditCardForm';

import KnownCards from './KnownCards';
import NewCard from './NewCard';

import styles from '../NewVisitForm.module.scss';

interface PaymentMethodProps {
    clientId: string;
    handleChangeCreditCard: (value: string) => void;
    currentCardId: string;
    creditCards: ReadonlyArray<CreditCard>;
    isNewCard: boolean;
    formValid: boolean;
    telehealthInput?: TelehealthInput;
}

function PaymentMethod({
    clientId,
    handleChangeCreditCard,
    currentCardId,
    creditCards,
    isNewCard,
    formValid,
    telehealthInput,
}: PaymentMethodProps): React.ReactElement | null {
    const translate = useTranslate();
    const cardControls = useClientCreditCardForm(clientId);
    const {
        handleSubmitKnownCard,
        handleSubmitNewCard,
        processing,
        processingError,
        success,
    } = cardControls;

    const handleSubmit = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();

            if (!telehealthInput) return;

            if (isNewCard) handleSubmitNewCard(telehealthInput).then();
            else handleSubmitKnownCard(telehealthInput).then();
        },
        [
            isNewCard,
            telehealthInput,
            handleSubmitKnownCard,
            handleSubmitNewCard,
        ],
    );

    return (
        <>
            <span className={styles.flyoutSubtitle}>
                {translate(
                    creditCards.length > 0
                        ? 'vetspireExtension.telehealth.addClientToQueue.formFields.paymentMethod.cardOnFileLabel'
                        : 'vetspireExtension.telehealth.addClientToQueue.formFields.paymentMethod.newCardLabel',
                )}
            </span>
            <span className={styles.root}>
                <ul className={styles.cardsRadio}>
                    {isNewCard ? (
                        <NewCard cardControls={cardControls} />
                    ) : (
                        <KnownCards
                            currentCardId={currentCardId}
                            cards={creditCards}
                            handleChangeCreditCard={handleChangeCreditCard}
                        />
                    )}
                </ul>
            </span>
            <fieldset className={styles.container}>
                <ActionButton
                    className={styles.addToQueueButton}
                    type="submit"
                    disabled={!formValid || processing}
                    onClick={handleSubmit}
                >
                    {translate(
                        'vetspireExtension.telehealth.addClientToQueue.bookButton.text',
                    )}
                </ActionButton>
                {processingError && (
                    <p className={styles.errorText}>{processingError}</p>
                )}
                {processing && (
                    <CircularProgress className={styles.processing} />
                )}
                {success && (
                    <span className={styles.success}>
                        {translate(
                            'vetspireExtension.telehealth.addClientToQueue.success',
                        )}
                    </span>
                )}
            </fieldset>
        </>
    );
}

export default PaymentMethod;
