import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useTranslate } from '@bondvet/web-app-i18n/util';

import { ReactComponent as DownloadIcon } from '../../assets/download.svg';

import styles from './ImagePreviewModal.module.scss';

interface ImagePreviewModalProps {
    src: string;
    onClose: () => void;
}

export default function ImagePreviewModal({
    src,
    onClose,
}: ImagePreviewModalProps): React.ReactElement {
    const translate = useTranslate();

    return (
        <Dialog
            open
            classes={{
                paper: styles.modalContent,
            }}
        >
            <CloseIcon
                className={styles.close}
                onClick={() => {
                    onClose();
                }}
            />
            <DialogContent className={styles.imageContainer}>
                <img className={styles.image} src={src} alt={`image: ${src}`} />
            </DialogContent>
            <div className={styles.buttons}>
                <Button
                    className={styles.closeButton}
                    onClick={() => onClose()}
                >
                    {translate(
                        'vetspireExtension.texting.conversationPane.imagePreviewModal.close',
                    )}
                </Button>
                <Button className={styles.downloadButton}>
                    <a
                        className={styles.downloadText}
                        href={src}
                        download="image.jpg"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {translate(
                            'vetspireExtension.texting.conversationPane.imagePreviewModal.download',
                        )}
                        <DownloadIcon />
                    </a>
                </Button>
            </div>
        </Dialog>
    );
}
