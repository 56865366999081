import { gql } from '@apollo/client';
import useLazySukiQuery from 'hooks/useLazySukiQuery';
import { SukiGlobalSettingsQueryResult } from '@bondvet/types/suki';

const query = gql`
    query sukiGlobalSettings {
        sukiGlobalSettings {
            partnerId
            isTestMode
            encounterMappings {
                sukiTitle
                sectionTitle
                blockTitle
            }
            encounterNoteTypeId
            telephoneNoteTypeId
        }
    }
`;

export default function useLazySukiGlobalSettingsQuery() {
    return useLazySukiQuery<SukiGlobalSettingsQueryResult>(query, {
        fetchPolicy: 'cache-first',
    });
}
