import * as React from 'react';
import classnames from 'classnames';
import styles from './BlockOffLogEvents.module.scss';
import {
    APPOINTMENT_DATETIME_FORMAT,
    CREATE_DATETIME_FORMAT_SHORT,
    DeleteBlockOffEvent,
    EVENT_DATETIME_FORMAT,
} from '../../types';

export const Delete = ({
    event,
    sameDay,
}: {
    event: DeleteBlockOffEvent;
    sameDay: boolean;
}): React.ReactElement => (
    <div className={styles.textLine}>
        <div>
            <div className={classnames(styles.bullet, styles.bgCreated)} />
        </div>
        <div className={styles.textLeft}>
            <span className={styles.eventDatetime}>
                [{event.eventDatetime.format(EVENT_DATETIME_FORMAT)}]
            </span>
            <span>
                {event.cancelName} deleted{' '}
                {event.apptDatetime.format(
                    sameDay
                        ? CREATE_DATETIME_FORMAT_SHORT
                        : APPOINTMENT_DATETIME_FORMAT,
                )}
                -{event.apptEndDatetime.format(CREATE_DATETIME_FORMAT_SHORT)}{' '}
                &quot;
                {event.appointmentTypeName}&quot;
            </span>
        </div>
    </div>
);
export default Delete;
