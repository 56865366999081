import * as React from 'react';
import Select from 'react-select';
import { SurgeryType } from '@bondvet/types/surgeryReferrals';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useTranslate from 'hooks/useTranslate';
import styles from 'components/LookupSelect/LookupSelect.module.scss';
import useSurgeryTypes from '../../hooks/useSurgeryTypes';
import formStyles from '../QuestionForm/QuestionForm.module.scss';

type SelectSurgeryTypeProps = {
    value: SurgeryType | null;
    onChange: (surgeryType: SurgeryType | null) => void;
    label: React.ReactNode;
};

function getSurgeryTypeValue({ _id }: SurgeryType): string {
    return _id;
}

function getSurgeryTypeLabel({ name }: SurgeryType): string {
    return name;
}

export default function SelectSurgeryType({
    value,
    onChange,
    label,
}: SelectSurgeryTypeProps): React.ReactElement {
    const { loading, surgeryTypes } = useSurgeryTypes();
    const options = React.useMemo(
        () => surgeryTypes.slice().sort((a, b) => a.name.localeCompare(b.name)),
        [surgeryTypes],
    );

    const handleChange = React.useCallback(
        (surgeryType: SurgeryType | null) => {
            onChange(surgeryType);
        },
        [onChange],
    );

    const translate = useTranslate();

    return (
        <fieldset className={formStyles.container}>
            <label htmlFor="recommended-date" className={formStyles.label}>
                {label}
            </label>
            <Select
                isLoading={loading}
                name="surgery-type"
                options={options}
                value={value}
                getOptionValue={getSurgeryTypeValue}
                getOptionLabel={getSurgeryTypeLabel}
                onChange={handleChange}
                className={styles.lookup}
                classNamePrefix="lookup-select"
                placeholder={
                    <div className={styles.placeholder}>
                        <SearchOutlinedIcon
                            className={styles.placeholderIcon}
                        />
                        {translate(
                            'vetspireExtension.surgeryReferrals.lookups.surgeryTypes.placeholder',
                        )}
                    </div>
                }
            />
        </fieldset>
    );
}
