import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getVetspireAuthToken } from './vetspireAuth';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_VETSPIRE_API_URL,
    credentials: 'omit',
});

const authLink = setContext(async ({ variables }, { headers: prevHeaders }) => {
    const headers = {
        ...prevHeaders,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const context: Record<string, any> = { headers };

    if (variables?.__noAuth === true) {
        return context;
    }

    const vetspireAuthToken = getVetspireAuthToken();

    if (vetspireAuthToken) {
        headers.Authorization = `Bearer ${vetspireAuthToken}`;
    }

    return context;
});

export default authLink.concat(httpLink);
