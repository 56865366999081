import * as React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Moment } from 'moment';
import { AppointmentLog } from 'api/bond/queries';
import { getFallbackTimezone } from 'lib/utils';
import { openPatient } from 'lib/vetspireActions';
import styles from './AppointmentLogItem.module.scss';
import { AppointmentLogFilterType } from '../AppointmentLogFilter/types';
import {
    AppointmentLogCreate,
    AppointmentLogCancel,
    AppointmentLogNoShow,
    AppointmentLogRescheduled,
} from '../AppointmentLogEvents';
import {
    CancelAppointmentEvent,
    CreateAppointmentEvent,
    NoShowAppointmentEvent,
    RescheduledAppointmentEvent,
    TEAM_VETSPIRE_USERNAME,
    USER_TYPE_SELECTED_CLIENT,
    USER_TYPE_SELECTED_TEAM,
    UserTypeSelectOption,
} from '../../types';

interface ApptLogItemProps {
    appointmentLog: AppointmentLog;
    selectedDay: Moment;
    currentFilter: AppointmentLogFilterType;
    sameDayChangesOnly: boolean;
    selectedUserTypes: UserTypeSelectOption[];
}

const AppointmentLogItem: React.FunctionComponent<ApptLogItemProps> = ({
    appointmentLog,
    selectedDay,
    currentFilter,
    sameDayChangesOnly,
    selectedUserTypes,
}) => {
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        setExpanded(false);
    }, [currentFilter, sameDayChangesOnly]);

    const events = React.useMemo(() => {
        const timezone =
            appointmentLog.location?.timezone || getFallbackTimezone();
        const tz = (date: string) => {
            return moment.tz(date, timezone);
        };
        const sameDay = (date: string) => {
            const day1 = selectedDay.format('YYYY-MM-DD');
            const day2 = moment.tz(date, timezone).format('YYYY-MM-DD');
            return day1 === day2;
        };
        const userTypeSelected = (
            decoratedEvent:
                | CreateAppointmentEvent
                | RescheduledAppointmentEvent
                | CancelAppointmentEvent
                | NoShowAppointmentEvent,
        ) =>
            selectedUserTypes.length === 0 ||
            decoratedEvent.type === AppointmentLogFilterType.noShow ||
            expanded ||
            (decoratedEvent.cancelName === TEAM_VETSPIRE_USERNAME &&
                selectedUserTypes.find(
                    (selectedUserType) =>
                        selectedUserType.value === USER_TYPE_SELECTED_CLIENT,
                )) ||
            (decoratedEvent.cancelName !== TEAM_VETSPIRE_USERNAME &&
                selectedUserTypes.find(
                    (selectedUserType) =>
                        selectedUserType.value === USER_TYPE_SELECTED_TEAM,
                ));
        return appointmentLog.decoratedEvents
            .slice()
            .filter(
                (decoratedEvent) =>
                    ((!expanded &&
                        sameDayChangesOnly &&
                        sameDay(decoratedEvent.eventDatetime)) ||
                        expanded ||
                        !sameDayChangesOnly) &&
                    userTypeSelected(decoratedEvent),
            )
            .map((decoratedEvent) => {
                if (
                    decoratedEvent.type === AppointmentLogFilterType.create &&
                    expanded
                ) {
                    return (
                        <AppointmentLogCreate
                            key={decoratedEvent.id}
                            tz={tz}
                            event={decoratedEvent}
                        />
                    );
                }

                if (
                    decoratedEvent.type === AppointmentLogFilterType.cancelled
                ) {
                    const todayAndCurrentFilter =
                        (currentFilter === AppointmentLogFilterType.all ||
                            currentFilter ===
                                AppointmentLogFilterType.cancelled) &&
                        sameDay(decoratedEvent.apptDatetime);

                    if (expanded && todayAndCurrentFilter) {
                        return (
                            <AppointmentLogCancel
                                key={decoratedEvent.id}
                                tz={tz}
                                event={decoratedEvent}
                                highlighted
                            />
                        );
                    }
                    if (expanded || todayAndCurrentFilter) {
                        return (
                            <AppointmentLogCancel
                                key={decoratedEvent.id}
                                tz={tz}
                                event={decoratedEvent}
                            />
                        );
                    }
                }

                if (
                    decoratedEvent.type === AppointmentLogFilterType.rescheduled
                ) {
                    const todayAndCurrentFilter =
                        (currentFilter === AppointmentLogFilterType.all ||
                            currentFilter ===
                                AppointmentLogFilterType.rescheduled) &&
                        (sameDay(decoratedEvent.oldApptDatetime) ||
                            sameDay(decoratedEvent.newApptDatetime));

                    if (expanded && todayAndCurrentFilter) {
                        return (
                            <AppointmentLogRescheduled
                                key={decoratedEvent.id}
                                tz={tz}
                                event={decoratedEvent}
                                highlighted
                            />
                        );
                    }
                    if (expanded || todayAndCurrentFilter) {
                        return (
                            <AppointmentLogRescheduled
                                key={decoratedEvent.id}
                                tz={tz}
                                event={decoratedEvent}
                            />
                        );
                    }
                }

                if (decoratedEvent.type === AppointmentLogFilterType.noShow) {
                    const todayAndCurrentFilter =
                        (currentFilter === AppointmentLogFilterType.all ||
                            currentFilter ===
                                AppointmentLogFilterType.noShow) &&
                        sameDay(decoratedEvent.eventDatetime);
                    if (expanded && todayAndCurrentFilter) {
                        return (
                            <AppointmentLogNoShow
                                key={decoratedEvent.id}
                                tz={tz}
                                event={decoratedEvent}
                                highlighted
                            />
                        );
                    }
                    if (expanded || todayAndCurrentFilter) {
                        return (
                            <AppointmentLogNoShow
                                key={decoratedEvent.id}
                                tz={tz}
                                event={decoratedEvent}
                            />
                        );
                    }
                }

                return null;
            })
            .filter((decoratedEvent) => decoratedEvent != null);
    }, [
        appointmentLog.decoratedEvents,
        expanded,
        currentFilter,
        appointmentLog.location?.timezone,
        selectedDay,
        sameDayChangesOnly,
        selectedUserTypes,
    ]);

    if (events.length === 0) {
        return null;
    }

    return (
        <div
            className={styles.container}
            tabIndex={0}
            role="button"
            onKeyDown={() => {}}
            onClick={() => {
                if (appointmentLog.vetspireUserId) {
                    openPatient(appointmentLog.vetspireUserId);
                }
            }}
        >
            <div className={styles.content}>
                <div className={styles.header}>
                    <div
                        className={classnames(
                            styles.headerLeft,
                            styles.smallText,
                        )}
                    >
                        {`${appointmentLog.firstName || '?'} ${
                            appointmentLog.lastName || '?'
                        } > ${appointmentLog.pet || '?'}`}
                    </div>
                    <div
                        className={classnames(
                            styles.headerRight,
                            styles.smallText,
                        )}
                    >
                        {appointmentLog.location?.name || ''}
                    </div>
                </div>

                {events}

                <div
                    className={styles.showMoreContainer}
                    onClick={(event) => {
                        event.stopPropagation();
                        setExpanded(!expanded);
                    }}
                    role="button"
                    tabIndex={-1}
                    onKeyDown={() => {}}
                >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                    <span>{expanded ? 'Show less' : 'Show more'}</span>
                </div>
            </div>
        </div>
    );
};

export default AppointmentLogItem;
