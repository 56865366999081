import * as React from 'react';

import useTranslate from 'hooks/useTranslate';
import useClientAndPatientId from 'hooks/useClientAndPatientId';
import classnames from 'classnames';

import styles from './Telehealth.module.scss';
import CurrentQueue from './components/CurrentQueue';
import AddClientToQueue from './components/AddClientToQueue';

function Telehealth(): React.ReactElement {
    const translate = useTranslate();
    const { clientId, patientId } = useClientAndPatientId();

    return (
        <div className={classnames(styles.flyout, styles.container)}>
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.telehealth.title')}
                </div>
            </div>
            <CurrentQueue />
            <AddClientToQueue
                clientId={clientId || undefined}
                patientId={patientId ?? undefined}
            />
        </div>
    );
}

export default Telehealth;
