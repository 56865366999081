import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import Tabs from 'components/Tabs';
import useClientAndPatientId from 'hooks/useClientAndPatientId';
import { ProviderRightValue } from '@bondvet/types/providers';
import styles from './SurgeryReferrals.module.scss';
import { SurgeryReferralTab } from './types';
import SendRecommendation from './components/SendRecommendation';
import useViewerSettings from '../../hooks/useViewerSettings';
import ReferralsList from './components/ReferralsList';
import Checklist from './components/Checklist';

const TABS: ReadonlyArray<SurgeryReferralTab> = [
    'send',
    'referrals',
    'checklist',
];

export default function SurgeryReferrals(): React.ReactElement {
    const translate = useTranslate();
    const { clientId, patientId } = useClientAndPatientId();

    const { rights } = useViewerSettings();

    const showTabs = React.useMemo(
        () =>
            rights?.vetspireExtension_surgeryReferrals ===
            ProviderRightValue.enabled_allRecords,
        [rights],
    );

    const [currentTab, setCurrentTab] =
        React.useState<SurgeryReferralTab>('send');
    React.useEffect(() => {
        // set initial tab to referrals for SX concierges
        if (showTabs) {
            setCurrentTab('referrals');
        }
    }, [showTabs]);

    const changeCurrentTab = React.useCallback((tab: string) => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setCurrentTab(tab as SurgeryReferralTab);
            });
        });
    }, []);

    const tabs = React.useMemo(
        () =>
            TABS.map((name) => ({
                name,
                label: translate(
                    `vetspireExtension.surgeryReferrals.tabs.${name}`,
                ),
            })),
        [translate],
    );

    return (
        <div className={styles.flyout}>
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.surgeryReferrals.title')}
                </div>
            </div>
            <div
                className={classnames(
                    styles.flyoutBlockFullWidth,
                    styles.noHorizontal,
                )}
            >
                {showTabs && (
                    <Tabs
                        currentTab={currentTab}
                        onChange={changeCurrentTab}
                        tabs={tabs}
                    />
                )}
            </div>
            <div
                className={classnames(styles.flyoutBlock, styles.flyoutContent)}
            >
                {currentTab === 'send' && (
                    <SendRecommendation
                        clientId={clientId}
                        patientId={patientId}
                    />
                )}
                {currentTab === 'referrals' && (
                    <ReferralsList patientId={patientId} />
                )}
                {currentTab === 'checklist' && (
                    <Checklist onChangeTab={changeCurrentTab} />
                )}
            </div>
        </div>
    );
}
