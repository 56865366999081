import {
    CREDIT_MEMO_OPTION_CASH,
    CreditBucket,
    type CreditMemoNoteOption,
    type SelectableCreditBucket,
} from './index';

export const CREDIT_BUCKET_NAMES: Record<CreditBucket, string> = {
    [CreditBucket.promoCode]: 'Promo Codes',
    [CreditBucket.courtesy]: 'Courtesy',
    [CreditBucket.other]: 'Other',
    [CreditBucket.notGoodwill]: 'Not Goodwill',
};

export const CREDIT_MEMO_NOTES_MAP: Record<
    SelectableCreditBucket | typeof CREDIT_MEMO_OPTION_CASH,
    string
> = {
    [CreditBucket.promoCode]: CREDIT_BUCKET_NAMES[CreditBucket.promoCode],
    [CreditBucket.courtesy]: CREDIT_BUCKET_NAMES[CreditBucket.courtesy],

    [CREDIT_MEMO_OPTION_CASH]: 'Overpayment/Cash/Not Enough Change',
};

export const CREDIT_MEMO_NOTE_OPTIONS: ReadonlyArray<CreditMemoNoteOption> =
    Object.entries(CREDIT_MEMO_NOTES_MAP).reduce<
        ReadonlyArray<CreditMemoNoteOption>
    >(
        (aggregator, [value, label]) => [
            ...aggregator,
            { value: value as SelectableCreditBucket, label },
        ],
        [] as CreditMemoNoteOption[],
    );

/**
 * realtime DB path for credit ledger exports
 */
export const CREDIT_LEDGER_REPORTS_PATH = 'creditLedger/reports';

/**
 * path prefix for credit ledger reports in Cloud Storage
 */
export const CREDIT_LEDGER_REPORTS_STORAGE_PATH = 'creditLedger/reports';

export const VETSPIRE_LEDGER_ENTRIES_COLLECTION_NAME = 'vetspire.ledgerEntries';

export const VETSPIRE_PAYMENT_METHOD_TYPE_GOODWILL_CREDIT = 'goodwill_credit';
