import * as React from 'react';
import { gql } from '@apollo/client';
import { formatInTimeZone } from 'date-fns-tz';
import useVetspireQuery from 'hooks/useVetspireQuery';
import { getFallbackTimezone } from 'lib/utils';

type AppointmentPreview = {
    date: string;
    time: string;
    locationName: string;
    providerName: string | null;
};
type RescheduledAppointmentPreview = {
    appointment: null | AppointmentPreview;
    loading: boolean;
};

const currentAppointmentQuery = gql`
    query currentAppointmentData($appointmentId: ID!) {
        appointment(id: $appointmentId) {
            start
            location {
                name
                timezone
            }
            provider {
                name
            }
        }
    }
`;

const providerQuery = gql`
    query provider($providerId: ID!) {
        provider(id: $providerId) {
            name
        }
    }
`;

type CurrentAppointmentQueryResult = {
    appointment: {
        start: string;
        location: {
            name: string;
            timezone: string;
        };
        provider: null | {
            name: string;
        };
    };
};

type CurrentAppointmentQueryVariables = {
    appointmentId: string;
};

type ProviderQueryResult = {
    provider: {
        name: string;
    };
};

type ProviderQueryVariables = {
    providerId: string;
};

type AppointmentInput = {
    providerId?: string | null;
    scheduleId?: string | null;
    start?: string | null;
};

type OriginalQueryVariables = {
    id: string;
    input: AppointmentInput;
};

export default function useRescheduledAppointmentPreview(
    // will be set to `true` if the mode isn't rescheduling
    skip: boolean,
    appointmentId: string | null,
    originalQueryVariables: string | null,
): RescheduledAppointmentPreview {
    const input = React.useMemo<AppointmentInput | null>(() => {
        if (originalQueryVariables) {
            return (
                JSON.parse(originalQueryVariables) as OriginalQueryVariables
            ).input;
        }
        return null;
    }, [originalQueryVariables]);
    const {
        loading: isLoadingAppointmentData,
        data: currentAppointmentQueryData,
    } = useVetspireQuery<
        CurrentAppointmentQueryResult,
        CurrentAppointmentQueryVariables
    >(currentAppointmentQuery, {
        skip: skip || !appointmentId,
        variables: { appointmentId: appointmentId ?? '' },
        fetchPolicy: 'no-cache',
    });
    const { loading: isLoadingProvider, data: providerQueryData } =
        useVetspireQuery<ProviderQueryResult, ProviderQueryVariables>(
            providerQuery,
            {
                skip: skip || !input?.providerId,
            },
        );

    const loading = isLoadingProvider || isLoadingAppointmentData;
    const appointment = React.useMemo<null | AppointmentPreview>(() => {
        if (loading || !currentAppointmentQueryData?.appointment) {
            return null;
        }

        const currentAppointment = currentAppointmentQueryData.appointment;

        const newStart = input?.start || currentAppointment.start;
        const date = new Date(newStart);

        const timezone =
            currentAppointment.location?.timezone ?? getFallbackTimezone();

        let providerName = currentAppointment.provider?.name ?? null;

        if (input?.providerId) {
            providerName = providerQueryData?.provider?.name ?? null;
        } else if (input?.scheduleId || input?.providerId === null) {
            // provider gets deleted
            providerName = null;
        }

        return {
            date: formatInTimeZone(date, timezone, 'cccc, MMMM d'),
            time: formatInTimeZone(date, timezone, 'h:mm aa'),
            locationName: currentAppointment.location?.name ?? '',
            providerName,
        };
    }, [
        loading,
        currentAppointmentQueryData?.appointment,
        input,
        providerQueryData?.provider,
    ]);

    return {
        loading,
        appointment,
    };
}
