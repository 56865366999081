import * as React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Moment } from 'moment';

import styles from './DaySelect.module.scss';

interface DaySelectProps {
    day: Moment;
    onChange: (newDay: Moment) => void;
}

const DaySelect: React.FunctionComponent<DaySelectProps> = ({
    day,
    onChange,
}) => {
    return (
        <div className={styles.container}>
            <button
                type="button"
                className={styles.buttonBack}
                onClick={() => {
                    onChange(day.clone().subtract({ days: 1 }));
                }}
            >
                <ArrowBackIosIcon fontSize="small" />
            </button>
            <div className={styles.date}>{day.format('MM/D/YYYY')}</div>
            <button
                type="button"
                className={styles.buttonForward}
                onClick={() => {
                    onChange(day.clone().add({ days: 1 }));
                }}
            >
                <ArrowBackIosIcon fontSize="small" />
            </button>
        </div>
    );
};

export default DaySelect;
