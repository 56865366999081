import { gql } from '@apollo/client';
import { SurgeryForm } from '@bondvet/types/surgeryForms';

import {
    VetspireProviderWithRights,
    GraphQLVetspireProvider,
} from '@bondvet/types/providers';
import { Location, LocationSchedule } from '@bondvet/types/locations';
import {
    CancelAppointmentEvent,
    ChangeAppointmentTypeNameBlockOffEvent,
    ChangeDurationBlockOffEvent,
    ChangeProviderNameBlockOffEvent,
    ChangeStartBlockOffEvent,
    CreateAppointmentEvent,
    CreateBlockOffEvent,
    DeleteBlockOffEvent,
    NoShowAppointmentEvent,
    RescheduledAppointmentEvent,
} from 'pages/AppointmentLog/types';
import { FeeProduct } from '@bondvet/types/noShows';
import { MenuBarSettings } from '@bondvet/types/menuBar';
import { CommonLink } from '@bondvet/types/commonLinks';
import { Reference } from '@bondvet/types/creditLedger';
import { Region } from '@bondvet/types/analytics/index';

export type Token = {
    token: string;
};

export type ExchangeVetspireAuthTokenQueryResult = {
    exchangeVetspireAuthToken: Token;
};

export type Shift = {
    _id: string;
    confirmed: boolean;
    negative: boolean;
    firstName: string;
    lastName: string;
    firstResponse?: string;
    responseMessages?: ReadonlyArray<string>;
};

export type AppointmentLog = {
    _id: string;
    date: string;
    location?: {
        _id?: string;
        name?: string;
        timezone?: string;
    };
    firstName?: string;
    lastName?: string;
    locationId: string;
    vetspireUserId?: string;
    pet?: string;
    vetspireScheduleName?: string;
    decoratedEvents: (
        | CreateAppointmentEvent
        | RescheduledAppointmentEvent
        | CancelAppointmentEvent
        | NoShowAppointmentEvent
    )[];
    events?: AppointmentLogEvent[];
};

export type BlockOffLog = {
    _id: string;
    date: string;
    appointmentTypeName: string;
    location?: {
        _id?: string;
        name?: string;
        timezone?: string;
    };
    locationId: string;
    events?: BlockOffLogEvent[];
    decoratedEvents: (
        | CreateBlockOffEvent
        | ChangeStartBlockOffEvent
        | ChangeDurationBlockOffEvent
        | ChangeProviderNameBlockOffEvent
        | ChangeAppointmentTypeNameBlockOffEvent
        | DeleteBlockOffEvent
    )[];
};

export type AppointmentLogEvent = {
    id: string;
    type: string;
    datetime: string;
    metadata?: {
        schedule_id?: string;
        provider_id?: string;
        status?: string;
        start?: string;
    };
    provider: {
        id: string;
        name: string;
    };
};

export type BlockOffLogEvent = {
    id: string;
    type: string;
    datetime: string;
    metadata?: {
        schedule_id?: string;
        provider_id?: string;
        status?: string;
        start?: string;
        duration?: number;
        appointmentTypeName?: string;
    };
    provider: {
        id: string;
        name: string;
    };
};

export type ShiftsOfDayQueryResult = {
    shiftsOfDay: ReadonlyArray<Shift>;
};

export type ShiftsOfDayQueryVariables = {
    day: string;
    locationIds: ReadonlyArray<string>;
};

export type AppointmentLogQueryResult = {
    appointmentLog: ReadonlyArray<AppointmentLog>;
};

export type BlockOffLogQueryResult = {
    blockOffLog: ReadonlyArray<BlockOffLog>;
};

export type BonuslyFeedItemType = {
    id: string;
    amount: number;
    reason_html: string;
    giver: {
        profile_pic_url: string;
        full_name: string;
    };
    receivers: [
        {
            profile_pic_url: string;
            username: string;
        },
    ];
};
export type BonuslyQueryResult = {
    bonuslyFeed: ReadonlyArray<BonuslyFeedItemType>;
};

export type BonuslyQueryVariables = {
    page: number;
};

export type AppointmentLogQueryVariables = {
    day: string;
    locationIds: ReadonlyArray<string>;
};

export type BlockOffLogQueryVariables = {
    day: string;
    locationIds: ReadonlyArray<string>;
};

export const exchangeVetspireAuthToken = gql`
    query exchangeVetspireAuthToken($token: String!) {
        exchangeVetspireAuthToken(token: $token) {
            token
        }
    }
`;

export const tokenWithoutRoles = gql`
    query tokenWithoutRoles {
        tokenWithoutRoles {
            token
        }
    }
`;

export const ShiftFragment = gql`
    fragment ShiftFragment on Shift {
        _id
        confirmed
        negative
        firstName
        lastName
        firstResponse
        responseMessages
    }
`;

export const shiftsOfDay = gql`
    ${ShiftFragment}
    query shiftsOfDay($day: DateString!, $locationIds: [String]) {
        shiftsOfDay(day: $day, locationIds: $locationIds) {
            ...ShiftFragment
        }
    }
`;

export const appointmentLogQuery = gql`
    query appointmentLog($day: DateString!, $locationIds: [String]) {
        appointmentLog(day: $day, locationIds: $locationIds) {
            _id
            firstName
            lastName
            date
            pet
            vetspireScheduleName
            locationId
            vetspireUserId
            events {
                id
                type
                datetime
                metadata {
                    schedule_id
                    provider_id
                    status
                    start
                }
                provider {
                    id
                    name
                }
            }
        }
    }
`;
export const blockOffLogQuery = gql`
    query blockOffLog($day: DateString!, $locationIds: [String]) {
        blockOffLog(day: $day, locationIds: $locationIds) {
            _id
            locationId
            appointmentTypeName
            events {
                id
                type
                datetime
                metadata {
                    schedule_id
                    provider_id
                    status
                    duration
                    start
                    appointmentTypeName
                }
                provider {
                    id
                    name
                }
            }
        }
    }
`;

export const bonuslyQuery = gql`
    query bonuslyFeed($page: Int!) {
        bonuslyFeed(page: $page) {
            id
            amount
            reason_html
            giver {
                profile_pic_url
                full_name
            }
            receivers {
                profile_pic_url
                username
            }
        }
    }
`;

export const appointmentLogLocationQuery = gql`
    query locations {
        locations {
            _id
            name
            timezone
        }
    }
`;
export const multipleLocationSchedulesQuery = gql`
    query multipleLocationSchedules(
        $locationIds: [String]
        $vetspireLocationIds: [String]
    ) {
        multipleLocationSchedules(
            locationIds: $locationIds
            vetspireLocationIds: $vetspireLocationIds
        ) {
            _id
            name
            locationId
            vetspireLocationId
            isNurseAppointment
            isProcedure
        }
    }
`;

export type MultipleLocationSchedulesQueryResult = {
    multipleLocationSchedules: ReadonlyArray<LocationSchedule>;
};

export type SurgeryFormsQueryResult = {
    surgeryForms: ReadonlyArray<SurgeryForm>;
};

export const surgeryFormsQuery = gql`
    query {
        surgeryForms {
            _id
            name
        }
    }
`;

export interface SurgeryFormsOption {
    _id: string;
    renderedContent: string;
}

export type SurgeryFormsOptionsQueryResult = {
    surgeryFormsOptions: ReadonlyArray<SurgeryFormsOption>;
};

export const surgeryFormsOptionsQuery = gql`
    query surgeryFormsOptions($encounterId: String!) {
        surgeryFormsOptions(encounterId: $encounterId) {
            _id
            renderedContent
        }
    }
`;

export interface SurgeryFormPdfQueryVariables {
    surgeryFormId: string;
    encounterId: string;
    medicationIds?: string[];
    note?: string | null;
    optionsId?: string | null;
}

export type SurgeryFormPdfQueryResult = {
    surgeryFormPdf: {
        base64Content: string;
        medicationPage: number;
        recheckPage: number;
    };
};

export const surgeryFormPdfQuery = gql`
    query surgeryFormPdf(
        $surgeryFormId: ID!
        $encounterId: String!
        $medicationIds: [String]
        $optionsId: String
        $note: String
    ) {
        surgeryFormPdf(
            surgeryFormId: $surgeryFormId
            input: {
                encounterId: $encounterId
                medicationIds: $medicationIds
                optionsId: $optionsId
                note: $note
            }
        ) {
            base64Content
            medicationPage
            recheckPage
        }
    }
`;

export interface VetspireProviderQueryResult {
    vetspireProvider: VetspireProviderWithRights;
}

export const vetspireProvider = gql`
    query vetspireProvider($providerId: ID!) {
        vetspireProvider(providerId: $providerId) {
            id
            rights {
                vetspireExtension_tasks
                vetspireExtension_surgeryForms
                vetspireExtension_encounters
                vetspireExtension_creditCards
                vetspireExtension_chargeNoShowFees
                vetspireExtension_staffOptimizationUKG
                vetspireExtension_providerPayment
                vetspireExtension_gainsharing
                vetspireExtension_apptLog
                vetspireExtension_bonusly
                vetspireExtension_infoPdf
                vetspireExtension_surgeryCalendar
                vetspireExtension_surgeryReferrals
                vetspireExtension_externalReferrals
                vetspireExtension_medicalMishaps
                vetspireExtension_telehealth
                vetspireExtension_suki
                vetspireExtension_texting
                vetspireExtension_patientPlans
                vetspireExtension_travelCertificates
                vetspireExtension_intradayCapacity
            }
            paymentPin
        }
    }
`;

export interface VetspireProvidersQueryResult {
    vetspireProviders: GraphQLVetspireProvider[];
}

export const vetspireProviders = gql`
    query vetspireProviders {
        vetspireProviders {
            id
            name
            canDoSurgeries
        }
    }
`;

export const locationsQuery = gql`
    query {
        locations {
            _id
            name
            timezone
            showStaffOptimization
            _vetspire {
                id
            }
            _humanity {
                id
            }
            _ukg {
                costCenterId
            }
        }
    }
`;

export interface LocationsQueryResult {
    locations: ReadonlyArray<Location>;
}

export const regionsQuery = gql`
    query {
        getRegions {
            _id
            name
            locationIds
        }
    }
`;

export interface RegionsQueryResult {
    getRegions: ReadonlyArray<Region>;
}

export const feeProductsQuery = gql`
    query feeProducts($vetspireLocationId: ID) {
        feeProducts(vetspireLocationId: $vetspireLocationId) {
            _id
            price
        }
    }
`;

export interface FeeProductsQueryResult {
    feeProducts: FeeProduct[];
}

export const extensionMenuBarSettings = gql`
    query extensionMenuBarSettings($userId: ID!) {
        extensionMenuBarSettings(userId: $userId) {
            userId
            menuItemList
        }
    }
`;

export interface MenuBarSettingsResult {
    extensionMenuBarSettings: MenuBarSettings;
}

export const creditTransactions = gql`
    query creditTransactions($clientId: String!) {
        creditTransactions(clientId: $clientId) {
            _id
            amount
            date
            notes
            references {
                providerId
                creditMemoId
            }
            vetspireClientId
        }
    }
`;

export interface CreditTransactionQueryResult {
    creditTransactions: ReadonlyArray<Credit>;
}

export const clientAccountCredit = gql`
    query clientAccountCredit($clientId: ID!) {
        client(id: $clientId) {
            accountCredit
        }
    }
`;

export interface ClientAccountCreditQueryResult {
    client: null | {
        accountCredit: string;
    };
}

export interface ClientAccountCreditQueryVariables {
    clientId: string;
}

export interface BaseCreditTransaction<DecimalType, DateType> {
    _id: string;
    amount: DecimalType;
    date: DateType | null;
    notes: string;
    references: ReadonlyArray<Reference>;
    vetspireClientId: string;
}

export type RawCredit = BaseCreditTransaction<string, string>;

export type Credit = BaseCreditTransaction<number, string>;

export interface ProviderPaymentMonthReportQueryVariables {
    vetspireProviderId: string;
    month: string;
}

export interface ProviderPaymentMonthReportQueryResult {
    providerPaymentMonthReport: {
        fileName: string;
        base64Content: string;
    };
}

export const providerPaymentMonthReportQuery = gql`
    query providerPaymentMonthReport(
        $vetspireProviderId: ID!
        $month: String!
    ) {
        providerPaymentMonthReport(
            vetspireProviderId: $vetspireProviderId
            month: $month
        ) {
            fileName
            base64Content
        }
    }
`;

export interface CommonLinksQueryVariables {
    id?: string;
}
export interface CommonLinksQueryResult {
    commonLinks: readonly CommonLink[];
}

export const commonLinksQuery = gql`
    query commonLinks($id: ID) {
        commonLinks(id: $id) {
            _id
            link
            name
        }
    }
`;

export const patientTagsQuery = gql`
    query patientTags($patientId: ID!) {
        patient(id: $patientId) {
            tags {
                id
            }
        }
    }
`;

export interface PatientTagsQueryResult {
    patient: null | {
        tags: ReadonlyArray<{ id: string }>;
    };
}

export interface PatientTagsQueryVariables {
    patientId: string;
}
