import * as React from 'react';
import { Translated } from '@bondvet/web-app-i18n/util';
import styles from './GlossarySection.module.scss';

interface HeaderProps {
    title: Translated;
    subtitle?: Translated;
    children?: React.ReactElement[] | React.ReactElement;
}

export default function GlossarySection({
    title,
    subtitle,
    children,
}: HeaderProps) {
    return (
        <>
            <div className={styles.container}>
                <>
                    <span className={styles.title}>{title}</span>{' '}
                    <span className={styles.subtitle}>{subtitle}</span>
                </>
            </div>

            <div className={styles.childrenContainer}>{children}</div>
        </>
    );
}
