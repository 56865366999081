import * as React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { SvgIconProps } from '@mui/material';
import type { Brand as BaseBrand } from 'api/types';
import { ReactComponent as VisaIcon } from './assets/visa.svg';
import { ReactComponent as MasterCardIcon } from './assets/mastercard.svg';
import { ReactComponent as AmexIcon } from './assets/amex.svg';
import { ReactComponent as DiscoverIcon } from './assets/discover.svg';
import { ReactComponent as DinersIcon } from './assets/diners.svg';
import { ReactComponent as JcbIcon } from './assets/jcb.svg';
import { ReactComponent as UnionpayIcon } from './assets/unionpay.svg';

export type Brand =
    | BaseBrand
    | import('@stripe/stripe-js').StripeCardElementChangeEvent['brand'];

export type CreditCardBrandProps = {
    brand: Brand;
} & (React.SVGProps<SVGSVGElement> | SvgIconProps);

function CreditCardBrand({
    brand = 'unknown',
    ...props
}: CreditCardBrandProps): React.ReactElement {
    /* eslint-disable react/jsx-props-no-spreading */

    switch (brand) {
        case 'visa':
        case 'Visa':
            return <VisaIcon {...props} />;
        case 'mastercard':
        case 'MasterCard':
            return <MasterCardIcon {...props} />;
        case 'amex':
        case 'American Express':
            return <AmexIcon {...props} />;
        case 'discover':
        case 'Discover':
            return <DiscoverIcon {...props} />;
        case 'diners':
        case 'Diners Club':
            return <DinersIcon {...props} />;
        case 'jcb':
        case 'JCB':
            return <JcbIcon {...props} />;
        case 'unionpay':
        case 'UnionPay':
            return <UnionpayIcon {...props} />;
        default:
            return <CreditCardIcon {...(props as SvgIconProps)} />;
    }
}

export default CreditCardBrand;
