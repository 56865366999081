import useProviders from 'hooks/useProviders';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import {
    bondLocationQuery,
    surgeryCalendar,
    SurgeryCalendarQueryResult,
} from 'api/clients/queries';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import moment from 'moment-timezone';
import { getFallbackTimezone } from 'lib/utils';
import sumBy from 'lodash/sumBy';
import range from 'lodash/range';
import uniqBy from 'lodash/uniqBy';
import type { Provider } from 'api/providers/queries';
import { Location } from '@bondvet/types/locations';
import useSurgeryCalendarQuery from 'hooks/useSurgeryCalendarQuery';
import { SurgeryCalendarQueryArguments } from '@bondvet/types/surgeryCalendar';
import { SurgeryCalendarOptions } from '../types';

export type Shift = {
    providers: ReadonlyArray<Provider> | null;
    surgeries: {
        _id: string;
        locationId: string;
        date: Date;
        appointmentTypeId: string;
        appointmentTypeName: string;
        color: string;
        open: boolean;
    }[];
};

export type BondLocationWithShiftData = {
    location?: Location;
    utilization: number;
    totalCapacity: number;
    capacityUsed: number;
    shifts: {
        [date: string]: Shift;
    };
};

export type SurgeryCalendarsData = {
    loading?: boolean;
    error?: string;
    locationsWithShiftData?: ReadonlyArray<BondLocationWithShiftData>;
    doctors?: ReadonlyArray<Provider>;
};

const getColor = (isOpen: boolean) => (isOpen ? '#48D2A0' : '#10365A');

const useSurgeryCalendarsData = ({
    week,
    vetspireLocationIds,
    openSlots,
    surgeries,
    doctors,
    dentals,
}: SurgeryCalendarOptions): SurgeryCalendarsData => {
    const bondLocationsQueryResult = useQuery<{
        locations: ReadonlyArray<Location>;
    }>(bondLocationQuery, {
        fetchPolicy: 'network-only',
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    const providersQueryResult = useProviders();

    const surgeryCalendarQueryResult = useSurgeryCalendarQuery<
        SurgeryCalendarQueryResult,
        SurgeryCalendarQueryArguments
    >(surgeryCalendar, {
        fetchPolicy: 'no-cache',
        variables: {
            vetspireLocationIds,
            fromDate: week.format('YYYY-MM-DD'),
            toDate: week.clone().add(7, 'days').format('YYYY-MM-DD'),
        },
    });

    return React.useMemo<SurgeryCalendarsData>(() => {
        if (bondLocationsQueryResult.error) {
            return {
                error: bondLocationsQueryResult.error.message,
            };
        }

        if (providersQueryResult.error) {
            return {
                error: providersQueryResult.error.message,
            };
        }

        if (surgeryCalendarQueryResult.error) {
            return {
                error: surgeryCalendarQueryResult.error.message,
            };
        }

        if (
            bondLocationsQueryResult.loading ||
            providersQueryResult.loading ||
            surgeryCalendarQueryResult.loading
        ) {
            return { loading: true };
        }

        if (
            surgeryCalendarQueryResult.data?.surgeryCalendar &&
            bondLocationsQueryResult.data?.locations
        ) {
            const appointments =
                surgeryCalendarQueryResult.data.surgeryCalendar.appointments
                    .map(
                        ({
                            _id,
                            locationId,
                            date,
                            appointmentTypeId,
                            appointmentTypeName,
                        }) => ({
                            _id,
                            locationId,
                            date,
                            appointmentTypeId,
                            appointmentTypeName,
                            open: false,
                        }),
                    )
                    .concat(
                        surgeryCalendarQueryResult.data.surgeryCalendar.blockOffs.map(
                            ({
                                _id,
                                locationId,
                                date,
                                appointmentTypeId,
                                appointmentTypeName,
                            }) => ({
                                _id,
                                locationId,
                                date,
                                appointmentTypeId,
                                appointmentTypeName: `Open ${appointmentTypeName}`,
                                open: true,
                            }),
                        ),
                    )
                    .filter(
                        ({ appointmentTypeId }) =>
                            !surgeryCalendarQueryResult.data?.surgeryCalendar.globalSettings.types.hidden.includes(
                                appointmentTypeId,
                            ),
                    )
                    .reduce<
                        {
                            location?: Location;
                            shifts: {
                                _id: string;
                                locationId: string;
                                date: Date;
                                appointmentTypeId: string;
                                appointmentTypeName: string;
                                open: boolean;
                            }[];
                        }[]
                    >((acc, appointment) => {
                        const locationGroup = acc.find(
                            (locationGroupTmp) =>
                                locationGroupTmp.location?._id ===
                                appointment.locationId,
                        );
                        if (locationGroup != null) {
                            locationGroup.shifts =
                                locationGroup.shifts.concat(appointment);
                        } else {
                            acc.push({
                                location:
                                    bondLocationsQueryResult.data?.locations.find(
                                        (vetspireLocation) =>
                                            vetspireLocation._id ===
                                            appointment.locationId,
                                    ),
                                shifts: [appointment],
                            });
                        }
                        return acc;
                    }, [])
                    .map(({ location, shifts }): BondLocationWithShiftData => {
                        let shifts2 = shifts.reduce<{ [date: string]: Shift }>(
                            (acc, appointment) => {
                                const day = moment
                                    .tz(
                                        appointment.date,
                                        location?.timezone ||
                                            getFallbackTimezone(),
                                    )
                                    .format('YYYY-MM-DD');

                                if (!acc[day]?.providers) {
                                    acc[day] = {
                                        providers:
                                            surgeryCalendarQueryResult.data?.surgeryCalendar.shifts
                                                .filter(
                                                    (shift2) =>
                                                        shift2.vetspireLocationId ===
                                                            location?._vetspire
                                                                ?.id &&
                                                        shift2.date === day,
                                                )
                                                .map((shift) =>
                                                    providersQueryResult.providers.find(
                                                        (provider) =>
                                                            provider.id ===
                                                            shift.vetspireProviderId,
                                                    ),
                                                )
                                                .filter(
                                                    (provider) => !!provider,
                                                ) as ReadonlyArray<Provider>,

                                        surgeries: [],
                                    };
                                }

                                acc[day].surgeries.push({
                                    ...appointment,
                                    color: getColor(appointment.open),
                                });

                                return acc;
                            },
                            range(7).reduce(
                                (acc: { [key: string]: Shift }, n) => {
                                    const day = week.clone().add({ days: n });
                                    acc[day.format('YYYY-MM-DD')] = {
                                        surgeries: [],
                                        providers: null,
                                    };
                                    return acc;
                                },
                                {},
                            ),
                        );
                        const totalCapacity = sumBy(
                            Object.keys(shifts2),
                            (date: string) => shifts2[date].surgeries.length,
                        );
                        const capacityUsed = sumBy(
                            Object.keys(shifts2),
                            (date: string) =>
                                shifts2[date].surgeries.filter(
                                    (surgery) => !surgery.open,
                                ).length,
                        );

                        shifts2 = Object.keys(shifts2).reduce((acc, date) => {
                            if (
                                (doctors || [])?.length === 0 ||
                                (acc[date]?.providers?.length === 1 &&
                                    (doctors || []).find(
                                        (doc) =>
                                            doc.id ===
                                            acc[date].providers?.[0]?.id,
                                    ))
                            )
                                acc[date].surgeries = shifts2[
                                    date
                                ].surgeries.filter(
                                    (surgery) =>
                                        (dentals ||
                                            !surgeryCalendarQueryResult.data?.surgeryCalendar.globalSettings.types.dental.includes(
                                                surgery.appointmentTypeId,
                                            )) &&
                                        (surgeries ||
                                            !surgeryCalendarQueryResult.data?.surgeryCalendar.globalSettings.types.surgery.includes(
                                                surgery.appointmentTypeId,
                                            )) &&
                                        (!openSlots || surgery.open),
                                );
                            return acc;
                        }, shifts2);

                        return {
                            location,
                            utilization:
                                Math.floor(
                                    (capacityUsed / totalCapacity) * 100,
                                ) / 100,
                            totalCapacity,
                            capacityUsed,
                            shifts: shifts2,
                        };
                    });

            return {
                locationsWithShiftData: appointments,
                doctors:
                    uniqBy(
                        appointments
                            .map((app) => Object.values(app.shifts))
                            .flat()
                            .map((shift) => shift.providers || [])
                            .flat()
                            .filter((provider) => !!provider),
                        'id',
                    ) || [],
            };
        }
        return {
            x: 1,
            appointments: [],
            doctors: [],
        };
    }, [
        bondLocationsQueryResult.error,
        bondLocationsQueryResult.loading,
        bondLocationsQueryResult.data?.locations,
        providersQueryResult.error,
        providersQueryResult.loading,
        providersQueryResult.providers,
        surgeryCalendarQueryResult.error,
        surgeryCalendarQueryResult.loading,
        surgeryCalendarQueryResult.data?.surgeryCalendar,
        week,
        doctors,
        dentals,
        surgeries,
        openSlots,
    ]);
};

export default useSurgeryCalendarsData;
