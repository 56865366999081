import * as React from 'react';
import { BannerContent } from 'components/ProviderPayment/components/Banner';
import { openFlyout, Page } from 'lib/vetspireActions';
import useViewerSettings from 'hooks/useViewerSettings';
import useTranslate from 'hooks/useTranslate';
import { gql } from '@apollo/client';
import useProviderPaymentQuery from 'hooks/useProviderPaymentQuery';
import moment from 'moment';

const LATEST_PROSAL_BANNER_CLOSE = 'latestProsalBannerClose';

type LatestAvailablePaymentReportVariables = {
    vetspireProviderId: string;
};

type LatestAvailablePaymentReportResult = {
    latestAvailablePaymentReport: {
        month: string;
    };
};

const latestAvailablePaymentReport = gql`
    query latestAvailablePaymentReport($vetspireProviderId: String!) {
        latestAvailablePaymentReport(vetspireProviderId: $vetspireProviderId) {
            month
        }
    }
`;

export default function useShowBanner(
    pinValidated: boolean,
    setBannerContent: (bannerContent: BannerContent | null) => void,
) {
    const { viewer } = useViewerSettings();
    const { data, loading } = useProviderPaymentQuery<
        LatestAvailablePaymentReportResult,
        LatestAvailablePaymentReportVariables
    >(latestAvailablePaymentReport, {
        fetchPolicy: 'cache-and-network',
        variables: {
            vetspireProviderId: viewer?.id || '',
        },
        skip: !viewer?.id,
    });
    const translate = useTranslate();

    React.useEffect(() => {
        if (
            setBannerContent &&
            pinValidated &&
            viewer?.id &&
            !loading &&
            data?.latestAvailablePaymentReport?.month
        ) {
            const latestMonthId = data.latestAvailablePaymentReport.month;
            const latestProsalBannerClose = localStorage.getItem(
                LATEST_PROSAL_BANNER_CLOSE,
            );

            if (
                !latestProsalBannerClose ||
                latestProsalBannerClose < latestMonthId
            ) {
                setBannerContent({
                    text: translate(
                        'vetspireExtension.providerPayment.banner.reportAvailable',
                        {
                            month: moment(`${latestMonthId}-01`).format(
                                'MMMM YYYY',
                            ),
                        },
                    ).toString(),
                    buttonText: translate(
                        'vetspireExtension.providerPayment.banner.openReport',
                    ).toString(),
                    onClick: async () => {
                        await openFlyout(
                            Page.pulseMonthlyReportFrame,
                            `?month=${latestMonthId}&providerId=${viewer.id}`,
                        );
                    },
                    onClose: () => {
                        setBannerContent(null);
                        localStorage.setItem(
                            LATEST_PROSAL_BANNER_CLOSE,
                            latestMonthId,
                        );
                    },
                });
            }
        }
    }, [
        translate,
        pinValidated,
        setBannerContent,
        viewer?.id,
        loading,
        data?.latestAvailablePaymentReport?.month,
    ]);
}
