import { gql } from '@apollo/client';
import type {
    GlobalSettings,
    GlobalSettingsQueryResult,
} from '@bondvet/types/patientPlans';
import { defaultGlobalSettings } from '@bondvet/types/patientPlans';
import usePatientPlansQuery from 'hooks/usePatientPlansQuery';

const globalSettingsQuery = gql`
    query globalSettings {
        globalSettings {
            planPoliciesUrl
            faqsUrl
            overviewUrl
            cancellationPeriodDays
        }
    }
`;

export default function useGlobalSettings(): GlobalSettings & {
    loading: boolean;
} {
    const { data, loading } =
        usePatientPlansQuery<GlobalSettingsQueryResult>(globalSettingsQuery);

    return {
        ...defaultGlobalSettings,
        ...(data?.globalSettings || {}),
        loading,
    };
}
