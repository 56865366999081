import * as React from 'react';
import Select, { MultiValue } from 'react-select';
import {
    StaffingRole,
    STAFFING_ROLES,
} from '@bondvet/types/scheduling/staffing';
import useTranslate from 'hooks/useTranslate';
import { ReactComponent as LocationIcon } from 'assets/location.svg';

import classnames from 'classnames';
import styles from './LookupSelect.module.scss';

interface StaffingRolesSelectProps {
    selectedStaffingRoles: StaffingRole[];
    onChange: (staffingRoles: StaffingRole[]) => void;
    enabledRoles?: StaffingRole[];
    isDisabled?: boolean;
    className?: string;
}

interface SelectOption {
    value: string;
    label: string;
}

const StaffingRolesSelect: React.FunctionComponent<
    StaffingRolesSelectProps
> = ({
    selectedStaffingRoles,
    onChange,
    enabledRoles,
    isDisabled = false,
    className,
}) => {
    const translate = useTranslate();
    // we have to map from and to StaffingRole[] because ReactSelect cannot handle arrays of scalars as options
    const options = React.useMemo<SelectOption[]>(() => {
        return STAFFING_ROLES.filter(
            (role) =>
                !enabledRoles || enabledRoles.includes(role as StaffingRole),
        ).map((role) => ({
            value: role,
            label: translate(
                `vetspireExtension.lookups.staffingRoles.${role.toString()}`,
            ) as string,
        }));
    }, [translate, enabledRoles]);

    const selectedOptions = React.useMemo(() => {
        return options.filter((option) =>
            selectedStaffingRoles.includes(option.value as StaffingRole),
        );
    }, [options, selectedStaffingRoles]);

    return (
        <Select
            isMulti
            name="staffingRoles"
            options={options}
            value={selectedOptions}
            className={classnames(styles.lookup, className)}
            classNamePrefix="lookup-select"
            onChange={(value: MultiValue<SelectOption>) => {
                onChange(
                    ((value || []) as SelectOption[]).map(
                        (newOption) => newOption.value as StaffingRole,
                    ),
                );
            }}
            isDisabled={isDisabled}
            placeholder={
                <div className={styles.placeholder}>
                    <LocationIcon className={styles.placeholderIcon} />
                    {translate(
                        'vetspireExtension.lookups.staffingRoles.placeholder',
                    )}
                </div>
            }
        />
    );
};

export default StaffingRolesSelect;
