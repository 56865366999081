import * as React from 'react';
import { TranslateFunction } from '@bondvet/web-app-i18n/util';
import moment from 'moment-timezone';
import { ApolloError } from '@apollo/client/errors';
import { VetspireProductTypeCategory } from '@bondvet/types/products';
import { ProviderPaymentSetting } from '@bondvet/types/providers';

import useProviderPaymentQuery from 'hooks/useProviderPaymentQuery';
import * as queries from 'components/MonthlyPulseReport/api/queries';
import { ProviderPaymentMonthData } from 'components/MonthlyPulseReport/api/queries';
import useTranslate from 'hooks/useTranslate';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import {
    TranslateOptions,
    TranslatePlaceholderData,
    TranslateValue,
} from 'react-localize-redux';
import {
    ProviderExtraShiftDay,
    ProviderPaymentMonthDataResult,
} from '@bondvet/types/providerPayment';

export interface LabelEntry {
    label: string;
    value: number;
    stackValue?: number;
    target?: number;
}

export interface TableRowData {
    _id?: string;
    name: string;
    productTypeCategory?: VetspireProductTypeCategory;
    grossProduction?: number;
    netProduction: number;
    locationValue?: number;
    targetRow?: boolean;
    showSign?: boolean;
    note?: string;
    hidden?: boolean;
}

export enum RowGroup {
    inClinic = 'inClinic',
    atHome = 'atHome',
    misc = 'misc',
}

const filterForLabelOrValue = (
    argument: Partial<LabelEntry>,
): argument is LabelEntry =>
    argument.label !== undefined && argument.value !== undefined;

type RowGrouping = {
    [RowGroup.inClinic]: TableRowData[];
    [RowGroup.atHome]: TableRowData[];
    [RowGroup.misc]: TableRowData[];
};

interface UseMonthlyPulseReportData {
    monthWithYear: string;
    month: string;
    homeLocation?: string;
    providerName: string;
    shiftsWorked: number;
    ptoShifts: number;
    totalNetProduction: number;
    totalProduction: number;
    gapToAchieveProductionTarget: number;
    yearToDateEarnings: number;
    providerPayRate: number;
    productionBonus: number;
    showOnlinePharmacy: boolean;
    appointmentsToHitProduction: number | undefined;
    topFiveAppointmentTypesByRevenue: LabelEntry[];
    topFiveAppointmentTypesByNumber: LabelEntry[];
    monthOverMonthProduction: LabelEntry[];
    appointmentsPerShiftEntries: LabelEntry[];
    revenuePerShiftEntries: LabelEntry[];
    agtEntries: LabelEntry[];
    npsEntries: LabelEntry[];
    detailsTable: {
        summary: TableRowData[];
        rows: RowGrouping;
    };
    extraShiftBonus: string;
    extraShiftDays: {
        day: string;
        dailyBonus: string;
        summary: TableRowData[];
        rows: RowGrouping;
    }[];
    criticalExtraShiftBonus: string;
    criticalExtraShiftDays: {
        day: string;
        dailyBonus: string;
        summary: TableRowData[];
        rows: RowGrouping;
    }[];
}

interface UseMonthlyPulseReport {
    loading: boolean;
    data: null | UseMonthlyPulseReportData;
    textVersion: string;
    reportTranslate: TranslateFunction;
    error: ApolloError | undefined;
}

const groupProductsByCategory = (products: TableRowData[]) => {
    return products.reduce<RowGrouping>(
        (acc, product) => {
            if (product.productTypeCategory) {
                acc[product.productTypeCategory].push(product);
            } else {
                acc[RowGroup.misc].push(product);
            }

            return acc;
        },
        {
            [RowGroup.inClinic]: [],
            [RowGroup.atHome]: [],
            [RowGroup.misc]: [],
        },
    );
};

const useMonthlyPulseReportData = (
    month: string,
    vetspireProviderId: string,
    settings: ProviderPaymentSetting | undefined,
    skip?: boolean,
): UseMonthlyPulseReport => {
    const {
        error,
        data: providerPaymentMonthResult,
        loading,
    } = useProviderPaymentQuery<ProviderPaymentMonthData>(
        queries.providerPaymentMonthData,
        {
            variables: {
                month,
                vetspireProviderId,
            },
            skip,
            fetchPolicy: 'no-cache',
        },
    );

    const moneyFormatter = useMoneyFormatter({
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    const firstNoAdminPtoSickMonth = settings?.firstNoAdminPtoSickMonth || null;

    const hideAdminPtoSick = firstNoAdminPtoSickMonth
        ? month >= firstNoAdminPtoSickMonth
        : false;

    const firstCriticalExtraShiftDaysMonth =
        settings?.firstCriticalExtraShiftDaysMonth || null;

    const showCriticalExtraShiftDays = firstCriticalExtraShiftDaysMonth
        ? month >= firstCriticalExtraShiftDaysMonth
        : false;

    const firstOnlinePharmacyCreditMonth =
        settings?.firstOnlinePharmacyCreditMonth || null;

    const showOnlinePharmacy = firstOnlinePharmacyCreditMonth
        ? month >= firstOnlinePharmacyCreditMonth
        : false;

    // v1: before firstNoAdminPtoSickMonth (before 2023-12)
    // v2: firstNoAdminPtoSickMonth to firstCriticalExtraShiftDaysMonth (2023-12 - 2024-03)
    // v3: after firstCriticalExtraShiftDaysMonth (2024-04)
    // v4: after firstOnlinePharmacyCreditMonth (2024-05 - ...)

    let textVersion: string;
    if (showOnlinePharmacy) {
        textVersion = 'v4';
    } else if (showCriticalExtraShiftDays) {
        textVersion = 'v3';
    } else if (hideAdminPtoSick) {
        textVersion = 'v2';
    } else {
        textVersion = 'v1';
    }

    const rawTranslate = useTranslate();

    const reportTranslate = React.useCallback(
        (
            translateValue: TranslateValue,
            translateData?: TranslatePlaceholderData,
            translateOptions?: TranslateOptions,
        ) => {
            return rawTranslate(
                `vetspireExtension.monthlyPulseReport_${textVersion}.${translateValue}`,
                translateData,
                translateOptions,
            );
        },
        [textVersion, rawTranslate],
    );

    const mapExtraShiftDay = React.useCallback(
        (
            requestData: ProviderPaymentMonthDataResult,
            extraShiftDay: ProviderExtraShiftDay,
            extraShiftDayBonus: number,
        ) => ({
            day: moment(
                `${requestData.month}-${extraShiftDay.date}`,
                'YYYY-MM-DD',
            ).format('MMM DD, YYYY'),
            dailyBonus: moneyFormatter.format(
                textVersion >= 'v3'
                    ? extraShiftDay.dailyBonus - extraShiftDayBonus
                    : extraShiftDay.dailyBonus,
            ),
            rows: {
                ...groupProductsByCategory(
                    extraShiftDay.providerProductTypeDetail.map(
                        (entry): TableRowData => ({
                            _id: entry._id,
                            name: entry.name,
                            productTypeCategory: entry.productTypeCategory,
                            grossProduction: entry.grossProduction,
                            netProduction: entry.netProduction,
                        }),
                    ),
                ),
            },
            summary: [
                {
                    name: reportTranslate('table.summary.total').toString(),
                    grossProduction: extraShiftDay.totalGrossProduction,
                    netProduction: extraShiftDay.totalNetProduction,
                    targetRow: false,
                },
            ],
        }),
        [moneyFormatter, reportTranslate, textVersion],
    );

    return React.useMemo(() => {
        let data: UseMonthlyPulseReportData | null = null;

        if (providerPaymentMonthResult?.providerPaymentMonthData) {
            const requestData =
                providerPaymentMonthResult?.providerPaymentMonthData;
            data = {
                monthWithYear: moment(requestData.month, 'YYYY-MM').format(
                    'MMMM YYYY',
                ),
                month: moment(requestData.month, 'YYYY-MM').format('MMMM'),
                homeLocation: requestData?.homeLocation,
                providerName: requestData.providerName,
                shiftsWorked: requestData.shiftsWorked,
                ptoShifts: requestData.ptoShifts,
                totalNetProduction: requestData?.totalNetProduction,
                totalProduction: requestData?.totalProduction,
                gapToAchieveProductionTarget:
                    requestData?.gapToAchieveProductionTarget,
                yearToDateEarnings: requestData.yearToDateEarnings,
                productionBonus: requestData?.productionBonus,
                appointmentsToHitProduction:
                    requestData.appointmentsToHitProduction,
                providerPayRate: requestData.providerPayRate,
                showOnlinePharmacy,
                topFiveAppointmentTypesByRevenue:
                    requestData.topFiveAppointmentTypesByRevenue
                        .map(({ appointmentTypeName, value }) => ({
                            label: appointmentTypeName,
                            value,
                        }))
                        .sort((a, b) => b.value - a.value),
                topFiveAppointmentTypesByNumber:
                    requestData?.topFiveAppointmentTypesByVisits
                        .map(({ appointmentTypeName, value }) => ({
                            label: appointmentTypeName,
                            value,
                        }))
                        .sort((a, b) => b.value - a.value),
                monthOverMonthProduction:
                    requestData.monthOverMonthTotalProduction.map((entry) => ({
                        label: moment(entry.month, 'YYYY-MM').format('MMM ‘YY'),
                        value: entry.totalNetProduction,
                        stackValue: entry.ptoAndAdminCredit,
                        target: entry.productionTarget,
                    })),
                appointmentsPerShiftEntries: [
                    {
                        label: reportTranslate('you').toString(),
                        value: requestData.appointmentsPerShift,
                    },
                    {
                        label: requestData.homeLocation,
                        value: requestData.compareTo?.appointmentsPerShift,
                    },
                ].filter<LabelEntry>(filterForLabelOrValue),
                revenuePerShiftEntries: [
                    {
                        label: reportTranslate('you').toString(),
                        value: requestData.revenuePerShift,
                    },
                    {
                        label: requestData.homeLocation,
                        value: requestData.compareTo?.revenuePerShift,
                    },
                ].filter<LabelEntry>(filterForLabelOrValue),
                agtEntries: [
                    {
                        label: reportTranslate('you').toString(),
                        value: requestData.agt,
                    },
                    {
                        label: requestData.homeLocation,
                        value: requestData.compareTo?.agt,
                    },
                ].filter<LabelEntry>(filterForLabelOrValue),
                npsEntries: [
                    {
                        label: reportTranslate('you').toString(),
                        value: requestData.nps,
                    },
                    {
                        label: requestData.homeLocation,
                        value: requestData.compareTo?.nps,
                    },
                ].filter<LabelEntry>(filterForLabelOrValue),
                detailsTable: {
                    summary: [
                        {
                            name: reportTranslate(
                                `table.summary.total`,
                            ).toString(),
                            grossProduction: requestData.totalGrossProduction,
                            netProduction: requestData.totalNetProduction,
                            locationValue:
                                requestData.compareTo?.totalNetProduction,
                        },
                        {
                            name: reportTranslate(
                                `table.summary.adminAndPTOCredit`,
                            ).toString(),
                            netProduction: requestData.ptoAndAdminCredit,
                            hidden: hideAdminPtoSick,
                        },
                        {
                            name: reportTranslate(
                                `table.summary.netProductionAdjustment`,
                            ).toString(),
                            netProduction:
                                requestData?.netProductionAdjustmentAmount,
                            note:
                                requestData?.netProductionAdjustmentNote ||
                                undefined,
                        },

                        {
                            name: reportTranslate(
                                `table.summary.adjustedTotalProduction`,
                            ).toString(),
                            netProduction: requestData.totalProduction,
                        },
                        {
                            name: reportTranslate(
                                `table.summary.target`,
                            ).toString(),
                            netProduction: requestData?.productionTarget,
                            targetRow: true,
                        },
                        {
                            name: reportTranslate(
                                `table.summary.gapToTarget`,
                            ).toString(),
                            netProduction:
                                requestData?.gapToAchieveProductionTarget,
                        },
                    ],
                    rows: {
                        ...groupProductsByCategory(
                            requestData.providerProductTypeDetail.map(
                                (entry): TableRowData => ({
                                    _id: entry._id,
                                    name: entry.name,
                                    productTypeCategory:
                                        entry.productTypeCategory,
                                    grossProduction: entry.grossProduction,
                                    netProduction: entry.netProduction,
                                    locationValue:
                                        requestData?.compareTo?.providerProductTypeDetail.find(
                                            (compareToEntry) =>
                                                compareToEntry.name ===
                                                entry.name,
                                        )?.netProduction,
                                }),
                            ),
                        ),
                    },
                },
                extraShiftBonus: moneyFormatter.format(
                    requestData.extraShiftBonus ?? 0,
                ),
                extraShiftDays: (requestData.extraShiftDays || []).map(
                    (extraShiftDay) =>
                        mapExtraShiftDay(
                            requestData,
                            extraShiftDay,
                            requestData.extraShiftBonus ?? 0,
                        ),
                ),
                criticalExtraShiftBonus: moneyFormatter.format(
                    requestData.criticalExtraShiftBonus ?? 0,
                ),
                criticalExtraShiftDays: (
                    requestData.criticalExtraShiftDays || []
                ).map((extraShiftDay) =>
                    mapExtraShiftDay(
                        requestData,
                        extraShiftDay,
                        requestData.criticalExtraShiftBonus ?? 0,
                    ),
                ),
            };
        }

        return {
            loading,
            data,
            textVersion,
            reportTranslate,
            error,
        };
    }, [
        providerPaymentMonthResult?.providerPaymentMonthData,
        loading,
        textVersion,
        reportTranslate,
        error,
        showOnlinePharmacy,
        hideAdminPtoSick,
        moneyFormatter,
        mapExtraShiftDay,
    ]);
};

export default useMonthlyPulseReportData;
