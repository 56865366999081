import * as React from 'react';
import type { ApolloError } from '@apollo/client/errors';
import type { GraphQLVetspireProvider } from '@bondvet/types/providers';
import useViewerSettings from 'hooks/useViewerSettings';
import {
    vetspireProviders,
    VetspireProvidersQueryResult,
} from '../api/bond/queries';
import useBondQuery from './useBondQuery';

export type UseVetspireProvidersResult = {
    loading: boolean;
    error?: ApolloError;
    vetspireProviders: ReadonlyArray<GraphQLVetspireProvider>;
};

export default function useVetspireProviders(): UseVetspireProvidersResult {
    const viewerSettings = useViewerSettings();

    const { data, loading, error } = useBondQuery<VetspireProvidersQueryResult>(
        vetspireProviders,
        {
            fetchPolicy: 'cache-first',
        },
    );

    return React.useMemo(
        () => ({
            loading,
            error,
            vetspireProviders:
                data?.vetspireProviders.filter(
                    (provider) => provider.id !== viewerSettings.viewer?.id,
                ) || [],
        }),
        [loading, error, data?.vetspireProviders, viewerSettings.viewer?.id],
    );
}
