import * as React from 'react';
import classnames from 'classnames';

import styles from './ActionButton.module.scss';

interface ActionButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    active?: boolean;
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
    className = '',
    active = true,
    type = 'button',
    disabled,
    ...props
}) => {
    /* eslint-disable react/button-has-type, react/jsx-props-no-spreading */
    return (
        <button
            {...props}
            type={type}
            disabled={disabled}
            className={classnames(styles.container, className, {
                [styles.active]: active && !disabled,
            })}
        />
    );
};

export default ActionButton;
