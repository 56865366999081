import * as React from 'react';
import type { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import Tooltip from '@mui/material/Tooltip';

import styles from './TextingTooltip.module.scss';

export default function TextingTooltip({
    children,
    classes,
    ...props
}: TooltipProps) {
    return (
        <Tooltip
            {...props}
            classes={{
                popper: styles.popper,
                tooltip: styles.tooltip,
                ...(classes ?? {}),
            }}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -14],
                            },
                        },
                    ],
                },
            }}
        >
            {children}
        </Tooltip>
    );
}
