import * as React from 'react';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import useCreditCard from 'components/CreditCards/components/AddCreditCard/useCreditCard';
import CreditCardBrand from 'components/CreditCards/components/CreditCardBrand';
import useCreditCards from 'components/CreditCards/hooks/useCreditCards';
import useTranslate from 'hooks/useTranslate';
import { CreditCard } from 'api/types';
import classnames from 'classnames';
import styles from './ChoosePaymentMethod.module.scss';

export interface SubmitRef {
    submit: () => Promise<boolean>;
}

interface ChoosePaymentMethodProps {
    className?: string;
    clientId: string;
    error: boolean;
}

function ChoosePaymentMethod(
    { className, clientId, error }: ChoosePaymentMethodProps,
    submitRef: React.ForwardedRef<SubmitRef>,
) {
    const translate = useTranslate();

    const [selectedCreditCard, setSelectedCreditCard] =
        React.useState<CreditCard | null>(null);

    const { creditCards, isLoading, setDefault } = useCreditCards(clientId);

    React.useEffect(() => {
        if (creditCards) {
            setSelectedCreditCard(
                creditCards.find((card) => card.isDefault) || null,
            );
        }
    }, [creditCards]);

    const {
        cardNumberRef,
        cardCvcRef,
        cardExpiryRef,
        brand,
        handleSubmit,
        focus,
        valid,
        processing,
        processingError,
    } = useCreditCard(clientId, false);

    const selectNewCard = React.useCallback(() => {
        setSelectedCreditCard(null);
        focus();
    }, [focus, setSelectedCreditCard]);

    React.useImperativeHandle(submitRef, () => ({
        async submit() {
            if (selectedCreditCard?.isDefault) {
                // using default card -> nothing to do
                return true;
            }

            if (selectedCreditCard === null) {
                // using new card -> create payment source
                if (valid) {
                    return handleSubmit();
                }

                // invalid card -> show error
                return false;
            }

            // use existing card -> mark as default
            setDefault(selectedCreditCard.id);
            return true;
        },
    }));

    return (
        <div className={className}>
            {(isLoading || processing) && (
                <CircularProgress
                    size={50}
                    classes={{ root: styles.loading }}
                />
            )}
            {!isLoading && (
                <div className={styles.label}>
                    {translate(
                        creditCards.length > 0
                            ? 'vetspireExtension.clientDetails.payments.chargeFee.choosePaymentMethod'
                            : 'vetspireExtension.clientDetails.payments.chargeFee.addPaymentMethod',
                    )}
                </div>
            )}

            {!isLoading && creditCards.length > 0 && (
                <div className={styles.defaultPaymentMethodNote}>
                    {translate(
                        'vetspireExtension.clientDetails.payments.chargeFee.defaultPaymentMethodNote',
                    )}
                </div>
            )}
            <ul
                className={classnames(styles.root, {
                    [styles.hidden]: isLoading || processing,
                })}
            >
                {creditCards.map((card) => (
                    <li
                        key={card.last4}
                        className={classnames(styles.card, {
                            [styles.selected]:
                                card.last4 === selectedCreditCard?.last4,
                        })}
                        onClick={() => setSelectedCreditCard(card)}
                    >
                        <input
                            type="radio"
                            checked={card.last4 === selectedCreditCard?.last4}
                            onChange={() => {}}
                        />

                        <div className={styles.brandContainer}>
                            <CreditCardBrand
                                className={styles.brand}
                                brand={card.brand}
                            />
                        </div>

                        <div>
                            **** {card.last4} ({card.expirationMonth}/
                            {card.expirationYear})
                        </div>

                        {card.isDefault && (
                            <Chip
                                color="primary"
                                disabled={
                                    card.last4 !== selectedCreditCard?.last4
                                }
                                label={translate(
                                    'vetspireExtension.clientDetails.payments.creditCards.isDefault',
                                )}
                                size="small"
                                className={styles.chip}
                            />
                        )}

                        {!card.isDefault &&
                            card.last4 === selectedCreditCard?.last4 && (
                                <Chip
                                    color="primary"
                                    label={translate(
                                        'vetspireExtension.clientDetails.payments.creditCards.isNewDefault',
                                    )}
                                    size="small"
                                    className={styles.chip}
                                />
                            )}
                    </li>
                ))}
                <li
                    className={classnames({
                        [styles.selected]: selectedCreditCard === null,
                    })}
                >
                    <form>
                        <div
                            className={classnames(styles.card, styles.newCard, {
                                [styles.error]: error,
                            })}
                        >
                            <input
                                type="radio"
                                checked={selectedCreditCard === null}
                                onChange={() => {}}
                            />
                            <div className={styles.brandContainer}>
                                <CreditCardBrand
                                    brand={brand}
                                    className={styles.brand}
                                />
                            </div>
                            <div id="card-number" ref={cardNumberRef} />
                            <div id="card-expiry" ref={cardExpiryRef} />
                            <div id="card-cvc" ref={cardCvcRef} />
                            {selectedCreditCard === null && (
                                <Chip
                                    color="primary"
                                    label={translate(
                                        'vetspireExtension.clientDetails.payments.creditCards.isNewDefault',
                                    )}
                                    size="small"
                                    className={styles.chip}
                                />
                            )}
                            {selectedCreditCard !== null && (
                                <div
                                    className={styles.catchClick}
                                    onClick={selectNewCard}
                                />
                            )}
                        </div>
                    </form>
                </li>
            </ul>
            {processingError && (
                <div className={styles.error}>{processingError}</div>
            )}
        </div>
    );
}

export default React.forwardRef(ChoosePaymentMethod);
