import * as React from 'react';
import {
    RECOMMENDED_DATE_EMERGENT,
    RECOMMENDED_DATE_URGENT,
    RECOMMENDED_DATE_NEXT_SIX_MONTHS,
    RECOMMENDED_DATE_OTHER,
    RecommendedDate as RecommendedDateType,
} from '@bondvet/types/surgeryReferrals';
import Select, { SingleValue } from 'react-select';
import styles from 'components/LookupSelect/LookupSelect.module.scss';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useTranslate from 'hooks/useTranslate';
import { sendSurgeryReferralContext } from '../../context';
import formStyles from '../QuestionForm/QuestionForm.module.scss';

type RecommendedDateOption = {
    value: RecommendedDateType;
    label: RecommendedDateType;
};

const OPTIONS: ReadonlyArray<RecommendedDateOption> = (
    [
        RECOMMENDED_DATE_EMERGENT,
        RECOMMENDED_DATE_URGENT,
        RECOMMENDED_DATE_NEXT_SIX_MONTHS,
        RECOMMENDED_DATE_OTHER,
    ] as ReadonlyArray<RecommendedDateType>
).map((value) => ({
    value,
    label: value,
}));

function getValue({ value }: RecommendedDateOption): RecommendedDateType {
    return value;
}

function getLabel({ label }: RecommendedDateOption): string {
    return label;
}

export default function RecommendedDate(): React.ReactElement {
    const translate = useTranslate();
    const { recommendedDate, changeRecommendedDate } = React.useContext(
        sendSurgeryReferralContext,
    );

    const handleChange = React.useCallback(
        (newOption: SingleValue<RecommendedDateOption>) => {
            if (newOption) {
                changeRecommendedDate(newOption.value);
            }
        },
        [changeRecommendedDate],
    );

    const value = React.useMemo(
        () => ({ value: recommendedDate, label: recommendedDate }),
        [recommendedDate],
    );

    return (
        <fieldset className={formStyles.container}>
            <label htmlFor="recommended-date" className={formStyles.label}>
                {translate(
                    'vetspireExtension.surgeryReferrals.send.recommendedDate',
                )}
            </label>
            <Select
                name="recommended-date"
                id="recommended-date"
                options={OPTIONS}
                value={value}
                onChange={handleChange}
                className={styles.lookup}
                classNamePrefix="lookup-select"
                getOptionLabel={getLabel}
                getOptionValue={getValue}
                placeholder={
                    <div className={styles.placeholder}>
                        <SearchOutlinedIcon
                            className={styles.placeholderIcon}
                        />
                        {translate(
                            'vetspireExtension.surgeryReferrals.lookups.recommendedDate.placeholder',
                        )}
                    </div>
                }
            />
        </fieldset>
    );
}
