import * as React from 'react';
import {
    OptionsQuestion,
    SurgeryReferralCheckboxesAnswer,
} from '@bondvet/types/surgeryReferrals';
import { InputProps } from './types';
import styles from './QuestionForm.module.scss';

interface CheckboxesInputProps extends InputProps {
    value: SurgeryReferralCheckboxesAnswer['answers'];
    options: OptionsQuestion['options'];
}

export default function CheckboxesInput({
    value,
    onChange,
    id,
    options,
}: CheckboxesInputProps): React.ReactElement {
    const handleChange = React.useCallback(
        ({
            target: { value: option, checked },
        }: React.ChangeEvent<HTMLInputElement>) => {
            const idx = value.indexOf(option);
            if (checked) {
                if (idx === -1) {
                    onChange([...value, option]);
                }
            } else if (idx !== -1) {
                onChange([...value.slice(0, idx), ...value.slice(idx + 1)]);
            }
        },
        [value, onChange],
    );
    return (
        <ul className={styles.checkboxes}>
            {options.map((option) => {
                const optionId = `${id}_${option.replace(/[^\w]+/g, '-')}`;
                return (
                    <li key={option}>
                        <input
                            type="checkbox"
                            id={optionId}
                            checked={value.includes(option)}
                            onChange={handleChange}
                            value={option}
                        />
                        <label htmlFor={optionId}>{option}</label>
                    </li>
                );
            })}
        </ul>
    );
}
