import * as React from 'react';
import Chip from '@mui/material/Chip';

import { CreditCard } from 'api/types';
import CreditCardBrand from 'components/CreditCards/components/CreditCardBrand';
import useTranslate from 'hooks/useTranslate';

import styles from '../NewVisitForm.module.scss';

interface KnownCardsProps {
    cards: ReadonlyArray<CreditCard>;
    currentCardId: string;
    handleChangeCreditCard: (value: string) => void;
}

interface CardProps {
    card: CreditCard;
}

function Card({ card }: CardProps) {
    const translate = useTranslate();

    return (
        <div className={styles.card}>
            <CreditCardBrand className={styles.logo} brand={card.brand} />
            <div className={styles.info}>
                <p>
                    {card.last4 && `**** ${card.last4}`}
                    {card.expirationYear &&
                        card.expirationMonth &&
                        ` (${card.expirationMonth}/${card.expirationYear})`}
                </p>
            </div>
            {card.isDefault && (
                <Chip
                    color="primary"
                    label={translate(
                        'vetspireExtension.clientDetails.payments.creditCards.isDefault',
                    )}
                    size="small"
                    classes={{
                        root: styles.chip,
                        sizeSmall: styles.smallChip,
                    }}
                />
            )}
        </div>
    );
}

function KnownCards({
    cards,
    currentCardId,
    handleChangeCreditCard,
}: KnownCardsProps): React.ReactElement {
    return (
        <>
            {cards.map((card) => {
                const cardId = `card-${card.id}`;
                return (
                    <li key={cardId}>
                        <label htmlFor={cardId}>
                            <input
                                form="new-visit-form"
                                type="radio"
                                value={card.id}
                                checked={
                                    currentCardId === card.id ||
                                    (currentCardId === '' && card.isDefault)
                                }
                                onChange={() => handleChangeCreditCard(card.id)}
                                id={cardId}
                            />
                            <Card card={card} />
                        </label>
                    </li>
                );
            })}
        </>
    );
}

export default KnownCards;
