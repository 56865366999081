import * as React from 'react';
import {
    MESSAGE_SOURCE_FLYOUT,
    MESSAGE_TARGET_CONTENT,
    sendBackgroundMessage,
} from '../lib/backgroundMessages';

export default function useGlobalFlyoutEffects() {
    React.useEffect(() => {
        sendBackgroundMessage(
            MESSAGE_SOURCE_FLYOUT,
            [MESSAGE_TARGET_CONTENT],
            'updatePageData',
        );
    }, []);
}
