import * as React from 'react';
import { useQuery } from '@apollo/client';

import { Client, clientQuery, ClientQueryResult } from 'api/clients/queries';

export default function useClientDetails(
    clientId: string | null | undefined,
): Client | null {
    const [client, setClient] = React.useState<Client | null>(null);

    const { data } = useQuery<ClientQueryResult>(clientQuery, {
        variables: { clientId },
        skip: !clientId,
    });

    React.useEffect(() => {
        if (data?.client) {
            setClient(data.client);
        } else {
            setClient(null);
        }
    }, [data]);

    return client;
}
