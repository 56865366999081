import { closeCustomModal } from 'lib/vetspireActions';
import * as React from 'react';
import classnames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { format, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import useTranslate from 'hooks/useTranslate';
import useModalHeightResizer from 'hooks/useModalHeightResizer';
import useLocationId from 'hooks/useLocationId';
import useVetspireContext from 'hooks/useVetspireContext';
import useExpandedAccessSlotsInDateRange from '../hooks/useExpandedAccessSlotsInDateRange';
import styles from './IntradayCapacityModal.module.scss';
import { DATE_FORMAT, DISPLAY_TIME_FORMAT } from '../types';

const HEADER_DATE_FORMAT = 'M/d/yyyy';

function onClose(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    closeCustomModal('intradayCapacityModal');
}

export default function IntradayCapacityModal() {
    const root = React.useRef<HTMLDivElement>(null);
    useModalHeightResizer(root, 'intradayCapacityModal');
    const { selectedScheduleDate } = useVetspireContext();

    const locationId = useLocationId();
    const translate = useTranslate();
    const { loading, expandedAccessSlots, locationTimezone } =
        useExpandedAccessSlotsInDateRange(
            locationId,
            selectedScheduleDate ?? '',
            selectedScheduleDate ?? '',
            selectedScheduleDate === null,
        );

    const isLoading = loading || !selectedScheduleDate;

    const { expandedAccessSlotsArray, total } = React.useMemo(
        () => ({
            expandedAccessSlotsArray: Object.values(expandedAccessSlots)
                .filter(({ availableSlots }) => availableSlots.length > 0)
                .map(({ availableSlots, ...rest }) => ({
                    ...rest,
                    availableSlots: availableSlots.map((slot) =>
                        formatInTimeZone(
                            new Date(slot),
                            locationTimezone,
                            DISPLAY_TIME_FORMAT,
                        ),
                    ),
                })),
            total: Object.values(expandedAccessSlots).reduce<number>(
                (sum, { availableSlots }) =>
                    sum + (availableSlots?.length ?? 0),
                0,
            ),
        }),
        [expandedAccessSlots, locationTimezone],
    );

    return (
        <div
            ref={root}
            className={classnames(styles.root, {
                [styles.loading]: isLoading,
            })}
        >
            <button
                onClick={onClose}
                type="button"
                className={styles.closeButton}
            >
                <CloseIcon />
            </button>
            {isLoading && <CircularProgress />}
            {!isLoading && (
                <>
                    <Typography variant="h2">
                        {translate(
                            'vetspireExtension.intradayCapacity.modal.title',
                            {
                                date: format(
                                    parse(
                                        selectedScheduleDate,
                                        DATE_FORMAT,
                                        new Date(),
                                    ),
                                    HEADER_DATE_FORMAT,
                                ),
                            },
                        )}
                    </Typography>
                    {total === 0 && (
                        <div className={styles.empty}>
                            {translate(
                                'vetspireExtension.intradayCapacity.modal.empty',
                            )}
                        </div>
                    )}
                    {total > 0 && (
                        <dl>
                            {expandedAccessSlotsArray.map((slot) => (
                                <React.Fragment key={slot._id}>
                                    <dt>{slot.provider.name}</dt>
                                    <dd>
                                        <ul>
                                            {slot.availableSlots.map(
                                                (availableSlot) => (
                                                    <li key={availableSlot}>
                                                        {availableSlot}
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    </dd>
                                </React.Fragment>
                            ))}
                        </dl>
                    )}
                </>
            )}
        </div>
    );
}
