import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import type { DayWithAdditionalCapacitySlots } from '../../types';
import Badge from '../Badge';
import styles from './MonthOverview.module.scss';

type DayProps = DayWithAdditionalCapacitySlots & {
    onSelect: (date: string) => void;
    isSelected: boolean;
    isAdmin: boolean;
    viewerName: string | null;
};

export default function Day({
    label,
    date,
    isToday,
    isFuture,
    providers,
    onSelect,
    isSelected,
    isAdmin,
    viewerName,
}: DayProps) {
    const translate = useTranslate();

    const handleClick = React.useCallback(
        (event: React.SyntheticEvent) => {
            event?.preventDefault();
            onSelect(date);
        },
        [onSelect, date],
    );

    const tooltip = React.useMemo(() => {
        const future = isFuture ? (
            <strong>
                {translate('vetspireExtension.intradayCapacity.futureDate')}
            </strong>
        ) : null;

        if (providers.length === 0) {
            return (
                <>
                    {future}
                    <dl>
                        {!isAdmin && viewerName && <dt>{viewerName}</dt>}
                        <dd>
                            <span>
                                {translate(
                                    'vetspireExtension.intradayCapacity.noShifts',
                                )}
                            </span>
                        </dd>
                    </dl>
                </>
            );
        }

        return (
            <>
                {future}
                <dl>
                    {providers.map((provider) => (
                        <React.Fragment key={provider.id}>
                            <dt>{provider.name}</dt>
                            <dd>
                                <span>
                                    {translate(
                                        'vetspireExtension.intradayCapacity.additionalCapacitySlots',
                                    )}
                                </span>
                                <Badge>
                                    {
                                        provider.numberOfAvailableForExtendedAccess
                                    }
                                </Badge>
                            </dd>
                        </React.Fragment>
                    ))}
                </dl>
            </>
        );
    }, [isFuture, translate, providers, isAdmin, viewerName]);

    return (
        <Tooltip
            title={tooltip}
            placement="top"
            classes={{
                popper: classnames(styles.popper, {
                    [styles.future]: isFuture,
                }),
                tooltip: styles.tooltip,
            }}
        >
            <div
                role="button"
                onClick={handleClick}
                className={classnames(styles.day, {
                    [styles.past]: !isToday && !isFuture,
                    [styles.selected]: isSelected,
                    [styles.noShifts]: providers.length === 0,
                })}
            >
                {label}
            </div>
        </Tooltip>
    );
}
