import { gql } from '@apollo/client';
import useSukiMutation from 'hooks/useSukiMutation';
import {
    CreateSukiAmbientSessionArguments,
    CreateSukiAmbientSessionResult,
} from '@bondvet/types/suki';

const query = gql`
    mutation createSukiAmbientSession(
        $_id: ID!
        $input: CreateSukiAmbientSessionInput!
    ) {
        createSukiAmbientSession(_id: $_id, input: $input) {
            success
            error
            id
        }
    }
`;

export default function useCreateSukiAmbientSessionMutation() {
    return useSukiMutation<
        CreateSukiAmbientSessionResult,
        CreateSukiAmbientSessionArguments
    >(query);
}
