import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import { Medication } from 'api/encounters/queries';
import ActionButton from 'components/ActionButton';
import MedicationItem from '../MedicationItem';

import styles from './Medications.module.scss';
import surgeryFormsStyles from '../../SurgeryForms.module.scss';

interface MedicationsProps {
    medications: ReadonlyArray<Medication>;
    selectedMedicationIds: string[];
    onChangeSelectedMedicationIds: (medicationIds: string[]) => void;
    onBack: () => void;
    onNext: () => void;
}

const Medications: React.FunctionComponent<MedicationsProps> = ({
    medications,
    selectedMedicationIds,
    onChangeSelectedMedicationIds,
    onBack,
    onNext,
}) => {
    const translate = useTranslate();

    const setSelected = (medicationId: string, selected: boolean) => {
        if (selected) {
            onChangeSelectedMedicationIds([
                ...selectedMedicationIds,
                medicationId,
            ]);
        } else {
            onChangeSelectedMedicationIds(
                selectedMedicationIds.filter((id) => id !== medicationId),
            );
        }
    };

    return (
        <div
            className={classnames(
                styles.flyoutBlock,
                styles.noHorizontal,
                styles.noVertical,
                styles.container,
            )}
        >
            <div className={styles.list}>
                {medications.length === 0 ? (
                    <div
                        className={classnames(
                            styles.listItem,
                            styles.noMedications,
                        )}
                    >
                        {translate(
                            'vetspireExtension.surgeryForms.noMedications',
                        )}
                    </div>
                ) : (
                    medications.map((medication) => (
                        <MedicationItem
                            key={medication.id}
                            className={styles.listItem}
                            medication={medication}
                            selected={selectedMedicationIds.includes(
                                medication.id,
                            )}
                            setSelected={setSelected}
                        />
                    ))
                )}

                <div
                    className={classnames(
                        styles.listItem,
                        styles.medicationNote,
                    )}
                >
                    {translate('vetspireExtension.surgeryForms.medicationNote')}
                </div>
            </div>

            <div
                className={classnames(
                    styles.flyoutBlock,
                    styles.noMargin,
                    styles.padding,
                    surgeryFormsStyles.actions,
                )}
            >
                <ActionButton
                    onClick={onBack}
                    active
                    className={surgeryFormsStyles.back}
                >
                    {translate('vetspireExtension.surgeryForms.actions.back')}
                </ActionButton>
                <div />
                <ActionButton
                    onClick={onNext}
                    active
                    className={surgeryFormsStyles.next}
                >
                    {translate('vetspireExtension.surgeryForms.actions.next')}
                </ActionButton>
            </div>
        </div>
    );
};

export default Medications;
