import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import CircularProgress from '@mui/material/CircularProgress';
import GeneralError from 'components/GeneralError/GeneralError';
import ActionButton from 'components/ActionButton';
import Alert from 'components/Alert';
import styles from './Finished.module.scss';

interface FinishedFormProps {
    onClose: () => void;
    loading: boolean;
    error: string | null;
}

const Finished: React.FunctionComponent<FinishedFormProps> = ({
    loading,
    onClose,
    error,
}) => {
    const translate = useTranslate();

    let content = null;

    if (error) {
        content = <GeneralError message={error} />;
    } else if (loading) {
        content = (
            <div className={styles.loading}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div
            className={classnames(
                styles.flyoutBlock,
                styles.noHorizontal,
                styles.noVertical,
                styles.container,
            )}
        >
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.infoPDFs.title')}
                </div>
            </div>
            {!error && !loading && (
                <div className={styles.successContainer}>
                    <div className={styles.title}>
                        {translate('vetspireExtension.infoPDFs.finished.title')}
                    </div>

                    <Alert variant="success" className={styles.finished}>
                        {translate(
                            'vetspireExtension.infoPDFs.finished.content',
                        )}
                    </Alert>
                </div>
            )}
            {!error && !loading && (
                <div
                    className={classnames(
                        styles.flyoutBlock,
                        styles.noMargin,
                        styles.padding,
                        styles.actions,
                    )}
                >
                    <ActionButton onClick={onClose} className={styles.close}>
                        {translate('vetspireExtension.infoPDFs.finished.close')}
                    </ActionButton>
                </div>
            )}
            {content}
        </div>
    );
};

export default Finished;
