import * as React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useClientAndPatientId from './useClientAndPatientId';

const query = gql`
    query patients($clientId: ID!) {
        patients(
            filters: { clientId: $clientId, isDeceased: false }
            limit: 1
        ) {
            id
        }
    }
`;

type PatientsQuery = {
    patients: ReadonlyArray<{ id: string }>;
};

type PatientsQueryVariables = {
    clientId: string;
};

export default function useAnyPatientId(
    clientId: string | null,
): string | null {
    const { patientId: currentPatientId, clientId: currentClientId } =
        useClientAndPatientId();
    const [patientId, setPatientId] = React.useState<string | null>(null);

    const [runQuery, { data }] = useLazyQuery<
        PatientsQuery,
        PatientsQueryVariables
    >(query, {});

    React.useEffect(() => {
        if (clientId && clientId === currentClientId && currentPatientId) {
            setPatientId(currentPatientId);
        } else if (data && data.patients.length > 0) {
            setPatientId(data.patients[0].id);
        } else {
            setPatientId(null);
        }
    }, [clientId, patientId, currentClientId, currentPatientId, data]);

    React.useEffect(() => {
        if (clientId && (currentClientId !== clientId || !currentPatientId)) {
            runQuery({ variables: { clientId } });
        }
    }, [clientId, currentClientId, currentPatientId, runQuery]);

    return patientId;
}
