import * as React from 'react';
import { QueryResult } from '@apollo/client';
import Select, { MultiValue } from 'react-select';
import useTranslate from 'hooks/useTranslate';
import { ReactComponent as LocationIcon } from 'assets/location.svg';
import { LocationsQueryResult } from 'api/bond/queries';
import { Location } from '@bondvet/types/locations';
import styles from './LookupSelect.module.scss';
import { LookupStats } from './types';

interface LocationsSelectProps {
    locationsQueryResult: QueryResult<LocationsQueryResult>;
    selectedLocations: Location[];
    onChange: (locations: Location[]) => void;
    filter?: (location: Location) => boolean;
    isDisabled?: boolean;
    stats?: LookupStats | null;
}

const BondLocationsSelect: React.FunctionComponent<LocationsSelectProps> = ({
    locationsQueryResult,
    selectedLocations,
    onChange,
    filter,
    isDisabled = false,
    stats = null,
}) => {
    const translate = useTranslate();
    const getLocationName = React.useCallback(
        (location: Location) => {
            if (stats) {
                const counter = stats[location._id]
                    ? ` (${stats[location._id]})`
                    : '';
                return `${location.name || ''}${counter}`;
            }
            return location.name || '';
        },
        [stats],
    );

    const options = React.useMemo(() => {
        return (
            locationsQueryResult.data?.locations
                .slice()
                .filter((location) => !filter || filter(location))
                .sort((a, b) => (a.name || '').localeCompare(b.name || '')) ||
            []
        );
    }, [filter, locationsQueryResult.data]);

    return (
        <Select
            isMulti
            isLoading={locationsQueryResult.loading}
            name="providers"
            options={options}
            value={selectedLocations}
            className={styles.lookup}
            classNamePrefix="lookup-select"
            onChange={(value: MultiValue<Location>) =>
                onChange((value || []) as Location[])
            }
            getOptionValue={(location: Location) => location._id}
            getOptionLabel={getLocationName}
            isDisabled={isDisabled}
            placeholder={
                <div className={styles.placeholder}>
                    <LocationIcon className={styles.placeholderIcon} />
                    {translate(
                        'vetspireExtension.lookups.locations.placeholder',
                    )}
                </div>
            }
        />
    );
};

export default BondLocationsSelect;
