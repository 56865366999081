import Tooltip from '@mui/material/Tooltip';
import useClientAndPatientId from 'hooks/useClientAndPatientId';
import * as React from 'react';
import useViewerSettings from 'hooks/useViewerSettings';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import orderBy from 'lodash/orderBy';
import classnames from 'classnames';
import { format } from 'date-fns';
import type {
    GraphQLGiftCardWithDetails,
    GiftCardTransaction,
} from '@bondvet/types/giftCards';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import useTranslate from 'hooks/useTranslate';
import useLocationId from 'hooks/useLocationId';
import CircularProgress from '@mui/material/CircularProgress';
import { closeFlyout, openVetspirePage } from 'lib/vetspireActions';
import InfoIcon from '@mui/icons-material/Info';
import RefundTransactionDialog from '../RefundTransactionDialog';
import { ReactComponent as RefundSVG } from './assets/refund.svg';
import useTransactionHistory from './hooks/useTransactionHistory';

import styles from './TransactionsHistoryDialog.module.scss';

type TransactionsHistoryDialogProps = {
    onClose: (refetch: boolean) => void;
    refetchGiftCards: () => void;
    clientId: string;
    giftCard: GraphQLGiftCardWithDetails;
};

export default function TransactionsHistoryDialog({
    onClose,
    clientId,
    giftCard,
}: TransactionsHistoryDialogProps) {
    const viewerSettings = useViewerSettings();
    const vetspireLocationId = useLocationId();
    const { clientId: urlClientId } = useClientAndPatientId();
    const translate = useTranslate();
    const { _id: id, last3, balance } = giftCard;
    const moneyFormatter = useMoneyFormatter({
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
    });

    const [refundTransaction, setRefundTransaction] =
        React.useState<GiftCardTransaction | null>(null);

    const allowRefund =
        viewerSettings.viewer?.providerRole?.permissions.includes(
            'billing.refund',
        );

    const {
        originalBalance,
        transactions: allTransactions,
        loading,
    } = useTransactionHistory({
        vetspireLocationId,
        giftCardId: id,
        vetspireClientId: clientId,
    });

    const transactions = React.useMemo(() => {
        return orderBy(
            allTransactions.filter(
                (transaction) =>
                    transaction.id !== originalBalance?.transactionId,
            ),
            ['transactionTime'],
            ['desc'],
        );
    }, [allTransactions, originalBalance?.transactionId]);

    const handleOpenInvoicePage = React.useCallback(
        (invoiceClientId: string, patientId: string, orderId: string) => {
            openVetspirePage(
                `/clients/${invoiceClientId}/patients/${patientId}/billing/invoices/${orderId}`,
            );
            if (invoiceClientId !== clientId) {
                closeFlyout();
            }
        },
        [clientId],
    );

    const renderIcon = React.useCallback(
        (transaction: GiftCardTransaction) => {
            const {
                amount,
                orderId,
                clientId: invoiceClientId,
                patientId,
            } = transaction;

            if (
                !allowRefund ||
                !invoiceClientId ||
                !patientId ||
                !orderId ||
                amount >= 0
            ) {
                return null;
            }

            if (invoiceClientId !== urlClientId) {
                return (
                    <div>
                        <Tooltip
                            placement="top"
                            title={
                                <span className={styles.tooltip}>
                                    {translate(
                                        'vetspireExtension.clientDetails.payments.giftCards.historyDialog.changeClient',
                                    )}
                                </span>
                            }
                        >
                            <button
                                className={styles.refund}
                                onClick={() => {
                                    handleOpenInvoicePage(
                                        invoiceClientId,
                                        patientId,
                                        orderId,
                                    );
                                }}
                            >
                                <InfoIcon />
                            </button>
                        </Tooltip>
                    </div>
                );
            }

            if ((transaction.amountRefunded ?? 0) < Math.abs(amount)) {
                return (
                    <div>
                        <Tooltip
                            placement="top"
                            title={
                                <span className={styles.tooltip}>
                                    {translate(
                                        'vetspireExtension.clientDetails.payments.giftCards.historyDialog.refund',
                                    )}
                                </span>
                            }
                        >
                            <button
                                className={styles.refund}
                                onClick={() => {
                                    handleOpenInvoicePage(
                                        invoiceClientId,
                                        patientId,
                                        orderId,
                                    );
                                    setRefundTransaction(transaction);
                                }}
                            >
                                <RefundSVG />
                            </button>
                        </Tooltip>
                    </div>
                );
            }

            return (
                <div>
                    <Tooltip
                        placement="top"
                        title={
                            <span className={styles.tooltip}>
                                {translate(
                                    'vetspireExtension.clientDetails.payments.giftCards.historyDialog.fullyRefunded',
                                )}
                            </span>
                        }
                    >
                        <InfoIcon className={styles.infoIcon} />
                    </Tooltip>
                </div>
            );
        },
        [allowRefund, handleOpenInvoicePage, translate, urlClientId],
    );

    const mapTransaction = React.useCallback(
        (transaction: GiftCardTransaction) => {
            const {
                id: tid,
                amount,
                orderId,
                transactionTime,
                clientId: invoiceClientId,
                patientId,
            } = transaction;

            const date = format(
                new Date(Number.parseInt(transactionTime, 10)),
                'MMM dd, yyyy',
            );

            let label: React.ReactElement;
            if (!orderId) {
                label = (
                    <div>
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.historyDialog.noInvoice',
                            {
                                date,
                            },
                        )}
                    </div>
                );
            } else if (invoiceClientId && patientId) {
                label = (
                    <Link
                        className={styles.invoiceLink}
                        onClick={() =>
                            handleOpenInvoicePage(
                                invoiceClientId,
                                patientId,
                                orderId,
                            )
                        }
                    >
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.historyDialog.label',
                            {
                                orderId,
                                date,
                            },
                        )}
                    </Link>
                );
            } else {
                label = (
                    <div>
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.historyDialog.label',
                            {
                                orderId,
                                date,
                            },
                        )}
                    </div>
                );
            }

            return (
                <li key={tid} className={styles.transaction}>
                    {label}
                    <div
                        className={classnames(styles.amount, {
                            [styles.negative]: amount < 0,
                        })}
                    >
                        {moneyFormatter.format(amount)}
                    </div>
                    {renderIcon(transaction)}
                </li>
            );
        },
        [handleOpenInvoicePage, moneyFormatter, renderIcon, translate],
    );

    if (refundTransaction) {
        return (
            <RefundTransactionDialog
                clientId={clientId}
                giftCard={giftCard}
                transaction={refundTransaction}
                onClose={onClose}
            />
        );
    }

    return (
        <Dialog
            open
            onClose={() => onClose(false)}
            classes={{ paper: styles.root }}
        >
            <DialogTitle className={styles.title}>
                {translate(
                    'vetspireExtension.clientDetails.payments.giftCards.historyDialog.title',
                    {
                        last3,
                    },
                    {
                        renderInnerHtml: true,
                    },
                )}
            </DialogTitle>
            <DialogContent>
                <ul>
                    {!!originalBalance && (
                        <li className={styles.transaction}>
                            <div>
                                <strong>
                                    {translate(
                                        'vetspireExtension.clientDetails.payments.giftCards.historyDialog.originalBalance',
                                    )}
                                </strong>
                            </div>
                            <div className={styles.amount}>
                                <strong>
                                    {moneyFormatter.format(
                                        originalBalance?.balance ?? 0,
                                    )}
                                </strong>
                            </div>
                            <div />
                        </li>
                    )}
                    {transactions.map(mapTransaction)}
                    {!transactions.length && loading && (
                        <CircularProgress className={styles.loading} />
                    )}
                    <li className={styles.current}>
                        <div>
                            {translate(
                                'vetspireExtension.clientDetails.payments.giftCards.historyDialog.currentBalance',
                            )}
                        </div>
                        <div className={styles.amount}>
                            {moneyFormatter.format(balance)}
                        </div>
                        <div />
                    </li>
                </ul>
            </DialogContent>
            <DialogActions>
                <button
                    onClick={() => onClose(false)}
                    type="button"
                    className={styles.closeButton}
                >
                    <CloseIcon />
                </button>
            </DialogActions>
        </Dialog>
    );
}
