import * as React from 'react';
import { vetspireContext } from 'context/VetspireContext';

export default function useCurrentInvoiceId(): string | null {
    const { pathName } = React.useContext(vetspireContext);

    return React.useMemo(() => {
        const match = pathName.match(
            /^\/clients\/\d+\/patients\/\d+\/billing\/invoices\/(\d+)$/,
        );

        if (match) {
            return match[1];
        }

        return null;
    }, [pathName]);
}
