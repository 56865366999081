import * as React from 'react';
import useStripe from 'hooks/useStripe';
import {
    Stripe,
    StripeCardCvcElement,
    StripeCardElementChangeEvent,
    StripeCardExpiryElement,
    StripeCardNumberElement,
    StripeElementChangeEvent,
    StripeElements,
} from '@stripe/stripe-js';
import type { ExecutableDefinitionNode } from 'graphql/language/ast';
import { creditCards } from 'api/clients/queries';
import {
    verifyCreditCard,
    VerifyCreditCardResult,
} from 'api/clients/mutations';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { Page } from 'lib/vetspireActions';
import { useAnalytics } from 'hooks/useAnalytics';
import { VerifyCreditCardOrigin } from '@bondvet/types/creditCards';
import { Brand } from '../CreditCardBrand';

type CreditCardControls = {
    cardNumberRef: React.RefObject<HTMLDivElement>;
    cardExpiryRef: React.RefObject<HTMLDivElement>;
    cardCvcRef: React.RefObject<HTMLDivElement>;
    markAsDefault: boolean;
    setMarkAsDefault: (newValue: boolean) => void;
    handleSubmit: () => Promise<boolean>;
    handleSubmitEvent: React.FormEventHandler<HTMLFormElement>;
    valid: boolean;
    processing: boolean;
    processingError: string;
    brand: Brand;
    success: boolean;
    focus: () => void;
};

const style = {
    base: {
        fontSize: '16px',
        lineHeight: '1',
        color: '#103559',
        fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
        iconColor: '#8599ab',
        '::placeholder': {
            color: '#8599ab',
        },
        ':-webkit-autofill': {
            color: '#103559',
        },
    },
    invalid: {
        color: '#f54b5e',
        iconColor: '#f54b5e',
    },
};

type StripeElement =
    | StripeCardNumberElement
    | StripeCardCvcElement
    | StripeCardExpiryElement;

type ElementType = 'cardNumber' | 'cardExpiry' | 'cardCvc';

interface ChangeEvent extends StripeElementChangeEvent {
    elementType: ElementType;
    brand?: StripeCardElementChangeEvent['brand'];
}

type ValidFlags = {
    cardNumberValid: boolean;
    cardCvcValid: boolean;
    cardExpiryValid: boolean;
};

const elementTypes: ReadonlyArray<ElementType> = [
    'cardNumber',
    'cardCvc',
    'cardExpiry',
];

const initialProcessingState = {
    processing: false,
    processingError: '',
    success: false,
};

async function createSetupIntent(
    stripe: Stripe,
    cardElement: StripeElement,
    clientId: string,
    markAsDefault?: boolean,
): Promise<void> {
    // create a source with stripe
    const { error: sourceError, source } = await stripe.createSource(
        cardElement,
        {
            usage: 'reusable',
        },
    );

    if (sourceError) {
        throw new Error(
            sourceError.message || sourceError.code || sourceError.toString(),
        );
    }

    if (!source) {
        throw new Error('no source');
    }

    const { default: apiClient } = await import('lib/apiClient');
    const { data, errors } = await apiClient.mutate<VerifyCreditCardResult>({
        mutation: verifyCreditCard,
        context: { clientName: GRAPHQL_CLIENT_NAMES.creditCards },
        variables: {
            sourceId: source.id,
            clientId,
            markAsDefault,
            origin: VerifyCreditCardOrigin.extensionApp,
        },
        refetchQueries: creditCards.definitions
            .map(
                (definition) =>
                    (definition as ExecutableDefinitionNode).name?.value || '',
            )
            .filter((name) => name !== ''),
    });

    if (errors && errors.length > 0) {
        throw errors[0];
    }

    if (data?.verifyCreditCard.success !== true) {
        throw new Error(data?.verifyCreditCard.error || 'unknown error');
    }
}

export default function useCreditCard(
    clientId: string,
    focusOnRender = true,
): CreditCardControls {
    const analytics = useAnalytics();
    const cardNumberRef = React.useRef<HTMLDivElement>(null);
    const cardExpiryRef = React.useRef<HTMLDivElement>(null);
    const cardCvcRef = React.useRef<HTMLDivElement>(null);
    const stripe = useStripe();
    const [brand, setBrand] = React.useState<Brand>('unknown');
    const [markAsDefault, setMarkAsDefault] = React.useState(true);

    const [elements, setElements] = React.useState<StripeElements | null>(null);

    const [{ processing, processingError, success }, setProcessingState] =
        React.useState({ ...initialProcessingState });
    const [{ valid }, setValid] = React.useState<
        ValidFlags & { valid: boolean }
    >({
        valid: false,
        cardNumberValid: false,
        cardCvcValid: false,
        cardExpiryValid: false,
    });

    React.useEffect(() => {
        setElements(stripe?.elements() || null);
    }, [stripe]);

    React.useEffect(() => {
        if (
            elements &&
            cardNumberRef.current &&
            cardExpiryRef.current &&
            cardCvcRef.current
        ) {
            setBrand('unknown');

            const createChangeHandler = (next?: StripeElement) => {
                return ({
                    complete,
                    elementType,
                    brand: newBrand,
                }: ChangeEvent) => {
                    requestAnimationFrame(() => {
                        requestAnimationFrame(() => {
                            const elementKey = `${elementType}Valid`;

                            setValid((prev) => {
                                const allValid = (
                                    Object.keys(prev).filter(
                                        (key) =>
                                            key !== 'valid' &&
                                            key !== elementKey,
                                    ) as Array<keyof ValidFlags>
                                ).reduce((result, key) => {
                                    if (key === elementKey) {
                                        return result && complete;
                                    }

                                    return result && prev[key];
                                }, complete);

                                return {
                                    ...prev,
                                    [elementKey]: complete,
                                    valid: allValid,
                                };
                            });

                            if (newBrand) {
                                setBrand(newBrand || 'unknown');
                            }
                        });
                    });

                    if (complete && next) {
                        next.focus();
                    }
                };
            };
            const cardNumberElement = elements.create('cardNumber', {
                style,
            });
            cardNumberElement.mount(cardNumberRef.current);
            cardNumberElement.on('ready', () => {
                if (focusOnRender) {
                    cardNumberElement.focus();
                }
            });

            const cardExpiryElement = elements.create('cardExpiry', { style });
            cardExpiryElement.mount(cardExpiryRef.current);

            const cardCvcElement = elements.create('cardCvc', { style });
            cardCvcElement.mount(cardCvcRef.current);

            cardNumberElement.on(
                'change',
                createChangeHandler(cardExpiryElement),
            );
            cardExpiryElement.on('change', createChangeHandler(cardCvcElement));
            cardCvcElement.on('change', createChangeHandler());
        }
    }, [elements, focusOnRender]);

    const focus = React.useCallback(() => {
        if (elements?.getElement('cardNumber')) {
            elements?.getElement('cardNumber')?.focus();
        }
    }, [elements]);

    const handleSubmit = React.useCallback(async (): Promise<boolean> => {
        if (valid && !processing && elements && clientId) {
            setProcessingState({
                ...initialProcessingState,
                processing: true,
            });
            const cardElement = elements.getElement('cardNumber');

            if (!cardElement) {
                throw new Error('no card element found');
            }

            analytics.trackEvent(Page.clientDetails, 'add_credit_card');

            try {
                await createSetupIntent(
                    stripe as Stripe,
                    cardElement,
                    clientId,
                    markAsDefault,
                );

                setProcessingState((prev) => ({
                    ...prev,
                    processing: false,
                    success: true,
                }));

                // clear inputs
                elementTypes.forEach((type: ElementType) => {
                    // TypeScript won't accept elements.getElement here
                    // with an ElementType due to the way it's defined
                    // in the library.
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const element = elements.getElement(type);

                    if (element) {
                        element.clear();
                        element.blur();
                    } else {
                        console.warn(`no element of type "${type}" found`);
                    }
                });

                return true;
            } catch (error) {
                setProcessingState((prev) => ({
                    ...prev,
                    processing: false,
                    processingError: (error as Error).message,
                }));
            }
        }
        return false;
    }, [
        analytics,
        clientId,
        elements,
        markAsDefault,
        processing,
        stripe,
        valid,
    ]);

    const handleSubmitEvent = React.useCallback(
        (event?: React.FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            handleSubmit().then();
        },
        [handleSubmit],
    );

    React.useEffect(() => {
        let timer: NodeJS.Timeout | undefined;

        if (success) {
            timer = setTimeout(() => {
                timer = undefined;
                setProcessingState((prev) => ({
                    ...prev,
                    success: false,
                }));
            }, 5000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [success]);

    return {
        cardNumberRef,
        cardExpiryRef,
        cardCvcRef,
        markAsDefault,
        setMarkAsDefault,
        processing,
        processingError,
        success,
        valid,
        handleSubmit,
        handleSubmitEvent,
        brand,
        focus,
    };
}
