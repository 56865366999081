import {
    useQuery,
    type DocumentNode,
    type OperationVariables,
    type QueryHookOptions,
    type QueryResult,
    type TypedDocumentNode,
} from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from '../lib/constants';

export default function useCreditCardsQuery<
    TData = unknown,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
    return useQuery<TData, TVariables>(query, {
        ...options,
        context: {
            ...options?.context,
            clientName: GRAPHQL_CLIENT_NAMES.creditCards,
        },
    });
}
