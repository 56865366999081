import * as React from 'react';
import classnames from 'classnames';
import { Translated } from '@bondvet/web-app-i18n/util';

import styles from './Section.module.scss';

interface HeaderProps {
    title: Translated;
    subtitle?: Translated;
    className?: string;
    children?: React.ReactElement[] | React.ReactElement;
}

const Section: React.FunctionComponent<HeaderProps> = ({
    title,
    subtitle,
    className,
    children,
}) => {
    return (
        <>
            <div
                className={
                    className
                        ? classnames(styles.container, className)
                        : styles.container
                }
            >
                <>
                    <span className={styles.title}>{title}</span>{' '}
                    <span className={styles.subtitle}>{subtitle}</span>
                </>
            </div>
            <div className={styles.childrenContainer}>{children}</div>
        </>
    );
};

export default Section;
