import * as React from 'react';
import { gql } from '@apollo/client';
import useGiftCardsQuery from 'hooks/useGiftCardsQuery';
import {
    GiftCardOriginalBalanceArguments,
    GiftCardOriginalBalanceResponse,
    GiftCardTransaction,
    type GiftCardTransactionHistoryArguments,
    type GiftCardTransactionHistoryResponse,
} from '@bondvet/types/giftCards';
import useLazyGiftCardsQuery from 'hooks/useLazyGiftCardsQuery';

const PAGE_SIZE = 20;

const giftCardOriginalBalanceQuery = gql`
    query giftCardOriginalBalance(
        $vetspireLocationId: String!
        $vetspireClientId: String!
        $giftCardId: String!
    ) {
        giftCardOriginalBalance(
            vetspireLocationId: $vetspireLocationId
            vetspireClientId: $vetspireClientId
            giftCardId: $giftCardId
        ) {
            id
            transactionId
            balance
        }
    }
`;

export const giftCardTransactionHistoryQuery = gql`
    query giftCardTransactionHistory(
        $vetspireLocationId: String!
        $vetspireClientId: String!
        $giftCardId: String!
        $offset: Int
        $maxResults: Int
    ) {
        giftCardTransactionHistory(
            vetspireLocationId: $vetspireLocationId
            vetspireClientId: $vetspireClientId
            giftCardId: $giftCardId
            offset: $offset
            maxResults: $maxResults
        ) {
            transactions {
                id
                transactionTime
                amount
                orderId
                clientId
                patientId
                orderDateTime
            }
            transactionCount
            matchCount
        }
    }
`;

interface UseTransactionHistoryProps {
    vetspireLocationId: string;
    giftCardId: string;
    vetspireClientId: string;
}

interface UseTransactionHistory {
    originalBalance: {
        transactionId: string;
        balance: number;
    } | null;
    transactions: readonly GiftCardTransaction[];
    loading: boolean;
}

export default function useTransactionHistory({
    vetspireLocationId,
    giftCardId,
    vetspireClientId,
}: UseTransactionHistoryProps): UseTransactionHistory {
    const { data: originalBalanceData, loading: balanceLoading } =
        useGiftCardsQuery<
            GiftCardOriginalBalanceResponse,
            GiftCardOriginalBalanceArguments
        >(giftCardOriginalBalanceQuery, {
            variables: {
                vetspireLocationId,
                giftCardId,
                vetspireClientId,
            },
            fetchPolicy: 'no-cache',
        });

    const [loading, setLoading] = React.useState(false);

    const [runGiftCardTransactionHistoryQuery] = useLazyGiftCardsQuery<
        GiftCardTransactionHistoryResponse,
        GiftCardTransactionHistoryArguments
    >(giftCardTransactionHistoryQuery, {
        fetchPolicy: 'no-cache',
    });

    const [transactions, setTransactions] = React.useState<
        GiftCardTransaction[]
    >([]);

    const loadedProps = React.useRef<UseTransactionHistoryProps | null>(null);

    React.useEffect(() => {
        const loadTransactions = async () => {
            let offset = 0;
            const newTransactions: GiftCardTransaction[] = [];

            setLoading(true);

            loadedProps.current = {
                vetspireLocationId,
                vetspireClientId,
                giftCardId,
            };

            setTransactions([]);

            let finished = false;

            do {
                const result = await runGiftCardTransactionHistoryQuery({
                    variables: {
                        vetspireLocationId,
                        giftCardId,
                        vetspireClientId,
                        offset,
                        maxResults: PAGE_SIZE,
                    },
                });

                result.data?.giftCardTransactionHistory?.transactions?.forEach(
                    (newTransaction) => {
                        if (
                            !newTransactions.some(
                                (searchTransaction) =>
                                    searchTransaction.id === newTransaction.id,
                            )
                        ) {
                            newTransactions.push(newTransaction);
                        }
                    },
                );

                if (
                    newTransactions.length >=
                    (result.data?.giftCardTransactionHistory?.matchCount ?? 0)
                ) {
                    finished = true;
                } else {
                    offset += PAGE_SIZE;
                }
            } while (!finished);

            // combine transactions if there are 2 or more of the same order
            const combinedTransactions = newTransactions.reduce(
                (acc, newTransaction) => {
                    if (newTransaction.amount < 0) {
                        const prev = acc.findIndex(
                            ({ orderId, amount }) =>
                                newTransaction.orderId === orderId &&
                                amount < 0,
                        );

                        if (prev !== -1) {
                            const copy = [...acc];
                            copy.splice(prev, 1, {
                                ...acc[prev],
                                amount:
                                    acc[prev].amount + newTransaction.amount,
                            });
                            return copy;
                        }
                    }

                    return [...acc, newTransaction];
                },
                [] as GiftCardTransaction[],
            );

            setTransactions(
                combinedTransactions.map((newTransaction) => ({
                    ...newTransaction,
                    amountRefunded:
                        newTransaction.amount > 0
                            ? 0
                            : combinedTransactions
                                  .filter(
                                      (searchTransaction) =>
                                          searchTransaction.id !==
                                              newTransaction.id &&
                                          searchTransaction.orderId ===
                                              newTransaction.orderId &&
                                          searchTransaction.amount > 0,
                                  )
                                  .reduce(
                                      (total, transaction) =>
                                          total + Math.abs(transaction.amount),
                                      0,
                                  ),
                })),
            );

            setLoading(false);
        };

        if (
            loadedProps.current?.vetspireLocationId !== vetspireLocationId ||
            loadedProps.current?.giftCardId !== giftCardId ||
            loadedProps.current?.vetspireClientId !== vetspireClientId
        ) {
            loadTransactions().then();
        }
    }, [
        giftCardId,
        runGiftCardTransactionHistoryQuery,
        vetspireClientId,
        vetspireLocationId,
    ]);

    return {
        originalBalance: originalBalanceData?.giftCardOriginalBalance
            ? {
                  transactionId:
                      originalBalanceData.giftCardOriginalBalance.transactionId,
                  balance: originalBalanceData.giftCardOriginalBalance.balance,
              }
            : null,
        transactions,
        loading: balanceLoading || loading,
    };
}
