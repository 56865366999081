import { Moment } from 'moment';
import * as React from 'react';
import classnames from 'classnames';
import styles from './AppointmentLogEvents.module.scss';
import { NO_SHOW_DATETIME_FORMAT, NoShowAppointmentEvent } from '../../types';

export const NoShow = ({
    tz,
    event,
    highlighted,
}: {
    tz: (date: string) => Moment;
    event: NoShowAppointmentEvent;
    highlighted?: boolean;
}): React.ReactElement => (
    <div
        className={classnames(
            styles.textLine,
            highlighted && styles.highlighted,
        )}
    >
        <div>
            <div className={classnames(styles.bullet, styles.bgNoShow)} />
        </div>
        <div className={styles.textLeft}>
            <span>
                {tz(event.eventDatetime).format(NO_SHOW_DATETIME_FORMAT)} No
                Show
            </span>
        </div>
    </div>
);

export default NoShow;
