import * as React from 'react';
import useImpactScoresV2 from 'components/ProviderPayment/hooks/useImpactScoresV2';
import LightningIcon from 'assets/lightning.png';
import CircularProgress from '@mui/material/CircularProgress';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import ConfettiSVG from 'assets/confetti.svg';
import DogSVG from 'assets/dog.svg';
import moment from 'moment-timezone';
import useTimezone from 'hooks/useTimezone';
import styles from './TodaySubTab.module.scss';
import Progress from '../Progress';

interface TodaySubTabProps {
    vetspireProviderId: string;
}

const TodaySubTab: React.FunctionComponent<TodaySubTabProps> = ({
    vetspireProviderId,
}) => {
    const translate = useTranslate();
    const timezone = useTimezone();
    const day = moment.tz(timezone || '');
    const impactScores = useImpactScoresV2(
        vetspireProviderId,
        day.clone().startOf('month').format('YYYY-MM-DD'),
        day.format('YYYY-MM-DD'),
    );

    const titlePath = React.useMemo(() => {
        let title = 'default';
        if (!impactScores?.locationId) {
            title = 'noShift';
        }
        return `vetspireExtension.providerPayment.impactBonus.today.title.${title}`;
    }, [impactScores?.locationId]);

    const subtitle = React.useMemo(() => {
        if (!impactScores?.isFirstMilestoneReached) {
            return translate(
                'vetspireExtension.providerPayment.impactBonus.today.subtitle.belowFirstMilestone',
                {
                    percent:
                        impactScores?.progressBars?.visits?.milestones?.[0]
                            ?.percentRevenueImpact,
                },
            );
        }

        if (!impactScores?.isLastMilestoneReached) {
            return translate(
                'vetspireExtension.providerPayment.impactBonus.today.subtitle.belowSecondMilestone',
                {
                    percent:
                        impactScores?.progressBars?.visits?.milestones?.[1]
                            ?.percentRevenueImpact,
                },
            );
        }
        return translate(
            'vetspireExtension.providerPayment.impactBonus.today.subtitle.overSecondMilestone',
            {
                percent: (
                    <strong>
                        {
                            impactScores?.progressBars?.visits?.milestones?.[1]
                                ?.percentRevenueImpact
                        }
                    </strong>
                ),
            },
        );
    }, [impactScores, translate]);

    if (impactScores?.loading) {
        return (
            <div className={styles.loading}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={styles.tabContent}>
            {impactScores.locationId && (
                <div className={styles.progressContainer}>
                    <div
                        className={classnames(
                            styles.progressLabel,
                            styles.visitsLabel,
                        )}
                    >
                        {translate(
                            'vetspireExtension.providerPayment.impactBonus.today.visits',
                        )}
                    </div>
                    <Progress
                        loading={impactScores.loading}
                        milestones={
                            impactScores?.progressBars?.visits?.milestones || []
                        }
                        milestoneIcons={[
                            <img
                                src={LightningIcon}
                                key="milestoneIcon1"
                                alt="lightning"
                            />,
                            <img
                                src={LightningIcon}
                                key="milestoneIcon2"
                                alt="lightning"
                            />,
                        ]}
                        label={impactScores?.progressBars?.visits?.label || ''}
                        value={impactScores?.progressBars?.visits?.value || 0}
                        percent={
                            impactScores?.progressBars?.visits?.percent || 0
                        }
                    />
                    <div className={styles.progressLabel}>
                        {translate(
                            'vetspireExtension.providerPayment.impactBonus.today.revenuePerShift',
                        )}
                    </div>
                    <Progress
                        loading={impactScores.loading}
                        milestones={
                            impactScores?.progressBars?.revenue?.milestones ||
                            []
                        }
                        milestoneIcons={[
                            <img
                                src={LightningIcon}
                                key="milestoneIcon1"
                                alt="lightning"
                            />,
                            <img
                                src={LightningIcon}
                                key="milestoneIcon2"
                                alt="lightning"
                            />,
                        ]}
                        value={impactScores?.progressBars?.revenue?.value || 0}
                        label={impactScores?.progressBars?.revenue?.label || ''}
                        percent={
                            impactScores?.progressBars?.revenue?.percent || 0
                        }
                    />
                </div>
            )}

            <div className={styles.paper}>
                {impactScores?.isLastMilestoneReached && (
                    <img
                        src={ConfettiSVG}
                        className={styles.confetti}
                        alt="Confetti"
                    />
                )}
                {!impactScores.locationId && (
                    <img src={DogSVG} alt="Dog Emoji" className={styles.dog} />
                )}

                {impactScores.locationId && (
                    <div className={styles.priceContainer}>
                        <span
                            className={classnames(styles.price, {
                                [styles.priceHighlighted]:
                                    impactScores?.isFirstMilestoneReached,
                            })}
                        >
                            {impactScores?.payout || '$0'}
                        </span>
                    </div>
                )}
                <div className={styles.title}>{translate(titlePath)}</div>
                {impactScores.locationId && (
                    <div className={styles.subtitle}>{subtitle}</div>
                )}

                {impactScores?.selectedRange?.hasPayout && (
                    <div className={styles.collectedContainer}>
                        <span>
                            {translate(
                                'vetspireExtension.providerPayment.impactBonus.today.collectedThisMonth',
                            )}
                        </span>
                        <span className={styles.price}>
                            {impactScores?.selectedRange?.payout}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TodaySubTab;
