import ErrorMessage from 'components/ErrorMessage';
import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import useViewerSettings from 'hooks/useViewerSettings';

import styles from './PinValidation.module.scss';

const PIN_LENGTH = 4;

interface PinValidationProps {
    onValidated: () => void;
}

export default function PinValidation({ onValidated }: PinValidationProps) {
    const translate = useTranslate();

    const viewerSettings = useViewerSettings();

    const [pin, setPin] = React.useState('');
    const [showInvalidPin, setShowInvalidPin] = React.useState(false);

    const changePin = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPin = event.target.value;
        if (newPin.length <= PIN_LENGTH) {
            setPin(newPin);
            if (newPin.length === PIN_LENGTH) {
                if (
                    viewerSettings.paymentPin !== undefined &&
                    viewerSettings.paymentPin !== null &&
                    newPin === viewerSettings.paymentPin
                ) {
                    onValidated();
                } else {
                    setShowInvalidPin(true);
                }
            } else if (showInvalidPin) {
                setShowInvalidPin(false);
            }
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.label}>
                    {translate('vetspireExtension.providerPayment.enterPin')}
                </div>
                <input
                    className={styles.pin}
                    type="password"
                    value={pin}
                    onChange={changePin}
                />
                <ErrorMessage>
                    {(showInvalidPin &&
                        translate(
                            'vetspireExtension.providerPayment.errors.invalidPin',
                        )) ||
                        '\u00A0'}
                </ErrorMessage>
            </div>
            <div className={styles.overlay} />
        </div>
    );
}
