import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    creditCards as creditCardsQuery,
    CreditCardsQueryResult,
} from 'api/clients/queries';
import {
    deleteCreditCard as deleteCreditCardMutation,
    DeleteCreditCardResult,
    setDefaultCard as setDefaultCardMutation,
    SetDefaultCardResult,
} from 'api/clients/mutations';
import type { CreditCard } from 'api/types';
import { ExecutableDefinitionNode } from 'graphql/language/ast';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';

export type CreditCards = {
    creditCards: ReadonlyArray<CreditCard>;
    refetch: () => void;
    deleteCard: (cardId: string) => void;
    isLoading: boolean;
    setDefault: (cardId: string) => void;
};

export default function useCreditCards(clientId: string): CreditCards {
    const { data, refetch, loading } = useQuery<CreditCardsQueryResult>(
        creditCardsQuery,
        {
            variables: { clientId },
            context: { clientName: GRAPHQL_CLIENT_NAMES.creditCards },
            fetchPolicy: 'cache-and-network',
        },
    );
    const [creditCards, setCreditCards] = React.useState<
        ReadonlyArray<CreditCard>
    >([]);

    React.useEffect(() => {
        setCreditCards(data?.creditCards || []);
    }, [data]);

    const [deleteCreditCard] = useMutation<DeleteCreditCardResult>(
        deleteCreditCardMutation,
        {
            context: { clientName: GRAPHQL_CLIENT_NAMES.creditCards },
            refetchQueries: creditCardsQuery.definitions
                .map(
                    (definition) =>
                        (definition as ExecutableDefinitionNode).name?.value ||
                        '',
                )
                .filter((name) => name !== ''),
        },
    );

    const [setDefaultCard] = useMutation<SetDefaultCardResult>(
        setDefaultCardMutation,
        {
            context: { clientName: GRAPHQL_CLIENT_NAMES.creditCards },
            refetchQueries: creditCardsQuery.definitions
                .map(
                    (definition) =>
                        (definition as ExecutableDefinitionNode).name?.value ||
                        '',
                )
                .filter((name) => name !== ''),
        },
    );

    const deleteCard = React.useCallback(
        (cardId: string) => {
            deleteCreditCard({
                variables: { clientId, sourceId: cardId },
            });
        },
        [clientId, deleteCreditCard],
    );

    const setDefault = React.useCallback(
        (cardId: string) => {
            setDefaultCard({ variables: { clientId, sourceId: cardId } });
        },
        [clientId, setDefaultCard],
    );

    return { creditCards, refetch, deleteCard, isLoading: loading, setDefault };
}
