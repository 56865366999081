import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import classnames from 'classnames';
import ActionButton from 'components/ActionButton';
import useClientAndPatientId from 'hooks/useClientAndPatientId';
import { DecoratedPatientPlan } from 'pages/PatientPlans/types';
import Switch from '@mui/material/Switch';
import useTranslate from 'hooks/useTranslate';
import { format } from 'date-fns';
import globalStyles from 'pages/PatientPlans/PatientPlansGlobal.module.scss';
import usePatientPlans from '../../hooks/usePatientPlans';

import styles from './SelectPlan.module.scss';

interface SelectPlanProps {
    selectedPatientPlan: DecoratedPatientPlan | null;
    setSelectedPatientPlan: (plan: DecoratedPatientPlan) => void;
    onNext: () => void;
}

export default function SelectPlan({
    selectedPatientPlan,
    setSelectedPatientPlan,
    onNext,
}: SelectPlanProps) {
    const translate = useTranslate();

    const [showInactivePlans, setShowInactivePlans] = React.useState(false);

    const { clientId } = useClientAndPatientId();
    const {
        activePatientPlans,
        inactivePatientPlans,
        visiblePatientPlans,
        loading,
    } = usePatientPlans(clientId, showInactivePlans);

    return (
        <div className={styles.container}>
            {!loading &&
                clientId &&
                (inactivePatientPlans.length > 0 ||
                    activePatientPlans.length > 0) && (
                    <FormControlLabel
                        className={styles.showInactivePlans}
                        control={
                            <Switch
                                color="default"
                                checked={showInactivePlans}
                                onChange={(value) =>
                                    setShowInactivePlans(value.target.checked)
                                }
                            />
                        }
                        label={translate(
                            'vetspireExtension.patientPlans.selectPlan.showInactivePlans',
                        )}
                    />
                )}

            <ul>
                {!clientId && (
                    <li className={styles.noClient}>
                        {translate(
                            'vetspireExtension.patientPlans.selectPlan.errors.noClient',
                        )}
                    </li>
                )}
                {clientId &&
                    !loading &&
                    activePatientPlans.length === 0 &&
                    inactivePatientPlans.length > 0 && (
                        <li className={styles.noPlans}>
                            {translate(
                                'vetspireExtension.patientPlans.selectPlan.errors.noActivePlans',
                            )}
                        </li>
                    )}
                {clientId &&
                    !loading &&
                    activePatientPlans.length === 0 &&
                    inactivePatientPlans.length === 0 && (
                        <li className={styles.noPlans}>
                            {translate(
                                'vetspireExtension.patientPlans.selectPlan.errors.noPlans',
                            )}
                        </li>
                    )}
                {loading && (
                    <li className={styles.loading}>
                        <CircularProgress />
                    </li>
                )}
                {clientId &&
                    !loading &&
                    visiblePatientPlans.map((plan) => {
                        let statusClass = '';
                        let statusText = '';

                        if (plan.status === 'active') {
                            statusText = translate(
                                'vetspireExtension.patientPlans.selectPlan.status.active',
                                {
                                    startDate: plan.formattedStartDate,
                                    endDate: plan.formattedEndDate,
                                },
                                { renderInnerHtml: true },
                            ) as string;
                        } else if (plan.status === 'patientDeceased') {
                            statusText = translate(
                                'vetspireExtension.patientPlans.selectPlan.status.patientDeceased',
                                {
                                    deceasedDate: plan.patient?.deceasedDate
                                        ? format(
                                              new Date(
                                                  plan.patient?.deceasedDate,
                                              ),
                                              'MMMM dd, yyyy',
                                          )
                                        : '?',
                                },
                                { renderInnerHtml: true },
                            ) as string;
                        } else if (plan.status === 'patientInactive') {
                            statusClass = styles.patientInactive;
                            statusText = translate(
                                'vetspireExtension.patientPlans.selectPlan.status.patientInactive',
                                {
                                    updatedAt: format(
                                        new Date(plan.patient?.updatedAt),
                                        'MMMM dd, yyyy',
                                    ),
                                },
                                { renderInnerHtml: true },
                            ) as string;
                        } else if (plan.status === 'cancelled') {
                            statusClass = styles.cancelled;
                            statusText = translate(
                                'vetspireExtension.patientPlans.selectPlan.status.cancelled',
                                {
                                    updatedAt: format(
                                        new Date(plan.updatedAt),
                                        'MMMM dd, yyyy',
                                    ),
                                },
                                { renderInnerHtml: true },
                            ) as string;
                        } else if (plan.status === 'expired') {
                            statusClass = styles.expired;
                            statusText = translate(
                                'vetspireExtension.patientPlans.selectPlan.status.expired',
                                {
                                    endDate: plan.formattedEndDate,
                                },
                                { renderInnerHtml: true },
                            ) as string;
                        }

                        return (
                            <li
                                key={plan.id}
                                onClick={() => setSelectedPatientPlan(plan)}
                                className={classnames({
                                    [styles.selected]:
                                        selectedPatientPlan?.id === plan.id,
                                })}
                            >
                                <div
                                    className={styles.patientName}
                                    title={plan.patient?.id}
                                >
                                    {plan.patient?.name}
                                </div>
                                <div
                                    className={classnames(
                                        globalStyles.badge,
                                        globalStyles.planName,
                                    )}
                                    title={`${plan.preventionPlan?.id} - ${plan.id}`}
                                >
                                    {plan.preventionPlan?.publicName}
                                </div>

                                <div
                                    className={classnames(
                                        styles.planPeriod,
                                        statusClass,
                                    )}
                                >
                                    {statusText}
                                </div>
                            </li>
                        );
                    })}
            </ul>

            {!loading && (
                <div className={globalStyles.footer}>
                    <div />
                    <ActionButton
                        type="button"
                        className={classnames(styles.button, globalStyles.next)}
                        disabled={!selectedPatientPlan}
                        onClick={onNext}
                    >
                        {translate(
                            'vetspireExtension.patientPlans.actions.next',
                        )}
                    </ActionButton>
                </div>
            )}
        </div>
    );
}
