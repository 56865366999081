import * as React from 'react';
import { type ModalPage, resizeCustomModal } from 'lib/vetspireActions';

export default function useModalHeightResizer(
    rootRef: ReturnType<typeof React.useRef<HTMLDivElement | null>>,
    modalPage: ModalPage,
) {
    React.useEffect(() => {
        let unsubscribe = () => {};

        const root = rootRef.current;

        if (root) {
            const onMutate = () => {
                const { height } = root.getBoundingClientRect();

                const { scrollHeight } = root;

                if (scrollHeight > height) {
                    resizeCustomModal(modalPage, scrollHeight);
                }
            };

            const observer = new MutationObserver(onMutate);

            observer.observe(root, { childList: true, subtree: true });

            // run an immediate and a few delayed resize check,
            // to catch any repaints.
            window.requestAnimationFrame(onMutate);

            const timers = Array(10)
                .fill(0)
                .map((_, idx) => window.setTimeout(onMutate, idx * 100 + 100));

            unsubscribe = () => {
                observer.disconnect();

                timers.forEach((timer) => window.clearTimeout(timer));
            };
        }

        return unsubscribe;
    }, [rootRef, modalPage]);
}
