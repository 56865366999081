import * as React from 'react';

export default function useMoneyFormatter(
    options?: Intl.NumberFormatOptions,
): Intl.NumberFormat {
    return React.useMemo(() => {
        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            ...options,
        });
    }, [options]);
}
