import type { OperationResult } from '@bondvet/types';
import { gql } from '@apollo/client';

export type UpdateConversationalSubscriptionStatusResult = {
    updateConversationalSubscriptionStatus: OperationResult;
};

export const updateConversationalSubscriptionStatusMutation = gql`
    mutation updateConversationalSubscriptionStatus(
        $input: UpdateConversationalSubscriptionStatusInput!
    ) {
        updateConversationalSubscriptionStatus(input: $input) {
            success
            error
        }
    }
`;
