import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import Select, { SingleValue } from 'react-select';
import { format, parseISO } from 'date-fns';
import styles from './UpcomingVisitSelect.module.scss';
import addCreditMemoStyles from '../AddCreditMemo/AddCreditMemo.module.scss';
import useUpcomingVisits, { UpcomingVisitOption } from './useUpcomingVisits';

type UpcomingVisitSelectProps = {
    skip: boolean;
    visit: SingleValue<UpcomingVisitOption> | null;
    selectVisit: (visit: SingleValue<UpcomingVisitOption> | null) => void;
    clientId: string;
};

function UpcomingVisitSelect({
    skip,
    visit,
    selectVisit,
    clientId,
}: UpcomingVisitSelectProps): React.ReactElement {
    const translate = useTranslate();
    const { options, isLoading } = useUpcomingVisits({
        clientId,
        skip,
    });

    const handleVisitChange = React.useCallback(
        (option?: SingleValue<UpcomingVisitOption>) => {
            if (option) {
                selectVisit(option);
            } else {
                selectVisit(null);
            }
        },
        [selectVisit],
    );

    const isVisitOptionSelected = React.useCallback(
        (option: UpcomingVisitOption) => {
            return visit?.id === option.id;
        },
        [visit],
    );

    const formatVisitOptionLabel = (option: UpcomingVisitOption) => {
        const {
            appointmentData: { pet, date, locationName, appointmentTypeName },
        } = option;
        return (
            <span>
                <span className={styles.strong}>{pet}</span>
                {' - '}
                <span>
                    {`${format(parseISO(date), "MMMM d 'at' h:mma")} at ${locationName} for ${appointmentTypeName}`}
                </span>
            </span>
        );
    };

    return (
        <>
            <div className={addCreditMemoStyles.section}>
                {translate(
                    'vetspireExtension.clientDetails.credits.addCredits.visit',
                )}
            </div>
            <Select<UpcomingVisitOption>
                name="visit"
                autoFocus
                className={styles.lookup}
                classNamePrefix={'visit-select'}
                value={visit}
                onChange={handleVisitChange}
                options={options}
                isOptionSelected={isVisitOptionSelected}
                required
                formatOptionLabel={formatVisitOptionLabel}
                isLoading={isLoading}
                noOptionsMessage={() =>
                    translate(
                        'vetspireExtension.clientDetails.credits.addCredits.noVisits',
                    ) as string
                }
                placeholder={
                    <div className={styles.placeholder}>
                        {translate(
                            'vetspireExtension.clientDetails.credits.addCredits.visit',
                        )}
                    </div>
                }
            />
        </>
    );
}

export default UpcomingVisitSelect;
