import * as React from 'react';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { gql, useLazyQuery } from '@apollo/client';
import { ProviderPaymentMonth } from '@bondvet/types/providers';

export const providerPaymentMonthQuery = gql`
    query providerPaymentMonth($vetspireProviderId: ID!, $month: String!) {
        providerPaymentMonth(
            vetspireProviderId: $vetspireProviderId
            month: $month
        ) {
            _id
            vetspireProviderId
            month
            vetspireLocationId
            extraShiftDays
            criticalExtraShiftDays
            allowReportDownload
            paymentCalculation {
                monthlyGoal
                monthlyBonus
                workingDays
                totalProduction
                totalBonus
                extraShiftDayBonusSum
                extraShiftDaysCalculation {
                    extraShiftDay
                    earning
                    bonus
                }
                criticalExtraShiftDayBonusSum
                criticalExtraShiftDaysCalculation {
                    extraShiftDay
                    earning
                    bonus
                }
            }
        }
    }
`;

type ProviderPaymentMonthVariables = {
    vetspireProviderId: string;
    month: string;
};

type ProviderPaymentMonthResult = {
    loadProviderPaymentMonth: (
        variables: ProviderPaymentMonthVariables,
    ) => Promise<void>;
    providerPaymentMonth?: ProviderPaymentMonth;
    lastRunVariables?: ProviderPaymentMonthVariables;
    loading: boolean;
};

type ProviderPaymentMonthQueryResult = {
    providerPaymentMonth: ProviderPaymentMonth;
};

export default function useProviderPaymentMonth(): ProviderPaymentMonthResult {
    const [runLazyQuery, { data, variables: lastRunVariables, loading }] =
        useLazyQuery<
            ProviderPaymentMonthQueryResult,
            ProviderPaymentMonthVariables
        >(providerPaymentMonthQuery, {
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
            fetchPolicy: 'cache-first',
        });

    const loadProviderPaymentMonth = React.useCallback(
        async (variables: ProviderPaymentMonthVariables) => {
            await runLazyQuery({
                variables,
            });
        },
        [runLazyQuery],
    );

    return {
        loadProviderPaymentMonth,
        providerPaymentMonth: data?.providerPaymentMonth,
        lastRunVariables,
        loading,
    };
}
