import * as React from 'react';
import classnames from 'classnames';
import styles from './ErrorMessage.module.scss';

type ErrorMessageProps = {
    className?: string;
};

export default function ErrorMessage({
    className,
    children,
}: React.PropsWithChildren<ErrorMessageProps>) {
    return <div className={classnames(className, styles.root)}>{children}</div>;
}
