import * as React from 'react';
import Alert from 'components/Alert';
import CheckIcon from '@mui/icons-material/Check';
import useTranslate from 'hooks/useTranslate';
import usePatientDetails from 'hooks/usePatientDetails';
import ActionButton from 'components/ActionButton';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import PreferredClinic from '../PreferredClinic';
import styles from './SendRecommendation.module.scss';
import SelectSurgeryType from '../SelectSurgeryType';
import { sendSurgeryReferralContext } from '../../context';
import QuestionForm from '../QuestionForm';
import useForm from './useForm';
import Notes from '../Notes';
import RecommendForEducationalFund from '../RecommendForEducationalFund';
import RecommendedDate from '../RecommendedDate';
import RecommendedSurgeons from '../RecommendedSurgeons';

type SendRecommendationProps = {
    clientId: string | null;
    patientId: string | null;
};

export default function SendRecommendation({
    clientId,
    patientId,
}: SendRecommendationProps): React.ReactElement {
    const translate = useTranslate();
    const { details } = usePatientDetails(patientId);
    const {
        setSurgeryType,
        answers,
        valid,
        submit,
        submitting,
        error,
        loading,
        succeeded,
        surgeonOptions,
        ...contextData
    } = useForm(clientId, patientId);

    const questions = React.useMemo(
        () =>
            answers
                .slice(0)
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((answer) => (
                    <QuestionForm answer={answer} key={answer.id} />
                )),
        [answers],
    );

    if (!patientId) {
        return (
            <Alert>
                {translate(
                    'vetspireExtension.surgeryReferrals.send.noPatientRoute',
                )}
            </Alert>
        );
    }
    const { surgeryType } = contextData;

    return (
        <sendSurgeryReferralContext.Provider value={contextData}>
            <form onSubmit={submit} className={styles.container}>
                {loading && (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                )}

                {!loading && (
                    <SelectSurgeryType
                        value={surgeryType}
                        onChange={setSurgeryType}
                        label={
                            <>
                                {translate(
                                    'vetspireExtension.surgeryReferrals.send.intro1',
                                )}{' '}
                                <strong>{details?.name}</strong>{' '}
                                {translate(
                                    'vetspireExtension.surgeryReferrals.send.intro2',
                                )}
                            </>
                        }
                    />
                )}

                {surgeryType && !loading && (
                    <>
                        <RecommendedDate />
                        <PreferredClinic />
                        <RecommendedSurgeons surgeonOptions={surgeonOptions} />
                        {questions}
                        <Notes />
                        <RecommendForEducationalFund />
                    </>
                )}

                {error && <Alert>{error}</Alert>}

                {!loading && (
                    <div className={styles.actions}>
                        <ActionButton
                            disabled={
                                !valid ||
                                !surgeryType ||
                                submitting ||
                                succeeded
                            }
                            type="submit"
                            className={classnames(styles.button, {
                                [styles.submit]: !succeeded,
                                [styles.succeeded]: succeeded,
                            })}
                        >
                            {succeeded && <CheckIcon className={styles.icon} />}
                            {translate(
                                `vetspireExtension.surgeryReferrals.send.${
                                    succeeded ? 'sent' : 'submit'
                                }`,
                            )}
                        </ActionButton>
                    </div>
                )}
            </form>
        </sendSurgeryReferralContext.Provider>
    );
}
