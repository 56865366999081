import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import * as React from 'react';
import styles from './YearSelect.module.scss';

interface YearSelectProps {
    year: number; // YYYY
    minYear?: number;
    maxYear?: number;
    onChange: (newYear: number) => void;
}

const YearSelect: React.FunctionComponent<YearSelectProps> = ({
    year,
    minYear,
    maxYear,
    onChange,
}) => {
    const priorYearEnabled = !minYear || year > minYear;
    const nextYearEnabled = !maxYear || year < maxYear;

    return (
        <div className={styles.container}>
            {priorYearEnabled ? (
                <button
                    type="button"
                    className={styles.buttonBack}
                    onClick={() => {
                        onChange(year - 1);
                    }}
                >
                    <ArrowBackIosIcon fontSize="small" />
                </button>
            ) : (
                <div />
            )}
            <div className={styles.date}>{year.toString()}</div>
            {nextYearEnabled ? (
                <button
                    type="button"
                    className={styles.buttonForward}
                    onClick={() => {
                        onChange(year + 1);
                    }}
                >
                    <ArrowBackIosIcon fontSize="small" />
                </button>
            ) : (
                <div />
            )}
        </div>
    );
};

export default YearSelect;
