import * as React from 'react';
import useLazySukiGlobalSettingsQuery from 'pages/Suki/hooks/useLazySukiGlobalSettingsQuery';
import { nl2br } from 'pages/Suki/utils';
import useTranslate from 'hooks/useTranslate';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { SukiNoteData } from 'pages/Suki/components/AmbientSession/types';

const encounterQuery = gql`
    query encounter($id: ID!) {
        encounter(id: $id) {
            id
            sectionsJson
        }
    }
`;

interface EncounterQueryVariables {
    id: string;
}

interface EncounterQueryResult {
    encounter: {
        id: string;
        sectionsJson: string;
    };
}

const updateEncounterSectionDatumMutation = gql`
    mutation updateEncounterSectionDatum(
        $encounterId: ID!
        $sectionIdx: Int!
        $datumIdx: Int!
        $updates: String!
    ) {
        updateEncounterSectionDatum(
            encounterId: $encounterId
            sectionIdx: $sectionIdx
            datumIdx: $datumIdx
            updates: $updates
        ) {
            id
        }
    }
`;

interface UpdateEncounterSectionDatumMutationVariables {
    encounterId: string;
    sectionIdx: number;
    datumIdx: number;
    updates: string;
}

interface UpdateEncounterSectionDatumMutationResult {
    updateEncounterSectionDatum: {
        id: string;
    };
}

interface UseUpdateEncounter {
    updateEncounter: (
        encounterId: string,
        sukiNoteData: SukiNoteData,
    ) => Promise<void>;
    loading: boolean;
    updating: boolean;
    error: string | null;
}

export default function useUpdateEncounter(): UseUpdateEncounter {
    const translate = useTranslate();
    const [error, setError] = React.useState<string | null>(null);
    const [updating, setUpdating] = React.useState(false);

    const [runLazyGlobalSettingsQuery] = useLazySukiGlobalSettingsQuery();

    const [runEncounterQuery, { loading }] = useLazyQuery<
        EncounterQueryResult,
        EncounterQueryVariables
    >(encounterQuery, {
        fetchPolicy: 'no-cache',
    });

    const [updateEncounterSectionDatum] = useMutation<
        UpdateEncounterSectionDatumMutationResult,
        UpdateEncounterSectionDatumMutationVariables
    >(updateEncounterSectionDatumMutation);

    const updateSectionBlockValue = React.useCallback(
        async (
            encounterId: string,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sections: any[],
            sectionTitle: string,
            blockTitle: string,
            sukiValue: string,
        ) => {
            const subjectiveSectionIdx = sections.findIndex(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (section: any) => section?.name?.trim() === sectionTitle,
            );

            if (subjectiveSectionIdx === -1) {
                console.warn(
                    `Section with title ${sectionTitle} section not found in encounter`,
                );
            } else {
                const subjectiveSection = sections[subjectiveSectionIdx];

                const historyBlockIdx = subjectiveSection.data.findIndex(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (block: any) => block?.label?.trim() === blockTitle,
                );

                if (historyBlockIdx === -1) {
                    console.warn(
                        `Block with label ${blockTitle} not found in section ${sectionTitle}`,
                    );
                } else {
                    const historyBlock =
                        subjectiveSection.data[historyBlockIdx];

                    const newValue = `${historyBlock.value ?? ''}<p>${nl2br(sukiValue)}</p>`;

                    await updateEncounterSectionDatum({
                        variables: {
                            encounterId,
                            sectionIdx: subjectiveSectionIdx,
                            datumIdx: historyBlockIdx,
                            updates: JSON.stringify({
                                value: newValue,
                            }),
                        },
                    });
                }
            }
        },
        [updateEncounterSectionDatum],
    );

    const updateEncounter = React.useCallback(
        async (encounterId: string, sukiNoteData: SukiNoteData) => {
            setError(null);
            setUpdating(true);

            // we use lazy query here because we need the settings and by using runLazyGlobalSettingsQuery,
            // we can wait for the settings to be loaded before we continue.
            const { data: globalSettingsData } =
                await runLazyGlobalSettingsQuery();

            if (!globalSettingsData) {
                setError(
                    translate(
                        'vetspireExtension.suki.errors.globalSettingsNotFound',
                    ) as string,
                );
                setUpdating(false);
                return;
            }

            const { sukiGlobalSettings } = globalSettingsData;

            const { data, error: encounterQueryError } =
                await runEncounterQuery({
                    variables: { id: encounterId },
                });

            if (encounterQueryError) {
                setError(encounterQueryError.message);
                setUpdating(false);
                return;
            }

            if (!data?.encounter) {
                setError(
                    translate(
                        'vetspireExtension.suki.errors.encounterNotFound',
                    ) as string,
                );
                setUpdating(false);
                return;
            }

            try {
                const sections = JSON.parse(data?.encounter.sectionsJson);

                for (const mapping of sukiGlobalSettings.encounterMappings) {
                    const sukiBlock = sukiNoteData.contents.find(
                        (content) => content.title === mapping.sukiTitle,
                    );
                    if (sukiBlock?.content?.trim()) {
                        await updateSectionBlockValue(
                            encounterId,
                            sections,
                            mapping.sectionTitle,
                            mapping.blockTitle,
                            sukiBlock.content ?? '',
                        );
                    }
                }
            } catch (err) {
                console.error('Error: ', err);
                setError((err as Error).message);
                setUpdating(false);
                return;
            }

            setUpdating(false);
        },
        [
            runEncounterQuery,
            runLazyGlobalSettingsQuery,
            translate,
            updateSectionBlockValue,
        ],
    );

    return {
        updateEncounter,
        loading,
        updating,
        error,
    };
}
