import useTimezone from 'hooks/useTimezone';
import useViewerSettings from 'hooks/useViewerSettings';
import useLazySukiAmbientSessionStatsQuery from 'pages/Suki/hooks/useLazySukiAmbientSessionStatsQuery';
import * as React from 'react';
import { CircularProgress } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import {
    startOfISOWeek,
    endOfISOWeek,
    lastDayOfMonth,
    addDays,
    format,
    parse,
} from 'date-fns';

import styles from './Calendar.module.scss';

interface CalendarProps {
    month: string; // yyyy-MM
    selectedDate: string;
    setSelectedDate: (date: string) => void; // yyyy-MM-dd
}

interface Day {
    date: string; // yyyy-MM-dd
    day: string; // 1-31
    finishedAmbientSessions: number;
    processedAmbientSessions: number;
    isInSelectedMonth: boolean;
    isFutureDay: boolean;
}

export default function Calendar({
    month,
    selectedDate,
    setSelectedDate,
}: CalendarProps) {
    const viewerSettings = useViewerSettings();
    const translate = useTranslate();
    const timezone = useTimezone();

    const [
        runSukiAmbientSessionStatsQuery,
        { loading, data: sukiAmbientSessionStats },
    ] = useLazySukiAmbientSessionStatsQuery();

    const days = React.useMemo<readonly Day[]>(() => {
        const firstOfMonth = parse(`${month}-01`, 'yyyy-MM-dd', new Date());
        const firstVisible = startOfISOWeek(firstOfMonth);

        const lastOfMonth = lastDayOfMonth(firstOfMonth);
        const lastVisible = endOfISOWeek(lastOfMonth);

        // triggering the query async here to avoid blocking the render
        runSukiAmbientSessionStatsQuery({
            variables: {
                vetspireProviderId: viewerSettings.viewer?.id ?? '',
                from: firstVisible,
                to: lastVisible,
                timezone,
            },
        });

        const now = new Date();

        let day = firstVisible;
        const newDays: Day[] = [];

        do {
            newDays.push({
                date: format(day, 'yyyy-MM-dd'),
                day: format(day, 'd'),
                finishedAmbientSessions: 0,
                processedAmbientSessions: 0,
                isInSelectedMonth:
                    day.getTime() > firstOfMonth.getTime() &&
                    day.getTime() < lastOfMonth.getTime(),
                isFutureDay: day.getTime() > now.getTime(),
            });
            day = addDays(day, 1);
        } while (day.getTime() < lastVisible.getTime());

        return newDays;
    }, [
        month,
        runSukiAmbientSessionStatsQuery,
        timezone,
        viewerSettings.viewer?.id,
    ]);

    const daysWithStats = React.useMemo(() => {
        if (!sukiAmbientSessionStats) {
            return days;
        }

        return days.map((day) => {
            const dayStats =
                sukiAmbientSessionStats?.sukiAmbientSessionStats?.find(
                    (stats) => stats.date === day.date,
                );

            return {
                ...day,
                finishedAmbientSessions: dayStats?.finishedAmbientSessions ?? 0,
                processedAmbientSessions:
                    dayStats?.processedAmbientSessions ?? 0,
            };
        });
    }, [days, sukiAmbientSessionStats]);

    const dayLabels = React.useMemo(
        () =>
            days
                .slice(0, 7)
                .map(({ date }) =>
                    format(parse(date, 'yyyy-MM-dd', new Date()), 'EEEEE'),
                ),
        [days],
    );

    return (
        <div className={styles.container}>
            {translate('vetspireExtension.suki.sessionOverview.calendarNote')}
            <div className={styles.grid}>
                {dayLabels.map((day, index) => (
                    <div key={index} className={styles.dayLabel}>
                        {day}
                    </div>
                ))}
                {daysWithStats.map(
                    ({
                        day,
                        date,
                        processedAmbientSessions,
                        finishedAmbientSessions,
                        isInSelectedMonth,
                        isFutureDay,
                    }) => {
                        const totalAmbientSessions =
                            finishedAmbientSessions + processedAmbientSessions;

                        const percent =
                            totalAmbientSessions === 0
                                ? 100
                                : processedAmbientSessions /
                                  (totalAmbientSessions / 100);

                        return (
                            <div
                                className={classnames(styles.dayContainer, {
                                    [styles.selected]: date === selectedDate,
                                })}
                                key={date}
                            >
                                <Tooltip
                                    title={translate(
                                        'vetspireExtension.suki.sessionOverview.dayTooltip',
                                        {
                                            finished: finishedAmbientSessions,
                                            processed: processedAmbientSessions,
                                        },
                                        {
                                            renderInnerHtml: true,
                                        },
                                    )}
                                    className={styles.tooltip}
                                    placement="top"
                                >
                                    <div
                                        className={classnames(styles.day, {
                                            [styles.notInSelectedMonth]:
                                                !isInSelectedMonth,
                                            [styles.futureDay]: isFutureDay,
                                            [styles.noSessions]:
                                                totalAmbientSessions === 0,
                                            [styles.allProcessed]:
                                                totalAmbientSessions > 0 &&
                                                finishedAmbientSessions === 0,
                                        })}
                                        onClick={() => {
                                            setSelectedDate(date);
                                        }}
                                    >
                                        <div>
                                            {finishedAmbientSessions > 0 && (
                                                <CircularProgress
                                                    variant={
                                                        loading
                                                            ? 'indeterminate'
                                                            : 'determinate'
                                                    }
                                                    classes={{
                                                        root: styles.backgroundProgress,
                                                    }}
                                                    value={100}
                                                    size={28}
                                                    sx={{
                                                        color: '#BBBBBB',
                                                    }}
                                                />
                                            )}
                                            {finishedAmbientSessions > 0 && (
                                                <CircularProgress
                                                    variant={
                                                        loading
                                                            ? 'indeterminate'
                                                            : 'determinate'
                                                    }
                                                    classes={{
                                                        root: styles.progress,
                                                    }}
                                                    value={percent}
                                                    size={28}
                                                    sx={{
                                                        color: '#0E8852',
                                                    }}
                                                />
                                            )}
                                            <span className={styles.dayNumber}>
                                                {day}
                                            </span>
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    },
                )}
            </div>
        </div>
    );
}
