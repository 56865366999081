import * as React from 'react';
import { Translated } from '@bondvet/web-app-i18n/util';
import styles from './BigNumberCard.module.scss';

interface BigNumberCardProps {
    label: Translated;
    subLabel?: Translated;
    value: number | string;
}

const BigNumberCard: React.FunctionComponent<BigNumberCardProps> = ({
    label,
    value,
    subLabel,
}) => {
    return (
        <div className={styles.container}>
            <span className={styles.value}>{value}</span>
            <span className={styles.label}>{label}</span>
            {subLabel && <span className={styles.subLabel}>{subLabel}</span>}
        </div>
    );
};

export default BigNumberCard;
