import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import * as React from 'react';
import moment from 'moment';

import styles from './MonthSelect.module.scss';

interface MonthSelectProps {
    month: string; // YYYY-MM
    minMonth?: string;
    maxMonth?: string;
    onChange: (newMonth: string) => void;
}

const MonthSelect: React.FunctionComponent<MonthSelectProps> = ({
    month,
    minMonth,
    maxMonth,
    onChange,
}) => {
    const currentMonth = moment(`${month}-01`);

    const priorMonthEnabled = !minMonth || month > minMonth;
    const nextMonthEnabled = !maxMonth || month < maxMonth;

    return (
        <div className={styles.container}>
            {priorMonthEnabled ? (
                <button
                    type="button"
                    className={styles.buttonBack}
                    onClick={() => {
                        onChange(
                            currentMonth
                                .clone()
                                .subtract({ months: 1 })
                                .format('YYYY-MM'),
                        );
                    }}
                >
                    <ArrowBackIosIcon fontSize="small" />
                </button>
            ) : (
                <div />
            )}
            <div className={styles.date}>
                {currentMonth.format('MMMM YYYY')}
            </div>
            {nextMonthEnabled ? (
                <button
                    type="button"
                    className={styles.buttonForward}
                    onClick={() => {
                        onChange(
                            currentMonth
                                .clone()
                                .add({ months: 1 })
                                .format('YYYY-MM'),
                        );
                    }}
                >
                    <ArrowBackIosIcon fontSize="small" />
                </button>
            ) : (
                <div />
            )}
        </div>
    );
};

export default MonthSelect;
