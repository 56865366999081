import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import ActionButton from 'components/ActionButton';
import Alert from 'components/Alert';
import CheckBox from 'components/CheckBox/CheckBox';
import styles from './AddCreditCard.module.scss';
import useCreditCard from './useCreditCard';
import CreditCardBrand from '../CreditCardBrand';
import useSendLink from './useSendLink';

export type AddCreditCardProps = {
    clientId: string;
};

function AddCreditCard({ clientId }: AddCreditCardProps): React.ReactElement {
    const {
        cardNumberRef,
        cardCvcRef,
        cardExpiryRef,
        markAsDefault,
        setMarkAsDefault,
        brand,
        handleSubmitEvent,
        valid,
        processing,
        processingError,
        success,
    } = useCreditCard(clientId);
    const { sendingLink, linkSent, linkError, sendLink } =
        useSendLink(clientId);
    const translate = useTranslate();

    return (
        <form className={styles.container} onSubmit={handleSubmitEvent}>
            <div className={styles.card}>
                <CreditCardBrand brand={brand} className={styles.cardIcon} />
                <div id="card-number" ref={cardNumberRef} />
                <div id="card-expiry" ref={cardExpiryRef} />
                <div id="card-cvc" ref={cardCvcRef} />
            </div>
            <div className={styles.markAsDefault}>
                <CheckBox
                    label={translate(
                        'vetspireExtension.clientDetails.payments.creditCards.markAsDefault',
                    )}
                    value={markAsDefault}
                    onChange={(newValue) => setMarkAsDefault(newValue)}
                />
            </div>
            <div className={styles.actions}>
                <ActionButton
                    disabled={!valid || processing}
                    type="submit"
                    className={classnames(styles.button, styles.submit)}
                >
                    {translate(
                        'vetspireExtension.clientDetails.payments.creditCards.addCard',
                    )}
                </ActionButton>
                <ActionButton
                    disabled={sendingLink}
                    type="button"
                    className={classnames(styles.button, styles.sendLink)}
                    onClick={sendLink}
                >
                    {translate(
                        'vetspireExtension.clientDetails.payments.creditCards.sendLink',
                    )}
                </ActionButton>
            </div>
            {processingError && <Alert>{processingError}</Alert>}
            {success && (
                <Alert variant="success">
                    {translate(
                        'vetspireExtension.clientDetails.payments.creditCards.cardAdded',
                    )}
                </Alert>
            )}
            {linkError && <Alert>{linkError}</Alert>}
            {linkSent && (
                <Alert variant="success">
                    {translate(
                        'vetspireExtension.clientDetails.payments.creditCards.linkSent',
                    )}
                </Alert>
            )}
        </form>
    );
}

export default AddCreditCard;
