import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import type { OperationResult } from '@bondvet/types';
import { gql } from '@apollo/client';
import { StaffingRole } from '@bondvet/types/scheduling/staffing';
import useSchedulingMutation from 'hooks/useSchedulingMutation';

type SetStaffingShiftStatusResult = {
    setStaffingShiftStatus: OperationResult;
};

export const setStaffingShiftStatusMutation = gql`
    mutation setStaffingShiftStatus(
        $locationId: ID!
        $day: DateString!
        $staffingRole: StaffingRole!
        $ignore: Int!
    ) {
        setStaffingShiftStatus(
            locationId: $locationId
            day: $day
            staffingRole: $staffingRole
            ignore: $ignore
        ) {
            success
            error
        }
    }
`;

export interface Variables {
    locationId: string;
    day: string;
    staffingRole: StaffingRole;
    ignore: number;
}

export type SetStaffingShiftStatus = {
    loading: boolean;
    error?: string | null;
    setStaffingShiftStatus: (
        locationId: string,
        day: string,
        staffingRole: StaffingRole,
        ignore: number,
    ) => void;
};

export default function useSetStaffingShiftStatus(): SetStaffingShiftStatus {
    const [mutate, { loading, error, data }] = useSchedulingMutation<
        SetStaffingShiftStatusResult,
        Variables
    >(setStaffingShiftStatusMutation, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    const setStaffingShiftStatus = async (
        locationId: string,
        day: string,
        staffingRole: StaffingRole,
        ignore: number,
    ) => {
        const variables: Variables = {
            locationId,
            day,
            staffingRole,
            ignore,
        };

        await mutate({ variables });
    };

    return {
        loading,
        error: error?.message || data?.setStaffingShiftStatus.error || null,
        setStaffingShiftStatus,
    };
}
