import * as React from 'react';
import useProviders from 'hooks/useProviders';
import SubTabs from 'components/SubTabs';
import useTranslate from 'hooks/useTranslate';
import type { Provider } from 'api/providers/queries';
import useTimezone from 'hooks/useTimezone';
import { ProviderRightValue } from '@bondvet/types/providers';
import useViewerSettings from 'hooks/useViewerSettings';
import { ProvidersSelect } from 'components/LookupSelect';
import YearSubTab from '../YearSubTab';
import MonthSubTab from '../MonthSubTab';
import TodaySubTab from '../TodaySubTab';
import styles from './ImpactBonusTab.module.scss';

type PulseTabs = 'today' | 'month' | 'year';

const TABS: ReadonlyArray<PulseTabs> = ['today', 'month', 'year'];

const ImpactBonusTab: React.FunctionComponent = () => {
    const viewerSettings = useViewerSettings();
    const [currentTab, setCurrentTab] = React.useState<PulseTabs>('today');
    const translate = useTranslate();
    const tabs = React.useMemo(
        () =>
            TABS.map((name) => ({
                name,
                label: translate(
                    `vetspireExtension.providerPayment.impactBonus.subTabs.${name}`,
                ),
            })),
        [translate],
    );

    const changeCurrentTab = React.useCallback((tab: string) => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setCurrentTab(tab as PulseTabs);
            });
        });
    }, []);

    const [selectedProviders, setSelectedProviders] = React.useState<
        readonly Provider[]
    >([]);
    const providersQueryResult = useProviders();

    const timezone = useTimezone();

    const allowProviderAndDateFilter =
        viewerSettings.rights?.vetspireExtension_providerPayment ===
        ProviderRightValue.enabled_allRecords;

    React.useEffect(() => {
        const viewerProvider = providersQueryResult.providers.find(
            (prov) => prov.id === viewerSettings.viewer?.id,
        );

        if (viewerProvider) {
            // set provider if is has not yet been set
            // set provider if user is not allowed to see different providers but a different provider is selected
            if (
                selectedProviders.length !== 1 ||
                (!allowProviderAndDateFilter &&
                    viewerProvider.id !== selectedProviders?.[0]?.id)
            ) {
                setSelectedProviders([viewerProvider]);
            }
        }
    }, [
        providersQueryResult,
        viewerSettings,
        selectedProviders,
        timezone,
        allowProviderAndDateFilter,
    ]);

    return (
        <>
            {allowProviderAndDateFilter && (
                <div className={styles.providerFilter}>
                    <ProvidersSelect
                        providersQueryResult={providersQueryResult}
                        selectedProviders={[...selectedProviders]}
                        onChange={(providers) => {
                            if (providers.length === 1) {
                                setSelectedProviders([...providers]);
                            }
                        }}
                        addLocationGroup={false}
                        addOtherGroup={false}
                        isMulti={false}
                    />
                </div>
            )}
            <div className={styles.tabBarContainer}>
                <SubTabs
                    tabs={tabs}
                    currentTab={currentTab}
                    onChange={changeCurrentTab}
                />
            </div>
            {selectedProviders.length === 1 && currentTab === 'today' && (
                <TodaySubTab vetspireProviderId={selectedProviders[0].id} />
            )}
            {selectedProviders.length === 1 && currentTab === 'month' && (
                <MonthSubTab vetspireProviderId={selectedProviders[0].id} />
            )}
            {selectedProviders.length === 1 && currentTab === 'year' && (
                <YearSubTab vetspireProviderId={selectedProviders[0].id} />
            )}
        </>
    );
};

export default ImpactBonusTab;
