import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import {
    encounterQuery,
    EncounterQueryResult,
    EncounterQueryVariables,
} from 'api/encounters/queries';
import {
    surgeryFormPdfQuery,
    SurgeryFormPdfQueryResult,
    SurgeryFormPdfQueryVariables,
} from 'api/bond/queries';
import { vetspireContext } from 'context/VetspireContext';
import { closeFlyout, Page } from 'lib/vetspireActions';
import GeneralError from 'components/GeneralError/GeneralError';
import Alert from 'components/Alert';
import { useAnalytics } from 'hooks/useAnalytics';
import useUploadAndSendSurgeryForm from './hooks/useUploadAndSendSurgeryForm';
import ProgressSteps from './components/ProgressSteps';
import { ProgressStepType } from './types';
import SelectForm from './components/SelectForm';
import Medications from './components/Medications';
import Recheck from './components/Recheck';

import styles from './SurgeryForms.module.scss';
import Finished from './components/Finished/Finished';

interface SurgeryFormData {
    surgeryFormId: string | null;
    surgeryFormsOptionId: string | null;
    surgeryFormNote: string;
    medicationIds: string[];
}

const surgeryFormDataDefault: SurgeryFormData = {
    surgeryFormId: null,
    surgeryFormsOptionId: null,
    surgeryFormNote: '',
    medicationIds: [],
};

const SurgeryForms: React.FunctionComponent = () => {
    const translate = useTranslate();
    const analytics = useAnalytics();
    const previewRef = React.useRef<HTMLIFrameElement>(null);

    const [currentProgressStepType, setCurrentProgressStepType] =
        React.useState<ProgressStepType>(ProgressStepType.selectForm);

    const [surgeryFormData, setSurgeryFormData] =
        React.useState<SurgeryFormData>(surgeryFormDataDefault);

    const vetspire = React.useContext(vetspireContext);

    const [runEncounterQuery, encounterQueryResult] = useLazyQuery<
        EncounterQueryResult,
        EncounterQueryVariables
    >(encounterQuery, { fetchPolicy: 'no-cache' });

    const [encounterId, setEncounterId] = React.useState<string | null>(null);

    const [runSurgeryFormPdfQuery, surgeryFormPdfQueryResult] = useLazyQuery<
        SurgeryFormPdfQueryResult,
        SurgeryFormPdfQueryVariables
    >(surgeryFormPdfQuery, {
        fetchPolicy: 'no-cache',
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    const uploadAndSendSurgeryFormMutation = useUploadAndSendSurgeryForm();

    // reload encounter if page has changed
    React.useEffect(() => {
        // example: /clients/1111151/patients/2926428/encounters2/5057381

        const found = vetspire.pathName.match(
            /^\/clients\/\d+\/patients\/\d+\/encounters2?\/(\d+)/,
        );

        if (found) {
            if (found[1] !== encounterId) {
                console.info('SurgeryForms, encounterId: ', found[1]);
                runEncounterQuery({
                    variables: {
                        id: found[1],
                    },
                });

                setEncounterId(found[1]);
                setCurrentProgressStepType(ProgressStepType.selectForm);
                setSurgeryFormData(surgeryFormDataDefault);
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (encounterId !== null) {
                console.info('SurgeryForms, encounterId: null');
                setEncounterId(null);
                setCurrentProgressStepType(ProgressStepType.selectForm);
                setSurgeryFormData(surgeryFormDataDefault);
            }
        }
    }, [vetspire.pathName, runEncounterQuery, encounterId, surgeryFormData]);

    const [debounceTimer, setDebounceTimer] =
        React.useState<NodeJS.Timeout | null>(null);

    // debounce the preview update to avoid unnecessary renderings and flickering
    React.useEffect(() => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
            setDebounceTimer(null);
        }

        if (surgeryFormData.surgeryFormId && encounterId) {
            const variables: SurgeryFormPdfQueryVariables = {
                surgeryFormId: surgeryFormData.surgeryFormId,
                encounterId,
            };

            if (surgeryFormData.surgeryFormsOptionId) {
                variables.optionsId = surgeryFormData.surgeryFormsOptionId;
            }

            if (surgeryFormData.surgeryFormNote) {
                variables.note = surgeryFormData.surgeryFormNote;
            }

            if (surgeryFormData.medicationIds) {
                variables.medicationIds = surgeryFormData.medicationIds;
            }

            setDebounceTimer(
                setTimeout(() => {
                    runSurgeryFormPdfQuery({ variables });
                }, 250),
            );
        }
        return () => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
                setDebounceTimer(null);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encounterId, surgeryFormData]);

    const enabledSteps = React.useMemo(() => {
        if (currentProgressStepType === ProgressStepType.finished) {
            return [];
        }

        if (!surgeryFormData.surgeryFormId) {
            return [ProgressStepType.selectForm];
        }

        return [
            ProgressStepType.selectForm,
            ProgressStepType.medication,
            ProgressStepType.recheck,
        ];
    }, [surgeryFormData, currentProgressStepType]);

    React.useEffect(() => {
        setSurgeryFormData((current) => ({
            ...current,
            medicationIds:
                encounterQueryResult.data?.encounter?.medications
                    ?.filter(
                        (medication) => medication.frequency !== 'in clinic',
                    )
                    .map((medication) => medication.id) || [],
        }));
    }, [encounterQueryResult]);

    const renderForm = (): JSX.Element | null => {
        if (currentProgressStepType === ProgressStepType.selectForm) {
            return (
                <SelectForm
                    selectedSurgeryFormId={surgeryFormData.surgeryFormId}
                    onChange={(surgeryFormId: string) => {
                        setSurgeryFormData((current) => ({
                            ...current,
                            surgeryFormId,
                        }));
                    }}
                    onNext={() =>
                        setCurrentProgressStepType(ProgressStepType.medication)
                    }
                />
            );
        }
        if (currentProgressStepType === ProgressStepType.medication) {
            return (
                <Medications
                    medications={
                        encounterQueryResult.data?.encounter?.medications || []
                    }
                    selectedMedicationIds={surgeryFormData.medicationIds}
                    onChangeSelectedMedicationIds={(medicationIds) => {
                        setSurgeryFormData((current) => ({
                            ...current,
                            medicationIds,
                        }));
                    }}
                    onBack={() => {
                        setCurrentProgressStepType(ProgressStepType.medication);
                    }}
                    onNext={() =>
                        setCurrentProgressStepType(ProgressStepType.recheck)
                    }
                />
            );
        }
        if (currentProgressStepType === ProgressStepType.recheck) {
            return (
                <Recheck
                    encounterId={encounterId || 'dummy'}
                    surgeryFormsOptionId={surgeryFormData.surgeryFormsOptionId}
                    surgeryFormNote={surgeryFormData.surgeryFormNote}
                    onChange={(surgeryFormsOptionId, surgeryFormNote) => {
                        setSurgeryFormData((current) => ({
                            ...current,
                            surgeryFormsOptionId,
                            surgeryFormNote,
                        }));
                    }}
                    onBack={() => {
                        setCurrentProgressStepType(ProgressStepType.selectForm);
                    }}
                    onSend={() => {
                        if (surgeryFormData.surgeryFormId && encounterId) {
                            setCurrentProgressStepType(
                                ProgressStepType.finished,
                            );
                            uploadAndSendSurgeryFormMutation.uploadAndSendSurgeryForm(
                                surgeryFormData.surgeryFormId,
                                encounterId,
                                surgeryFormData.medicationIds,
                                surgeryFormData.surgeryFormsOptionId,
                                surgeryFormData.surgeryFormNote,
                            );

                            analytics.trackEvent(Page.surgeryForms, 'send', {
                                surgeryFormId: surgeryFormData.surgeryFormId,
                            });
                        }
                    }}
                />
            );
        }
        if (currentProgressStepType === ProgressStepType.finished) {
            return (
                <Finished
                    uploadAndSendSurgeryFormMutation={
                        uploadAndSendSurgeryFormMutation
                    }
                    onClose={closeFlyout}
                />
            );
        }
        return null;
    };

    let previewPage = 1;
    if (currentProgressStepType === ProgressStepType.medication) {
        previewPage =
            surgeryFormPdfQueryResult.data?.surgeryFormPdf?.medicationPage || 1;
    } else if (currentProgressStepType === ProgressStepType.recheck) {
        previewPage =
            surgeryFormPdfQueryResult.data?.surgeryFormPdf?.recheckPage || 1;
    }

    return (
        <div className={styles.container}>
            <div className={styles.previewContainer}>
                {surgeryFormData.surgeryFormId &&
                !surgeryFormPdfQueryResult.error &&
                surgeryFormPdfQueryResult.data?.surgeryFormPdf ? (
                    <div className={styles.previewBackground}>
                        <iframe
                            className={styles.preview}
                            ref={previewRef}
                            title="preview"
                            src={`data:application/pdf;base64,${surgeryFormPdfQueryResult.data?.surgeryFormPdf?.base64Content}#page=${previewPage}`}
                        />
                    </div>
                ) : (
                    // as long as we don't have a surgeryFormId, we render an empty box with the
                    // same background-color as the pdf preview has to avoid massive flickering
                    <div className={styles.previewBackground}>
                        {surgeryFormPdfQueryResult.loading && (
                            <div className={styles.previewLoading}>
                                <CircularProgress
                                    size="50px"
                                    color="inherit"
                                    className={styles.previewLoadingIcon}
                                />
                            </div>
                        )}

                        <div className={styles.noSurgeryFormSelected}>
                            {surgeryFormData.surgeryFormId &&
                            surgeryFormPdfQueryResult.error ? (
                                <GeneralError
                                    message={
                                        surgeryFormPdfQueryResult.error.message
                                    }
                                />
                            ) : (
                                !surgeryFormData.surgeryFormId &&
                                translate(
                                    encounterId
                                        ? 'vetspireExtension.surgeryForms.noSurgeryFormSelected'
                                        : 'vetspireExtension.surgeryForms.notAnEncounter',
                                )
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.flyout}>
                <div className={classnames(styles.flyoutBlock, styles.first)}>
                    <div className={styles.flyoutTitle}>
                        {translate('vetspireExtension.surgeryForms.title')}
                    </div>
                </div>
                <div
                    className={classnames(
                        styles.flyoutBlockFullWidth,
                        styles.noHorizontal,
                    )}
                >
                    <ProgressSteps
                        currentProgressStepType={currentProgressStepType}
                        enabledSteps={enabledSteps}
                        onChange={(progressStepType) =>
                            setCurrentProgressStepType(progressStepType)
                        }
                    />
                </div>

                {encounterId === null ? (
                    <Alert>
                        {translate(
                            'vetspireExtension.surgeryForms.notAnEncounter',
                        )}
                    </Alert>
                ) : (
                    renderForm()
                )}
            </div>
        </div>
    );
};

export default SurgeryForms;
