import { CircularProgress } from '@mui/material';
import * as React from 'react';
import classnames from 'classnames';
import { parse, getDay } from 'date-fns';
import { type Capacities, DATE_FORMAT } from '../../types';
import Day from './Day';
import styles from './MonthOverview.module.scss';

type MonthOverviewProps = {
    month: string;
    onSelectDate: (date: string) => void;
    capacities: Capacities;
    selectedDate: string | null;
    isAdmin: boolean;
    isLoading: boolean;
    viewerName: string | null;
};

export default function MonthOverview({
    month,
    onSelectDate,
    capacities,
    selectedDate,
    isAdmin,
    viewerName,
    isLoading,
}: MonthOverviewProps): JSX.Element {
    const renderedDays = React.useMemo<readonly JSX.Element[]>(() => {
        const start = parse(`${month}-01`, DATE_FORMAT, new Date());

        const days: JSX.Element[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'].map(
            (day, idx) => (
                <div key={`daylabel.${idx}`} className={styles.day__heading}>
                    {day}
                </div>
            ),
        );

        if (isLoading) {
            days.push(
                <div key="loading" className={styles.loading}>
                    <CircularProgress />
                </div>,
            );
        } else {
            // pre-fill with empty array elements if month doesn't start on a monday
            const numberOfDaysToPrefill = (getDay(start) + 6) % 7;

            days.push(
                ...Array(numberOfDaysToPrefill)
                    .fill(null)
                    .map((day, index) => (
                        <div
                            key={`prefill-day.${index}`}
                            className={classnames(styles.day, styles.empty)}
                        />
                    )),
            );

            for (const { date, ...rest } of Object.values(capacities)) {
                days.push(
                    <Day
                        key={date}
                        date={date}
                        {...rest}
                        onSelect={onSelectDate}
                        isSelected={date === selectedDate}
                        isAdmin={isAdmin}
                        viewerName={viewerName}
                    />,
                );
            }
        }

        return days;
    }, [
        month,
        capacities,
        onSelectDate,
        selectedDate,
        isAdmin,
        viewerName,
        isLoading,
    ]);

    return <div className={styles.container}>{renderedDays}</div>;
}
