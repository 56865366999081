import * as React from 'react';
import {
    FeeProduct,
    FeeProductType,
    FEE_PRODUCT_TYPE_NO_SHOW,
    FEE_PRODUCT_TYPE_CANCEL,
    FEE_PRODUCT_TYPE_RESCHEDULE,
    FEE_PRODUCT_TYPE_VERY_LATE_RESCHEDULE,
    FEE_PRODUCT_TYPE_VERY_LATE_CANCEL,
} from '@bondvet/types/noShows';
import { feeProductsQuery, FeeProductsQueryResult } from 'api/bond/queries';
import useCreditCardsQuery from './useCreditCardsQuery';

type UseFeeProducts = {
    loading: boolean;
    error: string | null;
    feeProducts?: FeeProduct[];
    pricesByType: Record<FeeProductType, number>;
};

export default function useFeeProducts(
    vetspireLocationId?: string,
): UseFeeProducts {
    const { data, loading, error } =
        useCreditCardsQuery<FeeProductsQueryResult>(feeProductsQuery, {
            variables: { vetspireLocationId },
        });

    const pricesByType = React.useMemo(
        () => ({
            noShow:
                data?.feeProducts?.find(
                    (feeProduct) => feeProduct._id === FEE_PRODUCT_TYPE_NO_SHOW,
                )?.price || 0,
            cancel:
                data?.feeProducts?.find(
                    (feeProduct) => feeProduct._id === FEE_PRODUCT_TYPE_CANCEL,
                )?.price || 0,
            reschedule:
                data?.feeProducts?.find(
                    (feeProduct) =>
                        feeProduct._id === FEE_PRODUCT_TYPE_RESCHEDULE,
                )?.price || 0,
            veryLateReschedule:
                data?.feeProducts?.find(
                    (feeProduct) =>
                        feeProduct._id ===
                        FEE_PRODUCT_TYPE_VERY_LATE_RESCHEDULE,
                )?.price || 0,
            veryLateCancel:
                data?.feeProducts?.find(
                    (feeProduct) =>
                        feeProduct._id === FEE_PRODUCT_TYPE_VERY_LATE_CANCEL,
                )?.price || 0,
        }),
        [data?.feeProducts],
    );

    return {
        loading,
        error: error?.message || null,
        feeProducts: data?.feeProducts,
        pricesByType,
    };
}
