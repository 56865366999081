import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import CloseIcon from '@mui/icons-material/Close';
import { closeFlyout, Page } from 'lib/vetspireActions';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'hooks/useAnalytics';
import styles from './MonthlyPulseReportFrame.module.scss';

const MonthlyPulseReportFrame: React.FunctionComponent = () => {
    const { search } = useLocation();
    const analytics = useAnalytics();
    const translate = useTranslate();
    const iframeRef = React.useRef<HTMLIFrameElement>(null);

    return (
        <div className={styles.container}>
            <div className={styles.reportIframeContainer}>
                <div className={styles.printButtonContainer}>
                    <button
                        className={styles.printButton}
                        onClick={() => {
                            analytics.trackEvent(
                                Page.providerPayment,
                                'print_monthly_report',
                            );
                            iframeRef.current?.focus();
                            iframeRef.current?.contentWindow?.print();
                        }}
                    >
                        {translate(
                            'vetspireExtension.monthlyPulseReport_v2.printReport',
                        )}
                    </button>

                    <CloseIcon
                        className={styles.close}
                        onClick={() => {
                            closeFlyout();
                        }}
                    />
                </div>
                <iframe
                    ref={iframeRef}
                    className={styles.reportIframe}
                    id="montly-pulse-report-frame"
                    name="montly-pulse-report"
                    title="montly-pulse-report"
                    src={`/${Page.pulseMonthlyReport}${search}`}
                />
            </div>
        </div>
    );
};

export default MonthlyPulseReportFrame;
