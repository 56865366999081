import * as React from 'react';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useTranslate } from '@bondvet/web-app-i18n/util';

import { ClientWithPatients } from '../../api';

import SmsConversationalOptInModal from '../SmsConversationalOptInModal';
import styles from './OutboundMessageBanner.module.scss';

type OutboundMessageBannerProps = {
    selectedClient: ClientWithPatients;
    setSelectedClient: (value: ClientWithPatients | null) => void;
    setShowSendTextModal: (show: boolean) => void;
    hasOpenTicket: boolean;
    loadingNewMessages: boolean;
    loadingOpenTickets: boolean;
    eventTriggeredReload: boolean;
};

export default function OutboundMessageBanner({
    selectedClient,
    setSelectedClient,
    setShowSendTextModal,
    hasOpenTicket,
    loadingNewMessages,
    loadingOpenTickets,
    eventTriggeredReload,
}: OutboundMessageBannerProps): React.ReactElement {
    const translate = useTranslate();
    const [showOptInModal, setShowOptInModal] = React.useState<boolean>(false);

    const optInClient = () => {
        setSelectedClient({
            ...selectedClient,
            optedIn: true,
        });
        setShowOptInModal(false);
    };

    if (eventTriggeredReload) {
        return (
            <div className={classnames(styles.banner, styles.white)}>
                <div className={styles.message}>
                    <p>
                        {translate(
                            'vetspireExtension.texting.conversationPane.proactiveMessagingBanner.eventTriggeredLoading',
                        )}
                    </p>
                    <CircularProgress />
                </div>
            </div>
        );
    }
    // If currently getting newer messages, show that
    if (loadingNewMessages || loadingOpenTickets) {
        return (
            <div className={classnames(styles.banner, styles.white)}>
                <div className={styles.message}>
                    <p>
                        {translate(
                            loadingNewMessages
                                ? 'vetspireExtension.texting.conversationPane.proactiveMessagingBanner.loadingMessages'
                                : 'vetspireExtension.texting.conversationPane.proactiveMessagingBanner.loadingTickets',
                        )}
                    </p>
                    <CircularProgress />
                </div>
            </div>
        );
    }

    // Show whether there is an open ticket, or if the client can be proactively messaged
    if (selectedClient.optedIn) {
        return (
            <div
                className={classnames(styles.banner, {
                    [styles.blue]: !hasOpenTicket,
                    [styles.white]: hasOpenTicket,
                })}
            >
                {hasOpenTicket ? (
                    <div className={styles.message}>
                        {translate(
                            'vetspireExtension.texting.conversationPane.proactiveMessagingBanner.hasOpenTicket',
                        )}
                    </div>
                ) : (
                    <div className={styles.message}>
                        {translate(
                            'vetspireExtension.texting.conversationPane.proactiveMessagingBanner.noOpenTicket',
                        )}

                        <Button
                            className={styles.sendTextButton}
                            onClick={() => setShowSendTextModal(true)}
                        >
                            {translate(
                                'vetspireExtension.texting.conversationPane.proactiveMessagingBanner.sendAText',
                            )}
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    // If the client is opted out, show opted out message and prompt to opt them in
    return (
        <>
            <div className={styles.notOptedInDivider}>
                <span>Client is not opted in to customer support texts</span>
            </div>
            <div className={classnames(styles.banner, styles.orange)}>
                <div
                    className={classnames(styles.message, styles.optInMessage)}
                >
                    <p>
                        {translate(
                            'vetspireExtension.texting.conversationPane.proactiveMessagingBanner.notOptedIn',
                        )}
                    </p>
                    <Button
                        className={styles.optInButton}
                        onClick={() => setShowOptInModal(true)}
                    >
                        {translate(
                            'vetspireExtension.texting.conversationPane.proactiveMessagingBanner.optInClient',
                        )}
                    </Button>
                </div>
                {showOptInModal && selectedClient && (
                    <SmsConversationalOptInModal
                        client={selectedClient}
                        onSubmit={optInClient}
                        onClose={() => setShowOptInModal(false)}
                    />
                )}
            </div>
        </>
    );
}
