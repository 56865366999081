import CloseIcon from '@mui/icons-material/Close';
import { InputAdornment, TextField } from '@mui/material';
import classnames from 'classnames';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import * as React from 'react';
import useGiftCardsMutation from 'hooks/useGiftCardsMutation';
import useLocationId from 'hooks/useLocationId';
import Button from '@mui/material/Button';
import { useAnalytics } from 'hooks/useAnalytics';
import { refundOrder as refundOrderMutation } from 'api/clients/mutations';
import { Page } from 'lib/vetspireActions';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CheckIcon from '@mui/icons-material/Check';
import useTranslate from 'hooks/useTranslate';
import {
    type GiftCardTransaction,
    GraphQLGiftCardWithDetails,
    RefundOrderArguments,
    RefundOrderResult,
} from '@bondvet/types/giftCards';

import styles from './RefundTransactionDialog.module.scss';

type RefundTransactionDialogProps = {
    onClose: (refetch: boolean) => void;
    clientId: string;
    giftCard: GraphQLGiftCardWithDetails;
    transaction: GiftCardTransaction;
};

export default function RefundTransactionDialog({
    clientId,
    onClose,
    giftCard,
    transaction,
}: RefundTransactionDialogProps) {
    const vetspireLocationId = useLocationId();
    const analytics = useAnalytics();

    const moneyFormatter = useMoneyFormatter({
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
    });

    const translate = useTranslate();

    const [amountError, setAmountError] = React.useState<string | null>(null);
    const [refundError, setRefundError] = React.useState<string | null>(null);

    const [refundOrder, { loading: updating }] = useGiftCardsMutation<
        RefundOrderResult,
        RefundOrderArguments
    >(refundOrderMutation);

    const [amountS, setAmountS] = React.useState('');

    const [success, setSuccess] = React.useState(false);

    const { last3 } = giftCard;

    const maxRefund =
        Math.abs(transaction.amount) - (transaction.amountRefunded ?? 0);

    const handleSubmit = React.useCallback(
        async (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            const amount = Number.parseFloat(amountS);

            setAmountError(null);
            setRefundError(null);

            if (!amount) {
                setAmountError('tooSmall');
                return;
            }

            if (amount > maxRefund) {
                setAmountError('tooLarge');
                return;
            }

            if (transaction.orderId) {
                try {
                    const { errors } = await refundOrder({
                        variables: {
                            vetspireLocationId,
                            vetspireClientId: clientId,
                            cardId: giftCard._id,
                            amount,
                            orderId: transaction.orderId,
                        },
                    });

                    if (errors) {
                        setRefundError(errors[0].message);
                    } else {
                        analytics.trackEvent(
                            Page.clientDetails,
                            'refund_invoice',
                        );

                        setSuccess(true);
                    }
                } catch (error) {
                    setRefundError((error as Error).message);
                }
            }
        },
        [
            amountS,
            maxRefund,
            transaction.orderId,
            refundOrder,
            vetspireLocationId,
            clientId,
            giftCard._id,
            analytics,
        ],
    );

    if (success) {
        return (
            <Dialog
                open
                onClose={() => onClose(true)}
                classes={{ root: styles.root }}
            >
                <DialogTitle className={styles.title}>
                    {translate(
                        'vetspireExtension.clientDetails.payments.giftCards.refundDialog.title',
                        {
                            last3,
                        },
                        {
                            renderInnerHtml: true,
                        },
                    )}
                </DialogTitle>
                <DialogContent className={styles.content}>
                    <div className={styles.success}>
                        <CheckIcon fontSize="large" />
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.refundDialog.success',
                        )}
                    </div>
                </DialogContent>
                <DialogActions className={styles.actions}>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={() => onClose(true)}
                        className={styles.close}
                    >
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.refundDialog.close',
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog
            open
            onClose={() => onClose(false)}
            classes={{ root: styles.root }}
        >
            <DialogTitle className={styles.title}>
                {translate(
                    'vetspireExtension.clientDetails.payments.giftCards.refundDialog.title',
                    {
                        last3,
                    },
                    {
                        renderInnerHtml: true,
                    },
                )}
                <button
                    onClick={() => onClose(false)}
                    type="button"
                    className={styles.closeButton}
                >
                    <CloseIcon />
                </button>
            </DialogTitle>
            <DialogContent className={styles.content}>
                <div className={styles.text}>
                    {translate(
                        'vetspireExtension.clientDetails.payments.giftCards.refundDialog.text',
                        {
                            invoiceId: transaction.orderId,
                            last3,
                        },
                    )}
                </div>
                <div className={styles.block}>
                    <div>
                        <strong>
                            {translate(
                                'vetspireExtension.clientDetails.payments.giftCards.refundDialog.invoice',
                                {
                                    invoiceId: transaction.orderId,
                                },
                            )}
                        </strong>
                    </div>
                    <div>
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.refundDialog.amountCharged',
                            {
                                amount: moneyFormatter.format(
                                    Math.abs(transaction.amount),
                                ),
                            },
                            {
                                renderInnerHtml: true,
                            },
                        )}
                    </div>
                    <div>
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.refundDialog.amountRefunded',
                            {
                                amount: moneyFormatter.format(
                                    Math.abs(transaction.amountRefunded ?? 0),
                                ),
                            },
                            {
                                renderInnerHtml: true,
                            },
                        )}
                    </div>
                </div>

                <div className={styles.block}>
                    <div>
                        <strong>
                            {translate(
                                'vetspireExtension.clientDetails.payments.giftCards.refundDialog.confirm',
                            )}
                        </strong>
                    </div>
                    <div>
                        {translate(
                            'vetspireExtension.clientDetails.payments.giftCards.refundDialog.confirmNote',
                        )}
                    </div>
                    <div className={styles.amountLine}>
                        <div>
                            {translate(
                                'vetspireExtension.clientDetails.payments.giftCards.refundDialog.amountToRefund',
                            )}
                        </div>

                        <div>
                            <TextField
                                type="number"
                                value={amountS}
                                onChange={(e) => setAmountS(e.target.value)}
                                onWheel={(e) =>
                                    (e.target as HTMLInputElement).blur()
                                }
                                inputProps={{
                                    min: 0,
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        root: styles.input,
                                    },
                                }}
                            />
                        </div>

                        <div>
                            {translate(
                                'vetspireExtension.clientDetails.payments.giftCards.refundDialog.amountOf',
                                {
                                    amount: moneyFormatter.format(
                                        Math.abs(maxRefund),
                                    ),
                                },
                            )}
                        </div>
                    </div>
                    {amountError && (
                        <div className={styles.error}>
                            {translate(
                                `vetspireExtension.clientDetails.payments.giftCards.refundDialog.errors.${amountError}`,
                            )}
                        </div>
                    )}
                </div>

                <div className={classnames(styles.block, styles.note)}>
                    {translate(
                        'vetspireExtension.clientDetails.payments.giftCards.refundDialog.note',
                    )}
                </div>

                {refundError && (
                    <div className={styles.error}>{refundError}</div>
                )}
            </DialogContent>
            <DialogActions className={styles.actions}>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={updating}
                    className={styles.submit}
                >
                    {translate(
                        'vetspireExtension.clientDetails.payments.giftCards.refundDialog.submit',
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
