import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import Select, { type SingleValue } from 'react-select';
import lookupStyles from 'components/LookupSelect/LookupSelect.module.scss';
import type { ProviderAndSlots } from '../../types';
import Badge from '../Badge';
import styles from './ProviderSelect.module.scss';

type ProviderSelectProps = {
    value: string | null;
    onChange: (value: string | null) => void;
    providers: readonly ProviderAndSlots[];
};

type ProviderOption = ProviderAndSlots & {
    value: string;
};

function formatOptionLabel(provider: ProviderAndSlots) {
    return (
        <div className={styles.option}>
            {provider.name}
            <Badge>{provider.numberOfAvailableForExtendedAccess}</Badge>
        </div>
    );
}

export default function ProviderSelect({
    value,
    onChange,
    providers,
}: ProviderSelectProps) {
    const translate = useTranslate();
    const handleChange = React.useCallback(
        (newValue: SingleValue<ProviderAndSlots>) => {
            onChange(newValue?.id ?? null);
        },
        [onChange],
    );
    const options = React.useMemo(
        () =>
            providers.map((provider) => ({
                ...provider,
                value: provider.id,
            })),
        [providers],
    );
    const selected = React.useMemo(
        () => options.find((provider) => provider.id === value) ?? null,
        [value, options],
    );
    const isOptionSelected = React.useCallback(
        (provider: ProviderAndSlots) => {
            return !!value && provider.id === value;
        },
        [value],
    );

    return (
        <>
            <label
                htmlFor="intraday-capacity-provider"
                className={styles.label}
            >
                {translate('vetspireExtension.intradayCapacity.provider')}
            </label>
            <Select<ProviderOption, false>
                id="intraday-capacity-provider"
                isMulti={false}
                name="provider"
                value={selected}
                className={classnames(lookupStyles.lookup, styles.select)}
                classNamePrefix="lookup-select"
                menuPosition="fixed"
                menuPlacement="auto"
                onChange={handleChange}
                options={options}
                formatOptionLabel={formatOptionLabel}
                isOptionSelected={isOptionSelected}
                placeholder={translate(
                    'vetspireExtension.intradayCapacity.selectProviderPlaceholder',
                )}
            />
        </>
    );
}
