import { useMutation } from '@apollo/client';
import { ExecutableDefinitionNode } from 'graphql/language/ast';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import {
    hideAppointment as hideAppointmentMutation,
    HideAppointmentResult,
    HideAppointmentVariables,
} from '../../api/mutations';
import { surgeryAppointments } from '../../api/queries';

type HideAppointment = {
    processing: boolean;
    error?: string;
    onHide: () => void;
};

export default function useHideAppointment(
    appointmentId: string,
): HideAppointment {
    const [hideAppointment, { loading, error }] = useMutation<
        HideAppointmentResult,
        HideAppointmentVariables
    >(hideAppointmentMutation, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        variables: { appointmentId },
        refetchQueries: surgeryAppointments.definitions
            .map(
                (definition) =>
                    (definition as ExecutableDefinitionNode).name?.value || '',
            )
            .filter((name) => !!name),
    });

    return {
        processing: loading,
        error: error?.graphQLErrors?.reduce(
            (message, graphQLError) =>
                [message, graphQLError.message].join('\n'),
            '',
        ),
        onHide: () => {
            hideAppointment();
        },
    };
}
