import * as React from 'react';
import classnames from 'classnames';
import styles from './BlockOffLogEvents.module.scss';
import {
    ChangeProviderNameBlockOffEvent,
    EVENT_DATETIME_FORMAT,
} from '../../types';

export const ChangeProviderName = ({
    event,
}: {
    event: ChangeProviderNameBlockOffEvent;
}): React.ReactElement => (
    <div className={styles.textLine}>
        <div>
            <div className={classnames(styles.bullet, styles.bgCreated)} />
        </div>
        <div className={styles.textLeft}>
            <span className={styles.eventDatetime}>
                [{event.eventDatetime.format(EVENT_DATETIME_FORMAT)}]
            </span>
            <span>
                {event.cancelName} moved &quot;{event.appointmentTypeName}&quot;
                to {event.providerName}
            </span>
        </div>
    </div>
);
export default ChangeProviderName;
