import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Translated } from '@bondvet/web-app-i18n/util';
import {
    chargeFeeProduct,
    ChargeFeeProductResult,
    ChargeFeeProductVariables,
} from 'api/clients/mutations';
import classnames from 'classnames';
import { formatAmountAsUSD } from 'components/CreditCards/util';
import TextArea from 'components/Form/TextArea';
import { useAnalytics } from 'hooks/useAnalytics';
import useBookingMutation from 'hooks/useBookingMutation';
import useBookingQuery from 'hooks/useBookingQuery';
import useCancellationSettings from 'hooks/useCancellationSettings';
import useGlobalBookingSettings from 'hooks/useGlobalBookingSettings';
import useVetspireMutation from 'hooks/useVetspireMutation';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { getFallbackTimezone } from 'lib/utils';
import {
    openInvoice,
    openVetspirePage,
    Page,
    updateCalendar,
} from 'lib/vetspireActions';
import * as React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useFeeProducts from 'hooks/useFeeProducts';
import useTranslate from 'hooks/useTranslate';
import { useLocation } from 'react-router-dom';
import { FeeProductType } from '@bondvet/types/noShows';
import { formatInTimeZone } from 'date-fns-tz';
import type {
    AppointmentData,
    AddLateCancelRescheduleWaiveResult,
    AddLateCancelRescheduleWaiveVariables,
    CancelAppointmentArguments,
    WaiveReason,
} from '@bondvet/types/booking';
import type { CancellationReason } from '@bondvet/types/clientApp/settings';
import type { OperationResult } from '@bondvet/types';
import useViewerSettings from 'hooks/useViewerSettings';
import ChoosePaymentMethod, { type SubmitRef } from '../ChoosePaymentMethod';
import SelectWaiveReason from '../SelectWaiveReason';
import SelectCancelReason from '../SelectCancelReason';
import LifetimeStats from '../LifetimeStats';
import useRescheduledAppointmentPreview from './useRescheduledAppointmentPreview';
import { ReactComponent as SuccessSVG } from './assets/success.svg';
import { ReactComponent as PatientSVG } from './assets/patient.svg';
import { ReactComponent as StartSVG } from './assets/start.svg';
import { ReactComponent as ProviderSVG } from './assets/provider.svg';
import { ReactComponent as LocationSVG } from './assets/location.svg';

import styles from './ChargeFee.module.scss';

const appointmentQuery = gql`
    query appointment($id: ID!) {
        appointment(id: $id) {
            start
            location {
                id
                name
                timezone
            }
            patient {
                id
                name
            }
            provider {
                id
                name
            }
        }
    }
`;

const addLateCancelRescheduleWaiveMutation = gql`
    mutation addLateCancelRescheduleWaive(
        $input: LateCancelRescheduleWaiveInput!
    ) {
        addLateCancelRescheduleWaive(input: $input) {
            success
            error
        }
    }
`;

const cancelAppointmentMutation = gql`
    mutation cancelAppointment(
        $appointmentId: ID!
        $reason: String!
        $confirmationHash: String
        $description: String
        $provider: String
    ) {
        cancelAppointment(
            appointmentId: $appointmentId
            reason: $reason
            description: $description
            origin: vetspire
            confirmationHash: $confirmationHash
            provider: $provider
        ) {
            success
            error
        }
    }
`;

const appointmentDataQuery = gql`
    query getAppointmentData($vetspireAppointmentId: String!) {
        appointmentData(vetspireAppointmentId: $vetspireAppointmentId) {
            id
            confirmationHash
        }
    }
`;

type AppointmentDataQuery = {
    appointmentData: AppointmentData;
};

type AppointmentDataQueryVariables = {
    vetspireAppointmentId: string;
};

interface AppointmentQueryResult {
    appointment: {
        id: string;
        start: string;
        insertedAt: string;
        location: {
            id: string;
            name: string;
            timezone: string;
        };
        patient: {
            id: string;
            name: string;
        };
        provider: null | {
            id: string;
            name: string;
        };
    };
}

interface AppointmentQueryVariables {
    id: string;
}

type CancelAppointmentMutationResult = {
    cancelAppointment: OperationResult;
};

interface ChargeFeeProps {
    clientId: string;
    feeProductType: FeeProductType;
    onClose: (closePage: boolean) => void;
}

export default function ChargeFee({
    clientId,
    feeProductType,
    onClose,
}: ChargeFeeProps) {
    const analytics = useAnalytics();
    const translate = useTranslate();

    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const { viewer } = useViewerSettings();
    const appointmentId = urlParams.get('appointmentId') as string | null;
    const locationId = urlParams.get('locationId') as string | null;
    const patientId = urlParams.get('patientId') as string | null;
    const originalQueryS = urlParams.get('originalQuery') as string | null;
    const originalVariablesS = urlParams.get('originalVariables') as
        | string
        | null;

    const { loading: isFeeProductsLoading, pricesByType } = useFeeProducts(
        locationId || undefined,
    );
    const { loading: isGlobalBookingSettingsLoading, settingsById } =
        useGlobalBookingSettings();
    const { cancellationSettings } = useCancellationSettings();
    const cancellationReasons = React.useMemo(
        () =>
            cancellationSettings.cancellationReasons.filter(
                (cancellationReason) => !cancellationReason.obsolete,
            ),
        [cancellationSettings.cancellationReasons],
    );

    const [selectedCancellationReason, setSelectedCancellationReason] =
        React.useState<CancellationReason | null>(null);

    const [cancelDescription, setCancelDescription] = React.useState('');

    const { loading: isAppointmentIdLoading, data: appointmentData } =
        useBookingQuery<AppointmentDataQuery, AppointmentDataQueryVariables>(
            appointmentDataQuery,
            {
                skip: !appointmentId,
                variables: { vetspireAppointmentId: appointmentId as string },
            },
        );

    const [
        addLateCancelRescheduleWaive,
        { loading: addLateCancelRescheduleWaiveLoading },
    ] = useBookingMutation<
        AddLateCancelRescheduleWaiveResult,
        AddLateCancelRescheduleWaiveVariables
    >(addLateCancelRescheduleWaiveMutation);
    const [cancelAppointment, { loading: cancelAppointmentMutationLoading }] =
        useBookingMutation<
            CancelAppointmentMutationResult,
            Omit<CancelAppointmentArguments, 'origin'> & {
                __noAuth: true;
            }
        >(cancelAppointmentMutation);

    const settingsId = React.useMemo(() => {
        switch (feeProductType) {
            case 'cancel':
                return 'chargeLateCancelFee';
            case 'veryLateCancel':
                return 'chargeVeryLateCancelFee';
            case 'reschedule':
                return 'chargeLateRescheduleFee';
            case 'veryLateReschedule':
                return 'chargeVeryLateRescheduleFee';
            default:
                throw new Error(
                    `internal error: unexpected fee type: [${feeProductType}]`,
                );
        }
    }, [feeProductType]);

    const [getAppointment, { data: updatedAppointmentData }] = useLazyQuery<
        AppointmentQueryResult,
        AppointmentQueryVariables
    >(appointmentQuery, { fetchPolicy: 'no-cache' });

    const [showWaive, setShowWaive] = React.useState(false);

    const [selectedWaiveReason, setSelectedWaiveReason] =
        React.useState<WaiveReason | null>(null);

    const [waiveReasonOtherNote, setWaiveReasonOtherNote] = React.useState('');

    const [showSuccess, setShowSuccess] = React.useState<
        'charged' | 'waived' | null
    >(null);

    const [error, setError] = React.useState<Translated | null>(null);

    const isCancellation =
        feeProductType === 'cancel' || feeProductType === 'veryLateCancel';
    const isReschedule =
        feeProductType === 'reschedule' ||
        feeProductType === 'veryLateReschedule';

    const {
        loading: reschedulePreviewLoading,
        appointment: reschedulePreview,
    } = useRescheduledAppointmentPreview(
        isCancellation,
        appointmentId,
        originalVariablesS,
    );

    const cancelReasonValid = !isCancellation || !!selectedCancellationReason;
    const cancelDescriptionValid =
        !isCancellation ||
        !!cancelDescription ||
        (!!selectedCancellationReason &&
            !selectedCancellationReason.requiresExplanation);

    const validateCancelReason = React.useCallback((): boolean => {
        if (!cancelReasonValid) {
            setError(
                translate(
                    'vetspireExtension.clientDetails.payments.chargeFee.errors.cancelReasonNotSet',
                ),
            );
            return false;
        }

        if (!cancelDescriptionValid) {
            setError(
                translate(
                    'vetspireExtension.clientDetails.payments.chargeFee.errors.cancelDescriptionNotSet',
                ),
            );
            return false;
        }

        return true;
    }, [cancelDescriptionValid, cancelReasonValid, translate]);

    const onGoToWaive = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (validateCancelReason()) {
                setError(null);
                setShowWaive(true);
            }
        },
        [validateCancelReason],
    );

    const originalQuery = React.useMemo(() => {
        return gql`
            ${originalQueryS}
        `;
    }, [originalQueryS]);
    const [runOriginalQuery, { loading: originalQueryLoading }] =
        useVetspireMutation(originalQuery);

    const paramsError = React.useMemo(() => {
        if (!appointmentId) {
            return 'internalErrorAppointmentId';
        }
        if (!locationId) {
            return 'internalErrorLocationId';
        }
        if (!patientId) {
            return 'internalErrorPatientId';
        }
        if (!originalQueryS) {
            return 'internalErrorOriginalQuery';
        }
        if (!originalVariablesS) {
            return 'internalErrorOriginalVariables';
        }
        return null;
    }, [
        appointmentId,
        locationId,
        originalQueryS,
        originalVariablesS,
        patientId,
    ]);

    const [runChargeFeeProductMutation, { loading: chargeFeeProductLoading }] =
        useMutation<ChargeFeeProductResult, ChargeFeeProductVariables>(
            chargeFeeProduct,
            {
                context: { clientName: GRAPHQL_CLIENT_NAMES.creditCards },
            },
        );

    const submitRef = React.useRef<SubmitRef>(null);

    const onCancel = React.useCallback(() => {
        onClose(true);
    }, [onClose]);

    const finishOperation = React.useCallback(async () => {
        if (isCancellation) {
            if (
                appointmentData?.appointmentData &&
                selectedCancellationReason
            ) {
                const { id, confirmationHash } =
                    appointmentData.appointmentData;
                await cancelAppointment({
                    variables: {
                        appointmentId: id,
                        confirmationHash,
                        reason: selectedCancellationReason.value,
                        description:
                            selectedCancellationReason.requiresExplanation
                                ? cancelDescription
                                : undefined,
                        provider: viewer?.id,
                        __noAuth: true,
                    },
                });
            } else if (originalQuery && originalVariablesS) {
                // This happens when an appointment is deleted in Vetspire and has not yet been synchronized to
                // our MongoDB (it can take up to 5 minutes for the appointment to be synchronized).
                // As this is an edge case that should never happen as long as the 1-hour grace period is active,
                // we agreed to delete the appointment from Vetspire without storing the cancellation reason.

                console.warn(
                    'Appointment not in sync, deleting it from Vetspire without storing the cancellation reason.',
                );

                await runOriginalQuery({
                    variables: JSON.parse(originalVariablesS),
                });
            }
        }

        if (isReschedule) {
            if (originalQuery && originalVariablesS) {
                await runOriginalQuery({
                    variables: JSON.parse(originalVariablesS),
                });
            }

            await getAppointment({
                variables: {
                    id: appointmentId || '',
                },
            });
        }
    }, [
        isCancellation,
        isReschedule,
        appointmentData?.appointmentData,
        selectedCancellationReason,
        cancelAppointment,
        cancelDescription,
        originalQuery,
        originalVariablesS,
        getAppointment,
        appointmentId,
        runOriginalQuery,
        viewer?.id,
    ]);

    const onWaive = React.useCallback(
        async (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            if (error) {
                setError(null);
            }

            if (!selectedWaiveReason?.id) {
                setError(
                    translate(
                        'vetspireExtension.clientDetails.payments.chargeFee.errors.waiveReasonNotSet',
                    ),
                );
                return;
            }

            if (
                selectedWaiveReason.id === 'other' &&
                !waiveReasonOtherNote.trim()
            ) {
                setError(
                    translate(
                        'vetspireExtension.clientDetails.payments.chargeFee.errors.waiveReasonOtherNoteNotSet',
                    ),
                );
                return;
            }

            const result = await addLateCancelRescheduleWaive({
                variables: {
                    input: {
                        vetspireLocationId: locationId || '',
                        vetspireClientId: clientId || '',
                        vetspireAppointmentId: appointmentId || '',
                        feeProductType,
                        waiveReasonId: selectedWaiveReason?.id || '',
                        waiveReasonOtherNote,
                    },
                },
            });

            if (result?.data?.addLateCancelRescheduleWaive.success) {
                await finishOperation();

                setShowSuccess('waived');

                updateCalendar();

                analytics.trackEvent(
                    Page.clientDetails,
                    `waive_fee_product_${feeProductType}`,
                    {
                        clientId,
                        appointmentId,
                        feeProductType,
                        waiveReason: selectedWaiveReason?.id,
                        waiveReasonOtherNote,
                    },
                );
            } else if (result?.data?.addLateCancelRescheduleWaive.error) {
                setError(result.data.addLateCancelRescheduleWaive.error);
            } else {
                setError(
                    translate(
                        'vetspireExtension.clientDetails.payments.chargeFee.errors.internalError',
                    ),
                );
            }
        },
        [
            addLateCancelRescheduleWaive,
            analytics,
            appointmentId,
            clientId,
            error,
            feeProductType,
            finishOperation,
            locationId,
            selectedWaiveReason?.id,
            translate,
            waiveReasonOtherNote,
        ],
    );

    const onCharge = React.useCallback(
        async (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (!validateCancelReason()) {
                return;
            }

            if (error) {
                setError(null);
            }

            if (locationId && submitRef.current) {
                const validCreditCard = await submitRef.current.submit();
                if (!validCreditCard) {
                    setError(
                        translate(
                            'vetspireExtension.clientDetails.payments.chargeFee.errors.invalidCreditCard',
                        ),
                    );
                    return;
                }

                const result = await runChargeFeeProductMutation({
                    variables: {
                        clientId,
                        locationId,
                        feeProductType,
                    },
                });

                if (result?.data?.chargeFeeProduct.success) {
                    await finishOperation();

                    setShowSuccess('charged');

                    analytics.trackEvent(
                        Page.clientDetails,
                        `charge_fee_product_${feeProductType}`,
                        {
                            clientId,
                            appointmentId,
                            feeProductType,
                        },
                    );

                    const invoiceId = result.data.chargeFeeProduct?.invoiceId;

                    if (invoiceId && patientId && clientId) {
                        openInvoice(clientId, patientId, invoiceId);
                    }
                } else if (result?.data?.chargeFeeProduct.error) {
                    setError(result.data.chargeFeeProduct.error);
                } else {
                    setError(
                        translate(
                            'vetspireExtension.clientDetails.payments.chargeFee.errors.internalError',
                        ),
                    );
                }
            }
        },
        [
            analytics,
            appointmentId,
            clientId,
            error,
            feeProductType,
            finishOperation,
            locationId,
            patientId,
            runChargeFeeProductMutation,
            translate,
            validateCancelReason,
        ],
    );

    const isLoading =
        isFeeProductsLoading ||
        isGlobalBookingSettingsLoading ||
        chargeFeeProductLoading ||
        addLateCancelRescheduleWaiveLoading ||
        cancelAppointmentMutationLoading ||
        isAppointmentIdLoading ||
        originalQueryLoading ||
        reschedulePreviewLoading;

    if (showSuccess) {
        return (
            <Dialog className={styles.success} open={true}>
                <DialogContent className={styles.content}>
                    <SuccessSVG />
                    <div className={styles.successTitle}>
                        {translate(
                            `vetspireExtension.clientDetails.payments.chargeFee.${feeProductType}.success.${showSuccess}`,
                        )}
                    </div>
                    {isReschedule && (
                        <div className={styles.updated}>
                            <div className={styles.title}>
                                {translate(
                                    'vetspireExtension.clientDetails.payments.chargeFee.updatedVisitDetails',
                                )}
                            </div>
                            <ul>
                                <li>
                                    <PatientSVG />
                                    {updatedAppointmentData?.appointment
                                        ?.patient?.name || ''}
                                </li>
                                <li>
                                    <StartSVG />
                                    {updatedAppointmentData?.appointment?.start
                                        ? formatInTimeZone(
                                              new Date(
                                                  updatedAppointmentData.appointment.start,
                                              ),
                                              updatedAppointmentData.appointment
                                                  ?.location?.timezone ||
                                                  getFallbackTimezone(),
                                              "EEEE, MMMM do, yyyy 'at' h:mm a",
                                          )
                                        : ''}
                                </li>
                                <li>
                                    <ProviderSVG />
                                    {updatedAppointmentData?.appointment
                                        ?.provider?.name || ''}
                                </li>
                                <li>
                                    <LocationSVG />
                                    {updatedAppointmentData?.appointment
                                        ?.location?.name || ''}
                                </li>
                            </ul>
                        </div>
                    )}
                </DialogContent>
                <DialogActions className={styles.actions}>
                    {isReschedule && (
                        <Button
                            className={styles.close}
                            type="button"
                            onClick={() => {
                                openVetspirePage(
                                    `/clients/${clientId}/patients/${patientId}/chart`,
                                );
                                onClose(true);
                            }}
                            disabled={isLoading}
                        >
                            {translate(
                                'vetspireExtension.clientDetails.payments.chargeFee.goToVisit',
                            )}
                        </Button>
                    )}
                    <Button
                        className={styles.close}
                        type="button"
                        onClick={() => onClose(true)}
                        disabled={isLoading}
                        variant="contained"
                    >
                        {translate(
                            'vetspireExtension.clientDetails.payments.chargeFee.close',
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog className={styles.root} open={true}>
            <DialogTitle>
                {translate(
                    `vetspireExtension.clientDetails.payments.chargeFee.${feeProductType}.title`,
                )}
            </DialogTitle>
            <DialogContent className={styles.content}>
                <div className={styles.note}>
                    <div className={styles.noteTitle}>
                        {translate(
                            `vetspireExtension.clientDetails.payments.chargeFee.${feeProductType}.noteTitle`,
                        )}
                    </div>
                    <div className={styles.noteText}>
                        {translate(
                            `vetspireExtension.clientDetails.payments.chargeFee.${feeProductType}.noteText`,
                            {
                                lateCancelHours:
                                    settingsById.chargeLateCancelFee.hours,
                                veryLateCancelHours:
                                    settingsById.chargeVeryLateCancelFee.hours,
                                lateRescheduleHours:
                                    settingsById.chargeLateRescheduleFee.hours,
                                veryLateRescheduleHours:
                                    settingsById.chargeVeryLateRescheduleFee
                                        .hours,
                            },
                            {
                                renderInnerHtml: true,
                            },
                        )}
                    </div>
                    <div>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                                translate(
                                    'vetspireExtension.clientDetails.payments.chargeFee.policyLink',
                                ) as string
                            }
                        >
                            {translate(
                                'vetspireExtension.clientDetails.payments.chargeFee.policyLinkText',
                            )}
                        </a>
                    </div>
                </div>

                <LifetimeStats
                    clientId={clientId}
                    noHeader
                    className={styles.lifeTimeStats}
                />

                {!isLoading && isReschedule && reschedulePreview && (
                    <div className={styles.reschedulePreview}>
                        <div className={styles.label}>
                            {translate(
                                'vetspireExtension.clientDetails.payments.chargeFee.updatedVisitDetails',
                            )}
                        </div>
                        <div>
                            {translate(
                                'vetspireExtension.clientDetails.payments.chargeFee.reschedule.preview.details',
                                {
                                    location: reschedulePreview.locationName,
                                    date: reschedulePreview.date,
                                    time: reschedulePreview.time,
                                },
                            )}
                            {reschedulePreview.providerName &&
                                translate(
                                    'vetspireExtension.clientDetails.payments.chargeFee.reschedule.preview.provider',
                                    {
                                        provider:
                                            reschedulePreview.providerName.replace(
                                                /^Dr\.\s+/,
                                                '',
                                            ),
                                    },
                                )}
                        </div>
                    </div>
                )}

                <div>
                    {isLoading && (
                        <>
                            <CircularProgress
                                size={50}
                                classes={{ root: styles.loading }}
                            />
                        </>
                    )}
                    {!isLoading && showWaive && (
                        <>
                            {isCancellation && (
                                <div>
                                    <div
                                        className={classnames(
                                            styles.cancelReasonDisplay,
                                            styles.label,
                                        )}
                                    >
                                        {translate(
                                            'vetspireExtension.clientDetails.payments.chargeFee.cancelReason.title',
                                        )}
                                    </div>
                                    <div className={styles.cancelReasonDisplay}>
                                        {selectedCancellationReason?.label}
                                        {selectedCancellationReason?.requiresExplanation &&
                                            ` - ${cancelDescription}`}
                                    </div>
                                </div>
                            )}
                            <div>
                                <div
                                    className={classnames(
                                        styles.waiveReasonNote,
                                        styles.label,
                                    )}
                                >
                                    {translate(
                                        'vetspireExtension.clientDetails.payments.chargeFee.waiveReason.title',
                                    )}
                                </div>
                                <SelectWaiveReason
                                    waiveReasons={
                                        settingsById[settingsId]
                                            ?.waiveReasons || []
                                    }
                                    selectedWaiveReason={selectedWaiveReason}
                                    setSelectedWaiveReason={
                                        setSelectedWaiveReason
                                    }
                                    error={!!error && !selectedWaiveReason?.id}
                                />
                                {selectedWaiveReason?.id === 'other' && (
                                    <>
                                        <TextArea
                                            id="otherNote"
                                            className={classnames(
                                                styles.waiveReasonOtherNote,
                                                {
                                                    [styles.textAreaError]:
                                                        !!error,
                                                },
                                            )}
                                            required
                                            rows={3}
                                            label={translate(
                                                'vetspireExtension.clientDetails.payments.chargeFee.waiveReason.otherLabel',
                                            )}
                                            value={waiveReasonOtherNote}
                                            onChange={(value) =>
                                                setWaiveReasonOtherNote(value)
                                            }
                                            placeholder={
                                                translate(
                                                    'vetspireExtension.clientDetails.payments.chargeFee.waiveReason.otherPlaceholder',
                                                ) as string
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {!isLoading && !showWaive && (
                        <>
                            {isCancellation && (
                                <div className={styles.cancelReason}>
                                    <div
                                        className={classnames(
                                            styles.cancelReasonNote,
                                            styles.label,
                                        )}
                                    >
                                        {translate(
                                            'vetspireExtension.clientDetails.payments.chargeFee.cancelReason.title',
                                        )}
                                    </div>
                                    <SelectCancelReason
                                        cancellationReasons={
                                            cancellationReasons
                                        }
                                        selectedCancellationReason={
                                            selectedCancellationReason
                                        }
                                        setSelectedCancellationReason={(
                                            reason,
                                        ) => {
                                            setSelectedCancellationReason(
                                                reason,
                                            );
                                            setError(null);
                                        }}
                                        error={!!error && !cancelReasonValid}
                                    />

                                    {selectedCancellationReason?.requiresExplanation && (
                                        <TextArea
                                            id="cancelDescription"
                                            className={classnames(
                                                styles.waiveReasonOtherNote,
                                                {
                                                    [styles.textAreaError]:
                                                        !!error &&
                                                        !cancelDescriptionValid,
                                                },
                                            )}
                                            required
                                            rows={3}
                                            label={translate(
                                                'vetspireExtension.clientDetails.payments.chargeFee.cancelReason.descriptionLabel',
                                            )}
                                            value={cancelDescription}
                                            onChange={(value) => {
                                                setError(null);
                                                setCancelDescription(value);
                                            }}
                                            placeholder={
                                                translate(
                                                    'vetspireExtension.clientDetails.payments.chargeFee.cancelReason.descriptionPlaceholder',
                                                ) as string
                                            }
                                        />
                                    )}
                                </div>
                            )}

                            <ChoosePaymentMethod
                                clientId={clientId}
                                error={
                                    !!error &&
                                    !!selectedCancellationReason &&
                                    (!selectedCancellationReason.requiresExplanation ||
                                        !!cancelDescription)
                                }
                                ref={submitRef}
                            />
                        </>
                    )}
                </div>
                {!isLoading && !showWaive && (
                    <div>
                        {translate(
                            `vetspireExtension.clientDetails.payments.chargeFee.${feeProductType}.summary`,
                            {
                                lateCancelFee: formatAmountAsUSD(
                                    pricesByType.cancel * 100,
                                ),
                                veryLateCancelFee: formatAmountAsUSD(
                                    pricesByType.veryLateCancel * 100,
                                ),
                                lateRescheduleFee: formatAmountAsUSD(
                                    pricesByType.reschedule * 100,
                                ),
                                veryLateRescheduleFee: formatAmountAsUSD(
                                    pricesByType.veryLateReschedule * 100,
                                ),
                            },
                            {
                                renderInnerHtml: true,
                            },
                        )}
                    </div>
                )}
                {error && <div className={styles.error}>{error}</div>}
                {paramsError && (
                    <div className={styles.error}>
                        {translate(
                            `vetspireExtension.clientDetails.payments.chargeFee.errors.${paramsError}`,
                        )}
                    </div>
                )}
                <div className={styles.actions}>
                    <Button
                        className={styles.cancel}
                        type="button"
                        onClick={onCancel}
                        disabled={isLoading}
                        color="error"
                    >
                        {translate(
                            'vetspireExtension.clientDetails.payments.chargeFee.abort',
                        )}
                    </Button>
                    {showWaive ? (
                        <>
                            <Button
                                className={styles.waive}
                                type="button"
                                onClick={() => {
                                    setError(null);
                                    setShowWaive(false);
                                }}
                                disabled={isLoading || !!paramsError}
                            >
                                {translate(
                                    'vetspireExtension.clientDetails.payments.chargeFee.back',
                                )}
                            </Button>
                            <Button
                                className={styles.submit}
                                type="button"
                                onClick={onWaive}
                                disabled={isLoading || !!paramsError}
                                variant="contained"
                            >
                                {translate(
                                    `vetspireExtension.clientDetails.payments.chargeFee.${feeProductType}.submitWaive`,
                                )}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                className={styles.waive}
                                type="button"
                                variant={isCancellation ? 'outlined' : 'text'}
                                onClick={onGoToWaive}
                                disabled={isLoading || !!paramsError}
                            >
                                {translate(
                                    'vetspireExtension.clientDetails.payments.chargeFee.waive',
                                )}
                            </Button>
                            <Button
                                className={styles.submit}
                                type="button"
                                onClick={onCharge}
                                disabled={isLoading || !!paramsError}
                                variant="contained"
                            >
                                {translate(
                                    `vetspireExtension.clientDetails.payments.chargeFee.${feeProductType}.submitCharge`,
                                )}
                            </Button>
                        </>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}
