import { gql } from '@apollo/client';
import { ImpactScoreV2OfProvider } from '@bondvet/types/gainSharing';

export const providerScheduleLocation = gql`
    query providerScheduleLocation(
        $vetspireProviderId: ID!
        $date: DateString!
    ) {
        providerScheduleLocation(
            vetspireProviderId: $vetspireProviderId
            date: $date
        ) {
            vetspireLocationId
            locationId
            timezone
        }
    }
`;

export interface ProviderScheduleLocationResult {
    providerScheduleLocation: {
        vetspireLocationId: string | null | undefined;
        locationId: string | null | undefined;
        timezone: string | null | undefined;
    };
}

export const impactScoreV2OfProvider = gql`
    query impactScoreV2OfProvider(
        $vetspireProviderId: ID!
        $from: DateString!
        $to: DateString!
    ) {
        impactScoreV2OfProvider(
            vetspireProviderId: $vetspireProviderId
            from: $from
            to: $to
        ) {
            payout
            payoutPerDay {
                _id
                date
                payout
                progress
            }
        }
    }
`;

export interface ImpactScoreV2OfProviderQueryVariables {
    vetspireProviderId: string;
    from: string;
    to: string;
}

export interface ImpactScoreV2OfProviderQueryResult {
    impactScoreV2OfProvider: ImpactScoreV2OfProvider;
}
