import { gql } from '@apollo/client';
import usePatientPlansQuery from 'hooks/usePatientPlansQuery';
import {
    ClientHasPatientsWithActivePlansQueryArguments,
    ClientHasPatientsWithActivePlansQueryResult,
} from '@bondvet/types/patientPlans';

const query = gql`
    query clientHasPatientsWithActivePlans($vetspireClientId: ID!) {
        clientHasPatientsWithActivePlans(vetspireClientId: $vetspireClientId)
    }
`;

interface UseClientHasPatientsWithActivePlans {
    clientHasPatientsWithActivePlans: boolean | null;
    loading: boolean;
}

export default function useClientHasPatientsWithActivePlans(
    vetspireClientId: string | null | undefined,
): UseClientHasPatientsWithActivePlans {
    const { data, loading } = usePatientPlansQuery<
        ClientHasPatientsWithActivePlansQueryResult,
        ClientHasPatientsWithActivePlansQueryArguments
    >(query, {
        variables: {
            vetspireClientId: vetspireClientId ?? '',
        },
        skip: !vetspireClientId,
    });

    if (loading) {
        return {
            clientHasPatientsWithActivePlans: null,
            loading: true,
        };
    }

    return {
        clientHasPatientsWithActivePlans:
            data?.clientHasPatientsWithActivePlans ?? false,
        loading: false,
    };
}
