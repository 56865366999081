import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { FeeProductType } from '@bondvet/types/noShows';
import { formatInTimeZone } from 'date-fns-tz';
import { ReactComponent as FirstFreeCancelRescheduleSVG } from './assets/firstFreeCancelReschedule.svg';
import { LateCancel, LateReschedule, NoShow } from '../../types';
import styles from './LifetimeStatsModal.module.scss';

export type LifetimeStatsModalProps = {
    type: FeeProductType;
    appointments:
        | readonly NoShow[]
        | readonly LateCancel[]
        | readonly LateReschedule[];
    onClose: () => void;
};

const mapDate = (
    date: Date | null | undefined,
    timezone: string,
    customFormat?: string,
) => {
    if (!date) {
        return 'No Date';
    }

    return formatInTimeZone(
        new Date(date),
        timezone,
        customFormat ?? 'M/d/yy, h:mmaaa',
    );
};

const renderIcon = (feeCharged: boolean | null, waivingReason?: string) => {
    if (feeCharged === null) {
        return <div>N/A</div>;
    }
    if (feeCharged) {
        return <CheckIcon className={styles.feeChargedIcon} />;
    }

    if (waivingReason === 'first_free_cancel_reschedule') {
        return (
            <FirstFreeCancelRescheduleSVG
                className={styles.firstFreeCancelRescheduleIcon}
            />
        );
    }

    return <CloseIcon className={styles.feeWaivedIcon} />;
};

function LifetimeStatsModal({
    type,
    appointments,
    onClose,
}: LifetimeStatsModalProps): React.ReactElement {
    const translate = useTranslate();

    const renderNoShowTableContent = React.useCallback(() => {
        return (appointments as readonly NoShow[]).map((item) => {
            let error = item.noShowChargeLastError ?? '';
            if (error.includes('StripeTools.preparePaymentIds')) {
                error = translate(
                    'vetspireExtension.clientDetails.payments.lifetimeStats.table.noValidCardOnFile',
                ) as string;
            }

            return (
                <TableRow key={item.id}>
                    <TableCell className={styles.cell}>
                        <div>
                            {mapDate(item.appointmentDate, item.timezone)}
                        </div>
                        <div>{item.location}</div>
                    </TableCell>
                    <TableCell className={styles.cell}>
                        {renderIcon(item.noShowCharged ?? null)}
                        {item.noShowCharged === false && error && (
                            <Tooltip
                                classes={{ tooltip: styles.feeInfoTooltip }}
                                placement="top-start"
                                title={
                                    <div className={styles.feeInfoTooltipText}>
                                        <div>{error}</div>
                                    </div>
                                }
                            >
                                <InfoIcon className={styles.feeInfoIcon} />
                            </Tooltip>
                        )}
                    </TableCell>
                </TableRow>
            );
        });
    }, [appointments, translate]);

    const renderWaivingReason = React.useCallback(
        (waivingReason: string) => {
            if (waivingReason === 'first_free_cancel_reschedule') {
                return translate(
                    'vetspireExtension.clientDetails.payments.lifetimeStats.table.firstFreeCancelReschedule',
                );
            }

            return waivingReason;
        },
        [translate],
    );

    const renderLateCancelTableContent = React.useCallback(() => {
        return (appointments as readonly LateCancel[]).map((item) => (
            <TableRow key={item.id}>
                <TableCell className={styles.cell}>
                    {mapDate(item?.eventDate, item.timezone)}
                </TableCell>
                <TableCell className={styles.cell}>{item.name}</TableCell>
                <TableCell className={styles.cell}>
                    <div>{mapDate(item.appointmentDate, item.timezone)}</div>
                    <div>{item.location}</div>
                </TableCell>
                <TableCell className={styles.cell}>
                    {renderIcon(item.feeCharged, item.waivingReason)}
                    {item.feeCharged === false && item.waivingReason && (
                        <Tooltip
                            classes={{ tooltip: styles.feeInfoTooltip }}
                            placement="top-start"
                            title={
                                <div className={styles.feeInfoTooltipText}>
                                    <strong>
                                        {translate(
                                            'vetspireExtension.clientDetails.payments.lifetimeStats.table.reasonForWaiving',
                                        )}
                                    </strong>
                                    <div>
                                        {renderWaivingReason(
                                            item.waivingReason,
                                        )}
                                    </div>
                                </div>
                            }
                        >
                            <InfoIcon className={styles.feeInfoIcon} />
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
        ));
    }, [appointments, renderWaivingReason, translate]);

    const renderLateRescheduleTableContent = React.useCallback(() => {
        return (appointments as readonly LateReschedule[]).map((item) => (
            <TableRow key={`${item.id}|${item.eventDate}`}>
                <TableCell className={styles.cell}>
                    {mapDate(item?.eventDate, item.timezone)}
                </TableCell>
                <TableCell className={styles.cell}>{item.name}</TableCell>
                <TableCell className={styles.cell}>
                    <div>{mapDate(item.appointmentDate, item.timezone)}</div>
                    <div>{item.location}</div>
                </TableCell>
                <TableCell className={styles.cell}>
                    <div>
                        {mapDate(
                            item.newAppointment.appointmentDate,
                            item.timezone,
                        )}
                    </div>
                    <div>{item.newAppointment.location}</div>
                </TableCell>
                <TableCell className={styles.cell}>
                    {renderIcon(item.feeCharged, item.waivingReason)}
                    {item.feeCharged === false && item.waivingReason && (
                        <Tooltip
                            classes={{ tooltip: styles.feeInfoTooltip }}
                            placement="top-start"
                            title={
                                <div className={styles.feeInfoTooltipText}>
                                    <strong>
                                        {translate(
                                            'vetspireExtension.clientDetails.payments.lifetimeStats.table.reasonForWaiving',
                                        )}
                                    </strong>
                                    <div>
                                        {renderWaivingReason(
                                            item.waivingReason,
                                        )}
                                    </div>
                                </div>
                            }
                        >
                            <InfoIcon className={styles.feeInfoIcon} />
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
        ));
    }, [appointments, renderWaivingReason, translate]);

    const tableBody = React.useMemo(() => {
        switch (type) {
            case 'noShow':
                return renderNoShowTableContent();
            case 'cancel':
            case 'veryLateCancel':
                return renderLateCancelTableContent();
            case 'reschedule':
            case 'veryLateReschedule':
                return renderLateRescheduleTableContent();
            default:
                return null;
        }
    }, [
        type,
        renderNoShowTableContent,
        renderLateCancelTableContent,
        renderLateRescheduleTableContent,
    ]);

    const showLateCancelField = type !== 'noShow';
    const showLateRescheduleField =
        type === 'reschedule' || type === 'veryLateReschedule';

    return (
        <Dialog
            onClose={() => onClose()}
            open
            classes={{
                paper: styles.modalContent,
            }}
        >
            <span className={styles.modalTitle}>
                {translate(
                    `vetspireExtension.clientDetails.payments.lifetimeStats.${type}`,
                )}
            </span>
            <CloseIcon
                className={styles.close}
                onClick={() => {
                    onClose();
                }}
            />
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            {showLateCancelField && (
                                <TableCell className={styles.cell}>
                                    {translate(
                                        'vetspireExtension.clientDetails.payments.lifetimeStats.table.timestamp',
                                    )}
                                </TableCell>
                            )}
                            {showLateCancelField && (
                                <TableCell className={styles.cell}>
                                    {translate(
                                        `vetspireExtension.clientDetails.payments.lifetimeStats.table.${
                                            showLateRescheduleField
                                                ? 'rescheduledBy'
                                                : 'cancelledBy'
                                        }`,
                                    )}
                                </TableCell>
                            )}
                            <TableCell className={styles.cell}>
                                {translate(
                                    'vetspireExtension.clientDetails.payments.lifetimeStats.table.originalAppt',
                                )}
                            </TableCell>
                            {showLateRescheduleField && (
                                <TableCell className={styles.cell}>
                                    {translate(
                                        'vetspireExtension.clientDetails.payments.lifetimeStats.table.newAppt',
                                    )}
                                </TableCell>
                            )}
                            <TableCell className={styles.cell}>
                                {translate(
                                    'vetspireExtension.clientDetails.payments.lifetimeStats.table.feeCharged',
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{tableBody}</TableBody>
                </Table>
            </div>
        </Dialog>
    );
}

export default LifetimeStatsModal;
