import * as React from 'react';
import { ApolloError } from '@apollo/client/errors';
import { useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import { Location } from '@bondvet/types/locations';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { getFallbackTimezone } from 'lib/utils';
import {
    AppointmentLog,
    AppointmentLogQueryResult,
    AppointmentLogQueryVariables,
    appointmentLogQuery,
    appointmentLogLocationQuery,
    AppointmentLogEvent,
} from 'api/bond/queries';
import useProviders from 'hooks/useProviders';
import useLocationSchedules from 'hooks/useLocationSchedules';
import { AppointmentLogFilterType } from '../components/AppointmentLogFilter/types';
import {
    CancelAppointmentEvent,
    CreateAppointmentEvent,
    NoShowAppointmentEvent,
    RescheduledAppointmentEvent,
    TEAM_VETSPIRE_USERNAME,
    USER_TYPE_SELECTED_CLIENT,
    USER_TYPE_SELECTED_TEAM,
    UserTypeSelectOption,
} from '../types';

export type AppointmentLogData = {
    appointmentLog: ReadonlyArray<AppointmentLog>;
    all: number;
    cancelled: number;
    rescheduled: number;
    noShow: number;
};

export type AppointmentLogs = {
    loading: boolean;
    error?: ApolloError;
    data: AppointmentLogData;
    missingData: boolean;
};

export default function useAppointmentLogs(
    day: Moment,
    locationIds: ReadonlyArray<string>,
    sameDayChangesOnly: boolean,
    selectedUserTypes: ReadonlyArray<UserTypeSelectOption>,
): AppointmentLogs {
    const CLIENT_VETSPIRE_ACCOUNT_NAME = 'Team';
    const { data, loading, error } = useQuery<
        AppointmentLogQueryResult,
        AppointmentLogQueryVariables
    >(appointmentLogQuery, {
        variables: { day: day.format('YYYY-MM-DD'), locationIds },
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        fetchPolicy: 'network-only',
    });

    const providersQueryResult = useProviders();

    const locationsQueryResult = useQuery<{
        locations: ReadonlyArray<Location>;
    }>(appointmentLogLocationQuery, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        fetchPolicy: 'cache-and-network',
    });

    const locationSchedulesQueryResult = useLocationSchedules({ locationIds });

    const decoratedAppointmentLogsWithStats = React.useMemo(() => {
        let appointments = data?.appointmentLog;
        const { providers } = providersQueryResult;
        const locations = locationsQueryResult.data?.locations;
        const { locationSchedules } = locationSchedulesQueryResult;

        if (appointments && providers && locations && locationSchedules) {
            const stats = {
                all: 0,
                cancelled: 0,
                rescheduled: 0,
                noShow: 0,
            };
            const sameDay = (date: string, tz?: string) => {
                const timezone = tz || getFallbackTimezone();
                const day1 = day.format('YYYY-MM-DD');
                const day2 = moment.tz(date, timezone).format('YYYY-MM-DD');
                return day1 === day2;
            };

            const getProviderName = (
                event: AppointmentLogEvent,
                locationId: string,
            ): string | undefined => {
                if (event.metadata?.provider_id) {
                    return providers.find(
                        (providerName) =>
                            event.metadata?.provider_id === providerName.id,
                    )?.name;
                }
                if (event.metadata?.schedule_id) {
                    return locationSchedules.find(
                        (locationSchedule) =>
                            locationSchedule._id.toString() ===
                                event.metadata?.schedule_id &&
                            locationSchedule.locationId === locationId,
                    )?.name;
                }
                return undefined;
            };

            const userTypeSelected = (
                decoratedEvent:
                    | CreateAppointmentEvent
                    | RescheduledAppointmentEvent
                    | CancelAppointmentEvent
                    | NoShowAppointmentEvent,
            ) =>
                selectedUserTypes.length === 0 ||
                decoratedEvent.type === AppointmentLogFilterType.noShow ||
                (decoratedEvent.cancelName === TEAM_VETSPIRE_USERNAME &&
                    selectedUserTypes.find(
                        (selectedUserType) =>
                            selectedUserType.value ===
                            USER_TYPE_SELECTED_CLIENT,
                    )) ||
                (decoratedEvent.cancelName !== TEAM_VETSPIRE_USERNAME &&
                    selectedUserTypes.find(
                        (selectedUserType) =>
                            selectedUserType.value === USER_TYPE_SELECTED_TEAM,
                    ));

            // map the location over the location id
            appointments = appointments
                .map((apptLog) => ({
                    ...apptLog,
                    location: locations.find(
                        (l) => l._id === apptLog.locationId,
                    ),
                }))
                .map((apptLog: AppointmentLog): AppointmentLog => {
                    // filter out unnecessary items and change the format so that the user
                    const decoratedEvents: (
                        | CreateAppointmentEvent
                        | RescheduledAppointmentEvent
                        | CancelAppointmentEvent
                        | NoShowAppointmentEvent
                    )[] = [];
                    let oldAppointmentDate: string;
                    let oldProviderName: string;
                    if (
                        apptLog.events &&
                        apptLog.events.find(
                            (event) => event.type === 'appointment.create',
                        ) != null
                    ) {
                        [...apptLog.events]
                            .sort((a, b) =>
                                a.datetime.localeCompare(b.datetime),
                            )
                            .forEach((event) => {
                                if (event.type === 'appointment.create') {
                                    // create event
                                    decoratedEvents.push({
                                        id: event.id,
                                        type: AppointmentLogFilterType.create,
                                        eventDatetime: event.datetime,
                                        apptDatetime:
                                            event.metadata?.start || '',
                                        providerName:
                                            getProviderName(
                                                event,
                                                apptLog.locationId,
                                            ) || '',
                                        cancelName:
                                            event.provider.name ===
                                            CLIENT_VETSPIRE_ACCOUNT_NAME
                                                ? TEAM_VETSPIRE_USERNAME
                                                : event.provider.name,
                                    });
                                } else if (
                                    (event.type === 'appointment.delete' ||
                                        (event.type === 'appointment.update' &&
                                            event.metadata?.status ===
                                                'cancelled')) &&
                                    decoratedEvents.filter(
                                        (event2) =>
                                            event2.type ===
                                            AppointmentLogFilterType.cancelled,
                                    ).length === 0
                                ) {
                                    // cancelled event
                                    const decoratedEvent: CancelAppointmentEvent =
                                        {
                                            id: event.id,
                                            type: AppointmentLogFilterType.cancelled,
                                            eventDatetime: event.datetime,
                                            cancelName:
                                                event.provider.name ===
                                                CLIENT_VETSPIRE_ACCOUNT_NAME
                                                    ? TEAM_VETSPIRE_USERNAME
                                                    : event.provider.name,
                                            apptDatetime: oldAppointmentDate,
                                            providerName: oldProviderName,
                                        };
                                    decoratedEvents.push(decoratedEvent);

                                    if (
                                        sameDay(
                                            oldAppointmentDate,
                                            apptLog.location?.timezone,
                                        ) &&
                                        ((sameDayChangesOnly &&
                                            sameDay(
                                                event.datetime,
                                                apptLog.location?.timezone,
                                            )) ||
                                            !sameDayChangesOnly) &&
                                        userTypeSelected(decoratedEvent)
                                    ) {
                                        stats.cancelled += 1;
                                    }
                                } else if (
                                    event.type === 'appointment.update'
                                ) {
                                    if (
                                        event.metadata?.start ||
                                        event.metadata?.provider_id
                                    ) {
                                        // rescheduled event
                                        const decoratedEvent: RescheduledAppointmentEvent =
                                            {
                                                id: event.id,
                                                type: AppointmentLogFilterType.rescheduled,
                                                eventDatetime: event.datetime,
                                                cancelName:
                                                    event.provider.name ===
                                                    CLIENT_VETSPIRE_ACCOUNT_NAME
                                                        ? TEAM_VETSPIRE_USERNAME
                                                        : event.provider.name,
                                                oldApptDatetime:
                                                    oldAppointmentDate || '',
                                                newApptDatetime:
                                                    event.metadata.start || '',
                                                oldProviderName,
                                                newProviderName:
                                                    getProviderName(
                                                        event,
                                                        apptLog.locationId,
                                                    ) || oldProviderName,
                                            };
                                        decoratedEvents.push(decoratedEvent);

                                        if (
                                            (sameDay(
                                                event.metadata.start || '',
                                                apptLog.location?.timezone,
                                            ) ||
                                                sameDay(
                                                    oldAppointmentDate,
                                                    apptLog.location?.timezone,
                                                )) &&
                                            ((sameDayChangesOnly &&
                                                sameDay(
                                                    event.datetime,
                                                    apptLog.location?.timezone,
                                                )) ||
                                                !sameDayChangesOnly) &&
                                            userTypeSelected(decoratedEvent)
                                        ) {
                                            stats.rescheduled += 1;
                                        }
                                    }
                                }

                                // update the oldProviderName and oldAppointmentDate (if changed)
                                // when the event is rescheduled we also need the old date and provider
                                if (
                                    event.metadata?.provider_id ||
                                    event.metadata?.schedule_id
                                ) {
                                    oldProviderName =
                                        getProviderName(
                                            event,
                                            apptLog.locationId,
                                        ) || '';
                                }
                                if (event.metadata?.start) {
                                    oldAppointmentDate = event.metadata.start;
                                }
                            });
                    }
                    // lastly add also the no-show event
                    if (apptLog.vetspireScheduleName?.match(/^no.shows?$/i)) {
                        if (sameDay(apptLog.date, apptLog.location?.timezone)) {
                            stats.noShow += 1;
                        }
                        decoratedEvents.push({
                            id: `${apptLog._id} no-show`,
                            type: AppointmentLogFilterType.noShow,
                            eventDatetime: apptLog.date,
                        });
                    }

                    return {
                        ...apptLog,
                        decoratedEvents,
                    };
                })
                .filter(
                    (appointmentLog: AppointmentLog) =>
                        appointmentLog.decoratedEvents.length > 0,
                );
            stats.all = stats.cancelled + stats.rescheduled + stats.noShow;
            return {
                ...stats,
                appointmentLog: appointments,
            };
        }
        return {
            all: 0,
            cancelled: 0,
            rescheduled: 0,
            noShow: 0,
            appointmentLog: [],
        };
    }, [
        data?.appointmentLog,
        providersQueryResult,
        locationsQueryResult.data?.locations,
        day,
        locationSchedulesQueryResult,
        sameDayChangesOnly,
        selectedUserTypes,
    ]);

    const missingData = React.useMemo(
        () =>
            !!data?.appointmentLog &&
            data?.appointmentLog.find(
                ({ events }) =>
                    !events ||
                    events.find(
                        (event) => event.type === 'appointment.create',
                    ) == null,
            ) != null,
        [data?.appointmentLog],
    );

    return React.useMemo(
        () => ({
            loading:
                loading ||
                providersQueryResult.loading ||
                locationsQueryResult.loading ||
                locationSchedulesQueryResult.loading,
            error:
                error ||
                providersQueryResult.error ||
                locationsQueryResult.error,
            data: decoratedAppointmentLogsWithStats,
            missingData,
        }),
        [
            decoratedAppointmentLogsWithStats,
            error,
            loading,
            locationSchedulesQueryResult.loading,
            locationsQueryResult.error,
            locationsQueryResult.loading,
            missingData,
            providersQueryResult.error,
            providersQueryResult.loading,
        ],
    );
}
