import { createTheme } from '@mui/material/styles';

export default createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '50px',
                        '& fieldset': {
                            borderColor: '#CCCCCC',
                        },
                        '&:hover fieldset': {
                            borderColor: '#0054C5',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#0054C5',
                        },
                        '& .MuiSelect-select': {
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    color: 'black',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: '50px',
                    height: '37px',
                    '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
                icon: {
                    color: 'gray',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    height: '37px',
                    '& .MuiInputBase-root': {
                        height: '37px',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#0054C5',
                    },
                    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                        padding: '0 7px',
                        color: '#103559',
                    },
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                        top: '-7px',
                        color: '#103559',
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    '.MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                            color: '#536D87',
                        },
                    },
                },
            },
        },
    },
});
