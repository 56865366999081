import * as React from 'react';
import Select, { MultiValue } from 'react-select';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import useTranslate from 'hooks/useTranslate';
import styles from 'components/LookupSelect/LookupSelect.module.scss';

interface Option {
    value: string;
    label: string;
}

interface EncounterTypeSelectProps {
    selectedEncounterTypes: Option[];
    setSelectedEncounterTypes: (userType: Option[]) => void;
    options: Option[];
}

const EncounterTypeSelect: React.FunctionComponent<
    EncounterTypeSelectProps
> = ({ selectedEncounterTypes, setSelectedEncounterTypes, options }) => {
    const translate = useTranslate();

    return (
        <Select
            isMulti
            name="encounterTypes"
            value={selectedEncounterTypes}
            className={styles.lookup}
            classNamePrefix="lookup-select"
            options={options}
            onChange={(value: MultiValue<Option>) => {
                setSelectedEncounterTypes((value as Option[]) || []);
            }}
            placeholder={
                <div className={styles.placeholder}>
                    <PersonOutlineOutlinedIcon
                        className={styles.placeholderIcon}
                    />
                    {translate(
                        `vetspireExtension.encounters.encounterTypeFilterPlaceholder`,
                    )}
                </div>
            }
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
        />
    );
};
export default EncounterTypeSelect;
