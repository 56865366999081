import type { IsomorphicTimestamp } from '../firebase';
import { VetspireAppointmentStatus, type IdAndName } from '../vetspire';

export type SurgeryReferralQuestionBase = {
    label: string;
    required: boolean;
};

export type SurgeryReferralTextQuestion = SurgeryReferralQuestionBase & {
    type: 'text';
};

export type SurgeryReferralRadiosQuestion = SurgeryReferralQuestionBase & {
    type: 'radios';
    options: ReadonlyArray<string>;
};

export type SurgeryReferralCheckboxesQuestion = SurgeryReferralQuestionBase & {
    type: 'checkboxes';
    options: ReadonlyArray<string>;
};

export type SurgeryReferralDropdownQuestion = SurgeryReferralQuestionBase & {
    type: 'dropdown';
    options: ReadonlyArray<string>;
};

export type SurgeryReferralQuestion = (
    | SurgeryReferralTextQuestion
    | SurgeryReferralRadiosQuestion
    | SurgeryReferralCheckboxesQuestion
    | SurgeryReferralDropdownQuestion
) & { options: ReadonlyArray<string> };

export type GeneralSurgeryReferralQuestion = SurgeryReferralQuestion & {
    _id: string;
    createdAt: Date;
    updatedAt: Date;
};

export type GraphQLGeneralSurgeryReferralQuestion = Pick<
    GeneralSurgeryReferralQuestion,
    '_id' | 'createdAt' | 'updatedAt'
> & {
    question: SurgeryReferralQuestion;
};

export type GeneralSurgeryReferralQuestionInput = Omit<
    GeneralSurgeryReferralQuestion,
    '_id' | 'createdAt' | 'updatedAt'
>;

export type SurgeryReferralAnswerBase = {
    label: string;
};

export type SurgeryReferralTextAnswer = SurgeryReferralAnswerBase & {
    type: SurgeryReferralTextQuestion['type'];
    answer: string;
};

export type SurgeryReferralRadiosAnswer = SurgeryReferralAnswerBase & {
    type: SurgeryReferralRadiosQuestion['type'];
    answer: string;
};

export type SurgeryReferralCheckboxesAnswer = SurgeryReferralAnswerBase & {
    type: SurgeryReferralCheckboxesQuestion['type'];
    answers: ReadonlyArray<string>;
};

export type SurgeryReferralDropdownAnswer = SurgeryReferralAnswerBase & {
    type: SurgeryReferralDropdownQuestion['type'];
    answer: string;
};

export type SurgeryReferralAnswer =
    | SurgeryReferralTextAnswer
    | SurgeryReferralRadiosAnswer
    | SurgeryReferralCheckboxesAnswer
    | SurgeryReferralDropdownAnswer;

export interface LinkedInvoice {
    // Vetspire ID of the invoice linked to this referral
    invoiceId: string;
    // Vetspire provider ID of the user who linked the referral to an invoice
    linkedBy: string;
    linkedAt: Date;
}

export const RECOMMENDED_DATE_EMERGENT = 'Emergent';
export const RECOMMENDED_DATE_URGENT = 'Urgent (within the next few weeks)';
export const RECOMMENDED_DATE_NEXT_SIX_MONTHS =
    'Sometime within the next 6 months';
export const RECOMMENDED_DATE_OTHER = 'Other - describe in notes';

export const RECOMMENDED_SURGEON_NO_PREFERENCE = 'noPreference';
export const RECOMMENDED_SURGEON_MYSELF = 'myself';

export type RecommendedDate =
    | typeof RECOMMENDED_DATE_EMERGENT
    | typeof RECOMMENDED_DATE_URGENT
    | typeof RECOMMENDED_DATE_NEXT_SIX_MONTHS
    | typeof RECOMMENDED_DATE_OTHER;

export const PREFERRED_CLINIC_NO_PREFERENCE = 'noPreference';

export type PreferredClinic = string | typeof PREFERRED_CLINIC_NO_PREFERENCE;

/**
 * collection: `surgeryReferrals.referrals`
 *
 * also stored in Firestore in `surgeryReferrals.referrals`
 */
export interface SurgeryReferral {
    /**
     * internal, alphanumeric id (e.g. "ppJ3FgGDPiBWWG4z5")
     */
    _id: string;
    /**
     * Vetpsire client ID
     */
    clientId: string;
    /**
     * Vetspire patient ID
     */
    patientId: string;
    /**
     * Vetspire provider ID of the provider who submitted the referral
     */
    providerId: string;
    /**
     * Vetspire location ID of the location that was selected in Vetspire
     * when the SX referral was sent
     */
    locationId: string;
    recommendForEducationalFund: boolean;
    surgeryTypeId: string;
    answers: ReadonlyArray<SurgeryReferralAnswer>;
    notes?: string | null;
    createdAt: Date;
    linkedInvoices?: ReadonlyArray<LinkedInvoice> | null;
    // necessary for querying for specific invoice IDs, since
    // Firestore doesn't support for querying fields of objects
    // in arrays stored in a document
    linkedInvoiceIds?: ReadonlyArray<string> | null;
    recommendedDate: RecommendedDate;
    recommendedSurgeonIds: ReadonlyArray<
        | string
        | typeof RECOMMENDED_SURGEON_NO_PREFERENCE
        | typeof RECOMMENDED_SURGEON_MYSELF
    >;

    /**
     * Vetspire location ID or "noPreference"
     */
    preferredClinic: PreferredClinic;

    noteAdded: boolean;

    /**
     * ID of the Zendesk ticket created for this referral
     */
    zendeskTicketId?: number;
}

export interface FirestoreLinkedInvoice
    extends Omit<LinkedInvoice, 'linkedAt'> {
    linkedAt: IsomorphicTimestamp;
}

export interface FirestoreSurgeryReferral
    extends Omit<SurgeryReferral, '_id' | 'createdAt' | 'linkedInvoices'> {
    createdAt: IsomorphicTimestamp;
    storedInMongoDb: boolean;
    sentToStreak: boolean;
    streakSyncingAttempts: number;
    sentToZendesk: boolean;
    zendeskSyncingAttempts: number;
    linkedInvoices?: ReadonlyArray<FirestoreLinkedInvoice> | null;
    sentToStreakAt?: IsomorphicTimestamp;
    lastStreakErrorAt?: null | IsomorphicTimestamp;
    lastStreakError?: null | IsomorphicTimestamp;
    sentToZendeskAt?: IsomorphicTimestamp;
    lastZendeskErrorAt?: null | IsomorphicTimestamp;
    lastZendeskError?: null | IsomorphicTimestamp;
}

export type SurgeryType = {
    _id: string;
    name: string;
    fuzzySearch?: string;
    questions: ReadonlyArray<SurgeryReferralQuestion>;
    notesRequired: boolean;
    createdAt: Date;
    updatedAt: Date;
    streakDropdownKey?: string | null;
    surgeryTypeId?: string | null;
};

export type SurgeryTypeInput = Pick<
    SurgeryType,
    'name' | 'questions' | 'notesRequired' | 'fuzzySearch'
>;

export type SurgeryReferralInput = Pick<
    SurgeryReferral,
    | 'clientId'
    | 'patientId'
    | 'recommendForEducationalFund'
    | 'surgeryTypeId'
    | 'answers'
    | 'notes'
    | 'recommendedDate'
    | 'recommendedSurgeonIds'
    | 'preferredClinic'
    | 'locationId'
>;

export const SURGERY_TYPE_COLLECTION_NAME = 'surgeryReferrals.surgeryTypes';

export const GENERAL_QUESTION_COLLECTION_NAME =
    'surgeryReferrals.generalQuestions';

export const SURGERY_REFERRAL_COLLECTION_NAME = 'surgeryReferrals.referrals';
export const SURGERY_REFERRALS_PATH = 'surgeryReferrals.referrals';

export const SURGERY_REFERRALS_SETTINGS_PATH = 'surgeryReferrals/settings';

export interface SurgeryReferralsSettings {
    streakEnabled: boolean;
}

export type SingleValueAnswer =
    | SurgeryReferralTextAnswer
    | SurgeryReferralRadiosAnswer
    | SurgeryReferralDropdownAnswer;
export type MultiValueAnswer = SurgeryReferralCheckboxesAnswer;

export type OptionsQuestion =
    | SurgeryReferralRadiosQuestion
    | SurgeryReferralCheckboxesQuestion
    | SurgeryReferralDropdownQuestion;

export const OPTIONS_QUESTION_TYPES: ReadonlyArray<
    SurgeryReferralQuestion['type']
> = ['radios', 'dropdown', 'checkboxes'];

export const SINGLE_VALUE_ANSWER_TYPES: ReadonlyArray<
    SurgeryReferralQuestion['type']
> = ['text', 'radios', 'dropdown'];

export const PUBSUB_TOPIC_SURGERY_REFERRAL = 'sx-referral';

export const SURGERY_REFERRAL_CREATED = 'referralCreated';
export const SURGERY_REFERRAL_LINKED = 'referralLinked';
export const SURGERY_REFERRAL_UNLINKED = 'referralUnlinked';
export const SYNC_SURGERY_REFERRAL_WITH_MONGODB = 'syncWithMongoDb';
export const SYNC_SURGERY_REFERRAL_WITH_STREAK = 'syncWithStreak';
export const SYNC_SURGERY_REFERRAL_WITH_ZENDESK = 'syncWithZendesk';
export const ADD_SURGERY_REFERRAL_NOTE = 'addNote';

export type SurgeryReferralCreatedMessage = {
    type: typeof SURGERY_REFERRAL_CREATED;
    payload: {
        id: string;
        data: SurgeryReferralInput;
        providerId: string;
        timestamp: number;
    };
};

export type SurgeryReferralLinkedMessage = {
    type: typeof SURGERY_REFERRAL_LINKED;
    payload: {
        id: string;
        invoiceId: string;
        providerId: string;
        timestamp: number;
    };
};

export type SurgeryReferralUninkedMessage = {
    type: typeof SURGERY_REFERRAL_UNLINKED;
    payload: {
        id: string;
        invoiceId: string;
    };
};

export type SyncSurgeryReferralWithMongoDbMessage = {
    type: typeof SYNC_SURGERY_REFERRAL_WITH_MONGODB;
    payload: {
        id: string;
    };
};

export type SyncSurgeryReferralWithStreakMessage = {
    type: typeof SYNC_SURGERY_REFERRAL_WITH_STREAK;
    payload: {
        id: string;
    };
};

export type SyncSurgeryReferralWithZendeskMessage = {
    type: typeof SYNC_SURGERY_REFERRAL_WITH_ZENDESK;
    payload: {
        id: string;
    };
};

export type AddSurgeryReferralNoteMessage = {
    type: typeof ADD_SURGERY_REFERRAL_NOTE;
    payload: {
        id: string;
    };
};

export type SurgeryReferralMessage =
    | SurgeryReferralCreatedMessage
    | SurgeryReferralLinkedMessage
    | SurgeryReferralUninkedMessage
    | SyncSurgeryReferralWithMongoDbMessage
    | SyncSurgeryReferralWithStreakMessage
    | SyncSurgeryReferralWithZendeskMessage
    | AddSurgeryReferralNoteMessage;

export type { IdAndName };

export type SurgeryOrder = {
    id: string;
    invoiced: boolean;
    patients?: null | ReadonlyArray<IdAndName>;
};

export type SurgeryEncounter = {
    id: string;
    order?: null | SurgeryOrder;
};

export type SurgeryAppointment = {
    id: string;
    schedule?: null | IdAndName;
    start: string;
    patient: IdAndName & { client: IdAndName };
    location: IdAndName & { timezone: string };
    status: VetspireAppointmentStatus;
    encounters?: null | ReadonlyArray<SurgeryEncounter>;
    type: IdAndName;
};

export type GraphQLSurgeryAppointment = SurgeryAppointment & {
    invoices?: null | ReadonlyArray<SurgeryOrder>;
    linkedReferrals?: null | ReadonlyArray<SurgeryReferral>;
    hidden: boolean;
};
