import * as React from 'react';

import styles from './TextingUnreadBadge.module.scss';

interface TextingUnreadBadgeProps {
    count: number;
}

export const TextingUnreadBadge = ({
    count,
}: TextingUnreadBadgeProps): React.ReactElement => {
    return (
        <div className={styles.container}>
            <span className={styles.number}>{count}</span>
        </div>
    );
};
