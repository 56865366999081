import * as React from 'react';
import {
    defaultSukiGlobalSettings,
    type SukiGlobalSetting,
} from '@bondvet/types/suki';
import useLazySukiGlobalSettingsQuery from './useLazySukiGlobalSettingsQuery';

interface UseSukiGlobalSettings {
    sukiGlobalSettings: SukiGlobalSetting;
    loading: boolean;
}

export default function useSukiGlobalSettings(): UseSukiGlobalSettings {
    const [sukiGlobalSettings, setSukiGlobalSettings] =
        React.useState<SukiGlobalSetting>(defaultSukiGlobalSettings);

    const [runLazyGlobalSettingsQuery, { loading }] =
        useLazySukiGlobalSettingsQuery();

    React.useEffect(() => {
        runLazyGlobalSettingsQuery().then((result) => {
            setSukiGlobalSettings(
                result?.data?.sukiGlobalSettings ?? defaultSukiGlobalSettings,
            );
        });
    }, [runLazyGlobalSettingsQuery]);

    return {
        sukiGlobalSettings,
        loading,
    };
}
