import { gql } from '@apollo/client';
import useSchedulingQuery from 'hooks/useSchedulingQuery';
import { SchedulingShiftTime } from '@bondvet/types/scheduling';

export const schedulingShiftTimes = gql`
    query schedulingShiftTimes {
        schedulingShiftTimes {
            _id
            startMinutes
            durationMinutes
            isActive
            icon
        }
    }
`;

export interface SchedulingShiftTimesQueryResult {
    schedulingShiftTimes: ReadonlyArray<SchedulingShiftTime>;
}

type SchedulingShiftTimes = {
    isLoading: boolean;
    schedulingShiftTimes: ReadonlyArray<SchedulingShiftTime>;
    refetch: () => void;
};

export default function useSchedulingShiftTimes(): SchedulingShiftTimes {
    const { data, loading, refetch } =
        useSchedulingQuery<SchedulingShiftTimesQueryResult>(
            schedulingShiftTimes,
            {
                fetchPolicy: 'cache-first',
            },
        );

    return {
        isLoading: loading,
        schedulingShiftTimes: (data?.schedulingShiftTimes || [])
            .slice(0)
            .sort((a, b) => a.startMinutes - b.startMinutes),
        refetch,
    };
}
