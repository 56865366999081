import * as React from 'react';
import {
    SurgeryReferralDropdownAnswer,
    OptionsQuestion,
} from '@bondvet/types/surgeryReferrals';
import Select, { SingleValue } from 'react-select';
import styles from 'components/LookupSelect/LookupSelect.module.scss';
import { InputProps } from './types';

interface DropdownInputProps extends InputProps {
    value: SurgeryReferralDropdownAnswer['answer'];
    options: OptionsQuestion['options'];
}

type OptionType = {
    value: string;
    label: string;
};

export default function DropdownInput({
    value,
    onChange,
    id,
    options,
}: DropdownInputProps): React.ReactElement {
    const handleChange = React.useCallback(
        (newOption: SingleValue<OptionType>) => {
            if (newOption) {
                onChange(newOption?.value);
            }
        },
        [onChange],
    );

    const selected = React.useMemo<OptionType>(
        () => ({ value, label: value }),
        [value],
    );

    const selectOptions = React.useMemo<ReadonlyArray<OptionType>>(
        () =>
            options.map((option) => ({
                value: option,
                label: option,
            })),
        [options],
    );

    return (
        <Select
            name={id}
            value={selected}
            options={selectOptions}
            onChange={handleChange}
            className={styles.lookup}
            classNamePrefix="lookup-select"
        />
    );
}
