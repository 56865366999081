import ErrorMessage from 'components/ErrorMessage';
import * as React from 'react';
import {
    CREDIT_MEMO_OPTION_CASH,
    CreditBucket,
} from '@bondvet/types/creditLedger';
import {
    CREDIT_BUCKET_NAMES,
    CREDIT_MEMO_NOTES_MAP,
} from '@bondvet/types/creditLedger/constants';
import classnames from 'classnames';
import useLocationId from 'hooks/useLocationId';
import useTranslate from 'hooks/useTranslate';
import { WithFlyout } from 'lib/types';
import ActionButton from 'components/ActionButton';
import { useAnalytics } from 'hooks/useAnalytics';
import { Page } from 'lib/vetspireActions';
import { CircularProgress } from '@mui/material';
import { SingleValue } from 'react-select';
import { gql } from '@apollo/client';
import useBondMutation from 'hooks/useBondMutation';
import { PROMO_CODE_NOTE_PREFIX } from '../../constants';
import CreditReasonSelect from '../CreditReasonSelect';
import { formatAmountAsUSD } from '../../util';
import styles from './AddCreditMemo.module.scss';
import usePromoCodeSetting from '../../hooks/usePromoCodeSetting';
import { UpcomingVisitOption } from '../UpcomingVisitSelect/useUpcomingVisits';
import usePromoCodes from '../PromoCodeSelect/usePromoCodes';

const applyPromoCodeMutation = gql`
    mutation applyPromoCode($code: String!, $vetspireAppointmentId: String!) {
        applyPromoCode(
            code: $code
            vetspireAppointmentId: $vetspireAppointmentId
        )
    }
`;

type ApplyPromoCodeMutationArguments = {
    code: string;
    vetspireAppointmentId: string;
};

type ApplyPromoCodeMutationResult = {
    applyPromoCode: number;
};

type AddCreditMemoProps = {
    classes?: Partial<WithFlyout<typeof styles>>;
    isLoading: boolean;
    currentTotal: number;
    updateCurrentTotal: (newTotal: number) => void;
    addCredit: (amount: number, locationId: string, note: string) => void;
    addPatientTag: (patientId: string, tagId: string) => void;
    clientId: string;
};

type AmountBoundaries = {
    min: number;
    max: number;
};

const AMOUNT_BOUNDARIES = {
    [CREDIT_BUCKET_NAMES[CreditBucket.courtesy]]: {
        min: 0,
        max: 200.0,
    },
    [CREDIT_MEMO_OPTION_CASH]: { min: -99.99, max: 99.99 },
} as const;

const parseAmount = (amtString: string) => {
    if (amtString) {
        return parseFloat(amtString);
    }
    return 0;
};

function AddCreditMemo({
    classes,
    addCredit,
    addPatientTag,
    isLoading,
    currentTotal,
    updateCurrentTotal,
    clientId,
}: AddCreditMemoProps): React.ReactElement {
    const analytics = useAnalytics();
    const locationId = useLocationId();
    const translate = useTranslate();
    const [amount, setAmount] = React.useState('');
    const [courtesyReason, setCourtesyReason] = React.useState('');
    const [courtesyReasonDirty, setCourtesyReasonDirty] = React.useState(false);
    const [note, setNote] = React.useState<string>('');
    const [error, setError] = React.useState<string | null>(null);
    const [selectedVisit, setSelectedVisit] =
        React.useState<SingleValue<UpcomingVisitOption> | null>(null);
    const [promoCodePatientTag, setPromoCodePatientTag] = React.useState<
        string | null | undefined
    >(null);
    const { valid: validPromoCodeOptions } = usePromoCodes(
        !selectedVisit,
        selectedVisit?.id,
    );
    const parsedAmount = parseAmount(amount);
    const isCourtesy = note === CreditBucket.courtesy;
    const inputsNotFilled =
        amount === '' ||
        note === '' ||
        note === CreditBucket.promoCode ||
        (isCourtesy && courtesyReason === '');
    const { promoCodeSettingEnabled, isLoading: promoCodeSettingLoading } =
        usePromoCodeSetting();

    const [runApplyPromoCodeMutation] = useBondMutation<
        ApplyPromoCodeMutationResult,
        ApplyPromoCodeMutationArguments
    >(applyPromoCodeMutation);

    const handleChangeAmount = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setAmount(event.target.value);
        },
        [],
    );

    const handleChangeCourtesyReason = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCourtesyReason(event.target.value);
        },
        [],
    );

    const handleBlurCourtesyReason = React.useCallback(() => {
        setCourtesyReasonDirty(true);
    }, []);

    const amountBoundaries = React.useMemo<AmountBoundaries | null>(
        () => AMOUNT_BOUNDARIES[note] ?? null,
        [note],
    );

    const cleanAmount = React.useCallback(() => {
        setAmount(Number.parseFloat(amount).toFixed(2));
    }, [amount]);

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setError(null);
            // TODO: need some error handling here instead of returning nothing
            if ((!promoCodePatientTag && !parsedAmount) || !note) return;
            if (
                // if the new promocode setting is enabled we want to handle all
                // of the promo codes with the applyPromoCodes function
                promoCodeSettingEnabled &&
                note.startsWith(PROMO_CODE_NOTE_PREFIX)
            ) {
                const promoCode = note.replace(PROMO_CODE_NOTE_PREFIX, '');
                if (promoCode && selectedVisit?.id) {
                    try {
                        const { data } = await runApplyPromoCodeMutation({
                            variables: {
                                code: promoCode,
                                vetspireAppointmentId: selectedVisit.id,
                            },
                        });
                        if (
                            !data?.applyPromoCode &&
                            data?.applyPromoCode !== 0
                        ) {
                            setError('Internal Error');
                            return;
                        }

                        updateCurrentTotal(data.applyPromoCode / 100);
                    } catch (e) {
                        setError((e as Error).message);
                        return;
                    }
                }
            } else if (promoCodePatientTag) {
                if (
                    selectedVisit?.appointmentData?.animalId &&
                    promoCodePatientTag
                ) {
                    addPatientTag(
                        selectedVisit.appointmentData.animalId,
                        promoCodePatientTag,
                    );
                }
            } else if (isCourtesy) {
                addCredit(
                    parsedAmount,
                    locationId,
                    `${note}: ${courtesyReason}`,
                );
            } else {
                addCredit(parsedAmount, locationId, note);
            }
            analytics.trackEvent(Page.clientDetails, 'add_credit');
            setAmount('');
            setNote('');
            setSelectedVisit(null);
        },
        [
            promoCodePatientTag,
            parsedAmount,
            note,
            promoCodeSettingEnabled,
            isCourtesy,
            analytics,
            selectedVisit?.id,
            selectedVisit?.appointmentData?.animalId,
            runApplyPromoCodeMutation,
            updateCurrentTotal,
            addPatientTag,
            addCredit,
            locationId,
            courtesyReason,
        ],
    );

    const canChangeAmount = React.useMemo(() => {
        return !note.startsWith(PROMO_CODE_NOTE_PREFIX);
    }, [note]);

    const amountIsInBoundaries =
        !amountBoundaries ||
        (parsedAmount >= amountBoundaries.min &&
            parsedAmount <= amountBoundaries.max);

    const { addedCreditAmount, oldPromoCodeAmount, oldPromoCodeSelected } =
        React.useMemo(() => {
            if (
                selectedVisit?.appointmentData?.promoCode ===
                note.replace(PROMO_CODE_NOTE_PREFIX, '')
            ) {
                return {
                    addedCreditAmount: parsedAmount,
                    oldPromoCodeAmount: 0,
                    oldPromoCodeSelected: true,
                };
            }
            const oldPromoCode = validPromoCodeOptions.find(
                ({ label }) =>
                    label === selectedVisit?.appointmentData?.promoCode,
            );

            return {
                addedCreditAmount:
                    parsedAmount - parseInt(oldPromoCode?.amount ?? '0', 10),
                oldPromoCodeAmount: parseInt(oldPromoCode?.amount ?? '0', 10),
                oldPromoCodeSelected: false,
            };
        }, [
            note,
            parsedAmount,
            selectedVisit?.appointmentData?.promoCode,
            validPromoCodeOptions,
        ]);

    return (
        <div
            className={classnames(
                classes?.flyoutBlock,
                styles.flyoutBlock,
                styles.root,
            )}
        >
            <header className={classnames(styles.first, styles.section)}>
                <span className={styles.flyoutTitle}>
                    {translate(
                        'vetspireExtension.clientDetails.credits.addCredits.title',
                    )}
                </span>
            </header>

            <form className={styles.container} onSubmit={handleSubmit}>
                {promoCodeSettingLoading ? (
                    <CircularProgress />
                ) : (
                    <CreditReasonSelect
                        promoCodeValidation={promoCodeSettingEnabled}
                        clientId={clientId}
                        note={note}
                        setNote={setNote}
                        setAmount={setAmount}
                        selectedVisit={selectedVisit}
                        setSelectedVisit={setSelectedVisit}
                        setPromoCodePatientTag={setPromoCodePatientTag}
                    />
                )}

                {!promoCodePatientTag && (
                    <>
                        <div className={styles.section}>
                            {translate(
                                'vetspireExtension.clientDetails.credits.addCredits.amount',
                            )}
                        </div>
                        <div className={styles.amountInputContainer}>
                            <input
                                name="amount"
                                type="number"
                                className={styles.creditAmountInput}
                                placeholder="0.00"
                                value={amount}
                                onChange={handleChangeAmount}
                                onBlur={cleanAmount}
                                readOnly={!canChangeAmount}
                                step={0.01}
                                min={amountBoundaries?.min}
                                max={amountBoundaries?.max}
                            />
                            {!amountIsInBoundaries && (
                                <ErrorMessage>
                                    {translate(
                                        'vetspireExtension.clientDetails.credits.addCredits.invalidAmount',
                                        {
                                            type:
                                                CREDIT_MEMO_NOTES_MAP[
                                                    note as keyof typeof CREDIT_MEMO_NOTES_MAP
                                                ] ?? note,
                                            min: formatAmountAsUSD(
                                                100 *
                                                    (amountBoundaries?.min ??
                                                        0),
                                                true,
                                            ),
                                            max: formatAmountAsUSD(
                                                100 *
                                                    (amountBoundaries?.max ??
                                                        0),
                                                true,
                                            ),
                                        },
                                    )}
                                </ErrorMessage>
                            )}
                        </div>
                    </>
                )}
                {isCourtesy && (
                    <>
                        <div className={styles.section}>
                            {translate(
                                'vetspireExtension.clientDetails.credits.addCredits.courtesyReason',
                            )}
                        </div>
                        <div className={styles.inputContainer}>
                            <input
                                name="courtesyReason"
                                type="text"
                                className={styles.creditAmountInput}
                                placeholder="Reason"
                                value={courtesyReason}
                                onChange={handleChangeCourtesyReason}
                                onBlur={handleBlurCourtesyReason}
                                maxLength={25}
                            />
                            {!courtesyReason && courtesyReasonDirty && (
                                <ErrorMessage>
                                    {translate(
                                        'vetspireExtension.clientDetails.credits.addCredits.courtesyReasonRequired',
                                    )}
                                </ErrorMessage>
                            )}
                        </div>
                    </>
                )}
                {oldPromoCodeSelected && (
                    <div className={styles.section}>
                        {translate(
                            'vetspireExtension.clientDetails.credits.addCredits.appliedPromoCodeSelected',
                        )}
                    </div>
                )}
                {!oldPromoCodeSelected &&
                    (promoCodePatientTag ? (
                        <div className={styles.section}>
                            Coupon will automatically be applied on qualifying
                            invoices at checkout.
                        </div>
                    ) : (
                        <div className={styles.section}>
                            {translate(
                                oldPromoCodeAmount === 0
                                    ? 'vetspireExtension.clientDetails.credits.addCredits.changedCredit'
                                    : 'vetspireExtension.clientDetails.credits.addCredits.changedCreditRemoveOldCredit',
                                {
                                    existing: formatAmountAsUSD(currentTotal),
                                    new: formatAmountAsUSD(
                                        addedCreditAmount * 100 + currentTotal,
                                    ),
                                    old: formatAmountAsUSD(
                                        oldPromoCodeAmount * 100,
                                    ),
                                },
                                {
                                    renderInnerHtml: true,
                                },
                            )}
                        </div>
                    ))}
                <ActionButton
                    className={classnames(
                        styles.button,
                        styles.addCredit,
                        styles.section,
                    )}
                    disabled={
                        isLoading ||
                        inputsNotFilled ||
                        (!promoCodePatientTag &&
                            (parsedAmount === 0 || !amountIsInBoundaries)) ||
                        oldPromoCodeSelected
                    }
                    type="submit"
                >
                    {translate(
                        'vetspireExtension.clientDetails.credits.addCredits.button',
                    )}
                </ActionButton>
                {error && <div className={styles.error}>{error}</div>}
            </form>
        </div>
    );
}

export default AddCreditMemo;
