import * as React from 'react';
import { startOfDay, format } from 'date-fns';
import MonthSelect from 'components/MonthSelect';
import moment from 'moment-timezone';
import useTimezone from 'hooks/useTimezone';
import classnames from 'classnames';
import { CircularProgress } from '@mui/material';
import useTranslate from 'hooks/useTranslate';
import useImpactScoresV2 from 'components/ProviderPayment/hooks/useImpactScoresV2';
import useViewerSettings from 'hooks/useViewerSettings';
import LightTooltip from 'components/LightTooltip/LightTooltip';
import ConfettiIcon from 'assets/confetti.png';
import styles from './MonthSubTab.module.scss';

interface MonthSubTabProps {
    vetspireProviderId: string;
}

const MonthSubTab: React.FunctionComponent<MonthSubTabProps> = ({
    vetspireProviderId,
}) => {
    const translate = useTranslate();
    const { viewer } = useViewerSettings();
    const timezone = useTimezone();
    const [selectedMonth, setSelectedMonth] = React.useState(() =>
        moment.tz(timezone).format('YYYY-MM'),
    );
    const { selectedRange, loading } = useImpactScoresV2(
        vetspireProviderId,
        `${selectedMonth}-01`,
        moment
            .tz(`${selectedMonth}-01`, timezone)
            .endOf('month')
            .format('YYYY-MM-DD'),
    );

    const showDisclaimer = React.useMemo(() => {
        return format(startOfDay(new Date()), 'yyyy-MM') === selectedMonth;
    }, [selectedMonth]);

    const renderedDays = React.useMemo(() => {
        const start = moment
            .tz(`${selectedMonth}-01`, timezone)
            .startOf('month');
        const end = start.clone().endOf('month');

        const today = moment.tz(timezone).startOf('day');

        const days: JSX.Element[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'].map(
            (day, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`day.${index}`} className={styles.dayHeading}>
                    <div>{day}</div>
                </li>
            ),
        );

        // pre-fill with empty array elements if day does not start with monday
        const prefillDays = ((start.day() || 7) - 1) % 7;

        days.push(
            ...Array(prefillDays)
                .fill(null)
                .map((day, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`prefill-day.${index}`} />
                )),
        );

        const currentDay = start.clone();
        while (currentDay.isBefore(end)) {
            const day = currentDay.date();
            const isToday = currentDay.isSame(today, 'day');
            const isFuture = currentDay.isAfter(today);
            const todayPayout = selectedRange?.payoutPerDay
                // Include the sort in case the employee had two locations / shifts and only one hit bonus (take larger payout)
                ?.sort((payout1, payout2) => payout2.payout - payout1.payout)
                .find(
                    (payoutPerDay) =>
                        payoutPerDay.date === currentDay.format('YYYY-MM-DD'),
                );

            const isGoalReached = (todayPayout?.payout || 0) > 0;
            const isWorkingDay = !!todayPayout;

            let tooltipTitle = '';
            if (isFuture) {
                tooltipTitle = translate(
                    'vetspireExtension.providerPayment.impactBonus.month.tooltip.futureDay',
                ) as string;
            } else if (isGoalReached && isToday) {
                tooltipTitle = translate(
                    'vetspireExtension.providerPayment.impactBonus.month.tooltip.bonusEarnedToday',
                    {
                        amount: todayPayout?.formattedPayout || '',
                    },
                ) as string;
            } else if (isGoalReached) {
                tooltipTitle = todayPayout?.formattedPayout || '';
            } else if (isWorkingDay && isToday) {
                tooltipTitle = translate(
                    'vetspireExtension.providerPayment.impactBonus.month.tooltip.noBonusEarnedToday',
                ) as string;
            } else if (isWorkingDay) {
                tooltipTitle = translate(
                    'vetspireExtension.providerPayment.impactBonus.month.tooltip.noBonusEarnedPast',
                ) as string;
            } else {
                tooltipTitle = translate(
                    'vetspireExtension.providerPayment.impactBonus.month.tooltip.noShift',
                ) as string;
            }

            const content = <div className={styles.dayNumber}>{day}</div>;

            days.push(
                <li key={day} className={styles.dayContainer}>
                    <LightTooltip
                        arrow
                        title={
                            <div className={styles.tooltipContent}>
                                {tooltipTitle}
                                {isGoalReached && (
                                    <img src={ConfettiIcon} alt="confetti" />
                                )}
                            </div>
                        }
                        placement="top"
                    >
                        <div
                            className={classnames(styles.day, {
                                [styles.noBonusEarned]:
                                    !isFuture && isWorkingDay && !isGoalReached,
                                [styles.bonusEarned]: isGoalReached,
                                [styles.futureDate]: isFuture,
                            })}
                        >
                            {!isFuture && isWorkingDay && !isGoalReached && (
                                <>
                                    <CircularProgress
                                        variant="determinate"
                                        thickness={5}
                                        value={100}
                                        size={25}
                                        classes={{
                                            root: styles.circularLoadingBackground,
                                        }}
                                    />
                                    <CircularProgress
                                        variant="determinate"
                                        thickness={5}
                                        value={todayPayout?.progress || 0}
                                        size={25}
                                        classes={{
                                            root: styles.circularLoading,
                                        }}
                                    />
                                </>
                            )}
                            {isGoalReached ? (
                                <img src={ConfettiIcon} alt="confetti" />
                            ) : (
                                content
                            )}
                            {isToday && <div className={styles.today} />}
                        </div>
                    </LightTooltip>
                </li>,
            );
            currentDay.add({ days: 1 });
        }

        return (
            <div className={styles.listContainer}>
                <ul className={styles.daysList}>{days}</ul>
            </div>
        );
    }, [selectedMonth, selectedRange?.payoutPerDay, timezone, translate]);

    const translatedDays = React.useMemo(() => {
        return translate(
            `vetspireExtension.lookups.timeSpan.${
                selectedRange.payoutDays === 1 ? 'day' : 'days'
            }`,
            {
                days: selectedRange.payoutDays,
            },
        );
    }, [selectedRange.payoutDays, translate]);

    const minMonth = moment
        .tz(timezone)
        .subtract({ months: 3 })
        .format('YYYY-MM');
    const maxMonth = moment.tz(timezone).format('YYYY-MM');

    const isCurrentMonth = maxMonth === selectedMonth;
    const i18nPath = `${isCurrentMonth ? 'thisMonth' : 'lastMonth'}.${
        selectedRange?.hasPayout ? 'hasPayout' : 'hasNoPayout'
    }`;
    const content = React.useMemo(() => {
        if (loading) {
            return (
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <>
                <div className={styles.flyoutBlock}>
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: translate(
                                `vetspireExtension.providerPayment.impactBonus.month.description.${i18nPath}`,
                                {
                                    days: translatedDays,
                                    month: moment
                                        .tz(selectedMonth, timezone)
                                        .format('MMMM'),
                                },
                            ).toString(),
                        }}
                    />
                </div>
                <div className={styles.flyoutBlock}>{renderedDays}</div>
                <div className={styles.flyoutBlock}>
                    <div className={styles.collectedContainer}>
                        <span
                            className={classnames(styles.price, {
                                [styles.grayedOut]: !selectedRange?.hasPayout,
                            })}
                        >
                            {`${selectedRange.payout}${showDisclaimer ? '*' : ''}`}
                        </span>
                        <span className={styles.title}>
                            {translate(
                                isCurrentMonth
                                    ? 'vetspireExtension.providerPayment.impactBonus.month.card.title.thisMonth'
                                    : 'vetspireExtension.providerPayment.impactBonus.month.card.title.lastMonth',
                                {
                                    month: moment
                                        .tz(selectedMonth, timezone)
                                        .format('MMMM'),
                                },
                            )}
                        </span>
                        <span className={styles.subtitle}>
                            {translate(
                                `vetspireExtension.providerPayment.impactBonus.month.card.subtitle.${i18nPath}`,
                                {
                                    name: viewer?.givenName,
                                },
                            )}
                        </span>
                    </div>
                </div>
                {showDisclaimer && (
                    <div className={styles.flyoutBlock}>
                        <div
                            className={styles.disclaimer}
                            dangerouslySetInnerHTML={{
                                __html: translate(
                                    `vetspireExtension.providerPayment.impactBonus.month.disclaimer`,
                                ).toString(),
                            }}
                        />
                    </div>
                )}
            </>
        );
    }, [
        showDisclaimer,
        i18nPath,
        isCurrentMonth,
        loading,
        renderedDays,
        selectedMonth,
        selectedRange?.hasPayout,
        selectedRange.payout,
        timezone,
        translate,
        viewer?.givenName,
        translatedDays,
    ]);

    return (
        <>
            <div className={classnames(styles.flyoutBlock, styles.monthSelect)}>
                <MonthSelect
                    month={selectedMonth}
                    minMonth={minMonth}
                    maxMonth={maxMonth}
                    onChange={(newMonth) => setSelectedMonth(newMonth)}
                />
            </div>
            {content}
        </>
    );
};

export default MonthSubTab;
