import useLocationId from 'hooks/useLocationId';
import * as React from 'react';
import {
    GraphQLGeneralSurgeryReferralQuestion,
    SurgeryReferralAnswer,
    SurgeryReferralQuestion,
    SurgeryType,
    SingleValueAnswer,
    MultiValueAnswer,
    SINGLE_VALUE_ANSWER_TYPES,
    OptionsQuestion,
    OPTIONS_QUESTION_TYPES,
    SurgeryReferralInput,
    RecommendedDate,
    RECOMMENDED_DATE_EMERGENT,
    RECOMMENDED_SURGEON_NO_PREFERENCE,
    RECOMMENDED_SURGEON_MYSELF,
    PreferredClinic,
} from '@bondvet/types/surgeryReferrals';
import { useMutation } from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import useVetspireProviders from 'hooks/useVetspireProviders';
import useTranslate from 'hooks/useTranslate';
import { useAnalytics } from 'hooks/useAnalytics';
import { Page } from 'lib/vetspireActions';
import { getVetspireLocationId } from 'context/VetspireContext';
import { SendSurgeryReferralContext } from '../../context';
import useGeneralQuestions from '../../hooks/useGeneralQuestions';
import { AnswerData, OptionsAnswerData, SurgeonOption } from '../../types';
import {
    sendSurgeryRecommendation,
    SendSurgeryRecommendationResult,
    SendSurgeryRecommendationVariables,
} from '../../api/mutations';

interface FormData extends SendSurgeryReferralContext {
    answers: ReadonlyArray<AnswerData>;
    setSurgeryType: (surgeryType: SurgeryType | null) => void;
    generalQuestions: ReadonlyArray<GraphQLGeneralSurgeryReferralQuestion>;
    surgeonOptions: ReadonlyArray<SurgeonOption>;
    valid: boolean;
    submit: (event: React.FormEvent<HTMLFormElement>) => void;
    submitting: boolean;
    succeeded: boolean;
    loading: boolean;
    error: string | null;
}

function getAnswerId({ type, label }: SurgeryReferralQuestion): string {
    return `${type}-${label.replace(/[^\w]+/g, '-').toLowerCase()}`;
}

function validate(answers: ReadonlyArray<AnswerData>): boolean {
    return answers.reduce<boolean>((result, answer) => {
        if (answer.required) {
            if (SINGLE_VALUE_ANSWER_TYPES.includes(answer.type)) {
                const value = (answer as SingleValueAnswer).answer as string;

                return result && !!value?.trim();
            }

            const value = (answer as MultiValueAnswer).answers as string[];

            return result && value.length > 0;
        }

        return result;
    }, true);
}

function mapQuestion(
    question: SurgeryReferralQuestion,
    sortOrder: number,
): AnswerData | OptionsAnswerData {
    const { type, label, required } = question;
    const id = getAnswerId(question);
    const base: Omit<AnswerData | OptionsAnswerData, 'answer' | 'answers'> = {
        type,
        id,
        label,
        sortOrder,
        required,
        valid: !required,
    };

    if (OPTIONS_QUESTION_TYPES.includes(type)) {
        (base as OptionsAnswerData).options = (
            question as OptionsQuestion
        ).options;
    }

    if (SINGLE_VALUE_ANSWER_TYPES.includes(type)) {
        return {
            ...base,
            answer: '',
        } as AnswerData | OptionsAnswerData;
    }

    return {
        ...(base as Omit<OptionsAnswerData, 'answers'>),
        answers: [],
    } as OptionsAnswerData;
}

export default function useForm(
    clientId: string | null,
    patientId: string | null,
): FormData {
    const translate = useTranslate();

    const [surgeryType, setSurgeryType] = React.useState<SurgeryType | null>(
        null,
    );
    const { questions: generalQuestions, loading: loadingGeneralQuestions } =
        useGeneralQuestions();
    const { loading: loadingVetspireProviders, vetspireProviders } =
        useVetspireProviders();
    const [answers, setAnswers] = React.useState<ReadonlyArray<AnswerData>>([]);

    const surgeonOptions = React.useMemo<ReadonlyArray<SurgeonOption>>(() => {
        return [
            {
                value: RECOMMENDED_SURGEON_NO_PREFERENCE,
                label: translate(
                    'vetspireExtension.surgeryReferrals.send.noPreference',
                ) as string,
            },
            {
                value: RECOMMENDED_SURGEON_MYSELF,
                label: translate(
                    'vetspireExtension.surgeryReferrals.send.myself',
                ) as string,
            },
            ...vetspireProviders
                .filter((provider) => provider.canDoSurgeries)
                .map((provider) => ({
                    value: provider.id,
                    label: provider.name || '?',
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        ];
    }, [translate, vetspireProviders]);

    const onChange = React.useCallback(
        (
            id: string,
            type: SurgeryReferralAnswer['type'],
            value: string | string[],
        ) => {
            setAnswers((oldAnswers) => {
                const idx = oldAnswers.findIndex(
                    (oldAnswer) => oldAnswer.id === id,
                );

                if (idx === -1) {
                    throw new Error(`cannot find answer ${id}`);
                }

                const answer = {
                    ...oldAnswers[idx],
                };

                if (SINGLE_VALUE_ANSWER_TYPES.includes(type)) {
                    (answer as SingleValueAnswer).answer = value as string;
                    answer.valid =
                        !answer.required || !!(value as string)?.trim();
                } else {
                    (answer as MultiValueAnswer).answers = value as string[];
                    answer.valid =
                        !answer.required || (value as string[]).length > 0;
                }

                return [
                    ...oldAnswers.slice(0, idx),
                    answer,
                    ...oldAnswers.slice(idx + 1),
                ];
            });
        },
        [],
    );

    React.useEffect(() => {
        const allQuestions = [
            ...generalQuestions.map(({ question }) => question),
            ...(surgeryType?.questions || []),
        ];

        setAnswers(
            allQuestions.map<AnswerData>((question, idx) =>
                mapQuestion(question, idx),
            ),
        );
    }, [surgeryType?.questions, generalQuestions]);

    const [recommendForEducationalFund, setRecommendForEducationalFund] =
        React.useState(false);
    const [notes, setNotes] = React.useState('');

    const changeNotes = React.useCallback((newNotes: string | null) => {
        setNotes(newNotes || '');
    }, []);

    const changeRecommendForEducationalFund = React.useCallback(
        (newValue: boolean) => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setRecommendForEducationalFund(newValue);
                });
            });
        },
        [],
    );

    const [recommendedDate, setRecommendedDate] =
        React.useState<RecommendedDate>(RECOMMENDED_DATE_EMERGENT);

    const [recommendedSurgeonIds, setRecommendedSurgeonIds] = React.useState<
        string[]
    >([]);

    const changeRecommendedDate = React.useCallback(
        (newValue: RecommendedDate) => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setRecommendedDate(newValue);
                });
            });
        },
        [],
    );

    const [preferredClinic, setPreferredClinic] =
        React.useState<PreferredClinic>(() => getVetspireLocationId());

    const changePreferredClinic = React.useCallback(
        (newValue: PreferredClinic) => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setPreferredClinic(newValue);
                });
            });
        },
        [],
    );

    const changeRecommendedSurgeonIds = React.useCallback(
        (newValues: string[]) => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setRecommendedSurgeonIds(newValues);
                });
            });
        },
        [],
    );

    const valid = React.useMemo(
        () =>
            (!!notes || !surgeryType?.notesRequired) &&
            recommendedSurgeonIds.length > 0 &&
            validate(answers),
        [answers, notes, surgeryType?.notesRequired, recommendedSurgeonIds],
    );

    const locationId = useLocationId();

    const [runMutation, { loading: submitting, data }] = useMutation<
        SendSurgeryRecommendationResult,
        SendSurgeryRecommendationVariables
    >(sendSurgeryRecommendation, {
        context: {
            clientName: GRAPHQL_CLIENT_NAMES.default,
        },
    });

    const analytics = useAnalytics();

    const submit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (surgeryType && clientId && patientId) {
                const input: SurgeryReferralInput = {
                    surgeryTypeId: surgeryType._id,
                    clientId,
                    patientId,
                    locationId,
                    recommendForEducationalFund,
                    notes,
                    recommendedDate,
                    preferredClinic,
                    recommendedSurgeonIds,
                    answers: answers.map((answer) => {
                        const { type, label } = answer;
                        const base: Pick<
                            SurgeryReferralAnswer,
                            'label' | 'type'
                        > = {
                            label,
                            type,
                        };

                        if (SINGLE_VALUE_ANSWER_TYPES.includes(type)) {
                            return {
                                ...base,
                                answer: (answer as SingleValueAnswer).answer,
                            } as SingleValueAnswer;
                        }

                        return {
                            ...base,
                            answers: (answer as MultiValueAnswer).answers,
                        } as MultiValueAnswer;
                    }),
                };

                runMutation({
                    variables: {
                        input,
                    },
                }).then();
                analytics.trackEvent(
                    Page.surgeryReferrals,
                    'send_my_surgery_recommendation',
                );
            }
        },
        [
            runMutation,
            surgeryType,
            clientId,
            patientId,
            locationId,
            notes,
            answers,
            recommendForEducationalFund,
            recommendedDate,
            preferredClinic,
            recommendedSurgeonIds,
            analytics,
        ],
    );

    const [succeeded, setSucceeded] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (submitting) {
            setSucceeded(false);
            setError(null);
        }

        if (data?.sendSurgeryRecommendation) {
            const { success, error: submissionError } =
                data.sendSurgeryRecommendation;

            if (success) {
                setSucceeded(true);
            } else if (submissionError) {
                setError(submissionError);
            }
        }
    }, [data, submitting]);

    return {
        surgeryType,
        notes,
        recommendForEducationalFund,
        answers,
        setSurgeryType,
        changeNotes,
        changeRecommendForEducationalFund,
        generalQuestions,
        surgeonOptions,
        onChange,
        valid,
        submitting,
        submit,
        succeeded,
        loading: loadingGeneralQuestions || loadingVetspireProviders,
        error,
        recommendedDate,
        changeRecommendedDate,
        preferredClinic,
        changePreferredClinic,
        recommendedSurgeonIds,
        changeRecommendedSurgeonIds,
    };
}
