import * as React from 'react';
import { gql } from '@apollo/client';
import {
    UpcomingAppointment,
    UpcomingAppointmentsQueryResult,
    UpcomingAppointmentsQueryVariables,
} from '@bondvet/types/appointments';
import useBondQuery from 'hooks/useBondQuery';

export type UpcomingVisitOption = {
    id: string;
    appointmentData: Omit<UpcomingAppointment, 'vetspireId'>;
};

const query = gql`
    query upcomingAppointments($clientId: ID!) {
        upcomingAppointments(clientId: $clientId) {
            id
            vetspireId
            animalId
            pet
            species
            locationName
            locationId
            date
            appointmentTypeId
            appointmentTypeName
            promoCode
        }
    }
`;

export interface UseUpcomingVisits {
    isLoading: boolean;
    options: readonly UpcomingVisitOption[];
}

type UpcomingVisitsProps = {
    clientId: string;
    skip: boolean;
};

export default function useUpcomingVisits({
    clientId,
    skip,
}: UpcomingVisitsProps): UseUpcomingVisits {
    const { data, loading } = useBondQuery<
        UpcomingAppointmentsQueryResult,
        UpcomingAppointmentsQueryVariables
    >(query, {
        fetchPolicy: 'cache-and-network',
        variables: { clientId },
        skip,
    });

    const options = React.useMemo(() => {
        if (data?.upcomingAppointments) {
            return data.upcomingAppointments.map(
                ({ vetspireId, ...appointmentData }: UpcomingAppointment) => ({
                    id: vetspireId,
                    appointmentData,
                }),
            ) as UpcomingVisitOption[];
        }
        return [];
    }, [data?.upcomingAppointments]);

    return {
        isLoading: loading,
        options,
    };
}
