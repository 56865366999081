import {
    CancellationSettings,
    defaultCancellationSettings,
    CancellationSettingsQueryResult,
} from '@bondvet/types/clientApp/settings';
import { gql } from '@apollo/client';
import useClientAppQuery from './useClientAppQuery';

const cancellationSettingsQuery = gql`
    query cancellationSettings {
        cancellationSettings {
            cancellationReasons {
                label
                value
                requiresExplanation
                obsolete
            }
        }
    }
`;

interface UseCancellationSettings {
    loading: boolean;
    cancellationSettings: CancellationSettings;
}

export default function useCancellationSettings(): UseCancellationSettings {
    const { loading, data } =
        useClientAppQuery<CancellationSettingsQueryResult>(
            cancellationSettingsQuery,
            {
                variables: {
                    __noAuth: true,
                },
            },
        );

    return {
        loading,
        cancellationSettings:
            data?.cancellationSettings || defaultCancellationSettings,
    };
}
