import * as React from 'react';
import { ApolloError } from '@apollo/client/errors';
import { useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import { Location, LocationSchedule } from '@bondvet/types/locations';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { getFallbackTimezone } from 'lib/utils';
import {
    appointmentLogLocationQuery,
    multipleLocationSchedulesQuery,
    AppointmentLogEvent,
    BlockOffLog,
    BlockOffLogQueryResult,
    BlockOffLogQueryVariables,
    blockOffLogQuery,
} from 'api/bond/queries';
import useProviders from 'hooks/useProviders';
import {
    BlockOffLogType,
    ChangeAppointmentTypeNameBlockOffEvent,
    ChangeDurationBlockOffEvent,
    ChangeProviderNameBlockOffEvent,
    ChangeStartBlockOffEvent,
    CreateBlockOffEvent,
    DeleteBlockOffEvent,
    TEAM_VETSPIRE_USERNAME,
} from '../types';

export type BlockOffLogData = {
    blockOffLog: ReadonlyArray<BlockOffLog>;
    blockOffCount: number;
};

export type BlockOffLogs = {
    loading: boolean;
    error?: ApolloError;
    data: BlockOffLogData;
    missingData: boolean;
};
export default function useBlockOffLogs(
    day: Moment,
    locationIds: ReadonlyArray<string>,
    sameDayChangesOnly: boolean,
): BlockOffLogs {
    const CLIENT_VETSPIRE_ACCOUNT_NAME = 'Team';
    const { data, loading, error } = useQuery<
        BlockOffLogQueryResult,
        BlockOffLogQueryVariables
    >(blockOffLogQuery, {
        variables: { day: day.format('YYYY-MM-DD'), locationIds },
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        fetchPolicy: 'network-only',
    });

    const providersQueryResult = useProviders();

    const locationsQueryResult = useQuery<{
        locations: ReadonlyArray<Location>;
    }>(appointmentLogLocationQuery, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        fetchPolicy: 'cache-and-network',
    });

    const locationSchedulesQueryResult = useQuery<
        {
            multipleLocationSchedules: ReadonlyArray<LocationSchedule>;
        },
        { locationIds: ReadonlyArray<string> }
    >(multipleLocationSchedulesQuery, {
        variables: { locationIds },
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        fetchPolicy: 'cache-first',
    });

    const [
        decoratedAppointmentLogsWithStats,
        setDecoratedAppointmentLogsWithStats,
    ] = React.useState<BlockOffLogData>({
        blockOffCount: 0,
        blockOffLog: [],
    });

    React.useEffect(() => {
        let blockOffs = data?.blockOffLog;
        const { providers } = providersQueryResult;
        const locations = locationsQueryResult.data?.locations;
        const locationSchedules =
            locationSchedulesQueryResult.data?.multipleLocationSchedules;

        if (blockOffs && providers && locations && locationSchedules) {
            const getProviderName = (
                event: AppointmentLogEvent,
                locationId: string,
            ): string | undefined => {
                if (event.metadata?.provider_id) {
                    return providers.find(
                        (providerName) =>
                            event.metadata?.provider_id === providerName.id,
                    )?.name;
                }
                if (event.metadata?.schedule_id) {
                    return `${
                        locationSchedules.find(
                            (locationSchedule) =>
                                locationSchedule._id.toString() ===
                                    event.metadata?.schedule_id &&
                                locationSchedule.locationId === locationId,
                        )?.name
                    } column`;
                }
                return undefined;
            };

            // map the location over the location id
            blockOffs = blockOffs
                .map((blockOff) => ({
                    ...blockOff,
                    location: locations.find(
                        (l) => l._id === blockOff.locationId,
                    ),
                }))
                .map((blockOff: BlockOffLog): BlockOffLog => {
                    const decoratedEvents: (
                        | CreateBlockOffEvent
                        | ChangeStartBlockOffEvent
                        | ChangeDurationBlockOffEvent
                        | ChangeProviderNameBlockOffEvent
                        | ChangeAppointmentTypeNameBlockOffEvent
                        | DeleteBlockOffEvent
                    )[] = [];

                    if (
                        blockOff.events &&
                        blockOff.events.find(
                            (event) => event.type === 'appointment.create',
                        ) != null
                    ) {
                        let oldEventStart: Moment;
                        let oldDuration: number;
                        let oldAppointmentType = '?';
                        [...blockOff.events]
                            .sort((a, b) =>
                                a.datetime.localeCompare(b.datetime),
                            )
                            .forEach((event) => {
                                if (event.type === 'appointment.create') {
                                    const apptDatetime = moment.tz(
                                        event.metadata?.start || '',
                                        blockOff.location?.timezone ||
                                            getFallbackTimezone(),
                                    );
                                    // create event
                                    decoratedEvents.push({
                                        id: event.id,
                                        type: BlockOffLogType.create,
                                        eventDatetime: moment.tz(
                                            event.datetime,
                                            blockOff.location?.timezone ||
                                                getFallbackTimezone(),
                                        ),
                                        apptDatetime,
                                        providerName:
                                            getProviderName(
                                                event,
                                                blockOff.locationId,
                                            ) || '',
                                        cancelName:
                                            event.provider.name ===
                                            CLIENT_VETSPIRE_ACCOUNT_NAME
                                                ? TEAM_VETSPIRE_USERNAME
                                                : event.provider.name,
                                        appointmentTypeName:
                                            event.metadata
                                                ?.appointmentTypeName || '?',
                                        apptEndDatetime: apptDatetime
                                            .clone()
                                            .add(
                                                event.metadata?.duration || 0,
                                                'minutes',
                                            ),
                                    });
                                } else if (
                                    event.type === 'appointment.update'
                                ) {
                                    if (event.metadata?.start) {
                                        const apptDatetime = moment.tz(
                                            event.metadata?.start || '',
                                            blockOff.location?.timezone ||
                                                getFallbackTimezone(),
                                        );
                                        if (
                                            oldEventStart &&
                                            !oldEventStart.isSame(apptDatetime)
                                        ) {
                                            // date/time changed
                                            decoratedEvents.push({
                                                id: `${event.id}_start`,
                                                type: BlockOffLogType.changeStart,
                                                eventDatetime: moment.tz(
                                                    event.datetime,
                                                    blockOff.location
                                                        ?.timezone ||
                                                        getFallbackTimezone(),
                                                ),
                                                apptDatetime,
                                                cancelName:
                                                    event.provider.name ===
                                                    CLIENT_VETSPIRE_ACCOUNT_NAME
                                                        ? TEAM_VETSPIRE_USERNAME
                                                        : event.provider.name,
                                                appointmentTypeName:
                                                    oldAppointmentType,
                                            });
                                        }
                                    }
                                    if (event.metadata?.duration) {
                                        const apptDatetime = event.metadata
                                            ?.start
                                            ? moment.tz(
                                                  event.metadata?.start,
                                                  blockOff.location?.timezone ||
                                                      getFallbackTimezone(),
                                              )
                                            : oldEventStart;
                                        if (
                                            (oldEventStart &&
                                                !oldEventStart.isSame(
                                                    apptDatetime,
                                                )) ||
                                            oldDuration !==
                                                event.metadata.duration
                                        )
                                            // duration changed
                                            decoratedEvents.push({
                                                id: `${event.id}_duration`,
                                                type: BlockOffLogType.changeDuration,
                                                eventDatetime: moment.tz(
                                                    event.datetime,
                                                    blockOff.location
                                                        ?.timezone ||
                                                        getFallbackTimezone(),
                                                ),
                                                apptDatetime,
                                                oldApptDatetime: oldEventStart,
                                                oldApptEndDatetime:
                                                    oldEventStart
                                                        ?.clone()
                                                        .add(
                                                            oldDuration || 0,
                                                            'minutes',
                                                        ),
                                                cancelName:
                                                    event.provider.name ===
                                                    CLIENT_VETSPIRE_ACCOUNT_NAME
                                                        ? TEAM_VETSPIRE_USERNAME
                                                        : event.provider.name,
                                                appointmentTypeName:
                                                    oldAppointmentType,
                                                apptEndDatetime: apptDatetime
                                                    .clone()
                                                    .add(
                                                        event.metadata.duration,
                                                        'minutes',
                                                    ),
                                            });
                                    }
                                    if (
                                        event.metadata?.schedule_id ||
                                        event.metadata?.provider_id
                                    ) {
                                        // ScheduleId or ProviderId changed
                                        decoratedEvents.push({
                                            id: `${event.id}_schedule_id`,
                                            type: BlockOffLogType.changeProviderName,
                                            eventDatetime: moment.tz(
                                                event.datetime,
                                                blockOff.location?.timezone ||
                                                    getFallbackTimezone(),
                                            ),
                                            providerName:
                                                getProviderName(
                                                    event,
                                                    blockOff.locationId,
                                                ) || '',
                                            cancelName:
                                                event.provider.name ===
                                                CLIENT_VETSPIRE_ACCOUNT_NAME
                                                    ? TEAM_VETSPIRE_USERNAME
                                                    : event.provider.name,
                                            appointmentTypeName:
                                                oldAppointmentType,
                                        });
                                    }
                                    if (event.metadata?.appointmentTypeName) {
                                        // schedule name changed
                                        decoratedEvents.push({
                                            id: `${event.id}_appointmentTypeName`,
                                            type: BlockOffLogType.changeAppointmentTypeName,
                                            eventDatetime: moment.tz(
                                                event.datetime,
                                                blockOff.location?.timezone ||
                                                    getFallbackTimezone(),
                                            ),
                                            cancelName:
                                                event.provider.name ===
                                                CLIENT_VETSPIRE_ACCOUNT_NAME
                                                    ? TEAM_VETSPIRE_USERNAME
                                                    : event.provider.name,
                                            appointmentTypeName:
                                                event.metadata
                                                    .appointmentTypeName,
                                            oldAppointmentTypeName:
                                                oldAppointmentType,
                                        });
                                    }
                                } else if (
                                    event.type === 'appointment.delete'
                                ) {
                                    // deleted
                                    decoratedEvents.push({
                                        id: event.id,
                                        type: BlockOffLogType.deleteBlockOffEvent,
                                        eventDatetime: moment.tz(
                                            event.datetime,
                                            blockOff.location?.timezone ||
                                                getFallbackTimezone(),
                                        ),
                                        apptDatetime: oldEventStart,
                                        cancelName:
                                            event.provider.name ===
                                            CLIENT_VETSPIRE_ACCOUNT_NAME
                                                ? TEAM_VETSPIRE_USERNAME
                                                : event.provider.name,
                                        appointmentTypeName: oldAppointmentType,
                                        apptEndDatetime: oldEventStart
                                            .clone()
                                            .add(oldDuration, 'minutes'),
                                    });
                                }

                                if (event.metadata?.start) {
                                    oldEventStart = moment.tz(
                                        event.metadata.start,
                                        blockOff.location?.timezone ||
                                            getFallbackTimezone(),
                                    );
                                }
                                if (event.metadata?.duration) {
                                    oldDuration = event.metadata.duration;
                                }
                                if (event.metadata?.appointmentTypeName) {
                                    oldAppointmentType =
                                        event.metadata.appointmentTypeName;
                                }
                            });
                    }

                    return { ...blockOff, decoratedEvents };
                })
                .filter(
                    (blockOff: BlockOffLog) =>
                        blockOff.decoratedEvents.length > 0,
                );
            setDecoratedAppointmentLogsWithStats({
                blockOffCount: blockOffs.length,
                blockOffLog: blockOffs,
            });
        }
    }, [
        data?.blockOffLog,
        providersQueryResult,
        locationsQueryResult.data?.locations,
        day,
        locationSchedulesQueryResult,
        sameDayChangesOnly,
    ]);

    const missingData = React.useMemo(
        () =>
            !!data?.blockOffLog &&
            data?.blockOffLog.find(
                ({ events }) =>
                    !events ||
                    events.find(
                        (event) => event.type === 'appointment.create',
                    ) == null,
            ) != null,
        [data?.blockOffLog],
    );

    return {
        loading:
            loading ||
            providersQueryResult.loading ||
            locationsQueryResult.loading ||
            locationSchedulesQueryResult.loading,
        error:
            error ||
            providersQueryResult.error ||
            locationsQueryResult.error ||
            locationSchedulesQueryResult.error,
        data: decoratedAppointmentLogsWithStats,
        missingData,
    };
}
