import * as React from 'react';
import { useMutation } from '@apollo/client';
import { sendSetupLink, SendSetupLinkResult } from 'api/clients/mutations';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { useAnalytics } from 'hooks/useAnalytics';
import { Page } from 'lib/vetspireActions';

type State = {
    sendingLink: boolean;
    linkSent: boolean;
    linkError: string;
    sendLink: React.MouseEventHandler<HTMLButtonElement>;
};

const initialSendingState = {
    linkSent: false,
    linkError: '',
};

export default function useSendLink(clientId: string): State {
    const analytics = useAnalytics();
    const [{ linkSent, linkError }, setSendingState] = React.useState({
        ...initialSendingState,
    });

    const [mutate, { loading: sendingLink, data }] =
        useMutation<SendSetupLinkResult>(sendSetupLink, {
            variables: { clientId },
            context: { clientName: GRAPHQL_CLIENT_NAMES.creditCards },
        });

    const sendLink = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            setSendingState({
                ...initialSendingState,
            });

            mutate();
            analytics.trackEvent(Page.clientDetails, 'send_cc_link');
        },
        [mutate, analytics],
    );

    React.useEffect(() => {
        if (data?.sendSetupLink.success) {
            setSendingState((prev) => ({
                ...prev,
                linkSent: true,
            }));
        }
    }, [data]);

    React.useEffect(() => {
        let timer: NodeJS.Timeout | undefined;

        if (linkSent) {
            timer = setTimeout(() => {
                timer = undefined;
                setSendingState((prev) => ({
                    ...prev,
                    linkSent: false,
                }));
            }, 5000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [linkSent]);

    return { linkSent, linkError, sendingLink, sendLink };
}
