import * as React from 'react';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import { differenceInDays } from 'date-fns';
import useConversationalTextMessagingSettings from '../../hooks/useConversationalTextMessagingSettings';
import styles from './FaqModal.module.scss';

type FaqModalProps = {
    setShowFaqs: (newValue: boolean) => void;
    showFaqs: boolean;
};

export default function FaqModal({ setShowFaqs, showFaqs }: FaqModalProps) {
    const translate = useTranslate();
    const [faqLink, setFaqLink] = React.useState('');

    const { loading, conversationalTextingSettings } =
        useConversationalTextMessagingSettings();

    const faqLinks = React.useMemo(() => {
        return conversationalTextingSettings?.faqLinks || [];
    }, [conversationalTextingSettings?.faqLinks]);

    React.useEffect(() => {
        if (faqLinks.length > 0) {
            setFaqLink(faqLinks[0].url ?? '');
        }

        const today = new Date();
        if (conversationalTextingSettings?.goLiveDate) {
            const dayDifference = differenceInDays(
                today,
                conversationalTextingSettings?.goLiveDate,
            );
            if (
                dayDifference < 7 &&
                !localStorage.getItem(`faq-seen-${today.toDateString()}`)
            ) {
                setShowFaqs(true);
                localStorage.setItem(
                    `faq-seen-${today.toDateString()}`,
                    'true',
                );
            }
        }
    }, [conversationalTextingSettings?.goLiveDate, faqLinks, setShowFaqs]);

    const handlePillClick = React.useCallback((link: string) => {
        setFaqLink(link);
    }, []);

    const content = React.useMemo(() => {
        if (loading) {
            return <CircularProgress />;
        }
        return (
            <>
                <h2 className={styles.title}>
                    {translate('vetspireExtension.texting.faq.title')}
                </h2>
                <div className={styles.pills}>
                    {faqLinks.map(({ title, url }) => (
                        <button
                            key={url}
                            className={classnames(styles.pill, {
                                [styles.active]: faqLink === url,
                            })}
                            onClick={() => handlePillClick(url)}
                        >
                            {title}
                        </button>
                    ))}
                </div>
                {faqLink && (
                    <iframe
                        id="faq-iframe"
                        className={styles.faqIframe}
                        src={faqLink}
                        title="FAQ Content"
                    />
                )}
            </>
        );
    }, [faqLink, faqLinks, handlePillClick, loading, translate]);

    const contentRef = React.useRef<HTMLDivElement>(null);

    const handleClickOutside = React.useCallback(
        (event: MouseEvent) => {
            if (
                contentRef.current &&
                !contentRef.current?.contains(event.target as Node)
            ) {
                setShowFaqs(false);
            }
        },
        [setShowFaqs],
    );

    React.useEffect(() => {
        if (showFaqs) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside, showFaqs]);

    if (!showFaqs) {
        return null;
    }

    return (
        <div id="faq-modal" className={styles.faqModal}>
            <div className={styles.faqModalContent} ref={contentRef}>
                <button
                    onClick={() => setShowFaqs(false)}
                    type="button"
                    className={styles.closeButton}
                >
                    <CloseIcon />
                </button>
                {content}
            </div>
        </div>
    );
}
