import * as React from 'react';
import type { Translated } from '@bondvet/web-app-i18n/util';
import type { UseProviders } from 'hooks/useProviders';
import classnames from 'classnames';
import type { QueryResult } from '@apollo/client';
import Select, {
    type SingleValue,
    type MultiValue,
    type GroupBase,
    type OptionsOrGroups,
} from 'react-select';
import useTranslate from 'hooks/useTranslate';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import type { LocationsQueryResult } from 'api/locations/queries';
import type { Provider } from 'api/providers/queries';
import type { LookupStats } from './types';

import styles from './LookupSelect.module.scss';

interface ProvidersSelectProps {
    locationsQueryResult?: QueryResult<LocationsQueryResult>;
    providersQueryResult: UseProviders;
    selectedProviders: Provider[];
    vetsOnly?: boolean;
    addLocationGroup?: boolean;
    addOtherGroup?: boolean;
    onChange: (providers: Provider[]) => void;
    isDisabled?: boolean;
    stats?: LookupStats;
    isMulti?: boolean;
    className?: string;
    placeholder?: Translated;
}

enum Group {
    location = 'location',
    provider = 'provider',
    other = 'other',
}

enum OtherType {
    noDefaultLocation = 'noDefaultLocation',
    inactiveProvider = 'inactiveProvider',
}

interface Option {
    group: Group;
    value: string;
    label: string;
}

const ProvidersSelect: React.FunctionComponent<ProvidersSelectProps> = ({
    locationsQueryResult,
    providersQueryResult,
    selectedProviders,
    vetsOnly = false,
    onChange,
    isDisabled = false,
    stats,
    addLocationGroup = true,
    addOtherGroup = true,
    isMulti = true,
    className,
    placeholder,
}) => {
    const translate = useTranslate();
    const options = React.useMemo<
        OptionsOrGroups<Option, GroupBase<Option>>
    >(() => {
        const providerOptions = (providersQueryResult.providers || [])
            .filter(
                (provider) =>
                    provider.isActive && (provider.isVeterinarian || !vetsOnly),
            )
            .map((provider) => ({
                group: Group.provider,
                value: provider.id,
                label: provider.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        const newOptions: (GroupBase<Option> | Option)[] = [];

        // return options without groups
        if (!addLocationGroup && !addOtherGroup) {
            return providerOptions;
        }

        if (addLocationGroup && isMulti) {
            newOptions.push({
                label: translate(
                    'vetspireExtension.lookups.providers.locations',
                ) as string,
                options: (locationsQueryResult?.data?.locations || [])
                    .filter((location) => location.isActive)
                    .map((location) => ({
                        group: Group.location,
                        value: location.id,
                        label: location.name,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)),
            });
        }

        newOptions.push({
            label: translate(
                'vetspireExtension.lookups.providers.providers',
            ) as string,
            options: providerOptions,
        });

        if (addOtherGroup && isMulti) {
            newOptions.push({
                label: translate(
                    'vetspireExtension.lookups.providers.other',
                ) as string,
                options: [
                    {
                        group: Group.other,
                        value: OtherType.noDefaultLocation,
                        label: translate(
                            'vetspireExtension.lookups.providers.providersWithoutDefaultLocation',
                        ) as string,
                    },
                    {
                        group: Group.other,
                        value: OtherType.inactiveProvider,
                        label: translate(
                            'vetspireExtension.lookups.providers.inactiveProviders',
                        ) as string,
                    },
                ],
            });
        }

        return newOptions;
    }, [
        translate,
        locationsQueryResult,
        providersQueryResult,
        vetsOnly,
        addOtherGroup,
        addLocationGroup,
        isMulti,
    ]);

    const values = React.useMemo<MultiValue<Option>>(() => {
        return selectedProviders
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((provider) => ({
                group: Group.provider,
                value: provider.id,
                label: provider.name,
            }));
    }, [selectedProviders]);

    const getOptionLabel = (option: Option): string => {
        if (option.group === Group.provider) {
            const counter = stats?.[option.value]
                ? ` (${stats[option.value]})`
                : '';

            return `${option.label || ''}${counter}`;
        }
        return option.label;
    };

    const localOnChange = (
        newValues: SingleValue<Option> | MultiValue<Option>,
    ): void => {
        if (!isMulti) {
            const providersById: {
                [key: string]: Provider;
            } = {};

            const provider = (providersQueryResult.providers || []).find(
                (prov) => prov.id === (newValues as SingleValue<Option>)?.value,
            );

            if (provider) {
                providersById[provider.id] = provider;
            }

            onChange(Object.values(providersById));
        } else if (
            !newValues ||
            (newValues as MultiValue<Option>).length === 0
        ) {
            onChange([]);
        } else {
            const providersById: {
                [key: string]: Provider;
            } = {};

            (newValues as MultiValue<Option>).forEach((newValue: Option) => {
                if (newValue.group === Group.location) {
                    (providersQueryResult.providers || [])
                        .filter(
                            (provider) =>
                                provider.isActive &&
                                (provider.isVeterinarian || !vetsOnly) &&
                                provider.defaultLocationId === newValue.value,
                        )
                        .forEach((provider) => {
                            providersById[provider.id] = provider;
                        });
                } else if (newValue.group === Group.other) {
                    if (newValue.value === OtherType.noDefaultLocation) {
                        (providersQueryResult.providers || [])
                            .filter(
                                (provider) =>
                                    provider.isActive &&
                                    (provider.isVeterinarian || !vetsOnly) &&
                                    !provider.defaultLocationId,
                            )
                            .forEach((provider) => {
                                providersById[provider.id] = provider;
                            });
                    } else if (newValue.value === OtherType.inactiveProvider) {
                        (providersQueryResult.providers || [])
                            .filter(
                                (provider) =>
                                    !provider.isActive &&
                                    (provider.isVeterinarian || !vetsOnly),
                            )
                            .forEach((provider) => {
                                providersById[provider.id] = provider;
                            });
                    }
                } else {
                    const provider = (
                        providersQueryResult.providers || []
                    ).find((prov) => prov.id === newValue.value);
                    if (provider) {
                        providersById[provider.id] = provider;
                    }
                }
            });

            onChange(Object.values(providersById));
        }
    };

    return (
        <Select
            isMulti={isMulti}
            isLoading={
                locationsQueryResult?.loading || providersQueryResult.loading
            }
            name="providers"
            options={options}
            value={values}
            className={classnames(styles.lookup, className)}
            classNamePrefix="lookup-select"
            getOptionLabel={getOptionLabel}
            onChange={localOnChange}
            menuPosition="fixed"
            isDisabled={isDisabled}
            placeholder={
                <div className={styles.placeholder}>
                    <PersonOutlineIcon
                        fontSize="small"
                        classes={{
                            root: styles.placeholderMuiIcon,
                        }}
                    />
                    {placeholder ??
                        translate(
                            'vetspireExtension.lookups.providers.placeholder',
                        )}
                </div>
            }
        />
    );
};

export default ProvidersSelect;
