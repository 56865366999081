export type WithFlyout<Styles extends { readonly [key: string]: string }> = {
    flyoutBlock: string;
    flyoutTitle: string;
    first: string;
    noMargin: string;
    noHorizontal: string;
    noVertical: string;
    padding: string;
} & Styles;

export enum PageDomain {
    vetspire = 'vetspire',
    humanity = 'humanity',
}

export type YearAndDate = {
    year: number;
    date: string;
};
