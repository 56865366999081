import * as React from 'react';
import Select, { MultiValue } from 'react-select';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import useTranslate from 'hooks/useTranslate';
import styles from 'components/LookupSelect/LookupSelect.module.scss';
import {
    USER_TYPE_SELECTED_CLIENT,
    USER_TYPE_SELECTED_TEAM,
    UserTypeSelectOption,
} from '../../types';

interface AppointmentLogUserTypeSelectProps {
    selectedUserTypes: UserTypeSelectOption[];
    setSelectedUserTypes: (userType: UserTypeSelectOption[]) => void;
}

const AppointmentLogUserTypeSelect: React.FunctionComponent<
    AppointmentLogUserTypeSelectProps
> = ({ selectedUserTypes, setSelectedUserTypes }) => {
    const translate = useTranslate();

    return (
        <Select
            isMulti
            name="userTypes"
            value={selectedUserTypes}
            className={styles.lookup}
            classNamePrefix="lookup-select"
            options={[
                {
                    value: USER_TYPE_SELECTED_CLIENT,
                    label: translate(
                        `vetspireExtension.apptLog.userFilters.client`,
                    ),
                } as UserTypeSelectOption,
                {
                    value: USER_TYPE_SELECTED_TEAM,
                    label: translate(
                        `vetspireExtension.apptLog.userFilters.team`,
                    ),
                } as UserTypeSelectOption,
            ]}
            onChange={(value: MultiValue<UserTypeSelectOption>) => {
                setSelectedUserTypes((value as UserTypeSelectOption[]) || []);
            }}
            placeholder={
                <div className={styles.placeholder}>
                    <PersonOutlineOutlinedIcon
                        className={styles.placeholderIcon}
                    />
                    {translate(
                        `vetspireExtension.apptLog.userFilters.placeholder`,
                    )}
                </div>
            }
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
        />
    );
};
export default AppointmentLogUserTypeSelect;
