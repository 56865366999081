import * as React from 'react';
import { QueryResult } from '@apollo/client';
import Select, { MultiValue } from 'react-select';
import useTranslate from 'hooks/useTranslate';
import { TasksCategoriesQueryResult, TaskCategory } from 'api/tasks/queries';
import { ReactComponent as TaskCategoryIcon } from 'assets/taskCategory.svg';
import { LookupStats } from './types';

import styles from './LookupSelect.module.scss';

interface TaskCategoriesSelectProps {
    taskCategoriesQueryResult: QueryResult<TasksCategoriesQueryResult>;
    selectedTaskCategories: TaskCategory[];
    onChange: (taskCategories: TaskCategory[]) => void;
    stats: LookupStats;
}

const TaskCategoriesSelect: React.FunctionComponent<
    TaskCategoriesSelectProps
> = ({
    taskCategoriesQueryResult,
    selectedTaskCategories,
    onChange,
    stats,
}) => {
    const translate = useTranslate();
    const getTaskCategoriesName = (taskCategory: TaskCategory) => {
        const counter = stats[taskCategory.id]
            ? ` (${stats[taskCategory.id]})`
            : '';
        return `${taskCategory.name || ''}${counter}`;
    };

    const options = React.useMemo(() => {
        return (
            taskCategoriesQueryResult.data?.taskCategories
                .slice()
                .sort((a, b) => (a.name || '').localeCompare(b.name || '')) ||
            []
        );
    }, [taskCategoriesQueryResult.data]);

    return (
        <Select
            isMulti
            isLoading={taskCategoriesQueryResult.loading}
            name="providers"
            options={options}
            value={selectedTaskCategories}
            className={styles.lookup}
            classNamePrefix="lookup-select"
            onChange={(value: MultiValue<TaskCategory>) =>
                onChange((value || []) as TaskCategory[])
            }
            getOptionValue={(taskCategory: TaskCategory) => taskCategory.id}
            getOptionLabel={getTaskCategoriesName}
            placeholder={
                <div className={styles.placeholder}>
                    <TaskCategoryIcon className={styles.placeholderIcon} />
                    {translate(
                        'vetspireExtension.lookups.taskCategories.placeholder',
                    )}
                </div>
            }
        />
    );
};

export default TaskCategoriesSelect;
