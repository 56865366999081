import * as React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { Encounter } from 'api/encounters/queries';
import useTimezone from 'hooks/useTimezone';
import { openPatientEncounter } from 'lib/vetspireActions';
import { DateFilterType } from 'components/DateFilter/types';
import dateFilterStyles from 'components/DateFilter/DateFilter.module.scss';

import styles from './EncounterItem.module.scss';

interface EncounterItemProps {
    encounter: Encounter;
    dateFilterType: DateFilterType;
}

const EncounterItem: React.FunctionComponent<EncounterItemProps> = ({
    encounter,
    dateFilterType,
}) => {
    const timezone = useTimezone();

    const start = moment.tz(encounter.start, timezone);

    return (
        <div
            className={styles.container}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => {
                if (encounter.patient?.client?.id) {
                    openPatientEncounter(
                        encounter.patient?.client?.id,
                        encounter.patient?.id,
                        encounter.id,
                    );
                }
            }}
        >
            <div
                className={classnames(
                    styles.startDateColorColumn,
                    dateFilterStyles[`bg-${dateFilterType}`],
                )}
            />
            <div className={styles.content}>
                <div className={styles.header}>
                    <div
                        className={classnames(
                            styles.headerLeft,
                            styles.smallText,
                        )}
                    >
                        {encounter.patient &&
                            `${encounter.patient?.client?.name} > ${encounter.patient?.name}`}
                    </div>
                    <div
                        className={classnames(
                            styles.headerRight,
                            styles.smallText,
                        )}
                    >
                        {encounter.location?.name || ''}
                    </div>
                </div>

                <div className={styles.textLine}>
                    <div className={styles.textLeft}>
                        {encounter.provider?.name}
                    </div>
                    <div className={styles.textRight}>
                        {start.format('MMM D, YYYY')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EncounterItem;
