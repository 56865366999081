import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import * as React from 'react';
import { Moment } from 'moment';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import classnames from 'classnames';
import styles from './WeekSelect.module.scss';

interface WeekSelectProps {
    week: Moment;
    onChange: (newDay: Moment) => void;
    mode?: 'default' | 'picker';
}

const WeekSelect: React.FunctionComponent<WeekSelectProps> = ({
    week,
    onChange,
    mode = 'default',
}) => {
    const [showDatePicker, setShowDatePicker] = React.useState(false);

    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleChange = (value: Moment) => {
        setShowDatePicker(false);
        onChange(value);
    };

    const content = (
        <>
            {mode === 'default' && (
                <button
                    type="button"
                    className={styles.buttonBack}
                    onClick={() => {
                        onChange(week.clone().subtract({ days: 7 }));
                    }}
                >
                    <ArrowBackIosIcon fontSize="small" />
                </button>
            )}
            <div className={styles.date}>
                {week.format('MM/D/YY')} -{' '}
                {week.clone().add({ days: 6 }).format('MM/D/YY')}
            </div>
            {mode === 'default' && (
                <button
                    type="button"
                    className={styles.buttonForward}
                    onClick={() => {
                        onChange(week.clone().add({ days: 7 }));
                    }}
                >
                    <ArrowBackIosIcon fontSize="small" />
                </button>
            )}
            {mode === 'picker' && <CalendarTodayIcon fontSize="small" />}
        </>
    );

    if (mode === 'picker') {
        return (
            <div className={styles.containerWrapper}>
                <button
                    onClick={toggleDatePicker}
                    className={classnames(styles.container, styles.picker)}
                >
                    {content}
                </button>
                {showDatePicker && (
                    <div className={styles.calendar}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateCalendar
                                defaultValue={week}
                                onChange={handleChange}
                            />
                        </LocalizationProvider>
                    </div>
                )}
            </div>
        );
    }
    return <div className={styles.container}>{content}</div>;
};

export default WeekSelect;
