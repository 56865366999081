import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';

import { isSameDay } from 'date-fns';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import { formatInTimeZone } from 'date-fns-tz';
import { LocationConversationInfo } from '@bondvet/types/textMessaging';

import useTimezone from 'hooks/useTimezone';

import { ReactComponent as UnreadDotSvg } from '../../assets/unreadDot.svg';
import useConversationalTextMessagingSettings from '../../hooks/useConversationalTextMessagingSettings';
import { ClientWithPatients } from '../../api';

import styles from './RecentLocationConversations.module.scss';

type RecentConversationsProps = {
    selectedClient: ClientWithPatients | undefined | null;
    setSelectedClient: (client: ClientWithPatients | null) => void;
    recentConversationInfo: readonly LocationConversationInfo[];
    loading: boolean;
};

export default function RecentLocationConversations({
    selectedClient,
    setSelectedClient,
    recentConversationInfo,
    loading,
}: RecentConversationsProps): React.ReactElement {
    const translate = useTranslate();
    const timezone = useTimezone();
    const settings = useConversationalTextMessagingSettings();

    const handleConversationSelect = React.useCallback(
        (info: LocationConversationInfo) => {
            setSelectedClient({
                id: info.vetspireClientId,
                familyName: info.familyName,
                givenName: info.givenName,
                pets: info.pets,
                preferredPhoneNumber: info.preferredPhoneNumber || '',
                optedIn: info.optedIn,
            });
        },
        [setSelectedClient],
    );
    return (
        <div className={styles.container}>
            <div className={styles.label}>
                {translate(
                    'vetspireExtension.texting.recentLocationConversations.blurb',
                    {
                        hours: settings.conversationalTextingSettings
                            .showMessagesForHours,
                    },
                )}
            </div>
            <div className={styles.list}>
                {loading ? (
                    <div className={styles.loadingContainer}>
                        <CircularProgress />
                    </div>
                ) : (
                    recentConversationInfo.map((info) => {
                        const formatString = isSameDay(
                            new Date(info.datetime),
                            new Date(),
                        )
                            ? 'h:mma'
                            : 'MMM dd';

                        const formattedDate = formatInTimeZone(
                            info.datetime,
                            timezone,
                            formatString,
                        );

                        return (
                            <div
                                className={classnames(styles.conversationInfo, {
                                    [styles.selected]:
                                        selectedClient?.id ===
                                        info.vetspireClientId,
                                })}
                                key={info.vetspireClientId}
                                onClick={() => handleConversationSelect(info)}
                            >
                                <div className={styles.nameAndDate}>
                                    {info.unread ? (
                                        <div className={styles.unreadInfo}>
                                            <UnreadDotSvg
                                                className={styles.dot}
                                            />
                                            <span>
                                                {`${info.givenName} ${info.familyName}`}
                                            </span>
                                        </div>
                                    ) : (
                                        <span className={styles.name}>
                                            {`${info.givenName} ${info.familyName}`}
                                        </span>
                                    )}

                                    <div className={styles.date}>
                                        {formattedDate}
                                    </div>
                                </div>
                                <span className={styles.pets}>
                                    {(info.pets || []).map((p, index) => (
                                        <span
                                            className={classnames(
                                                styles.patient,
                                                {
                                                    [styles.inactiveOrDeceased]:
                                                        p.inactive ||
                                                        p.deceased,
                                                },
                                            )}
                                            key={p.id}
                                        >
                                            {p.name}
                                            {index ===
                                            (info.pets || []).length - 1
                                                ? ''
                                                : ', '}
                                        </span>
                                    ))}
                                </span>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}
