import * as React from 'react';
import ActionButton from 'components/ActionButton';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import { useApolloClient, useMutation } from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useAnalytics } from 'hooks/useAnalytics';
import { Page } from 'lib/vetspireActions';
import { SurgeryReferralFragment } from '../../api/fragments';
import {
    linkSurgeryReferral,
    LinkSurgeryReferralResult,
    LinkSurgeryReferralVariables,
    unlinkSurgeryReferral,
    UnlinkSurgeryReferralResult,
    UnlinkSurgeryReferralVariables,
} from '../../api/mutations';
import styles from './LinkSurgeryReferral.module.scss';
import { GraphQLSurgeryReferral } from '../../api/queries';

type LinkSurgeryReferralProps = {
    invoiceId: string | null;
    isLinked: boolean;
    onLinked: () => void;
    referral: GraphQLSurgeryReferral;
    canLink: boolean;
};

export default function LinkSurgeryReferral({
    invoiceId,
    referral,
    isLinked,
    onLinked,
    canLink,
}: LinkSurgeryReferralProps): React.ReactElement {
    const analytics = useAnalytics();
    const translate = useTranslate();
    const { _id: referralId, provider, linkedInvoices, surgeryType } = referral;
    const client = useApolloClient();
    const [linkReferral, { loading: linking }] = useMutation<
        LinkSurgeryReferralResult,
        LinkSurgeryReferralVariables
    >(linkSurgeryReferral, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });
    const [unlinkReferral, { loading: unlinking }] = useMutation<
        UnlinkSurgeryReferralResult,
        UnlinkSurgeryReferralVariables
    >(unlinkSurgeryReferral, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });
    const [showDialog, setShowDialog] = React.useState(false);

    const onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setShowDialog(false);
    };

    const onUnlink = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();

            if (isLinked) {
                unlinkReferral({ variables: { referralId } }).then(
                    ({ data }) => {
                        if (data) {
                            client.writeFragment({
                                fragment: SurgeryReferralFragment,
                                id: data.unlinkSurgeryReferral._id,
                                data: data.unlinkSurgeryReferral,
                            });
                        }
                    },
                );
                analytics.trackEvent(
                    Page.surgeryReferrals,
                    'unlink_referral_to_invoice',
                );
            }
            setShowDialog(false);
        },
        [referralId, unlinkReferral, client, isLinked, analytics],
    );

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();

            if (isLinked) {
                setShowDialog(true);
            } else if (invoiceId) {
                linkReferral({ variables: { referralId, invoiceId } }).then(
                    ({ data }) => {
                        if (data) {
                            onLinked();
                            client.writeFragment({
                                fragment: SurgeryReferralFragment,
                                id: data.linkSurgeryReferral._id,
                                data: data.linkSurgeryReferral,
                            });
                        }
                    },
                );
                analytics.trackEvent(
                    Page.surgeryReferrals,
                    'link_referral_to_invoice',
                );
            }
        },
        [
            invoiceId,
            referralId,
            linkReferral,
            client,
            isLinked,
            onLinked,
            analytics,
        ],
    );

    // for increased readability, let's split up the reasons why
    // the "Link to current SX" button could be disabled
    // ("Unlink" will never be disabled, though)
    const isProcessing = linking || unlinking;
    const notLinkedAndNotOnInvoiceRoute = !invoiceId && !isLinked;

    // `canLink` will be set to `false` if we're currently displaying an invoice
    // which has already been linked to a referral
    const notLinkedAndLinkingDisabled = !isLinked && !canLink;

    const actionDisabled =
        isProcessing ||
        notLinkedAndNotOnInvoiceRoute ||
        notLinkedAndLinkingDisabled;

    return (
        <>
            <ActionButton
                className={classnames(styles.button, {
                    [styles.linked]: isLinked,
                })}
                disabled={actionDisabled}
                type="button"
                onClick={handleClick}
            >
                {translate(
                    `vetspireExtension.surgeryReferrals.referrals.${
                        isLinked ? 'unlink' : 'link'
                    }`,
                )}
            </ActionButton>
            {isLinked && (
                <Dialog open={showDialog} keepMounted={false}>
                    <DialogTitle>
                        {translate(
                            'vetspireExtension.surgeryReferrals.referrals.unlinkTitle',
                        )}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {translate(
                                'vetspireExtension.surgeryReferrals.referrals.unlinkConfirm',
                                {
                                    provider: provider.name,
                                    invoice: linkedInvoices?.[0]?.invoiceId,
                                    procedure: surgeryType.name,
                                },
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={onCancel}>
                            {translate(
                                'vetspireExtension.surgeryReferrals.referrals.unlinkCancel',
                            )}
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={onUnlink}
                        >
                            {translate(
                                'vetspireExtension.surgeryReferrals.referrals.unlinkSubmit',
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
