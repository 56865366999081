import * as React from 'react';
import {
    SurgeryReferralCheckboxesAnswer,
    SurgeryReferralDropdownAnswer,
    SurgeryReferralRadiosAnswer,
    SurgeryReferralTextAnswer,
} from '@bondvet/types/surgeryReferrals';
import styles from './QuestionForm.module.scss';
import { AnswerData, OptionsAnswerData } from '../../types';
import { sendSurgeryReferralContext } from '../../context';
import TextInput from './TextInput';
import CheckboxesInput from './CheckboxesInput';
import DropdownInput from './DropdownInput';
import RadiosInput from './RadiosInput';

interface QuestionFormProps {
    answer: AnswerData;
}

export default function QuestionForm({
    answer,
}: QuestionFormProps): React.ReactElement {
    const { label, type, required, id } = answer;
    const { onChange } = React.useContext(sendSurgeryReferralContext);

    const handleChange = React.useCallback(
        (value: string | string[]) => {
            onChange(id, type, value);
        },
        [onChange, id, type],
    );

    return (
        <fieldset className={styles.container}>
            <label htmlFor={id} className={styles.label}>
                {label}
                {required ? '*' : ''}
            </label>
            {type === 'text' && (
                <TextInput
                    id={id}
                    value={(answer as SurgeryReferralTextAnswer).answer}
                    onChange={handleChange}
                    required={required}
                />
            )}
            {type === 'checkboxes' && (
                <CheckboxesInput
                    value={(answer as SurgeryReferralCheckboxesAnswer).answers}
                    options={(answer as OptionsAnswerData).options}
                    onChange={handleChange}
                    id={id}
                    required={required}
                />
            )}
            {type === 'dropdown' && (
                <DropdownInput
                    value={(answer as SurgeryReferralDropdownAnswer).answer}
                    options={(answer as OptionsAnswerData).options}
                    onChange={handleChange}
                    id={id}
                    required={required}
                />
            )}
            {type === 'radios' && (
                <RadiosInput
                    value={(answer as SurgeryReferralRadiosAnswer).answer}
                    options={(answer as OptionsAnswerData).options}
                    onChange={handleChange}
                    id={id}
                    required={required}
                />
            )}
        </fieldset>
    );
}
