import { gql } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import useSchedulingQuery from 'hooks/useSchedulingQuery';
import {
    STAFFING_ROLES,
    StaffingSettings,
    StaffingSettingsQueryResult,
    defaultStaffingSettings,
} from '@bondvet/types/scheduling/staffing';

export const staffingSettingsQuery = gql`
    query staffingSettings {
        staffingSettings {
            skillRoleMapping {
                ${Object.values(STAFFING_ROLES).join('\n')}
            }
        }
    }
`;

type UseStaffingSettings = {
    loading: boolean;
    error?: ApolloError;
    staffingSettings: StaffingSettings;
};

export default function useStaffingSettings(): UseStaffingSettings {
    const { data, loading, error } =
        useSchedulingQuery<StaffingSettingsQueryResult>(staffingSettingsQuery, {
            fetchPolicy: 'cache-first',
        });

    return {
        loading,
        error,
        staffingSettings: data?.staffingSettings || defaultStaffingSettings,
    };
}
