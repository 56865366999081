import * as React from 'react';
import classNames from 'classnames';
import { Credit } from 'api/bond/queries';
import { Provider } from 'api/providers/queries';
import styles from './CreditMemo.module.scss';
import { formatAmountAsUSD } from '../../util';

type CreditWithProvider = Credit & {
    provider?: Provider;
};

export type CreditMemoProps = {
    classes?: {
        root?: string;
        selected?: string;
    };
    credit: CreditWithProvider;
    className?: string;
};

function CreditMemo({
    classes,
    credit,
    className,
}: CreditMemoProps): React.ReactElement {
    const { amount, date, notes, provider } = credit;

    return (
        <div
            role="button"
            tabIndex={0}
            className={classNames(className, classes?.root, styles.root)}
        >
            <strong>{date}</strong>
            <span>{provider?.name}</span>
            <span className={+amount > 0 ? styles.positive : styles.negative}>
                {formatAmountAsUSD(+amount)}
            </span>
            <span>{notes}</span>
        </div>
    );
}

export default CreditMemo;
