import { ImportMessage, Projection } from '../common';
import { NonNullableObject, PickStartsWith } from '../util';

export interface BaseTransaction {
    /** alphanumeric id */
    _id: string;
    /** numeric-as-string foreign key to {@link User} `_vetspire.id` */
    vetspireClientId: string;
    /** date and time of the transaction */
    date: Date;
    /** payment id, required to compile the aggregation key */
    paymentId: string;
    /** raw, total amount in USD cents of this transaction */
    amount: number;
    /** additional notes explaining this transaction */
    notes: string | null;
}

export const REFERENCE_TYPE_CREDIT_MEMO = 'creditMemo';
export const REFERENCE_TYPE_PAYMENT = 'payment';

export interface BaseReference {
    date: Date;
    notes: string | null;
    providerId: string | null;
    vetspireLocationId: string | null;
    locationName: string | null;
}

/**
 * additional data of a credit memo
 */
export interface CreditMemoData {
    /** link to the Vetspire CreditMemo */
    creditMemoId: string;
    /** link to the Vetspire order item, if it exists */
    orderItemId: string | null;
}

/**
 * additional data of a payment
 */
export interface PaymentData {
    /** link to the Vetspire Payment */
    paymentId: string;
    /**
     * link to {@link VetspireOrder | Vetspire orders}, if they exist
     */
    orderIds: ReadonlyArray<string>;
    /**
     * IDs of linked estimated
     */
    estimateIds: ReadonlyArray<string>;
    /**
     * taken from Vetspire's Payment.methodType
     */
    methodType: string | null;
}

/**
 * reference to a credit memo
 */
export interface CreditMemoReference extends BaseReference, CreditMemoData {
    type: typeof REFERENCE_TYPE_CREDIT_MEMO;
}

/**
 * reference to a payment
 */
export interface PaymentReference extends BaseReference, PaymentData {
    type: typeof REFERENCE_TYPE_PAYMENT;
}

/**
 * reference to either a credit memo or a payment
 */
export type Reference = CreditMemoReference | PaymentReference;

/**
 * possible buckets of credits
 */
export enum CreditBucket {
    promoCode = 'Promo Codes',
    courtesy = 'Courtesy',
    notGoodwill = 'Not Goodwill',
    other = 'Other',
}

export type CreditBucketKey = keyof typeof CreditBucket;

export type SelectableCreditBucket = Exclude<
    CreditBucket,
    CreditBucket.other | CreditBucket.notGoodwill
>;

export const CREDIT_MEMO_OPTION_CASH = 'CASH';

export interface CreditMemoNoteOption {
    value: SelectableCreditBucket | typeof CREDIT_MEMO_OPTION_CASH;
    label: string;
}

/**
 * shared data of {@link RawTransaction} for both `type`s
 */
export interface BaseRawTransaction extends BaseTransaction {
    /**
     * whether the balances for this transaction have already been
     * calculated
     */
    _isNew?: boolean;
    /**
     * sometimes we find both a payment and a credit memo for
     * the same transaction. They usually have the same timestamp
     * but we will use the same 5 second period and the amount
     * as an aggregation key
     */
    aggregationKey: string;

    /**
     * numeric-as-key foreign key to {@link VetspireProvider}
     * provider involved in this transaction (if any)
     */
    providerId: string | null;
    /**
     * numeric-as-key foreign key to {@link Location}._vetspire.id
     */
    vetspireLocationId: string | null;
    /**
     * name of the location of this transaction, if recorded
     */
    locationName: string | null;
}

/**
 * {@link RawTransaction} of a credit memo
 */
export interface RawCreditMemoTransaction
    extends BaseRawTransaction,
        CreditMemoData {
    type: CreditMemoReference['type'];
}

/**
 * {@link RawTransaction} of a payment
 */
export interface RawPaymentTransaction extends BaseRawTransaction, PaymentData {
    type: PaymentReference['type'];
}

/**
 * collection: `creditLedger.rawTransactions`
 * raw transaction stored on the credit ledger of a client,
 * without any balances applied to it
 */
export type RawTransaction = RawCreditMemoTransaction | RawPaymentTransaction;

/**
 * balances of a {@link Transaction}
 */
export interface Balances {
    /**
     * amount of "not goodwill" credits in USD cents of this transaction.
     * a negative value represents the usage of such credits f.e. on an invoice.
     * will be `null` initially for imported payments.
     */
    notGoodwillCredits: number | null;

    /**
     * amount of courtesy credits in USD cents of this transaction.
     * a negative value represents the usage of such credits f.e. on an invoice.
     * will be `null` initially for imported payments.
     */
    courtesyCredits: number | null;

    /**
     * amount of promo credits in USD cents of this transaction.
     * a negative value represents the usage of such credits f.e. on an invoice.
     * will be `null` initially for imported payments.
     */
    promoCodeCredits: number | null;

    /**
     * amount of other credits in USD cents of this transaction.
     * a negative value represents the usage of such credits f.e. on an invoice.
     * will be `null` initially for imported payments.
     */
    otherCredits: number | null;

    /**
     * balance of "not goodwill" credits prior to this transaction.
     * will be set to `null` when importing transactions and will be fixed
     * per client afterwards
     */
    oldNotGoodwillCreditsBalance: number | null;
    /**
     * balance of "not goodwill" credits after this transaction.
     * will be set to `null` when importing transactions and will be fixed
     * per client afterwards
     */
    newNotGoodwillCreditsBalance: number | null;

    /**
     * balance of courtesy credits prior to this transaction.
     * will be set to `null` when importing transactions and will be fixed
     * per client afterwards
     */
    oldCourtesyCreditsBalance: number | null;
    /**
     * balance of courtesy credits after this transaction.
     * will be set to `null` when importing transactions and will be fixed
     * per client afterwards
     */
    newCourtesyCreditsBalance: number | null;

    /**
     * balance of promo credits prior to this transaction.
     * will be set to `null` when importing transactions and will be fixed
     * per client afterwards
     */
    oldPromoCodeCreditsBalance: number | null;
    /**
     * balance of promo credits after this transaction.
     * will be set to `null` when importing transactions and will be fixed
     * per client afterwards
     */
    newPromoCodeCreditsBalance: number | null;

    /**
     * balance of other credits prior to this transaction.
     * will be set to `null` when importing transactions and will be fixed
     * per client afterwards
     */
    oldOtherCreditsBalance: number | null;
    /**
     * balance of other credits after this transaction.
     * will be set to `null` when importing transactions and will be fixed
     * per client afterwards
     */
    newOtherCreditsBalance: number | null;

    /**
     * total amount of credits before this transaction
     */
    oldTotalCreditsBalance: number;

    /**
     * total amount of credits after this transaction
     */
    newTotalCreditsBalance: number;
}

export type OldBalances = NonNullableObject<PickStartsWith<Balances, 'old'>>;
export type NewBalances = NonNullableObject<PickStartsWith<Balances, 'new'>>;
export type BalancesPayload = NonNullableObject<
    Omit<Balances, keyof PickStartsWith<Balances, 'old' | 'new'>>
>;

// deduction order:
// Not Goodwill
// → Courtesy
// → Promo Codes
// → Other
export const DEDUCTION_ORDER: ReadonlyArray<keyof BalancesPayload> = [
    'notGoodwillCredits',
    'courtesyCredits',
    'promoCodeCredits',
    'otherCredits',
];

export const OLD_DEDUCTION_ORDER: ReadonlyArray<keyof OldBalances> = [
    'oldNotGoodwillCreditsBalance',
    'oldCourtesyCreditsBalance',
    'oldPromoCodeCreditsBalance',
    'oldOtherCreditsBalance',
];

// cancelled promoCodes have a different deduction order
export const CANCELLED_PROMO_CODE_DEDUCTION_ORDER: ReadonlyArray<
    keyof BalancesPayload
> = [
    'promoCodeCredits',
    'otherCredits',
    'courtesyCredits',
    'notGoodwillCredits',
];

export const OLD_CANCELLED_PROMO_CODE_DEDUCTION_ORDER: ReadonlyArray<
    keyof OldBalances
> = [
    'oldPromoCodeCreditsBalance',
    'oldOtherCreditsBalance',
    'oldCourtesyCreditsBalance',
    'oldNotGoodwillCreditsBalance',
];

/**
 * collection: `creditLedger.transactions`
 * transaction stored on the credit ledger of a client
 */
export interface Transaction extends BaseTransaction, Balances {
    /**
     * {@link Reference | references} to all the {@link RawTransaction | raw transactions} with
     * the same amount and (nearly) the same time
     */
    references: ReadonlyArray<Reference>;

    /**
     * which {@link CreditBucket | bucket} does this fall into, if it's a credit
     * (just for convenience. can actually be deducted by looking at the balances)
     *
     */
    bucket?: CreditBucket;
}

export const TRANSACTIONS_COLLECTION_NAME = 'creditLedger.transactions';
export const RAW_TRANSACTIONS_COLLECTION_NAME = 'creditLedger.rawTransactions';

export const PUBSUB_TOPIC_IMPORT_CREDIT_MEMOS =
    'credit-ledger-import-credit-memos';
export const PUBSUB_TOPIC_IMPORT_PAYMENTS = 'credit-ledger-import-payments';
export const PUBSUB_TOPIC_ADJUST_BALANCES = 'credit-ledger-adjust-balances';
export const PUBSUB_TOPIC_TRIGGER_MISSING_BALANCES =
    'credit-ledger-trigger-missing-balances';

// don't know yet if we are actually going to use this, but this
// describes the order in which we will use up the credit buckets
// when want to deduct credits from a client's ledger
export const CREDIT_DEDUCTION_ORDER: ReadonlyArray<CreditBucket> = [
    CreditBucket.courtesy,
    CreditBucket.promoCode,
    CreditBucket.other,
] as const;

export const IMPORT_PAYMENTS = 'import-payments';

export type ImportCreditLedgerPayload = {
    clientId?: string;
};

export type ImportPaymentsMessage = ImportMessage<
    typeof IMPORT_PAYMENTS,
    ImportCreditLedgerPayload
>;

export interface AdjustBalancesMessage {
    vetspireClientId: string;
    // timestamp in milliseconds from where to begin
    // adjusting balances for the current client
    start: number;
}

export const AGGREGATION_DATA = [
    'amount',
    'date',
    'vetspireClientId',
    'paymentId',
] as const;

export type AggregationDataFields = (typeof AGGREGATION_DATA)[number];

export type AggregationData = Pick<BaseTransaction, AggregationDataFields>;

const TRANSACTION_DATA_FIELDS = [
    'vetspireClientId',
    'date',
    'amount',
    'notGoodwillCredits',
    'courtesyCredits',
    'promoCodeCredits',
    'otherCredits',
    'notes',
] as const;

export const TRANSACTION_DATA_PROJECTION: Projection =
    TRANSACTION_DATA_FIELDS.reduce(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        {},
    );

export type TransactionDataFields = (typeof TRANSACTION_DATA_FIELDS)[number];

export type TransactionData = Pick<Transaction, TransactionDataFields>;

export interface CreditLedgerReportData<Timestamp extends number | Date> {
    processed: boolean;
    started: boolean;
    startDate: string;
    endDate: string;
    createdAt: Timestamp;
    createdBy: string;
    startedAt?: Timestamp;
    finishedAt?: Timestamp;
    fileName?: string;
    storagePath?: string;
}

export interface CreditLedgerReport<Timestamp extends number | Date>
    extends CreditLedgerReportData<Timestamp> {
    id: string;
}

/**
 * collection: `vetspire.ledgerEntries`
 */
export interface VetspireLedgerEntry {
    /** numeric-as-string id in Vetspire (e.g. "1234") */
    _id: string;
    /** imported from Vetspire */
    transactionType: string;
    /** imported from Vetspire */
    /** imported from Vetspire */
    transactionId: string;
    /** imported from Vetspire */
    orderIds: readonly string[];
    /** imported from Vetspire */
    datetime: Date;
    /** imported from Vetspire */
    locationId: string;
    /** imported from Vetspire */
    clientId: string;
    /** imported from Vetspire */
    account: string | null;
    /** imported from Vetspire */
    subaccount: string | null;
    /** imported from Vetspire */
    debitCents: number;
    /** imported from Vetspire */
    creditCents: number;
}
