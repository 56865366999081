import {
    PreventionPlan as BaseVetspirePreventionPlan,
    PlanOption as BaseVetspirePlanOption,
    PatientPlan as BaseVetspirePatientPlan,
    PatientPlanItem as BaseVetspirePatientPlanItem,
    Product as BaseVetspireProduct,
    ProductPackage,
} from '../../generated/vetspire';
import { OperationResult } from '../common';
import { IdAndName, VetspireSex } from '../vetspire';

export enum VetspirePlanInterval {
    Monthly = 'MONTHLY',
    Quarterly = 'QUARTERLY',
    Yearly = 'YEARLY',
}

export {
    PATIENT_PLAN_EVENTS_COLLECTION_NAME,
    PATIENT_PLANS_COLLECTION_NAME,
    PUBSUB_TOPIC_PROCESS_PLAN_ITEM_INVOICE,
    PUBSUB_TOPIC_PROCESS_PATIENT_PLAN_EVENT,
    PREVENTION_PLANS_COLLECTION_NAME,
} from './constants';

export interface VetspirePatientPlanItem
    extends Pick<
        BaseVetspirePatientPlanItem,
        'quantity' | 'quantityUsed' | 'name' | 'unitTotal'
    > {
    id: string;
    product: null | IdAndName;
    package: null | IdAndName;
}

export interface VetspirePatientPlan
    extends Pick<
        BaseVetspirePatientPlan,
        | 'insertedAt'
        | 'cancelled'
        | 'updatedAt'
        | 'amountCharged'
        | 'endDate'
        | 'deferred'
        | 'paymentDate'
        | 'planDiscountPercent'
        | 'shouldHaveCharged'
        | 'total'
        | 'usedRevenue'
        | 'value'
    > {
    id: string;
    items: null | readonly VetspirePatientPlanItem[];
    location: null | IdAndName;
    patient: {
        id: string;
        client: { id: string };
    };
    planInterval: VetspirePlanInterval;
    preventionPlan: IdAndName;
}

export interface PatientPlanItem
    extends Omit<
        VetspirePatientPlanItem,
        'product' | 'package' | 'unitTotal' | 'quantity' | 'quantityUsed'
    > {
    productId: string | null;
    packageId: string | null;
    /** USD cents */
    unitTotal: number;
    quantity: number;
    quantityUsed: number;
}

export interface PatientPlanAdditions {
    cancelledAt?: Date | null;
    cancellationReason?: string | null;
    cancellationReasonNote?: string | null;
    autoRenew?: boolean;
}

/**
 * collection: `patientPlans`
 */
export interface PatientPlan
    extends Omit<
            VetspirePatientPlan,
            | 'id'
            | 'insertedAt'
            | 'updatedAt'
            | 'amountCharged'
            | 'value'
            | 'items'
            | 'preventionPlan'
            | 'total'
            | 'usedRevenue'
            | 'planDiscountPercent'
            | 'shouldHaveCharged'
            | 'patient'
            | 'location'
        >,
        PatientPlanAdditions {
    /** ID from Vetspire */
    _id: string;
    /**
     * same as `_id`, but as a number
     * in Bigquery we want to have an INT64, which we can use for joins
     */
    planId: number;
    insertedAt: Date;
    updatedAt: null | Date;
    /** USD cents */
    amountCharged: number;
    /** USD cents */
    value: number;
    items: readonly PatientPlanItem[];
    /**
     * {@link PreventionPlan._id}
     */
    preventionPlanId: string;
    vetspirePatientId: string;
    vetspireClientId: string;

    /**
     * in Bigquery we want to have an INT64, which we can use for joins
     * {@link PreventionPlan.preventionPlanId}
     */
    preventionPlanIdInt: number;
    /**
     * same as `vetspirePatientId`, but as a number
     * in Bigquery we want to have an INT64, which we can use for joins
     */
    vetspirePatientIdInt: number;
    /**
     * same as `vetspireClientId`, but as a number
     * in Bigquery we want to have an INT64, which we can use for joins
     */
    vetspireClientIdInt: number;

    total: number;
    usedRevenue: number;
    planDiscountPercent: number;
    shouldHaveCharged: number;

    /**
     * {@link Location._vetspire.id}
     */
    vetspireLocationId: string | null;
    /**
     * same as `vetspireLocationId`, but as a number
     * in Bigquery we want to have an INT64, which we can use for joins
     */
    vetspireLocationIdInt: number | null;
}

/**
 * collection: `patientPlans.events`
 */
export interface PatientPlanEvent {
    /**
     * numeric ID of the event from Vetspire
     */
    _id: string;
    /**
     * date and time of the event
     */
    datetime: Date;
    /**
     * whether we have processed this event already
     */
    processed: boolean;
    /**
     * ID of the Vetspire location
     */
    vetspireLocationId: string | null;
    /**
     * ID of the Vetspire  provider
     */
    vetspireProviderId: string | null;
    /**
     * plan total in USD cents
     */
    total: number;
    /**
     * Vetspire ID of the patient
     */
    vetspirePatientId: string;
    /**
     * Vetspire ID of the invoice
     */
    vetspireOrderId: string;
    interval: VetspirePlanInterval;
    /**
     * Vetspire ID of the client
     */
    vetspireClientId: string;
    /**
     * ID of the {@link PatientPlan}
     */
    planId?: string;
}

export interface ProcessPatientPlanEventMessage {
    eventId: string;
    datetime: string;
    vetspirePatientId: string;
    vetspireClientId: string;
    vetspireOrderId: string;
    interval: VetspirePlanInterval;
}

export type VetspirePlanOption = Pick<
    BaseVetspirePlanOption,
    | 'excludeRenewal'
    | 'name'
    | 'notNeutered'
    | 'packageId'
    | 'productIds'
    | 'productId'
    | 'products'
    | 'quantity'
    | 'quantities'
    | 'required'
    | 'selected'
    | 'sex'
    | 'species'
>;

export interface VetspirePlanOptionWithPrices extends VetspirePlanOption {
    product: BaseVetspireProduct & {
        firstPrice: string;
        secondPrice: string;
    };
    package: ProductPackage & {
        fixedPrice: string;
    };
}

export interface IntervalPrices {
    totalValue: number;
    totalPrice: number;
    monthlyPrice: number;
    monthlyDiscountedPrice: number;
    quarterlyPrice: number;
    quarterlyDiscountedPrice: number;
    yearlyPrice: number;
    yearlyDiscountedPrice: number;
}

export const defaultIntervalPrices: IntervalPrices = {
    totalValue: 0,
    totalPrice: 0,
    monthlyPrice: 0,
    monthlyDiscountedPrice: 0,
    quarterlyPrice: 0,
    quarterlyDiscountedPrice: 0,
    yearlyPrice: 0,
    yearlyDiscountedPrice: 0,
};

export interface DecoratedVetspirePlanOptionWithPrices
    extends VetspirePlanOption,
        IntervalPrices {
    productOrPackageId: string; // product_123 or package_123
    product:
        | (BaseVetspireProduct &
              IntervalPrices & {
                  firstPrice: string;
                  secondPrice: string;
              })
        | null;
    package:
        | (ProductPackage &
              IntervalPrices & {
                  fixedPrice: string;
              })
        | null;
}

export interface VetspirePreventionPlan
    extends Pick<
        BaseVetspirePreventionPlan,
        | 'dynamicPrice'
        | 'enrollmentFee'
        | 'excludedProductIds'
        | 'includedProductIds'
        | 'insertedAt'
        | 'isActive'
        | 'name'
        | 'percentDiscount'
        | 'planDiscountPercent'
        | 'previouslyPaidProductId'
        | 'terms'
        | 'totalValue'
    > {
    id: string;
    options: readonly VetspirePlanOption[] | null;
}

export interface Benefit {
    // dynamic - null for non-optional benefits, product_123 or package_123 for optional benefits
    productOrPackageId?: string | null;
    isOptional: boolean;
    publicName: string;
    note: string;
    productId: string | null;
    packageId: string | null;
}

/** fields needed by Puppy/Kitten plan signup */
export interface VetspirePreventionPlanExtension {
    species?: string | null;
    sex?: VetspireSex | null;
    neutered?: boolean | null;
    publicName?: string;
    benefits: readonly Benefit[];
    showCheckIconProductOrPackageId: string[];
    quarterlyPaymentDiscountPercent: null | number;
    yearlyPaymentDiscountPercent: null | number;
    basicPreventionPlanId: string | null;
    autoRenewPreventionPlanId: string | null;
    minAgeDays: number | null;
    maxAgeDays: number | null;
}

export interface VetspirePreventionPlanWithPrices
    extends VetspirePreventionPlan {
    options: readonly VetspirePlanOptionWithPrices[] | null;
}

/**
 * collection: `patientPlans.preventionPlans`
 */
export interface PreventionPlan
    extends Omit<
            VetspirePreventionPlan,
            | 'id'
            | 'insertedAt'
            | 'enrollmentFee'
            | 'planDiscountPercent'
            | 'percentDiscount'
            | 'totalValue'
        >,
        VetspirePreventionPlanExtension {
    /** ID imported from Vetspire */
    _id: string;
    /**
     * same as `_id`, but as a number
     * in Bigquery we want to have an INT64, which we can use for joins
     */
    preventionPlanId: number;
    insertedAt: Date;
    enrollmentFee: null | number;
    percentDiscount: null | number;
    planDiscountPercent: null | number;
    /** USD cents */
    totalValue: null | number;
}

export interface PatientPlanEnrollProtocol {
    _id: string;
    date: Date;
    clientId: string;
    vetspireLocationId: string;
    patientId: string;
    planId: string;
    optionalBenefitIds: readonly string[];
    planInterval: VetspirePlanInterval;
    orderId: string;
    trackingOrigin?: string | null;
    trackingProviderId?: string | null;
    trackingCampaign?: string | null;
}

export const PATIENT_PLAN_SETTINGS_COLLECTION_NAME = 'patientPlans.settings';
export const PATIENT_PLAN_SETTINGS_GLOBAL_ID = 'global';
export const PATIENT_PLAN_SETTINGS_CANCELLATION_ID = 'cancellation';

export interface GlobalSettings {
    _id: typeof PATIENT_PLAN_SETTINGS_GLOBAL_ID;
    planPoliciesUrl: string;
    faqsUrl: string;
    overviewUrl: string;
    cancellationPeriodDays: number;
    refundRecentInvoicesDays: number;
    quarterlyPaymentDiscountPercent: number;
    yearlyPaymentDiscountPercent: number;
    enrollmentFee: number;
}

export const defaultGlobalSettings: GlobalSettings = {
    _id: PATIENT_PLAN_SETTINGS_GLOBAL_ID,
    planPoliciesUrl: '',
    faqsUrl: '',
    overviewUrl: '',
    cancellationPeriodDays: 30,
    refundRecentInvoicesDays: 2,
    quarterlyPaymentDiscountPercent: 2,
    yearlyPaymentDiscountPercent: 5,
    enrollmentFee: 0,
};

export type LimitedGlobalPatientPlanSettings = Pick<
    GlobalSettings,
    | 'refundRecentInvoicesDays'
    | 'quarterlyPaymentDiscountPercent'
    | 'yearlyPaymentDiscountPercent'
    | 'enrollmentFee'
>;

export type PatientPlanStatus =
    | 'active'
    | 'patientInactive'
    | 'patientDeceased'
    | 'cancelled'
    | 'expired';

export interface CancellationReason {
    value: string;
    label: string;
    requiresExplanation?: boolean;
    obsolete?: boolean;
}

export interface CancellationSettingsInput {
    cancellationReasons: readonly CancellationReason[];
}
export type CancellationSettings = CancellationSettingsInput & {
    _id: typeof PATIENT_PLAN_SETTINGS_CANCELLATION_ID;
};

export const defaultCancellationSettings: CancellationSettings = {
    _id: PATIENT_PLAN_SETTINGS_CANCELLATION_ID,
    cancellationReasons: [
        {
            value: 'health_change',
            label: "Changes in my pet's health",
        },
        {
            value: 'financial_reasons',
            label: 'Financial reasons',
        },
        {
            value: 'moving',
            label: 'Moving',
        },
        {
            value: 'pet_deceased',
            label: 'Pet is deceased',
        },
        {
            value: 're_enrolling',
            label: 'Re-enrolling in different plan',
        },
        {
            value: 'other',
            label: 'Other',
            requiresExplanation: true,
        },
    ],
};

export type PatientPlanSettingsType = GlobalSettings | CancellationSettings;

export interface GlobalSettingsQueryResult {
    globalSettings: GlobalSettings;
}

export interface CancellationSettingsQueryResult {
    cancellationSettings: CancellationSettings;
}

export interface PatientPlanAdditionsQueryVariables {
    id: string;
}

export interface PatientPlanAdditionsQueryResult {
    patientPlanAdditions: PatientPlanAdditions;
}

export interface UpdatePatientPlanAdditionsVariables {
    id: string;
    input: PatientPlanAdditions;
}

export interface UpdatePatientPlanAdditionsResult {
    updatePatientPlanAdditions: OperationResult;
}

export interface DecoratedPreventionPlan
    extends VetspirePreventionPlanExtension,
        IntervalPrices {
    id: string;
    options: readonly DecoratedVetspirePlanOptionWithPrices[] | null;
    dynamicPrice: boolean;
    totalValue: number;
    enrollmentFee: number;
    pricesWithOptions: IntervalPrices;
}

export interface PreventionPlansQueryArguments {
    vetspireLocationId: string | null;
}

export interface PreventionPlansQueryResult {
    preventionPlans: readonly DecoratedPreventionPlan[];
}

export interface PreventionPlanQueryArguments {
    preventionPlanId: string;
    vetspireLocationId: string | null;
}

export interface PreventionPlanQueryResult {
    preventionPlan: DecoratedPreventionPlan | null;
}

export interface ClientHasPatientsWithActivePlansQueryArguments {
    vetspireClientId: string;
}

export interface ClientHasPatientsWithActivePlansQueryResult {
    clientHasPatientsWithActivePlans: boolean;
}

export interface ClientHasRefundableAppointmentsArguments {
    email: string;
}

export interface ClientHasRefundableAppointmentsResult {
    clientHasRefundableAppointments: boolean;
}
