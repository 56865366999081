import * as React from 'react';
import { Medication } from 'api/encounters/queries';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

import styles from './MedicationItem.module.scss';

interface MedicationItemProps {
    className: string;
    medication: Medication;
    selected: boolean;
    setSelected: (medicationId: string, selected: boolean) => void;
}

const MedicationItem: React.FunctionComponent<MedicationItemProps> = ({
    className,
    medication,
    selected,
    setSelected,
}) => {
    return (
        <div key={medication.id} className={className}>
            {selected ? (
                <CheckBoxOutlinedIcon
                    classes={{ root: styles.selected }}
                    onClick={() => setSelected(medication.id, false)}
                />
            ) : (
                <CheckBoxOutlineBlankOutlinedIcon
                    classes={{ root: styles.selected }}
                    onClick={() => setSelected(medication.id, true)}
                />
            )}
            <div className={styles.name}>{medication.name}</div>
            <div>
                <span className={styles.sig}>{medication.sig} </span>
                <span className={styles.note}>{medication.note}</span>
            </div>
            {medication.frequency === 'in clinic' && (
                <div className={styles.frequency}>({medication.frequency})</div>
            )}
        </div>
    );
};

export default MedicationItem;
