import * as React from 'react';
import Select from 'react-select';
import { Translated } from '@bondvet/web-app-i18n/util';
import styles from '../Form.module.scss';

type Option = {
    id: string;
    label: string;
};

type DropdownProps = {
    id: string;
    onChange: (value: string) => void;
    options: Option[];
    label?: Translated;
    value?: string;
    required?: boolean;
    placeholder?: string | React.ReactElement;
    disabled?: boolean;
    controlShouldRenderValue?: boolean;
};

export default function Dropdown({
    id,
    label,
    onChange,
    options,
    value,
    required,
    placeholder,
    disabled = false,
    controlShouldRenderValue = false,
}: DropdownProps): React.ReactElement {
    const selected = React.useMemo(() => {
        return options.find((option) => option.id === value);
    }, [options, value]);

    return (
        <fieldset className={styles.container}>
            {label && (
                <label htmlFor={id} className={styles.label}>
                    {label}
                    {required && ' *'}
                </label>
            )}

            <Select
                isDisabled={disabled}
                name={id}
                value={selected}
                options={options}
                onChange={(option) => {
                    // option.id exists
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange(option?.id);
                }}
                placeholder={placeholder}
                className={styles.dropdown}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.label}
                controlShouldRenderValue={controlShouldRenderValue}
            />
        </fieldset>
    );
}
