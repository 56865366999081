import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import WeekSelect from 'components/WeekSelect';
import { LocationsSelect } from 'components/LookupSelect';
import CheckBox from 'components/CheckBox/CheckBox';
import useViewerSettings, {
    ViewerSettingsKey,
    ViewerSettingsNS,
} from 'hooks/useViewerSettings';
import { useQuery } from '@apollo/client';
import {
    Location,
    locationsQuery,
    LocationsQueryResult,
} from 'api/locations/queries';
import { Provider } from 'api/providers/queries';
import Select from 'react-select';
import { ReactComponent as LocationIcon } from 'assets/location.svg';
import { SurgeryCalendarOptions } from '../../types';

import styles from './Options.module.scss';

type SurgeryCalendarValues = Pick<
    SurgeryCalendarOptions,
    'dentals' | 'surgeries' | 'openSlots' | 'analytics'
>;

interface OptionsProps {
    options: SurgeryCalendarOptions;
    onChange: (newOptions: SurgeryCalendarOptions) => void;
    possibleDoctors: ReadonlyArray<Provider>;
}

const Options: React.FunctionComponent<OptionsProps> = ({
    options,
    onChange,
    possibleDoctors,
}) => {
    const translate = useTranslate();

    const viewerSettings = useViewerSettings();

    const [optionsRestored, setOptionsRestored] = React.useState(false);

    const onChangeWeek = (week: Moment) => {
        if (!options.loading) {
            viewerSettings.setItem<string>(
                ViewerSettingsNS.surgeryCalendar,
                ViewerSettingsKey.selectedWeek,
                week.toISOString(),
            );

            onChange({
                ...options,
                week,
            });
        }
    };

    const onChangeLocations = (locations: Location[]) => {
        if (!options.loading) {
            const vetspireLocationIds = locations.map(
                (location) => location.id,
            );

            viewerSettings.setItem<string[]>(
                ViewerSettingsNS.surgeryCalendar,
                ViewerSettingsKey.selectedLocationIds,
                vetspireLocationIds,
            );

            onChange({
                ...options,
                vetspireLocationIds,
            });
        }
    };

    const locationsQueryResult = useQuery<LocationsQueryResult>(
        locationsQuery,
        {
            fetchPolicy: 'cache-first',
        },
    );

    const onChangeValue = (
        key: keyof SurgeryCalendarValues,
        value: unknown,
    ) => {
        if (!options.loading) {
            const newOptions = {
                ...options,
                [key]: value,
            };

            viewerSettings.setItem<SurgeryCalendarValues>(
                ViewerSettingsNS.surgeryCalendar,
                ViewerSettingsKey.selectedOptions,
                {
                    surgeries: newOptions.surgeries,
                    dentals: newOptions.dentals,
                    openSlots: newOptions.openSlots,
                    analytics: newOptions.analytics,
                },
            );

            onChange(newOptions);
        }
    };

    // restore options from localStorage as soon as all required queries are executed
    React.useEffect(() => {
        if (
            !viewerSettings.loading &&
            !viewerSettings.error &&
            !locationsQueryResult.loading &&
            !locationsQueryResult.error &&
            locationsQueryResult.data?.locations
        ) {
            if (!optionsRestored) {
                // restore options only once
                setOptionsRestored(true);

                const restoredOptions: SurgeryCalendarOptions = {
                    ...viewerSettings.getItem<SurgeryCalendarValues>(
                        ViewerSettingsNS.surgeryCalendar,
                        ViewerSettingsKey.selectedOptions,
                        options,
                    ),
                    vetspireLocationIds: [],
                    week: options.week,
                    loading: false,
                    doctors: [],
                };

                const storedWeek = moment(
                    viewerSettings.getItem<string>(
                        ViewerSettingsNS.surgeryCalendar,
                        ViewerSettingsKey.selectedWeek,
                        options.week?.toISOString() || '',
                    ),
                );

                if (storedWeek.isoWeekday() === options.week.isoWeekday()) {
                    // only restore week if it points to the correct day of week (as time of writing - sunday)
                    restoredOptions.week = storedWeek;
                }

                restoredOptions.vetspireLocationIds = viewerSettings.getItem<
                    string[]
                >(
                    ViewerSettingsNS.surgeryCalendar,
                    ViewerSettingsKey.selectedLocationIds,
                    options.vetspireLocationIds,
                );

                onChange(restoredOptions);
            }
        }
    }, [
        locationsQueryResult,
        onChange,
        options,
        optionsRestored,
        viewerSettings,
    ]);

    return (
        <div className={styles.container}>
            <WeekSelect week={options.week} onChange={onChangeWeek} />

            <LocationsSelect
                locationsQueryResult={locationsQueryResult}
                selectedLocations={
                    locationsQueryResult.data?.locations.filter((location) =>
                        options.vetspireLocationIds.includes(location.id),
                    ) || []
                }
                onChange={onChangeLocations}
                isMulti
            />

            <Select
                isMulti
                name="doctors"
                value={options.doctors}
                className={styles.lookup}
                classNamePrefix="lookup-select"
                options={possibleDoctors}
                onChange={(providers) =>
                    onChange({
                        ...options,
                        doctors: providers as Provider[],
                    })
                }
                placeholder={
                    <div className={styles.placeholder}>
                        <LocationIcon className={styles.placeholderIcon} />
                        {translate(
                            `vetspireExtension.surgeryCalendar.doctorFilterPlaceholder`,
                        )}
                    </div>
                }
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
            />

            <div className={styles.checkboxColumn}>
                <CheckBox
                    label={translate(
                        'vetspireExtension.surgeryCalendar.surgeries',
                    )}
                    value={options.surgeries}
                    onChange={(newValue) =>
                        onChangeValue('surgeries', newValue)
                    }
                />
                <CheckBox
                    label={translate(
                        'vetspireExtension.surgeryCalendar.dentals',
                    )}
                    value={options.dentals}
                    onChange={(newValue) => onChangeValue('dentals', newValue)}
                />
            </div>

            <div className={styles.checkboxColumn}>
                <CheckBox
                    label={translate(
                        'vetspireExtension.surgeryCalendar.openSlots',
                    )}
                    value={options.openSlots}
                    onChange={(newValue) =>
                        onChangeValue('openSlots', newValue)
                    }
                />
                <CheckBox
                    label={translate(
                        'vetspireExtension.surgeryCalendar.analytics',
                    )}
                    value={options.analytics}
                    onChange={(newValue) =>
                        onChangeValue('analytics', newValue)
                    }
                />
            </div>
        </div>
    );
};

export default Options;
