import * as React from 'react';
import { QueryResult } from '@apollo/client';
import Select, { SingleValue, MultiValue } from 'react-select';
import useTranslate from 'hooks/useTranslate';
import { ReactComponent as LocationIcon } from 'assets/location.svg';
import { Location, LocationsQueryResult } from 'api/locations/queries';

import styles from './LookupSelect.module.scss';
import { LookupStats } from './types';

interface LocationsSelectProps {
    locationsQueryResult: QueryResult<LocationsQueryResult>;
    selectedLocations: Location[];
    onChange: (locations: Location[]) => void;
    isDisabled?: boolean;
    isMulti?: boolean;
    stats?: LookupStats | null;
}

const LocationsSelect: React.FunctionComponent<LocationsSelectProps> = ({
    locationsQueryResult,
    selectedLocations,
    onChange,
    isDisabled = false,
    isMulti = false,
    stats = null,
}) => {
    const translate = useTranslate();

    const getLocationName = React.useCallback(
        (location: Location) => {
            if (stats) {
                const counter = stats[location.id]
                    ? ` (${stats[location.id]})`
                    : '';
                return `${location.name || ''}${counter}`;
            }
            return location.name;
        },
        [stats],
    );

    const options = React.useMemo(() => {
        return (
            locationsQueryResult.data?.locations
                .slice()
                .filter((location) => location.isActive)
                .sort((a, b) => (a.name || '').localeCompare(b.name || '')) ||
            []
        );
    }, [locationsQueryResult.data]);

    const handleChange = React.useCallback(
        (newValues: SingleValue<Location> | MultiValue<Location>): void => {
            if (isMulti) {
                onChange((newValues || []) as Location[]);
            } else {
                onChange([newValues as Location]);
            }
        },
        [isMulti, onChange],
    );

    return (
        <Select
            isMulti={isMulti}
            isLoading={locationsQueryResult.loading}
            name="providers"
            options={options}
            value={selectedLocations}
            className={styles.lookup}
            classNamePrefix="lookup-select"
            onChange={handleChange}
            getOptionValue={(location: Location) => location.id}
            getOptionLabel={getLocationName}
            isDisabled={isDisabled}
            placeholder={
                <div className={styles.placeholder}>
                    <LocationIcon className={styles.placeholderIcon} />
                    {translate(
                        `vetspireExtension.lookups.${
                            isMulti ? 'locations' : 'location'
                        }.placeholder`,
                    )}
                </div>
            }
        />
    );
};

export default LocationsSelect;
