import * as React from 'react';
import { gql } from '@apollo/client';
import { CREDIT_MEMO_NOTES_MAP } from '@bondvet/types/creditLedger/constants';
import { CREDIT_MEMO_OPTION_CASH } from '@bondvet/types/creditLedger';
import useVetspireMutation from 'hooks/useVetspireMutation';

const chargeClientMutation = gql`
    mutation chargeClient(
        $clientId: ID!
        $amount: Decimal!
        $locationId: ID!
        $notes: String
    ) {
        chargeClient(
            clientId: $clientId
            amount: $amount
            locationId: $locationId
            notes: $notes
            method: CASH
            methodType: "OTHER"
            tokenize: false
        ) {
            id
        }
    }
`;

interface ChargeClientMutation {
    chargeClient: { id: string };
}

interface ChargeClientMutationVariables {
    clientId: string;
    amount: string;
    locationId: string;
    notes: string;
}

type AddCashCredit = (amount: number, locationId: string) => Promise<void>;

export default function useCashCredit(clientId: string): AddCashCredit {
    const [chargeClient] = useVetspireMutation<
        ChargeClientMutation,
        ChargeClientMutationVariables
    >(chargeClientMutation);

    return React.useCallback(
        async (amount: number, locationId: string) => {
            const { data, errors } = await chargeClient({
                variables: {
                    clientId,
                    amount: amount.toFixed(2),
                    locationId,
                    notes: CREDIT_MEMO_NOTES_MAP[CREDIT_MEMO_OPTION_CASH],
                },
            });

            if (errors) {
                console.error('error charging client:', errors);
                throw new Error('error charging client');
            }

            if (!data?.chargeClient?.id) {
                console.error('no payment id returned', data);
                throw new Error("charging didn't return the expected data");
            }
        },
        [clientId, chargeClient],
    );
}
