import useBondQuery from 'hooks/useBondQuery';
import { gql } from '@apollo/client';

const query = gql`
    query extensionPromoCodeValidationEnabled {
        extensionPromoCodeValidationEnabled
    }
`;

type ExtensionPromoCodeValidationEnabledResult = {
    extensionPromoCodeValidationEnabled: boolean;
};

type UsePromoCodeSetting = {
    promoCodeSettingEnabled: boolean;
    isLoading: boolean;
};

export default function usePromoCodeSetting(): UsePromoCodeSetting {
    const { data, loading } =
        useBondQuery<ExtensionPromoCodeValidationEnabledResult>(query, {
            fetchPolicy: 'cache-and-network',
        });

    return {
        promoCodeSettingEnabled:
            data?.extensionPromoCodeValidationEnabled ?? false,
        isLoading: loading,
    };
}
