import * as React from 'react';
import classnames from 'classnames';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { Moment } from 'moment';
import { BlockOffLog } from 'api/bond/queries';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './BlockOffLogItem.module.scss';
import { AppointmentLogFilterType } from '../AppointmentLogFilter/types';
import {
    BlockOffLogChangeAppointmentTypeName,
    BlockOffLogChangeDuration,
    BlockOffLogChangeProviderName,
    BlockOffLogChangeStart,
    BlockOffLogCreate,
    BlockOffLogDelete,
} from '../BlockOffLogEvents';
import { BlockOffLogType } from '../../types';

interface BlockOffLogItemProps {
    blockOffLog: BlockOffLog;
    selectedDay: Moment;
    currentFilter: AppointmentLogFilterType;
    sameDayChangesOnly: boolean;
}

const BlockOffLogItem: React.FunctionComponent<BlockOffLogItemProps> = ({
    blockOffLog,
    selectedDay,
    currentFilter,
    sameDayChangesOnly,
}) => {
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        setExpanded(false);
    }, [currentFilter, sameDayChangesOnly]);
    const deleted =
        blockOffLog.decoratedEvents[blockOffLog.decoratedEvents.length - 1]
            .type === BlockOffLogType.deleteBlockOffEvent;

    const events = React.useMemo(() => {
        const sameDay = (date: Moment) => {
            const day1 = selectedDay.format('YYYY-MM-DD');
            const day2 = date.format('YYYY-MM-DD');
            return day1 === day2;
        };
        return blockOffLog.decoratedEvents
            .slice()
            .filter(
                (decoratedEvent, index) =>
                    (!expanded &&
                        !sameDayChangesOnly &&
                        (index === 0 ||
                            index ===
                                blockOffLog.decoratedEvents.length - 1)) ||
                    (!expanded &&
                        sameDayChangesOnly &&
                        sameDay(decoratedEvent.eventDatetime)) ||
                    expanded,
            )
            .map((decoratedEvent) => {
                if (decoratedEvent.type === BlockOffLogType.create) {
                    const elements = [
                        <BlockOffLogCreate
                            key={decoratedEvent.id}
                            event={decoratedEvent}
                            sameDay={sameDay(decoratedEvent.apptDatetime)}
                        />,
                    ];
                    if (
                        !expanded &&
                        !sameDayChangesOnly &&
                        blockOffLog.decoratedEvents.length > 2
                    ) {
                        elements.push(<MoreVertIcon key="MoreVertIcon" />);
                    }
                    return elements;
                }
                if (decoratedEvent.type === BlockOffLogType.changeStart) {
                    return (
                        <BlockOffLogChangeStart
                            key={decoratedEvent.id}
                            event={decoratedEvent}
                            sameDay={sameDay(decoratedEvent.apptDatetime)}
                        />
                    );
                }
                if (decoratedEvent.type === BlockOffLogType.changeDuration) {
                    return (
                        <BlockOffLogChangeDuration
                            key={decoratedEvent.id}
                            event={decoratedEvent}
                            sameDay={sameDay(decoratedEvent.apptDatetime)}
                        />
                    );
                }
                if (
                    decoratedEvent.type === BlockOffLogType.changeProviderName
                ) {
                    return (
                        <BlockOffLogChangeProviderName
                            key={decoratedEvent.id}
                            event={decoratedEvent}
                        />
                    );
                }
                if (
                    decoratedEvent.type ===
                    BlockOffLogType.changeAppointmentTypeName
                ) {
                    return (
                        <BlockOffLogChangeAppointmentTypeName
                            key={decoratedEvent.id}
                            event={decoratedEvent}
                        />
                    );
                }
                if (
                    decoratedEvent.type === BlockOffLogType.deleteBlockOffEvent
                ) {
                    return (
                        <BlockOffLogDelete
                            key={decoratedEvent.id}
                            event={decoratedEvent}
                            sameDay={sameDay(decoratedEvent.apptDatetime)}
                        />
                    );
                }

                return null;
            })
            .filter((decoratedEvent) => decoratedEvent != null);
    }, [
        blockOffLog.decoratedEvents,
        expanded,
        sameDayChangesOnly,
        selectedDay,
    ]);

    if (events.length === 0) {
        return null;
    }

    return (
        <div
            className={styles.container}
            tabIndex={0}
            role="button"
            onKeyDown={() => {}}
            onClick={() => {}}
        >
            <div className={styles.content}>
                <div className={styles.header}>
                    <div
                        className={classnames(
                            styles.headerLeft,
                            styles.smallText,
                        )}
                    >
                        {deleted ? (
                            <span className={styles.deleted}>
                                Deleted {blockOffLog.appointmentTypeName}
                            </span>
                        ) : (
                            blockOffLog.appointmentTypeName
                        )}
                    </div>
                    <div
                        className={classnames(
                            styles.headerRight,
                            styles.smallText,
                        )}
                    >
                        {blockOffLog.location?.name || ''}
                    </div>
                </div>

                {events}

                {blockOffLog.decoratedEvents.length > 2 && (
                    <div
                        className={styles.showMoreContainer}
                        onClick={(event) => {
                            event.stopPropagation();
                            setExpanded(!expanded);
                        }}
                        role="button"
                        tabIndex={-1}
                        onKeyDown={() => {}}
                    >
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                        <span>{expanded ? 'Show less' : 'Show more'}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlockOffLogItem;
