import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import CircularProgress from '@mui/material/CircularProgress';
import useSurgeryForms from 'pages/SurgeryForms/hooks/useSurgeryForms';
import GeneralError from 'components/GeneralError/GeneralError';
import SearchInput from 'components/SearchInput';
import ActionButton from 'components/ActionButton';

import styles from './SelectForm.module.scss';
import surgeryFormsStyles from '../../SurgeryForms.module.scss';

interface SelectFormProps {
    selectedSurgeryFormId: string | null;
    onChange: (surgeryFormId: string) => void;
    onNext: () => void;
}

const SelectForm: React.FunctionComponent<SelectFormProps> = ({
    selectedSurgeryFormId,
    onChange,
    onNext,
}) => {
    const translate = useTranslate();
    const surgeryFormsQuery = useSurgeryForms();
    const [filterText, setFilterText] = React.useState('');

    const filteredSurgeryForms = React.useMemo(() => {
        if (surgeryFormsQuery.loading || surgeryFormsQuery.error) {
            return [];
        }
        return surgeryFormsQuery.surgeryForms.filter((surgeryForm) =>
            surgeryForm.name
                .toLocaleLowerCase()
                .includes(filterText.toLocaleLowerCase()),
        );
    }, [surgeryFormsQuery, filterText]);

    if (surgeryFormsQuery.loading) {
        return (
            <div className={styles.loading}>
                <CircularProgress />
            </div>
        );
    }

    if (surgeryFormsQuery.error) {
        return <GeneralError message={surgeryFormsQuery.error.message} />;
    }

    return (
        <div
            className={classnames(
                styles.flyoutBlock,
                styles.noHorizontal,
                styles.noVertical,
                styles.container,
            )}
        >
            <SearchInput
                value={filterText}
                className={styles.filterText}
                onChange={(value) => setFilterText(value)}
            />
            <div className={styles.list}>
                {filteredSurgeryForms.map((surgeryForm) => (
                    <div
                        key={surgeryForm._id}
                        className={classnames(styles.listItem, {
                            [styles.active]:
                                selectedSurgeryFormId === surgeryForm._id,
                        })}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => onChange(surgeryForm._id || '')}
                    >
                        {surgeryForm.name}
                    </div>
                ))}
            </div>
            <div
                className={classnames(
                    styles.flyoutBlock,
                    styles.noMargin,
                    styles.padding,
                    surgeryFormsStyles.actions,
                )}
            >
                <ActionButton
                    onClick={() => {}}
                    active={false}
                    className={surgeryFormsStyles.back}
                >
                    {translate('vetspireExtension.surgeryForms.actions.back')}
                </ActionButton>
                <div />
                <ActionButton
                    onClick={() => {
                        if (selectedSurgeryFormId !== null) {
                            onNext();
                        }
                    }}
                    active={selectedSurgeryFormId !== null}
                    className={surgeryFormsStyles.next}
                >
                    {translate('vetspireExtension.surgeryForms.actions.next')}
                </ActionButton>
            </div>
        </div>
    );
};

export default SelectForm;
