import * as React from 'react';
import classnames from 'classnames';
import Select, { SingleValue } from 'react-select';
import useTranslate from 'hooks/useTranslate';
import { WaiveReason } from '@bondvet/types/booking';
import styles from 'components/LookupSelect/LookupSelect.module.scss';

interface SelectWaiveReasonProps {
    waiveReasons: readonly WaiveReason[];
    selectedWaiveReason: WaiveReason | null;
    setSelectedWaiveReason: (waiveReason: WaiveReason | null) => void;
    error?: boolean;
}

export default function SelectWaiveReason({
    waiveReasons,
    setSelectedWaiveReason,
    selectedWaiveReason,
    error,
}: SelectWaiveReasonProps) {
    const translate = useTranslate();

    return (
        <Select
            isMulti={false}
            name="waiveReason"
            value={selectedWaiveReason}
            required
            className={classnames(styles.lookup, { [styles.error]: error })}
            classNamePrefix="lookup-select"
            options={waiveReasons}
            onChange={(value: SingleValue<WaiveReason>) => {
                setSelectedWaiveReason(value as WaiveReason | null);
            }}
            menuPosition="fixed"
            placeholder={
                <div className={styles.placeholder}>
                    {translate(
                        'vetspireExtension.clientDetails.payments.chargeFee.waiveReason.placeholder',
                    )}
                </div>
            }
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.label}
        />
    );
}
