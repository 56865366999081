import * as React from 'react';

import {
    Client,
    ClientsQueryResult,
    clientsWithEmail,
} from 'api/clients/queries';
import { useLazyQuery } from '@apollo/client';
import useClientDetails from 'hooks/useClientDetails';

export default function useDuplicates(clientId: string): ReadonlyArray<Client> {
    const client = useClientDetails(clientId);
    const [duplicates, setDuplicates] = React.useState<ReadonlyArray<Client>>(
        [],
    );

    const [loadDuplicates, { data }] =
        useLazyQuery<ClientsQueryResult>(clientsWithEmail);

    React.useEffect(() => {
        if (client?.email) {
            loadDuplicates({ variables: { email: client.email } });
        } else {
            setDuplicates([]);
        }
    }, [client?.email, loadDuplicates]);

    React.useEffect(() => {
        if (data?.clients) {
            setDuplicates(data?.clients.filter(({ id }) => id !== clientId));
        }
    }, [data?.clients, clientId]);

    return duplicates;
}
