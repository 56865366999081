import useProviders from 'hooks/useProviders';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import {
    LifetimeData,
    RescheduledAtItem,
    lifetimeStatsQuery,
    LifetimeStatsQueryResult,
    LifetimeDataWaivedFee,
} from 'api/clients/queries';
import { locationsQuery, LocationsQueryResult } from 'api/bond/queries';
import type { Provider } from 'api/providers/queries';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { getFallbackTimezone } from 'lib/utils';
import { parse, parseISO, differenceInMinutes, isBefore } from 'date-fns';
import { LateCancel, LateReschedule, LifetimeStats, NoShow } from '../types';

const getProviderName = (
    providers: readonly Provider[] | undefined,
    providerId: string | undefined,
    cancelOrigin?: string,
) => {
    const name = providers?.find(
        (providerItem) => providerItem.id === providerId,
    )?.name;
    if (
        (!name && cancelOrigin !== 'vetspire') ||
        (name === 'Team' && cancelOrigin !== 'vetspire')
    ) {
        return 'Client';
    }
    return name ?? 'Team';
};

const WAIVE_FEES_START_DATE = '2024-02-26';

const checkWaivedFee = (
    timestamp: string | null,
    waivedFee?: LifetimeDataWaivedFee,
) => {
    if (!timestamp) return null;
    // we start tracking waived fees on jan 26th 2024 so all data before
    // this date should show N/A for if a fee was charged
    const waivedFeeStart = parse(
        WAIVE_FEES_START_DATE,
        'yyyy-MM-dd',
        new Date(),
    );

    return isBefore(waivedFeeStart, parseISO(timestamp)) ? !waivedFee : null;
};

const mapNoShow = (lifetimeStatItem: LifetimeData): NoShow => {
    return {
        id: lifetimeStatItem._id,
        location: lifetimeStatItem.location?.name || '?',
        appointmentDate: lifetimeStatItem.date,
        timezone: lifetimeStatItem.location?.timezone ?? getFallbackTimezone(),
        noShowCharged: lifetimeStatItem.noShowCharged,
        noShowInvoiceId: lifetimeStatItem.noShowInvoiceId,
        noShowChargeAttempts: lifetimeStatItem.noShowChargeAttempts,
        noShowChargeLastError: lifetimeStatItem.noShowChargeLastError,
    };
};

const mapLateCancel = (
    lifetimeStatItem: LifetimeData,
    providers: readonly Provider[],
    type: 'cancel' | 'veryLateCancel',
): LateCancel => {
    const waivedFee = lifetimeStatItem.waivedFees.find(
        ({ createdAt, feeProductType }) => {
            const timestamp =
                lifetimeStatItem.removedAt || lifetimeStatItem.cancelledAt;
            if (type === feeProductType && timestamp) {
                const timeDiff = differenceInMinutes(
                    parseISO(timestamp),
                    parseISO(createdAt),
                );
                return Math.abs(timeDiff) < 2;
            }
            return false;
        },
    );

    return {
        id: lifetimeStatItem._id,
        eventDate: lifetimeStatItem.removedAt || lifetimeStatItem.cancelledAt,
        name: getProviderName(
            providers,
            lifetimeStatItem.removedBy ?? lifetimeStatItem.cancelledBy ?? '',
            lifetimeStatItem.cancelOrigin,
        ),
        location: lifetimeStatItem.location?.name || '?',
        appointmentDate: lifetimeStatItem.date,
        feeCharged: checkWaivedFee(
            lifetimeStatItem.removedAt || lifetimeStatItem.cancelledAt,
            waivedFee,
        ),
        waivingReason: waivedFee?.waiveReason,
        timezone: lifetimeStatItem.location?.timezone ?? getFallbackTimezone(),
    };
};

const mapLateReschedule = (
    rescheduledAtItem: RescheduledAtItem,
    lifetimeStatItem: LifetimeData,
    providers: readonly Provider[],
    type: 'reschedule' | 'veryLateReschedule',
): LateReschedule => {
    const waivedFee = lifetimeStatItem.waivedFees.find(
        ({ createdAt, feeProductType }) => {
            if (type === feeProductType && rescheduledAtItem.timestamp) {
                const timeDiff = differenceInMinutes(
                    parseISO(rescheduledAtItem.timestamp),
                    parseISO(createdAt),
                );
                return Math.abs(timeDiff) < 2;
            }
            return false;
        },
    );

    return {
        id: lifetimeStatItem._id,
        eventDate: rescheduledAtItem.timestamp,
        name: getProviderName(providers, rescheduledAtItem.providerId),
        appointmentDate: rescheduledAtItem.oldTime,
        location: lifetimeStatItem.location?.name || '?',
        newAppointment: {
            appointmentDate: rescheduledAtItem.newTime,
            location: lifetimeStatItem.location?.name || '?',
        },
        feeCharged: checkWaivedFee(rescheduledAtItem.timestamp, waivedFee),
        waivingReason: waivedFee?.waiveReason,
        timezone: lifetimeStatItem.location?.timezone ?? getFallbackTimezone(),
    };
};

export default function useLifetimeStats(clientId: string): LifetimeStats {
    const lifetimeStatsQueryResult = useQuery<LifetimeStatsQueryResult>(
        lifetimeStatsQuery,
        {
            fetchPolicy: 'network-only',
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
            variables: { clientId },
        },
    );

    const locationsQueryResult = useQuery<LocationsQueryResult>(
        locationsQuery,
        {
            fetchPolicy: 'cache-and-network',
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );

    const { providers } = useProviders();

    const {
        noShowData,
        lateCancelsData,
        lateReschedulesData,
        veryLateReschedulesData,
        veryLateCancelsData,
    } = React.useMemo(() => {
        const locations = locationsQueryResult?.data?.locations;
        let lifetimeStats = lifetimeStatsQueryResult?.data?.lifetimeStats;
        if (lifetimeStats && locations && providers) {
            lifetimeStats = lifetimeStats.map((lifetimeStatsItem) => {
                const location = locations.find(
                    (locationItem) =>
                        locationItem._id === lifetimeStatsItem.locationId,
                );
                return {
                    ...lifetimeStatsItem,
                    location,
                };
            });

            return {
                noShowData: lifetimeStats
                    .filter((lifetimeStatItem) => lifetimeStatItem.isNoShow)
                    .map(mapNoShow)
                    .sort((a, b) => {
                        if (!a.appointmentDate) {
                            return 1;
                        }
                        if (!b.appointmentDate) {
                            return -1;
                        }
                        return (
                            new Date(b.appointmentDate).getTime() -
                            new Date(a.appointmentDate).getTime()
                        );
                    }),
                lateCancelsData: lifetimeStats
                    .filter(
                        (lifetimeStatItem) =>
                            lifetimeStatItem.isLateCancellation,
                    )
                    .map((lifetimeStatsItem) =>
                        mapLateCancel(lifetimeStatsItem, providers, 'cancel'),
                    )
                    .sort((a, b) => {
                        if (!a.eventDate) {
                            return 1;
                        }
                        if (!b.eventDate) {
                            return -1;
                        }
                        return (
                            new Date(b.eventDate).getTime() -
                            new Date(a.eventDate).getTime()
                        );
                    }),
                lateReschedulesData: lifetimeStats
                    .reduce((result: LateReschedule[], lifetimeStatItem) => {
                        if (!lifetimeStatItem.rescheduledAt) return result;

                        return result.concat(
                            lifetimeStatItem.rescheduledAt
                                .filter(
                                    (rescheduledAtItem) =>
                                        rescheduledAtItem.isLateReschedule,
                                )
                                .map((rescheduledAtItem) =>
                                    mapLateReschedule(
                                        rescheduledAtItem,
                                        lifetimeStatItem,
                                        providers,
                                        'reschedule',
                                    ),
                                ),
                        );
                    }, [])
                    .sort((a, b) => {
                        if (!a.eventDate) {
                            return 1;
                        }
                        if (!b.eventDate) {
                            return -1;
                        }
                        return (
                            new Date(b.eventDate).getTime() -
                            new Date(a.eventDate).getTime()
                        );
                    }),
                veryLateCancelsData: lifetimeStats
                    .filter(
                        (lifetimeStatItem) =>
                            lifetimeStatItem.isVeryLateCancellation,
                    )
                    .map((lifetimeStatsItem) =>
                        mapLateCancel(
                            lifetimeStatsItem,
                            providers,
                            'veryLateCancel',
                        ),
                    )
                    .sort((a, b) => {
                        if (!a.eventDate) {
                            return 1;
                        }
                        if (!b.eventDate) {
                            return -1;
                        }
                        return (
                            new Date(b.eventDate).getTime() -
                            new Date(a.eventDate).getTime()
                        );
                    }),
                veryLateReschedulesData: lifetimeStats
                    .reduce((result: LateReschedule[], lifetimeStatItem) => {
                        if (!lifetimeStatItem.rescheduledAt) return result;

                        return result.concat(
                            lifetimeStatItem.rescheduledAt
                                .filter(
                                    (rescheduledAtItem) =>
                                        rescheduledAtItem.isVeryLateReschedule,
                                )
                                .map((rescheduledAtItem) =>
                                    mapLateReschedule(
                                        rescheduledAtItem,
                                        lifetimeStatItem,
                                        providers,
                                        'veryLateReschedule',
                                    ),
                                ),
                        );
                    }, [])
                    .sort((a, b) => {
                        if (!a.eventDate) {
                            return 1;
                        }
                        if (!b.eventDate) {
                            return -1;
                        }
                        return (
                            new Date(b.eventDate).getTime() -
                            new Date(a.eventDate).getTime()
                        );
                    }),
            };
        }
        return {
            noShowData: [],
            lateCancelsData: [],
            lateReschedulesData: [],
            veryLateCancelsData: [],
            veryLateReschedulesData: [],
        };
    }, [lifetimeStatsQueryResult, locationsQueryResult, providers]);

    return {
        noShows: {
            count: noShowData.length,
            data: noShowData,
        },
        lateCancels: {
            count: lateCancelsData.length,
            data: lateCancelsData,
        },
        lateReschedules: {
            count: lateReschedulesData.length,
            data: lateReschedulesData,
        },
        veryLateCancels: {
            count: veryLateCancelsData.length,
            data: veryLateCancelsData,
        },
        veryLateReschedules: {
            count: veryLateReschedulesData.length,
            data: veryLateReschedulesData,
        },
    };
}
