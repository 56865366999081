import { SukiProvider } from '@suki-sdk/react';
import AmbientSession from 'pages/Suki/components/AmbientSession';
import { SUKI_TABS, SukiTabs } from 'pages/Suki/types';
import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import Tabs from 'components/Tabs';
import SessionOverview from './components/SessionOverview';

import styles from './Suki.module.scss';

export default function Suki() {
    const translate = useTranslate();

    const [currentTab, setCurrentTab] =
        React.useState<SukiTabs>('ambientSession');

    const tabs = React.useMemo(
        () =>
            SUKI_TABS.map((name) => ({
                name,
                label: translate(`vetspireExtension.suki.tabs.${name}`),
            })),
        [translate],
    );

    const changeCurrentTab = React.useCallback((tab: SukiTabs) => {
        setCurrentTab(tab);
    }, []);

    return (
        <SukiProvider>
            <div className={classnames(styles.container)}>
                <div className={classnames(styles.flyoutBlock, styles.first)}>
                    <div className={styles.flyoutTitle}>
                        {translate('vetspireExtension.suki.title')}
                    </div>
                </div>
                <Tabs
                    currentTab={currentTab}
                    onChange={changeCurrentTab as (tab: string) => void}
                    tabs={tabs}
                />
                {/* AmbientSession must always be rendered because there might be an ongoing session which must not be interrupted. */}
                <AmbientSession hidden={currentTab !== 'ambientSession'} />
                {currentTab === 'sessionOverview' && (
                    <SessionOverview changeCurrentTab={changeCurrentTab} />
                )}
            </div>
        </SukiProvider>
    );
}
