import * as React from 'react';
import NavigationButton from 'components/NavigationButton/NavigationButton';
import { Page } from 'lib/vetspireActions';
import { MenuItem } from '../../hooks/useMenuList';
import TextingUnreadBadge from '../TextingUnreadBadge';

type NavigationItemsNotInEditModeArgs = {
    expanded: boolean;
    currentPage?: string | null;
    onNavigationClick: (page: Page) => void;
    menuButtons: MenuItem[];
    unreadMessageCount: number;
};

export const NavigationItemsNotInEditMode = ({
    menuButtons,
    expanded,
    currentPage,
    onNavigationClick,
    unreadMessageCount,
}: NavigationItemsNotInEditModeArgs): React.ReactElement => {
    return (
        <div>
            {menuButtons.map(({ icon, titleTag, page }: MenuItem) => (
                <NavigationButton
                    key={page}
                    expanded={expanded}
                    titleTag={titleTag}
                    icon={icon}
                    active={currentPage === page}
                    onClick={() => onNavigationClick(page)}
                    badge={
                        page === Page.texting && unreadMessageCount > 0 ? (
                            <TextingUnreadBadge count={unreadMessageCount} />
                        ) : undefined
                    }
                />
            ))}
        </div>
    );
};

export default NavigationItemsNotInEditMode;
