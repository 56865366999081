import * as React from 'react';
import NavigationButton from 'components/NavigationButton/NavigationButton';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProps,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd';
import { MenuItem } from '../../hooks/useMenuList';

type NavigationItemsNotInEditModeArgs = {
    expanded: boolean;
    currentPage?: string | null;
    menuButtons: MenuItem[];
    setMenuButtons: (menuButtons: MenuItem[]) => void;
};

// Needed wrapper to make <Droppable /> working in React 18 Strict Mode.
// source: https://github.com/atlassian/react-beautiful-dnd/issues/2399
// We should probably migrate to react-dnd at some point.
export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
    const [enabled, setEnabled] = React.useState(false);

    React.useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));

        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);

    if (!enabled) {
        return null;
    }

    return <Droppable {...props}>{children}</Droppable>;
};

export const NavigationItemsInEditMode = ({
    expanded,
    currentPage,
    menuButtons,
    setMenuButtons,
}: NavigationItemsNotInEditModeArgs): React.ReactElement => {
    const texting = menuButtons.find(({ page }) => page === 'texting');

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const sourceIndex = texting ? source.index + 1 : source.index;
        const destinationIndex = texting
            ? destination.index + 1
            : destination.index;

        const movedItem = menuButtons[sourceIndex];

        const itemsWithoutSource = menuButtons.filter(
            (item: MenuItem) => item.page !== movedItem.page,
        );

        const itemsWithDestination = [
            ...itemsWithoutSource.slice(0, destinationIndex),
            movedItem,
            ...itemsWithoutSource.slice(destinationIndex),
        ];

        setMenuButtons(itemsWithDestination);
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                    {(provided: DroppableProvided) => (
                        <div ref={provided.innerRef}>
                            {!!texting && (
                                <NavigationButton
                                    key={'texting'}
                                    style={{
                                        borderTop:
                                            '1px solid rgba(255, 255, 255, 0.1)',
                                        borderBottom:
                                            '1px solid rgba(255, 255, 255, 0.1)',
                                    }}
                                    expanded={expanded}
                                    titleTag={texting.titleTag}
                                    icon={texting.icon}
                                    active={currentPage === 'texting'}
                                />
                            )}
                            {menuButtons
                                .filter(({ page }) => page !== 'texting')
                                .map(
                                    (
                                        { icon, titleTag, page }: MenuItem,
                                        index: number,
                                    ) => (
                                        <Draggable
                                            key={page}
                                            draggableId={page}
                                            index={index}
                                        >
                                            {(provided2: DraggableProvided) => (
                                                <NavigationButton
                                                    innerRef={
                                                        provided2.innerRef
                                                    }
                                                    key={page}
                                                    style={{
                                                        borderTop:
                                                            '1px solid rgba(255, 255, 255, 0.1)',
                                                        borderBottom:
                                                            '1px solid rgba(255, 255, 255, 0.1)',
                                                        ...provided2
                                                            .draggableProps
                                                            .style,
                                                    }}
                                                    expanded={expanded}
                                                    titleTag={titleTag}
                                                    icon={icon}
                                                    draggableProps={
                                                        provided2.draggableProps
                                                    }
                                                    dragHandleProps={
                                                        provided2.dragHandleProps ||
                                                        undefined
                                                    }
                                                    active={
                                                        currentPage === page
                                                    }
                                                />
                                            )}
                                        </Draggable>
                                    ),
                                )}
                            {provided.placeholder}
                        </div>
                    )}
                </StrictModeDroppable>
            </DragDropContext>
        </>
    );
};
export default NavigationItemsInEditMode;
