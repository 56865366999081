import * as React from 'react';

import { useTranslate } from '@bondvet/web-app-i18n/util';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import classnames from 'classnames';

import useTextMessagingQuery from 'hooks/useTextMessagingQuery';
import { ProactiveMessagingTemplate } from '@bondvet/types/textMessaging';
import { ReactComponent as NoTemplateSelected } from '../../assets/noTemplateSelected.svg';

import styles from './MessageTemplateSelection.module.scss';
import {
    proactiveMessagingTemplatesQuery,
    ProactiveMessagingTemplatesQueryResult,
    ClientWithPatients,
} from '../../api';

interface MessageTemplateSelectionProps {
    client: ClientWithPatients;
    selectedTemplate: ProactiveMessagingTemplate | null;
    setSelectedTemplate: (
        newTemplate: ProactiveMessagingTemplate | null,
    ) => void;
    next: () => void;
}

export default function MessageTemplateSelection({
    client,
    selectedTemplate,
    setSelectedTemplate,
    next,
}: MessageTemplateSelectionProps): React.ReactElement {
    const translate = useTranslate();

    const { data: templates, loading: templatesLoading } =
        useTextMessagingQuery<ProactiveMessagingTemplatesQueryResult>(
            proactiveMessagingTemplatesQuery,
            {
                fetchPolicy: 'no-cache',
            },
        );

    return (
        <DialogContent className={styles.root}>
            <div className={styles.leftSide}>
                <div className={styles.leftSideContent}>
                    {templatesLoading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <DialogTitle className={styles.title}>
                                {translate(
                                    'vetspireExtension.texting.sendOutboundMessageModal.title',
                                )}
                            </DialogTitle>
                            <Tabs
                                value={1}
                                variant="fullWidth"
                                classes={{
                                    root: styles.indicatorBackground,
                                    indicator: styles.indicator,
                                }}
                            >
                                <Tab
                                    classes={{
                                        root: styles.tab,
                                        selected: styles.selected,
                                    }}
                                    value={1}
                                    label="1. Template"
                                />
                                <Tab
                                    classes={{
                                        root: styles.tab,
                                        disabled: styles.selected,
                                    }}
                                    value={2}
                                    disabled
                                    label="2. Variables"
                                />
                            </Tabs>
                            <div className={styles.tabContent}>
                                <div>
                                    {`To: `}
                                    <strong>
                                        {`${client.givenName} ${client.familyName}`}
                                    </strong>
                                    {` ${client.preferredPhoneNumber}`}
                                </div>
                                <ul className={styles.templateList}>
                                    {templates?.proactiveMessagingTemplates.map(
                                        (template) => (
                                            <li
                                                className={classnames({
                                                    [styles.selectedTemplate]:
                                                        template._id ===
                                                        selectedTemplate?._id,
                                                })}
                                                key={template._id}
                                                onClick={() =>
                                                    setSelectedTemplate(
                                                        template,
                                                    )
                                                }
                                            >
                                                {`${template.name}`}
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
                <DialogActions className={styles.actions}>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => next()}
                        className={classnames(styles.actionButton, {
                            [styles.disabled]: !selectedTemplate,
                        })}
                        disabled={!selectedTemplate}
                    >
                        {translate(
                            'vetspireExtension.texting.sendOutboundMessageModal.template.submit',
                        )}
                    </Button>
                </DialogActions>
            </div>
            <div className={styles.rightSide}>
                {selectedTemplate ? (
                    <div className={styles.templateWrapper}>
                        <h2 className={styles.templateTitle}>
                            {selectedTemplate?.name}
                        </h2>
                        <h4 className={styles.subtitle}>Purpose</h4>
                        <p>{selectedTemplate.purposeDescription}</p>
                        <hr className={styles.divider} />
                        <h4 className={styles.subtitle}>Target Audience</h4>
                        <p>{selectedTemplate.targetAudienceDescription}</p>
                        <hr className={styles.divider} />
                        <h4 className={styles.subtitle}>Timing</h4>
                        <p>{selectedTemplate.timingDescription}</p>
                        <hr className={styles.divider} />
                        <h4 className={styles.subtitle}>Preview</h4>
                        <p
                            className={styles.preview}
                            dangerouslySetInnerHTML={{
                                __html: selectedTemplate.messageBody1 ?? '',
                            }}
                        />
                        <p
                            className={styles.preview}
                            dangerouslySetInnerHTML={{
                                __html: selectedTemplate.messageBody2 ?? '',
                            }}
                        />
                    </div>
                ) : (
                    <div className={styles.noTemplate}>
                        <NoTemplateSelected />
                        <div>Choose a template</div>
                    </div>
                )}
            </div>
        </DialogContent>
    );
}
