import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import { ShiftInfoData } from '../../types';

import styles from './ShiftInfo.module.scss';

interface ShiftInfoProps {
    position: 'top' | 'bottom';
    data: ShiftInfoData;
    hide: () => void;
    setIgnoreShift: (ignore: number) => void;
}

const ShiftInfo: React.FC<ShiftInfoProps> = ({
    position,
    data: { employeeName, stats, badgeNumber, clicked },
    hide,
    setIgnoreShift,
}) => {
    const translate = useTranslate();

    const nodeRef = React.useRef<HTMLDivElement>(null);

    const clickListener = React.useCallback(
        (event: MouseEvent) => {
            if (
                nodeRef?.current &&
                !nodeRef.current.contains(event.target as HTMLElement) &&
                clicked
            ) {
                hide();
            }
        },
        [nodeRef, hide, clicked],
    );

    React.useEffect(() => {
        document.addEventListener('click', clickListener);

        return (): void => {
            document.removeEventListener('click', clickListener);
        };
    }, [clickListener]);

    const isUnderstaffed = stats.underStaffed > 0 && badgeNumber > stats.count;
    const isUnderstaffedIgnored =
        stats.underStaffed > 0 &&
        badgeNumber > stats.count &&
        badgeNumber <= stats.count + stats.ignore;

    const isOverstaffed = stats.overStaffed > 0 && badgeNumber > stats.expected;
    const isOverstaffedIgnored =
        isOverstaffed && badgeNumber <= stats.expected + stats.ignore;

    const isIgnored = isUnderstaffedIgnored || isOverstaffedIgnored;

    return (
        <div
            className={classnames(
                styles.shiftInfo,
                { [styles.bottom]: position === 'bottom' },
                { [styles.top]: position === 'top' },
            )}
            ref={nodeRef}
        >
            <div
                className={classnames(
                    { [styles.arrowTop]: position === 'bottom' },
                    { [styles.arrowBottom]: position === 'top' },
                )}
            />
            {clicked ? (
                <>
                    <div className={styles.message}>
                        {isIgnored
                            ? translate(
                                  'vetspireExtension.staffOptimization.shiftInfo.ignoreMessage',
                              )
                            : translate(
                                  `vetspireExtension.staffOptimization.shiftInfo.${
                                      stats.underStaffed > 0
                                          ? 'understaffedMessage'
                                          : 'overstaffedMessage'
                                  }`,
                              )}
                    </div>
                    <div className={styles.buttons}>
                        <button
                            type="button"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setIgnoreShift(
                                    isUnderstaffedIgnored ||
                                        isOverstaffedIgnored
                                        ? stats.ignore - 1
                                        : stats.ignore + 1,
                                );
                            }}
                        >
                            {translate(
                                `vetspireExtension.staffOptimization.shiftInfo.${
                                    isUnderstaffedIgnored ||
                                    isOverstaffedIgnored
                                        ? 'unignore'
                                        : 'ignore'
                                }`,
                            )}
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.name}>{employeeName}</div>
                    {isUnderstaffed && (
                        <div className={styles.underStaffed}>
                            {translate(
                                'vetspireExtension.staffOptimization.shiftInfo.understaffed',
                            )}
                        </div>
                    )}
                    {isOverstaffed && (
                        <div className={styles.overStaffed}>
                            {translate(
                                'vetspireExtension.staffOptimization.shiftInfo.overstaffed',
                            )}
                        </div>
                    )}
                    {(isUnderstaffed || isOverstaffed) && (
                        <div className={styles.hint}>
                            {translate(
                                'vetspireExtension.staffOptimization.shiftInfo.ignoreHint',
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ShiftInfo;
