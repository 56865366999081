import {
    useLazyQuery,
    type DocumentNode,
    type OperationVariables,
    type LazyQueryHookOptions,
    type TypedDocumentNode,
} from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';

export default function useLazySchedulingQuery<
    TData = unknown,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: LazyQueryHookOptions<TData, TVariables>,
): ReturnType<typeof useLazyQuery<TData, TVariables>> {
    return useLazyQuery<TData, TVariables>(query, {
        ...options,
        context: {
            ...options?.context,
            clientName: GRAPHQL_CLIENT_NAMES.scheduling,
        },
    });
}
