export const PATIENT_PLANS_COLLECTION_NAME = 'patientPlans';

export const PATIENT_PLAN_EVENTS_COLLECTION_NAME = 'patientPlans.events';

export const PUBSUB_TOPIC_PROCESS_PATIENT_PLAN_EVENT =
    'patientPlans-processEvent';

export const PUBSUB_TOPIC_PROCESS_PLAN_ITEM_INVOICE =
    'patientPlans-processInvoice';

export const PREVENTION_PLANS_COLLECTION_NAME = 'patientPlans.preventionPlans';

export const PATIENT_PLAN_ENROLL_PROTOCOLS_COLLECTION_NAME =
    'patientPlans.enrollProtocols';

export const PATIENT_PLAN_ENROLL_SESSIONS_COLLECTION_NAME =
    'patientPlans.enrollSessions';
