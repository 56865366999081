import { OperationResult } from '../common';

export const STAFFING_SHIFTS_STATUSES_COLLECTION_NAME =
    'staffing.shifts.statuses';

export const STAFFING_ROLES = [
    'manager',
    'doctor',
    'trainingDoctor',
    'nurse',
    'assistant',
    'careCoordinator',
    'surgeryDoctor',
    'surgeryNurse',
    'surgeryAssistant',
    'surgeryConcierge',
] as const;

export type StaffingRole = (typeof STAFFING_ROLES)[number];

export interface StaffingShiftStatus {
    _id?: string;
    vetspireLocationId: string;
    day: string;
    staffingRole: StaffingRole;
    ignore: number;
}

export interface StaffingShiftStatusesQueryArguments {
    vetspireLocationIds: ReadonlyArray<string>;
    fromDate: string;
    toDate: string;
}

export interface StaffingShiftStatusesQueryResult {
    staffingShiftStatuses: StaffingShiftStatus[];
}

export const SCHEDULING_SETTINGS_STAFFING_ID = 'staffing';

export interface StaffingSettingsInput {
    skillRoleMapping: Record<StaffingRole, ReadonlyArray<string>>;
}

export type StaffingSettings = StaffingSettingsInput & {
    _id: typeof SCHEDULING_SETTINGS_STAFFING_ID;
};

export const defaultStaffingSettings: StaffingSettings = {
    _id: SCHEDULING_SETTINGS_STAFFING_ID,
    skillRoleMapping: {
        manager: [],
        doctor: [],
        trainingDoctor: [],
        nurse: [],
        assistant: [],
        careCoordinator: [],
        surgeryDoctor: [],
        surgeryNurse: [],
        surgeryAssistant: [],
        surgeryConcierge: [],
    },
};

export interface StaffingSettingsQueryResult {
    staffingSettings: StaffingSettings;
}

export interface UpdateStaffingSettingsArguments {
    input: StaffingSettingsInput;
}

export interface UpdateStaffingSettingsResult {
    updateStaffingSettings: OperationResult;
}
