import type { OperationResult } from '../common';
import { VetspireSex } from '../vetspire';
import type { Animal } from '../animals';
import type { LLMProvider } from '../llm';
import type { SchedulingSource } from '../scheduling';
import type { User } from '../users';
import type { PickStartsWith } from '../util';
import type { LeadSubscriptionStatusChanges } from '../newsletter';

export const TEXT_MESSAGING_EVENTS_FIREBASE_COLLECTION_NAME =
    'textMessaging.conversationalSmsEvents';

export const TEXT_MESSAGING_UNREAD_MESSAGE_EVENTS_FIREBASE_COLLECTION_NAME =
    'textMessaging.unreadSMSMessageEvents';

export const TEXT_MESSAGES_COLLECTION_NAME = 'textMessages';
export const PROACTIVE_MESSAGING_TEMPLATES_COLLECTION_NAME =
    'textMessaging.proactiveMessagingTemplates';
export const PROACTIVE_MESSAGING_VARIABLES_COLLECTION_NAME =
    'textMessaging.proactiveMessagingVariables';
export const CONVERSATIONAL_SMS_MESSAGES_COLLECTION_NAME =
    'textMessaging.conversationalSmsMessages';

export type TextMessageStatus = 'received' | 'forbidden' | 'delivered' | 'sent';

export type TextMessageDirection = 'in' | 'out';

export type SMSSubscriptionTimestampKey = keyof PickStartsWith<
    LeadSubscriptionStatusChanges,
    'sms'
>;

export const TEXT_MESSAGE_PHONE_LINES = [
    'conversational',
    'transactional',
    'marketing',
] as const;

export type TextMessagePhoneLine = (typeof TEXT_MESSAGE_PHONE_LINES)[number];

export type TextMessageOptInOutAction = 'subscribe' | 'unsubscribe';
export type TextMessageResponseCategory = 'yes' | 'no' | 'unknown';
/**
 * collection: `textMessages`
 *
 * TRANSACTIONAL Text messages sent to employees and clients
 */
export type TextMessage = {
    /** alphanumeric id*/
    _id: string;
    /** the client whom the message was sent */
    clientId?: string;
    /** the appointment id the message relates to */
    appointmentId?: string;
    /** the Humanity or UKG Ready employee the message was sent to */
    employeeId?: string;
    /** the Humanity or UKG Pro person the message was sent to */
    ukgProPersonId?: string;
    /** The provider  */
    providerId?: string;
    /** The UKG Ready shift that this text (survey) is associated with */
    shiftId?: string;
    /** The UKG Pro shift that this text (survey) is associated with */
    ukgProShiftId?: string;
    /** the body of the message */
    message: string;
    /** timestamp */
    createdAt?: Date;
    /** timestamp */
    receivedAt?: Date;
    /** phone number sent from */
    from: string;
    /** phone number sent to */
    to: string;
    /** in or outgoing */
    direction?: TextMessageDirection;
    /** whether the message is a survey  */
    isSurvey?: boolean;
    /** number of tries to send the message */
    tryCount?: number;
    /** Twilio message id */
    messageSid?: string;
    /** true if message came from a manual correction run */
    correctionRun?: boolean;
    /** If present, whether the user subscribed or unsubscribed with a keyword message **/
    optInOutAction?: TextMessageOptInOutAction;
};

export const TEXT_MESSAGING_SETTINGS_COLLECTION_NAME = 'textMessaging.settings';
export const TEXT_MESSAGING_SETTINGS_POST_SHIFT_SURVEY_ID = 'postShiftSurvey';
export const TEXT_MESSAGING_SETTINGS_NPS_SURVEY_ID = 'npsSurvey';
export const TEXT_MESSAGING_SETTINGS_APPOINTMENT_REMINDERS_ID =
    'appointmentReminders';
export const TEXT_MESSAGING_SETTINGS_CONVERSATIONAL_ID = 'conversational';

export type EnabledStatus = 'disabled' | 'onlyAllowedPhoneNumbers' | 'enabled';

/*
 **
 * collection: textMessaging.settings, _id='conversational'
 */
export type FaqLink = {
    title: string;
    url: string;
};

export interface TextMessagingSettingsInput {
    /** if disabled, the whole process will not run */
    status: EnabledStatus;
    /** Twilio phone number for sending and receiving survey messages */
    twilioPhoneNumber: string;
    /** Twilio sid for sending and receiving survey messages */
    twilioSID: string;
    /** if status is onlyAllowedPhoneNumbers, sending will be restricted to this list of phone numbers */
    allowedPhoneNumbers: ReadonlyArray<string>;
    /** if we receive an unexpected text message, we'll forward it to this email list */
    fallbackEmailList: ReadonlyArray<string>;
    /** faq links to show in the chrome extension */
    faqLinks?: ReadonlyArray<FaqLink>;
    launchDate?: Date;
}

/**
 * collection: textMessaging.settings, _id='postShiftSurvey'
 */
export type PostShiftSurveySettingsInput = TextMessagingSettingsInput & {
    /** Ignore shifts that are PTO if true */
    ignorePTO: boolean;
    /** ignore shifts with one of these skills */
    ignoreUKGSkillIds: ReadonlyArray<string>;
    /** ignore shifts with one of these skills */
    ignoreUKGProSegmentTagIds: readonly string[];
    /** do not process surveys at all if more than this amount of surveys should be processed at once
     * Another basic safety check to avoid SMS flooding. */
    maxSurveys: number;
    /** name of the campaign to trigger */
    customerIoCampaignTrigger: string;
    /** name of the campaign to trigger for responses */
    customerIoCampaignResponseTrigger: string;
    /** whether we should use UKG Ready or UKG Pro shifts and employees as source for surveys */
    shiftSource: SchedulingSource;
    /** The amount of time in minutes after a shift has ended to send the sms survey. */
    afterShiftMinMinutes: number;
    /** Limit the time of looking back for sending surveys.
     * A basic safety check to avoid SMS flooding. */
    afterShiftMaxMinutes: number;
};

export type PostShiftSurveySettings = PostShiftSurveySettingsInput & {
    _id: typeof TEXT_MESSAGING_SETTINGS_POST_SHIFT_SURVEY_ID;
};

export const defaultPostShiftSurveySettings: PostShiftSurveySettings = {
    _id: TEXT_MESSAGING_SETTINGS_POST_SHIFT_SURVEY_ID,
    status: 'disabled',
    twilioPhoneNumber: '',
    twilioSID: '',
    allowedPhoneNumbers: [],
    ignorePTO: true,
    ignoreUKGSkillIds: [],
    ignoreUKGProSegmentTagIds: [],
    maxSurveys: 500,
    customerIoCampaignTrigger: '',
    customerIoCampaignResponseTrigger: '',
    afterShiftMinMinutes: 5,
    afterShiftMaxMinutes: 120,
    fallbackEmailList: [],
    shiftSource: 'ukg-pro',
};

export interface PostShiftSurveySettingsQueryResult {
    postShiftSurveySettings: PostShiftSurveySettings;
}

export interface UpdatePostShiftSurveySettingsArguments {
    input: PostShiftSurveySettingsInput;
}

export interface UpdatePostShiftSurveySettingsResult {
    updatePostShiftSurveySettings: OperationResult;
}

/**
 * collection: textMessaging.settings, _id='npsSurvey'
 */
export type NPSSurveySettingsInput = TextMessagingSettingsInput & {
    // Numeric responses less than or equal to this value will be treated as ow scores.
    lowScoreThreshold: number;
    // Numeric responses greater than or equal to this value will be treated as high scores.
    highScoreThreshold: number;
    // The amount of time in minutes after a patient discharge to send the sms survey.
    sendSurveyDelayMinMinutes: number;
    // Limit the time of looking back for sending surveys.
    // A basic safety check to avoid SMS flooding.
    sendSurveyDelayMaxMinutes: number;
    // do not process surveys at all if more than this amount of surveys should be processed at once
    // Another basic safety check to avoid SMS flooding.
    maxSurveyCount: number;
    // process unknown incoming survey responses via LLM
    processUnknownViaLLM?: boolean;
    // LLM provider for unknown incoming survey responses
    unknownProcessingLLMProvider?: LLMProvider;
    // the LLM that should be used for processing unknown incoming
    // survey responses
    unknownProcessingLLMModel?: string;
    // whether we actually want to execute any triggers
    // based on the response of the LLM
    processLLMResponse?: boolean;
};

export type NPSSurveySettings = NPSSurveySettingsInput & {
    _id: typeof TEXT_MESSAGING_SETTINGS_NPS_SURVEY_ID;
};

export const defaultNPSSurveySettings: NPSSurveySettings = {
    _id: TEXT_MESSAGING_SETTINGS_NPS_SURVEY_ID,
    lowScoreThreshold: 4,
    highScoreThreshold: 9,
    maxSurveyCount: 50,
    sendSurveyDelayMinMinutes: 60,
    sendSurveyDelayMaxMinutes: 300,
    fallbackEmailList: [],
    allowedPhoneNumbers: [],
    twilioPhoneNumber: '',
    twilioSID: '',
    status: 'disabled',
    processUnknownViaLLM: false,
    unknownProcessingLLMProvider: 'openAI',
    unknownProcessingLLMModel: '',
    processLLMResponse: false,
};

export interface NPSSurveySettingsQueryResult {
    npsSurveySettings: NPSSurveySettings;
}

export interface UpdateNPSSurveySettingsArguments {
    input: NPSSurveySettingsInput;
}

export interface UpdateNPSSurveySettingsResult {
    updateNPSSurveySettings: OperationResult;
}

/**
 * collection: textMessaging.settings, _id='postShiftSurvey'
 */
export type AppointmentRemindersSettingsInput = TextMessagingSettingsInput & {
    // do not process appointment reminders at all if more than this amount of
    // appointment reminders should be processed at once.
    // Another basic safety check to avoid SMS flooding.
    maxAppointmentReminderCount: number;
    logAppointmentReminderQuerySlackChannel: string;
};

export type AppointmentRemindersSettings = AppointmentRemindersSettingsInput & {
    _id: typeof TEXT_MESSAGING_SETTINGS_APPOINTMENT_REMINDERS_ID;
};

export const defaultAppointmentRemindersSettings: AppointmentRemindersSettings =
    {
        _id: TEXT_MESSAGING_SETTINGS_APPOINTMENT_REMINDERS_ID,
        maxAppointmentReminderCount: 200,
        logAppointmentReminderQuerySlackChannel: '',
        fallbackEmailList: [],
        allowedPhoneNumbers: [],
        twilioPhoneNumber: '',
        twilioSID: '',
        status: 'disabled',
    };

export interface AppointmentRemindersSettingsQueryResult {
    appointmentRemindersSettings: AppointmentRemindersSettings;
}

export interface UpdateAppointmentRemindersSettingsArguments {
    input: AppointmentRemindersSettingsInput;
}

export interface UpdateAppointmentRemindersSettingsResult {
    updateAppointmentRemindersSettings: OperationResult;
}

export type ConversationalSettingsInput = TextMessagingSettingsInput & {
    showMessagesForHours: number;
    goLiveDate: Date;
    adaBotName: string;
    faqLinks?: ReadonlyArray<FaqLink>;
    launchDate?: Date;
    proactiveMessageAdaAnswerId: string;
    zendeskCustomFieldIds: {
        phoneNumber: number;
        proactive: number;
        locationId: number;
        providerId: number;
        sunshineUserId: number;
        sunshineConversationId: number;
        autoCloseTimestamp: number;
        contactReason: number;
        smsFlag: number;
    };
    nonProactiveMessageBodies: ReadonlyArray<string>;
    fallbackZendeskContactReason: string;
};

export type ConversationalSettings = ConversationalSettingsInput & {
    _id: typeof TEXT_MESSAGING_SETTINGS_CONVERSATIONAL_ID;
};

export const defaultConversationalSettings: ConversationalSettings = {
    _id: TEXT_MESSAGING_SETTINGS_CONVERSATIONAL_ID,
    status: 'disabled',
    twilioPhoneNumber: '',
    twilioSID: '',
    allowedPhoneNumbers: [],
    fallbackEmailList: [],
    showMessagesForHours: 36,
    goLiveDate: new Date('2024-06-01'),
    adaBotName: 'Bond Vet Virtual Assistant',
    proactiveMessageAdaAnswerId: '669aaec2e628973e4d1c7a1a',
    zendeskCustomFieldIds: {
        phoneNumber: 23494409360788,
        proactive: 28619374525460,
        locationId: 27793056561428,
        providerId: 27793017982612,
        sunshineUserId: 24954420045972,
        sunshineConversationId: 24954472402964,
        autoCloseTimestamp: 29880539903892,
        contactReason: 360049348192,
        smsFlag: 30068036781460,
    },
    nonProactiveMessageBodies: [
        'Keep in mind it may take us a few minutes to get back to you — thanks for your patience!',
        'Text STOP to stop receiving SMS messages',
    ],
    fallbackZendeskContactReason: 'ada__automation_autoclose',
};

export interface ConversationalSettingsQueryResult {
    conversationalSettings: ConversationalSettings;
}

export interface UpdateConversationalSettingsArguments {
    input: Partial<ConversationalSettingsInput>;
}

export interface UpdateConversationalSettingsResult {
    updateConversationalSettings: OperationResult;
}

export const JOB_TYPE_SEND_TEXT_MESSAGE = 'textMessages.send';
export const JOB_TYPE_HANDLE_STATUS_UPDATE = 'textMessages.handleStatusUpdate';
export const JOB_TYPE_HANDLE_INCOMING = 'textMessages.handleIncoming';
export const JOB_TYPE_SEND_NPS_SURVEYS = 'textMessages.sendNPSSurveys';
export const JOB_TYPE_SEND_TEAM_SURVEYS = 'textMessages.sendTeamSurveys';
export const JOB_TYPE_SEND_APPOINTMENT_REMINDERS =
    'textMessages.sendAppointmentReminders';

export type JobTypes = Readonly<{
    sendTextMessage: typeof JOB_TYPE_SEND_TEXT_MESSAGE;
    handleStatusUpdate: typeof JOB_TYPE_HANDLE_STATUS_UPDATE;
    handleIncoming: typeof JOB_TYPE_HANDLE_INCOMING;
    sendNPSSurveys: typeof JOB_TYPE_SEND_NPS_SURVEYS;
    sendTeamSurveys: typeof JOB_TYPE_SEND_TEAM_SURVEYS;
    sendAppointmentReminders: typeof JOB_TYPE_SEND_APPOINTMENT_REMINDERS;
}>;

export const JOB_TYPES: JobTypes = Object.freeze({
    sendTextMessage: JOB_TYPE_SEND_TEXT_MESSAGE,
    handleStatusUpdate: JOB_TYPE_HANDLE_STATUS_UPDATE,
    handleIncoming: JOB_TYPE_HANDLE_INCOMING,
    sendNPSSurveys: JOB_TYPE_SEND_NPS_SURVEYS,
    sendTeamSurveys: JOB_TYPE_SEND_TEAM_SURVEYS,
    sendAppointmentReminders: JOB_TYPE_SEND_APPOINTMENT_REMINDERS,
});

export const TEXT_MESSAGING_CONVERSATIONAL_USERS_COLLECTION_NAME =
    'textMessaging.conversationalUsers';

export interface LinkedUser {
    vetspireClientId: string;
    historical: boolean;
    lastMessageAt?: Date | null;
}

/**
 * collection: textMessaging.conversationalUsers'
 */
export type TextMessagingConversationalUser = {
    // Random mongo ID
    _id: string;
    // external ID field on the sunshine / zd user. Should be a twilio ID and match between the two. This is unique!
    externalId: string;
    // ID of the user in the sunshine API ( this is unique! )
    sunshineUserId: string;
    // ID of the user in the ZD ticketing API
    zendeskUserId: string;
    // Formatted phone number
    intlPhoneNumber: string;
    // All current and past linked VS users
    linkedUsers: LinkedUser[];
};

/**
 * A cache of all SMS messages sent and received with our conversational line. Pulled from Zendesk and Sunshine
 * collection: textMessaging.conversationalSmsMessages
 */
export type ConversationalSmsMessage = {
    // The ID of the message from sunshine API
    _id: string;
    // The id of the sunshine conversation the message came from
    sunshineConversationId?: string;
    // What type of message (or other conversation event) this is
    type: 'text' | 'image' | 'internalNote' | 'conversationBreak' | 'pending';
    // Whether the message was inbound or outbound - internal notes and conversation breaks are internal
    direction: 'in' | 'out' | 'internal';
    // The phone number of the associated client conversation (this is the unique thing that ties messages / users together in sunshine)
    intlPhoneNumber: string;
    // The body of the message or note
    body: string;
    // The time the message was sent/received (or internal note left / conversation ended)
    datetime: Date;
    // The client or provider name who sent the message
    fromName: string;
    // The place or team at bond that the message was sent to or from
    bondLocation: string;
    // The ID from of the related ZD ticket, if there is one
    zendeskTicketId?: string;
    // For direction === out,  Whether the message was from the bot
    botMessage?: boolean;
    // For direction === out, Whether the message was from the VO team
    virtualTeamMessage?: boolean;
    // For direction === out, Whether the message was proactively sent from a clinic
    proactive?: boolean;
    // For proactive === true, the vetspire location id it came from
    locationId?: string;
    // For proactive === true, the vetspire provider id who sent it
    providerId?: string;
    // For type === 'pending', when it should "expire" // TODO: build a cleanup job
    pendingExpiration?: Date;
    // For type === image, the url of the image
    imageSrc?: string;
    // For type === 'conversationBreak', whether the conversation was noted in the chart
    notedInChart?: boolean;
};

export type CachedUserConversationHistoryQueryArguments = {
    phoneNumber: string;
    offset?: number;
    limit?: number;
};

export type RecentUserConversationHistoryArguments = {
    phoneNumber: string;
    vetspireClientId: string;
    afterId?: string;
};

export type RecentUserConversationHistoryResult = {
    newMessages: number;
};

export interface TextingAnimal extends Pick<Animal, 'name' | 'isDeceased'> {
    vetspireId: string;
    inactive: boolean;
    vetspireLink: string;
}

export interface TextingClient
    extends Pick<User, 'firstName' | 'lastName' | 'intlPhoneNumber'> {
    vetspireId: string;
    animals: readonly TextingAnimal[];
}

export interface ConversationalSMSEvent {
    createdAt: Date;
    eventTye: string;
    expiresAt: Date;
    messageBody?: string;
    vetspireClientIds: readonly string[];
}

export type SearchClientQueryArguments = {
    searchString: string;
};

/**
 * collection: textMessaging.proactiveMessagingTemplates'
 */
export type ProactiveMessagingTemplate = {
    // Random mongo ID
    _id: string;
    name: string;
    purposeDescription: string;
    targetAudienceDescription: string;
    timingDescription: string;
    requiresImage: boolean;
    enabled: boolean;
    urgent: boolean;
    messageBody1: string;
    messageBody2?: string;
    autoCloseHours?: number | null;
};

export type PatientPronounType = 'objective' | 'subjective' | 'possessive';
/**
 * collection: textMessaging.proactiveMessagingVariables'
 */
export type ProactiveMessagingVariable = {
    // Random mongo ID
    _id: string;
    // The identifier in the team app - e.g. "client_name"
    identifier: string;
    // The type of variable - freeform is editable in the team app settings
    type: 'freeform' | 'vetspire';
    // The description of the variable
    description: string;
    // An example value
    example: string;
    // Maximum characters allowed
    maxCharacters: number;
    // Not required on freeform variables....
    // The vetspire object from which to source the value
    vetspireEntity?: 'client' | 'appointment' | 'location' | 'patient';
    // The path on the vetspire entity to reach the value  i.e. givenName or preferredPhone.value
    vetspirePath?: string;
    // The raw type that the value will be - for formatting purposes (they are all strings, but we need to format date and times etc.)
    vetspirePathRawType?: 'date' | 'time' | 'string';
    // The type of pronoun (for VS variables of type string that represent pronouns) to determine if it's he/she/they, him/her/them, or his/her/their
    pronounType?: PatientPronounType;
};

export interface ProactiveMessagingTemplatesQueryVariables {
    id?: string;
    onlyEnabled?: boolean;
    asHTML?: boolean;
}

export type ProactiveMessagingTemplateInput = Omit<
    ProactiveMessagingTemplate,
    '_id'
>;

export interface AddProactiveMessagingTemplateArguments {
    input: ProactiveMessagingTemplateInput;
}

export type UpdateProactiveMessagingTemplateInput =
    Partial<ProactiveMessagingTemplateInput>;

export interface UpdateProactiveMessagingTemplateArguments {
    id: string;
    input: UpdateProactiveMessagingTemplateInput;
}

export type ProactiveMessagingVariableInput = Omit<
    ProactiveMessagingVariable,
    '_id'
>;

export interface AddProactiveMessagingVariableArguments {
    input: ProactiveMessagingVariableInput;
}

export type UpdateProactiveMessagingVariableInput =
    Partial<ProactiveMessagingVariableInput>;

export interface UpdateProactiveMessagingVariableArguments {
    id: string;
    input: UpdateProactiveMessagingVariableInput;
}

export interface TemplateVariableValue {
    identifier: string;
    value: string;
}

export interface TemplateVariablesQueryArguments {
    templateId: string;
}

export interface BuildProactiveMessageBodyInput {
    templateId?: string;
    // build a proactive message body for a custom text not linked to a template yet
    message?: string;
    // If true, this is for preview in the team app where the below entities can all be undefined
    // If false, the below must be defined (if needed by the template) otherwise will return the variable identifier (error)
    useExampleValues?: boolean;
    // Values to replace freeform variables with
    freeFormVariableValues: ReadonlyArray<TemplateVariableValue>;
    // Values to replace freeform variables with
    vetspireVariableOverrideValues: ReadonlyArray<TemplateVariableValue>;
    // id of VetspireClient
    clientId?: string;
    // id of VetspirePatient
    patientId?: string;
    // id of  VetspireLocation
    locationId?: string;
    // id of VetspireAppointment
    appointmentId?: string;
}
export interface BuildProactiveMessageBodyArguments {
    input: BuildProactiveMessageBodyInput;
}
export interface BuildProactiveMessageBodyResult {
    body1: string;
    body2?: string;
}

export interface GetTemplateVetspireVariableValuesInput {
    templateId: string;
    // Values to replace freeform variables with
    vetspireVariableOverrideValues: ReadonlyArray<TemplateVariableValue>;
    // id of VetspireClient
    clientId?: string;
    // id of VetspirePatient
    patientId?: string;
    // id of  VetspireLocation
    locationId?: string;
    // id of VetspireAppointment
    appointmentId?: string;
}

export interface GetTemplateVetspireVariableValuesArguments {
    input: GetTemplateVetspireVariableValuesInput;
}

export interface GetTemplateVetspireVariableValuesResult {
    values: ReadonlyArray<TemplateVariableValue>;
}

export interface SendProactiveMessageInput {
    phoneNumber: string;
    templateId: string;
    locationId: string;
    providerId: string;
    freeFormVariableValues?: ReadonlyArray<TemplateVariableValue>;
    vetspireVariableOverrideValues?: ReadonlyArray<TemplateVariableValue>;
    clientId: string;
    patientId?: string;
    appointmentId?: string;
    imageBase64?: string;
}

export interface SendProactiveMessageArguments {
    input: SendProactiveMessageInput;
}

export interface UpdateConversationalSubscriptionStatusInput {
    clientId: string;
    userId: string;
    userName: string;
    action: TextMessageOptInOutAction;
}

export interface UpdateConversationalSubscriptionStatusArguments {
    input: UpdateConversationalSubscriptionStatusInput;
}

export interface PhoneNumberHasOpenTicketsArguemnts {
    phoneNumber: string;
}

export interface RecentLocationConversationsArguments {
    locationId: string;
}

export interface LocationConversationPet {
    id: string;
    name: string;
    inactive: boolean;
    deceased: boolean;
    sex: VetspireSex;
}

export interface LocationConversationInfo {
    vetspireClientId: string;
    givenName: string;
    familyName: string;
    preferredPhoneNumber?: string;
    optedIn: boolean;
    isActive: boolean;
    pets: readonly LocationConversationPet[];
    datetime: Date;
    unread: boolean;
}

export type UnreadMessageOrigin = 'newMessage' | 'manuallyMarked';
// Corresponds to Firestore collection 'textMessaging.unreadSMSMessageEvents'
export interface UnreadSMSMessageEvent {
    vetspireClientId: string;
    clientName: string;
    vetspireLocationId: string;
    intlPhoneNumber: string;
    unread: boolean;
    lastSeenAt: Date;
    origin: UnreadMessageOrigin;
    alertedToast?: boolean;
}

export interface UpdateConversationUnreadStatusInput {
    clientPhoneNumber: string;
    vetspireLocationId: string;
    vetspireClientId: string;
    unread: boolean;
}

export interface UpdateConversationUnreadStatusArguments {
    input: UpdateConversationUnreadStatusInput;
}
