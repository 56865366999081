import * as React from 'react';
import { InfoPdf } from 'api/infoPdfs/queries';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { ReactComponent as CheckboxEmptyIcon } from 'assets/checkboxEmpty.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/checkboxChecked.svg';
import useTranslate from 'hooks/useTranslate';
import styles from './PdfOverviewRow.module.scss';

type PdfOverviewProps = {
    pdf: InfoPdf;
    selected: boolean;
    onPreview: (id: string) => void;
    onSelect: (id: string) => void;
    onUnselect: (id: string) => void;
    previewing: boolean;
};

const PdfOverviewRow: React.FunctionComponent<PdfOverviewProps> = ({
    pdf,
    onPreview,
    onSelect,
    onUnselect,
    selected,
    previewing,
}) => {
    let containerStyle = styles.pdfContainer;
    if (selected) {
        containerStyle += ` ${styles.selected}`;
    }

    const translate = useTranslate();
    const handleClick = React.useCallback(() => {
        onPreview(pdf._id);
    }, [onPreview, pdf._id]);

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                onSelect(pdf._id);
            } else {
                onUnselect(pdf._id);
            }
        },
        [onSelect, onUnselect, pdf._id],
    );
    const handleCheckboxClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
        },
        [],
    );

    return (
        <button
            className={containerStyle}
            key={pdf.name}
            type="button"
            onClick={handleClick}
        >
            <Tooltip
                disableInteractive
                classes={{ tooltip: styles.tooltip, popper: styles.popper }}
                placement="top"
                title={translate('vetspireExtension.infoPDFs.select')}
            >
                <Checkbox
                    checked={selected}
                    onClick={handleCheckboxClick}
                    onChange={handleChange}
                    icon={<CheckboxEmptyIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                    className={styles.checkbox}
                />
            </Tooltip>
            <span className={styles.name}>{pdf.name}</span>
            {previewing && (
                <Tooltip
                    disableInteractive
                    classes={{ tooltip: styles.tooltip, popper: styles.popper }}
                    placement="top"
                    title={translate('vetspireExtension.infoPDFs.previewing')}
                >
                    <VisibilityIcon className={styles.icon} />
                </Tooltip>
            )}
            <span
                style={
                    {
                        '--background-color': pdf.category?.color,
                    } as React.CSSProperties
                }
                className={styles.categoryName}
            >
                {pdf?.category?.name}
            </span>
        </button>
    );
};

export default PdfOverviewRow;
