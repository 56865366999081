import { gql, useQuery } from '@apollo/client';
import type {
    Patient,
    PatientFilters,
} from '@bondvet/types/generated/vetspire';

export const query = gql`
    query patients($filters: PatientFilters!) {
        patients(filters: $filters) {
            id
            name
            sex
            species
            birthDate
            client {
                id
                name
            }
        }
    }
`;

export type PatientsQueryResult = {
    patients: Patient[];
};

export type PatientsQueryVariables = {
    filters: PatientFilters;
};

export default function usePatientsQuery(
    filters: PatientFilters,
    skip = false,
) {
    return useQuery<PatientsQueryResult, PatientsQueryVariables>(query, {
        variables: { filters },
        fetchPolicy: 'cache-first',
        skip,
    });
}
