import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import SmsConversationalOptIn, {
    type SmsConversationalOptInProps,
} from '../SmsConversationalOptIn';

import styles from './SmsConversationalOptInModal.module.scss';

export default function SmsConversationalOptInModal({
    onClose,
    onSubmit,
    client,
}: SmsConversationalOptInProps): React.ReactElement {
    return (
        <Dialog
            open
            classes={{ container: styles.container, paper: styles.root }}
        >
            <SmsConversationalOptIn
                onSubmit={onSubmit}
                client={client}
                onClose={onClose}
            />
        </Dialog>
    );
}
