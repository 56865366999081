import * as React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './PatientPlanSignUp.module.scss';

const clientAppBaseUrl =
    process.env.REACT_APP_CLIENTAPP_URL || 'https://app.bondvet.com';

export default function PatientPlanSignUp() {
    const { search } = useLocation();

    const planSignUpUrl = new URL('/plan-signup', clientAppBaseUrl);

    return (
        <div className={styles.container}>
            <iframe src={`${planSignUpUrl.toString()}${search}`} />
        </div>
    );
}
