import * as React from 'react';
import { openCustomModal } from 'lib/vetspireActions';
import { formatInTimeZone } from 'date-fns-tz';
import { getFallbackTimezone } from 'lib/utils';
import { gql } from '@apollo/client';
import type { OperationResult } from '@bondvet/types/common';
import { eachLimit } from 'async';
import type {
    ClientExternalCommPreferencesInput,
    RootMutationTypeUpdateClientExternalCommPreferencesArgs,
} from '@bondvet/types/generated/vetspire';
import {
    type LeadSubscriptionStatus,
    SubscriptionStatus,
    type GraphQLSubscriptionStatusChangeInput as SubscriptionStatusChangeInput,
} from '@bondvet/types/newsletter';
import type { InterceptorResponse } from './useRequestInterceptor';
import useViewerSettings from './useViewerSettings';
import useBondMutation from './useBondMutation';
import useAddClientPrivateNotes from './useAddClientPrivateNotes';
import useLazyVetspireQuery from './useLazyVetspireQuery';

const emailAndPhoneNumberQuery = gql`
    query emailAndPhoneNumber($clientId: ID!) {
        client(id: $clientId) {
            email
            # we need the name when we add a note to other
            # client profiles with the same preferredPhoneNumber
            name
            preferredPhoneNumber {
                value
            }
        }
    }
`;

const clientsWithPhoneNumberQuery = gql`
    query clientsWithPhoneNumber($phoneNumber: String!) {
        clients(filters: { phoneNumber: $phoneNumber }) {
            # we need the ID, so we can filter out the original client
            id
            # we don't know if the phoneNumber filter only works
            # on the preferredPhoneNumber, or on _any_ phone number.
            # but we will only update clients that have the same
            # preferredPhoneNumber, so we need to query it and
            # filter out all clients that Vetspire returns but
            # who have a different preferredPhoneNumber
            preferredPhoneNumber {
                value
            }
        }
    }
`;

type EmailAndPhoneNumberQueryResult = {
    client: null | {
        email: string | null;
        preferredPhoneNumber: null | { value: string | null };
        name: string | null;
    };
};

type EmailAndPhoneNumberQueryVariables = {
    clientId: string;
};

type ClientWithPhoneNumber = {
    id: string | null;
    preferredPhoneNumber: null | { value: string | null };
};

type ClientsWithPhoneNumberQueryResult = {
    clients: null | readonly ClientWithPhoneNumber[];
};

type ClientsWithPhoneNumberQueryVariables = {
    phoneNumber: string;
};

// the updateClientExternalCommPreferences operation doesn't have a
// `clientId` variable, but rather an `id` variable
export type ClientExternalCommPreferencesArgs = Omit<
    RootMutationTypeUpdateClientExternalCommPreferencesArgs,
    'clientId'
> & {
    id: RootMutationTypeUpdateClientExternalCommPreferencesArgs['clientId'];
};

const SUBSCRIPTION_STATUS_TYPE_MAP: Record<
    keyof Omit<
        ClientExternalCommPreferencesInput,
        'emailTransactional' | 'postcardTransactional'
    >,
    keyof LeadSubscriptionStatus
> = {
    emailMarketing: 'email_subscribe',
    smsMarketing: 'sms_marketing',
    smsTransactional: 'sms_transactional',
    postcardMarketing: 'sms_conversational',
};

function createSubscriptionStatusChangeInputs(
    input: ClientExternalCommPreferencesInput,
): readonly Pick<SubscriptionStatusChangeInput, 'type' | 'status'>[] {
    return Object.entries(input).reduce<
        Pick<SubscriptionStatusChangeInput, 'type' | 'status'>[]
    >(
        (accumulator, [key, value]) => {
            if (key in SUBSCRIPTION_STATUS_TYPE_MAP) {
                accumulator.push({
                    type: SUBSCRIPTION_STATUS_TYPE_MAP[
                        key as keyof typeof SUBSCRIPTION_STATUS_TYPE_MAP
                    ],
                    status: value
                        ? SubscriptionStatus.subscribed
                        : SubscriptionStatus.unsubscribed,
                });
            }

            return accumulator;
        },
        [] as Pick<SubscriptionStatusChangeInput, 'type' | 'status'>[],
    );
}

const subscriptionStatusChangedMutation = gql`
    mutation subscriptionStatusChanged(
        $clientId: ID!
        $input: SubscriptionStatusChangeInput!
    ) {
        subscriptionStatusChanged(vetspireClientId: $clientId, input: $input) {
            success
            error
        }
    }
`;

type SubscriptionStatusChangedMutationResult = {
    subbscriptionStatusChanged: OperationResult;
};

type GraphQLSubscriptionStatusChangeInput = Omit<
    SubscriptionStatusChangeInput,
    'timestamp'
> & {
    timestamp: string;
};
type SubscriptionStatusChangedMutationVariables = {
    clientId: string;
    input: GraphQLSubscriptionStatusChangeInput;
};

type OnSubscriptionStatusUpdate = (
    args: ClientExternalCommPreferencesArgs,
) => Promise<InterceptorResponse<ClientExternalCommPreferencesArgs>>;

function getNoteLabel(type: SubscriptionStatusChangeInput['type']): string {
    switch (type) {
        case 'email_subscribe':
            return 'Email Marketing';
        case 'sms_marketing':
            return 'SMS Marketing';
        case 'sms_transactional':
            return 'SMS Transactional';
        case 'sms_conversational':
            return 'SMS Conversational';
        default:
            throw new Error(`Unknown subscription status type: ${type}`);
    }
}

export default function useTrackSubscriptionStatus(): OnSubscriptionStatusUpdate {
    const { viewer } = useViewerSettings();
    const viewerName = viewer?.name ?? 'Unknown';
    const addPrivateNotes = useAddClientPrivateNotes();

    const showSmsConversationalOptInModal = React.useCallback(
        (clientId: string) => {
            openCustomModal(
                'smsConversationalOptIn',
                new URLSearchParams({ client_id: clientId }),
            );
        },
        [],
    );

    const [subscriptionStatusChanged] = useBondMutation<
        SubscriptionStatusChangedMutationResult,
        SubscriptionStatusChangedMutationVariables
    >(subscriptionStatusChangedMutation);

    const [loadEmailAndPhoneNumber] = useLazyVetspireQuery<
        EmailAndPhoneNumberQueryResult,
        EmailAndPhoneNumberQueryVariables
    >(emailAndPhoneNumberQuery, {
        fetchPolicy: 'no-cache',
    });

    const [loadClientsViaPhoneNumber] = useLazyVetspireQuery<
        ClientsWithPhoneNumberQueryResult,
        ClientsWithPhoneNumberQueryVariables
    >(clientsWithPhoneNumberQuery);

    const addNoteToOtherClients = React.useCallback(
        async (
            clientId: string,
            phoneNumber: string,
            formattedTimeStamp: string,
            clientName: string,
            input: Pick<SubscriptionStatusChangeInput, 'type' | 'status'>,
        ) => {
            if (!input.type.startsWith('sms_')) {
                console.warn(
                    'input with invalid, non-SMS type "%s" provided to addNoteToOtherClients.',
                    input.type,
                );
                return;
            }

            if (!clientId) {
                console.warn('no clientId provided to addNoteToOtherClients.');
                return;
            }

            if (!phoneNumber) {
                console.warn(
                    'No phoneNumber provided to addNoteToOtherClients.',
                );
                return;
            }

            const { data } = await loadClientsViaPhoneNumber({
                variables: { phoneNumber },
            });

            const otherClients = (data?.clients ?? []).filter(
                (client) => client.id !== clientId,
            );

            if (otherClients.length === 0) {
                return;
            }

            let profile = `with ID ${clientId}`;

            if (clientName) {
                profile = `${clientName} (Client ID ${clientId})`;
            }
            const note = `${viewerName} set ${getNoteLabel(input.type)} to ${input.status} on ${formattedTimeStamp} on client profile ${profile}`;

            await eachLimit(otherClients, 5, async (otherClient) => {
                if (otherClient.id) {
                    return addPrivateNotes(otherClient.id, note);
                }

                return Promise.resolve();
            });
        },
        [loadClientsViaPhoneNumber, addPrivateNotes, viewerName],
    );

    return React.useCallback(
        async ({
            id,
            input: inputData,
        }: ClientExternalCommPreferencesArgs): Promise<
            InterceptorResponse<ClientExternalCommPreferencesArgs>
        > => {
            const timestamp = new Date();

            const inputs = createSubscriptionStatusChangeInputs(inputData);

            if (inputs.length === 0) {
                return {
                    runOriginalRequest: true,
                };
            }

            const smsConversationalOptIns = inputs.filter(
                (input) =>
                    input.status === SubscriptionStatus.subscribed &&
                    input.type === 'sms_conversational',
            );

            const runOriginalRequest = smsConversationalOptIns.length === 0;

            const inputsToProcess = runOriginalRequest
                ? inputs
                : inputs.filter(
                      (input) => input.status !== SubscriptionStatus.subscribed,
                  );

            if (!runOriginalRequest) {
                showSmsConversationalOptInModal(id);
            }

            // get email and phone number of client
            const { data: emailAndPhoneNumberData } =
                await loadEmailAndPhoneNumber({ variables: { clientId: id } });

            const email = emailAndPhoneNumberData?.client?.email;
            const phoneNumber =
                emailAndPhoneNumberData?.client?.preferredPhoneNumber?.value;
            const clientName = emailAndPhoneNumberData?.client?.name || '';

            const notesToAdd: string[] = [];

            for (const input of inputsToProcess) {
                const inputVariable: GraphQLSubscriptionStatusChangeInput = {
                    ...input,
                    timestamp: timestamp.toISOString(),
                    source: 'vetspireProvider',
                };

                const formattedTimeStamp = formatInTimeZone(
                    timestamp,
                    getFallbackTimezone(),
                    "MMMM do, yyyy 'at' h:mm a",
                );

                if (input.type === 'email_subscribe') {
                    inputVariable.email = email;
                } else if (phoneNumber) {
                    inputVariable.phoneNumber = phoneNumber;
                    addNoteToOtherClients(
                        id,
                        phoneNumber,
                        formattedTimeStamp,
                        clientName,
                        input,
                    ).then();
                }

                subscriptionStatusChanged({
                    variables: {
                        clientId: id,
                        input: inputVariable,
                    },
                }).then();
                notesToAdd.push(
                    `${viewerName} set ${getNoteLabel(input.type)} to ${input.status} on ${formattedTimeStamp}.`,
                );
            }

            if (notesToAdd.length > 0) {
                addPrivateNotes(id, notesToAdd.join('\n')).then();
            }

            return {
                runOriginalRequest,
            };
        },
        [
            addPrivateNotes,
            loadEmailAndPhoneNumber,
            subscriptionStatusChanged,
            viewerName,
            addNoteToOtherClients,
            showSmsConversationalOptInModal,
        ],
    );
}
