import { addDays, format, parse } from 'date-fns';
import {
    DecoratedPreventionPlan,
    type GlobalSettings,
    type IntervalPrices,
    type PatientPlanStatus,
} from '@bondvet/types/patientPlans';
import { DecoratedPatientPlan } from 'pages/PatientPlans/types';
import type {
    PatientPlan,
    PlanInterval,
} from '@bondvet/types/generated/vetspire';

export function getIntervalPrice(
    intervalPrices: IntervalPrices,
    planInterval: PlanInterval,
) {
    switch (planInterval) {
        case 'MONTHLY':
            return {
                price: intervalPrices.monthlyPrice,
                discountedPrice: intervalPrices.monthlyDiscountedPrice,
            };
        case 'QUARTERLY':
            return {
                price: intervalPrices.quarterlyPrice,
                discountedPrice: intervalPrices.quarterlyDiscountedPrice,
            };
        case 'YEARLY':
            return {
                price: intervalPrices.yearlyPrice,
                discountedPrice: intervalPrices.yearlyDiscountedPrice,
            };
        default:
            return {
                price: 0,
                discountedPrice: 0,
            };
    }
}

export function decoratePatientPlan(
    globalSettings: GlobalSettings,
    preventionPlans: readonly DecoratedPreventionPlan[],
    plan: PatientPlan,
): DecoratedPatientPlan {
    const today = format(new Date(), 'yyyy-MM-dd');

    const startDate = parse(plan.startDate, 'yyyy-MM-dd', new Date());

    const endDate = parse(plan.endDate, 'yyyy-MM-dd', new Date());

    const autoRenewDate = addDays(endDate, 1);

    let sortDate: Date = startDate;

    let status: PatientPlanStatus = 'active';
    if (plan.patient?.isDeceased) {
        status = 'patientDeceased';
        sortDate = new Date(plan.patient?.deceasedDate ?? plan.updatedAt);
    } else if (!plan.patient?.isActive) {
        status = 'patientInactive';
        sortDate = new Date(plan.patient?.updatedAt ?? plan.updatedAt);
    } else if (plan.cancelled) {
        status = 'cancelled';
        sortDate = new Date(plan.updatedAt);
    } else if (plan.endDate < today) {
        status = 'expired';
        sortDate = endDate;
    }

    const someItemsRedeemed =
        plan.items?.some((item) => item?.quantityUsed > 0) ?? false;

    const cancellationPeriodLimit = addDays(
        startDate,
        globalSettings.cancellationPeriodDays,
    );

    const sortDateString =
        status === 'active'
            ? 10 ** 13 + sortDate.getTime()
            : 10 ** 13 - sortDate.getTime();

    const sortString = `${status === 'active' ? 'a' : 'b'}|${plan.patient?.name?.toLocaleLowerCase()}|${sortDateString}`;

    const preventionPlan = preventionPlans.find(
        (searchPlan) => searchPlan.id === plan.preventionPlan?.id,
    );

    return {
        ...plan,
        status,
        autoRenewDate,
        formattedStartDate: format(startDate, 'MMM dd, yyyy'),
        formattedEndDate: format(endDate, 'MMM dd, yyyy'),
        formattedAutoRenewDate: format(autoRenewDate, 'MMM dd, yyyy'),
        withinCancellationPeriod:
            new Date().getTime() <= cancellationPeriodLimit.getTime(),
        someItemsRedeemed,
        sortString,
        preventionPlan: {
            ...plan.preventionPlan,
            publicName: preventionPlan?.publicName ?? plan.preventionPlan?.name,
        },
    } as DecoratedPatientPlan;
}
