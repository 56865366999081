import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { JwtPayload } from 'jwt-decode';
import { Role } from '@bondvet/roles';
import type { TokenWithoutRolesResult } from '@bondvet/types/auth';

const BOND_AUTH_TOKEN_KEY = 'bond_auth_token';

export type TokenPayload = {
    roles: Role[];
    iat: number;
    exp: number;
    sub: string;
};

export function setBondAuthToken(bondAuthToken: string): void {
    sessionStorage.setItem(BOND_AUTH_TOKEN_KEY, bondAuthToken || '');
}

export function getBondAuthToken(): string | null {
    return sessionStorage.getItem(BOND_AUTH_TOKEN_KEY) || null;
}

export async function isStillValid(token: string): Promise<boolean> {
    const { default: jwtDecode } = await import('jwt-decode');

    const { exp } = jwtDecode<JwtPayload>(token);

    if (exp === undefined) {
        // token doesn't expire
        return true;
    }

    // "exp" field is the UNIX timestamp in seconds
    // let's make sure it's valid for at least another minute
    const limit = Math.floor(Date.now() / 1000) + 60;

    return limit <= exp;
}

async function exchangeVetspireAuthToken(token: string): Promise<string> {
    // eslint-disable-next-line import/no-cycle
    const { default: apiClient } = await import('./apiClient');
    type QueryResult =
        import('api/bond/queries').ExchangeVetspireAuthTokenQueryResult;
    const { exchangeVetspireAuthToken: query } = await import(
        'api/bond/queries'
    );

    const result = await apiClient.query<QueryResult>({
        query,
        variables: { token, __noAuth: true },
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        fetchPolicy: 'network-only',
    });

    const { data, errors, error } = result;

    if (errors) {
        throw errors[0];
    }

    if (error) {
        throw error;
    }

    return data.exchangeVetspireAuthToken.token;
}

export async function getValidBondAuthToken(): Promise<string> {
    const token = getBondAuthToken();

    if (token) {
        const isValid = await isStillValid(token);

        if (isValid) {
            return token;
        }
    }

    const { getVetspireAuthToken } = await import('./vetspireAuth');
    const vetspireToken = getVetspireAuthToken();

    if (vetspireToken) {
        // we assume that it is still valid
        const newToken = await exchangeVetspireAuthToken(vetspireToken);

        setBondAuthToken(newToken);

        return newToken;
    }

    throw new Error('error.noVetspireAuthToken');
}

export async function getValidBondAuthTokenWithoutRoles(): Promise<string> {
    const { default: apiClient } = await import('./apiClient');

    const { tokenWithoutRoles: query } = await import('api/bond/queries');

    const result = await apiClient.query<TokenWithoutRolesResult>({
        query,
        context: { clientName: GRAPHQL_CLIENT_NAMES.auth },
        fetchPolicy: 'network-only',
    });

    const { data, errors, error } = result;

    if (errors) {
        throw errors[0];
    }

    if (error) {
        throw error;
    }

    return data.tokenWithoutRoles.token;
}

export async function getBondRoles(): Promise<Role[]> {
    const { default: jwtDecode } = await import('jwt-decode');

    const token = await getValidBondAuthToken();
    if (!token) {
        return [];
    }

    const payload = jwtDecode<TokenPayload>(token);

    return payload.roles || [];
}
