import * as React from 'react';

import { CreditCardControls } from 'pages/Telehealth/hooks/useCreditCardForm/types';

import styles from '../NewVisitForm.module.scss';

interface NewCardProps {
    cardControls: CreditCardControls;
}

function NewCard({ cardControls }: NewCardProps): React.ReactElement {
    const { cardNumberRef, cardCvcRef, cardExpiryRef } = cardControls;

    return (
        <li key="add-new-card">
            <div className={styles.newCard}>
                <div id="card-number" ref={cardNumberRef} />
                <div id="card-expiry" ref={cardExpiryRef} />
                <div id="card-cvc" ref={cardCvcRef} />
            </div>
        </li>
    );
}

export default NewCard;
