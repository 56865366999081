import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { BondLocationsSelect } from 'components/LookupSelect';
import { locationsQuery, LocationsQueryResult } from 'api/bond/queries';
import { Location } from '@bondvet/types/locations';
import GeneralError from 'components/GeneralError/GeneralError';
import DaySelect from 'components/DaySelect';
import useViewerSettings, {
    ViewerSettingsKey,
    ViewerSettingsNS,
} from 'hooks/useViewerSettings';
import CircularProgress from '@mui/material/CircularProgress';
import { getVetspireLocationId } from 'context/VetspireContext';
import CheckBox from 'components/CheckBox/CheckBox';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import styles from './AppointmentLog.module.scss';
import AppointmentLogFilter from './components/AppointmentLogFilter';
import { AppointmentLogFilterType } from './components/AppointmentLogFilter/types';
import AppointmentLogItem from './components/AppointmentLogItem';
import useAppointmentLogs from './hooks/useAppointmentLogs';
import { UserTypeSelectOption } from './types';
import AppointmentLogUserTypeSelect from './components/AppointmentLogUserTypeSelect/AppointmentLogUserTypeSelect';
import useBlockOffLogs from './hooks/useBlockOffLogs';
import BlockOffLogItem from './components/BlockOffLogItem';
import WarningMessage from './components/WarningMessage';

const AppointmentLog: React.FunctionComponent = () => {
    const translate = useTranslate();

    const [sameDayChangesOnly, setSameDayChangesOnly] =
        React.useState<boolean>(false);
    const [selectedLocations, setSelectedLocations] = React.useState<
        Location[]
    >([]);
    const [selectedUserTypes, setSelectedUserTypes] = React.useState<
        UserTypeSelectOption[]
    >([]);

    const [
        currentAppointmentLogFilterType,
        setCurrentAppointmentLogFilterType,
    ] = React.useState<AppointmentLogFilterType>(AppointmentLogFilterType.all);

    const viewerSettings = useViewerSettings();

    const locationsQueryResult = useQuery<LocationsQueryResult>(
        locationsQuery,
        {
            fetchPolicy: 'network-only',
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );

    React.useEffect(() => {
        if (locationsQueryResult.data?.locations) {
            const location = locationsQueryResult.data?.locations.filter(
                (loc) => loc._vetspire?.id === getVetspireLocationId(),
            );
            if (location && location.length > 0) {
                setSelectedLocations([location[0]]);
            }
        }
    }, [locationsQueryResult.data?.locations]);

    const [selectedDay, setSelectedDay] = React.useState<Moment>(moment.tz());

    const locationIds = React.useMemo(
        () =>
            selectedLocations
                .map((location) => location._vetspire?.id)
                .filter((location) => !!location) as string[],
        [selectedLocations],
    );

    const appointmentsQuery = useAppointmentLogs(
        selectedDay,
        locationIds,
        sameDayChangesOnly,
        selectedUserTypes,
    );

    const blockOffsQuery = useBlockOffLogs(
        selectedDay,
        locationIds,
        sameDayChangesOnly,
    );

    const onChangeLocations = (locations: Location[]) => {
        viewerSettings.setItem<string[]>(
            ViewerSettingsNS.appointmentLog,
            ViewerSettingsKey.selectedLocationIds,
            locationIds,
        );
        setSelectedLocations(locations);
    };

    const onChangeDay = (newDay: Moment): void => {
        setSelectedDay(newDay);
    };

    const appointmentLogsComponents = React.useMemo(
        () =>
            appointmentsQuery.data.appointmentLog.map((appointmentLog) => (
                <AppointmentLogItem
                    key={appointmentLog._id}
                    selectedDay={selectedDay}
                    currentFilter={currentAppointmentLogFilterType}
                    appointmentLog={appointmentLog}
                    sameDayChangesOnly={sameDayChangesOnly}
                    selectedUserTypes={selectedUserTypes}
                />
            )),
        [
            appointmentsQuery.data.appointmentLog,
            currentAppointmentLogFilterType,
            selectedDay,
            sameDayChangesOnly,
            selectedUserTypes,
        ],
    );

    const blockOffLogsComponents = React.useMemo(
        () =>
            blockOffsQuery.data.blockOffLog.map((blockOffLog) => (
                <BlockOffLogItem
                    key={blockOffLog._id}
                    selectedDay={selectedDay}
                    currentFilter={currentAppointmentLogFilterType}
                    blockOffLog={blockOffLog}
                    sameDayChangesOnly={sameDayChangesOnly}
                />
            )),
        [
            blockOffsQuery.data,
            currentAppointmentLogFilterType,
            selectedDay,
            sameDayChangesOnly,
        ],
    );

    if (locationsQueryResult.error) {
        return <GeneralError message={locationsQueryResult.error.message} />;
    }

    if (appointmentsQuery.error) {
        return <GeneralError message={appointmentsQuery.error.message} />;
    }

    return (
        <div className={styles.container}>
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.apptLog.title')}
                </div>
            </div>
            <div className={styles.flyoutBlock}>
                {locationsQueryResult && (
                    <BondLocationsSelect
                        locationsQueryResult={locationsQueryResult}
                        selectedLocations={selectedLocations}
                        onChange={onChangeLocations}
                    />
                )}
            </div>
            <div className={styles.flyoutBlock}>
                <AppointmentLogUserTypeSelect
                    selectedUserTypes={selectedUserTypes}
                    setSelectedUserTypes={setSelectedUserTypes}
                />
            </div>
            <div className={styles.flyoutBlock}>
                <DaySelect day={selectedDay} onChange={onChangeDay} />
            </div>
            <div className={styles.flyoutBlock}>
                <CheckBox
                    label={translate(
                        'vetspireExtension.apptLog.sameDayChangesOnly',
                    )}
                    value={sameDayChangesOnly}
                    onChange={setSameDayChangesOnly}
                />
            </div>
            <div
                className={classnames(
                    styles.flyoutBlockFullWidth,
                    styles.noHorizontal,
                )}
            >
                <AppointmentLogFilter
                    currentAppointmentLogFilterType={
                        currentAppointmentLogFilterType
                    }
                    appointmentLogStats={{
                        all: appointmentsQuery.data.all,
                        cancelled: appointmentsQuery.data.cancelled,
                        rescheduled: appointmentsQuery.data.rescheduled,
                        noShow: appointmentsQuery.data.noShow,
                        blockoff: blockOffsQuery.data.blockOffCount,
                    }}
                    translatePrefix="vetspireExtension.apptLog.apptLogCategories"
                    onChange={(cancelFilterType) => {
                        setCurrentAppointmentLogFilterType(cancelFilterType);
                    }}
                />
            </div>
            <div
                className={classnames(
                    styles.flyoutBlock,
                    styles.noVertical,
                    styles.noHorizontal,
                    styles.apptLogItemList,
                )}
            >
                {(appointmentsQuery.loading ||
                    blockOffsQuery.loading ||
                    viewerSettings.loading) && (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                )}

                <WarningMessage
                    key="warningMessage"
                    appointmentsQuery={appointmentsQuery}
                    blockOffsQuery={blockOffsQuery}
                    currentAppointmentLogFilterType={
                        currentAppointmentLogFilterType
                    }
                />

                {currentAppointmentLogFilterType ===
                AppointmentLogFilterType.blockoff
                    ? blockOffLogsComponents
                    : appointmentLogsComponents}
            </div>
        </div>
    );
};

export default AppointmentLog;
