import { gql } from '@apollo/client';
import useLazySukiQuery from 'hooks/useLazySukiQuery';
import useSukiQuery from 'hooks/useSukiQuery';
import {
    SukiAmbientSessionsQueryArguments,
    SukiAmbientSessionsQueryResult,
} from '@bondvet/types/suki';

const query = gql`
    query sukiAmbientSessions(
        $_id: ID
        $vetspireProviderId: ID
        $from: DateTime
        $to: DateTime
        $types: [SukiAmbientSessionType]
        $statuses: [SukiAmbientSessionStatus]
    ) {
        sukiAmbientSessions(
            _id: $_id
            vetspireProviderId: $vetspireProviderId
            from: $from
            to: $to
            types: $types
            statuses: $statuses
        ) {
            _id
            type
            status
            vetspireClientId
            vetspirePatientId
            vetspireEncounterId
            startedAt
            finishedAt
            processedAt
        }
    }
`;

export default function useSukiAmbientSessionsQuery(
    variables: SukiAmbientSessionsQueryArguments,
) {
    return useSukiQuery<
        SukiAmbientSessionsQueryResult,
        SukiAmbientSessionsQueryArguments
    >(query, { variables, fetchPolicy: 'cache-and-network' });
}

export function useLazySukiAmbientSessionsQuery() {
    return useLazySukiQuery<
        SukiAmbientSessionsQueryResult,
        SukiAmbientSessionsQueryArguments
    >(query, { fetchPolicy: 'no-cache' });
}
