import * as React from 'react';
import {
    FirestoreProvider,
    PROVIDERS_COLLECTION_NAME,
} from '@bondvet/types/telehealth';
import { onSnapshot, query } from 'firebase/firestore';

import useFirebaseContext from 'hooks/firebase/useFirebaseContext';

type UseActiveProvidersType = {
    activeProviderIds: string[];
};

export default function useActiveProviders(): UseActiveProvidersType {
    const [activeProviderIds, setActiveProviderIds] = React.useState<string[]>(
        [],
    );

    const { getCollection } = useFirebaseContext();

    React.useEffect(() => {
        let unsubscribe = () => {};

        const loadProviders = async () => {
            const providersCollection = await getCollection(
                PROVIDERS_COLLECTION_NAME,
            );

            if (providersCollection) {
                const providerQuery = query(providersCollection);

                unsubscribe = await onSnapshot(providerQuery, (snapshot) => {
                    const providerIds: string[] = [];

                    snapshot.forEach((doc) => {
                        const data = doc.data() as FirestoreProvider;

                        if (data.active) providerIds.push(data.vetspireId);
                    });
                    setActiveProviderIds(providerIds.sort());
                });
            }
        };
        loadProviders().then();

        return unsubscribe;
    }, [getCollection]);

    return { activeProviderIds };
}
