import * as React from 'react';
import useProviders from 'hooks/useProviders';
import { CREDIT_MEMO_OPTION_CASH } from '@bondvet/types/creditLedger';
import useVetspireMutation from 'hooks/useVetspireMutation';
import useVetspireQuery from 'hooks/useVetspireQuery';
import useBondQuery from 'hooks/useBondQuery';
import useBondMutation from 'hooks/useBondMutation';
import {
    Credit,
    creditTransactions,
    CreditTransactionQueryResult,
    ClientAccountCreditQueryResult,
    ClientAccountCreditQueryVariables,
    clientAccountCredit,
    PatientTagsQueryResult,
    PatientTagsQueryVariables,
    patientTagsQuery,
} from 'api/bond/queries';
import {
    addCreditMemo as addCreditMemoMutation,
    updatePatientTags as updatePatientTagsMutation,
    UpdatePatientTagsResult,
    UpdatePatientTagsVariables,
} from 'api/clients/mutations';
import { importSingleCreditTransaction as importCreditMemosMutation } from 'api/bond/mutations';
import type { Provider } from 'api/providers/queries';
import moment from 'moment-timezone';
import useTimezone from 'hooks/useTimezone';
import uniq from 'lodash/uniq';
import useLazyVetspireQuery from 'hooks/useLazyVetspireQuery';
import useCashCredit from './useCashCredit';

function mapCredit(
    { date, amount, notes, ...credit }: Credit,
    timezone: string,
): Credit {
    const newDate = date ? new Date(+date).toISOString() : null;
    const newNotes = notes.includes('|') ? notes.split('|')[0] : notes;
    return {
        ...credit,
        amount,
        notes: newNotes,
        date: date
            ? moment(newDate, moment.ISO_8601)
                  .tz(timezone)
                  .format('MMM DD, YYYY')
            : null,
    };
}

interface CreditMemos {
    credits: ReadonlyArray<Credit>;
    providers: ReadonlyArray<Provider>;
    isPolling: boolean;
    isLoading: boolean;
    currentTotal: number;
    updateCurrentTotal: (newTotal: number) => void;
    addCredit: (amount: number, locationId: string, note: string) => void;
    addPatientTag: (patientId: string, tagId: string) => void;
}

export default function useCreditMemos(clientId: string): CreditMemos {
    const [credits, setCredits] = React.useState<ReadonlyArray<Credit>>([]);
    const addCashCredit = useCashCredit(clientId);
    const [transactionCount, setTransactionCount] = React.useState(0);
    const timezone = useTimezone();
    const [isPolling, setIsPolling] = React.useState(false);
    const [currentTotal, setCurrentTotal] = React.useState(0);
    const { data, loading, startPolling, stopPolling } =
        useBondQuery<CreditTransactionQueryResult>(creditTransactions, {
            fetchPolicy: 'cache-and-network',
            variables: { clientId },
        });

    const { providers } = useProviders(
        undefined,
        undefined,
        'cache-and-network',
    );

    const {
        data: clientAccountCreditData,
        refetch: refetchClientAccountCredit,
    } = useVetspireQuery<
        ClientAccountCreditQueryResult,
        ClientAccountCreditQueryVariables
    >(clientAccountCredit, {
        variables: { clientId },
        fetchPolicy: 'cache-and-network',
    });

    const [runPatientTagsQuery] = useLazyVetspireQuery<
        PatientTagsQueryResult,
        PatientTagsQueryVariables
    >(patientTagsQuery, {
        fetchPolicy: 'cache-and-network',
    });

    const updateCurrentTotal = React.useCallback((newTotal: number) => {
        setCurrentTotal(Math.floor(newTotal * 100));
    }, []);

    React.useEffect(() => {
        const decimal = clientAccountCreditData?.client?.accountCredit;

        if (decimal) {
            updateCurrentTotal(parseFloat(decimal));
        } else {
            setCurrentTotal(0.0);
        }
    }, [clientAccountCreditData, updateCurrentTotal]);

    React.useEffect(() => {
        if (isPolling) {
            startPolling(500);
        } else {
            stopPolling();
        }
    }, [isPolling, stopPolling, startPolling]);

    React.useEffect(() => {
        if (data?.creditTransactions && providers.length > 0) {
            const newCredits = [...data.creditTransactions];
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setCredits(
                        newCredits.map<Credit>((credit) =>
                            mapCredit(credit, timezone),
                        ),
                    );
                });
            });
            if (transactionCount !== data.creditTransactions.length) {
                setTransactionCount(data.creditTransactions.length);
                setIsPolling(false);
            }
        }
    }, [
        data?.creditTransactions,
        providers,
        timezone,
        stopPolling,
        transactionCount,
    ]);

    const [addCreditMemo] = useVetspireMutation(addCreditMemoMutation);
    const [updatePatientTags] = useVetspireMutation<
        UpdatePatientTagsResult,
        UpdatePatientTagsVariables
    >(updatePatientTagsMutation);

    const [importSingleCreditTransaction] = useBondMutation(
        importCreditMemosMutation,
    );

    const addCredit = React.useCallback(
        (amount: number, locationId: string, note: string) => {
            const seconds = 30;
            const [since] = new Date(Date.now() - seconds * 1000)
                .toISOString()
                .split('T');

            const promise =
                note === CREDIT_MEMO_OPTION_CASH
                    ? addCashCredit(amount, locationId)
                    : addCreditMemo({
                          variables: { clientId, amount, locationId, note },
                      });

            promise
                .then(() => {
                    setIsPolling(true);

                    return refetchClientAccountCredit();
                })
                .then(() =>
                    importSingleCreditTransaction({
                        variables: { since, clientId },
                    }),
                );
        },
        [
            clientId,
            addCreditMemo,
            importSingleCreditTransaction,
            refetchClientAccountCredit,
            addCashCredit,
        ],
    );

    const addPatientTag = React.useCallback(
        (patientId: string, tagId: string) => {
            runPatientTagsQuery({
                variables: { patientId },
            }).then(({ data: existingPatientTagData, error }) => {
                if (error || !existingPatientTagData?.patient?.tags) {
                    return;
                }
                const newPatientTags = uniq([
                    ...existingPatientTagData.patient.tags.map(({ id }) => id),
                    tagId,
                ]);
                updatePatientTags({
                    variables: {
                        patientId,
                        patientTagIds: newPatientTags,
                    },
                }).then();
            });
        },
        [runPatientTagsQuery, updatePatientTags],
    );

    return {
        credits,
        providers,
        addCredit,
        addPatientTag,
        isPolling,
        isLoading: loading,
        currentTotal,
        updateCurrentTotal,
    };
}
