import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import { sendSurgeryReferralContext } from '../../context';
import styles from '../QuestionForm/QuestionForm.module.scss';

export default function Notes(): React.ReactElement {
    const { notes, changeNotes, surgeryType } = React.useContext(
        sendSurgeryReferralContext,
    );

    const handleChange = React.useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
            changeNotes(value);
        },
        [changeNotes],
    );

    const translate = useTranslate();

    return (
        <fieldset className={styles.container}>
            <label htmlFor="notes" className={styles.label}>
                {translate('vetspireExtension.surgeryReferrals.send.notes')}
                {surgeryType?.notesRequired ? '*' : ''}
            </label>
            <textarea
                id="notes"
                rows={4}
                onChange={handleChange}
                value={notes}
            />
        </fieldset>
    );
}
