import { RectangleProps } from 'recharts';
import * as React from 'react';

export const CustomTargetShape = ({
    fill,
    x,
    y,
    width,
    target,
}: RectangleProps & { target?: number }) => {
    if (!target || x == null || y == null) return null;

    return (
        <>
            <line
                x1={x}
                y1={y + 1}
                x2={x + (width || 0)}
                y2={y + 1}
                strokeDasharray="4"
                strokeWidth={2}
                stroke={fill}
            />
        </>
    );
};
