import * as React from 'react';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import InfoIcon from '@mui/icons-material/Info';
import { formatInTimeZone } from 'date-fns-tz';
import classnames from 'classnames';

import { ConversationalSmsMessage } from '@bondvet/types/textMessaging';
import flatMap from 'lodash/flatMap';
import split from 'lodash/split';

import navBond from 'assets/navBond.png';
import useTimezone from 'hooks/useTimezone';
import { useTranslate } from '@bondvet/web-app-i18n/util';

import TextingTooltip from '../TextingTooltip';
import styles from './Message.module.scss';

type MessageProps = {
    message: ConversationalSmsMessage;
    onImageClick: (url: string) => void;
    scrollToMessageRef: React.MutableRefObject<
        HTMLDivElement | undefined
    > | null;
};
export default function Message({
    message,
    onImageClick,
    scrollToMessageRef,
}: MessageProps): React.ReactElement {
    const timezone = useTimezone();
    const translate = useTranslate();

    const {
        datetime,
        type,
        direction,
        proactive,
        botMessage,
        fromName,
        bondLocation,
        body,
    } = message;

    const formattedSentAt = formatInTimeZone(
        datetime.toString(),
        timezone,
        "MMMM d, yyyy 'at' h:mma",
    );

    const formattedBody = React.useMemo(() => {
        const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/gi;

        const parts = split(body, urlPattern);
        const links = body.match(urlPattern) ?? [];

        return (
            <p className={styles.body}>
                {flatMap(parts, (part, index) => (
                    <React.Fragment key={index}>
                        {part}
                        {links[index] && (
                            <a
                                href={links[index]}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {`${links[index].slice(0, 50)}${links[index].length > 50 ? '...' : ''}`}
                            </a>
                        )}
                    </React.Fragment>
                ))}
            </p>
        );
    }, [body]);

    if (type === 'conversationBreak') {
        return (
            <div
                ref={scrollToMessageRef as React.LegacyRef<HTMLDivElement>}
                id={message._id}
                className={classnames(styles.root, styles.conversationBreak)}
            >
                <hr className={styles.line} />
                <div className={styles.banner}>
                    {translate(
                        'vetspireExtension.texting.conversationPane.conversationBreakNote',
                    )}
                    <TextingTooltip
                        title={translate(
                            'vetspireExtension.texting.conversationPane.conversationBreakInfo',
                        )}
                        placement="top"
                    >
                        <InfoIcon className={styles.info} />
                    </TextingTooltip>
                </div>
            </div>
        );
    }

    return (
        <div
            ref={scrollToMessageRef as React.LegacyRef<HTMLDivElement>}
            id={message._id}
            className={classnames(styles.root, {
                [styles.left]: direction === 'in',
                [styles.right]: direction === 'out' || direction === 'internal',
                [styles.internalNote]: type === 'internalNote',
                [styles.pending]: type === 'pending',
            })}
        >
            <div
                className={classnames(styles.from, {
                    [styles.red]: botMessage && direction === 'out',
                })}
            >
                {botMessage && direction === 'out' && (
                    <img className={styles.logo} src={navBond} alt="Bond Vet" />
                )}
                {fromName}
                {(proactive || type === 'pending') && (
                    <FlashOnIcon className={styles.templateIcon} />
                )}
            </div>
            {type === 'pending' ? (
                <div className={styles.pendingBlurb}>
                    {translate(
                        'vetspireExtension.texting.conversationPane.messageMeta.pendingBlurb',
                    )}
                </div>
            ) : (
                <div className={styles.sentAt}>{formattedSentAt}</div>
            )}
            <div className={styles.location}>
                {direction === 'in' && (
                    <span>
                        {translate(
                            'vetspireExtension.texting.conversationPane.messageMeta.to',
                        )}
                    </span>
                )}
                {direction === 'out' && (
                    <span>
                        {translate(
                            'vetspireExtension.texting.conversationPane.messageMeta.out',
                        )}
                    </span>
                )}
                {direction === 'internal' && (
                    <span>
                        {translate(
                            'vetspireExtension.texting.conversationPane.messageMeta.internalBlurb',
                        )}
                    </span>
                )}
                {botMessage && (
                    <span>
                        <img
                            className={styles.logo}
                            src={navBond}
                            alt="Bond Vet"
                        />
                    </span>
                )}
                <span
                    className={classnames({
                        [styles.red]: botMessage,
                    })}
                >
                    {bondLocation}
                </span>
            </div>
            <hr
                className={classnames(styles.divider, {
                    [styles.internalNote]: type === 'internalNote',
                })}
            />
            {(type === 'image' || type === 'pending') && message.imageSrc && (
                <img
                    onClick={() => onImageClick(message.imageSrc || '')}
                    className={styles.imageBody}
                    src={message.imageSrc}
                    alt={message.imageSrc}
                />
            )}
            {formattedBody ?? null}
        </div>
    );
}
