import * as React from 'react';
import { GraphQLGeneralSurgeryReferralQuestion } from '@bondvet/types/surgeryReferrals';
import { useQuery } from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { generalQuestions, GeneralQuestionsQuery } from '../api/queries';

type GeneralQuestions = {
    loading: boolean;
    questions: ReadonlyArray<GraphQLGeneralSurgeryReferralQuestion>;
};

export default function useGeneralQuestions(): GeneralQuestions {
    const { loading, data } = useQuery<GeneralQuestionsQuery>(
        generalQuestions,
        {
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );

    const questions = React.useMemo(
        () => data?.generalSurgeryReferralQuestions || [],
        [data?.generalSurgeryReferralQuestions],
    );

    return { questions, loading };
}
