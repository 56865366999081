import * as React from 'react';
import {
    OptionsQuestion,
    SurgeryReferralRadiosAnswer,
} from '@bondvet/types/surgeryReferrals';
import { InputProps } from './types';
import styles from './QuestionForm.module.scss';

interface RadiosInputProps extends InputProps {
    value: SurgeryReferralRadiosAnswer['answer'];
    options: OptionsQuestion['options'];
}

export default function RadiosInput({
    value,
    onChange,
    id,
    options,
}: RadiosInputProps): React.ReactElement {
    const handleChange = React.useCallback(
        ({
            target: { value: newValue },
        }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(newValue);
        },
        [onChange],
    );
    return (
        <ul className={styles.radios}>
            {options.map((option) => {
                const optionId = `${id}_${option.replace(/[^\w]+/g, '-')}`;

                return (
                    <li key={option}>
                        <input
                            type="radio"
                            value={option}
                            checked={value === option}
                            onChange={handleChange}
                            id={optionId}
                        />
                        <label htmlFor={optionId}>{option}</label>
                    </li>
                );
            })}
        </ul>
    );
}
