import * as React from 'react';
import { gql } from '@apollo/client';
import { OperationResult } from '@bondvet/types';
import { Page } from 'lib/vetspireActions';
import { Services } from '@bondvet/types/common';
import { AnalyticsEventInput } from '@bondvet/types/analytics';
import useAnalyticsMutation from './useAnalyticsMutation';
import useViewerSettings from './useViewerSettings';

export type TrackEventResult = {
    trackEvent: OperationResult;
};

export const trackEventMutation = gql`
    mutation trackEvent($event: EventInput!) {
        trackEvent(event: $event) {
            success
            error
        }
    }
`;

type UseAnalytics = {
    trackEvent: (page: Page | string, event: string, data?: unknown) => void;
};

type AnalyticsInput = {
    event: AnalyticsEventInput;
};

export function useAnalytics(): UseAnalytics {
    const viewerSettings = useViewerSettings();
    const [runTrackEvent] = useAnalyticsMutation<
        TrackEventResult,
        AnalyticsInput
    >(trackEventMutation, {});

    const trackEvent = React.useCallback(
        (page: string, event: string, data?: unknown) => {
            if (process.env.REACT_APP_DISABLE_TRACKING === 'true') {
                // eslint-disable-next-line no-console
                console.debug(
                    'skipped trackEvent call on page "%s" for event "%s"',
                    page,
                    event,
                );
            } else {
                runTrackEvent({
                    variables: {
                        event: {
                            providerId: viewerSettings.viewer?.id,
                            component: Services.vetspireExtension,
                            page,
                            event,
                            data_json: data ? JSON.stringify(data) : null,
                        },
                    },
                }).then((result) => {
                    if (!result.data?.trackEvent?.success) {
                        console.error(
                            'analytics.trackEvent error: ',
                            result.data?.trackEvent?.error,
                        );
                    }
                });
            }
        },
        [runTrackEvent, viewerSettings.viewer?.id],
    );

    return {
        trackEvent,
    };
}
