import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import vetspireLink from './vetspireLink';
// eslint-disable-next-line import/no-cycle
import bondLink from './bondLink';

const apiClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.split(
        (operation) =>
            !operation.getContext().clientName ||
            operation.getContext().clientName === 'vetspire',
        vetspireLink,
        bondLink,
    ),
});

export default apiClient;
