import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import classnames from 'classnames';
import { UpdateConversationalSubscriptionStatusArguments } from '@bondvet/types/textMessaging';
import useTextMessagingMutation from 'hooks/useTextMessagingMutation';
import useViewerSettings from 'hooks/useViewerSettings';
import {
    updateConversationalSubscriptionStatusMutation,
    UpdateConversationalSubscriptionStatusResult,
} from 'api/texting/mutations';
import type { ClientWithPhoneNumber } from '../../api';
import styles from './SmsConversationalOptIn.module.scss';

export type Client = Omit<ClientWithPhoneNumber, 'optedIn'>;

export type SmsConversationalOptInProps = {
    onClose: () => void;
    onSubmit: () => void;
    client: Client;
};

export default function SmsConversationalOptIn({
    onClose,
    onSubmit,
    client,
}: SmsConversationalOptInProps) {
    const translate = useTranslate();
    const viewerSettings = useViewerSettings();
    const [employeeName, setEmployeeName] = React.useState('');
    const [error, setError] = React.useState<string | null>(null);
    const [nameError, setNameError] = React.useState<string | null>(null);
    const [update, { loading }] = useTextMessagingMutation<
        UpdateConversationalSubscriptionStatusResult,
        UpdateConversationalSubscriptionStatusArguments
    >(updateConversationalSubscriptionStatusMutation, {
        onCompleted: (data) => {
            if (data?.updateConversationalSubscriptionStatus?.success) {
                onSubmit();
            } else {
                setError(
                    data?.updateConversationalSubscriptionStatus?.error ||
                        'Internal Error',
                );
            }
        },
        onError: (apolloError) => {
            setError(apolloError?.message || 'Internal Error');
        },
    });

    const handleSubmit = () => {
        if (!employeeName) {
            setNameError('vetspireExtension.texting.optInModal.nameError');
        } else {
            setNameError(null);
            update({
                variables: {
                    input: {
                        clientId: client.id,
                        userId: viewerSettings.viewer?.id ?? '',
                        userName: employeeName,
                        action: 'subscribe',
                    },
                },
            });
        }
    };

    return (
        <>
            <CloseIcon
                className={styles.close}
                onClick={() => {
                    onClose();
                }}
            />
            <div className={styles.content}>
                <h2 className={styles.title}>
                    {translate('vetspireExtension.texting.optInModal.title')}
                </h2>
                <div className={styles.disclaimer}>
                    <h4 className={styles.disclaimerTitle}>
                        {translate(
                            'vetspireExtension.texting.optInModal.disclaimer.title',
                        )}
                    </h4>
                    <ul className={styles.disclaimerList}>
                        <li>
                            {translate(
                                'vetspireExtension.texting.optInModal.disclaimer.text1',

                                {},
                                {
                                    renderInnerHtml: true,
                                },
                            )}
                        </li>
                        <li>
                            {translate(
                                'vetspireExtension.texting.optInModal.disclaimer.text2',
                                {},
                                {
                                    renderInnerHtml: true,
                                },
                            )}
                        </li>
                        <li>
                            {translate(
                                'vetspireExtension.texting.optInModal.disclaimer.text3',
                                {},
                                {
                                    renderInnerHtml: true,
                                },
                            )}
                        </li>
                    </ul>
                </div>
                <h4 className={styles.agreementTitle}>
                    {translate(
                        'vetspireExtension.texting.optInModal.agreement.title',
                    )}
                </h4>
                <div className={styles.agreementText}>
                    {translate(
                        'vetspireExtension.texting.optInModal.agreement.text',
                        {
                            input: (
                                <input
                                    className={classnames(styles.input, {
                                        [styles.error]: !!nameError,
                                    })}
                                    placeholder={'Your name here'}
                                    value={employeeName}
                                    onChange={(event) => {
                                        setNameError(null);
                                        setEmployeeName(event.target.value);
                                    }}
                                />
                            ),
                        },
                    )}
                </div>
                {nameError && (
                    <div className={styles.nameError}>
                        {translate(nameError)}
                    </div>
                )}
                <div className={styles.agreementData}>
                    <div className={styles.column}>
                        <h5 className={styles.columnTitle}>
                            {translate(
                                'vetspireExtension.texting.optInModal.agreement.employee',
                            )}
                        </h5>
                        <div className={styles.item}>
                            <span>
                                {translate(
                                    'vetspireExtension.texting.optInModal.agreement.name',
                                )}
                                :
                            </span>
                            <span className={styles.value}>{employeeName}</span>
                        </div>
                        <div className={styles.item}>
                            <span>
                                {translate(
                                    'vetspireExtension.texting.optInModal.agreement.date',
                                )}
                                :
                            </span>
                            <span className={styles.value}>
                                {format(new Date(), 'MM/dd/yyyy')}
                            </span>
                        </div>
                    </div>
                    <div className={styles.column}>
                        <h5 className={styles.columnTitle}>
                            {translate(
                                'vetspireExtension.texting.optInModal.agreement.client',
                            )}
                        </h5>
                        <div className={styles.item}>
                            <span>
                                {translate(
                                    'vetspireExtension.texting.optInModal.agreement.name',
                                )}
                                :
                            </span>
                            <span className={styles.value}>
                                {`${client.givenName} ${client.familyName}`}
                            </span>
                        </div>
                        <div className={styles.item}>
                            <span>
                                {translate(
                                    'vetspireExtension.texting.optInModal.agreement.phone',
                                )}
                                :
                            </span>
                            <span className={styles.value}>
                                {client.preferredPhoneNumber}
                            </span>
                        </div>
                    </div>
                </div>
                {error && <div className={styles.error}>{error}</div>}
            </div>
            <div className={styles.buttons}>
                <Button className={styles.cancel} onClick={onClose}>
                    {translate('vetspireExtension.texting.optInModal.cancel')}
                </Button>
                <Button
                    className={styles.submit}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {translate('vetspireExtension.texting.optInModal.save')}
                </Button>
            </div>
        </>
    );
}
