import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import WeekSelect from 'components/WeekSelect';
import { ProviderRightValue } from '@bondvet/types/providers';
import { StaffingRole } from '@bondvet/types/scheduling/staffing';
import {
    StaffingRolesSelect,
    BondLocationsSelectWithRegions,
} from 'components/LookupSelect';
import CheckBox from 'components/CheckBox/CheckBox';
import useViewerSettings, {
    ViewerSettingsKey,
    ViewerSettingsNS,
} from 'hooks/useViewerSettings';
import { useQuery } from '@apollo/client';
import {
    locationsQuery,
    LocationsQueryResult,
    regionsQuery,
    RegionsQueryResult,
} from 'api/bond/queries';
import { Location } from '@bondvet/types/locations';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import ActionButton from 'components/ActionButton';
import { StaffOptimizationOptions } from '../../types';
import styles from './Options.module.scss';
import NameFormatSelect from '../NameFormatSelect';

type StaffOptimizationValues = Pick<
    StaffOptimizationOptions,
    'nameFormat' | 'numbers' | 'surgeries' | 'colors' | 'issues'
>;

interface OptionsProps {
    options: StaffOptimizationOptions;
    onChange: (newOptions: StaffOptimizationOptions) => void;
    runReportDisabled: boolean;
    onRunReport: () => void;
}

const Options: React.FunctionComponent<OptionsProps> = ({
    options,
    onChange,
    runReportDisabled,
    onRunReport,
}) => {
    const translate = useTranslate();

    const viewerSettings = useViewerSettings();

    const [optionsRestored, setOptionsRestored] = React.useState(false);

    const onChangeWeek = (week: Moment) => {
        if (!options.loading) {
            viewerSettings.setItem<string>(
                ViewerSettingsNS.staffOptimization,
                ViewerSettingsKey.selectedWeek,
                week.toISOString(),
            );

            onChange({
                ...options,
                week,
            });
        }
    };

    const onChangeLocations = (locations: Location[]) => {
        if (!options.loading) {
            const locationIds = locations.map((location) => location._id);

            viewerSettings.setItem<string[]>(
                ViewerSettingsNS.staffOptimization,
                ViewerSettingsKey.selectedLocationIds,
                locationIds,
            );

            onChange({
                ...options,
                locationIds,
            });
        }
    };

    const onChangeStaffingRoles = (staffingRoles: StaffingRole[]) => {
        if (!options.loading) {
            viewerSettings.setItem<string[]>(
                ViewerSettingsNS.staffOptimization,
                ViewerSettingsKey.selectedStaffingRoles,
                staffingRoles,
            );

            onChange({
                ...options,
                staffingRoles,
            });
        }
    };

    const onChangeValue = (
        key: keyof StaffOptimizationValues,
        value: unknown,
    ) => {
        if (!options.loading) {
            const newOptions = {
                ...options,
                [key]: value,
            };

            viewerSettings.setItem<StaffOptimizationValues>(
                ViewerSettingsNS.staffOptimization,
                ViewerSettingsKey.selectedOptions,
                {
                    nameFormat: newOptions.nameFormat,
                    numbers: newOptions.numbers,
                    surgeries: newOptions.surgeries,
                    colors: newOptions.colors,
                    issues: newOptions.issues,
                },
            );

            onChange(newOptions);
        }
    };

    const locationsQueryResult = useQuery<LocationsQueryResult>(
        locationsQuery,
        {
            fetchPolicy: 'cache-first',
            context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        },
    );

    const regionsQueryResult = useQuery<RegionsQueryResult>(regionsQuery, {
        fetchPolicy: 'cache-first',
        context: { clientName: GRAPHQL_CLIENT_NAMES.analytics },
    });

    // restore options from localStorage as soon as all required queries are executed
    React.useEffect(() => {
        if (
            !viewerSettings.loading &&
            !viewerSettings.error &&
            !locationsQueryResult.loading &&
            !locationsQueryResult.error &&
            locationsQueryResult.data?.locations
        ) {
            if (!optionsRestored) {
                // restore options only once
                setOptionsRestored(true);

                const restoredOptions: StaffOptimizationOptions = {
                    ...viewerSettings.getItem<StaffOptimizationValues>(
                        ViewerSettingsNS.staffOptimization,
                        ViewerSettingsKey.selectedOptions,
                        options,
                    ),
                    locationIds: [],
                    staffingRoles: viewerSettings.getItem<StaffingRole[]>(
                        ViewerSettingsNS.staffOptimization,
                        ViewerSettingsKey.selectedStaffingRoles,
                        options.staffingRoles,
                    ),
                    week: options.week,
                    loading: false,
                };

                const storedWeek = moment(
                    viewerSettings.getItem<string>(
                        ViewerSettingsNS.staffOptimization,
                        ViewerSettingsKey.selectedWeek,
                        options.week?.toISOString() || '',
                    ),
                );

                if (storedWeek.isoWeekday() === options.week.isoWeekday()) {
                    // only restore week if it points to the correct day of week (as time of writing - sunday)
                    restoredOptions.week = storedWeek;
                }

                if (
                    viewerSettings.rights
                        ?.vetspireExtension_staffOptimizationUKG ===
                    ProviderRightValue.enabled_ownRecords
                ) {
                    restoredOptions.locationIds = [
                        viewerSettings.viewer?.defaultLocationId || '',
                    ];
                } else {
                    restoredOptions.locationIds = viewerSettings.getItem<
                        string[]
                    >(
                        ViewerSettingsNS.staffOptimization,
                        ViewerSettingsKey.selectedLocationIds,
                        options.locationIds,
                    );
                }

                onChange(restoredOptions);
            }
        }
    }, [
        locationsQueryResult,
        onChange,
        options,
        optionsRestored,
        viewerSettings,
    ]);

    return (
        <div className={styles.container}>
            <WeekSelect
                week={options.week}
                onChange={onChangeWeek}
                mode="picker"
            />

            <BondLocationsSelectWithRegions
                locationsQueryResult={locationsQueryResult}
                regionsQueryResult={regionsQueryResult}
                selectedLocations={
                    locationsQueryResult.data?.locations.filter((location) =>
                        options.locationIds.includes(location._id),
                    ) || []
                }
                onChange={onChangeLocations}
                filter={(location) => location.showStaffOptimization || false}
                isDisabled={
                    viewerSettings.rights === null ||
                    viewerSettings.rights
                        .vetspireExtension_staffOptimizationUKG !==
                        ProviderRightValue.enabled_allRecords
                }
                className={styles.item}
            />

            <StaffingRolesSelect
                selectedStaffingRoles={options.staffingRoles}
                onChange={onChangeStaffingRoles}
                enabledRoles={[
                    'manager',
                    'doctor',
                    'trainingDoctor',
                    'nurse',
                    'assistant',
                    'careCoordinator',
                    'surgeryDoctor',
                    'surgeryNurse',
                    'surgeryAssistant',
                ]}
                className={styles.item}
            />

            <NameFormatSelect
                selectedNameFormat={options.nameFormat}
                onChange={(newValue) => onChangeValue('nameFormat', newValue)}
                className={styles.item}
            />

            <div className={styles.checkboxColumn}>
                <CheckBox
                    label={translate(
                        'vetspireExtension.staffOptimization.numbers',
                    )}
                    value={options.numbers}
                    onChange={(newValue) => onChangeValue('numbers', newValue)}
                />
                <CheckBox
                    label={translate(
                        'vetspireExtension.staffOptimization.surgeries',
                    )}
                    value={options.surgeries}
                    onChange={(newValue) =>
                        onChangeValue('surgeries', newValue)
                    }
                />
            </div>

            <div className={styles.checkboxColumn}>
                <CheckBox
                    label={translate(
                        'vetspireExtension.staffOptimization.colors',
                    )}
                    value={options.colors}
                    onChange={(newValue) => onChangeValue('colors', newValue)}
                />
                <CheckBox
                    label={translate(
                        'vetspireExtension.staffOptimization.issues',
                    )}
                    value={options.issues}
                    onChange={(newValue) => onChangeValue('issues', newValue)}
                />
            </div>
            <div className={styles.item}>
                <ActionButton
                    onClick={onRunReport}
                    className={styles.runReportButton}
                    disabled={runReportDisabled}
                >
                    Run Report
                </ActionButton>
            </div>
        </div>
    );
};

export default Options;
