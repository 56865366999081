import * as React from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';

export function useStripe(): Stripe | null {
    const [stripe, setStripe] = React.useState<Stripe | null>(null);
    React.useEffect(() => {
        loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string).then(
            (stripeApi: Stripe | null) => {
                setStripe(stripeApi);
            },
        );
    }, []);

    return stripe || null;
}

export const initialCardState = {
    processing: false,
    processingError: '',
    success: false,
};
