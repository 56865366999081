// eslint-disable-next-line import/prefer-default-export
export function formatAmountAsUSD(
    amount: number,
    stripDecimalsOnIntegers = false,
): string {
    const formatted = (amount / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    if (stripDecimalsOnIntegers && formatted.endsWith('.00')) {
        return formatted.replace(/\.00$/, '');
    }

    return formatted;
}
