import { OperationResult } from '../common';
import { FEE_PRODUCT_TYPE_CANCEL, FEE_PRODUCT_TYPE_NO_SHOW } from '../noShows';
import { SETTINGS_PATH } from '../settings';

export const CREDIT_CARD_SETTINGS_PATH = `${SETTINGS_PATH}/creditCards`;

export const CREDIT_CARD_FUND_CHECK_SETTINGS_PATH = `${CREDIT_CARD_SETTINGS_PATH}/fundCheck/enabled`;
export const EXTENSION_FUND_CHECK_SETTINGS_PATH = `${CREDIT_CARD_SETTINGS_PATH}/extensionFundCheck/enabled`;

export const CREDIT_CARD_AMOUNTS_PATH = `${CREDIT_CARD_SETTINGS_PATH}/amounts`;
export const NO_SHOW_AMOUNT_PATH = `${CREDIT_CARD_AMOUNTS_PATH}/${FEE_PRODUCT_TYPE_NO_SHOW}`;
export const CANCEL_AMOUNT_PATH = `${CREDIT_CARD_AMOUNTS_PATH}/${FEE_PRODUCT_TYPE_CANCEL}`;

export enum VerifyCreditCardOrigin {
    extensionApp = 'extensionApp',
    website = 'website',
    selfServiceForm = 'selfServiceForm',
    clientApp = 'clientApp',
}

export const VERIFY_CREDIT_CARD_ORIGIN = Object.freeze(VerifyCreditCardOrigin);

export interface CreditCardFundsCheckSetting {
    enabled: boolean;
    amount: number;
}

export const CreditCardFundsCheckSettingGraphQLType = `
type CreditCardFundsCheckSetting {
    enabled: Boolean!
    amount: Int!
}
`;

export enum PaymentIntentReason {
    telehealth = 'telehealth',
    enrollPlan = 'enrollPlan',
}

export const PAYMENT_INTENT_REASON = Object.freeze(PaymentIntentReason);

export type ConfirmationHashArguments = {
    clientId: string;
    confirmationHash: string;
};

export type BaseVerifyCreditCardArguments = {
    sourceId: string;
};

export type VerifyCreditCardArguments = BaseVerifyCreditCardArguments & {
    clientId: string;
    markAsDefault?: boolean;
    origin?: VerifyCreditCardOrigin;
};

export type VerifyCreditCardResult = {
    verifyCreditCard: OperationResult;
};

export type VerifyCreditCardWithConfirmationHashArguments =
    BaseVerifyCreditCardArguments & ConfirmationHashArguments;

export type VerifyCreditCardWithConfirmationHashResult = {
    verifyCreditCardWithConfirmationHash: OperationResult;
};

export interface LocationPrice {
    productId: string;
    productName: string;
    locationId: string;
    locationName: string;
    price: number;
}

export interface FeeProductLocationPricesQueryArguments {
    feeProductId: string;
}

export interface FeeProductLocationPricesQueryResult {
    feeProductLocationPrices: readonly LocationPrice[];
}
