import * as React from 'react';
import {
    locationsQuery,
    LocationsQueryResult,
    Location,
} from 'api/locations/queries';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import {
    ProactiveMessagingVariable,
    TemplateVariableValue,
} from '@bondvet/types/textMessaging';
import get from 'lodash/get';
import classnames from 'classnames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import VariableInput from './VariableInput';
import styles from './VariableInput.module.scss';

interface LocationFieldsProps {
    inputRefs?: (key: string, ref: HTMLInputElement | null) => void;
    selectedLocationId: string | undefined;
    activeVariables: ReadonlyArray<ProactiveMessagingVariable>;
    onLocationChange: (newLocation: Location) => void;
    overrideValues: ReadonlyArray<TemplateVariableValue>;
    updateOverrideValues: (
        identifier: string,
        value: string,
        updateImmediately?: boolean,
    ) => void;
    errors?: {
        [key: string]: string | undefined;
    };
}

export default function LocationFields({
    inputRefs,
    selectedLocationId,
    activeVariables,
    overrideValues,
    updateOverrideValues,
    errors,
    onLocationChange,
}: LocationFieldsProps): React.ReactElement | null {
    const translate = useTranslate();
    const valid = !!selectedLocationId;
    const lastLocationId = React.useRef<string | null>(null);
    const { data, loading } = useQuery<LocationsQueryResult>(locationsQuery, {
        fetchPolicy: 'cache-first',
    });

    const locations = React.useMemo(
        () => (data?.locations || []).filter((l) => l.isActive),
        [data],
    );

    const handleOnChange = React.useCallback(
        (locationId: string) => {
            const location = (locations || []).find((l) => l.id === locationId);
            if (location) {
                onLocationChange(location);
            }
        },
        [locations, onLocationChange],
    );

    React.useEffect(() => {
        if (
            selectedLocationId &&
            lastLocationId.current !== selectedLocationId
        ) {
            const selectedLocation = locations.find(
                ({ id }) => selectedLocationId === id,
            );

            if (selectedLocation) {
                activeVariables.forEach(({ identifier, vetspirePath }) => {
                    if (vetspirePath) {
                        const newValue = get(
                            selectedLocation,
                            vetspirePath,
                            '',
                        );
                        updateOverrideValues(identifier, newValue, true);
                    }
                });
                lastLocationId.current = selectedLocationId;
            }
        }
    }, [activeVariables, locations, selectedLocationId, updateOverrideValues]);

    const renderVariable = React.useCallback(
        (variable: ProactiveMessagingVariable) => {
            const { identifier } = variable;

            const variableValue = overrideValues.find(
                (v) => v.identifier === identifier,
            );

            return (
                <li key={variable.identifier}>
                    <VariableInput
                        inputRef={(ref) =>
                            inputRefs?.(variable.identifier, ref)
                        }
                        variable={variable}
                        value={variableValue?.value || ''}
                        onChange={(newValue) =>
                            updateOverrideValues(variable.identifier, newValue)
                        }
                        error={errors?.[variable.identifier]}
                    />
                </li>
            );
        },
        [overrideValues, errors, inputRefs, updateOverrideValues],
    );

    if (activeVariables.length === 0) {
        return null;
    }

    if (locations && !loading) {
        return (
            <>
                <li>
                    <div className={classnames(styles.root, styles.buffer)}>
                        <div className={styles.input}>
                            <label>Clinic</label>
                            <Select
                                fullWidth
                                label="Location"
                                placeholder={
                                    translate(
                                        'vetspireExtension.texting.sendOutboundMessageModal.variables.selectLocationPlaceholder',
                                    ) as string
                                }
                                disabled={loading}
                                value={selectedLocationId ?? ''}
                                onChange={(event) =>
                                    handleOnChange(event.target.value as string)
                                }
                            >
                                {locations.map((location) => (
                                    <MenuItem
                                        key={location.id}
                                        value={location.id}
                                    >
                                        {location.name || ''}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div
                            className={classnames(
                                styles.iconWrapper,
                                styles.smallBuffer,
                                {
                                    [styles.valid]: valid,
                                },
                            )}
                        >
                            <CheckCircleIcon />
                        </div>
                    </div>
                </li>
                {activeVariables.map((variable) => renderVariable(variable))}
            </>
        );
    }

    return <CircularProgress />;
}
