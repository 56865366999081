import * as React from 'react';
import { Translated } from '@bondvet/web-app-i18n/util';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Legend,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import { LabelEntry } from 'components/MonthlyPulseReport/hooks/useMonthlyPulseReportData';
import { renderCustomLegend } from 'components/MonthlyPulseReport/components/BarCard/CustomLegend';
import { CustomTargetShape } from './CustomBar';
import styles from './BarCard.module.scss';

interface BarCardProps {
    label: Translated;
    subLabel?: Translated;
    barColor: string;
    stackBarColor?: string;
    legend?: {
        value: string;
        stackValue: string;
        target: string;
    };
    unit?: 'dollar' | 'number';
    precision: number;
    data: LabelEntry[];
}

const BarCard: React.FunctionComponent<BarCardProps> = ({
    label,
    subLabel,
    barColor,
    stackBarColor,
    precision,
    legend,
    data,
    unit = 'dollar',
}) => {
    const moneyFormatter = useMoneyFormatter({
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
    });

    const formattedData = React.useMemo(
        () =>
            data.map((entry, index) => ({
                formattedValue:
                    unit === 'dollar'
                        ? moneyFormatter.format(
                              entry.value + (entry.stackValue || 0),
                          )
                        : entry.value + (entry.stackValue || 0),
                ...entry,
                stackValue: entry.stackValue || 0,
                target: entry.target || null,
                index,
            })),
        [moneyFormatter, data, unit],
    );

    return (
        <div className={styles.container}>
            <span className={styles.label}>{label}</span>
            {subLabel && <span className={styles.subLabel}>{subLabel}</span>}
            <div className={styles.chartContainer}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={formattedData}
                        margin={{ top: 20, bottom: 10, right: 3 }}
                    >
                        <YAxis hide />
                        {legend && (
                            <Legend content={renderCustomLegend} height={10} />
                        )}
                        <Bar
                            dataKey="value"
                            fill={barColor}
                            isAnimationActive={false}
                            maxBarSize={80}
                            name={legend?.value || ''}
                            legendType={legend?.value ? undefined : 'none'}
                            stackId="barStack"
                        >
                            {!stackBarColor && (
                                <LabelList
                                    dataKey="formattedValue"
                                    position="top"
                                    fill="#10365a"
                                    className={styles.topValue}
                                />
                            )}
                        </Bar>
                        <Bar
                            dataKey="stackValue"
                            fill={stackBarColor}
                            isAnimationActive={false}
                            maxBarSize={80}
                            name={legend?.stackValue || ''}
                            stackId="barStack"
                            legendType={legend?.stackValue ? undefined : 'none'}
                            hide={!stackBarColor}
                        >
                            <LabelList
                                dataKey="formattedValue"
                                position="top"
                                fill="#10365a"
                                className={styles.topValue}
                            />
                        </Bar>

                        <Bar
                            dataKey="target"
                            fill="#ff4d4d"
                            xAxisId={1}
                            name={legend?.target || ''}
                            legendType={legend?.target ? undefined : 'none'}
                            shape={<CustomTargetShape />}
                            isAnimationActive={false}
                            maxBarSize={80}
                        />
                        <XAxis
                            dataKey="index"
                            axisLine={false}
                            tickLine={false}
                            height={40}
                            tick={(props) => (
                                <foreignObject
                                    key={props.payload.value}
                                    className={styles.xAxisLabelWrapper}
                                    x={`calc(${props.x}px - (100% / ${data.length} - 1rem) / 2`}
                                    y={props.y}
                                    width={`calc(100% / ${data.length} - 1rem)`}
                                    height="2rem"
                                >
                                    <div>
                                        <span className={styles.xAxisLabel}>
                                            {formattedData[props.index].label}
                                        </span>
                                    </div>
                                </foreignObject>
                            )}
                        />
                        <XAxis dataKey="index" hide xAxisId={1} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default BarCard;
