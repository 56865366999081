import * as React from 'react';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import styles from './AppointmentLogFilter.module.scss';
import { AppointmentLogFilterType, AppointmentLogStats } from './types';

interface AppointmentLogFilterItemProps {
    active: boolean;
    appointmentLogFilterType: AppointmentLogFilterType;
    showBullet?: boolean;
    counter: number;
    translatePrefix: string;
    onClick: (cancelFilterType: AppointmentLogFilterType) => void;
}

const AppointmentLogFilterItem: React.FunctionComponent<
    AppointmentLogFilterItemProps
> = ({
    active,
    appointmentLogFilterType,
    showBullet = true,
    counter,
    translatePrefix,
    onClick,
}) => {
    const translate = useTranslate();
    return (
        <button
            type="button"
            className={classnames(styles.item, {
                [styles.active]: active,
            })}
            onClick={() => onClick(appointmentLogFilterType)}
        >
            {showBullet && (
                <div
                    className={classnames(
                        styles.bullet,
                        styles[`bg-${appointmentLogFilterType}`],
                    )}
                />
            )}
            {translate(`${translatePrefix}.${appointmentLogFilterType}`)}
            <div
                className={classnames(
                    styles.badge,
                    styles[`bg-${appointmentLogFilterType}`],
                )}
            >
                {counter}
            </div>
        </button>
    );
};

interface AppointmentLogFilterProps {
    currentAppointmentLogFilterType: AppointmentLogFilterType;
    appointmentLogStats: AppointmentLogStats;
    translatePrefix: string;
    onChange: (appointmentLogFilterType: AppointmentLogFilterType) => void;
}

const AppointmentLogFilter: React.FunctionComponent<
    AppointmentLogFilterProps
> = ({
    currentAppointmentLogFilterType,
    appointmentLogStats,
    translatePrefix,
    onChange,
}) => {
    return (
        <div className={classnames(styles.container, styles.columns)}>
            <AppointmentLogFilterItem
                active={
                    currentAppointmentLogFilterType ===
                    AppointmentLogFilterType.all
                }
                appointmentLogFilterType={AppointmentLogFilterType.all}
                counter={appointmentLogStats.all}
                translatePrefix={translatePrefix}
                onClick={onChange}
                showBullet={false}
            />
            <AppointmentLogFilterItem
                active={
                    currentAppointmentLogFilterType ===
                    AppointmentLogFilterType.cancelled
                }
                appointmentLogFilterType={AppointmentLogFilterType.cancelled}
                counter={appointmentLogStats.cancelled}
                translatePrefix={translatePrefix}
                onClick={onChange}
            />
            <AppointmentLogFilterItem
                active={
                    currentAppointmentLogFilterType ===
                    AppointmentLogFilterType.rescheduled
                }
                appointmentLogFilterType={AppointmentLogFilterType.rescheduled}
                counter={appointmentLogStats.rescheduled}
                translatePrefix={translatePrefix}
                onClick={onChange}
            />
            <AppointmentLogFilterItem
                active={
                    currentAppointmentLogFilterType ===
                    AppointmentLogFilterType.noShow
                }
                appointmentLogFilterType={AppointmentLogFilterType.noShow}
                counter={appointmentLogStats.noShow}
                translatePrefix={translatePrefix}
                onClick={onChange}
            />
            <AppointmentLogFilterItem
                active={
                    currentAppointmentLogFilterType ===
                    AppointmentLogFilterType.blockoff
                }
                appointmentLogFilterType={AppointmentLogFilterType.blockoff}
                counter={appointmentLogStats.blockoff}
                translatePrefix={translatePrefix}
                onClick={onChange}
                showBullet={false}
            />
        </div>
    );
};

export default AppointmentLogFilter;
