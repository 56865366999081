import * as React from 'react';
import {
    dueInvoices,
    DueInvoicesQueryResult,
    Order,
    RawOrder,
} from 'api/clients/queries';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import useTimezone from 'hooks/useTimezone';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';

function mapOrder(
    {
        invoicedAt,
        total: rawTotal,
        totalPaid: rawTotalPaid,
        totalNotRendered: rawTotalNotRendered,
        paymentDueCents,
        ...order
    }: RawOrder,
    timezone: string,
): Order {
    const total = parseFloat(rawTotal) * 100;
    const totalPaid = rawTotalPaid ? parseFloat(rawTotalPaid) * 100 : 0;
    const totalNotRendered = rawTotalNotRendered
        ? parseFloat(rawTotalNotRendered) * 100
        : 0;
    return {
        ...order,
        total,
        totalPaid,
        totalNotRendered,
        dueAmount: paymentDueCents || 0,
        invoicedAt: invoicedAt
            ? moment(invoicedAt, moment.ISO_8601).tz(timezone)
            : null,
    };
}

type DueInvoices = {
    orders: ReadonlyArray<Order>;
    refetch: () => void;
    isLoading: boolean;
};

export default function useDueInvoices(clientId: string): DueInvoices {
    const [orders, setOrders] = React.useState<ReadonlyArray<Order>>([]);
    const timezone = useTimezone();

    const { data, refetch, loading } = useQuery<DueInvoicesQueryResult>(
        dueInvoices,
        {
            fetchPolicy: 'cache-and-network',
            variables: { clientId },
            context: { clientName: GRAPHQL_CLIENT_NAMES.vetspire },
        },
    );

    React.useEffect(() => {
        if (data?.orders) {
            const newOrders = data.orders;
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setOrders(
                        newOrders
                            .map<Order>((order) => mapOrder(order, timezone))
                            .filter(({ dueAmount }) => dueAmount > 0),
                    );
                });
            });
        }
    }, [data?.orders, timezone]);

    return { orders, refetch, isLoading: loading };
}
