import { gql } from '@apollo/client';
import {
    ExternalReferralReason,
    ExternalReferralHospitalWithReasons,
} from '@bondvet/types/externalReferrals';

export type ReasonsQueryResult = {
    externalReferralReasons: ReadonlyArray<ExternalReferralReason>;
};
export type HospitalsQueryResult = {
    externalReferralHospitals: ReadonlyArray<ExternalReferralHospitalWithReasons>;
};

export const hospitalsQuery = gql`
    query {
        externalReferralHospitals {
            _id
            name
            address
            reasons {
                _id
                name
            }
            description
            imageUrl
        }
    }
`;

export const reasonsQuery = gql`
    query {
        externalReferralReasons {
            _id
            name
        }
    }
`;

export const actionsQuery = gql`
    query {
        customerIoEmailActions {
            name
            body
            subject
            from
        }
    }
`;
