import * as React from 'react';
import { TranslateFunction } from '@bondvet/web-app-i18n/util';
import classnames from 'classnames';
import Section from '../Section';
import GlossarySection from '../GlossarySection';
import styles from './Glossary.module.scss';

interface GlossaryProps {
    textVersion: string;
    reportTranslate: TranslateFunction;
    showOnlinePharmacy: boolean;
}

export default function Glossary({
    textVersion,
    reportTranslate,
    showOnlinePharmacy,
}: GlossaryProps) {
    return (
        <>
            <div
                className={classnames(
                    styles.breakAfter,
                    styles['start-new-page'],
                )}
            >
                <Section title={reportTranslate('glossary.title')}>
                    <div className={styles.mainBody}>
                        <div
                            className={styles.topMessage}
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.main.description',
                                ).toString(),
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.main.monthlyProductionBreakdown',
                                ).toString(),
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.main.yourMonthlySnapshot',
                                ).toString(),
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.main.productionDetails',
                                ).toString(),
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.main.internalPerDiemShifts',
                                ).toString(),
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.main.criticalInternalPerDiemShifts',
                                ).toString(),
                            }}
                        />
                        <div
                            className={styles.bottomMessage}
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.main.footnote',
                                ).toString(),
                            }}
                        />
                    </div>
                </Section>
                <GlossarySection
                    title={reportTranslate(
                        'glossary.monthlyProductionBreakdown.title',
                    )}
                    subtitle={reportTranslate(
                        'glossary.monthlyProductionBreakdown.subtitle',
                    )}
                >
                    <div className={styles.detailedContainer}>
                        <ul className={styles.list}>
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: reportTranslate(
                                        'glossary.monthlyProductionBreakdown.totalProduction',
                                    ).toString(),
                                }}
                            />
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: reportTranslate(
                                        'glossary.monthlyProductionBreakdown.ytdEarnings',
                                    ).toString(),
                                }}
                            />
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: reportTranslate(
                                        'glossary.monthlyProductionBreakdown.productionBonusEarned',
                                    ).toString(),
                                }}
                            />
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: reportTranslate(
                                        'glossary.monthlyProductionBreakdown.gapToAchieveProduction',
                                    ).toString(),
                                }}
                            />
                        </ul>
                    </div>
                </GlossarySection>
                <GlossarySection
                    title={reportTranslate(
                        'glossary.yourMonthlySnapshot.title',
                    )}
                    subtitle={reportTranslate(
                        'glossary.yourMonthlySnapshot.subtitle',
                    )}
                >
                    <div className={styles.detailedContainer}>
                        <ul className={styles.list}>
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: reportTranslate(
                                        'glossary.yourMonthlySnapshot.top5ApptTypes',
                                    ).toString(),
                                }}
                            />
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: reportTranslate(
                                        'glossary.yourMonthlySnapshot.numOfIncApptsToHitProd',
                                    ).toString(),
                                }}
                            />
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: reportTranslate(
                                        'glossary.yourMonthlySnapshot.apptsPerShiftAgtNpsRevPerShift',
                                    ).toString(),
                                }}
                            />
                        </ul>
                    </div>
                </GlossarySection>
                <div
                    className={classnames(
                        styles.printOnly,
                        styles.printMessage,
                    )}
                    dangerouslySetInnerHTML={{
                        __html: reportTranslate(
                            'glossary.continuedOnNextPage',
                        ).toString(),
                    }}
                />
            </div>

            <Section
                title={reportTranslate('glossary.title')}
                subtitle={reportTranslate('glossary.continued')}
                className={classnames(
                    styles.printOnly,
                    styles['start-new-page'],
                )}
            ></Section>

            <GlossarySection
                title={reportTranslate('glossary.productionDetails.title')}
            >
                <div className={styles.detailedContainer}>
                    <div
                        className={styles.topMessage}
                        dangerouslySetInnerHTML={{
                            __html: reportTranslate(
                                'glossary.productionDetails.productCategoriesHeader',
                            ).toString(),
                        }}
                    />

                    <ul className={styles.list}>
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.productionDetails.treatment',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.productionDetails.other',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.productionDetails.foodDiets',
                                ).toString(),
                            }}
                        />
                    </ul>
                    <div
                        className={styles.topMessage}
                        dangerouslySetInnerHTML={{
                            __html: reportTranslate(
                                'glossary.productionDetails.creditPercentagesHeader',
                            ).toString(),
                        }}
                    />

                    <ul className={styles.list}>
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.productionDetails.100Percent',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.productionDetails.25Percent',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.productionDetails.20Percent',
                                ).toString(),
                            }}
                        />
                        {textVersion !== 'v1' && (
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: reportTranslate(
                                        'glossary.productionDetails.10Percent',
                                    ).toString(),
                                }}
                            />
                        )}
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.productionDetails.0Percent',
                                ).toString(),
                            }}
                        />
                    </ul>
                    {showOnlinePharmacy && (
                        <div
                            className={styles.bottomMessage}
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.productionDetails.creditPercentagesFooter',
                                ).toString(),
                            }}
                        />
                    )}
                </div>
            </GlossarySection>

            <GlossarySection
                title={reportTranslate('glossary.generalTerms.title')}
            >
                <div className={styles.detailedContainer}>
                    <ul className={styles.list}>
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.generalTerms.grossProduction',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.generalTerms.netProduction',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.generalTerms.productionTarget',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.generalTerms.perDiemShift',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.generalTerms.criticalPerDiemShift',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.generalTerms.agt',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.generalTerms.averageNetProdForClinic',
                                ).toString(),
                            }}
                        />
                        <li
                            dangerouslySetInnerHTML={{
                                __html: reportTranslate(
                                    'glossary.generalTerms.netProductionAdjustment',
                                ).toString(),
                            }}
                        />
                        {textVersion !== 'v1' && (
                            <>
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: reportTranslate(
                                            'glossary.generalTerms.productionFactor',
                                        ).toString(),
                                    }}
                                />
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: reportTranslate(
                                            'glossary.generalTerms.productionBonus',
                                        ).toString(),
                                    }}
                                />
                            </>
                        )}
                    </ul>
                </div>
            </GlossarySection>
        </>
    );
}
