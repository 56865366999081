export enum DateFilterType {
    all = 'all',
    today = 'today',
    past = 'past',
    future = 'future',
}

export interface DateStats {
    all: number | string;
    today: number | string;
    past: number | string;
    future: number | string;
}

export interface DateStatsNumbers {
    all: number;
    today: number;
    past: number;
    future: number;
}
