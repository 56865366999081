import * as React from 'react';
import classnames from 'classnames';
import styles from 'components/LookupSelect/LookupSelect.module.scss';
import Select, { SingleValue } from 'react-select';
import useTranslate from 'hooks/useTranslate';
import { CancellationReason } from '@bondvet/types/clientApp/settings';

type SelectCancelReasonProps = {
    cancellationReasons: readonly CancellationReason[];
    selectedCancellationReason: CancellationReason | null;
    setSelectedCancellationReason: (
        cancellationReason: CancellationReason | null,
    ) => void;
    error?: boolean;
};

export default function SelectCancelReason({
    cancellationReasons,
    selectedCancellationReason,
    setSelectedCancellationReason,
    error,
}: SelectCancelReasonProps) {
    const translate = useTranslate();

    return (
        <Select
            isMulti={false}
            name="cancelReason"
            value={selectedCancellationReason}
            required
            className={classnames(styles.lookup, { [styles.error]: error })}
            classNamePrefix="lookup-select"
            options={cancellationReasons}
            onChange={(value: SingleValue<CancellationReason>) => {
                setSelectedCancellationReason(
                    value as CancellationReason | null,
                );
            }}
            menuPosition="fixed"
            placeholder={
                <div className={styles.placeholder}>
                    {translate(
                        'vetspireExtension.clientDetails.payments.chargeFee.cancelReason.placeholder',
                    )}
                </div>
            }
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
        />
    );
}
