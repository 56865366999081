import * as React from 'react';
import classnames from 'classnames';
import styles from './SubTabs.module.scss';

export interface SubTabData {
    label: React.ReactNode;
    name: string;
}

export interface SubTabsProps {
    currentTab: string;
    showCheckList?: boolean;
    onChange: (tab: string) => void;
    tabs: ReadonlyArray<SubTabData>;
    className?: string;
}

export default function SubTabs({
    currentTab,
    onChange,
    tabs,
    className,
}: SubTabsProps): React.ReactElement {
    return (
        <div
            className={classnames(styles.container, className, {
                [styles.columns2]: tabs.length === 2,
                [styles.columns3]: tabs.length === 3,
                [styles.columns4]: tabs.length === 4,
            })}
        >
            {tabs.map(({ name, label }) => (
                <div
                    key={name}
                    className={classnames(styles.tabContainer, {
                        [styles.activeTab]: name === currentTab,
                    })}
                >
                    <button
                        type="button"
                        className={styles.tab}
                        onClick={() => onChange(name)}
                    >
                        {label}
                    </button>
                </div>
            ))}
        </div>
    );
}
