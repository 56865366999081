import * as React from 'react';
import classnames from 'classnames';
import { Translated } from '@bondvet/web-app-i18n/util';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import styles from './CheckBox.module.scss';

interface CheckBoxProps {
    label: Translated;
    value: boolean;
    onChange: (value: boolean) => void;
    fullWidth?: boolean;
    labelOrientation?: 'before' | 'after';
    solid?: boolean;
    classes?: Partial<typeof styles>;
}

export default function Checkbox({
    label,
    value,
    onChange,
    fullWidth = true,
    labelOrientation = 'before',
    solid = false,
    classes,
}: CheckBoxProps) {
    const icon = solid ? (
        <CheckBoxIcon
            classes={{ root: classnames(styles.icon, classes?.icon) }}
        />
    ) : (
        <CheckBoxOutlinedIcon
            classes={{ root: classnames(styles.icon, classes?.icon) }}
        />
    );

    return (
        <button
            type="button"
            className={classnames(styles.checkbox, classes?.checkbox, {
                [styles.labelAfter]: labelOrientation === 'after',
            })}
            onClick={() => onChange(!value)}
        >
            <div
                className={classnames(styles.label, classes?.label, {
                    [styles.fullWidth]: fullWidth,
                })}
            >
                {label}
            </div>
            {value ? (
                icon
            ) : (
                <CheckBoxOutlineBlankOutlinedIcon
                    classes={{ root: classnames(styles.icon, classes?.icon) }}
                />
            )}
        </button>
    );
}
