import { gql } from '@apollo/client';
import { AppointmentType } from '@bondvet/types/booking';
import useLazyBookingQuery from 'hooks/useLazyBookingQuery';

const appointmentTypesQuery = gql`
    query appointmentTypes {
        appointmentTypes {
            _id
            name
            chargeLateCancelRescheduleFee
        }
    }
`;

export interface AppointmentTypesQueryResult {
    appointmentTypes: readonly Pick<
        AppointmentType,
        '_id' | 'name' | 'chargeLateCancelRescheduleFee'
    >[];
}

export default function useLazyAppointmentTypes(vetspireLocationId?: string) {
    return useLazyBookingQuery<AppointmentTypesQueryResult>(
        appointmentTypesQuery,
        {
            variables: { vetspireLocationId },
        },
    );
}
