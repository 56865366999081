import * as React from 'react';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import ActionButton from 'components/ActionButton';
import CircularProgress from '@mui/material/CircularProgress';
import GeneralError from 'components/GeneralError/GeneralError';
import { decode } from 'html-entities';
import useSurgeryFormsOptions from '../../hooks/useSurgeryFormsOptions';

import styles from './Recheck.module.scss';
import surgeryFormsStyles from '../../SurgeryForms.module.scss';

interface RecheckProps {
    encounterId: string;
    surgeryFormsOptionId: string | null;
    surgeryFormNote: string;
    onChange: (
        surgeryFormsOptionId: string | null,
        surgeryFormNote: string,
    ) => void;
    onBack: () => void;
    onSend: () => void;
}

const Recheck: React.FunctionComponent<RecheckProps> = ({
    encounterId,
    surgeryFormsOptionId,
    surgeryFormNote,
    onBack,
    onSend,
    onChange,
}) => {
    const translate = useTranslate();

    const surgeryFormsOptionsQuery = useSurgeryFormsOptions(encounterId);

    if (surgeryFormsOptionsQuery.loading) {
        return (
            <div className={styles.loading}>
                <CircularProgress />
            </div>
        );
    }

    if (surgeryFormsOptionsQuery.error) {
        return (
            <GeneralError message={surgeryFormsOptionsQuery.error.message} />
        );
    }

    return (
        <div
            className={classnames(
                styles.flyoutBlock,
                styles.noHorizontal,
                styles.noVertical,
                styles.container,
            )}
        >
            <div className={styles.options}>
                <div className={classnames(styles.itemsWithTitle)}>
                    <div className={styles.title}>
                        {translate('vetspireExtension.surgeryForms.recheck')}
                    </div>
                    <label className={styles.option}>
                        <input
                            name="surgeryFormsOption"
                            type="radio"
                            checked={surgeryFormsOptionId === null}
                            onChange={() => onChange(null, surgeryFormNote)}
                            className={styles.optionInput}
                        />
                        {translate(
                            'vetspireExtension.surgeryForms.noRecheckRequired',
                        )}
                    </label>
                    {surgeryFormsOptionsQuery.surgeryFormsOptions?.map(
                        (surgeryFormsOption) => (
                            <label
                                key={surgeryFormsOption._id}
                                className={styles.option}
                            >
                                <input
                                    name="surgeryFormsOption"
                                    type="radio"
                                    checked={
                                        surgeryFormsOptionId ===
                                        surgeryFormsOption._id
                                    }
                                    onChange={() =>
                                        onChange(
                                            surgeryFormsOption._id || null,
                                            surgeryFormNote,
                                        )
                                    }
                                    className={styles.optionInput}
                                />
                                {decode(surgeryFormsOption.renderedContent)}
                            </label>
                        ),
                    )}
                </div>
            </div>

            <div className={styles.note}>
                {translate(
                    'vetspireExtension.surgeryForms.additionalNotes.title',
                )}
                <textarea
                    value={surgeryFormNote}
                    placeholder={
                        translate(
                            'vetspireExtension.surgeryForms.additionalNotes.placeholder',
                        ) as string
                    }
                    onChange={(event) =>
                        onChange(surgeryFormsOptionId, event.target.value)
                    }
                />
            </div>

            <div
                className={classnames(
                    styles.flyoutBlock,
                    styles.noMargin,
                    styles.padding,
                    surgeryFormsStyles.actions,
                )}
            >
                <ActionButton
                    onClick={onBack}
                    active
                    className={surgeryFormsStyles.back}
                >
                    {translate('vetspireExtension.surgeryForms.actions.back')}
                </ActionButton>
                <div />
                <ActionButton
                    onClick={onSend}
                    active
                    className={surgeryFormsStyles.next}
                >
                    {translate('vetspireExtension.surgeryForms.actions.send')}
                </ActionButton>
            </div>
        </div>
    );
};

export default Recheck;
