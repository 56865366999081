import * as React from 'react';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import classnames from 'classnames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from './VariableInput.module.scss';

type AttachmentInputProps = {
    attachment: string | null;
    setAttachment: (value: string | null) => void;
    error?: string | null;
};

export default function AttachmentInput({
    attachment,
    setAttachment,
    error,
}: AttachmentInputProps): React.ReactElement | null {
    const translate = useTranslate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAttachment(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDelete = () => {
        setAttachment(null);
    };

    return (
        <li className={styles.attachmentRoot}>
            <p id="attachment-input-label">
                {translate(
                    'vetspireExtension.texting.sendOutboundMessageModal.variables.attachment',
                )}
            </p>
            <div className={styles.attachmentLabelWrapper}>
                <label
                    htmlFor="files"
                    className={classnames(styles.attachmentInputLabel, {
                        [styles.selected]: !!attachment,
                    })}
                >
                    <AttachFileIcon className={styles.icon} /> Attach Image
                </label>
                {!!attachment && (
                    <div
                        className={classnames(styles.iconWrapper, styles.valid)}
                    >
                        <CheckCircleIcon />
                    </div>
                )}
            </div>
            <input
                id="files"
                className={styles.attachmentInput}
                aria-labelledby="attachment-input-label"
                type="file"
                accept="image/*"
                onChange={handleChange}
                disabled={!!attachment}
            />
            {error && <div className={styles.errorText}>{error}</div>}
            {attachment && (
                <div className={styles.imageWrapper}>
                    <IconButton
                        classes={{ root: styles.deleteButton }}
                        onClick={handleDelete}
                        type="button"
                        aria-controls="actions-menu"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    <img
                        src={attachment}
                        alt="Selected"
                        className={styles.image}
                    />
                </div>
            )}
        </li>
    );
}
