import * as React from 'react';
import { vetspireContext } from 'context/VetspireContext';

export type ClientAndPatientId = {
    clientId: string | null;
    patientId: string | null;
};

export default function useClientAndPatientId(
    enforcedClientId?: string | null,
    enforcedPatientId?: string | null,
): ClientAndPatientId {
    const [{ clientId, patientId }, setClientAndPatientId] =
        React.useState<ClientAndPatientId>({
            clientId: null,
            patientId: null,
        });
    const { pathName } = React.useContext(vetspireContext);

    React.useEffect(() => {
        if (enforcedClientId) {
            setClientAndPatientId({
                clientId: enforcedClientId,
                patientId: enforcedPatientId || null,
            });
        } else {
            const match = pathName.match(
                /^\/clients\/(\d+)(?:\/patients\/(\d+))?/,
            );

            if (match) {
                const [, newClientId, newPatientId] = match;

                setClientAndPatientId({
                    clientId: newClientId,
                    patientId: newPatientId || null,
                });
            } else {
                setClientAndPatientId({ clientId: null, patientId: null });
            }
        }
    }, [enforcedClientId, enforcedPatientId, pathName]);

    return { clientId, patientId };
}
