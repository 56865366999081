import * as React from 'react';
import { useApolloClient } from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import useTranslate from 'hooks/useTranslate';
import {
    createTelehealthSessionMutation,
    CreateTelehealthSessionResult,
    TelehealthInput,
} from 'api/telehealth/queries';

import { CreateTelehealthSessionContextType } from './types';

export default function useCreateTelehealthSession(): CreateTelehealthSessionContextType {
    const translate = useTranslate();
    const apolloClient = useApolloClient();

    const setupSession = React.useCallback(
        async (
            telehealthInput: TelehealthInput,
            paymentIntentId: string,
            onError: (message: string) => void,
        ): Promise<boolean> => {
            const { data: telehealthData, errors: telehealthErrors } =
                await apolloClient.mutate<CreateTelehealthSessionResult>({
                    mutation: createTelehealthSessionMutation,
                    variables: {
                        input: telehealthInput,
                        paymentIntentId,
                    },
                    context: {
                        clientName: GRAPHQL_CLIENT_NAMES.telehealth,
                    },
                });

            if (telehealthErrors) {
                onError(
                    translate(
                        'vetspireExtension.telehealth.addClientToQueue.error',
                        { error: (telehealthErrors || []).join(', ') },
                    ) as string,
                );
                return false;
            }

            if (telehealthData?.createTelehealthSession?.error) {
                onError(
                    translate(
                        'vetspireExtension.telehealth.addClientToQueue.error',
                        {
                            error: telehealthData?.createTelehealthSession
                                ?.error,
                        },
                    ) as string,
                );
                return false;
            }

            if (!telehealthData?.createTelehealthSession.sessionIds) {
                onError(
                    translate(
                        'vetspireExtension.telehealth.addClientToQueue.error',
                        { error: 'sessionIds not set' },
                    ) as string,
                );
                return false;
            }

            return true;
        },
        [translate, apolloClient],
    );

    return {
        setupSession,
    };
}
