import * as React from 'react';
import Select, { SingleValue } from 'react-select';
import styles from 'components/LookupSelect/LookupSelect.module.scss';
import useTranslate from 'hooks/useTranslate';
import { Translated } from '@bondvet/web-app-i18n/util';
import classnames from 'classnames';
import { NameFormat } from '../../types';

interface NameFormatSelectProps {
    selectedNameFormat: NameFormat;
    onChange: (nameFormat: NameFormat) => void;
    isDisabled?: boolean;
    className?: string;
}

interface SelectOption {
    value: string;
    label: Translated;
}

const NameFormatSelect: React.FunctionComponent<NameFormatSelectProps> = ({
    selectedNameFormat,
    onChange,
    isDisabled = false,
    className,
}) => {
    const translate = useTranslate();

    const options = React.useMemo<SelectOption[]>(() => {
        return Object.keys(NameFormat).map((key) => ({
            value: key,
            label: translate(
                `vetspireExtension.staffOptimization.nameFormat.${key}`,
            ),
        }));
    }, [translate]);

    const value = React.useMemo(() => {
        return options.find((option) => option.value === selectedNameFormat);
    }, [options, selectedNameFormat]);

    return (
        <Select
            name="nameFormat"
            options={options}
            value={value}
            className={classnames(styles.lookup, className)}
            classNamePrefix="lookup-select"
            onChange={(newValue: SingleValue<SelectOption>) =>
                onChange((newValue?.value || NameFormat.initials) as NameFormat)
            }
            isDisabled={isDisabled}
        />
    );
};

export default NameFormatSelect;
