import { gql } from '@apollo/client';
import {
    PatientPlanAdditionsQueryResult,
    PatientPlanAdditionsQueryVariables,
} from '@bondvet/types/patientPlans';
import usePatientPlansQuery from 'hooks/usePatientPlansQuery';

const patientPlanAdditionsQuery = gql`
    query patientPlanAdditions($id: ID!) {
        patientPlanAdditions(id: $id) {
            cancellationReason
            cancellationReasonNote
            autoRenew
        }
    }
`;

export default function usePatientPlanAdditionsQuery(patientPlanId: string) {
    return usePatientPlansQuery<
        PatientPlanAdditionsQueryResult,
        PatientPlanAdditionsQueryVariables
    >(patientPlanAdditionsQuery, {
        variables: {
            id: patientPlanId,
        },
    });
}
