import * as React from 'react';
import { ApolloError } from '@apollo/client/errors';
import { SurgeryType } from '@bondvet/types/surgeryReferrals';
import { useQuery } from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import { surgeryTypes as query, SurgeryTypesQuery } from '../api/queries';

type SurgeryTypes = {
    loading: boolean;
    error?: ApolloError;
    surgeryTypes: ReadonlyArray<SurgeryType>;
};

export default function useSurgeryTypes(): SurgeryTypes {
    const { data, loading, error } = useQuery<SurgeryTypesQuery>(query, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
    });

    const surgeryTypes = React.useMemo(
        () => data?.surgeryTypes || [],
        [data?.surgeryTypes],
    );

    return {
        loading,
        error,
        surgeryTypes,
    };
}
