export const VETSPIRE_COUPONS_COLLECTION_NAME = 'vetspire.coupons';

export const PROMO_CODES_COLLECTION_NAME = 'promoCodes';
export const REFERRAL_LOGS_COLLECTION_NAME = 'promoCodes.referralLogs';
export const PROMO_CODE_LOGS_COLLECTION_NAME = 'promoCodes.logs';
export const DELETED_LOGS_COLLECTION_NAME = 'promoCodes.logs.deleted';
export const PARTNERSHIP_CATEGORIES_COLLECTION_NAME =
    'promoCodes.partnershipCategories';

export const NOTE_PREFIX_REFERRAL = 'Referral';
export const NOTE_PREFIX_PROMO_CODE = 'Promo';

// credit ledger correctly detects both types of notes.
export const NOTE_PROMO_CODE_CANCELLED = 'code cancelled';

// deprecated. only used to identify credit memos that were created
// _before_ we renamed `Coupons` to `Promo Codes`
export const DEPRECATED_COUPON_CANCELLED = 'Coupon cancelled';

export const JOB_TYPE_APPLY_PROMO_CODE = 'promoCodes.apply';

export const JOB_TYPE_CANCEL_PROMO_CODE = 'promoCodes.cancel';

export const JOB_TYPE_DISCHARGE_REFERRAL = 'promoCodes.referral.discharge';

export type JobTypes = Readonly<{
    apply: typeof JOB_TYPE_APPLY_PROMO_CODE;
    cancel: typeof JOB_TYPE_CANCEL_PROMO_CODE;
    referral: Readonly<{
        discharge: typeof JOB_TYPE_DISCHARGE_REFERRAL;
    }>;
}>;

export const JOB_TYPES: JobTypes = Object.freeze({
    apply: JOB_TYPE_APPLY_PROMO_CODE,
    cancel: JOB_TYPE_CANCEL_PROMO_CODE,
    referral: Object.freeze({
        discharge: JOB_TYPE_DISCHARGE_REFERRAL,
    }),
});

export const AMOUNT_TYPE_CUSTOM = 'custom' as const;
export const AMOUNT_TYPE_PATIENT_TAG = 'patientTag' as const;

export const PARTNERSHIP_CATEGORY_NEW = '__new__';

export const COUPON_RESTRICTIONS_COLLECTION_NAME = `vetspire.couponRestrictions`;
