import { useQuery } from '@apollo/client';
import {
    type Viewer,
    viewerQuery,
    type ViewerQueryResult,
} from 'api/providers/queries';
import type { ApolloError } from '@apollo/client/errors';
import type { ProviderRights } from '@bondvet/types/providers';
import {
    vetspireProvider,
    type VetspireProviderQueryResult,
} from 'api/bond/queries';
import useLazyBondQuery from './useLazyBondQuery';

export enum ViewerSettingsNS {
    tasks = 'tasks',
    encounters = 'encounters',
    staffOptimization = 'staffOptimization',
    staffOptimizationUKG = 'staffOptimizationUKG',
    providerPayment = 'providerPayment',
    appointmentLog = 'appointmentLog',
    surgeryCalendar = 'surgeryCalendar',
}

export enum ViewerSettingsKey {
    selectedProviderIds = 'selectedProviderIds',
    selectedTaskCategoryIds = 'selectedTaskCategoryIds',
    selectedLocationIds = 'selectedLocationIds',
    selectedWeek = 'selectedWeek',
    selectedMonth = 'selectedMonth',
    selectedStaffingRoles = 'selectedStaffingRoles',
    selectedOptions = 'selectedOptions',
}

interface ViewerSettings {
    loading: boolean;
    error?: ApolloError;
    viewer: Viewer | null;
    rights: ProviderRights | null;
    paymentPin: string | null;
    setItem: <T>(
        ns: ViewerSettingsNS,
        key: ViewerSettingsKey,
        value: T,
    ) => void;
    getItem: <T>(
        ns: ViewerSettingsNS,
        key: ViewerSettingsKey,
        defaultValue: T,
    ) => T;
}

export default function useViewerSettings(): ViewerSettings {
    const [runVetspireProviderQuery, vetspireProviderQueryResult] =
        useLazyBondQuery<VetspireProviderQueryResult>(vetspireProvider, {
            fetchPolicy: 'cache-first',
        });

    const viewerQueryResult = useQuery<ViewerQueryResult>(viewerQuery, {
        fetchPolicy: 'cache-first',
        onCompleted: (result) => {
            runVetspireProviderQuery({
                variables: {
                    providerId: result.viewer?.id,
                },
            });
        },
    });

    const setItem = <T>(
        ns: ViewerSettingsNS,
        key: ViewerSettingsKey,
        value: T,
    ) => {
        const viewerId = viewerQueryResult.data?.viewer?.id;
        if (viewerId) {
            localStorage.setItem(
                `${viewerId}.${ns}.${key}`,
                JSON.stringify(value),
            );
        } else {
            console.error('setItem called while viewer not loaded');
        }
    };

    const getItem = <T>(
        ns: ViewerSettingsNS,
        key: ViewerSettingsKey,
        defaultValue: T,
    ): T => {
        const viewerId = viewerQueryResult.data?.viewer?.id;
        if (viewerId) {
            const stringValue = localStorage.getItem(
                `${viewerId}.${ns}.${key}`,
            );
            if (stringValue === null) {
                return defaultValue;
            }

            return JSON.parse(stringValue) as T;
        }
        console.error('getItem called while viewer not loaded');
        return defaultValue;
    };

    if (viewerQueryResult.loading || vetspireProviderQueryResult.loading) {
        return {
            loading: true,
            viewer: null,
            rights: null,
            paymentPin: null,
            setItem,
            getItem,
        };
    }

    if (viewerQueryResult.error) {
        return {
            loading: false,
            error: viewerQueryResult.error,
            viewer: null,
            rights: null,
            paymentPin: null,
            setItem,
            getItem,
        };
    }

    if (vetspireProviderQueryResult.error) {
        return {
            loading: false,
            error: vetspireProviderQueryResult.error,
            viewer: null,
            rights: null,
            paymentPin: null,
            setItem,
            getItem,
        };
    }

    return {
        loading: false,
        viewer: viewerQueryResult.data?.viewer || null,
        rights:
            (vetspireProviderQueryResult.data?.vetspireProvider
                ?.rights as ProviderRights) || null,
        paymentPin:
            vetspireProviderQueryResult.data?.vetspireProvider?.paymentPin ||
            null,
        setItem,
        getItem,
    };
}
