import type { OperationResult } from '@bondvet/types';
import { gql } from '@apollo/client';

export interface SendInfoPdfResult {
    sendInfoPdf: OperationResult;
}

export interface SendInfoPdfVariables {
    clientId: string;
    pdfIds: ReadonlyArray<string>;
    petId: string;
}

export const sendInfoPdf = gql`
    mutation sendInfoPdf(
        $clientId: String!
        $pdfIds: [String]!
        $petId: String!
    ) {
        sendInfoPdf(clientId: $clientId, pdfIds: $pdfIds, petId: $petId) {
            success
            error
        }
    }
`;
